import { makeStyles } from "@material-ui/core/styles";

export const profileStyles = makeStyles(() => ({
  headerImage: {
    width: "100%",
    zIndex: 1,
  },
  avatar: {
    width: 140,
    height: 140,
  },
  profileInfo: {
    marginTop: -75,
    zIndex: 99,
  },
  actionButtons: {
    backgroundColor: "#363636",
    height: 40,
    width: 40,
    margin: 8,
  },
  icon: {
    color: "white",
    width: 30,
  },
  userName: {
    fontSize: 24,
    marginTop: 10,
  },
  publicKey: {
    fontSize: 14,
    color: "#AAABAA",
  },
}));
