import React, { useCallback, useEffect, useState } from "react";
import { Payment, CartItem } from "src/app/models";
import { usePaymentDAO } from "src/app/business";
import { Button, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Decidir } from "src/app/services";
import { FormProps, ValuesForm } from "../../../types";
import { Loading } from "../../../../../../commons";
import { useCheckoutFormContext } from "../../../hooks";
import { createOrder, createPaymentMercadoPago } from "../../../../../../utils/paymentOrderHelper";
import { useCartContext } from "../../../../../../context";
import { WithCardValues } from "../types";
import { useMercadopagoPayment, useMountMercadopagoPaymentCallback } from "../hooks";

export const CardInfoFormMercadoPago = ({ onBackStep, setIsBlocking, deliveryData }: FormProps) => {
  const { values } = useCheckoutFormContext();
  const { cartState } = useCartContext();
  const paymentDAO = usePaymentDAO();
  const [requesting, setRequesting] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [error, setError] = useState("");
  const mountMercadopago = useMountMercadopagoPaymentCallback();
  const triggerMercadopago = useMercadopagoPayment();

  useEffect(() => {
    if (values && cartState && !requesting) {
      setRequesting(true);
      (async () => {
        const { items, owner, lastUpdate = new Date(), state_id } = cartState;
        const itemsFix: CartItem[] = items.map(({ quantity, publication }) => ({
          quantity,
          publication,
        }));
        const ipAddress: any = await Decidir.getIp();
        const order = await createOrder({ items: itemsFix, owner, lastUpdate, state_id }, values as ValuesForm, deliveryData);
        const paymentBody = createPaymentMercadoPago(order, (values as WithCardValues).paymentMethod.payment, { ip_address: ipAddress });

        const resultPayment: Payment = await paymentDAO.paymentMercadoPago(paymentBody);

        if (resultPayment?.metadata?.mercadopago?.id) {
          mountMercadopago(resultPayment?.metadata?.mercadopago?.id);
          triggerMercadopago();
          setTimeout(() => {
            setShowButton(true);
            setError("");
          }, 2000);
        } else if (resultPayment?.metadata?.result === "NO_STOCK") {
          setError("Algun producto del carrito no tiene stock, por favor revise su orden");
          setShowButton(true);
        } else {
          console.log(resultPayment?.metadata);
          setError("Ocurrió un problema. Por favor pongase en contacto con nosotros");
          setShowButton(true);
        }
      })();
    }
  }, [cartState, mountMercadopago, paymentDAO, requesting, triggerMercadopago, values, deliveryData]);

  const onPay = useCallback(() => {
    if (setIsBlocking) setIsBlocking(false);
    triggerMercadopago();
  }, [triggerMercadopago, setIsBlocking]);

  return (
    <Grid container direction="column" spacing={1}>
      {!!error && (
        <Grid item>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      {showButton ? (
        <Grid item>
          <Grid container spacing={1} justify="center">
            <Grid item>
              <Button variant="outlined" onClick={onBackStep}>
                Volver
              </Button>
            </Grid>
            {!error && (
              <Grid item>
                <Button variant="outlined" color="primary" onClick={onPay}>
                  Intentar Pagar
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </Grid>
  );
};
