import React from "react";
import { useHistory } from "react-router";
import { CustomButtonProps } from "src/commons/components/CustomBotton";
import { Box, Grid, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { NotFoundPageStyles as useStyles } from "../../styles";
import error404Ico from "../../../../assets/images/error404-420x266.png";

export const NotFoundPageView = () => {
  const classes = useStyles();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const history = useHistory();

  const handleBackHome = () => {
    history.push("/");
  };
  return (
    <Grid container className={classes.gridContainer} wrap="wrap">
      <Box className={classes.boxContainer} width="100%">
        <Typography className={classes.titleText} variant="h3" component="h3">
          Página no encontrada
        </Typography>
        <Typography className={classes.infoText} variant="h3" component="h3">
          Esta página no existe o fué removida, le recomendamos ir al inicio
        </Typography>

        <Box className={classes.boxImage} left={isDownSm ? "40%" : "65%"} top="8.75rem">
          <img src={error404Ico} alt="Error 404 Ico" />
        </Box>
        <Box className={classes.boxError} left={isDownSm ? "35%" : "75%"} bottom="7rem">
          <Typography className={classes.errorNumber} variant="h1" color="textSecondary" align="right">
            404
          </Typography>
          <Typography className={classes.errorText} variant="h6" color="textSecondary" align="right">
            ERROR
          </Typography>
        </Box>
        <CustomButtonProps name="IR AL INICIO" onClick={handleBackHome} />
      </Box>
    </Grid>
  );
};
