/* eslint-disable */
import React from "react";

interface UserIconProps {
  width?: string | number;
  height?: string | number;
  color?: string;
}

export const UserIcon = ({ height = 21, width = 19, color = "#ADB2B8" }: UserIconProps) => (
  <svg width={width} height={height} viewBox="0 0 19 21" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.0342 16.4349C17.564 15.3212 16.8817 14.3095 16.0252 13.4563C15.1713 12.6006 14.1598 11.9184 13.0466 11.4473C13.0366 11.4423 13.0266 11.4398 13.0167 11.4349C14.5695 10.3132 15.579 8.48615 15.579 6.4248C15.579 3.01 12.8123 0.243256 9.39748 0.243256C5.98267 0.243256 3.21593 3.01 3.21593 6.4248C3.21593 8.48615 4.22542 10.3132 5.77828 11.4373C5.76831 11.4423 5.75834 11.4448 5.74837 11.4498C4.6317 11.9209 3.62969 12.5964 2.76976 13.4588C1.91409 14.3127 1.23186 15.3242 0.760758 16.4374C0.297945 17.5273 0.0483395 18.6958 0.025453 19.8796C0.0247877 19.9062 0.0294538 19.9327 0.0391766 19.9575C0.0488993 19.9823 0.0634819 20.0048 0.082065 20.0239C0.100648 20.0429 0.122856 20.0581 0.147379 20.0684C0.171903 20.0787 0.198247 20.084 0.224858 20.084H1.72039C1.83007 20.084 1.91731 19.9968 1.9198 19.8896C1.96965 17.9654 2.74234 16.1632 4.10826 14.7973C5.52155 13.384 7.39844 12.6064 9.39748 12.6064C11.3965 12.6064 13.2734 13.384 14.6867 14.7973C16.0526 16.1632 16.8253 17.9654 16.8752 19.8896C16.8776 19.9993 16.9649 20.084 17.0746 20.084H18.5701C18.5967 20.084 18.623 20.0787 18.6476 20.0684C18.6721 20.0581 18.6943 20.0429 18.7129 20.0239C18.7315 20.0048 18.7461 19.9823 18.7558 19.9575C18.7655 19.9327 18.7702 19.9062 18.7695 19.8796C18.7446 18.6882 18.4978 17.5292 18.0342 16.4349ZM9.39748 10.712C8.25339 10.712 7.17661 10.2658 6.36652 9.45576C5.55644 8.64568 5.11027 7.56889 5.11027 6.4248C5.11027 5.28072 5.55644 4.20393 6.36652 3.39385C7.17661 2.58377 8.25339 2.1376 9.39748 2.1376C10.5416 2.1376 11.6183 2.58377 12.4284 3.39385C13.2385 4.20393 13.6847 5.28072 13.6847 6.4248C13.6847 7.56889 13.2385 8.64568 12.4284 9.45576C11.6183 10.2658 10.5416 10.712 9.39748 10.712Z"
      fill={color}
    />
  </svg>
);
