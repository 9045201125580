import { LibbyFetchByIdReturn, makeEntityFetchById } from "../hooks";

export interface WithRenderPropFetchByIdProps<T> {
  id: string | number;
  aspect?: string;
  children: (result: LibbyFetchByIdReturn<T>) => JSX.Element;
}

export const withRenderPropFetchById = <T>(daoName: string) => {
  const useHook = makeEntityFetchById<T>(daoName);
  return ({ id, children, aspect }: WithRenderPropFetchByIdProps<T>) => {
    const result = useHook(id, aspect);
    return children(result);
  };
};
