import { Profile } from "src/screens";
import { UserInformationForm } from "src/screens/Private/Profile/component/UserInformationForm";
import { MenuItem } from "src/commons";
import { AddressesInfoForm } from "src/screens/Private/Profile/component/AddressesInfoForm";

export const routes: MenuItem[] = [
  {
    key: "privateFormAddressMain",
    basePath: "/address/:id?",
    target: {
      component: AddressesInfoForm,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Personal info",
    children: [],
    default: true,
  },
  {
    key: "privateFormInfoMain",
    basePath: "/info/:id?",
    target: {
      component: UserInformationForm,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Personal info",
    children: [],
    default: true,
  },
  {
    key: "privateProfileMain",
    basePath: "/",
    target: {
      component: Profile,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Profile",
    children: [],
    default: true,
  },
];
