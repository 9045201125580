import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useGeneralContext } from "src/context";
import { useSkeletonLibbyCall } from "src/app/business";
import { SKELETON_IDS } from "src/app/skeletons";
import { Skeleton, SkeletonComponent, SKELETON_TYPE, SkeletonComponents } from "src/app/models/Site";
import { Loading } from "src/commons";
import { orderBy } from "lodash";

export const Product = () => {
  const history = useHistory();
  const { generalState, dispatch: generalDispatch } = useGeneralContext();
  const { data, working } = useSkeletonLibbyCall<Skeleton>({ methodName: "getSkeleton", params: [SKELETON_IDS.PRODUCT] });

  useEffect(() => {
    if (generalState.paginate.path === "/product") return;
    generalDispatch.setPaginate({ ...generalState.paginate, path: "/product" });
  }, [generalDispatch, generalState.paginate, history.location.pathname]);

  return (
    <>
      {working ? (
        <Loading />
      ) : data && !Array.isArray(data) ? (
        orderBy(data.skeleton_components, "relevance", "asc").map((skeleton: SkeletonComponent) => {
          const Component = SkeletonComponents[SKELETON_TYPE[skeleton.config_values.component_key]];
          return Component ? <Component data={skeleton.config_values} /> : <></>;
        })
      ) : null}
    </>
  );
};
