import { DappWalletManager } from "src/platform/DappWallets/DappWalletManager";
import { Listeners } from "../common";

export const WalletConnectListeners: Listeners[] = [
  {
    wallet: "walletconnect",
    event: "connect",
    acction: async ({ param1, strategy, setState }) => {
      const address = param1.params[0].accounts[0];
      DappWalletManager.AddressWallet = address;
      setState((old) => ({ ...old, address, isConnected: true, strategy }));
    },
  },
  {
    wallet: "walletconnect",
    event: "disconnect",
    acction: async ({ setState }) => {
      DappWalletManager.AddressWallet = "";
      setState((old) => ({ ...old, address: "", isConnected: false, strategy: null }));
    },
  },
  {
    wallet: "walletconnect",
    event: "session_update",
    acction: async ({ param0, param1, setState }) => {
      if (!param0) {
        const address = param1.params[0].accounts[0];
        DappWalletManager.AddressWallet = address;
        setState((old) => ({ ...old, address, isConnected: true }));
      } else {
        console.log("ERROR", param0);
      }
    },
  },
];
