import { createContext, useContext } from "react";

export type CurrencyContextValue = {
  selectedCurrency: "USD" | "ETH";
  setSelectedCurrency: (selectedCurrency: "USD" | "ETH") => void;
};

export const CurrencyContext = createContext<CurrencyContextValue>({
  selectedCurrency: "ETH",
  setSelectedCurrency: () => {},
});

export const useCurrencyContext = () => useContext(CurrencyContext);
