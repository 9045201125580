import React, { useState, useCallback } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { ModalDialog } from "src/commons";
import { LegalesText } from "src/screens/Public/Legales";

interface LegalesLinkProps {
  title: string;
}

export const LegalesLink = ({ title }: LegalesLinkProps) => {
  const [openModal, setOpenModal] = useState(false);
  const onHandleCloseModal = useCallback(() => setOpenModal(false), []);
  const onOpenModals = useCallback(() => setOpenModal(true), []);

  return (
    <Grid item container xs={12} alignItems="center" justify="flex-end">
      <Button onClick={onOpenModals} color="primary">
        <Typography style={{ textTransform: "none" }} variant="body2" color="primary">
          {title}
        </Typography>
      </Button>
      <ModalDialog title="Legales" open={openModal} onHandleClose={onHandleCloseModal}>
        <LegalesText />
      </ModalDialog>
    </Grid>
  );
};
