import React, { FunctionComponent, useState } from "react";
import { Paper, Tabs, Tab, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useRouteMatch, Link, Route, Redirect } from "react-router-dom";
import { primary, grey } from "../../../theme/colors";

const tabBarStyles = makeStyles(() => ({
  container: {
    boxShadow: "none",
    borderBottom: "1px solid lightGrey",
  },
  activeTab: {
    height: "10px",
    color: primary.lightBlue,
    textTransform: "none",
    fontSize: 14,
  },
  defaultTab: {
    height: "10px",
    color: grey.medium,
    textTransform: "none",
    fontSize: 14,
  },
  indicator: {
    backgroundColor: primary.lightBlue,
  },
  link: {
    textDecoration: "none",
  },
}));

interface Content {
  title: string;
  path: string;
  component: React.ReactNode;
  linkUrl: string;
}

interface NavBarProps {
  content: Array<Content>;
  noLink?: boolean;
}

// FIXME: este componente tiene serios bugs con los Links del router dom, no creo que sea necesario usar el router aqui
export const TabBarV2: FunctionComponent<NavBarProps> = ({ content, noLink }) => {
  const { url } = useRouteMatch();
  const [value, setValue] = useState(0);
  const handleChange = (_: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };
  const classes = tabBarStyles();
  const getStyle = (index: number) => (index === value ? classes.activeTab : classes.defaultTab);

  return (
    <div>
      <div>
        <Paper className={classes.container}>
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{ indicator: classes.indicator }}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="auto-scroll-tabs"
          >
            {content.map((item, index) => {
              const typography = (
                <Typography>
                  <span className={getStyle(index)}>{item.title}</span>
                </Typography>
              );
              const label = noLink ? (
                typography
              ) : (
                <Link to={`${url}/${item.linkUrl}`} className={classes.link}>
                  {typography}
                </Link>
              );
              return <Tab key={`a${item.linkUrl}`} label={label} />;
            })}
          </Tabs>
        </Paper>
        {content.map((item) => (
          <Route path={`${url}/${item.path}`} key={item.title}>
            {item.component}
          </Route>
        ))}
        <Redirect to={`${url}/${content[0].linkUrl}`} />
      </div>
    </div>
  );
};
