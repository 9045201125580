import React, { useCallback, useMemo, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Grid } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import { PromotionsView } from "src/app/views";
import { Product } from "src/app/models/business/Product";
import { useScrollToTopOnMount, Loading } from "src/commons";
import { useGeneralContext } from "src/context";
import { useBreadcrumbsCategory } from "src/commons/hooks/useBreadcrumbsCategory";
import { Skeleton } from "src/app/models/Site";
import { useSkeletonLibbyCall } from "src/app/business";
import { ecommerceConfig } from "../../../config/ecommerce";

export const Promotions = () => {
  useScrollToTopOnMount();
  const params = useParams<{ id: string }>();
  const { dispatch } = useGeneralContext();
  const history = useHistory();

  const { data, working } = useSkeletonLibbyCall<Skeleton>({ methodName: "getSkeleton", params: [params.id] });

  useEffect(() => {
    if (!params.id) {
      dispatch.setSearch("");
    }
  }, [params.id, dispatch]);

  const links = useBreadcrumbsCategory({ name: "home" });

  const path = useMemo(() => history.location.pathname, [history.location.pathname]);
  const search = useMemo(() => history.location.search, [history.location.search]);

  const onClickProduct = useCallback(
    (item: Product) =>
      history.push({
        pathname: `/product/${item.productId}`,
        search,
        state: {
          goBack: links,
          prevPath: path,
        },
      }),
    [links, history, path, search],
  );

  return (
    <>
      <Helmet>
        <title>{ecommerceConfig.general.title}</title>
        <meta name="description" content={ecommerceConfig.general.description} />
      </Helmet>
      <Container maxWidth="lg">
        <Grid container direction="column">
          <Grid style={{ width: "100%" }}>
            {working ? (
              <Loading />
            ) : data && !Array.isArray(data) ? (
              <PromotionsView onClick={onClickProduct} skeletonComponents={data.skeleton_components} />
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
