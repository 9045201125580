import { useCallback, useState, useEffect } from "react";
import { usePublicationFavoriteDAO } from "src/app/business";
import { PublicationFavorite, PublicationFavoriteInfo } from "src/app/models";
import { useRolesContext } from "src/context";
import { useHistory } from "react-router";

export const useFavoriteManage = () => {
  const publicationFavoriteDAO = usePublicationFavoriteDAO();
  const history = useHistory();
  const { userInfo } = useRolesContext();
  const [favorites, setFavorites] = useState<PublicationFavoriteInfo[]>([]);
  const [loading, setLoading] = useState(false);

  const getFavoriteData = useCallback(async () => {
    setLoading(true);
    const data = await publicationFavoriteDAO.getAll();
    setFavorites(data);
    setLoading(false);
  }, [publicationFavoriteDAO]);

  useEffect(() => {
    if (userInfo.isGuest) return;
    getFavoriteData();
  }, [userInfo, getFavoriteData]);

  const addToFavorite = useCallback(
    async (publication_id: string) => {
      if (userInfo.isGuest) {
        history.push("/login");
      }
      const to_save: PublicationFavorite = {
        publication_id,
        buyer_id: "",
        created_at: "",
      };
      await publicationFavoriteDAO.save(to_save);
      await getFavoriteData();
    },
    [userInfo, history, publicationFavoriteDAO, getFavoriteData],
  );

  const removeFromFavorite = useCallback(
    async (publication_id: string) => {
      if (userInfo.isGuest) {
        history.push("/login");
      }
      const to_remove = favorites.find((fav) => fav.publication.id === publication_id);
      if (to_remove) {
        await publicationFavoriteDAO.remove(to_remove);
        await getFavoriteData();
      }
    },
    [userInfo, favorites, history, publicationFavoriteDAO, getFavoriteData],
  );

  const numberOfLikes = useCallback(
    async (publication_id: string): Promise<number> => {
      try {
        setLoading(true);
        const { count = 0 } = await publicationFavoriteDAO.getCountFavorite(publication_id);
        return count;
      } catch (error) {
        console.log("error", error);
        return 0;
      } finally {
        setLoading(false);
      }
    },
    [publicationFavoriteDAO],
  );

  return { favorites, loading, addToFavorite, removeFromFavorite, numberOfLikes };
};
