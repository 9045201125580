export interface AccountInfoInitialValue {
  fullname: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export const accountInfoInitialValues: AccountInfoInitialValue = {
  fullname: "",
  email: "",
  password: "",
  confirmPassword: "",
};
export interface RegisterInitialValue {
  termsAndConditions: boolean;
  accountInfo: AccountInfoInitialValue;
}

export const registerInitialValue: RegisterInitialValue = {
  termsAndConditions: false,
  accountInfo: accountInfoInitialValues,
};

export interface CheckCodeInitialValue {
  checkCode: string;
}

export const checkCodeInitialValue: CheckCodeInitialValue = {
  checkCode: "",
};

export interface FormValues extends RegisterInitialValue {
  accountInfo: AccountInfoInitialValue;
}

export const ValuesRegister = {
  ...registerInitialValue,
  accountInfo: accountInfoInitialValues,
  checkCodeInfo: checkCodeInitialValue,
};
