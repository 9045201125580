import { routes } from "./routes";
import { MenuItem } from "../../../../commons/ui/menu";

export const searchMenu: MenuItem = {
  key: "privateSearch",
  basePath: "/search",
  enabled: true,
  icon: "List",
  text: "Search",
  children: routes,
};
