import { useState } from "react";
import { useRolesContext } from "src/context";
import { useWalletContext } from "src/context/WalletContext";
import { Product } from "../models/business/Product";
import { useBuyerWalletDAO } from "../business/BuyerWallet";
import { BuyerWallet, Publication } from "../models";

interface Status {
  error: boolean;
  msg: string;
}

const initialStatus: Status = {
  error: false,
  msg: "",
};

export const useIsOwnerOf = (pub: Product | Publication) => {
  const { userInfo } = useRolesContext();
  const { address, isConnected } = useWalletContext();
  if (userInfo.isGuest || !isConnected) return false;

  if (userInfo.id?.toLowerCase() === pub.attributes.ownerUserId?.toLowerCase() && address?.toLowerCase() === pub.attributes.tokenOwner?.toLowerCase())
    return true;
  return false;
};

export const useSaveWalletBuyer = async () => {
  const [status, setStatus] = useState(initialStatus);
  const { userInfo } = useRolesContext();
  const { address, isConnected } = useWalletContext();
  const buyerWalletDAO = useBuyerWalletDAO();

  if (!isConnected || userInfo.isGuest) return status;
  try {
    if (address && isConnected) {
      const wallets: BuyerWallet[] = await buyerWalletDAO.getBuyerWallets();
      if (!wallets.find((item) => item.wallet.toLowerCase() === address.toLowerCase())) {
        await buyerWalletDAO.save({ wallet: address });
      }
    }
  } catch (e) {
    console.log("Error", e);
    setStatus((old) => ({ ...old, error: true, msg: "Ya existe una cuenta con esa wallet asociada!" }));
  }
  return status;
};
