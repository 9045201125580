import React, { useContext } from "react";
import { RouterContext } from "./types";

export const Context = React.createContext<RouterContext>({
  config: [],
  metadata: {},
  setMetadata: () => {},
});

export const useRouterContext = () => useContext<RouterContext>(Context);
