import React from "react";
import { Grid, Typography, Box, useMediaQuery, Theme, Button } from "@material-ui/core";
import { Form, LogicAddress } from "src/screens/Public/Checkout/types";
import { FormCheckbox, FormCardExpiryDate } from "src/lib/templates";
import { useScrollToTopOnMount } from "src/commons";
import { orderBy } from "lodash";
import { CommonFormInput } from "../CommonFormInput";
import { DeliveryForm } from "../DeliveryForm";

export type CardPaymentInfoFormProps = {
  form: Form;
  onClickStep: () => void;
  isCredidCard: boolean;
  logicAddress: LogicAddress;
  useSummaryAddress: boolean;
  disableNext: boolean;
  onBackStep: () => void;
  cardNumberErrorValidation: boolean;
  cardNumberErrorMessageValidation: string;
  cardExpirationErrorValidation: boolean;
  cardExpirationErrorMessageValidation: string;
};

export const CardPaymentInfoForm = ({
  form,
  isCredidCard,
  cardNumberErrorValidation,
  cardNumberErrorMessageValidation,
  cardExpirationErrorValidation,
  cardExpirationErrorMessageValidation,
  useSummaryAddress,
  logicAddress,
  disableNext,
  onClickStep,
  onBackStep,
}: CardPaymentInfoFormProps) => {
  useScrollToTopOnMount();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <Box padding={isDownSm ? 2 : 3}>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <Box mb={1} paddingBottom={1} borderColor="other.stroke" borderBottom={isDownSm && "1px solid"}>
            <Typography variant="h5" color="textPrimary">
              Datos de la tarjeta
            </Typography>
          </Box>
        </Grid>
        <CommonFormInput<Form>
          form={form}
          name="cardInfo.cardHolder"
          label="Nombre del titular de la tarjeta"
          variant="standard"
          lg={12}
          md={12}
          sm={12}
          xs={12}
          autoComplete="cc-name"
        />

        <CommonFormInput<Form>
          form={form}
          name="cardInfo.cardNumber"
          label="Número de tarjeta"
          variant="standard"
          lg={12}
          md={12}
          sm={12}
          xs={12}
          error={cardNumberErrorValidation}
          helperText={cardNumberErrorMessageValidation}
          autoComplete="cc-number"
        />

        <Grid item xs={6} sm={6} md={3} lg={2}>
          <FormCardExpiryDate
            cardExpirationErrorValidation={cardExpirationErrorValidation}
            cardExpirationErrorMessageValidation={cardExpirationErrorMessageValidation}
            label="Vencimiento"
            nameMonth="cardInfo.cardExpiration.month"
            nameYear="cardInfo.cardExpiration.year"
            form={form}
          />
        </Grid>

        <CommonFormInput<Form>
          form={form}
          name="cardInfo.cardSecurityCode"
          label="Código de seguridad"
          variant="standard"
          lg={3}
          md={4}
          sm={6}
          xs={6}
          autoComplete="cc-csc"
          helperText="Código inválido."
          type="password"
        />
        {isCredidCard && (
          <Grid item container xs={12}>
            <Box mb={2} mt={2}>
              <Typography>Domicilio de resumen de su tarjeta de crédito</Typography>
            </Box>
            <Grid item xs={12}>
              <Box mb={useSummaryAddress ? 0 : 2}>
                <FormCheckbox
                  label={
                    <Typography variant="body2" color="inherit">
                      La dirección de facturación es la misma que la de envío
                    </Typography>
                  }
                  name="useDeliveryAddress"
                  form={form}
                />
              </Box>
            </Grid>

            {!useSummaryAddress && (
              <DeliveryForm
                noTitle
                searchCityValue={logicAddress.search}
                nameForm="summaryAddress"
                disableFooter
                disableNext
                invalidZidCode={logicAddress.validZipCode}
                onClickStep={onClickStep}
                onInputChangeCity={logicAddress.onInputChangeCity}
                form={form}
                onBackStep={onBackStep}
                loading={logicAddress.working}
                provinces={orderBy(logicAddress.provinces, "name")}
                cities={logicAddress.cities}
                selectedProvince={logicAddress.selectedProvince}
                isResponsable={logicAddress.isResponsable}
                zipCodeLength={logicAddress.zipCodeLength}
              />
            )}
          </Grid>
        )}

        {isDownSm && (
          <Grid item xs={12}>
            <Button fullWidth disabled={disableNext} size="large" variant="contained" color="primary" onClick={onClickStep}>
              Confirmar Compra
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
