import React, { createContext, useReducer, useContext, useMemo } from "react";
import { generalInitialState, generalReducer, GeneralState, SET_SEARCH, SET_TEST, SET_LINKS, SET_PAGINATE, SET_SORT_OPTION } from "./reducer";

export interface GeneralContextValue {
  generalState: GeneralState;
  dispatch: { [k: string]: (payload: any) => void };
}

export const GeneralContext = createContext<GeneralContextValue>({
  generalState: generalInitialState,
  dispatch: {},
});

interface GeneralContextProps {
  children: JSX.Element;
}

export const GeneralContextProvider = ({ children }: GeneralContextProps) => {
  const [generalState, dispatcher] = useReducer(generalReducer, generalInitialState);

  const dispatch = useMemo(
    () => ({
      // ejemplo
      [SET_SEARCH]: (payload: string) => dispatcher({ type: SET_SEARCH, payload }),
      [SET_TEST]: (payload: string) => dispatcher({ type: SET_TEST, payload }),
      [SET_LINKS]: (payload: string) => dispatcher({ type: SET_LINKS, payload }),
      [SET_PAGINATE]: (payload: string) => dispatcher({ type: SET_PAGINATE, payload }),
      [SET_SORT_OPTION]: (payload: string) => dispatcher({ type: SET_SORT_OPTION, payload }),
    }),
    [],
  );

  const contextValue = useMemo(() => ({ generalState, dispatch }), [generalState, dispatch]);

  return <GeneralContext.Provider value={contextValue}>{children}</GeneralContext.Provider>;
};

export const useGeneralContext = () => useContext<GeneralContextValue>(GeneralContext);
