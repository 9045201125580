import React, { useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Container, Grid } from "@material-ui/core";
import { CategoriesView } from "src/app/views";
import { useHistory, useParams } from "react-router";
import { Product } from "src/app/models/business/Product";
import { sortOptions, SEARCH_TYPES, useBreadcrumbs, useSearch, useFavoriteManage } from "src/commons";
import { usePublicationByZone } from "src/app/business/PublicationByZone/usePublicationByZone";
import { useCategory } from "src/app/business/Category/useCategory";
import { ZONE } from "src/app/const";
import { useGeneralContext } from "src/context";
import { useScrollToTopOnMount } from "src/commons/hooks/useScrollToTopUnMount";
import { isZone } from "./util";
import { Publication } from "../../../app/models/publication";
import { FeatureGroup } from "../../../app/models/FeatureGroup";
import { ecommerceConfig } from "../../../config/ecommerce";

const emptyArray: any[] = [];

export const Search = () => {
  useScrollToTopOnMount();
  const params = useParams<Record<string, string>>();
  const { data: publications = [], working } = usePublicationByZone(isZone(params.value) ? params.value : ZONE.ALL);
  const { data: categories } = useCategory();
  const { dispatch } = useGeneralContext();

  const publicationsFixed = useMemo<Publication[]>(
    () =>
      publications
        ? publications.map((pub: Publication) => ({
            ...pub,
            attributes: {
              ...pub.attributes,
              features:
                pub.attributes?.features?.map((group: FeatureGroup) => ({
                  ...group,
                  features: group.features || [],
                })) || [],
            },
          }))
        : [],
    [publications],
  );

  const {
    list,
    pagination,
    onChangePagination,
    filterContent,
    onAddFilter,
    onRemoveFilter,
    filters,
    sortOpenModal,
    setSortOpenModal,
    setSortFilter,
    sortFilter,
    freeShipment,
    setFreeShipment,
  } = useSearch({
    searchType: SEARCH_TYPES.all,
    publications: publicationsFixed || emptyArray,
    categoriesSource: categories || emptyArray,
    byZone: isZone(params.value),
  });

  useEffect(() => {
    if (params.value && !isZone(params.value)) {
      dispatch.setSearch(params.value);
    }
  }, [params.value, dispatch]);

  const history = useHistory();

  const links = useBreadcrumbs({
    links: [
      { label: "Inicio", url: "/" },
      {
        label: !params.value ? "" : isZone(params.value) ? "Ofertas" : `Busqueda: ${params.value}`,
      },
    ],
  });

  const onClickProduct = useCallback(
    (item: Product) =>
      history.push({
        pathname: `/product/${item.productId}`,
        state: {
          goBack: links,
        },
      }),
    [links, history],
  );

  const { favorites, addToFavorite, removeFromFavorite } = useFavoriteManage();

  return (
    <>
      <Helmet>
        <title>
          {ecommerceConfig.general.title} - {params.value || ecommerceConfig.general.defaultSearchText}
        </title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid>
          <Grid container>
            <Grid item style={{ width: "100%" }}>
              {!working && list ? (
                <CategoriesView
                  sortOptions={sortOptions}
                  sortOpenModal={sortOpenModal}
                  setSortOpenModal={setSortOpenModal}
                  setSortFilter={setSortFilter}
                  sortFilter={sortFilter}
                  products={list}
                  filterContent={filterContent}
                  selectedFilters={filters}
                  onClick={onClickProduct}
                  onAddFilter={onAddFilter}
                  onRemoveFilter={onRemoveFilter}
                  onChangePagination={onChangePagination}
                  pagination={pagination}
                  defaultSearchText={params.value && isZone(params.value) ? "Ofertas" : ecommerceConfig.general.defaultSearchText}
                  searchType={SEARCH_TYPES.all}
                  freeShipment={freeShipment}
                  setFreeShipment={setFreeShipment}
                  favorites={favorites}
                  onRemoveFavorite={(item) => removeFromFavorite(item.productId.toString())}
                  onAddFavorite={(item) => addToFavorite(item.productId.toString())}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
