/* eslint-disable */
import React from "react";

interface FacebookIconProps {
  width?: string | number;
  height?: string | number;
  color?: string;
  opacity?: string;
}

export const FacebookIcon = ({ width = 24, height = 24, color = "white" }: FacebookIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23 12.0699C23 5.7186 17.8513 0.569879 11.5 0.569879C5.14872 0.569879 0 5.7186 0 12.0699C0 
        17.8099 4.20538 22.5674 9.70312 23.4302V15.3941H6.7832V12.0699H9.70312V9.53629C9.70312 6.6541
         11.42 5.06207 14.0468 5.06207C15.305 5.06207 16.6211 5.28668 16.6211 5.28668V8.11675H15.171C13.7424
          8.11675 13.2969 9.00322 13.2969 9.91266V12.0699H16.4863L15.9765 15.3941H13.2969V23.4302C18.7946 
          22.5674 23 17.8099 23 12.0699Z"
        fill={color}
      />
    </svg>
  );
};
