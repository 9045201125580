import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { Grid, Container, useMediaQuery, Theme, Card } from "@material-ui/core";
import { ethers } from "ethers";
import { Helmet } from "react-helmet";
import { Publication, BuyerWallet } from "src/app/models";
import { abi2 } from "src/platform/DappWallets/Strategies/const/Abi";
import { imageCarouselType, Loading, useScrollToTopOnMount, AnyObject } from "src/commons";
import { getPricePublication } from "src/context/CartContext/utils";
import { useBuyerWalletDAO } from "src/app/business/BuyerWallet";
import { ETHicon } from "src/assets/images/svgs";
import { useRolesContext } from "src/context";
import { DummyAvatar } from "src/assets/images";
import { useCurrencyContext } from "src/context/CurrencyContext";
import { CONTRACT_ADDRESS, ONESTAR_ADDRESS } from "src/config";
import { useWalletContext } from "src/context/WalletContext";
import { EthereumPrice } from "src/app/services";
import { shortenWalletAddress } from "src/utils";
import { formatUnits } from "ethers/lib/utils";
import { useIsOwnerOf } from "src/app/hooks/useIsOwnerOf";
import { AppBreadcrumbsApp } from "../AppBreadcrumbs";
import { ProductNFTViewMobile, ProductNFTViewWeb } from "./components";

interface ProductNFTViewProps {
  dataPublication?: Publication;
  newLinks: any;
  working: boolean;
  stock: any;
  workingStock: boolean;
  quantity: number;
  imagesCarousel: Array<imageCarouselType>;
  enableStock: boolean;
  availableQuantity: number;
  onBuyClick: () => void;
}

export const ProductNFTView = ({ dataPublication, newLinks, working }: ProductNFTViewProps) => {
  const [salesHistory, setSalesHistory] = useState<any>([]);
  useScrollToTopOnMount();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));
  const itBelongsOfOwner = useIsOwnerOf(dataPublication || ({} as Publication));
  const refs = useRef(null);
  const { selectedCurrency } = useCurrencyContext();
  const { selectedRole } = useRolesContext();
  const { strategy } = useWalletContext();
  const buyerWalletDAO = useBuyerWalletDAO();

  const getData = useCallback(async () => {
    const wallets: BuyerWallet[] = await buyerWalletDAO.getBuyerWallets();
    const res = await strategy?.getContract();
    const iface = new ethers.utils.Interface(abi2);
    const filter = {
      fromBlock: 0,
      toBlock: "latest",
      address: CONTRACT_ADDRESS,
    };
    const search = await res?.provider.getLogs(filter);
    if (search?.length) {
      const historyArray: any = [];
      search.map((log: any) => {
        const data = iface.parseLog(log);
        const value = data.args.tokenId?._hex;
        if (value !== undefined) {
          const number = parseInt(value, 16);
          if (number.toString() === dataPublication?.attributes.tokenId && data.name === "Transfer") {
            const isMintedEvent = parseInt(data.args.from, 16) === 0;
            const isOneStarAddressFrom = data.args.from.toLowerCase() === ONESTAR_ADDRESS.toLowerCase();
            const isOneStarAddressTo = data.args.to.toLowerCase() === ONESTAR_ADDRESS.toLowerCase();
            const fromOwnerName = wallets.filter((item: any) => item.wallet.toLowerCase() === data.args.from.toLowerCase());
            const toOwnerName = wallets.filter((item: any) => item.wallet.toLocaleLowerCase() === data.args.to.toLowerCase());
            historyArray.push({
              event: isMintedEvent ? "Minted" : "Venta",
              from: isOneStarAddressFrom ? "ONESTAR" : fromOwnerName.length ? fromOwnerName[0].nick : shortenWalletAddress(data.args.from),
              to: isOneStarAddressTo ? "ONESTAR" : toOwnerName.length ? toOwnerName[0].nick : shortenWalletAddress(data.args.to),
              price: "1500 U$D",
              date: "07/12/2021",
              image: DummyAvatar,
            });
          }
        }
        return data;
      });
      setSalesHistory(historyArray);
    }
  }, [dataPublication, strategy, buyerWalletDAO]);

  useEffect(() => {
    getData();
  }, [getData]);

  const loadPlayer = useCallback(() => {
    (window as AnyObject).playerContainer = refs.current;
    (window as AnyObject).vdo.add({
      otp: dataPublication?.attributes.videoInfo.otp,
      playbackInfo: dataPublication?.attributes.videoInfo.playbackInfo,
      theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
      container: (window as AnyObject).playerContainer,
    });
  }, [dataPublication]);

  useEffect(() => {
    if (working || !dataPublication || !itBelongsOfOwner) return;
    if ((window as AnyObject).vdo) return loadPlayer();
    const playerScript = document.createElement("script");
    playerScript.src = "https://player.vdocipher.com/playerAssets/1.6.10/vdo.js";
    document.body.appendChild(playerScript);
    playerScript.addEventListener("load", () => loadPlayer());
  }, [dataPublication, working, itBelongsOfOwner, loadPlayer]);

  const priceUsd = useMemo(() => {
    if (!dataPublication) return "0.00";
    return formatUnits(getPricePublication(dataPublication)) !== "0.0"
      ? `$ ${EthereumPrice.getPriceBy({ value: formatUnits(getPricePublication(dataPublication)) })}`
      : "No está a la venta";
  }, [dataPublication]);

  const priceEth = useMemo(() => {
    if (!dataPublication) return "0.0";
    return formatUnits(getPricePublication(dataPublication)) !== "0.0" ? (
      <>
        <ETHicon width="18" color="#95F035" /> {formatUnits(getPricePublication(dataPublication))}
      </>
    ) : (
      "No está a la venta"
    );
  }, [dataPublication]);

  const quantity = dataPublication?.attributes.videoInfo.picture.length || 1;
  const imgUrl = dataPublication?.attributes.videoInfo.picture[quantity - 1].url;
  const tokenId = dataPublication?.attributes.tokenId || "";
  const tagsFilter = dataPublication?.attributes?.tags || [];

  return (
    <>
      {dataPublication && (
        <Helmet>
          <title>{dataPublication.product}</title>
          <meta name="description" content={dataPublication?.attributes.description} />
        </Helmet>
      )}
      <Container maxWidth="lg" disableGutters={isDownXs}>
        <Grid item xs={12}>
          <AppBreadcrumbsApp links={newLinks} msgReturn="Ver más" />
        </Grid>
        <Grid>
          {working ? (
            <Loading />
          ) : dataPublication ? (
            isDownSm ? (
              <Card>
                <ProductNFTViewMobile
                  dataPublication={dataPublication}
                  selectedRole={selectedRole}
                  itBelongsOfOwner={itBelongsOfOwner}
                  refs={refs}
                  imgUrl={imgUrl}
                  selectedCurrency={selectedCurrency}
                  tokenId={tokenId}
                  salesHistory={salesHistory}
                  tagsFilter={tagsFilter}
                  priceEth={priceEth}
                  priceUsd={priceUsd}
                />
              </Card>
            ) : (
              <Card>
                <ProductNFTViewWeb
                  dataPublication={dataPublication}
                  selectedRole={selectedRole}
                  itBelongsOfOwner={itBelongsOfOwner}
                  refs={refs}
                  imgUrl={imgUrl}
                  selectedCurrency={selectedCurrency}
                  tokenId={tokenId}
                  salesHistory={salesHistory}
                  tagsFilter={tagsFilter}
                  priceEth={priceEth}
                  priceUsd={priceUsd}
                />
              </Card>
            )
          ) : (
            <div>No existe este producto.</div>
          )}
        </Grid>
      </Container>
    </>
  );
};
