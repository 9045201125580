import React from "react";
import { Box } from "@material-ui/core";
import { TextField, TextFieldProps } from "./TextField";

export type InputProps = TextFieldProps & {
  positionLabel?: "inner" | "outer";
};

export const Input = (props: InputProps): JSX.Element => {
  const { label, positionLabel, ...rest } = props;

  return (
    <div>
      {positionLabel === "outer" && <Box mb="10px">{label}</Box>}
      <TextField {...rest} label={positionLabel === "inner" && label} />
    </div>
  );
};
