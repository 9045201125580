import React, { useEffect } from "react";
import { Avatar, Container, Grid, Button, Theme, Typography, useMediaQuery, CircularProgress, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import ShareIcon from "@material-ui/icons/Share";
import { Helmet } from "react-helmet";
import { profileStyles as useStyles } from "src/app/views/styles";
import { usePurchasedItemsLibbyCall } from "src/app/business/PurchasedItems";
import { useRolesContext } from "src/context";
import { ContentCopy } from "src/assets/images/svgs";
import { Publication } from "src/app/models";
import { shortenWalletAddress } from "src/utils";
import { useWalletContext } from "src/context/WalletContext";
import { ProfileAvatarEmpty, ProfileHeader } from "../../../assets/images";
import { PurchasedItemsTabs } from "./component/PurchasedItems";

export const Profile = () => {
  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));
  const { address } = useWalletContext();
  const { userInfo } = useRolesContext();

  const { data, working, recall } = usePurchasedItemsLibbyCall<Publication[]>({
    methodName: "getItems",
    params: [],
    noAutoCall: true,
  });
  const classes = useStyles();

  useEffect(() => {
    if (address && !data) {
      recall(address);
    }
  }, [address, data, recall]);

  return (
    <>
      <Helmet>
        <title>User Profile</title>
        <meta name="description" content="User profile" />
      </Helmet>
      <img alt="Header" src={ProfileHeader} style={{ width: "100%" }} />
      <Container maxWidth="lg" disableGutters={isDownXs}>
        <Grid container justify="space-between">
          <Grid item className={classes.profileInfo}>
            <Avatar src={ProfileAvatarEmpty} className={classes.avatar} />
            <Typography variant="h4" className={classes.userName}>
              {userInfo.name.split(" ")[0]}
            </Typography>
            <Typography variant="h6" className={classes.publicKey}>
              {shortenWalletAddress(address)}
              <IconButton>
                <ContentCopy />
              </IconButton>
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" className={classes.actionButtons}>
              <EditIcon className={classes.icon} />
            </Button>
            <Button variant="contained" className={classes.actionButtons}>
              <SettingsIcon className={classes.icon} />
            </Button>
            <Button variant="contained" className={classes.actionButtons}>
              <ShareIcon className={classes.icon} />
            </Button>
          </Grid>
        </Grid>
        {working ? <CircularProgress /> : <PurchasedItemsTabs data={[...(data || [])]} />}
      </Container>
    </>
  );
};
