import React, { useEffect, useState, useMemo, ChangeEvent, useCallback } from "react";
import { PersonalCreditInfoForm } from "src/app/views";
import { useLocationLibbyCall, useLocationDAO } from "src/app/business";
import { State, City } from "src/app/models";
import { useDebounce } from "src/commons";
import { orderBy } from "lodash";
import { PROVINCES } from "src/app/const";
import { useCheckoutFormContext, useCreditAddressLogic } from "src/screens/Public/Checkout/hooks";
import { PaymentInitialValue, alumnInitialValue, AlumnInitialValue } from "../../initialValues";
import { ValuesForm, FormProps } from "../../types";

interface Values extends ValuesForm {
  alumnInfo: AlumnInitialValue<Partial<File>>;
  paymentMethod: PaymentInitialValue;
}

export const AlumnInfoForm = ({ onBackStep }: FormProps) => {
  const { form, values, errors, handleSubmit } = useCheckoutFormContext();
  const [cities, setCities] = useState<City[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const locationDAO = useLocationDAO();
  const { data, working } = useLocationLibbyCall<State[]>({ methodName: "getStates" });
  useCreditAddressLogic("alumnInfo");

  const valuesAll = useMemo(
    () =>
      ({
        alumnInfo: alumnInitialValue,
        ...values,
      } as Values),
    [values],
  );

  const provinces = useMemo(() => {
    let provincesFiltered: State[] = data?.filter((item) => item.id.toString() === PROVINCES.CATAMARCA.toString()) || [];
    if (valuesAll.useDeliveryAddress) {
      provincesFiltered = [...provincesFiltered, valuesAll.deliveryInfo.province];
    }
    return provincesFiltered;
  }, [data, valuesAll.useDeliveryAddress, valuesAll.deliveryInfo.province]);

  const [search, setSearch] = useState(valuesAll.alumnInfo.city?.name || "");
  const searchDebunce = useDebounce(search, 600);

  useEffect(() => {
    if (valuesAll.useDeliveryAddress && search !== valuesAll.deliveryInfo.city.name) {
      setSearch(valuesAll.deliveryInfo.city.name);
    } else if (!valuesAll.useDeliveryAddress && search === valuesAll.deliveryInfo.city.name) {
      setSearch("");
    }
  }, [valuesAll.useDeliveryAddress, valuesAll.deliveryInfo.city.name, search]);

  const selectedProvince = useMemo(() => valuesAll.alumnInfo.province?.id !== "", [valuesAll.alumnInfo.province?.id]);

  const zitCode = useMemo(() => valuesAll.alumnInfo.zipCode || "", [valuesAll.alumnInfo.zipCode]);

  const onInputChangeCity = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      setSearch(value);
    },
    [setSearch],
  );

  useEffect(() => {
    if ((valuesAll?.alumnInfo?.city?.name?.length > 0 && valuesAll?.alumnInfo?.city?.name !== search) || valuesAll?.alumnInfo?.city === null) {
      const copyValuesForm = { ...valuesAll };
      copyValuesForm.alumnInfo.city = alumnInitialValue.city;
      form.reset(copyValuesForm);
    }
  }, [search, form, valuesAll]);

  useEffect(() => {
    if (selectedProvince && searchDebunce && searchDebunce !== valuesAll.alumnInfo.city?.name) {
      setLoading(true);
      (async () => {
        const city = await locationDAO.getCityByState(valuesAll.alumnInfo.province?.id, searchDebunce);
        setCities(city);
        setLoading(false);
      })();
    }
  }, [selectedProvince, locationDAO, valuesAll.alumnInfo.province, searchDebunce, valuesAll.alumnInfo.city]);

  const invalidZipCode = useMemo(() => !!zitCode && !valuesAll.alumnInfo.city?.zip?.includes(Number(zitCode)), [valuesAll.alumnInfo.city, zitCode]);

  return (
    <PersonalCreditInfoForm
      title="Datos del solicitante"
      nameForm="alumnInfo"
      nameDelivery="alumnInfo"
      loading={working}
      invalidZidCode={invalidZipCode}
      loadingCities={loading}
      disableNext={Object.keys(errors as any).length !== 0}
      onClickStep={handleSubmit}
      onBackStep={onBackStep}
      form={form}
      provinces={orderBy(provinces, "name")}
      selectedProvince={selectedProvince}
      cities={cities}
      onInputChangeCity={onInputChangeCity}
      zipCodeLength={zitCode.length || 0}
      searchCityValue={search}
      useSameDeliveryAddress={values.useDeliveryAddress}
      showCheckbox
    />
  );
};
