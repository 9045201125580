import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { RegisterInfo, LoginStatusResponse } from "src/app/models";
import { useAccountDAO } from "src/app/business";
import { userGuest, validateUser, SessionChangeEmitter } from "src/platform/libby/session";
import { FormRegisterValues } from "../types";

export const useSendRegister = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const accounDAO = useAccountDAO();

  const sendRegister = async (values: FormRegisterValues) => {
    try {
      const toRegister: RegisterInfo = {
        email: values.accountInfo.email,
        password: values.accountInfo.password,
        document_number: "1234567",
        document_type: "DNI",
        name: values.accountInfo.fullname,
        lastname: values.accountInfo.fullname,
        oauth_id: values.token_id,
        oauth_provider: values.provider,
        token_id: values.token_id,
      };
      await accounDAO.register(toRegister);
      enqueueSnackbar("Registro completado!", { variant: "success" });
      let Session: LoginStatusResponse = { isGuest: false, user: userGuest };
      try {
        Session = await accounDAO.login({ email: toRegister.email, username: toRegister.email, password: toRegister.password });
        if (Session.isGuest || !validateUser(Session.user)) {
          enqueueSnackbar("Usuario o clave incorrectos", {
            variant: "error",
          });
        } else {
          history.push("/register/success");
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar("Usuario o clave incorrectos", {
          variant: "error",
        });
      } finally {
        const { user } = Session;
        const isLogged: boolean = !!user && user.isGuest === false;
        SessionChangeEmitter.emitChange({ isLogged, user });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { sendRegister };
};
