import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

export const deliveryFormStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      height: 288,
    },
  },
}));
