import React, { useCallback, useEffect } from "react";

import {
  Typography,
  Grid,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { useForm, FormConfig, useField } from "react-final-form-hooks";
import { getErrorAndHelperTextNoTranslation } from "src/utils/materialHelpers";
import { ETHicon } from "src/assets/images/svgs";
import { getPricePublication } from "src/context/CartContext/utils";
import { ecommerceConfig } from "src/config/ecommerce";
import { normalizePublications, ValidatorRule, ValidatorType, makeValidator, Validator } from "src/utils";
import CloseIcon from "@material-ui/icons/Close";
import { Publication } from "src/app/models";
import { formatUnits } from "ethers/lib/utils";
import { NftCard } from "../NftCard";

const dummyFunc = () => {};

interface FormDialogType extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  onConfirm: Function;
  customTheme: string | null;
  isValidNewMessage: boolean;
  item?: Publication;
}
const regex = new RegExp("^[0-9. ]+$");
const custonValidate: Validator = {
  validator: (value: string) => !regex.test(value),
  message: "Ingrese un valor valido!",
};

const custonValidate2: Validator = {
  validator: (value: string) => Number(value) === 0,
  message: "Ingrese un valor valido!",
};

const validations: ValidatorRule[] = [{ key: "price", validators: [custonValidate, custonValidate2, ValidatorType.REQUIRED] }];

const validate = makeValidator(validations);

export const SalesNFTModalForm = ({
  open = false,
  onCancel = dummyFunc,
  onConfirm = dummyFunc,
  customTheme = null,
  isValidNewMessage,
  item,
}: FormDialogType) => {
  const onSubmit = useCallback(
    async ({ price }: { price: string }) => {
      onConfirm({
        price,
      });
    },
    [onConfirm],
  );

  const { form, handleSubmit, errors } = useForm({
    initialValues: { price: "" },
    onSubmit,
    validate,
  });

  const price = useField("price", form);

  const handleReset = useCallback(() => {
    form.reset();
    form.resetFieldState("price");
  }, [form]);

  useEffect(() => {
    handleReset();
  }, [isValidNewMessage, handleReset]);

  const onCancelModal = useCallback(() => {
    onCancel();
    handleReset();
  }, [onCancel, handleReset]);

  const errorPrice = getErrorAndHelperTextNoTranslation(price);

  const dialog = item ? (
    <Dialog open={open} aria-labelledby="draggable-dialog-title" onClose={onCancelModal} fullWidth maxWidth="md">
      <DialogTitle style={{ padding: "24px 48px 0px 48px" }}>
        <Box borderBottom="1px solid #DADBE4" flexDirection="row" display="flex" width="100%" justifyContent="space-between" alignItems="center">
          <Box display="flex" height="100%" alignItems="center">
            <Typography variant="h5">Pon éste item a la venta</Typography>
          </Box>
          <Box onClick={onCancelModal}>
            <IconButton edge="end">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent style={{ padding: "8px 48px 24px 48px" }}>
        <DialogContentText>
          <form noValidate onSubmit={handleSubmit}>
            <Grid container direction="row">
              <Grid item xs={6}>
                <Typography variant="subtitle1" color="textPrimary">
                  Preview
                </Typography>
                <Box pt={1} pb={2}>
                  <NftCard data={normalizePublications([item])[0]} enableStock loading={false} onClick={() => {}} />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box pt={1} pb={2}>
                  <TextField
                    {...price.input}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ETHicon color="white" />
                        </InputAdornment>
                      ),
                    }}
                    id="standard-basic"
                    label="Precio (ETH)"
                    error={errorPrice.error}
                    helperText={errorPrice.error ? errorPrice.helperText : `Precio anterior: ${formatUnits(getPricePublication(item))} Eth`}
                  />
                </Box>
              </Grid>

              <Grid item xs={2} container justify="flex-end" alignItems="flex-end">
                <Box
                  mt={3}
                  disabled={Object.keys(errors as any).length > 0}
                  component={Button}
                  {...ecommerceConfig.ecommerceTypography.buttonText}
                  borderRadius={40}
                  bgcolor={ecommerceConfig.ecommercePalette.materialUi.primary.light}
                  color="primary.dark"
                  padding="12px 48px"
                  type="submit"
                >
                  Publicar
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  ) : (
    <></>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
