import { useCallback } from "react";
import { usePublicationByZone } from "./usePublicationByZone";
import { usePublicationNameByZone } from "./usePublicationNameByZone";
import { Publication } from "../../models";
import { LibbyCallReturn } from "../../../lib/libby/hooks";

export const usePublicationZoneName = (zone: string): LibbyCallReturn<{ publications: Publication[]; name: string }> => {
  const { data: publications = [], working: wPublications, recall: recallPublication } = usePublicationByZone(zone);
  const { data: nameZone = "", working: wNameZone, recall: recallNameZone } = usePublicationNameByZone(zone);

  const recall = useCallback(
    async (...args: any[]) => {
      const publication = await recallPublication(args);
      const name = await recallNameZone(args);
      return { publications: publication, name };
    },
    [recallPublication, recallNameZone],
  );

  return { data: { publications, name: nameZone }, working: wPublications || wNameZone, recall };
};
