import { createContext, useContext } from "react";
import { DappWalletStrategy } from "src/platform/DappWallets/DappWalletStrategy";

export type WalletContextValue = {
  address: string;
  isConnected: boolean;
  strategy: DappWalletStrategy | null;
  error: boolean;
  msg: string;
};

export const WalletContext = createContext<WalletContextValue>({
  address: "",
  isConnected: false,
  strategy: null,
  error: false,
  msg: "",
});

export const useWalletContext = () => useContext(WalletContext);
