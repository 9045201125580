/* eslint-disable */
/* eslint max-len: ["error", { "ignorePattern": "^\\s*var\\s.+=\\s*require\\s*\\(", "code": 1000 }] */
import * as React from "react";

interface MainLogoProps {
  width?: string;
  height?: string;
}

export const MainLogo = ({ width = "162", height = "25" }: MainLogoProps) => (
  <svg
    width={width}
    height={height}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 491.1 72.9"
    enableBackground="new 0 0 491.1 72.9"
    xmlSpace="preserve"
    id="store-icon"
  >
    <style type="text/css">
      {`.st0{fill:#07D3ED;}
  .st1{fill:#0B006D;}`}
    </style>
    <g id="Capa_2_00000083797798362304826840000005233352452460399238_">
      <g id="Capa_1-2">
        <path
          className="st0"
          d="M42.5,2.2v55.6h-9v-5.2C32,54.6,30,56.1,27.8,57c-2.4,1-4.9,1.5-7.5,1.4c-3.6,0-7.2-0.8-10.4-2.6
              c-3.1-1.7-5.6-4.2-7.2-7.2c-1.8-3.3-2.7-7-2.6-10.8c-0.1-3.7,0.8-7.4,2.6-10.7c1.7-3,4.2-5.5,7.2-7.2c3.2-1.7,6.8-2.6,10.4-2.5
              c2.5,0,5,0.4,7.3,1.4c2.2,0.9,4.1,2.3,5.6,4.1V2.2H42.5z M27.4,48.8c1.8-1,3.3-2.6,4.3-4.4c1.1-2,1.6-4.3,1.6-6.6
              c0.1-2.3-0.5-4.6-1.6-6.6c-1-1.8-2.5-3.4-4.3-4.4c-1.9-1-3.9-1.6-6.1-1.5c-2.1,0-4.2,0.5-6.1,1.5c-1.8,1-3.3,2.6-4.3,4.4
              c-1.1,2-1.6,4.3-1.6,6.6c-0.1,2.3,0.5,4.6,1.6,6.6c1,1.8,2.5,3.4,4.3,4.4c1.9,1,4,1.6,6.1,1.5C23.5,50.4,25.6,49.9,27.4,48.8z"
        />
        <path
          className="st0"
          d="M91.5,40.9H60.1c0.5,2.8,2,5.3,4.3,7c2.5,1.8,5.6,2.7,8.7,2.6c4.1,0.1,8-1.4,10.9-4.4l5,5.8
              c-1.8,2.2-4.2,3.9-6.8,4.9c-3,1.1-6.1,1.7-9.3,1.7c-4,0.1-8-0.8-11.6-2.6c-3.2-1.6-5.9-4.2-7.8-7.3c-1.9-3.2-2.8-6.9-2.7-10.6
              c-0.1-3.7,0.9-7.3,2.7-10.5c1.7-3.1,4.3-5.6,7.4-7.3c3.3-1.8,6.9-2.7,10.6-2.6c3.7-0.1,7.3,0.9,10.5,2.6c3,1.7,5.5,4.2,7.2,7.3
              c1.8,3.3,2.7,7.1,2.6,10.8C91.7,38.7,91.6,39.7,91.5,40.9z M63.8,27.5c-2.1,1.8-3.4,4.3-3.8,7.1h22.7c-0.3-2.7-1.6-5.2-3.7-7
              c-2.1-1.8-4.9-2.8-7.7-2.7C68.7,24.8,66,25.8,63.8,27.5z"
        />
        <path
          className="st1"
          d="M124,55.7c-1.2,0.9-2.5,1.6-4,2c-1.6,0.5-3.3,0.7-5,0.7c-4.4,0-7.8-1.2-10.2-3.5s-3.6-5.6-3.6-10V25.6h-6.6
              v-7.5h6.6V9h9.4v9.1h10.7v7.5h-10.7v19c-0.1,1.6,0.4,3.2,1.4,4.5c1.1,1.1,2.6,1.6,4.1,1.5c1.9,0.1,3.7-0.5,5.2-1.7L124,55.7z"
        />
        <path
          className="st1"
          d="M167.1,40.9h-31.4c0.5,2.8,2,5.3,4.3,7c2.5,1.8,5.6,2.7,8.6,2.6c4.1,0.1,8-1.4,10.9-4.3l5,5.8
              c-1.8,2.2-4.2,3.8-6.8,4.9c-6.8,2.5-14.4,2.1-20.9-1c-3.2-1.6-5.9-4.2-7.8-7.3c-1.8-3.2-2.8-6.9-2.7-10.6c0-3.7,0.9-7.3,2.7-10.5
              c1.7-3.1,4.3-5.6,7.4-7.3c3.3-1.8,6.9-2.7,10.6-2.6c3.7-0.1,7.3,0.8,10.5,2.6c3,1.7,5.5,4.2,7.2,7.3c1.8,3.3,2.7,7.1,2.6,10.8
              C167.2,38.7,167.2,39.7,167.1,40.9z M139.4,27.5c-2.1,1.8-3.4,4.3-3.8,7.1h22.7c-0.3-2.7-1.6-5.2-3.7-7c-2.1-1.8-4.9-2.8-7.6-2.7
              C144.2,24.8,141.5,25.8,139.4,27.5z"
        />
        <path
          className="st1"
          d="M182.7,55.8c-3.2-1.7-5.9-4.2-7.7-7.3c-1.9-3.2-2.8-6.9-2.8-10.6c-0.1-3.7,0.9-7.4,2.8-10.6
              c1.8-3.1,4.5-5.6,7.6-7.3c3.5-1.8,7.3-2.7,11.2-2.6c3.6-0.1,7.2,0.7,10.4,2.4c2.9,1.5,5.3,3.9,6.7,6.9l-7.2,4.2
              c-1-1.7-2.5-3.2-4.3-4.2c-4.7-2.4-10.5-1.6-14.4,2c-2.4,2.4-3.6,5.7-3.4,9.1c-0.2,3.4,1.1,6.7,3.4,9.1c3.9,3.6,9.7,4.4,14.4,2
              c1.8-1,3.3-2.4,4.3-4.2L211,49c-1.5,3-3.9,5.4-6.8,7c-3.2,1.7-6.7,2.5-10.3,2.4C190,58.5,186.1,57.6,182.7,55.8z"
        />
        <path
          className="st1"
          d="M253.1,21.8c3,3,4.6,7.3,4.6,13.1v22.9h-9.4V36.1c0-3.5-0.8-6.1-2.5-7.9c-1.8-1.8-4.4-2.8-7-2.7
              c-3-0.2-6,1-8.2,3.1c-2,2.1-3,5.1-3,9v20.2h-9.4v-40h8.9V23c1.6-1.9,3.6-3.3,5.9-4.2c2.5-1,5.2-1.5,7.9-1.4
              C246.1,17.4,250.1,18.9,253.1,21.8z"
        />
        <path
          className="st1"
          d="M276.3,55.8c-3.2-1.7-5.8-4.2-7.6-7.3c-1.8-3.2-2.8-6.9-2.7-10.6c-0.1-3.7,0.9-7.4,2.7-10.6
              c1.8-3.1,4.4-5.6,7.6-7.3c6.9-3.5,15.1-3.5,22,0c3.2,1.7,5.8,4.2,7.6,7.3c1.9,3.2,2.8,6.9,2.7,10.6c0.1,3.7-0.9,7.4-2.7,10.6
              c-1.8,3.1-4.4,5.6-7.6,7.3C291.4,59.3,283.2,59.3,276.3,55.8L276.3,55.8z M295.8,46.9c2.3-2.4,3.5-5.7,3.4-9.1
              c0.2-3.4-1.1-6.6-3.4-9.1c-2.2-2.3-5.3-3.5-8.5-3.5c-3.2-0.1-6.3,1.2-8.5,3.5c-2.3,2.5-3.5,5.7-3.3,9.1c-0.1,3.3,1.1,6.6,3.3,9.1
              c2.2,2.3,5.3,3.6,8.5,3.5C290.5,50.5,293.6,49.2,295.8,46.9z"
        />
        <path className="st1" d="M316.9,2.2h9.4v55.6h-9.4V2.2z" />
        <path
          className="st1"
          d="M344.8,55.8c-3.2-1.7-5.8-4.2-7.6-7.3c-1.8-3.2-2.8-6.9-2.7-10.6c-0.1-3.7,0.9-7.4,2.7-10.6
              c1.8-3.1,4.4-5.6,7.6-7.3c6.9-3.5,15.1-3.5,22,0c3.2,1.7,5.8,4.2,7.6,7.3c1.9,3.2,2.8,6.9,2.7,10.6c0.1,3.7-0.9,7.4-2.7,10.6
              c-1.8,3.1-4.4,5.6-7.6,7.3C359.9,59.3,351.7,59.3,344.8,55.8L344.8,55.8z M364.3,46.9c2.3-2.4,3.5-5.7,3.4-9.1
              c0.1-3.4-1.1-6.6-3.4-9.1c-2.2-2.3-5.3-3.5-8.5-3.5c-3.2-0.1-6.3,1.1-8.5,3.5c-2.3,2.5-3.5,5.7-3.3,9.1c-0.1,3.3,1.1,6.6,3.3,9.1
              c2.2,2.3,5.3,3.6,8.5,3.5C359,50.5,362.1,49.2,364.3,46.9L364.3,46.9z"
        />
        <path
          className="st1"
          d="M425.1,17.8v34c0,14.1-7.2,21.1-21.6,21.1c-3.7,0-7.4-0.5-11-1.5c-3.1-0.8-6-2.3-8.6-4.3l4.2-7
              c2,1.6,4.2,2.7,6.6,3.5c2.6,0.9,5.4,1.3,8.2,1.3c4.4,0,7.6-1,9.7-3c2-2,3.1-5,3.1-9.2v-2.1c-1.6,1.8-3.6,3.1-5.9,4
              c-2.4,0.9-5,1.4-7.6,1.3c-3.6,0-7.1-0.8-10.3-2.5c-3-1.6-5.5-3.9-7.3-6.9c-1.8-3.1-2.7-6.6-2.7-10.1c-0.1-3.5,0.8-7,2.7-10.1
              c1.7-2.9,4.3-5.2,7.3-6.8c3.2-1.6,6.7-2.5,10.3-2.4c2.7,0,5.4,0.5,7.9,1.4c2.3,0.9,4.4,2.4,6,4.3v-5.3H425.1z M412.5,44.9
              c2.3-2.1,3.5-5.2,3.4-8.3c0.1-3.1-1.1-6.1-3.4-8.2c-5.1-4.2-12.5-4.2-17.5,0c-2.3,2.1-3.5,5.1-3.4,8.2c-0.1,3.1,1.1,6.1,3.4,8.3
              C400,49.2,407.4,49.2,412.5,44.9z"
        />
        <path
          className="st1"
          d="M437.1,9.6c-1.1-1-1.8-2.5-1.7-4c0-1.5,0.6-3,1.7-4c1.2-1.1,2.7-1.7,4.3-1.6c1.6,0,3.1,0.5,4.3,1.5
              c1.1,1,1.8,2.4,1.7,3.9c0,1.6-0.6,3.1-1.7,4.2c-1.1,1.1-2.7,1.7-4.3,1.7C439.8,11.3,438.3,10.7,437.1,9.6z M436.7,17.8h9.3v40
              h-9.4L436.7,17.8z"
        />
        <path
          className="st1"
          d="M486.4,21.6c3.1,2.8,4.7,7.1,4.7,12.8v23.5h-8.8V53c-1.2,1.8-2.9,3.2-4.9,4c-2.4,1-5.1,1.5-7.7,1.4
              c-2.7,0.1-5.4-0.5-7.9-1.5c-2.1-0.9-3.9-2.4-5.2-4.3c-1.2-1.8-1.9-4-1.8-6.2c-0.1-3.4,1.4-6.6,4-8.7c2.7-2.2,6.9-3.3,12.6-3.3
              h10.3v-0.6c0.2-2.4-0.8-4.8-2.5-6.4c-1.7-1.5-4.2-2.2-7.5-2.2c-2.2,0-4.5,0.3-6.6,1c-2,0.6-3.9,1.6-5.5,2.9l-3.7-6.8
              c2.3-1.7,4.8-2.9,7.6-3.7c3-0.9,6.2-1.3,9.4-1.3C478.8,17.4,483.3,18.8,486.4,21.6z M477.9,50c1.8-1,3.2-2.7,3.9-4.6v-4.7H472
              c-5.3,0-8,1.8-8,5.3c0,1.6,0.7,3.1,2,4c1.7,1.1,3.6,1.6,5.6,1.5C473.8,51.6,475.9,51.1,477.9,50z"
        />
      </g>
    </g>
  </svg>
);
