import { makeByIdContext, makeGeneralContext } from "../context";

export const makeBusinessContext = <T>(daoName: string) => {
  const generalContext = makeGeneralContext<T>(daoName);
  const byIdContext = makeByIdContext<T>(daoName);

  return {
    generalContext,
    byIdContext,
  };
};
