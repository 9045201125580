import { TemplateProperty } from "src/lib/templates";

export const cardInfoTemplate: TemplateProperty = {
  name: "cardInfo",
  type: {
    name: "object",
    kind: [
      {
        name: "cardHolder",
        type: { name: "string" },
        label: "Titular de la tarjeta",
      },
      {
        name: "cardNumber",
        type: { name: "string" },
        label: "Número de la tarjeta",
      },
      {
        name: "cardExpiration",
        type: {
          name: "object",
          kind: [
            {
              name: "month",
              type: { name: "string" },
              validators: [{ name: "pattern", signature: ["^[0-9]+$"] }],
            },
            {
              name: "year",
              type: { name: "string" },
              validators: [{ name: "pattern", signature: ["^[0-9]+$"] }],
            },
          ],
        },
        label: "Vencimiento",
      },
      {
        name: "cardSecurityCode",
        type: { name: "string" },
        label: "Código de seguridad",
        validators: [
          {
            name: "pattern",
            signature: ["^[0-9]+$"],
          },
          {
            name: "max",
            signature: [4],
          },
        ],
      },
    ],
  },
};
