import React, { ReactNode, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, FormControl, Select, Typography, SelectProps, FormHelperText } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import typography from "src/theme/typography";
import { grey } from "src/theme/colors";
import { AnyObject } from "../../types";
import { primary } from "../../../theme/colors";
import { Loading } from "../Loading";
import pallet from "../../../theme/palette";

export interface SimpleSelectProps {
  title?: string;
  content?: any;
  value: string | number | string[] | number[];
  name?: string;
  handleChange?: (value: React.ChangeEvent<{ value: unknown }>) => void;
  onChange?: (value: React.ChangeEvent<{ value: unknown }>) => void;
  valueKey?: any;
  labelKey?: string;
  placeholder: string;
  customStyleTitle?: any;
  disableUnderline?: SelectProps["disableUnderline"];
  disabled?: SelectProps["disabled"];
  variant?: SelectProps["variant"];
  error?: boolean;
  helperText?: string;
  loading?: boolean;
  onBlur?: <T>(event?: React.FocusEvent<T>) => void;
  onFocus?: <T>(event?: React.FocusEvent<T>) => void;
  multiple?: boolean;
  [x: string]: any;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 1,
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "7px 1em",
      [theme.breakpoints.down("sm")]: {
        padding: "7px",
      },
    },
    "& .MuiOutlinedInput-root": {
      border: "none",
    },
  },
  title: {
    marginBottom: 10,
    fontSize: 16,
    lineHeight: "17px",
    fontWeight: 400,
    color: pallet.text.primaryAlternative,
  },
  select: {
    fontFamily: typography.fontFamily,
    color: grey.textPrimary,
    fontSize: "13px",
    maxWidth: "100%",
    display: "block",
    whiteSpace: "nowrap",
  },
  placeHolder: {
    fontFamily: typography.fontFamily,
    color: grey.textPrimary,
    fontSize: "16px",
    lineHeight: "17px",
    maxWidth: "100%",
    overflow: "hidden",
    display: "block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

export const SimpleSelect = ({
  title,
  content,
  handleChange,
  onChange,
  value,
  name = "",
  valueKey = "",
  labelKey = "",
  placeholder,
  customStyleTitle,
  disabled,
  disableUnderline = false,
  variant = "outlined",
  error,
  helperText,
  loading,
  onBlur,
  onFocus,
  multiple,
}: SimpleSelectProps) => {
  const classes = useStyles();

  const renderValue = useMemo(() => {
    if (!multiple) {
      return undefined;
    }
    return (values: unknown) => {
      if (!values || !(values as any[]).length) return placeholder;
      return (values as any[])
        .map((item: any) => {
          const option = content?.find((con: any) => `${_.get(con, valueKey)}` === `${item}`);
          return !option ? "-" : _.get(option, labelKey);
        })
        .join(", ") as ReactNode;
    };
  }, [content, labelKey, multiple, placeholder, valueKey]);
  return (
    <div>
      {title && (
        <Typography className={classes.title} style={customStyleTitle}>
          {title?.charAt(0).toUpperCase() + title?.toLowerCase().slice(1)}
        </Typography>
      )}
      {loading || !content ? (
        <Loading />
      ) : (
        <FormControl variant={variant} className={classes.formControl} error={error}>
          <Select
            name={name}
            value={value === undefined || value === null ? "" : value}
            displayEmpty
            onChange={onChange || handleChange}
            disableUnderline={disableUnderline}
            disabled={disabled}
            IconComponent={ExpandMoreIcon}
            onBlur={onBlur}
            onFocus={onFocus}
            multiple={multiple}
            renderValue={renderValue}
          >
            <MenuItem style={{ backgroundColor: primary.white, opacity: 1 }} value="">
              <Typography className={classes.placeHolder}>{placeholder?.charAt(0).toUpperCase() + placeholder?.toLowerCase().slice(1)}</Typography>
            </MenuItem>
            {content?.map((item: AnyObject) => {
              const theValue = _.get(item, valueKey);
              return (
                <MenuItem
                  id={theValue.includes(" ") ? `fee-${theValue.split(" ")[0]}` : theValue}
                  key={theValue}
                  style={{ backgroundColor: primary.white, opacity: 1 }}
                  value={theValue}
                >
                  <Typography className={classes.select}>
                    {!!item.icon && item.icon}
                    {_.get(item, labelKey)}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
          {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </div>
  );
};
