import React from "react";
import { makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    height: "100vh",
    width: "100vw",
    margin: -8,
  },
});

export const FullScreenLayout = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container} alignContent="center" alignItems="center" justify="center">
      {children}
    </Grid>
  );
};
