import React from "react";

export const AFALogo = () => (
  <svg width="50" height="50" viewBox="0 0 24 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2251_20709)">
      <path
        d="M11.1853 15.691C10.9033 18.5172 10.044 18.8509 9.65722 20.2337C9.43846 20.9322 9.44724 21.8045 
            9.73776 22.5049C10.0881 23.3494 10.6915 23.7821 11.4439 23.9895C12.8389 24.3734 14.1214 23.6934 14.699 
            21.9279C16.2677 17.5503 14.1493 11.5503 13.3293 9.88925C11.8947 6.76652 9.79623 3.43205 11.8748 
            6.10352e-05C8.4037 2.5226 9.44431 6.69459 10.4235 10.3154C10.6138 11.018 11.3028 13.3145 11.1853 15.691Z"
        fill="white"
      />
      <path
        d="M8.2955 27.6268C8.16101 27.6567 7.90079 27.6979 7.46301 27.7127C8.60729 28.5737 10.0381 29.086 
            11.593 29.086C11.6797 29.086 11.7653 29.0825 11.8511 29.0798C11.5593 28.8829 11.3759 28.7243 11.2882 28.6437C9.66784 28.5669 8.59825 27.8549 8.2955 27.6268Z"
        fill="white"
      />
      <path
        d="M13.2055 27.3771C13.0264 27.5721 12.8113 27.7556 12.5944 27.9199C12.2808 28.157 11.9645 
            28.3514 11.7473 28.4759C11.9153 28.6103 12.184 28.804 12.5647 29.0168C13.4756 28.8889 14.3277 
            28.5842 15.0853 28.1389C15.1336 27.97 15.1679 27.814 15.1913 27.6796C15.2027 27.6152 15.211 27.5581 15.2182 27.5042C14.5693 27.6465 13.5718 27.4582 13.2055 27.3771Z"
        fill="white"
      />
      <path
        d="M7.88192 25.2481C7.86651 25.8408 8.04274 26.3704 8.21391 26.7392C8.33751 27.0048 8.45739 
            27.1875 8.49779 27.2454C8.6485 27.3647 9.70054 28.1489 11.3307 28.2198C11.4341 28.1637 11.6975 
            28.0188 11.9965 27.8203C12.3038 27.6171 12.6475 27.3585 12.9003 27.0805L12.4641 24.8228C12.4016 
            24.8271 12.3711 24.8454 12.3081 24.8454C11.9591 24.8454 11.5138 24.7983 11.1601 24.6849C10.6867 24.5332 10.2531 24.2819 9.88341 23.9556"
        fill="white"
      />
      <path
        d="M7.67859 26.5484C7.55101 26.1896 7.45106 25.7537 7.45718 25.2759C7.15656 25.1549 6.47344 
            24.8279 5.86688 24.1781C5.78635 24.3944 5.69092 24.7145 5.6476 25.0876C5.62872 25.2556 5.62022 
            25.4329 5.62952 25.6172C5.97825 26.2421 6.42693 26.8054 6.95295 27.2884C7.45399 27.2957 7.78996 27.2696 7.99702 27.2426C7.90054 27.0743 7.78066 26.8364 7.67859 26.5484Z"
        fill="white"
      />
      <path
        d="M17.0339 26.0745C16.8149 26.3132 16.6046 26.5045 16.4154 26.6551C16.097 26.9856 15.8078 
            27.2057 15.6629 27.3062C15.6605 27.3431 15.6568 27.3872 15.6515 27.439C15.6419 27.537 15.626 
            27.6599 15.5991 27.8037C16.2171 27.36 16.7556 26.8164 17.1899 26.1971C17.1155 26.1289 17.0951 26.1155 17.0339 26.0745Z"
        fill="white"
      />
      <path
        d="M16.9363 23.7837L14.8625 23.2924C14.2448 24.0752 14.0343 24.2713 12.9413 24.76L13.3092 
            26.8644C13.9787 26.9878 15.0326 27.0915 15.3811 26.8951C15.3835 26.8938 15.4265 26.8695 15.4964 
            26.8254C15.8127 26.6554 16.3773 26.3181 16.9889 25.7018C17.2029 24.8578 17.0081 24.0588 16.9363 23.7837Z"
        fill="white"
      />
      <path
        d="M5.51315 23.5862C5.37679 23.1439 5.03922 21.4696 5.33453 20.4591C5.23618 20.0602 
            5.22369 19.349 5.22369 19.349C5.03922 19.8482 4.68225 20.4713 4.68225 22.2304C4.68225 23.0536
             4.96374 24.0829 5.25718 24.8264C5.27366 24.7278 5.29253 24.6333 5.31406 24.5444C5.39992 24.1829 
             5.51315 23.9097 5.57535 23.7748L5.57269 23.7675C5.55594 23.7211 5.53521 23.6605 5.51315 23.5862Z"
        fill="white"
      />
      <path
        d="M5.86285 16.8209C5.8562 16.9041 6.14806 16.3828 6.32003 15.9744C6.492 15.5663 7.02334 
            14.2347 6.30887 11.549C5.88305 9.94718 5.70682 8.78504 5.97502 7.71881C5.4902 8.62772 5.21801 9.60667 5.08272 10.9259C4.83101 13.3824 6.01569 14.8382 5.86285 16.8209Z"
        fill="white"
      />
      <path
        d="M9.10205 19.8442C9.67405 18.1638 10.4648 17.8044 10.6985 15.5968C10.885 13.161 10.165 
            11.3063 9.77054 10.2101C8.87239 7.70967 7.32862 5.37409 9.03852 2.67456C6.43844 5.29327 6.14792
             7.5149 7.00115 11.4019C7.61515 14.2005 7.17844 15.2054 6.57905 16.4658C6.46449 16.7066 5.18625 
             18.8211 5.59612 20.0072C5.90285 19.7604 6.44589 19.2922 7.28263 19.0436C7.36344 19.0131 8.42585 19.4183 9.10205 19.8442Z"
        fill="white"
      />
      <path
        d="M7.64804 24.8917L9.57617 23.6471C9.35795 23.4025 9.17827 23.0238 9.10597 22.8314C8.81731 
            22.0631 8.78089 21.1658 8.88907 20.5158C8.9122 20.3789 7.26343 19.5363 7.17332 19.6007C6.33897 
            20.0099 5.77733 20.5112 5.69094 20.6214C5.42062 22.2679 5.91741 23.4947 5.98864 23.6725C6.60264 24.4391 7.38862 24.7912 7.64804 24.8917Z"
        fill="white"
      />
      <path d="M15.9047 15.7908L15.9056 15.8101L15.9047 15.7908Z" fill="white" />
      <path
        d="M13.9187 20.6184C13.9623 20.9269 13.9221 21.2501 13.7831 21.5537C13.3993 22.3915 12.4169 
            22.7579 11.5884 22.3727C10.7596 21.9877 10.3989 20.9945 10.7828 20.1572C10.9218 19.8539 11.1397 
            19.6128 11.4005 19.4466C10.9157 19.622 10.4975 19.9851 10.2639 20.4945C9.80407 21.4996 10.2373 
            22.6895 11.2306 23.152C12.2253 23.6151 13.4044 23.1754 13.8653 22.1706C14.0981 21.6612 14.1016 21.1041 13.9187 20.6184Z"
        fill="black"
      />
      <path
        d="M13.9055 19.1195L13.4542 19.5128L13.8715 19.9404L13.2782 19.8967L13.2737 20.498L12.8849
             20.0425L12.4609 20.4654L12.5053 19.8657L11.9102 19.862L12.3612 19.4689L11.9442 19.0414L12.5372 19.0851L12.5417 18.4835L12.9308 18.9382L13.3548 18.5161L13.3104 19.1155"
        fill="black"
      />
      <path
        d="M19.7897 18.1352C19.6451 15.7137 18.6377 12.8367 16.9583 10.2263C13.5733 4.96439 13.5279 
            4.41026 14.0587 1.26544C12.1869 4.22573 12.5965 7.17202 13.9758 10.2322C15.4624 13.532 16.6197 18.7928
             15.0475 22.821L17.2074 23.4109C17.2074 23.4109 17.7204 24.527 17.4094 25.8198C18.6672 24.4704 20.0004 21.6817 19.7897 18.1352Z"
        fill="white"
      />
      <path
        d="M3.89431 32.2314C3.88208 32.1802 3.86906 32.1428 3.8563 32.1196C3.84301 32.097 3.81457 
            32.0857 3.77018 32.0857C3.70001 32.0857 3.65509 32.1342 3.63622 32.2314L3.05225 34.9072H4.47801L3.89431
             32.2314ZM6.78384 37.8449C6.78384 37.9491 6.72962 38 6.62144 38H5.2818C5.23103 38 5.18903 37.9847 5.15767
              37.9516C5.12577 37.9206 5.10265 37.8772 5.09095 37.8252L4.74621 36.2361H2.78459L2.44011 37.8252C2.42761 
              37.8772 2.40502 37.9206 2.37312 37.9516C2.34123 37.9847 2.3003 38 2.24873 38H0.909353C0.800906 38 0.746948
               37.9491 0.746948 37.8449L0.751733 37.8252L0.755986 37.7972L2.18175 31.785C2.23916 31.5523 2.32183 31.3645 
               2.4308 31.2225C2.53899 31.0806 2.66524 30.972 2.80824 30.8974C2.95231 30.8233 3.107 30.7729 3.27313 
               30.7471C3.43846 30.7218 3.60459 30.7086 3.77018 30.7086C3.93631 30.7086 4.10031 30.7218 4.26245
                30.7471C4.42538 30.7729 4.57875 30.8233 4.72228 30.8974C4.86555 30.972 4.99127 31.0806 5.09972 
                31.2225C5.20843 31.3645 5.29136 31.5523 5.34904 31.785L6.77427 37.7972L6.77906 37.8252L6.78384 
                37.8449Z"
        fill="white"
      />
      <path
        d="M11.9311 32.0763C11.8931 32.1145 11.8485 32.1342 11.7972 32.1342H9.85497C9.63196 32.1342
             9.47381 32.1811 9.38157 32.2751C9.28881 32.3688 9.24256 32.5412 9.24256 32.7937V33.6665H11.7209C11.7717
              33.6665 11.8166 33.6851 11.8543 33.7238C11.8931 33.7632 11.9123 33.809 11.9123 33.8599V34.7815C11.9123
               34.8334 11.8931 34.8779 11.8543 34.917C11.8166 34.956 11.7717 34.9757 11.7209 34.9757H9.24256V37.8067C9.24256 
               37.8581 9.22342 37.9039 9.18514 37.9427C9.14607 37.9815 9.10115 38.0001 9.05012 38.0001H7.79925C7.74769
                38.0001 7.70277 37.9815 7.66396 37.9427C7.62568 37.9039 7.60681 37.8581 7.60681 37.8067V32.7937C7.60681
                 32.3546 7.67087 31.9984 7.79872 31.7272C7.9279 31.4551 8.09881 31.2452 8.31385 31.0973C8.52914 30.9484
                  8.77847 30.8481 9.0642 30.7962C9.34888 30.7444 9.64445 30.7186 9.95092 30.7186C10.2056 30.7186 10.4304
                   30.7226 10.6247 30.7334C10.8196 30.7428 10.9905 30.7544 11.1372 30.7676C11.2839 30.7803 
                   11.4099 30.7926 11.5154 30.8058C11.6204 30.8188 11.7143 30.8314 11.7972 30.8446C11.925 
                   30.8638 11.9886 30.9287 11.9886 31.0389V31.9402C11.9886 31.9922 11.9694 32.0378 11.9311 
                   32.0763Z"
        fill="white"
      />
      <path
        d="M17.4899 32.0763C17.4516 32.1145 17.407 32.1342 17.3562 32.1342H15.4137C15.1902 32.1342
             15.0325 32.1811 14.9403 32.2751C14.8481 32.3688 14.8013 32.5412 14.8013 32.7937V33.6665H17.2791C17.3304 
             33.6665 17.3751 33.6851 17.4133 33.7238C17.4516 33.7632 17.471 33.809 17.471 33.8599V34.7815C17.471 
             34.8334 17.4516 34.8779 17.4133 34.917C17.3751 34.956 17.3304 34.9757 17.2791 
             34.9757H14.8013V37.8067C14.8013 37.8581 14.7819 37.9039 14.7436 37.9427C14.7053 37.9815 14.6604 
             38.0001 14.6091 38.0001H13.3583C13.3064 38.0001 13.2618 37.9815 13.223 37.9427C13.1844 37.9039
              13.1653 37.8581 13.1653 37.8067V32.7937C13.1653 32.3546 13.2293 31.9984 13.3577 31.7272C13.4861 
              31.4551 13.6578 31.2452 13.8728 31.0973C14.0879 30.9484 14.338 30.8481 14.6224 30.7962C14.9073
               30.7444 15.2037 30.7186 15.5091 30.7186C15.7646 30.7186 15.9894 30.7226 16.1843 30.7334C16.3783
                30.7428 16.5492 30.7544 16.6962 30.7676C16.8424 30.7803 16.9684 30.7926 17.0736 30.8058C17.1794
                 30.8188 17.2727 30.8314 17.3562 30.8446C17.4835 30.8638 17.5473 30.9287 17.5473 31.0389V31.9402C17.5473 31.9922 17.5282 32.0378 17.4899 32.0763Z"
        fill="white"
      />
      <path
        d="M20.8574 32.2314C20.8452 32.1802 20.8327 32.1428 20.8192 32.1196C20.8067 32.097 20.7774 
            32.0857 20.7333 32.0857C20.6626 32.0857 20.6185 32.1342 20.5991 32.2314L20.0156 34.9072H21.4417L20.8574
             32.2314ZM23.747 37.8449C23.747 37.9491 23.6925 38 23.5843 38H22.2452C22.1944 
             38 22.1524 37.9847 22.1208 37.9516C22.0889 37.9206 22.0663 37.8772 22.0536 37.8252L21.7093 
             36.2361H19.748L19.4035 37.8252C19.3907 37.8772 19.3684 37.9206 19.3365 37.9516C19.3044 37.9847 
             19.2629 38 19.2121 38H17.8728C17.764 38 17.7101 37.9491 17.7101 37.8449L17.7146 37.8252L17.7199 
             37.7972L19.1451 31.785C19.2023 31.5523 19.2855 31.3645 19.3939 31.2225C19.5024 31.0806 19.6286
              30.972 19.7722 30.8974C19.9152 30.8233 20.0699 30.7729 20.2357 30.7471C20.4021 30.7218 20.5672 
              30.7086 20.7333 30.7086C20.8989 30.7086 21.0632 30.7218 21.2261 30.7471C21.3888 30.7729 21.5419 
              30.8233 21.6854 30.8974C21.8289 30.972 21.9544 31.0806 22.0634 31.2225C22.1716 31.3645 22.2542 31.5523 22.3116 31.785L23.7379 37.7972L23.7425 37.8252L23.747 37.8449Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2251_20709">
        <rect width="23" height="38" fill="white" transform="translate(0.746948)" />
      </clipPath>
    </defs>
  </svg>
);
