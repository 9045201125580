import React, { useEffect } from "react";
import { Grid, Container, Box } from "@material-ui/core";
import { FormContextProvider } from "src/lib/templates";
import { SingIn, OAuthProvider } from "src/app/models";
import { SessionFBChangeEmitter } from "src/platform/libby/session";
import { StatusResponse } from "src/app/services";
import { useScrollToTopOnMount } from "src/commons";
import { LoginForm } from "./components";
import { loginInitialValue } from "./initialValues";
import { loginTemplate } from "./templates";
import { useLogin } from "./hooks";

export const Login = () => {
  const { singIn } = useLogin();
  useScrollToTopOnMount();

  useEffect(
    () =>
      SessionFBChangeEmitter.subscribe(async (fbStatus: StatusResponse) => {
        if (fbStatus.status === "connected") {
          const to_singin: SingIn = {
            token_id: fbStatus.authResponse?.accessToken,
            provider: OAuthProvider.FACEBOOK,
          };
          await singIn(to_singin);
        }
      }),
    [singIn],
  );

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Box mt={4}>
          <FormContextProvider initialValues={loginInitialValue} onSubmit={singIn} template={loginTemplate}>
            <LoginForm handlerSocialSingIn={singIn} />
          </FormContextProvider>
        </Box>
      </Grid>
    </Container>
  );
};
