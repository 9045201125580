import { Links } from "src/app/views";
import { SortOptions } from "src/commons";

export const SET_TEST = "setTest";
export const SET_SEARCH = "setSearch";
export const RESET_SEARCH = "resetSearch";
export const SET_LINKS = "setLinks";
export const SET_PAGINATE = "setPaginate";
export const SET_SORT_OPTION = "sortOption";

interface GeneralActions {
  type: string;
  payload?: any;
}

export type GeneralState = {
  test: string;
  search: string;
  links: Links[];
  paginate: { page: number; path: string };
  sortOption: SortOptions;
};

export const generalInitialState: GeneralState = {
  test: "",
  search: "",
  links: [{ label: "Inicio", url: "/" }],
  paginate: { page: 1, path: "/" },
  sortOption: { id: 1, name: "Mayor descuento" },
};

export const generalReducer = (state = generalInitialState, action: GeneralActions) => {
  switch (action.type) {
    case SET_TEST:
      return {
        ...state,
        test: action.payload,
      };
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case RESET_SEARCH:
      return {
        ...state,
        search: generalInitialState.search,
      };
    case SET_LINKS:
      return {
        ...state,
        links: action.payload,
      };
    case SET_PAGINATE:
      return {
        ...state,
        paginate: action.payload,
      };
    case SET_SORT_OPTION:
      return {
        ...state,
        sortOption: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
