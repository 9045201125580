import { makeStyles } from "@material-ui/core";
import palette from "src/theme/palette";

export const verticalBannerStyles = makeStyles(() => ({
  carouselButton: {
    "&:hover": {
      background: palette.primary.main,
    },
    "& svg": {
      height: "26px",
      width: "26px",
    },
    "& path": {
      fill: palette.primary.contrastText,
    },
  },
}));
