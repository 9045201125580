import React, { useState, useEffect, useCallback } from "react";
import { RegretFormView } from "src/app/views/components/RegretForm/RegretFormView";
import { useRegretFormContext } from "src/screens/Public/regret/hooks/RegretFormContext";
import { PaymentCancelation } from "src/app/models";
import { AnyObject } from "src/commons";
import { errorEmailType, validBuyNumberType } from "../types";

interface RegretFormProps {
  emailValidation: (value: PaymentCancelation) => void;
  errorEmail: errorEmailType;
  open: boolean;
  setOpen: (value: boolean) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  btnDisable: boolean;
  setBtnDisable: (value: boolean) => void;
  errorSave: boolean;
  paymentDAO: AnyObject;
}

const initialValidBuyNumberState = {
  valid: false,
  err: false,
  msg: "",
};

export const RegretForm = ({
  emailValidation,
  errorEmail,
  open,
  setOpen,
  loading,
  setLoading,
  btnDisable,
  setBtnDisable,
  errorSave,
  paymentDAO,
}: RegretFormProps) => {
  const { form, values, handleSubmit } = useRegretFormContext();
  const [validBuyNumber, setValidBuyNumber] = useState<validBuyNumberType>(initialValidBuyNumberState);

  const updateValidBuyNumber = useCallback(
    (newState) => {
      if (JSON.stringify(validBuyNumber) !== JSON.stringify(newState)) {
        setValidBuyNumber(newState);
      }
    },
    [validBuyNumber],
  );

  const onValidateBuyNumber = useCallback(async () => {
    setLoading(true);
    try {
      const res = await paymentDAO.checkExistPayment(values.buyNumber);
      if (res) {
        updateValidBuyNumber({
          valid: true,
          err: false,
          msg: "",
        });
      } else {
        updateValidBuyNumber({
          valid: false,
          err: true,
          msg: "Número de compra no es correcto.",
        });
      }
    } catch (err) {
      // do nothing
    } finally {
      setLoading(false);
    }
  }, [paymentDAO, setLoading, values.buyNumber, updateValidBuyNumber]);

  useEffect(() => {
    if (values.buyNumber.length === 36 || values.buyNumber.length === 39) {
      onValidateBuyNumber();
    } else {
      updateValidBuyNumber(initialValidBuyNumberState);
    }
  }, [values.buyNumber, onValidateBuyNumber, updateValidBuyNumber]);

  const onValidateEmail = useCallback(async () => {
    emailValidation(values);
  }, [emailValidation, values]);

  useEffect(() => {
    if (values.emailConfirm.length >= values.email.length && !!values.email.length) {
      onValidateEmail();
    }
    if (values.motive.length >= 10) setBtnDisable(false);
  }, [setBtnDisable, onValidateEmail, values]);

  return (
    <RegretFormView
      form={form}
      validBuyNumber={validBuyNumber}
      errorEmail={errorEmail}
      errorSave={errorSave}
      btnDisable={btnDisable}
      loading={loading}
      handleSubmit={handleSubmit}
      open={open}
      setOpen={setOpen}
    />
  );
};
