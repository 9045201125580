import React from "react";
import { Grid, Typography, useMediaQuery, Box, Divider, Theme, Link } from "@material-ui/core";
import { Publication } from "src/app/models";
import { formatPriceNotDecimal } from "src/utils";
import { Stock } from "src/app/models/Stock";
import { imageCarouselType } from "src/commons";
import { ShipmentData } from "src/screens/Public/CalculateShipments/types";
import { PaymentExtraDataModel } from "src/app/models/Payment";
import { useInfoPublicationPayment } from "src/commons/hooks/useInfoPublicationPayment";
import { productInfoPanelStyles as useStyles } from "../../styles";
import ProductInfoPanelQuantity from "./ProductInfoPanelQuantity";
import { ReactCarousel } from "../../../../commons/ui/view/Banner/CarouselMultiple";
import { CardDiscount } from "../CardDiscount/CardDiscount";
import { FreeShipmentView, PayShipmentView } from "./components";
import { HighLightPromo } from "../HighLightPromo";
import typography from "../../../../theme/typography";

interface ProductInfoPanelProps {
  data: Publication;
  stock: Stock | undefined;
  workingStock: boolean;
  onBuyClick: () => void;
  onAddToCartClick: () => void;
  onChangeQuantity: (quantity: number) => void;
  onClickMeansPayment: () => void;
  quantity: number;
  freeShipping: boolean;
  imagesCarousel: imageCarouselType[];
  enableStock: boolean;
  availableQuantity: number;
  discount: number;
  calculateShipment: () => void;
  shipmentData: ShipmentData;
}

interface BestPromotionsMsgProps {
  promotion: PaymentExtraDataModel;
}

export const BestPromotionsMsg = ({ promotion }: BestPromotionsMsgProps) => (
  <Box>
    {promotion.installments}
    {` ${promotion.installments > 1 ? "cuotas" : "pago"} `}
    de
    <Box display="inline" fontWeight="fontWeightBold" color="success.dark" px={1}>
      {promotion.amountTotal}
    </Box>
  </Box>
);

export const ProductInfoPanel = ({
  data,
  stock,
  workingStock,
  onBuyClick,
  onAddToCartClick,
  onChangeQuantity,
  onClickMeansPayment,
  quantity,
  freeShipping,
  imagesCarousel,
  enableStock,
  availableQuantity,
  discount,
  calculateShipment,
  shipmentData,
}: ProductInfoPanelProps) => {
  const classes = useStyles();
  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const carouselImagesArray = imagesCarousel.map((image) => <img src={image.img_url} className={classes.img} alt="item_product" />);
  const { mainPrice, mainAmount, alterPrice } = useInfoPublicationPayment(data);

  return (
    <Grid container direction="column">
      <Box pt={2}>
        <Grid>
          <Box component="text" color="primary.dark">
            <h1 style={typography.h5} className={classes.normalFontWeight} id={`${data.product}-name`}>
              {data.product}
            </h1>
          </Box>

          {isSm && <ReactCarousel arrayComponentsToBeRender={carouselImagesArray} forceNumberRender={1} />}

          {enableStock && !!discount && (
            <Box alignItems="center" display="flex" pt={1}>
              {!isSm && (
                <>
                  <Box>
                    <Typography variant="subtitle1">
                      <Box component="text" color="textPrimary" className={classes.sale} id={`${data.id}-old-price`}>
                        {`$${formatPriceNotDecimal(data.old_price)}`}
                      </Box>
                    </Typography>
                  </Box>
                </>
              )}
              <Box borderRadius={5} px={isSm ? 0 : 1}>
                <CardDiscount discount={discount} isAbsolute={false} semiBorderRadius={false} productId={data.id} />
              </Box>
            </Box>
          )}

          {enableStock && (
            <>
              <Typography variant="h4" className={classes.priceGrid}>
                <Box color="primary.dark" mb={1} id={`${data.id}-price`}>
                  {`$${formatPriceNotDecimal(mainPrice[mainAmount])}`}
                </Box>
              </Typography>
              <Box mb={1} display="flex">
                <HighLightPromo isAbsolute={false} semiBorderRadius={false} highlights={data.highlights || []} productId={data.id} />
              </Box>

              <Grid container direction="row" alignItems="center">
                <Typography variant="subtitle1">
                  <Box flexDirection="column" display="flex">
                    <Box display="inline" width="100%" id={`${data.id}-best-promos`}>
                      {alterPrice.installments}
                      {` ${alterPrice.typePayment}  de`}
                      <Box display="inline" fontWeight="fontWeightBold" color="success.dark" px={1}>
                        ${`${alterPrice.amountTotal} ${alterPrice.discount > 0 ? `(${alterPrice.discount}% OFF)` : ""}`}
                        {alterPrice.installments > 1 && alterPrice.interest === 0 ? "sin interés" : ""}
                      </Box>
                    </Box>
                  </Box>
                </Typography>
              </Grid>

              <Link href="/" component="button" variant="body2" onClick={onClickMeansPayment}>
                Ver otros medios de pago
              </Link>
            </>
          )}

          {enableStock &&
            (freeShipping ? (
              <FreeShipmentView shipmentData={shipmentData} calculateShipment={calculateShipment} />
            ) : (
              <PayShipmentView shipmentData={shipmentData} calculateShipment={calculateShipment} />
            ))}

          <ProductInfoPanelQuantity
            stock={stock}
            workingStock={workingStock}
            quantity={quantity}
            setQuantity={onChangeQuantity}
            onBuyClick={onBuyClick}
            onAddToCartClick={onAddToCartClick}
            enableStock={enableStock}
            availableQuantity={availableQuantity}
          />
        </Grid>
      </Box>
      {!isSm && <Divider />}
    </Grid>
  );
};
