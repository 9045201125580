import QRCodeModal from "@walletconnect/qrcode-modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { CONTRACT_ADDRESS } from "src/config";
import { DappWalletStrategy } from "../../DappWalletStrategy";
import { Wallet, WalletEvent } from "../common";
import { abi2 } from "../const/Abi";

export class WalletConnect implements DappWalletStrategy {
  name: Wallet;

  private provider: any;

  private ethersProvider!: ethers.providers.Web3Provider;

  constructor() {
    this.name = "walletconnect";
    this.init();
  }

  get Provider() {
    return this.provider;
  }

  async init() {
    //  Create WalletConnect Provider
    this.provider = new WalletConnectProvider({
      bridge: "https://bridge.walletconnect.org",
      chainId: 3,
      qrcodeModal: QRCodeModal,
      rpc: {
        1: "https://mainnet.mycustomnode.com",
        3: "https://eth-ropsten.alchemyapi.io/v2/_3evpOycrKE3HcBDtP6Sp_ekA44nSzU3",
        100: "https://dai.poa.network",
        // ...
      },
    });
    this.ethersProvider = new ethers.providers.Web3Provider(this.provider);
  }

  getName(): Wallet {
    return this.name;
  }

  getProvider() {
    return this.provider.wc;
  }

  public async connect() {
    console.log("Create new session");
    const accounts = await this.provider.enable();
    return accounts[0];
  }

  async isConnected() {
    return this.provider?.wc?.connected || false;
  }

  public async getAccount() {
    const signer = await this.getSigner();
    return signer.getAddress();
  }

  async disconnect() {
    this.provider.wc.killSession();
  }

  async getSigner() {
    return this.ethersProvider.getSigner();
  }

  async getContract() {
    const signer = await this.getSigner();
    const contract = new ethers.Contract(CONTRACT_ADDRESS, abi2, signer);
    return contract;
  }

  async buyNFT(tokenId: string, price: number) {
    const contract = await this.getContract();
    const response = await contract.buyNFT(tokenId, { value: price }); // el gasprice es para asegurar el mineo
    console.log("Minando");
    return response;
  }

  async setPriceNFT(tokenId: string, price: string) {
    const format = parseUnits(price);
    const contract = await this.getContract();
    const response = await contract.setPriceNFT(tokenId, format); // el gasprice es para asegurar el mineo
    console.log("Minando");
    return response;
  }

  async listeners(event: WalletEvent, callback: () => Promise<void>) {
    const ehter = window.ethereum as any;
    ehter?.on(event, () => callback());
  }

  async restart() {
    if (this.provider && this.provider instanceof WalletConnectProvider) {
      this.provider = null;
      await this.init();
    }
  }
}
