import React, { useMemo } from "react";
import { useBuyerFetchById } from "src/app/business";
import { Buyer } from "src/app/models";
import { TransformFunction } from "../types";

type ChildrenValue<T> = {
  data: T | null;
  working: boolean;
  raw: Buyer | undefined;
};

export interface UserInformationEditValueProps<T> {
  idBuyer: string;
  children: (value: ChildrenValue<T>) => JSX.Element;
  transformer: TransformFunction<T>;
}

// TODO: convertir en un componente de business makeEntityByIdTransformer
export function UserInformationEditValue<T>({ idBuyer, children, transformer }: UserInformationEditValueProps<T>) {
  const { data, working } = useBuyerFetchById(idBuyer);

  const transformed = useMemo<T | null>(() => {
    if (!data || working) {
      return null;
    }
    return transformer(data);
  }, [data, transformer, working]);

  return <>{children({ data: transformed, working, raw: data })}</>;
}
