import { makeStyles } from "@material-ui/core";

export const pendingPayment = makeStyles((theme) => ({
  icon: {
    height: "100px",
    width: "100px",
    [theme.breakpoints.down("sm")]: {
      height: "60px",
      width: "60px",
    },
    color: theme.palette.warning.dark,
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));
