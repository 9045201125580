/* eslint-disable class-methods-use-this */
const manager = {
  getItem: (key: string) => localStorage.getItem(key),
  setItem: (key: string, value: string) => localStorage.setItem(key, value),
  removeItem: (key: string) => localStorage.removeItem(key),
};

export const TOKEN_APP = "X-JustRPC-Token";

export class TokenManager {
  async retrieve(): Promise<string | null | boolean> {
    try {
      const token = await manager.getItem(TOKEN_APP);
      return token;
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      return false;
    }
  }

  clean(): void | boolean {
    try {
      return manager.removeItem(TOKEN_APP);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      return false;
    }
  }

  persist(token: string): void | boolean {
    try {
      return manager.setItem(TOKEN_APP, token);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      return false;
    }
  }

  static create(): TokenManager {
    return new TokenManager();
  }
}

export const tokenManager = TokenManager.create();
