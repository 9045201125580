import React, { useCallback } from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { ModalFormComponentProps } from "../../../../ui/dialogs/ModalForm";

export type RadioButtonsFormValue = any;

export interface RadioButtonsFormMetadata<T> {
  options: T[];
  getLabel?: (option: T) => React.ReactNode;
  getId?: (option: T) => string;
  getDisabledState?: (option: T, index: number) => boolean;
}

export const RadioButtonsForm = ({
  value,
  onChange,
  metadata = { options: [] },
}: ModalFormComponentProps<any | null, RadioButtonsFormMetadata<any>>) => {
  const { options, getLabel, getId, getDisabledState } = metadata;
  const getNormalizedId = useCallback((item) => (getId ? getId(item) : item), [getId]);
  const onChangeValue = useCallback(
    ({ target: { value: newValue } }) => {
      const selectedValue = options.find((item) => getNormalizedId(item).toString() === newValue.toString());
      onChange(selectedValue);
    },
    [options, onChange, getNormalizedId],
  );
  return (
    <FormControl component="fieldset">
      <RadioGroup aria-label="gender" name="gender1" value={value ? getNormalizedId(value) : value} onChange={onChangeValue}>
        {options.map((item: any, index: number) => {
          const id = getNormalizedId(item);
          return (
            <FormControlLabel
              key={id}
              value={id}
              control={<Radio color="primary" />}
              label={getLabel ? getLabel(item) : item}
              disabled={getDisabledState ? getDisabledState(item, index) : false}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
