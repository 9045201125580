import React from "react";
import { Grid, Card, useMediaQuery, useTheme, Box, Divider } from "@material-ui/core";
import { Publication as PublicationModels, ConfigValuesImages } from "src/app/models";
import { VerticalCarousel } from "src/commons/ui/view/Banner";
import { ModalDialog } from "src/commons/components/";
import { ShipmentData } from "src/screens/Public/CalculateShipments/types";
import { Stock } from "src/app/models/Stock";
import { imageCarouselType } from "src/commons";
import { ProductInfoPanel } from "../ProductInfoPanel/ProductInfoPanel";
import { ProductDetailsPromotions } from "./components";
import { ProductEspecification } from "./components/ProductEspecification";
import { ProductDescriptionView } from "./components/ProductDescription";
import { InfoModalData } from "./types";

interface ProductDetailsViewProps {
  product: PublicationModels;
  stock: Stock | undefined;
  workingStock: boolean;
  onBuyClick: () => void;
  onAddToCartClick: () => void;
  onChangeQuantity: (quantity: number) => void;
  quantity: number;
  freeShipping: boolean;
  imagesCarousel: imageCarouselType[];
  onClickMeansPayment: () => void;
  openMeansPayment: boolean;
  infoModalShow: (idInfoModalShow: string) => InfoModalData | undefined;
  idInfoModalShow: string;
  onClickGuarantees: () => void;
  onClickPolitics: () => void;
  onHandleCloseModal: () => void;
  enableStock: boolean;
  availableQuantity: number;
  discount: number;
  calculateShipment: () => void;
  shipmentData: ShipmentData;
  paymentImages: ConfigValuesImages;
}

export const ProductDetailsView = ({
  product,
  stock,
  workingStock,
  onBuyClick,
  onAddToCartClick,
  onChangeQuantity,
  quantity,
  freeShipping,
  imagesCarousel,
  onClickMeansPayment,
  openMeansPayment,
  infoModalShow,
  idInfoModalShow,
  onClickGuarantees,
  onClickPolitics,
  onHandleCloseModal,
  enableStock,
  availableQuantity,
  discount,
  calculateShipment,
  shipmentData,
  paymentImages,
}: ProductDetailsViewProps) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const dataModal = infoModalShow(idInfoModalShow);

  const carouselImagesArray = imagesCarousel.map((image) => ({
    introline: image.alt,
    isImage: true,
    content: { image: image.img_url, copy: image.alt },
  }));

  return (
    <Grid container direction="row" xs={12}>
      <Grid item xs={12}>
        <Card>
          <Box p={3}>
            <Grid container xs={12} direction={isSm ? "column-reverse" : "row"} spacing={1}>
              <Grid item direction="column" sm={12} md={8}>
                <Box pr={isSm ? 0 : 1}>
                  {!isSm && (
                    <Box pb={4}>
                      <Grid item xs={12}>
                        <VerticalCarousel leadingText="" data={carouselImagesArray} />
                      </Grid>
                    </Box>
                  )}
                  <Grid item>
                    <Box mb={1}>
                      <Divider />
                    </Box>
                  </Grid>
                  <Grid item xl={12}>
                    <Grid container direction="column">
                      <ProductDescriptionView data={product} />
                      <ProductEspecification data={product} />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item sm={12} md={4}>
                <Grid item>
                  <ProductInfoPanel
                    data={product}
                    stock={stock}
                    workingStock={workingStock}
                    onBuyClick={onBuyClick}
                    onAddToCartClick={onAddToCartClick}
                    onChangeQuantity={onChangeQuantity}
                    quantity={quantity}
                    freeShipping={freeShipping}
                    imagesCarousel={imagesCarousel}
                    enableStock={enableStock}
                    availableQuantity={availableQuantity}
                    discount={discount}
                    calculateShipment={calculateShipment}
                    shipmentData={shipmentData}
                    onClickMeansPayment={onClickMeansPayment}
                  />
                </Grid>

                <Grid item>
                  <Box mb={2}>
                    <ProductDetailsPromotions
                      paymentImages={paymentImages}
                      targets={product.targets}
                      onClickGuarantees={onClickGuarantees}
                      onClickPolitics={onClickPolitics}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      {dataModal && (
        <ModalDialog title={dataModal.name} open={openMeansPayment} onHandleClose={onHandleCloseModal}>
          <>{dataModal.render}</>
        </ModalDialog>
      )}
    </Grid>
  );
};
