import { MenuItem } from "src/commons";
import { routes } from "./routes";

export const dashboardMenu: MenuItem = {
  key: "privateDashboard",
  basePath: "/dashboard",
  enabled: true,
  icon: "List",
  text: "Mi cuenta",
  children: routes,
};
