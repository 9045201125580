import { makeStyles } from "@material-ui/core";

export const playerStatisticsStyles = makeStyles((theme) => ({
  descriptionInnerContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "& > div:nth-child(odd)": {
      marginRight: "100px",
      width: "50%",
      "@media (max-width: 500px)": {
        width: "100%",
      },
    },

    "@media (max-width: 500px)": {
      flexDirection: "column",
      width: "inherit",
    },
  },
  textContainer: {
    alignItems: "center",
    display: "flex",
    margin: "12px 0",
  },
  containers: {
    "@media (max-width: 500px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      // flexDirection: "column",
    },
  },
  centerTypography: {
    "@media (max-width: 500px)": {
      textAlign: "center",
      fontSize: 24,
      marginLeft: -24,
    },
  },
  centerNumber: {
    marginRight: 34,
    [theme.breakpoints.down(500)]: {
      fontSize: 57,
      marginLeft: -24,
      marginTop: -18,
    },
  },
  profileImage: {
    border: "3px solid #95F035",
    boxSizing: "border-box",
    boxShadow: "0px 0px 16px 4px rgba(149, 240, 53, 0.4)",
    borderRadius: "400px",
    marginRight: "28px",
    width: 160,
    height: 160,
    [theme.breakpoints.down(500)]: {
      width: 98,
      height: 98,
      marginLeft: 16,
    },
  },
  descriptionContainer: {
    margin: "84px auto 92px",
    width: "550px",
    "@media (max-width: 768px)": {
      width: "100%",
      marginLeft: 16,
    },
  },
  playerContainer: {
    width: "100%",
    marginTop: 80,
    marginBottom: 80,
    [theme.breakpoints.down(500)]: {
      justifyContent: "start",
      marginTop: 40,
      marginBottom: 40,
    },
  },
  gridTextContainer: {
    width: 250,
    [theme.breakpoints.down(500)]: {
      marginLeft: 16,
      marginBottom: -8,
    },
  },
}));
