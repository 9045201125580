export enum SKELETON_IDS {
  LEGALES = 1,
  RETURN_POLICY,
  HOW_TO_BUY,
  HOME,
  WE,
  FAQS,
  PROMOTION_BAPRO,
  PROMOTION_COMMON,
  PRODUCT,
}
