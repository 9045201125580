import { FeatureGroup } from "./FeatureGroup";
import { CardType } from "./CardType";
import { PaymentModel } from "./PaymentModel";
import { FooterInfo } from "./FooterInfo";
import { AttributesProduct } from "./business/Product";

export interface ConfigValues {
  imagen?: { small: string; normal: string };
  redirectTo?: string;
  nftInfo?: {
    start: string;
    location: string;
    date: string;
    typeOfGame: string;
    subTitle: string;
  };
}

export interface ConfigValuesSection extends ConfigValues {
  title: string;
  subTitle: string;
}

export interface ConfigValuesImages {
  images: Array<{ url: string; relevance: number }>;
  iconsUrl?: Array<FooterInfo>;
}
// INFO : main_price or alter_price if true show one installment payment, else show cuotes payment
type Amounts = "original_amount" | "amount";
export interface ConfigValuesPublication {
  showPrice: { main_price: boolean; alter_price: boolean; main_amount: Amounts; alter_amount: Amounts };
}

export const defaultConfigValues: ConfigValuesPublication = {
  showPrice: { main_price: false, alter_price: true, main_amount: "amount", alter_amount: "amount" },
};

export interface Attributes {
  physical?: {
    depth: number;
    width: number;
    height: number;
    weight: number;
  };
  image_url?: string;
  images?: string[];
  features?: FeatureGroup[];
  description?: string;
  title?: string;
}

export type Publication = {
  id: string;
  state: string;
  product: string;
  attributes: AttributesProduct;
  config_values: ConfigValues;
  type: string;
  old_price: number; // precio sin ningun descuento
  price: Array<{ promotion_id: number; price: string }>; // precio especial para cada promo
  targets: Array<string>;
  zones: Array<string>;
  highlights: Array<{ name: string; color: string }>;
  best: Array<PaymentModel>;
  has_stock: boolean;
  shipment_category_id: number;
  sku?: string;
  tags: string[];
};

export type Highlight = {
  key: string;
  url: string;
  text: string;
};

export type Target = {
  name: string;
  promotions: Array<string>;
  best: string;
  highlights: Array<Highlight>;
};

export type TargetPromotion = {
  target: string;
  options: Array<Promotion>;
};

export type Promotion = {
  id: string;
  name: string;
  from: Date;
  to: Date;
  options: Array<PromotionCard>;
};

export type PromotionCard = {
  id: string;
  installments: Array<number>;
  discount: number;
  interest: number;
  max_absolut: number;
  cards: Array<CardType>;
};

export type UserRole = {
  rol_id: string;
  rol_name: string;
};
