import React, { useCallback, useRef, useState, useMemo, useEffect } from "react";
import { ListItem, ListItemIcon, ListItemText, Collapse, Typography, Box } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useHistory } from "react-router";
import { ecommerceConfig } from "src/config/ecommerce";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";

export interface ListItemMenu {
  id: number;
  title: React.ReactNode;
  icon?: React.ReactNode;
  path?: string;
  subListItem?: Array<{ id: number; title: React.ReactNode; icon?: React.ReactNode; path?: string }>;
}

interface ListItemCollapseProps {
  listItem: ListItemMenu;
  isOpen: boolean;
}

export const ListItemCollapse = ({ listItem, isOpen }: ListItemCollapseProps) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const ref = useRef();

  const handleNavigateTo = useCallback((path?: string) => (path ? history.push(path) : {}), [history]);
  const handleClick = useCallback(() => setOpen(!open), [open]);

  useEffect(() => {
    if (!isOpen && open) {
      handleClick();
    }
  }, [isOpen, open, handleClick]);

  const haveSubItems = useMemo(() => !!(listItem.subListItem && listItem.subListItem.length > 0), [listItem]);

  const handleColorIcon = useCallback(
    (item: ListItemMenu) => {
      let isSelected = false;
      if (haveSubItems) {
        const paths = item.subListItem?.map((path) => path.path || "") || [];
        isSelected = paths.includes(history.location.pathname);
      } else {
        isSelected = history.location.pathname === item.path;
      }
      const colorTo = isSelected ? ecommerceConfig.ecommercePalette.materialUi.primary.main : ecommerceConfig.ecommercePalette.materialUi.text.hint;
      return <Box color={colorTo}>{typeof item.icon === "function" ? item.icon({ color: colorTo }) : item.icon}</Box>;
    },
    [history, haveSubItems],
  );

  return (
    <>
      <ListItem
        button
        key={`${listItem.id}-${listItem.title}-${listItem.path}`}
        onClick={haveSubItems && isOpen ? handleClick : () => handleNavigateTo(listItem.path)}
      >
        <ListItemIcon>{listItem.icon ? handleColorIcon(listItem) : <></>}</ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="subtitle1" color="textSecondary">
              {listItem.title}
            </Typography>
          }
        />
        {haveSubItems && isOpen ? open ? <ExpandLessIcon /> : <ExpandMoreIcon /> : <ChevronRightOutlinedIcon color="primary" />}
      </ListItem>
      <Collapse ref={ref} in={open} key={`${listItem.id}-${listItem.title}-${listItem.path}-collapse`} timeout="auto" unmountOnExit>
        {listItem.subListItem?.map((item) => (
          <ListItem button key={`${item.id}-${item.title}-${item.path}`} onClick={() => handleNavigateTo(item.path)}>
            <ListItemIcon>{item.icon ? item.icon : <></>}</ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="subtitle1" color={history.location.pathname === item.path ? "textPrimary" : "textSecondary"}>
                  {item.title}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </Collapse>
    </>
  );
};
