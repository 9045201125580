import moment from "moment";
import {
  Order,
  BuyerAddress,
  OrderState,
  OrderItem,
  Cart,
  PaymentOrder,
  PaymentModel,
  Payment,
  ResponseDecidir,
  Buyer,
  Shipment,
  Publication,
  MetadataMercadoPago,
} from "src/app/models";
import { ValuesForm } from "src/screens/Public/Checkout/types";
import { PersonalCredit } from "src/screens/Public/Checkout/initialValues";
import { getPricePublicationCart } from "src/context/CartContext/utils";
import { AnyObject } from "../commons";
import { APP_ECOMMERCE } from "../config";

interface CreateAddress {
  street: string;
  number: number;
  floor: string;
  department: string;
  cityId: string;
  zip: string;
  additionalInfo: string;
}

interface DataBuyer {
  email: string;
  name: string;
  phone: string;
  dateOfBirty: string;
  lastName: string;
  address: CreateAddress;
  document_number: string;
  document_type: string;
  company: string;
}

interface CreateBuyer {
  buyer: Buyer;
  address: BuyerAddress;
}

export const createBuyerAddress = (data: CreateAddress): BuyerAddress => ({
  street: data.street,
  number: data.number,
  floor: data.floor,
  department: data.department,
  city_id: data.cityId,
  zip: data.zip,
  comments: data.additionalInfo || "",
});

export const createBuyer = (data: DataBuyer): CreateBuyer => {
  const address = createBuyerAddress(data.address);
  const buyer: Buyer = {
    email: data.email,
    name: data.name,
    last_name: data.lastName,
    date_of_birth: moment(data.dateOfBirty).toISOString(),
    addresses: [address],
    document_number: data.document_number,
    document_type: data.document_type,
    phone: data.phone,
    company: data.company,
  };
  return {
    buyer,
    address,
  };
};

type documentType = "DNI" | "CUIL" | "CUIT";

const getDocumentType = (isResponsable: string, documentNumber: string): documentType => {
  if (APP_ECOMMERCE !== "detecnologia") {
    return isResponsable === "false" ? "DNI" : "CUIT";
  }

  return documentNumber.length >= 10 ? "CUIL" : "DNI";
};

export async function createOrder(cart: Omit<Omit<Cart, "email">, "send">, values: ValuesForm, deliveryData?: Shipment): Promise<Order> {
  const orderItems = new Array<OrderItem>();
  let total = 0;

  const {
    isResponsable,
    personalInfo: { name, lastName, email, document, phone, dateOfBirty },
    deliveryInfo: { street, streetNumber, floor, apartament, city, zipCode, additionalInfo },
    responsableInscripto: { cuit, business } = { cuit: "", business: "" },
    paymentMethod: { payment },
  } = values;

  cart.items.forEach(({ quantity, publication: { has_stock, ...rest } }) => {
    const orderItem: OrderItem = {
      publication: rest,
      quantity,
    };
    orderItems.push(orderItem);
    const price = getPricePublicationCart(orderItem.publication as Publication, payment);
    total += price * orderItem.quantity;
  });

  const dataBuyer: DataBuyer = {
    email,
    name,
    lastName,
    dateOfBirty,
    phone: `${phone.areaCode} ${phone.number}`,
    document_number: isResponsable === "false" ? document : cuit,
    document_type: getDocumentType(isResponsable, isResponsable === "false" ? document : cuit),
    company: business,
    address: {
      street,
      number: Number(streetNumber),
      floor,
      department: apartament,
      cityId: city.id,
      zip: zipCode,
      additionalInfo,
    },
  };

  const { buyer, address } = createBuyer(dataBuyer);

  const order: Order = {
    buyer,
    address,
    shipment: address,
    shipment_cost: deliveryData?.cost || 0,
    tax: 0,
    subtotal: total,
    total: total + (deliveryData?.cost || 0),
    created_at: new Date(),
    updated_at: new Date(),
    state: "Created" as OrderState,
    items: orderItems,
  };

  return order;
}

export const createPayment = (order: Order, payment: PaymentModel, reference: string, metadata: AnyObject): Payment => {
  const paymentOrder: PaymentOrder = {
    order,
    state: "Simulation",
    created_at: new Date(),
    updated_at: new Date(),
  };

  return {
    ...paymentOrder,
    ...payment,
    installments: +payment.installments,
    type: "352beb41-25f6-47f8-adc9-6d1cb3afd69c",
    reference,
    metadata,
    amount: payment.amount,
    original_amount: payment.original_amount,
    promotion_id: Number(payment.reference),
  };
};

export const createPaymentDecidir = (order: Order, payment: PaymentModel, decidirResponse: ResponseDecidir): Payment =>
  createPayment(order, payment, decidirResponse.id, decidirResponse);

export const createPaymentMercadoPago = (order: Order, payment: PaymentModel, metadata: MetadataMercadoPago) =>
  createPayment(order, payment, "mercadopago", metadata);

export const createPaymentCredit = (order: Order, payment: PaymentModel, applicantDetails: PersonalCredit): Payment =>
  createPayment(order, payment, "credit", applicantDetails);
