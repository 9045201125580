import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import { ecommerceConfig } from "src/config/ecommerce";

export interface BoxProps {
  mt?: number;
  name: string;
  borderRadius?: number;
  bgcolor?: string;
  color?: string;
  padding?: string;
  width?: number;
  height?: number;
  type?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    transition: theme.transitions.create(["color", "background-color"], { duration: theme.transitions.duration.complex }),
    "&:hover": {
      color: "white",
    },
  },
}));

export const CustomButtonProps = ({
  mt = 0,
  name = "",
  borderRadius = 40,
  bgcolor = "white",
  color = "black",
  padding = "12px 48px",
  width = 211,
  height = 48,
  type = "submit",
  disabled = false,
  onClick = () => {},
}: BoxProps) => {
  const classes = useStyles();
  return (
    <Box
      mt={mt}
      component={Button}
      {...ecommerceConfig.ecommerceTypography.buttonText}
      borderRadius={borderRadius}
      bgcolor={bgcolor}
      color={color}
      width={width}
      height={height}
      type={type}
      padding={padding}
      className={classes.button}
      disabled={disabled}
      onClick={onClick}
    >
      {name.toUpperCase()}
    </Box>
  );
};
