import React from "react";
import { Grid, Box, useMediaQuery, Theme } from "@material-ui/core";
import { FormProps, PaymentFormat, InnerCard, ErrorField } from "src/screens/Public/Checkout/types";
import { CartTotal, CartItemStock } from "src/context/CartContext/types";
import { TemplateProperty } from "src/lib/templates";
import { AnyObject } from "src/commons";
import { DeliveryCartActions } from "../DeliveryCartActions";
import { StepperView } from "../StepperView";
import { BlockRoute } from "../../../../commons/components";
import { CartItem, Shipment, PaymentModel } from "../../../models";

export type StepRecord = {
  label: string;
  hidden: boolean;
  template: TemplateProperty;
  initialValue: AnyObject;
  render: ({ onBackStep }: FormProps) => JSX.Element;
};
export interface CheckoutViewProps {
  totals: CartTotal;
  steps: StepRecord[];
  activeStep: number;
  completedSteps: Set<number>;
  cartItems: CartItemStock[];
  disabledBuyButton?: boolean;
  paymentFormat: PaymentFormat;
  valuesAcordion: InnerCard[];
  isBlocking: boolean;
  setOpenModal: (value: boolean) => void;
  component: ({ onBackStep }: FormProps) => JSX.Element;
  onBuyClick: () => void;
  onClickStep: (value: number) => void;
  onBackStep: () => void;
  setIsBlocking: (value: boolean) => void;
  deliveryData?: Shipment;
  onRemoveItem: (value: CartItem) => void;
  setCardValidate: (cardValidate: boolean) => void;
  cardExpirationErrorValidation: ErrorField;
  setCardExpirationErrorValidation: (errorField: ErrorField) => void;
  payment: PaymentModel;
  setReCaptcha: (value: boolean) => void;
  pendingCredit: boolean;
  setPendingCredit: (value: boolean) => void;
}

export const CheckoutView = ({
  valuesAcordion,
  totals,
  cartItems,
  paymentFormat,
  setOpenModal,
  steps,
  activeStep,
  isBlocking,
  completedSteps,
  disabledBuyButton,
  component,
  onBuyClick,
  onClickStep,
  onBackStep,
  setIsBlocking,
  deliveryData,
  onRemoveItem,
  setCardValidate,
  cardExpirationErrorValidation,
  setCardExpirationErrorValidation,
  payment,
  setReCaptcha,
  pendingCredit,
  setPendingCredit,
}: CheckoutViewProps) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const Comp = component;
  return (
    <>
      <BlockRoute isBlocked={isBlocking} content="Seguro quieres salir de la compra ?" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mb={isDownSm ? -2 : 1}>
            <StepperView activeStep={activeStep} steps={steps} onClickStep={onClickStep} completedSteps={completedSteps} />
          </Box>
        </Grid>

        <Grid item lg={8} md={8} xs={12}>
          <Box paddingBottom={10} bgcolor="white" borderRadius={8} boxShadow={3} display="content">
            <Comp
              onBackStep={onBackStep}
              setOpenModal={setOpenModal}
              setIsBlocking={setIsBlocking}
              deliveryData={deliveryData}
              setCardValidate={setCardValidate}
              cardExpirationErrorValidation={cardExpirationErrorValidation}
              setCardExpirationErrorValidation={setCardExpirationErrorValidation}
              setReCaptcha={setReCaptcha}
              pendingCredit={pendingCredit}
              setPendingCredit={setPendingCredit}
            />
          </Box>
        </Grid>

        <Grid item lg={4} md={4} xs={12}>
          <DeliveryCartActions
            maxStep={steps.length}
            activeStep={activeStep}
            cartItems={cartItems}
            valuesAcordion={valuesAcordion}
            paymentFormat={paymentFormat}
            cartTotals={totals}
            disabledBuyButton={disabledBuyButton}
            onBuyClick={onBuyClick}
            onRemoveItem={onRemoveItem}
            payment={payment}
          />
        </Grid>
      </Grid>
    </>
  );
};
