/* eslint max-len: ["error", { "ignorePattern": "^\\s*var\\s.+=\\s*require\\s*\\(", "code": 100000 }] */
import React from "react";

interface OneStarProps {
  width?: string | number;
  height?: string | number;
  noBorder?: boolean;
  noBackground?: boolean;
  onClick?: () => void;
}

export const OneStar = ({ width = 160, height = 160, noBorder, noBackground, onClick }: OneStarProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} onClick={onClick} viewBox="0 0 160 160" fill="none">
    {!noBackground && (
      <path
        d="M0 80C0 35.8172 35.8172 0 80 0V0C124.183 0 160 35.8172 160 80V80C160 124.183 124.183 160 80 160V160C35.8172 160 0 124.183 0 80V80Z"
        fill="#121212"
      />
    )}
    {!noBorder && (
      <path
        d="M80 157C37.4741 157 3 122.526 3 80H-3C-3 125.84 34.1604 163 80 163V157ZM157 80C157 122.526 122.526 157 80 157V163C125.84 163 163 125.84 163 80H157ZM80 3C122.526 3 157 37.4741 157 80H163C163 34.1604 125.84 -3 80 -3V3ZM80 -3C34.1604 -3 -3 34.1604 -3 80H3C3 37.4741 37.4741 3 80 3V-3Z"
        fill="#95F035"
      />
    )}
    <path
      d="M93.5465 55.2169L79.1587 69.6731C85.7153 76.2524 94.1016 80.7043 103.225 82.4486L74.5312 111.142C78.3629 114.974 83.5596 117.126 88.9783 117.126C94.3969 117.126 99.5937 114.974 103.425 111.142L130.443 84.1247C134.274 80.293 136.427 75.0963 136.427 69.6776C136.427 64.259 134.274 59.0622 130.443 55.2306V55.2306L128.785 56.8884C123.756 61.0442 117.359 63.1758 110.843 62.8667C104.326 62.5576 98.1593 59.83 93.5465 55.2169V55.2169Z"
      fill="white"
    />
    <path
      d="M27.7103 104.374L29.3681 102.716C34.3963 98.5601 40.7934 96.4277 47.3098 96.736C53.8261 97.0443 59.9934 99.7709 64.6067 104.383L78.9945 89.9272C72.4428 83.3511 64.0633 78.8995 54.9465 77.1517L83.6402 48.458C79.8077 44.6263 74.6103 42.4737 69.1909 42.4737C63.7715 42.4737 58.574 44.6263 54.7415 48.458L27.7103 75.4711C23.8788 79.3028 21.7263 84.4995 21.7263 89.9181C21.7263 95.3368 23.8788 100.534 27.7103 104.365V104.374Z"
      fill="white"
    />
  </svg>
);
