import { MenuItem } from "src/commons";
import { Favorites } from "src/screens";

export const routes: MenuItem[] = [
  {
    key: "privateFavoriteMain",
    basePath: "/",
    target: {
      component: Favorites,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Favorite",
    children: [],
    default: true,
  },
];
