import { useMemo, useEffect } from "react";
import { useFormContext } from "src/lib/templates";
import { AnyObject } from "src/commons";
import { EMITTERS } from "src/app/const/Emitters";
import {
  PaymentInitialValue,
  paymentInitialValue,
  InitialValue,
  initialValue,
  ApplicantInitialValue,
  DeliveryInitialValue,
  deliveryInitialValue,
  DocentInitialValue,
  docentInitialValue,
  AlumnInitialValue,
  alumnInitialValue,
} from "../initialValues";
import { docentInfoTemplate, alumnInfoTemplate } from "../templates";
import { applicantInfoTemplate } from "../templates/applicantTemplate";

interface Values extends InitialValue {
  paymentMethod: PaymentInitialValue;
  deliveryInfo: DeliveryInitialValue;
  applicantInfo?: ApplicantInitialValue<Partial<File> | undefined>;
  docentInfo?: DocentInitialValue<Partial<File> | undefined>;
  alumnInfo?: AlumnInitialValue<Partial<File> | undefined>;
}

const TEMPLATE_MAP = {
  docentInfo: docentInfoTemplate,
  alumnInfo: alumnInfoTemplate,
  applicantInfo: applicantInfoTemplate,
};

export const useCreditAddressLogic = (nameForm: "docentInfo" | "alumnInfo" | "applicantInfo") => {
  // TODO: como es dinamico tiparlo trae problemas al querer setear el estado de la key si esta no esta en el initial value
  const { form, values } = useFormContext<AnyObject>();

  const valuesForm: Values = useMemo(
    () => ({
      ...initialValue,
      paymentMethod: paymentInitialValue,
      deliveryInfo: deliveryInitialValue,
      docentInfo: docentInitialValue,
      alumnInfo: alumnInitialValue,
      ...values,
    }),
    [values],
  );

  useEffect(() => {
    const { additionalInfo, ...restDeliveryInfo } = valuesForm.deliveryInfo;
    const { province, city, street, streetNumber, floor, apartament, zipCode } = valuesForm[nameForm] || {};
    const validateInfo = JSON.stringify(restDeliveryInfo) !== JSON.stringify({ province, city, street, streetNumber, floor, apartament, zipCode });

    if (
      valuesForm.useDeliveryAddress &&
      valuesForm.paymentMethod.emitter.card_emiter_id.toString() === EMITTERS.CATAMARCA_CREDITS.toString() &&
      valuesForm[nameForm] &&
      validateInfo
    ) {
      // aqui quito actualizo la info del address con la ingresada en la informacion de envio
      const copy = { ...restDeliveryInfo };
      form.change(TEMPLATE_MAP[nameForm].name, { ...valuesForm[nameForm], ...copy });
    } else if (
      !valuesForm.useDeliveryAddress &&
      valuesForm.paymentMethod.emitter.card_emiter_id.toString() === EMITTERS.CATAMARCA_CREDITS.toString() &&
      valuesForm[nameForm] &&
      !validateInfo
    ) {
      // aqui quito blanqueo la info del address del template
      const { additionalInfo, ...rest } = deliveryInitialValue;
      form.change(TEMPLATE_MAP[nameForm].name, { ...valuesForm[nameForm], ...rest });
    }
  }, [form, valuesForm, nameForm]);
};
