import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

interface ModalDialogProps {
  open: boolean;
  children: JSX.Element | JSX.Element[];
  title: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  onHandleClose: () => void;
}

export const ModalDialog = ({ open, children, title, maxWidth = "md", onHandleClose }: ModalDialogProps) => (
  <Dialog open={open} aria-labelledby="draggable-dialog-title" onClose={onHandleClose} fullWidth maxWidth={maxWidth}>
    <DialogTitle>
      <Box flexDirection="row" display="flex" width="100%" justifyContent="space-between" alignItems="center">
        <Box display="flex" height="100%" alignItems="center">
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Box onClick={onHandleClose}>
          <IconButton edge="end">
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </DialogTitle>

    <DialogContent>
      <DialogContentText>{children}</DialogContentText>
    </DialogContent>
  </Dialog>
);
