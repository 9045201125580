import { StatusPayment } from "src/app/models";

export interface CodeErrors {
  title: string;
  description: string;
  details: string;
}

export const manageCodeErrorDecidir = (error_id: number): CodeErrors => {
  let error: CodeErrors;
  switch (error_id) {
    case 8:
    case 14:
    case 39:
    case 49:
    case 55:
      error = {
        title: "Su pago no pudo ser procesado",
        description: "Alguno de los datos ingresados es incorrecto.",
        details: "Intentá ingresando nuevamente los datos o utilizando otro medio de pago",
      };
      break;
    case 45:
    case 48:
    case 50:
    case 51:
    case 61:
    case 65:
    case 77:
      error = {
        title: "Su pago no pudo ser procesado",
        description: "El importe o las cuotas exceden el límite de la tarjeta.",
        details: "Intentá utilizando otro medio de pago",
      };
      break;
    case 5:
    case 46:
    case 54:
    case 56:
      error = {
        title: "Su pago no pudo ser procesado",
        description: "La tarjeta se encuentra vencida o deshabilitada.",
        details: "Intentá utilizando otro medio de pago",
      };
      break;
    case 1:
    case 2:
    case 76:
    case 91:
      error = {
        title: "Su pago no pudo ser procesado",
        description: "Contáctese con la entidad emisora de la tarjeta para autorizar el pago.",
        details: "Intentá utilizando otro medio de pago",
      };
      break;
    default:
      error = {
        title: "Su pago no pudo ser procesado",
        description: "Ocurrio un error procesando el pago!",
        details: "Intentá utilizando otro medio de pago",
      };
      break;
  }
  return error;
};

export const getErrorMsgDecidirCS = (result: StatusPayment): CodeErrors => {
  const { decision = "" } = result.metadata.fraud_detection?.status || {};
  let error = manageCodeErrorDecidir(result.metadata.status);
  if (decision === "black" || decision === "blue" || decision === "yellow") {
    error = {
      title: "Su pago está en proceso",
      description: "Su pago está siendo revisado, recibirá una confirmación una vez finalizado.",
      details: "",
    };
  }
  return error;
};
