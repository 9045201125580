import React, { useCallback, useRef, useState, useMemo } from "react";
import { Collapse, Typography, Box, Card, IconButton } from "@material-ui/core";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import { useHistory } from "react-router";

interface ListItemMenu {
  id: number;
  title: React.ReactNode;
  icon?: React.ReactNode;
  path?: string;
  subListItem?: Array<{ id: number; title: React.ReactNode; icon?: React.ReactNode; path?: string }>;
}

interface DashboarMenuProps {
  listItem: ListItemMenu;
}

export const DashboarMenu = ({ listItem }: DashboarMenuProps) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const ref = useRef();

  const handleNavigateTo = useCallback((path?: string) => (path ? history.push(path) : {}), [history]);
  const handleClick = useCallback(() => setOpen(!open), [open]);

  const haveSubItems = useMemo(() => !!(listItem.subListItem && listItem.subListItem.length > 0), [listItem]);

  return (
    <Box
      component={Card}
      display="flex"
      flexDirection="column"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      borderRadius={2}
      justifyContent="space-between"
      alignItems="center"
      padding="8px 16px"
      mb={1}
      width="100%"
    >
      <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" color="textPrimary">
          {listItem.title}
        </Typography>
        <IconButton size="small" onClick={haveSubItems ? handleClick : () => handleNavigateTo(listItem.path)}>
          <ChevronRightOutlinedIcon color="primary" style={haveSubItems ? { transform: open ? "rotate(-90deg)" : "rotate(90deg)" } : {}} />
        </IconButton>
      </Box>

      <Collapse ref={ref} in={open} timeout="auto" unmountOnExit style={{ width: "100%" }}>
        {haveSubItems &&
          listItem.subListItem?.map((subMenu) => (
            <Box
              mt={1}
              pt={1}
              width="100%"
              display="flex"
              borderTop="1px solid #DADBE4"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle1" color="textSecondary">
                {subMenu.title}
              </Typography>
              <IconButton size="small" onClick={() => handleNavigateTo(subMenu.path)}>
                <ChevronRightOutlinedIcon color="primary" />
              </IconButton>
            </Box>
          ))}
      </Collapse>
    </Box>
  );
};
