import { makeStyles } from "@material-ui/core";
import { ecommerceConfig } from "src/config/ecommerce";

interface StyleProps {
  lineHeight: number;
  lineCount: number;
  bgColor: string;
  isDownSm: boolean;
}

export const productCardRowStyles = makeStyles(() => ({
  container: {
    // display: 'flex',
    width: "100%",
    //  maxHeight: 230,
  },
  cover: { width: 265 },
  box: {
    overflow: "hidden",
  },
  installmentsContainer: {
    position: "absolute",
    zIndex: 10,
    top: 0,
  },
  cardContainer: {
    width: "100%",
    padding: ({ isDownSm }: StyleProps) => (isDownSm ? "8px" : "16px"),
    "&:last-child": {
      paddingBottom: ({ isDownSm }: StyleProps) => (isDownSm ? "8px" : "0px"),
    },
  },
  installmentsInnerContainer: {
    background: "#00D1F7",
    padding: "4px 12px",
    display: "flex",
    flexDirection: "column",
    borderBottomLeftRadius: 5,
  },
  installmentsTitle: {
    color: "white",
    fontWeight: 600,
  },
  installmentsSubtitle: {
    color: "white",
    fontWeight: 600,
    fontSize: 11,
    marginTop: "-5px",
  },
  priceWithDiscountContainer: {
    height: 30,
    margin: "0px -4px 5px",
  },
  priceText: {
    textDecoration: "line-through",
    color: "#8492A6",
    fontSize: 12,
    fontWeight: 400,
  },
  priceWithDiscountText: {
    color: "#3C4858",
    fontSize: 20,
    fontWeight: 400,
  },
  titleText: {
    minHeight: "50px",
  },
  oldPrice: {
    textDecoration: "line-through",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
  },
  iconLocalShipping: { width: 15, height: 15 },
  blockWithText: {
    overflow: "hidden",
    position: "relative",
    lineHeight: ({ lineHeight }: StyleProps) => lineHeight,
    maxHeight: ({ lineHeight, lineCount }: StyleProps) => `${lineHeight * lineCount}em`,
    textAlign: "left",
    marginRight: 0, // -1em
    paddingRight: "1em",
    "&::before": {
      content: '"..."',
      position: "absolute",
      right: 0,
      bottom: 0,
    },
    "&::after": {
      content: '""',
      position: "absolute",
      right: 0,
      width: "1em",
      height: "1em",
      marginTop: "0.2em",
      background: ({ bgColor }: StyleProps) => bgColor,
    },
  },
  localShipmentIcon: {
    color: ecommerceConfig.ecommercePalette.materialUi.primary.main,
    fontSize: ({ isDownSm }: StyleProps) => (isDownSm ? 16 : 24),
  },
  favoriteDeleteButton: {
    textTransform: "none",
    paddingLeft: 0,
    minWidth: 0,
  },
}));
