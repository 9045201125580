import React from "react";
import { Box, Typography, Grid, useMediaQuery, Theme, FormControl, InputLabel, FormHelperText, Input, Button } from "@material-ui/core";
import { useEmailSubscription } from "src/commons/";
import { ecommerceConfig } from "src/config/ecommerce";
import { APP_ECOMMERCE } from "src/config";
import { bannerInputViewStyles as useStyles } from "../styles/index";

interface BannerInputViewProps {
  title: string;
}

export const BannerInputView = ({ title }: BannerInputViewProps) => {
  const { handleInputChange, handleSubmit, email, error } = useEmailSubscription();
  const classes = useStyles();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid className={classes.container}>
      <Box
        display="flex"
        justifyContent={isDownSm ? "center" : "space-between"}
        alignItems="center"
        flexDirection={isDownSm ? "column" : "row"}
        height="100%"
      >
        <Typography variant="h5" className={classes.title}>
          {title || "¡Enterate de todas las novedades!"}
        </Typography>
        <form onSubmit={(e: any) => handleSubmit(e)} className={classes.containerForm}>
          <FormControl className={classes.inputStyle} required>
            <InputLabel shrink htmlFor="email">
              Deja tu correo aquí
            </InputLabel>
            <Input name="email" value={email} onChange={(e: any) => handleInputChange(e)} />
            <FormHelperText>{error.err ? error.msg : error.msg.length ? error.msg : null}</FormHelperText>
          </FormControl>
          {APP_ECOMMERCE === "nftmarket" ? (
            <Box
              component={Button}
              {...ecommerceConfig.ecommerceTypography.buttonText}
              borderRadius={40}
              bgcolor="white"
              color="black"
              padding="12px 48px"
              type="submit"
            >
              Suscribirme
            </Box>
          ) : (
            <Button type="submit" variant="contained" color="primary" className={classes.buttonStyles}>
              Suscribirme
            </Button>
          )}
        </form>
      </Box>
    </Grid>
  );
};
