import { enovaConfig } from "./enova/enovaConfig";
import { novatechConfig } from "./novatech/novatechConfig";
import { defaultConfig } from "./default/defaultConfig";
import { EcommerceConfig } from "../../app/models/general/ecommerceConfig";
import { detecnologiaConfig } from "./detecnologia/detecnlogiaConfig";
import { nftmarketConfig } from "./nftmarket/nftmarketConfig";

const config: Record<string, EcommerceConfig> = {
  enova: enovaConfig,
  novatech: novatechConfig,
  detecnologia: detecnologiaConfig,
  nftmarket: nftmarketConfig,
  default: defaultConfig,
};
export const ecommerceConfig = config[(process.env.REACT_APP_ECOMMERCE as string) || "default"];
