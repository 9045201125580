/* eslint-disable class-methods-use-this */
import React from "react";
import { Icon } from "@material-ui/core";

export type IconProps = {
  name?: string;
};

class Icons {
  IconByName(props: Required<IconProps>) {
    return <Icon>{props.name}</Icon>;
  }

  MenuIcon() {
    return <Icon>menu</Icon>;
  }
}

const i = new Icons();
export { i as Icons };
