import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { StepFormRecord } from "src/commons";
import ReactHtmlParser from "react-html-parser";

export interface RecoveryViewProps {
  steps: StepFormRecord[];
  activeStep: number;
  email: string;
}

export const RecoveryView = ({ steps, activeStep, email }: RecoveryViewProps) => {
  const Comp = steps[activeStep].render;

  return (
    <>
      <Grid justify="center" container spacing={2}>
        <Box>
          <Grid item xs={12}>
            <Grid item>
              <Typography align="center" variant="h4" color="textPrimary">
                {steps[activeStep].title}
              </Typography>
            </Grid>
            <Grid>
              <Box padding="15px 17%">
                <Typography variant="body1" align="center" color="textSecondary">
                  {ReactHtmlParser(steps[activeStep].subTitle.replace("$1", email))}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Comp />
      </Grid>
    </>
  );
};
