import { MenuItem } from "src/commons";
import { routes } from "./routes";

export const purchasesMenu: MenuItem = {
  key: "privatePurchases",
  basePath: "/purchases",
  enabled: true,
  icon: "List",
  text: "Compras",
  children: routes,
};
