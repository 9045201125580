/* eslint max-len: ["error", { "ignorePattern": "^\\s*var\\s.+=\\s*require\\s*\\(", "code": 2000 }] */
import React from "react";

export const ShortLogo = () => (
  <svg width="114" height="22" viewBox="0 0 114 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.78841 9.34588C8.14313 8.85144 7.34269 8.6033 6.53088 8.64604C5.88544 8.63618 5.24261 8.73019 4.62703 8.92447C4.12989 9.08217 3.64867 9.28624 3.18973 9.53401V20.6787C2.9905 20.7556 2.78365 20.8111 2.57267 20.8442C2.30033 20.8995 2.02275 20.9247 1.74491 20.9195C1.28361 20.9598 0.822209 20.8432 0.435543 20.5884C0.281615 20.4462 0.162766 20.2703 0.0883383 20.0744C0.0139107 19.8786 -0.0140655 19.6681 0.00661232 19.4596V9.44371C-0.0143409 9.04915 0.0797742 8.65701 0.277517 8.31494C0.524351 7.97631 0.833357 7.68773 1.18805 7.4646C2.80987 6.54821 4.64566 6.07823 6.5083 6.10256C8.15153 6.00908 9.77784 6.47602 11.1212 7.42698C11.6909 7.91514 12.1376 8.53057 12.4252 9.22345C12.7128 9.91634 12.8332 10.6672 12.7767 11.4153V20.6787C12.5778 20.7566 12.3709 20.8122 12.1597 20.8442C11.8822 20.8991 11.5997 20.9244 11.3168 20.9195C10.8558 20.9564 10.3956 20.84 10.0075 20.5884C9.85299 20.447 9.73424 20.2709 9.66099 20.0747C9.58773 19.8785 9.56206 19.6677 9.58607 19.4596V11.5206C9.61569 11.1196 9.55691 10.717 9.41388 10.3413C9.27085 9.96549 9.04706 9.62569 8.75831 9.34588H8.78841Z"
      fill="black"
    />
    <path
      d="M29.5426 13.6195C29.5595 14.6858 29.3836 15.7463 29.0234 16.75C28.7106 17.6314 28.2187 18.4385 27.5785 19.1204C26.9451 19.7811 26.1745 20.2948 25.321 20.6254C23.3974 21.3331 21.2847 21.3331 19.3611 20.6254C18.5086 20.2931 17.7383 19.7796 17.1036 19.1204C16.4635 18.4385 15.9715 17.6314 15.6588 16.75C15.3048 15.7449 15.1316 14.685 15.1471 13.6195C15.1351 12.5537 15.3109 11.494 15.6663 10.4891C15.9768 9.60832 16.4662 8.80128 17.1036 8.11869C17.7404 7.46207 18.5101 6.94896 19.3611 6.61367C21.2601 5.89863 23.3543 5.89863 25.2533 6.61367C26.1076 6.94276 26.8785 7.4567 27.5108 8.11869C28.1534 8.80144 28.6502 9.60799 28.9707 10.4891C29.3309 11.485 29.5068 12.5381 29.4899 13.597L29.5426 13.6195ZM22.3411 8.65297C21.7912 8.62907 21.2434 8.73438 20.7415 8.96047C20.2397 9.18655 19.7979 9.52711 19.4514 9.95482C18.6831 11.0162 18.3079 12.3118 18.3904 13.6195C18.303 14.9233 18.667 16.2173 19.4213 17.2843C19.7896 17.6923 20.2395 18.0185 20.7418 18.2418C21.2441 18.4651 21.7876 18.5804 22.3373 18.5804C22.887 18.5804 23.4306 18.4651 23.9328 18.2418C24.4351 18.0185 24.885 17.6923 25.2533 17.2843C26.0204 16.2219 26.3977 14.9277 26.3219 13.6195C26.3964 12.315 26.0278 11.0235 25.2759 9.95482C24.9317 9.51961 24.4877 9.17374 23.9815 8.94645C23.4779 8.71383 22.9251 8.60791 22.3712 8.63792"
      fill="black"
    />
    <path
      d="M42.8247 6.23005C43.2137 6.20904 43.5983 6.32045 43.9159 6.5461C44.0638 6.66683 44.18 6.82181 44.2545 6.99761C44.3412 7.17027 44.3851 7.36127 44.3824 7.55447C44.3524 7.96791 44.2767 8.37676 44.1567 8.77353C44.0062 9.35297 43.7955 10.0227 43.5471 10.7978C43.2988 11.5729 42.9903 12.3931 42.6667 13.2811C42.3431 14.169 41.9894 15.0645 41.6282 15.9374C41.267 16.8103 40.8757 17.6607 40.5446 18.4659C40.2135 19.2711 39.8674 19.9709 39.5664 20.5127C39.3301 20.6427 39.0768 20.7389 38.8139 20.7987C38.4556 20.8947 38.0861 20.9428 37.7152 20.9416C36.7219 20.9416 36.0747 20.6632 35.7737 20.1214C35.3573 19.3438 34.9811 18.5461 34.6449 17.7284C34.1784 16.6523 33.7043 15.4709 33.2152 14.1841C32.726 12.8973 32.2595 11.6105 31.8005 10.3237C31.3414 9.03691 30.9877 7.93072 30.7244 7.00513C30.9321 6.76861 31.1879 6.57914 31.4747 6.44937C31.7615 6.3196 32.0726 6.25252 32.3874 6.25262C32.784 6.22639 33.1774 6.33805 33.5011 6.56868C33.8251 6.86361 34.0532 7.24895 34.1558 7.67487L35.8264 12.8973C35.9468 13.2811 36.0898 13.7025 36.2478 14.1766C36.4058 14.6506 36.5714 15.1097 36.7369 15.5838C36.9025 16.0578 37.0605 16.5169 37.2261 16.9458C37.3916 17.3747 37.5195 17.7585 37.6174 18.0595H37.7754C38.4577 16.1532 39.1149 14.2343 39.747 12.3028C40.3791 10.3714 40.9209 8.45497 41.3724 6.55363C41.8103 6.32399 42.3005 6.21246 42.7946 6.23005"
      fill="black"
    />
    <path
      d="M51.7194 6.10248C53.3171 6.01587 54.9002 6.44595 56.2344 7.32907C57.3632 8.14178 57.9276 9.43359 57.9276 11.2045V18.2781C57.9564 18.6656 57.8442 19.0505 57.6115 19.3617C57.3604 19.6419 57.0687 19.8829 56.7461 20.0766C56.1004 20.4116 55.4154 20.6644 54.7068 20.8291C53.7619 21.0636 52.7907 21.1749 51.8172 21.1602C50.2016 21.2545 48.5956 20.8556 47.2118 20.0164C46.6687 19.629 46.2315 19.1115 45.9401 18.5114C45.652 17.9145 45.52 17.2543 45.5563 16.5925C45.5253 16.0274 45.6223 15.4626 45.8398 14.9401C46.0574 14.4176 46.3901 13.951 46.813 13.5749C47.8985 12.7863 49.1903 12.3313 50.5304 12.2655L54.7595 11.8441V11.1669C54.7927 10.7963 54.7306 10.4235 54.5792 10.0837C54.4277 9.74385 54.1919 9.44841 53.8941 9.2254C53.1625 8.77196 52.3073 8.55882 51.4485 8.61586C50.6556 8.60742 49.8661 8.72164 49.1082 8.95449C48.4223 9.1526 47.753 9.40421 47.1065 9.707C46.8942 9.53866 46.7155 9.33189 46.5797 9.09747C46.4467 8.86861 46.3742 8.60962 46.369 8.34496C46.359 8.03419 46.4545 7.72918 46.6399 7.47957C46.8542 7.22569 47.1284 7.02915 47.4376 6.90766C48.0544 6.61129 48.7109 6.40597 49.3866 6.29813C50.1489 6.16144 50.9223 6.09594 51.6968 6.10248H51.7194ZM51.7194 18.6995C52.3382 18.7059 52.9554 18.6325 53.5555 18.4813C53.9553 18.3981 54.3374 18.2453 54.6843 18.0298V14.1844L51.3657 14.5155C50.6283 14.5548 49.9099 14.7634 49.2662 15.1251C49.0382 15.2848 48.8544 15.4997 48.7319 15.7497C48.6043 16.0005 48.5495 16.282 48.5739 16.5624C48.5573 16.8665 48.6195 17.1698 48.7545 17.4428C48.8848 17.7153 49.0815 17.9508 49.3264 18.1276C50.042 18.5562 50.8725 18.7533 51.7043 18.692"
      fill="black"
    />
    <path
      d="M63.9853 18.1275C64.5214 18.4592 65.147 18.6169 65.7763 18.579C66.1451 18.5781 66.5121 18.5274 66.8674 18.4285C67.2157 18.3454 67.5539 18.2243 67.8758 18.0673C68.023 18.2361 68.1517 18.4203 68.2595 18.6167C68.4468 18.9672 68.4878 19.3775 68.3736 19.7582C68.2594 20.1388 67.9993 20.4588 67.65 20.6484C66.8945 21.0407 66.0465 21.2202 65.1968 21.1677C63.9082 21.2345 62.6334 20.8748 61.5697 20.1442C60.6667 19.467 60.2227 18.3533 60.2152 16.7956V2.6258L60.8247 2.46777C61.1091 2.4065 61.3993 2.37622 61.6901 2.37747C62.1475 2.34039 62.6039 2.45979 62.9844 2.7161C63.1276 2.86316 63.2356 3.04066 63.3005 3.23534C63.369 3.43077 63.3897 3.6398 63.3607 3.84487V6.80224H68.0714C68.1694 6.96185 68.2453 7.13398 68.2972 7.31394C68.3722 7.54222 68.4103 7.78101 68.41 8.0213C68.41 8.88669 68.0338 9.32315 67.2813 9.32315H63.3457V16.5849C63.3153 16.8733 63.354 17.1647 63.4585 17.4352C63.5647 17.7054 63.7351 17.9457 63.9552 18.135"
      fill="black"
    />
    <path
      d="M73.8881 15.238C74.085 16.2743 74.6756 17.194 75.5361 17.8041C76.4773 18.3984 77.577 18.6923 78.6892 18.6469C79.4843 18.6539 80.2743 18.5189 81.0219 18.248C81.6348 18.0388 82.2188 17.7531 82.7602 17.3977C83.0151 17.5238 83.2291 17.7194 83.3773 17.9621C83.5297 18.203 83.6106 18.4822 83.6106 18.7673C83.6021 19.1223 83.4596 19.461 83.2117 19.7154C82.9088 20.0378 82.5424 20.2941 82.1357 20.468C81.6181 20.6991 81.075 20.8683 80.5178 20.9721C79.8624 21.0926 79.1975 21.153 78.5311 21.1527C77.443 21.1669 76.3599 21.0017 75.3254 20.6636C74.4004 20.3696 73.5538 19.8704 72.8488 19.2032C72.1439 18.536 71.5988 17.7182 71.2544 16.8107C70.8569 15.7505 70.6653 14.6242 70.69 13.4922C70.6721 12.4012 70.8584 11.3165 71.2393 10.294C71.5698 9.42509 72.0831 8.63727 72.7443 7.9838C73.3857 7.3546 74.1559 6.87194 75.0019 6.56908C75.8714 6.24785 76.7914 6.08475 77.7184 6.08747C78.6256 6.07904 79.5265 6.23982 80.3748 6.56155C81.1434 6.8553 81.8466 7.29768 82.4442 7.8634C83.0231 8.42739 83.4769 9.10681 83.7761 9.85755C84.0981 10.656 84.2592 11.5101 84.2502 12.3709C84.2653 12.5716 84.2423 12.7733 84.1825 12.9654C84.1163 13.1546 84.0169 13.3304 83.889 13.4847C83.5873 13.7432 83.2152 13.9056 82.8204 13.9512L73.8881 15.238ZM77.6507 8.56323C77.1357 8.54826 76.6232 8.64051 76.1457 8.83414C75.6687 9.02881 75.2361 9.31807 74.8739 9.68447C74.0828 10.5665 73.6646 11.7211 73.7075 12.9052L81.18 11.8442C81.1189 10.9782 80.7661 10.1585 80.1791 9.51892C79.8518 9.18856 79.4573 8.9323 79.0224 8.76743C78.5875 8.60256 78.1223 8.53293 77.6582 8.56323H77.6507Z"
      fill="black"
    />
    <path
      d="M94.2286 8.68328C93.6147 8.6757 93.0047 8.78289 92.4301 8.99934C91.9009 9.2106 91.4253 9.53706 91.038 9.95503C90.6219 10.4079 90.302 10.9403 90.0973 11.5202C89.8487 12.2047 89.7288 12.9293 89.7437 13.6574C89.699 14.3189 89.7807 14.9829 89.9845 15.6139C90.1894 16.2475 90.5195 16.8335 90.9552 17.3372C91.3849 17.7606 91.8972 18.0911 92.4602 18.3079C93.0187 18.523 93.6157 18.6204 94.2136 18.5939C94.8861 18.6142 95.5567 18.5122 96.1927 18.2928C96.6983 18.1068 97.1767 17.8537 97.6149 17.5403C97.8634 17.6905 98.0806 17.8873 98.2546 18.1198C98.4198 18.3607 98.5041 18.648 98.4954 18.94C98.4626 19.2918 98.3306 19.6271 98.1148 19.9068C97.899 20.1865 97.6082 20.3993 97.2763 20.5203C96.2633 20.986 95.1545 21.2052 94.0405 21.1599C93.0018 21.1693 91.9681 21.0144 90.9778 20.7009C90.0905 20.413 89.2765 19.9354 88.5923 19.3012C87.9056 18.6392 87.3714 17.8354 87.0271 16.9459C86.6334 15.8951 86.4419 14.7793 86.4627 13.6574C86.4517 12.5241 86.6613 11.3995 87.0798 10.3463C87.4271 9.45717 87.9697 8.65738 88.6676 8.00602C89.3621 7.35385 90.1921 6.86307 91.0982 6.56873C92.6045 6.06876 94.2152 5.9702 95.7713 6.28278C96.2525 6.38713 96.718 6.5541 97.1559 6.77943C97.5021 6.9673 97.8067 7.22327 98.0514 7.53194C98.2387 7.7605 98.3492 8.04221 98.3674 8.33713C98.3812 8.62994 98.3022 8.91962 98.1417 9.16489C98.0036 9.38932 97.809 9.57356 97.5773 9.69917C97.13 9.41974 96.6561 9.18532 96.1626 8.99934C95.545 8.77896 94.8915 8.67684 94.2361 8.69833"
      fill="black"
    />
    <path
      d="M109.55 9.42836C108.884 8.89674 108.045 8.62887 107.194 8.67585C106.588 8.6675 105.985 8.77476 105.418 8.99191C104.917 9.18156 104.441 9.43444 104.004 9.74442V20.6784C103.807 20.7563 103.603 20.8119 103.394 20.8439C103.119 20.8988 102.839 20.9241 102.559 20.9192C102.098 20.959 101.636 20.8423 101.25 20.5881C101.098 20.4454 100.979 20.2706 100.903 20.0764C100.829 19.8797 100.804 19.6681 100.828 19.4593V1.03034L101.438 0.87984C101.729 0.811656 102.027 0.77881 102.326 0.782014C102.783 0.744932 103.239 0.864334 103.62 1.12064C103.77 1.26205 103.884 1.4376 103.951 1.63235C104.024 1.82662 104.05 2.03561 104.026 2.24188V7.0429C104.548 6.77907 105.092 6.56248 105.652 6.39574C106.328 6.19304 107.031 6.09409 107.736 6.10226C109.286 6.02787 110.809 6.52306 112.018 7.49441C113.082 8.42752 113.613 9.85478 113.613 11.7762V20.6784C113.417 20.7563 113.213 20.8119 113.004 20.8439C112.734 20.899 112.459 20.9242 112.184 20.9192C111.717 20.9624 111.25 20.8457 110.859 20.5881C110.709 20.4438 110.596 20.2658 110.528 20.0689C110.454 19.8747 110.428 19.6656 110.453 19.4593V11.874C110.493 11.4246 110.431 10.972 110.272 10.5496C110.12 10.1258 109.873 9.74239 109.55 9.42836Z"
      fill="black"
    />
  </svg>
);
