import React, { useState, useMemo, useEffect } from "react";
import { SOURCE } from "src/app/models/Site";
import { LinksBreadcrumbs, useBreadcrumbsPromotions, useBreadcrumbs, imageCarouselType } from "src/commons";
import { useParams, useHistory } from "react-router";
import { usePublicationByStock } from "src/app/business";
import { useCartContext } from "src/context";
import { Publication, FeatureGroup } from "src/app/models";
import { useBreadcrumbsCategory } from "src/commons/hooks/useBreadcrumbsCategory";
import { Analytics } from "src/platform/analytics";
import { searchSkuPublication, getProductImage, searchFeature } from "src/utils";
import { ProductNFTView } from "src/app/views/components/ProductNFTView";

export const SkeletonProductDetailsNFT = () => {
  const { publicationId } = useParams<{ publicationId: string }>();
  const [isTrack, setIsTrack] = useState(false);

  const { data: publication, working } = SOURCE.PUBLICATION(publicationId);

  const dataPublication = useMemo(() => {
    if (publication?.[0]) {
      const publicationData: Publication = { ...publication?.[0] };
      const {
        attributes: { features },
      } = publicationData;
      const newFeatures = features?.filter((feature) => feature.name !== "Categories");
      publicationData.attributes.features = newFeatures;
      return publicationData;
    }
    return undefined;
  }, [publication]);

  const { data: stock, working: workingStock } = usePublicationByStock(publicationId);

  const history = useHistory<any>();

  const links = useMemo(() => {
    if (publication?.[0]) {
      const linksProduct: LinksBreadcrumbs[] = [{ label: publication?.[0].product }];
      return linksProduct;
    }
    return [];
  }, [publication]);

  const nameCategory = useMemo(() => {
    if (publication?.[0]?.attributes?.features) {
      const featureCategory: FeatureGroup | undefined = searchFeature({
        name: "Categories",
        featuresAll: publication?.[0]?.attributes.features,
      });

      return featureCategory?.features?.[0]?.name;
    }
    return "";
  }, [publication]);

  const newLinksCategory = useBreadcrumbsCategory({ name: nameCategory });

  const newLinksPromo = useBreadcrumbsPromotions();

  const newLinks = useBreadcrumbs({
    links,
    oldLinks: history.location.state?.prevPath ? newLinksPromo : newLinksCategory,
  });

  const { cartState } = useCartContext();

  const productIdInCart = cartState.items.find((item) => item.publication.id === publicationId);

  const onBuyClick = () => {
    history.push({
      pathname: `/cart/checkout`,
      state: {
        goBack: newLinks,
      },
    });
  };

  const imagesCarousel: imageCarouselType[] = useMemo(() => {
    const images = publication?.[0].attributes?.images;
    const sku = searchSkuPublication({
      featuresPublication: publication?.[0].attributes.features,
    });
    if (images && sku) {
      return images.map((image) => ({
        img_url: getProductImage({
          sku,
          typeImage: image,
        }),
        alt: image,
      }));
    }
    return publication?.[0].attributes.image_url
      ? [
          {
            img_url: publication?.[0].attributes.image_url,
            alt: publicationId,
          },
        ]
      : [];
  }, [publication, publicationId]);

  const enableStock = useMemo(() => !!(stock && stock.quantity > 0), [stock]);

  const availableQuantity = useMemo(
    () => (stock && stock?.quantity > 0 ? stock?.quantity - (productIdInCart?.quantity || 1) : 0),
    [productIdInCart?.quantity, stock],
  );

  useEffect(() => {
    if (publication?.[0] && !isTrack) {
      setIsTrack(true);
      Analytics.trackVisitPublication({ publication: publication[0] });
    }
  }, [isTrack, publication]);

  return working ? (
    <div />
  ) : dataPublication ? (
    <ProductNFTView
      dataPublication={dataPublication}
      newLinks={newLinks}
      working={working}
      stock={stock}
      quantity={productIdInCart?.quantity || 1}
      availableQuantity={availableQuantity}
      imagesCarousel={imagesCarousel}
      enableStock={enableStock}
      workingStock={workingStock}
      onBuyClick={onBuyClick}
    />
  ) : (
    <>Producto no encontrado</>
  );
};
