import { Template } from "src/lib/templates";
import moment from "moment";

const date = moment().subtract(18, "year");

export const userInformationTemplate: Template = {
  name: "User Information Template",
  template: [
    {
      name: "name",
      type: { name: "string" },
      label: "Nombre",
      validators: [
        { name: "pattern", signature: ["^[A-Za-zÀ-ÿ\u00f1\u00d1 ]+$"] },
        { name: "max", signature: [30] },
        { name: "empty", errorMessage: "Completa este dato." },
      ],
    },
    {
      name: "lastName",
      type: { name: "string" },
      label: "Apellido",
      validators: [
        { name: "pattern", signature: ["^[A-Za-zÀ-ÿ\u00f1\u00d1 ]+$"] },
        { name: "max", signature: [30] },
        { name: "empty", errorMessage: "Completa este dato." },
      ],
    },
    {
      name: "document",
      type: { name: "string" },
      label: "Documento",
      validators: [
        {
          name: "pattern",
          signature: ["^[0-9]+$"],
        },
        { name: "length", signature: [8] },
        { name: "empty", errorMessage: "Completa este dato." },
      ],
    },
    {
      name: "dateOfBirty",
      type: { name: "date" },
      label: "Fecha de nacimiento",
      validators: [
        { name: "less", signature: [date], errorMessage: "Debes ser mayor de edad!" },
        { name: "empty", errorMessage: "Completa este dato." },
      ],
    },
    {
      name: "phone",
      type: {
        name: "object",
        kind: [
          {
            name: "areaCode",
            type: { name: "string" },
            label: "Cód. Área",
            validators: [
              {
                name: "pattern",
                signature: ["^[0-9]+$"],
              },
              {
                name: "max",
                signature: [4],
              },
            ],
          },
          {
            name: "number",
            type: { name: "string" },
            label: "Número de teléfono",
            validators: [
              {
                name: "pattern",
                signature: ["^[0-9]+$"],
              },
              {
                name: "max",
                signature: [20],
              },
            ],
          },
        ],
      },
    },
  ],
};
