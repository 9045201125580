import { EcommerceDAO } from "./EcommerceDAO";
import { Shipment } from "../../../app/models";

export interface ShipmentData {
  quantity: number;
  shipment_category_id: number;
}

export class ShipmentDAO extends EcommerceDAO<Shipment> {
  constructor() {
    super("shipment", "shipmentid");
  }

  async calculate(province: string, amount: number, shipmentData: ShipmentData[]): Promise<Shipment> {
    const response = await this.fetchData(`/shipment/calculate/${province}?amount=${amount}`, {
      method: "post",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(shipmentData),
    });
    return response.json();
  }
}
