import React, { useCallback } from "react";
import { FormControlLabel, List, FormHelperText } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { useField } from "react-final-form-hooks";
import { getErrorAndHelperTextNoTranslation } from "src/utils/materialHelpers";

export interface FormCheckboxTypesProps {
  name: string;
  label: React.ReactNode;
  form: any;
}

// TODO: hay que abstraer el uso de material como checkbox a la carpeta ui
// TODO: Manejar mejor es mensaje de error !
export const FormCheckbox = ({ name, form, label }: FormCheckboxTypesProps): JSX.Element => {
  const field = useField(name, form);

  const onChange = useCallback(
    (value) => {
      field.input.onChange(value.target.checked);
    },
    [field.input],
  );

  const { error } = getErrorAndHelperTextNoTranslation(field);

  return (
    <>
      <List key="list-item-one-checkbox" dense component="div" role="list" disablePadding>
        <FormControlLabel
          control={<Checkbox id={name} checked={field.input.value} onChange={(value) => onChange(value)} color="primary" />}
          label={label}
        />
        <FormHelperText error={error}>{error && "Debes aceptar los Términos y condiciones para continuar."}</FormHelperText>
      </List>
    </>
  );
};
