import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../lib/libby/generator";
import { AccountRol } from "../models";
import { businessOptions } from "./businessOptions";

const DAO_NAME = "account_rol";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "idAccountRol",
      labelKey: "descripcion",
    },
    withSelectedChips: {
      idKey: "idAccountRol",
      labelKey: "descripcion",
    },
  },
};

const optionsToUse = !businessOptions.AccountRol
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.AccountRol,
      hoc: { ...defaultOptions.hoc, ...businessOptions.AccountRol.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<AccountRol>(DAO_NAME, optionsToUse);

// hooks
export const useAccountRolFetchById = fetchById;
export const useAccountRolLibbyCall = libbyCall;
export const useAccountRolDAO = libbyDAO;
export const useAccountRolLibbyFetch = libbyFetch;

// components/hoc
export const AccountRolAutocomplete = entityAutocomplete;
export const AccountRolCall = renderPropCall;
export const AccountRolById = renderPropFetchById;
export const AccountRolSelect = simpleSelect;
export const AccountRolAccordionSelector = accordionSelector;
export const AccountRolSelectedChips = selectedChips;

// context
// ByIdContext
export const useAccountRolByIdContext = useByIdContext;
export const AccountRolByIdProvider = ByIdProvider;
export const AccountRolByIdContext = ByIdContext;
// GeneralContext
export const useAccountRolContext = useGeneralContext;
export const AccountRolProvider = GeneralProvider;
export const AccountRolContext = GeneralContext;
