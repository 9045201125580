import React, { FunctionComponent, useEffect, useState } from "react";
import { DatabaseStore } from "./platform/libby/DatabaseStore";
import { SessionChangeEmitter } from "./platform/libby/session";
import { PhinxApp } from "./lib/profiles/PhinxApp";
import { useProfileContext } from "./lib/profiles/ProfileContext";
import { Loading } from "./commons/components/Loading";
import { Analytics } from "./platform/analytics";
import { useRolesContext, UserInfo } from "./context";

// Initialize Libby
DatabaseStore.init();

// Trackers
// TODO : pasar id segun ecommerse
Analytics.initialize("UA-190202452-4");

export const App: FunctionComponent = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [initialProfileLoaded, setInitialProfileLoaded] = useState(false);
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined);
  const { setProfile } = useProfileContext();
  const { setUserInfo, setSelectedRole } = useRolesContext();

  useEffect(
    () =>
      SessionChangeEmitter.subscribe(({ isLogged: logged, user }: { isLogged: boolean; user: UserInfo }) => {
        // Esta logica es para manejar la seleccion del rol y la seleccion de las reglas de permiso
        if (logged) {
          setUserInfo(user);
          const rol = {
            rol_id: user.groups[0].id,
            rol_name: user.groups[0].name,
          };
          setSelectedRole(rol);
        }
        // if (user.groupSelected) {
        //   desactivateAllRulesSets();
        //   activateRulesSet(`rol-${user.groupSelected.rol.id}`);
        //   activateRulesSet(`nivel-${user.groupSelected.nivel.idNivel}`);
        //   setSelectedRole({});
        // } else {
        //   desactivateAllRulesSets();
        // }
        setIsLogged(logged);
        setTimeout(() => setIsFirstLoading(false), 0);
      }),
    [setUserInfo, setSelectedRole],
  );

  useEffect(() => {
    if (!isFirstLoading) {
      if (!isLogged) {
        setProfile("public");
      } else {
        setProfile("private");
      }
      setInitialProfileLoaded(true);
    }
  }, [isFirstLoading, isLogged, setProfile]);

  return !initialProfileLoaded ? <Loading /> : <PhinxApp />;
};
