import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../../lib/libby/generator";
import { Publication } from "../../models";
import { businessOptions } from "../businessOptions";

const DAO_NAME = "publication_by_stock";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "idPublicationByStock",
      labelKey: "descripcion",
    },
    withSelectedChips: {
      idKey: "idPublicationByStock",
      labelKey: "descripcion",
    },
  },
};

const optionsToUse = !businessOptions.PublicationByStock
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PublicationByStock,
      hoc: { ...defaultOptions.hoc, ...businessOptions.PublicationByStock.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Publication>(DAO_NAME, optionsToUse);

// hooks
export const usePublicationByStockFetchById = fetchById;
export const usePublicationByStockLibbyCall = libbyCall;
export const usePublicationByStockDAO = libbyDAO;
export const usePublicationByStockLibbyFetch = libbyFetch;

// components/hoc
export const PublicationByStockAutocomplete = entityAutocomplete;
export const PublicationByStockCall = renderPropCall;
export const PublicationByStockById = renderPropFetchById;
export const PublicationByStockSelect = simpleSelect;
export const PublicationByStockAccordionSelector = accordionSelector;
export const PublicationByStockSelectedChips = selectedChips;

// context
// ByIdContext
export const usePublicationByStockByIdContext = useByIdContext;
export const PublicationByStockByIdProvider = ByIdProvider;
export const PublicationByStockByIdContext = ByIdContext;
// GeneralContext
