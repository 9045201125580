import { EcommerceConfig } from "../../../app/models/general/ecommerceConfig";
import { assets } from "./assets";
import { general } from "./general";
import { ecommercePalette } from "./ecommercePalette";
import { ecommerceTypography } from "./ecommerceTypography";
import { ecommerceLogo } from "./ecommerceLogo";

export const detecnologiaConfig: EcommerceConfig = {
  assets,
  general,
  ecommercePalette,
  ecommerceTypography,
  ecommerceLogo,
};
