import { AgueroProfile, DepaulProfile, MaradonaProfile, NeymarProfile, SorinProfile, ZidaneProfile, ProfileAvatarEmpty } from "src/assets/images";

export const profileImage = (name: string) => {
  switch (name) {
    case "Zinedine Zidane":
      return ZidaneProfile;
    case "Maradona":
      return MaradonaProfile;
    case "Juan Pablo Sorin":
      return SorinProfile;
    case "Kun Aguero":
      return AgueroProfile;
    case "Rodrigo de Paul":
      return DepaulProfile;
    case "NEYMAR JR.":
      return NeymarProfile;
    default:
      return ProfileAvatarEmpty;
  }
};
