import React, { useMemo } from "react";
import { Profile as ProfileType } from "./types";
import { Page } from "./Page";
import { createRouter } from "../router";

export const Profile = ({ profile }: { profile: ProfileType }) => {
  const { RouterProvider, Routes } = useMemo(() => createRouter(profile.menu), [profile]);
  const ProfileComp = profile.component;
  return (
    <RouterProvider>
      <ProfileComp menu={profile.menu}>
        <Page>
          <Routes />
        </Page>
      </ProfileComp>
    </RouterProvider>
  );
};
