import React, { useEffect } from "react";
import { useMediaQuery, Theme, Grid, Typography, Container, Box } from "@material-ui/core";
import { useHistory } from "react-router";
import { sideMenuIcons } from "src/commons";
import { DashboarMenu } from "./components";

export const Dashboard = () => {
  const isUp768 = useMediaQuery<Theme>((theme) => theme.breakpoints.up(769));
  const history = useHistory();

  useEffect(() => {
    if (!isUp768) return;
    history.push("/dashboard/profile");
  }, [isUp768, history]);

  return (
    <Container maxWidth="lg">
      <Box px={!isUp768 ? 0 : "10%"} mt={2}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              Mi cuenta
            </Typography>
          </Grid>
          <Grid item container direction="column">
            {sideMenuIcons.map((sideMenu) => (
              <DashboarMenu listItem={sideMenu} />
            ))}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
