import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { parse } from "query-string";
import { useConfirmationDAO } from "src/app/business";
import { Container, Grid } from "@material-ui/core";
import { Loading } from "src/commons";
import { OAuthProvider } from "src/app/models";
import { useLogin } from "../../Login/hooks";

export const FacebookLogin = () => {
  const history = useHistory();
  const confirmationDAO = useConfirmationDAO();
  const { singIn } = useLogin();

  useEffect(() => {
    (async () => {
      const params = parse(history.location.hash);
      const result = await confirmationDAO.verifyToken(params.access_token);
      if (result.isValid) {
        await singIn({ provider: OAuthProvider.FACEBOOK, token_id: params.access_token as string });
      } else {
        history.push("/login");
      }
    })();
  }, [confirmationDAO, history, singIn]);

  return (
    <Container maxWidth="md">
      <Grid container justify="center" alignContent="center">
        <Loading />
      </Grid>
    </Container>
  );
};
