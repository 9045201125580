import { Publication } from "./publication";
import { PaymentModel } from "./PaymentModel";
import { City, State } from "./Location";

export interface PaymentExtraDataModel extends PaymentModel {
  discount: number;
  interest: number;
  typePayment: string;
  amountTotal: string;
}

export interface PaymentModelWithCustom extends PaymentModel {
  customStyles?: any;
}

export type PaymentOrder = {
  id?: string;
  order: Order;
  state: PaymentState;
  created_at: Date;
  updated_at: Date;
};

export type PaymentState = "Simulation" | "Pending" | "Approved" | "Revision" | "Rejected" | "No Funds" | "Manual Revision" | "Charge Back";

export type Order = {
  id?: string;
  buyer: Buyer;
  address: BuyerAddress;
  shipment: BuyerAddress;
  shipment_cost: number;
  tax: number;
  subtotal: number;
  total: number;
  created_at: Date;
  updated_at: Date;
  state: OrderState;
  items: Array<OrderItem>;
};

export type OrderItem = {
  quantity: number;
  publication: Omit<Publication, "has_stock">;
};

export type OrderState = "Created" | "Pending" | "Payment Problem" | "Paid" | "Cancelled";

export type Buyer = {
  buyer_id?: string;
  account_id?: number;
  email: string;
  name: string;
  phone: string;
  date_of_birth: string;
  last_name: string;
  addresses?: Array<BuyerAddress>;
  document_number: string;
  document_type: string;
  company: string;
};

export type BuyerAddress = {
  buyer_address_id?: string;
  street: string;
  number: number;
  floor: string;
  department: string;
  city_id: string;
  zip: string;
  comments: string;
};

export interface BuyerWallet {
  buyer_wallet_id?: string;
  buyer_id: string;
  wallet: string;
  nick?: string;
}

export interface BuyerAddressInfo extends Omit<BuyerAddress, "city_id"> {
  city: City;
  state: State;
  is_selected: boolean;
  is_deleted?: boolean;
}

export interface BuyerData extends Omit<Buyer, "addresses"> {
  addresses: Array<BuyerAddressInfo>;
}

export type Payment = PaymentModel & PaymentOrder & { promotion_id: number };

export type PaymentCancelation = {
  firstName: string;
  lastName: string;
  DNI: string;
  phoneNumber: string;
  email: string;
  emailConfirm: string;
  buyNumber: string;
  product: string;
  motive: string;
};

export interface ResponseDecidir {
  id: string;
  status: string;
  card_number_length: number;
  date_created: string;
  bin: string;
  last_four_digits: string;
  security_code_length: number;
  expiration_month: number;
  expiration_year: number;
  date_due: string;
  cardholder: {
    identification: {
      type: string;
      number: string;
    };
    name: string;
  };
}

interface Validation_Errors {
  code: string;
  param: string;
}

export interface StatusPayment {
  metadata: {
    result: string;
    status: number;
    status_details:
      | string
      | {
          error: any;
          ticket: string;
          address_validation_code: string;
          card_authorization_code: string;
        };
    fraud_detection?: {
      status: {
        review: any;
        details: {
          error_type: string;
          validation_errors: Array<Validation_Errors>;
        };
        decision: "green" | "yellow" | "red" | "blue" | "black";
        request_id: any;
        description: string;
        reason_code: number;
      };
    };
  };

  payment_state_id: string;
  payment_state_name: string;
}

export const PAYMENT_STATUS = {
  APPROVED: "abb0c62c-3555-4a85-9e4a-56c497860105",
  PENDING: "e8ecddc6-21f7-4910-b874-bd26e25e1e58",
};

export enum PAYMENT_METHODS {
  VISA_CREDIT = 1,
  VISA_DEBIT = 31,
  MASTER_PRISMA_CREDIT = 104,
  MASTER_PRISMA_DEBIT = 105,
  AMEX = 65,
  NARANJA = 24,
}

export const CARD_EMITER: { [k: number]: { emitter: string; type: string } } = {
  [PAYMENT_METHODS.VISA_CREDIT]: { emitter: "Visa", type: "Crédito" },
  [PAYMENT_METHODS.VISA_DEBIT]: { emitter: "Visa", type: "Débito" },
  [PAYMENT_METHODS.MASTER_PRISMA_CREDIT]: { emitter: "Master", type: "Crédito" },
  [PAYMENT_METHODS.MASTER_PRISMA_DEBIT]: { emitter: "Master", type: "Débito" },
  [PAYMENT_METHODS.AMEX]: { emitter: "AMEX", type: "Crédito" },
  [PAYMENT_METHODS.NARANJA]: { emitter: "Naranja", type: "Crédito" },
};

export interface TokenizedCard {
  token: string;
  payment_method_id: number;
  bin: string;
  last_four_digits: string;
  expiration_month: string;
  expiration_year: string;
  card_holder: {
    identification: {
      type: string;
      number: string;
    };
    name: string;
    nro_puerta: number;
  };
  expired: boolean;
}

export enum ORDER_STATE {
  CREATED = "1",
  SELLED = "2",
  ONQUEQUE = "3",
  WAITING_FOR_PREPARATION = "4",
  PREPARED = "5",
  READY_FOR_DELIVERY = "6",
  OUT_FOR_DELIVERY = "8",
  DELIVERED = "9",
  CANCELLED = "10",
  PRODUCTION = "11",
  PAID = "12",
  READY_TO_RESEND = "14",
  RETURNED = "15",
  TRACKER_INFORMED = "16",
  BILL_INFORMED = "17",
  COLLECTED = "18",
  WAITING_FOR_EXTERNAL_DELIVERY = "19",
  EXTERNAL_DELIVERY_READY = "20",
  WAITNG_FOR_APPROVAL = "21",
  WAITING_TO_CANCEL = "22",
  RETURNED_COMPLETED = "23",
  RETURNING_TO_CANCEL = "24",
  READY_FOR_PREPARATION = "25",
  WAITING_FOR_RETURN = "26",
  CANCELLATION_FOR_FRAUD = "27",
  RETAINED = "28",
}

export interface MetadataMercadoPago {
  ip_address: string;
}
