import { useEffect, useState, useCallback } from "react";
import Fuse from "fuse.js";
import { useHistory, useParams } from "react-router";
import { flatten, groupBy, uniqBy, orderBy, Many, ListIteratee } from "lodash";
import { getDiscountPublication, getProductImage, searchSkuPublication, blankSpaces } from "src/utils";
import { PUBLICATION_TYPE } from "src/app/const";
import { useGeneralContext } from "src/context";
import { getPricePublication } from "src/context/CartContext/utils";
import { PagePagination, Publication, Feature, FeatureGroup, Category, ConfigValues } from "../../app/models";
import { Product } from "../../app/models/business/Product";
import { FilterList, FILTER_TYPE, GenericFilterData } from "../ui/view/GenericFilter";

export enum SEARCH_TYPES {
  category,
  all,
}

const options = {
  keys: [
    "product",
    "sku",
    "attributes.nftInfo.location",
    "attributes.nftInfo.start",
    "attributes.nftInfo.subTitle",
    "attributes.nftInfo.date",
    "attributes.description",
    "attributes.ownerNick",
  ],
  useExtendedSearch: true,
  threshold: 0.6,
};

export interface SearchProps {
  searchType: SEARCH_TYPES;
  publications: Publication[];
  categoriesSource: Category[];
  byZone?: boolean;
  defaultFilter?: FilterList[];
}

const initialPagination = {
  perPage: 12,
  currentPage: 1,
  allCount: 0,
};

export enum SORT_TYPES {
  big_discount = 1,
  bests_sellers,
  relevance,
  higher_price,
  lower_price,
  free_shipment, // no es un sort
}

export interface SortOptions {
  id: number;
  name: string;
}

export const sortOptions: SortOptions[] = [
  // { id: 1, name: "Mayor descuento" },
  // { id: 2, name: 'Mas vendidos' },
  { id: 3, name: "Relevancia" },
  { id: 4, name: "Mayor precio" },
  { id: 5, name: "Menor precio" },
];

export interface SortListFuntion {
  [k: number]: Many<ListIteratee<Product>>;
}

export interface FilterByCategoryItems extends Category {
  items: GenericFilterData[];
}

export interface SubFilterByCategory extends Category {
  items: FeatureGroup[];
}

export interface SortList {
  sortOpenModal: boolean;
  setSortOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  list: Product[];
  sortOptions: SortOptions[];
  sortFilter: SortOptions | null;
  setSortFilter: React.Dispatch<React.SetStateAction<SortOptions | null>>;
}

export const orderListFuntions: SortListFuntion = {
  [SORT_TYPES.big_discount]: [(product: Product) => getDiscountPublication(product.best[0].amount.toString(), parseInt(product.old_price, 10))],
  [SORT_TYPES.higher_price]: [(product: Product) => parseInt(product.best[0].amount.toString(), 10)],
  [SORT_TYPES.lower_price]: [(product: Product) => parseInt(product.best[0].amount.toString(), 10)],
  [SORT_TYPES.relevance]: "priority",
  [SORT_TYPES.free_shipment]: [(product: Product) => product.shipment_category_id === 1 && getPricePublication(product) > 2000],
};

const collator = new Intl.Collator("en", { numeric: true, sensitivity: "base" });

export const useSearch = ({ searchType, publications, categoriesSource, byZone, defaultFilter = [] }: SearchProps) => {
  const [results, setResults] = useState<Product[]>([]);
  const [list, setList] = useState<Product[]>([]);
  const [filterContent, setFilterContent] = useState<GenericFilterData[]>([]);
  const [filters, setFilter] = useState<FilterList[]>(defaultFilter);
  const [categories, setCategories] = useState<Category[]>([]);
  const [sortOpenModal, setSortOpenModal] = useState(false);
  const params = useParams<{ value: string }>();
  const history = useHistory();
  const [freeShipment, setFreeShipment] = useState<boolean>(false);

  const { generalState, dispatch } = useGeneralContext();
  const [pagination, setPagination] = useState<PagePagination>({ ...initialPagination, currentPage: generalState.paginate.page });
  const [sortFilter, setSortFilter] = useState<SortOptions>(generalState.sortOption);

  useEffect(() => {
    if (generalState.sortOption.id !== sortFilter.id) {
      dispatch.sortOption(sortFilter);
    }
  }, [dispatch, sortFilter, generalState.sortOption.id]);

  const onSortFilterChange = useCallback(
    (nValue: SortOptions) => {
      if (sortFilter.id !== nValue.id) {
        dispatch.sortOption(nValue);
        setSortFilter(nValue);
        dispatch.setPaginate({ page: 1, path: "/" });
      }
    },
    [dispatch, sortFilter],
  );

  const onSearch = useCallback(
    (value: string, data: Publication[], type: SEARCH_TYPES) => {
      const dataWithSku = data.map((item) => {
        item.attributes.features?.forEach((feature) => {
          if (feature.name === "Generales")
            feature.features?.forEach((sku) => {
              if (sku.name === "SKU") item.sku = sku.value;
            });
        });
        return item;
      });
      const fuse = new Fuse(dataWithSku, { ...options, minMatchCharLength: value?.length });
      const myResults = !!value && type === SEARCH_TYPES.all && !byZone ? fuse.search(`'${value}`).map((item) => item.item) : data;
      const all = myResults
        .filter((publication) => publication.type === PUBLICATION_TYPE.SALE && publication.old_price.toString() !== "0")
        .map((item): Product => {
          const sku = searchSkuPublication({ featuresPublication: item.attributes?.features || [] }) || "sku";
          return {
            productId: item.id,
            name: item.product,
            sku,
            price: item.price,
            old_price: item.old_price.toString(),
            images: item.attributes?.images?.map((image: string) => getProductImage({ sku, typeImage: image, width: 265 })) || [],
            description: "",
            priority: "",
            attributes: {
              ...item.attributes,
              description: item.attributes.description || "",
              images: item.attributes.images || [],
              title: item.attributes.title || "",
              features: item.attributes?.features || [],
            },
            best: item.best,
            highlights: item.highlights,
            shipment_category_id: item.shipment_category_id,
            targets: item.targets,
            config_values: item.config_values,
          };
        });
      setResults(all);
    },
    [byZone],
  );

  useEffect(() => {
    if (generalState.paginate.path === "/product") {
      dispatch.sortOption(generalState.sortOption);
    } else {
      dispatch.setPaginate({ page: 1, path: history.location.pathname });
    }
  }, [history.location.pathname, generalState.paginate.path, dispatch, generalState.sortOption]);

  const getFilterContents = useCallback((products: Product[]) => {
    if (!products.length) return { filters: [] as GenericFilterData[], filtersByCategories: [] as Array<FilterByCategoryItems> };
    const featureGroups = products.reduce<FeatureGroup[]>((acum, product) => {
      acum = [...acum, ...product.attributes.features];
      return acum;
    }, []);

    const groupeFeatureGroup = groupBy(featureGroups, "name");

    const featureFilters = Object.entries(groupeFeatureGroup).reduce<GenericFilterData[]>((acum, [key, value]) => {
      const fixName = blankSpaces(key);
      const flattenFeatures = flatten(
        value.filter((item) => item.searchable).map((item) => item.features.filter((item) => item.searchable).map((item) => item)),
      );

      const filterValuesFeatures = flattenFeatures.reduce<Feature[]>((acum, feature) => {
        const validate = acum.find(
          (item) =>
            item.name.split(" ").join("") === feature.name.split(" ").join("") &&
            item.value.split(" ").join("") === feature.value.split(" ").join(""),
        );
        if (!validate) {
          acum.push(feature);
        }
        return acum;
      }, []);

      const groupByNameFeature = groupBy(filterValuesFeatures, "name");

      const to_save: GenericFilterData = {
        title: fixName,
        items: orderBy(
          Object.entries(groupByNameFeature)
            .filter(([key]) => !(key.toLowerCase() === "sku" || key.split(" ").join("").toLocaleLowerCase() === "marca"))
            .map<FilterList>(([key, value]) => ({
              value: "item",
              name: key,
              type: FILTER_TYPE.TEXT,
              selected: false,
              subItems: value
                .map((item) => ({ value: item.name, name: item.value, type: FILTER_TYPE.TEXT, selected: false }))
                .sort((a, b) => collator.compare(a.name, b.name)),
            })),
          "name",
        ),
        hide: false,
        type: FILTER_TYPE.TEXT,
      };
      acum.push(to_save);
      return acum;
    }, []);

    const categoriesFromFeatures: Category[] = [];
    products.reduce<Array<SubFilterByCategory>>((acum, product) => {
      const category = product.attributes.features.find((item) => item.name === "Categories");
      if (category) {
        category.features.forEach((pub) => {
          categoriesFromFeatures?.push({
            name: pub.name,
            id: pub?.category_id || "",
            config_values: {} as ConfigValues,
            publications: [],
            relevance: (pub as unknown as Category).relevance,
          });
          const exist = acum.find((a) => a.name === pub.name);
          if (exist) {
            exist.items.push(...product.attributes.features.filter((item) => item.name !== "Categories").map((item) => item));
          } else {
            acum.push({
              id: pub.category_id || "",
              name: pub.name,
              config_values: {} as ConfigValues,
              publications: [],
              items: [...product.attributes.features.filter((item) => item.name !== "Categories").map((item) => item)],
            });
          }
        });
      }
      return acum;
    }, []);

    const categoryFilter: GenericFilterData = {
      title: "Stars",
      type: FILTER_TYPE.CATEGORY,
      items: uniqBy(categoriesFromFeatures, (category: Category) => category.id)
        .map((category) => ({
          name: category.name,
          value: category.id,
          type: FILTER_TYPE.CATEGORY,
          selected: false,
          relevance: Number(category.relevance),
        }))
        .sort((a, b) => a.relevance - b.relevance)
        .map((item) => ({ name: item.name, value: item.value, type: item.type, selected: item.selected })),
    };

    const marcas = products.reduce<FilterList[]>((acum, publication) => {
      const nameMarca = publication.attributes.features
        ?.find((item) => item.name === "Características Generales")
        ?.features.find((_item) => _item.name === "Marca");
      if (nameMarca) {
        const exist = acum.find(
          (marca) => (marca.name as string).split(" ").join("").toLocaleLowerCase() === nameMarca.value.split(" ").join("").toLocaleLowerCase(),
        );
        if (!exist)
          acum.push({
            name: nameMarca.value,
            value: nameMarca.name,
            type: FILTER_TYPE.MARK,
            selected: false,
          });
      }
      return acum;
    }, []);

    const marcasFilter: GenericFilterData = {
      title: "Marcas",
      type: FILTER_TYPE.MARK,
      items: marcas,
    };

    return { filters: [marcasFilter, categoryFilter, ...featureFilters] };
  }, []);

  const order = useCallback(
    (products: Product[]) => {
      if (!sortFilter) return products;
      const productsOrder = orderBy(products, orderListFuntions[sortFilter.id || 1], sortFilter.id === SORT_TYPES.lower_price ? "asc" : "desc");
      return productsOrder;
    },
    [sortFilter],
  );

  const filter = useCallback(
    (data: Product[], filterList: FilterList[]) => {
      if (!filterList.length) return freeShipment ? data.filter((item) => item.shipment_category_id === 1 && getPricePublication(item) > 2000) : data;
      const products: Product[] = [];
      data.forEach((product: Product) => {
        const features = flatten(
          product.attributes?.features?.map((featureGroup: FeatureGroup) => featureGroup.features?.map((feature: Feature) => feature)),
        ) as Feature[];
        let match = true;
        filterList
          .filter((item) => item.type !== FILTER_TYPE.CATEGORY)
          .forEach((filterItem: FilterList) => {
            const { name, value, type } = filterItem;
            if (type === FILTER_TYPE.TEXT || type === FILTER_TYPE.MARK) {
              match = !!features?.find((feature) => feature.name === value && feature.value.toLowerCase() === name.toLowerCase());
            } else if (type === FILTER_TYPE.PRICE && typeof value !== "string") {
              match = Number(product.best[0].amount.toString()) >= value.min && Number(product.best[0].amount.toString()) <= value.max;
            }
          });
        if (match) products.push(product);
      });
      const filteredProducts = freeShipment
        ? products.filter((item) => item.shipment_category_id === 1 && getPricePublication(item) > 2000)
        : products;
      return uniqBy(flatten(filteredProducts), (product) => product.productId);
    },
    [freeShipment],
  );

  const onAddFilter = useCallback(
    (item: FilterList) => {
      if (item.type === FILTER_TYPE.CATEGORY) {
        const category = categories.find(({ id, name }) => id === item.value || name === item.value);
        history.push({
          pathname: `/categories/${category?.id}`,
          state: {
            search: params.value,
            category: { ...item, name: item.name, value: "Categoria" },
          },
        });
        return null;
      }
      if (item.type === FILTER_TYPE.PRICE) {
        const filterPriceActive = filters.find(({ type }) => type === FILTER_TYPE.PRICE);
        if (filterPriceActive) return null;
      }
      const exist = filters.find(({ name, value }) => value === item.value && name === item.name);
      if (exist) return null;
      setFilter((prev) => [...prev, item]);
      return null;
    },
    [filters, setFilter, history, params, categories],
  );

  const onRemoveFilter = useCallback(
    (item: FilterList) => {
      if (item.type === FILTER_TYPE.CATEGORY) {
        history.push({
          pathname: `/search/`,
        });
        return null;
      }
      const index = filters.findIndex(({ value, name }) => name === item.name && value === item.value);
      setFilter([...filters].filter((_, i) => i !== index));
    },
    [filters, setFilter, history],
  );

  const onChangePagination = useCallback(
    (page: number) => {
      setPagination((prev: PagePagination) => ({ ...prev, currentPage: page }));
      dispatch.setPaginate({ ...generalState.paginate, page });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    [generalState.paginate, dispatch],
  );

  useEffect(() => {
    if (publications?.length) onSearch(params.value, publications, searchType);
  }, [publications, params, onSearch, searchType]);

  useEffect(() => {
    const data = getFilterContents(results);
    setFilterContent(data.filters);
  }, [results, getFilterContents, setFilterContent]);

  useEffect(() => {
    const filteredData = filter(results, filters);
    const result = order(filteredData);
    const updatedPagination = { ...pagination, allCount: result.length };
    const indexTo = updatedPagination.currentPage * updatedPagination.perPage;
    const indexFrom = indexTo - updatedPagination.perPage;
    setList([...result].slice(indexFrom, indexTo));
    if (pagination.allCount !== updatedPagination.allCount) setPagination(updatedPagination);
    const data = getFilterContents(result);
    setFilterContent(data.filters);
  }, [filters, order, filter, results, pagination, setPagination, getFilterContents, setFilterContent]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, currentPage: generalState.paginate.page }));
  }, [filters, setPagination, generalState.paginate.page]);

  useEffect(() => {
    if (categoriesSource.length) setCategories(categoriesSource);
  }, [categoriesSource]);

  return {
    pagination,
    onChangePagination,
    params,
    filterContent,
    onAddFilter,
    onRemoveFilter,
    filters,
    list,
    sortOpenModal,
    setSortOpenModal,
    sortFilter,
    setSortFilter: onSortFilterChange,
    freeShipment,
    setFreeShipment,
  };
};
