import React, { useCallback, useState } from "react";
import { Typography, Grid, Box, Card, CardContent, CardMedia, makeStyles, IconButton, Menu, MenuItem, Avatar } from "@material-ui/core";
import { useCurrencyContext } from "src/context/CurrencyContext";
import { Publication } from "src/app/models";
import { useRolesContext } from "src/context";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { useHistory } from "react-router";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import { getPricePublication } from "src/context/CartContext/utils";
import { EthereumPrice } from "src/app/services";
import { formatUnits } from "ethers/lib/utils";
import { ETHicon } from "src/assets/images/svgs";
import { SalesNFTModal } from "../SalesNFTModal";
import { FavoriteButtonView } from "../FavoriteButtonView";

export const useStyles = makeStyles(() => ({
  container: {
    margin: 10,
    position: "relative",
    minHeight: "450px",
  },
  avatar: {
    width: 16,
    height: 16,
    fontSize: 8,
    borderRadius: "50%",
  },
}));

interface PurchasedItemsCardProps {
  item: Publication;
}
interface PriceProps {
  value: string;
  img: any;
}

export const PurchasedItemCard = ({ item }: PurchasedItemsCardProps, { value, img }: PriceProps) => {
  const classes = useStyles();
  const { userInfo } = useRolesContext();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { selectedCurrency } = useCurrencyContext();

  const getPriceCurrency = () => {
    if (!item) return "0.0";
    return selectedCurrency === "USD"
      ? formatUnits(getPricePublication(item)) !== "0.0"
        ? EthereumPrice.getPriceBy({ value: formatUnits(getPricePublication(item)) })
        : "No está a la venta"
      : formatUnits(getPricePublication(item)) !== "0.0"
      ? formatUnits(getPricePublication(item))
      : "No está a la venta";
  };

  const getIcon = () => {
    if (formatUnits(getPricePublication(item)) !== "0.0") return selectedCurrency === "USD" ? `$` : <ETHicon width="14" color="white" />;
  };

  value = getPriceCurrency();
  img = getIcon();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewVideo = useCallback(() => {
    history.push(`/product/${item?.id}`);
  }, [history, item?.id]);

  const nick = userInfo.name?.split(" ")[0].substring(0, 1).toUpperCase() + userInfo.lastname?.split(" ")[0].substring(0, 1).toUpperCase();

  return (
    <Card className={classes.container}>
      <CardMedia component="img" height={230} src={item?.attributes.videoInfo.picture[0].url} />

      <CardContent>
        <Grid container direction="column">
          <Box ml={0} position="relative">
            <Grid container justify="center" alignItems="center">
              <Grid item xs={11}>
                <Typography align="left" variant="h5" color="primary">
                  {item?.attributes.nftInfo.start.toUpperCase()}
                </Typography>
              </Grid>
              <Grid item xs={1} container justify="flex-end">
                <Box position="relative" right={-15}>
                  <IconButton onClick={handleClick}>
                    <MoreVertOutlinedIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <FavoriteButtonView withBg top={8} right={8} publicationId={item.id} />
          <Grid item xs>
            <Typography variant="subtitle1">{item?.attributes.nftInfo.subTitle}</Typography>
          </Grid>
          <Grid item container alignItems="center" style={{ marginTop: 10 }}>
            <Grid item>
              <Avatar aria-label="recipe" variant="rounded" className={classes.avatar}>
                {nick}
              </Avatar>
            </Grid>
            <Box pl="8px" color="#737373">
              <Typography variant="body2">{userInfo.name}</Typography>
            </Box>
          </Grid>

          <Box width="100%" display="flex" px={1} position="absolute" bottom={0} right={0}>
            <Grid container alignContent="flex-end" justify="space-between" alignItems="center">
              <Grid item>
                <Typography>
                  {img} {value}
                </Typography>
              </Grid>

              <Grid item>
                <IconButton color="primary" onClick={handleViewVideo}>
                  <PlayCircleFilledIcon color="primary" fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Menu
          id="menu-appbar-language"
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          transformOrigin={{ horizontal: -40, vertical: 10 }}
          keepMounted
          open={!!anchorEl}
          onClose={handleClose}
        >
          <SalesNFTModal component={MenuItem} item={item} />
        </Menu>
      </CardContent>
    </Card>
  );
};
