import { useCallback } from "react";
import { createOrder, createPaymentDecidir } from "src/utils/paymentOrderHelper";
import { CartItem, ResponseDecidir, Payment, StatusPayment, Shipment, PAYMENT_STATUS } from "src/app/models";
import { useCartContext } from "src/context";
import { usePaymentDAO } from "src/app/business";
import { Decidir } from "src/app/services";
import moment from "moment";
import { ValuesForm } from "../types";

let nCall = 0;

export const useProcessingPayment = () => {
  const { cartState } = useCartContext();
  const paymentDAO = usePaymentDAO();

  const checkStatusPayment = useCallback(
    async (id: string, cb: (status: StatusPayment) => void) => {
      const _status: StatusPayment = await paymentDAO.checkPayment(id);
      if (_status.payment_state_id !== PAYMENT_STATUS.PENDING || nCall === 3) {
        nCall = 0;
        cb(_status);
      } else {
        nCall += 1;
        setTimeout(checkStatusPayment, 10000, id, cb);
      }
    },
    [paymentDAO],
  );

  const getPayment = useCallback(
    async (values: ValuesForm, deviceId: string, deliveryData: Shipment): Promise<Payment> => {
      try {
        const {
          deliveryInfo: { streetNumber },
          useDeliveryAddress,
          cardInfo: { cardExpiration, cardHolder, cardNumber, cardSecurityCode },
          personalInfo: { document, dateOfBirty },
          isResponsable,
          paymentMethod: { payment },
          responsableInscripto = { cuit: "" },
          summaryAddress,
        } = values;
        const { cuit } = responsableInscripto;

        const holder_door_number = useDeliveryAddress ? streetNumber : summaryAddress?.streetNumber;

        const ipAddress = await Decidir.getIp();
        const body = {
          card_number: cardNumber,
          card_expiration_month: cardExpiration.month,
          card_expiration_year: cardExpiration.year,
          security_code: cardSecurityCode,
          card_holder_name: cardHolder,
          card_holder_door_number: Number(holder_door_number),
          card_holder_birthday: moment(dateOfBirty).format("DDMMYYYY"),
          card_holder_identification: {
            type: isResponsable === "true" ? "cuit" : "dni",
            number: isResponsable === "true" ? cuit : document,
          },
          fraud_detection: {
            device_unique_identifier: deviceId,
          },
          ip_address: ipAddress,
        };

        const result: ResponseDecidir = await Decidir.getToken(body);

        const { items, owner, lastUpdate = new Date(), state_id } = cartState;
        const itemsFix: CartItem[] = items.map(({ quantity, publication }) => ({
          quantity,
          publication,
        }));
        const order = await createOrder({ items: itemsFix, owner, lastUpdate, state_id }, values, deliveryData);

        const paymentBody = createPaymentDecidir(order, payment, result);

        paymentBody.metadata.ip_address = ipAddress;
        paymentBody.metadata.device_unique_identifier = deviceId;

        const resultPayment: Payment = await paymentDAO.paymentDecidir(paymentBody);

        return resultPayment;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    [paymentDAO, cartState],
  );

  return { getPayment, checkStatusPayment };
};
