import { StatusResponse } from "src/app/services";

class SessionFBChangeEmitter {
  // eslint-disable-next-line @typescript-eslint/ban-types
  subscriptions: Function[];

  constructor() {
    this.subscriptions = [];
  }

  subscribe(fn: (params: StatusResponse) => any): () => void {
    this.subscriptions.push(fn);
    return () => this.unsubscribe(fn);
  }

  unsubscribe(fn: (params: any) => any): void {
    this.subscriptions = this.subscriptions.filter((item) => item !== fn);
  }

  emitChange<F extends any[]>(...args: F): void {
    this.subscriptions.forEach((func) => func(...args));
  }
}

const i = new SessionFBChangeEmitter();
export { i as SessionFBChangeEmitter };
