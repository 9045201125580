import { routes } from "./routes";
import { MenuItem } from "../../../../commons/ui/menu";

export const comoComprarMenu: MenuItem = {
  key: "privateComoComprar",
  basePath: "/como-comprar",
  enabled: true,
  icon: "List",
  text: "Como comprar",
  children: routes,
  default: true,
};
