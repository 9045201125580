import { EcommerceAsset } from "../../../app/models/general/ecommerceAsset";
import NovaOfertaBomba from "../../../assets/images/NovaOfertaBomba.png";

export const assets: EcommerceAsset = {
  loadingSrc: "https://res.cloudinary.com/phinx-lab/image/upload/v1621854825/Assets/loading-nova.svg",
  timerBackgroundImage: {
    backgroundImage: NovaOfertaBomba,
    backgroundColor: `linear-gradient(to right, #007AE7 0%, #00C9A8 100%)`,
  },
  subcribeBackground: `linear-gradient(to right, #007AE7 0%, #00C9A8 100%)`,
};
