import { TemplateProperty } from "src/lib/templates";

export const checkCodeTemplate: TemplateProperty = {
  name: "checkCodeInfo",
  type: {
    name: "object",
    kind: [
      {
        name: "checkCode",
        type: { name: "string" },
        label: "Ingresá tu código",
        validators: [
          { name: "pattern", signature: ["^[0-9]+$"], errorMessage: "Solo podés ingresar números." },
          { name: "length", signature: [6], errorMessage: "El código es de 6 números." },
          { name: "empty", errorMessage: "Completa este dato." },
        ],
      },
    ],
  },
};
