import { EcommerceGeneral } from "../../../app/models/general/ecommerceGeneral";

export const general: EcommerceGeneral = {
  nameMp: "nftmarket",
  address: "Uspallata 2776, Parque Patricios",
  contactUrl: "hola@nftmarket.com.ar",
  facebookUrl: "https://www.facebook.com/enova.ar",
  instagramUrl: "https://www.instagram.com/enova.ar",
  title: "Onestar | Comprá, coleccioná e intercambiá NFTS",
  description: `Adueñate de momentos únicos. Comprá, coleccioná e intercambiá NFT's de los mejores momentos del deporte.`,
  defaultSearchText: "Todos los NFTs",
};
