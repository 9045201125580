import { useHistory } from "react-router";

export const useBreadcrumbsPromotions = () => {
  const history = useHistory<any>();
  const linksProduct = [];

  if (history.location.state?.prevPath) {
    const link = {
      label: "Promociones",
      search: history.location.search,
      url: history.location.state?.prevPath || "",
    };
    linksProduct.unshift(link);
  }

  linksProduct.unshift({ label: "Inicio", url: "/" });

  return linksProduct;
};
