import { routes } from "./routes";
import { MenuItem } from "../../../../commons/ui/menu";

export const cartMenu: MenuItem = {
  key: "privateCart",
  basePath: "/cart",
  enabled: true,
  icon: "List",
  text: "Carrito",
  children: routes,
};
