import React from "react";
import { Grid } from "@material-ui/core";
import { ecommerceConfig } from "../../../config/ecommerce";

export interface LoadingProps {
  className?: string;
  width?: number;
  size?: string | number;
}

export const Loading = ({ className = "", width = 50 }: LoadingProps) => (
  <Grid className={className} container justify="center" alignItems="center">
    <img src={ecommerceConfig.assets.loadingSrc} width={width} alt="loading" />
  </Grid>
);
