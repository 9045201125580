import React from "react";
import { Theme, useMediaQuery, Container, Box, Card, Grid, Typography, Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { Link } from "react-router-dom";
import { useRolesContext } from "src/context";
import { useWalletContext } from "src/context/WalletContext";
import { WalletConnection } from "src/commons/components/WalletConnection";

export const RegisterSuccess = () => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const { userInfo } = useRolesContext();
  const { isConnected, address } = useWalletContext();

  return (
    <Container maxWidth="md">
      <Box mt={4}>
        <Card elevation={2}>
          <Box borderRadius={8} py={3}>
            <Container maxWidth="md">
              <Grid container justify="center" alignContent="center">
                <Grid item xs={12} container justify="center">
                  <Box
                    component={CheckIcon}
                    padding={1}
                    width={56}
                    height={56}
                    color="primary.dark"
                    boxShadow="0px 0px 16px 4px rgba(149, 240, 53, 0.4)"
                    borderRadius={50}
                    bgcolor="primary.main"
                  />
                </Grid>

                <Grid item xs={9}>
                  <Box pt={4}>
                    <Typography align="center" variant={isDownSm ? "h6" : "h3"}>
                      <Box color="text.primary">{`¡Bienvenido a OneStar, ${userInfo.name.split(" ")[0]}!`}</Box>
                    </Typography>
                  </Box>
                  <Box mt={2} mb={3}>
                    <Typography align="center" variant="body1" color="textSecondary">
                      {`Ya creaste tu cuenta en OneStar, te recomendamos continuar la configuración de tu cuenta en la sección de `}
                      <Link to="/profile" color="primary" style={{ textDecoration: "none" }}>
                        <Box display="inline" color="primary.main">
                          Perfil
                        </Box>
                      </Link>
                      .{` Sino también te invitamos a explorar todos nuestros productos.`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item container justify="center" xs={12} direction="row" spacing={2}>
                  <Grid item>
                    <Link
                      to="/"
                      component={(props: any) => (
                        <Button {...props} variant="outline" style={{ border: "1px solid" }}>
                          Ir al inicio
                        </Button>
                      )}
                    />
                  </Grid>

                  {!address && !isConnected && (
                    <Grid item>
                      <WalletConnection />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};
