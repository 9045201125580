import { DappWalletManager } from "src/platform/DappWallets/DappWalletManager";
import { Listeners } from "../common";

export const MetaMaskListeners: Listeners[] = [
  {
    wallet: "metamask",
    event: "accountsChanged",
    acction: async ({ param0, strategy, setState, verifyWallet }) => {
      if (param0[0]) {
        const address = param0[0];
        const result = await verifyWallet(address);
        if (result) {
          DappWalletManager.AddressWallet = address;
          setState((old) => ({ ...old, address, isConnected: true, strategy }));
        }
      } else {
        DappWalletManager.disconnect();
        setState((old) => ({ ...old, address: "", isConnected: false, strategy: null }));
      }
    },
  },
];
