import { SessionFBChangeEmitter } from "src/platform/libby/session";
import { ProfileUserFB } from "../models";

export const userInfoInit: ProfileUserFB = {
  id: "",
  name: "",
  email: "",
  last_name: "",
  picture: { data: { height: 0, is_silhouette: false, url: "", width: 0 } },
  first_name: "",
};

const { FB } = window;
export type LoginStatus = "authorization_expired" | "connected" | "not_authorized" | "unknown";
export interface AuthResponse {
  accessToken: string;
  expiresIn: number;
  signedRequest: string;
  userID: string;
  grantedScopes?: string | undefined;
  reauthorize_required_in?: number | undefined;
}
export interface StatusResponse {
  status: LoginStatus;
  authResponse: AuthResponse | undefined;
}

class FacebookApi {
  statusChangeCallback(response: StatusResponse) {
    if (response.authResponse) {
      SessionFBChangeEmitter.emitChange(response);
    }
  }

  checkLoginStatus() {
    // auto authenticate with the api if already logged in with facebook
    let fbStatus: StatusResponse = { authResponse: undefined, status: "unknown" };
    FB.getLoginStatus((response) => {
      fbStatus = response;
      this.statusChangeCallback(response);
    });
    return fbStatus;
  }

  logout() {
    FB?.logout();
  }

  login() {
    FB.login(this.checkLoginStatus);
  }

  initialize() {
    // this.FB.Event.subscribe('auth.login', this.statusChangeCallback);
    this.checkLoginStatus();
  }

  getStatusLogin() {
    let fbStatus: StatusResponse = { authResponse: undefined, status: "unknown" };
    FB.getLoginStatus((response) => {
      fbStatus = response;
    });
    return fbStatus;
  }

  async getInfoUser() {
    const fbStatus = this.getStatusLogin();
    let userInfo: ProfileUserFB = userInfoInit;
    if (fbStatus.authResponse) {
      const url = `/${fbStatus.authResponse?.userID}?fields=name,email,picture`;
      const promise = new Promise<ProfileUserFB>((resolve, reject) => {
        FB.api<ProfileUserFB>(url, (response) => {
          if (response.id) {
            resolve(response);
          } else {
            reject(userInfo);
          }
        });
      });
      const resut = await promise;
      userInfo = resut;
    }
    return userInfo;
  }
}

const i: FacebookApi = new FacebookApi();
export { i as FacebookApi };
