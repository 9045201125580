import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../../lib/libby/generator";
import { Configuration } from "../../models";
import { businessOptions } from "../businessOptions";

const DAO_NAME = "configuration";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "idConfiguration",
      labelKey: "descripcion",
    },
    withSelectedChips: {
      idKey: "idConfiguration",
      labelKey: "descripcion",
    },
  },
};

const optionsToUse = !businessOptions.Configuration
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Configuration,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Configuration.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Configuration>(DAO_NAME, optionsToUse);

// hooks
export const useConfigurationFetchById = fetchById;
export const useConfigurationLibbyCall = libbyCall;
export const useConfigurationDAO = libbyDAO;
export const useConfigurationLibbyFetch = libbyFetch;

// components/hoc
export const ConfigurationAutocomplete = entityAutocomplete;
export const ConfigurationCall = renderPropCall;
export const ConfigurationById = renderPropFetchById;
export const ConfigurationSelect = simpleSelect;
export const ConfigurationAccordionSelector = accordionSelector;
export const ConfigurationSelectedChips = selectedChips;

// context
// ByIdContext
export const useConfigurationByIdContext = useByIdContext;
export const ConfigurationByIdProvider = ByIdProvider;
export const ConfigurationByIdContext = ByIdContext;
// GeneralContext
// export const useCardContext = useGeneralContext;
// export const CardProvider = GeneralProvider;
// export const CardContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
