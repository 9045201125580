import React, { useCallback } from "react";
import { uniqBy } from "lodash";
import { Grid, Typography, ListItem, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { PriceRange } from "src/app/models/PriceRange";
import { genericFilterStyles as useStyles } from "../styles/genericFilterStyles";

export enum FILTER_TYPE {
  CATEGORY = "category",
  PRICE = "price",
  TEXT = "text",
  MARK = "mark",
}

interface FilterListItem {
  value: string | PriceRange;
  name: string;
  type: FILTER_TYPE;
  selected: boolean;
}

export interface FilterList extends FilterListItem {
  subItems?: FilterListItem[];
}

export interface GenericFilterData {
  title: string;
  items: Array<FilterList>;
  hide?: boolean;
  type: FILTER_TYPE;
  defaultOpen?: string;
}

export interface GenericFilterProps extends GenericFilterData {
  onAddFilter: (value: FilterList) => void;
}

interface AccordionData {
  title: string;
  content: string[];
}

const MySummary = ({ title }: { title: string }) => (
  <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
    <Typography variant="subtitle1">{title}</Typography>
  </AccordionSummary>
);

export const GenericFilter = ({ title, items, onAddFilter, defaultOpen = "Categorías" }: GenericFilterProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(defaultOpen);

  const handleChange = (panel: string) => (event: React.ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getItems = useCallback(() => {
    const singleItems: JSX.Element[] = [];
    const accordions: AccordionData[] = [];

    items.forEach((item: FilterList) => {
      const splitedName = item.name.split(".");
      if (item.type === FILTER_TYPE.CATEGORY && splitedName.length > 1) {
        const index = accordions.findIndex(({ title: accordionTitle }: AccordionData) => accordionTitle === splitedName[0]);
        if (index < 0) {
          accordions.push({
            title: splitedName[0],
            content: splitedName.splice(1, splitedName.length),
          });
        } else {
          accordions[index].content.push(...splitedName);
        }
      } else {
        singleItems.push(
          <ListItem
            disableGutters
            // selected={item.selected}
            key={`${item.value}${item.name}`}
            button
            onClick={() => onAddFilter(item)}
          >
            <div className={classes.listItem}>
              <Typography variant="body1" color="primary">
                {item.name}
              </Typography>
            </div>
          </ListItem>,
        );
      }
    });
    const anidadedItems: JSX.Element[] = accordions.map((value) => (
      <Accordion className={classes.accordion}>
        <AccordionSummary className={[classes.listItem, classes.accordionSummaryItem].join(" ")} expandIcon={<ExpandMoreIcon color="primary" />}>
          <ListItem disableGutters selected={false} key={value.title}>
            <Typography variant="body1" color="primary">
              {value.title}
            </Typography>
          </ListItem>
        </AccordionSummary>
        {uniqBy(value.content, (item) => item)
          .filter((name) => name !== value.title)
          .map((text) => (
            <ListItem
              disableGutters
              selected={false}
              key={text}
              button
              onClick={() =>
                onAddFilter({
                  type: FILTER_TYPE.CATEGORY,
                  value: `${value.title}.${text}`,
                  name: text,
                  selected: false,
                })
              }
              className={classes.listItemChild}
            >
              <Typography variant="body1" color="primary">
                {text}
              </Typography>
            </ListItem>
          ))}
      </Accordion>
    ));

    return [...singleItems, ...anidadedItems];
  }, [classes, items, onAddFilter]);

  return (
    <Grid container direction="column" className={classes.root}>
      <Accordion expanded={expanded === title} onChange={handleChange(title)} className={classes.accordion}>
        <MySummary title={title} />
        <AccordionDetails className={classes.mainAccordionDetails}>{getItems().map((element) => element)}</AccordionDetails>
      </Accordion>
    </Grid>
  );
};
