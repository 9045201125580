import React, { useMemo } from "react";
import { Grid, Typography, Box, useMediaQuery, Theme, Button, IconButton, Card } from "@material-ui/core";
import { FormCheckbox } from "src/lib/templates/components";
import { FormRegister } from "src/screens/Public/Register/types";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import { AppTooltip } from "src/commons/components/AppTooltip";
import { FieldValidator } from "final-form";
import { FormInputData, CommonFormInput } from "../CommonFormInput";
import { useScrollToTopOnMount, ComponentFormProps } from "../../../../commons";

export interface AccountInfoFormViewProps extends ComponentFormProps<FormRegister> {
  disabledNext: boolean;
  openTermsAndConditions: () => void;
  validateEmail?: FieldValidator<any>;
}

const Icon = (
  <AppTooltip
    title={
      <Typography variant="caption">
        Tu clave debe tener entre 6 y 20 caracteres. No incluyas tu nombre, apellido o e-mail, ni caracteres idénticos consecutivos.
      </Typography>
    }
  >
    <IconButton size="small" aria-label="passwordInfo" color="primary">
      <ErrorIcon color="primary" fontSize="small" />
    </IconButton>
  </AppTooltip>
);

export const AccountInfoFormView = ({ form, disabledNext, onSubmit, validateEmail, openTermsAndConditions }: AccountInfoFormViewProps) => {
  useScrollToTopOnMount();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isDown768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));

  const formInput: FormInputData[] = useMemo(
    () => [
      {
        variant: "standard",
        name: "accountInfo.fullname",
        label: "Nombre y Apellido",
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: "accountInfoFullname",
        autoComplete: "fullname",
      },
      /*       {
        variant: 'standard',
        name: 'accountInfo.lastname',
        label: 'Apellido',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: 'accountInfoLastname',
        autoComplete: 'family-name',
      }, */
      {
        variant: "standard",
        type: "email",
        name: "accountInfo.email",
        label: "E-mail",
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: "accountInfoEmail",
        autoComplete: "email",
        validator: validateEmail,
      },
      /*   {
        variant: 'standard',
        name: 'accountInfo.document',
        label: 'DNI',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: 'accountInfoDocument',
        autoComplete: 'on',
      }, */
      {
        variant: "standard",
        type: "password",
        name: "accountInfo.password",
        label: "Clave",
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: "accountInfoPassword",
        autoComplete: "new-password",
        inputBaseProps: { endAdornment: Icon },
      },
      {
        variant: "standard",
        type: "password",
        name: "accountInfo.confirmPassword",
        label: "Confirmar Clave",
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: "accountInfoConfirmPassword",
        autoComplete: "new-password",
      },
    ],
    [validateEmail],
  );

  return (
    <Grid item lg={8} md={8} sm={isDown768 ? 6 : 12} xs={12}>
      <Box component={Card} borderRadius={8} boxShadow={3} display="content">
        <Box padding={isDownSm ? 2 : 3}>
          <Grid container direction="row" spacing={3}>
            {formInput.map((input) => (
              <CommonFormInput<FormRegister>
                key={input.name}
                form={form}
                name={input.name}
                label={input.label}
                variant={input.variant}
                type={input.type}
                lg={input.lg}
                md={input.md}
                sm={input.sm}
                xs={input.xs}
                id={input.id}
                autoComplete={input.autoComplete}
                helperText={input.helperText}
                inputBaseProps={input.inputBaseProps}
                validator={input.validator}
              />
            ))}
            <Grid item xs={12} />
            <Grid item xs={12}>
              <FormCheckbox
                label={
                  <Typography variant="body2" color="inherit">
                    <span>Acepto los </span>
                    <Box component="span" color="primary.main" display="inline" onClick={openTermsAndConditions}>
                      términos y condiciones
                    </Box>
                    <span> y autorizo el uso de mis datos de acuerdo a la </span>
                    <Box component="span" color="primary.main" display="inline" onClick={openTermsAndConditions}>
                      Declaración de Privacidad
                    </Box>
                    <span>.</span>
                  </Typography>
                }
                name="termsAndConditions"
                form={form}
              />
            </Grid>
            <Grid item container xs={12} justify="center">
              <Button size="large" disabled={disabledNext} variant="contained" onClick={onSubmit}>
                Continuar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};
