import React, { useState, useCallback } from "react";
import {
  Grid,
  Box,
  Typography,
  Card,
  Button,
  CardActionArea,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  Theme,
} from "@material-ui/core";
import { NameMuiIcon } from "src/assets/MuiIcons";
import { DappWalletManager } from "src/platform/DappWallets";
import { Wallet } from "src/platform/DappWallets/Strategies/common";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import { MetaMaskImg, WalletconnectImg, CoinbaseImg } from "src/assets/images";
import { AnyObject } from "src/commons/types";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import { AppTooltip } from "../AppTooltip";

interface WalletOptionProps {
  name: string;
  icon: NameMuiIcon;
  iconProps?: AnyObject;
  onClick: () => void;
}

const WalletIcon: AnyObject = {
  Metamask: MetaMaskImg,
  WalletConnect: WalletconnectImg,
  Coinbase: CoinbaseImg,
};

const WalletOption = ({ name, onClick }: WalletOptionProps) => {
  const Icon = WalletIcon[name];
  return (
    <CardActionArea component="div" onClick={onClick}>
      <Box
        maxHeight={50}
        bgcolor="neutral.5"
        py={0.8}
        component={Card}
        borderRadius={4}
        display="flex"
        flexDirection="row"
        style={{ border: "1px solid #fc71b4" }}
      >
        <Grid container direction="row" justify="center" alignItems="center">
          <Box alignItems="center" mr={2}>
            <Grid component="img" src={Icon} width={35} height={35} />
          </Box>
          <Box color="neutral.1">
            <Typography variant="body1">{name}</Typography>
          </Box>
        </Grid>
      </Box>
    </CardActionArea>
  );
};

interface WalletConnectionProps {
  action?: (props?: any) => JSX.Element;
}

export const WalletConnection = ({ action }: WalletConnectionProps) => {
  const [openModal, setOpenModal] = useState(false);
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const onHandleCloseModal = useCallback(() => setOpenModal(false), []);
  const onOpenModals = useCallback(() => setOpenModal(true), []);

  const onSelect = useCallback(
    async (provider: Wallet) => {
      onHandleCloseModal();
      try {
        await DappWalletManager.connectToWallet(provider);
      } catch (error) {
        console.log("Error", error);
        await DappWalletManager.restart();
      }
    },
    [onHandleCloseModal],
  );
  const CompAction = action;
  return (
    <Grid container>
      {CompAction ? (
        <CompAction size="small" onClick={onOpenModals} />
      ) : (
        <Box ml={-0.5}>
          <Button size="small" variant="text" color="primary" onClick={onOpenModals}>
            {isDownSm && <AccountBalanceWalletOutlinedIcon style={{ marginRight: 10 }} />}
            <Typography align="left" style={{ textTransform: "none" }} variant={isDownSm ? "subtitle2" : "body2"} color="primary">
              Conectar una billetera
            </Typography>
          </Button>
        </Box>
      )}

      <Dialog open={openModal} aria-labelledby="draggable-dialog-title" onClose={onHandleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box mb={1} color="natural.1" display="flex" width="100%" justifyContent="center" alignItems="center">
            <Typography align="center" variant="subtitle2">
              Seleccioná una billetera para conectar a OneStar
            </Typography>
          </Box>
          <Grid container alignItems="center" justify="center">
            <AppTooltip
              arrow
              color="primary.3"
              title={
                <Typography variant="caption">
                  Una billetera de criptomonedas (o wallet) es una cuenta digital que se usa para almacenar, enviar y recibir items digitales.
                </Typography>
              }
            >
              <IconButton size="small" aria-label="walletInfo" color="primary">
                <Box mr={1}>
                  <Typography variant="body2" color="primary">
                    ¿Qué es una billetera?
                  </Typography>
                </Box>
                <ErrorIcon color="primary" fontSize="small" />
              </IconButton>
            </AppTooltip>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <WalletOption name="Metamask" icon="AdbRounded" iconProps={{ fontSize: "small" }} onClick={() => onSelect("metamask")} />
              </Grid>
              <Grid item>
                <WalletOption
                  name="WalletConnect"
                  icon="BurstModeRounded"
                  iconProps={{ fontSize: "small", color: "primary" }}
                  onClick={() => onSelect("walletconnect")}
                />
              </Grid>
              <Grid item>
                <WalletOption name="Coinbase" icon="AdbRounded" iconProps={{ fontSize: "small" }} onClick={() => onSelect("coinbase")} />
              </Grid>
            </Grid>
          </DialogContentText>
          <DialogActions>
            <Grid container justify="center">
              <Grid item>
                <Button autoFocus variant="text" onClick={onHandleCloseModal}>
                  <Box color="neutral.2">Cancelar</Box>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
