import { API_URL } from "src/config";
import { LoginStatusResponse } from "src/app/models";
import { tokenManager } from "../TokenManager";
import { JetUser, SessionChangeEmitter } from "./SessionChangeEmitter";

export const userGuest: JetUser = {
  id: "",
  nick: "",
  email: "",
  username: "",
  name: "",
  lastname: "",
  groups: [{ id: "", name: "" }],
  isGuest: true,
};

export const validateUser = (user: JetUser): boolean => user.groups.length > 0;

let Session: LoginStatusResponse = { isGuest: true, user: userGuest };
export async function checkSession() {
  try {
    const res = await fetch(`${API_URL}/status`, {
      method: "post",
      headers: { "X-JustRPC-Token": (await tokenManager.retrieve()) as string },
    });
    const justRPCError = res.headers.get("x-justrpc-error");

    if (justRPCError === "Session has expired") {
      const { pathname, href } = window.location;
      const base = pathname === "/" ? href : href.replace(pathname, "");
      window.location.href = base;
    }

    const response = await res.json();

    if (validateUser(response.user)) {
      Session = response;
    }

    const { user } = Session;
    const isLogged: boolean = !!user && user.isGuest === false;
    return { isLogged, user };
  } catch (error) {
    tokenManager.clean();
    console.log("Error status", error);
    const { user } = Session;
    const isLogged: boolean = !!user && user.isGuest === false;
    return { isLogged, user };
  }
}

export async function logout() {
  try {
    await fetch(`${API_URL}/logout`, {
      method: "post",
      headers: { "X-JustRPC-Token": (await tokenManager.retrieve()) as string },
    });

    tokenManager.clean();
    const { user } = Session;
    const isLogged: boolean = !!user && user.isGuest === false;
    SessionChangeEmitter.emitChange({ isLogged, user });
  } catch (error) {
    console.log("Error logout", error);
  }
}
