import React, { useMemo, useState, createContext, useContext, useEffect } from "react";
import { useCardLibbyCall } from "src/app/business";
import { Emitter, PaymentSimulation } from "src/app/models";
import { useCartContext } from "src/context";
import { useSimulateDAO } from "src/app/business/Simulate";

export interface EmittersContextValue {
  emitters: Array<Emitter>;
  initialLoad: boolean;
  working: boolean;
}

const EmittersContext = createContext<EmittersContextValue>({ emitters: [], initialLoad: true, working: true });

interface EmittersContextProviderProps {
  children: JSX.Element;
}

export const EmittersContextProvider = ({ children }: EmittersContextProviderProps) => {
  const [validEmitters, setValidEmitters] = useState<Emitter[]>([]);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const { cartState } = useCartContext();
  const simulateDAO = useSimulateDAO();

  const { data: emitters = [], working } = useCardLibbyCall<Emitter[]>({
    methodName: "getAllEmitters",
  });

  useEffect(() => {
    (async () => {
      if (!(emitters.length && !validEmitters.length && initialLoad)) return;

      const { totals, ...rest } = cartState;

      const results: PaymentSimulation[] = await Promise.all(
        emitters.map((_emiter) => simulateDAO.generateSimulate(rest, _emiter.card_emiter_id, 0)),
      );

      const filter = results.filter((result) => Object.keys(result.options).length);
      const emiter = emitters.filter((_emiter) => filter.find((fil) => fil.emiter === _emiter.card_emiter_id.toString()));
      if (emiter.length) {
        setValidEmitters(emiter);
      } else {
        setInitialLoad(false);
      }
    })();
  }, [cartState, simulateDAO, emitters, validEmitters, initialLoad]);

  const contextValue = useMemo(() => ({ emitters: validEmitters, initialLoad, working }), [initialLoad, validEmitters, working]);

  return <EmittersContext.Provider value={contextValue}>{children}</EmittersContext.Provider>;
};

export const useEmittersContext = () => useContext(EmittersContext);
