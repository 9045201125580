export const optionsInvoice = [
  { id: "false", label: "Consumidor final" },
  { id: "true", label: "Responsable inscripto" },
];

export interface DocentType {
  id: number;
  name: string;
}

export const optionsDocentType: Array<DocentType> = [
  {
    id: 1,
    name: "Titular",
  },
  {
    id: 2,
    name: "Interino",
  },
  {
    id: 3,
    name: "Provisional",
  },
  {
    id: 4,
    name: "Suplente",
  },
];

export enum CARD_PERSONAL_CREDIT {
  DOCENTES = 9,
  ALUMNOS,
}
