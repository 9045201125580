import ReactPixel, { AddToCart, InitiateCheckout, Purchase, ViewContent } from "react-facebook-pixel";

export interface EventParams {
  content_name: string;
  content_ids: Array<any>;
}
export type PurchaseEventParams = Purchase;
export type CheckOutEventParams = InitiateCheckout;
export type AddToCartEventParams = AddToCart;
export type ViewContentEventParams = ViewContent;

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

class FacebookPixel {
  initialize() {
    // TODO : Pasar los id dependiento de la condig, ambiente y ecommerse
    ReactPixel.init("476040127155331", undefined, options);
  }

  track(event: string, { content_name, content_ids }: EventParams) {
    ReactPixel.trackCustom(event, { content_name, content_ids });
  }

  purchaseTrack(params: PurchaseEventParams) {
    ReactPixel.track("Purchase", params);
  }

  checkOutTrack(params: CheckOutEventParams) {
    ReactPixel.track("InitiateCheckout", params);
  }

  addToCartTrack(params: AddToCartEventParams) {
    ReactPixel.track("AddToCart", params);
  }

  viewContent(params: ViewContentEventParams) {
    ReactPixel.track("ViewContent", params);
  }
}

const i: FacebookPixel = new FacebookPixel();
export { i as FacebookPixel };
