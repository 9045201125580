import React from "react";

interface ErrorIconProps {
  height?: string;
  width?: string;
}

export const ErrorIcon = ({ width = "250", height = "250" }: ErrorIconProps) => (
  <svg width={width} height={height} viewBox="0 25 293 231" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_934_1806)">
      <path
        d="M146.466 111C131.857 111 120 99.1545 120 84.5C120 69.872 131.857 58 146.466 58C161.101 58 172.931 69.872 172.931 84.5C172.931 99.1545 161.101 111 146.466
111Z"
        stroke="#FF007C"
        strokeWidth="6.47308"
      />
    </g>
    <g filter="url(#filter1_f_934_1806)">
      <path
        d="M146.466 111C131.857 111 120 99.1545 120 84.5C120 69.872 131.857 58 146.466 58C161.101 58 172.931 69.872 172.931 84.5C172.931 99.1545 161.101 111 146.466
111Z"
        stroke="#FF007C"
        strokeWidth="8.4648"
      />
    </g>
    <g filter="url(#filter2_i_934_1806)">
      <path
        d="M146.466 111C131.857 111 120 99.1545 120 84.5C120 69.872 131.857 58 146.466 58C161.101 58 172.931 69.872 172.931 84.5C172.931 99.1545 161.101 111 146.466 
111Z"
        stroke="#FF007C"
        strokeOpacity="0.2"
        strokeWidth="6.47308"
      />
    </g>
    <g filter="url(#filter3_f_934_1806)">
      <path
        d="M146.466 111C131.857 111 120 99.1545 120 84.5C120 69.872 131.857 58 146.466 58C161.101 58 172.931 69.872 172.931 84.5C172.931 99.1545 161.101 111 146.466 
111Z"
        stroke="#FF007C"
        strokeWidth="3.98343"
      />
    </g>
    <g filter="url(#filter4_f_934_1806)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.466 111C131.857 111 120 99.1545 120 84.5C120 69.872 131.857 58 146.466 58C161.101 58 172.931 69.872 172.931 
84.5C172.931 99.1545 161.101 111 146.466 111Z"
        stroke="white"
        strokeWidth="1.99172"
      />
    </g>
    <g filter="url(#filter5_f_934_1806)">
      <path d="M179.02 40.86V42.66H175.08V46.66H172.98V42.66H169V40.86H172.98V37H175.08V40.86H179.02Z" fill="white" />
    </g>
    <g filter="url(#filter6_f_934_1806)">
      <path d="M123.02 75.86V77.66H119.08V81.66H116.98V77.66H113V75.86H116.98V72H119.08V75.86H123.02Z" fill="white" />
    </g>
    <g filter="url(#filter7_f_934_1806)">
      <path d="M164.02 99.8621V100.73H162.119V102.66H161.106V100.73H159.186V99.8621H161.106V98H162.119V99.8621H164.02Z" fill="#ABABAB" />
    </g>
    <path
      d="M148.2 89.6V92H145.8V89.6H148.2ZM148.2 76V85.2H145.8V76H148.2ZM130.5 84C130.5 74.8915 137.876 67.5 146.983 67.5C156.108 67.5 163.5 74.8925 163.5 84C163.5 
93.1075 156.108 100.5 146.983 100.5C137.876 100.5 130.5 93.1085 130.5 84ZM132.9 84C132.9 91.7901 139.21 98.1 147 98.1C154.79 98.1 161.1 91.7901 161.1 84C161.1 76.2099 
154.79 69.9 147 69.9C139.21 69.9 132.9 76.2099 132.9 84Z"
      fill="#FF007C"
      stroke="white"
    />
    <defs>
      <filter
        id="filter0_f_934_1806"
        x="0.671478"
        y="-61.3287"
        width="291.589"
        height="291.657"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="58.0461" result="effect1_foregroundBlur_934_1806" />
      </filter>
      <filter
        id="filter1_f_934_1806"
        x="77.0702"
        y="15.0702"
        width="138.791"
        height="138.86"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="19.3487" result="effect1_foregroundBlur_934_1806" />
      </filter>
      <filter
        id="filter2_i_934_1806"
        x="114.248"
        y="54.7635"
        width="61.9196"
        height="61.9884"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-2.51533" dy="5.03066" />
        <feGaussianBlur stdDeviation="1.25767" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_934_1806" />
      </filter>
      <filter
        id="filter3_f_934_1806"
        x="117.012"
        y="55.0124"
        width="58.9063"
        height="58.9751"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.497929" result="effect1_foregroundBlur_934_1806" />
      </filter>
      <filter
        id="filter4_f_934_1806"
        x="118.34"
        y="56.3402"
        width="56.2512"
        height="56.3195"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.331953" result="effect1_foregroundBlur_934_1806" />
      </filter>
      <filter id="filter5_f_934_1806" x="165" y="33.0001" width="18.02" height="17.66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_934_1806" />
      </filter>
      <filter id="filter6_f_934_1806" x="109" y="68.0001" width="18.02" height="17.66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_934_1806" />
      </filter>
      <filter
        id="filter7_f_934_1806"
        x="155.187"
        y="94.0001"
        width="12.8335"
        height="12.66"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_934_1806" />
      </filter>
    </defs>
  </svg>
);
