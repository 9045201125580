import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import { primary } from "../../../theme/colors";

interface AvatarProps {
  name?: string;
  img?: string;
  avatarWidth: number;
  avatarHeight: number;
  variant?: "circle" | "circular" | "rounded" | "square";
  avatarMaxWidth?: string;
  avatarMaxHeight?: string;
}

export const UserAvatar = ({ name, avatarWidth, avatarHeight, img, variant = "rounded", avatarMaxWidth, avatarMaxHeight }: AvatarProps) => {
  const AvatarStyles = makeStyles(() => ({
    avatarText: {
      padding: 5,
      width: avatarWidth,
      height: avatarHeight,
      backgroundColor: primary.lightBlue,
      fontSize: avatarWidth >= 35 ? 30 : 15,
      fontFamily: "Open Sans",
      color: primary.white,
    },
    avatarImage: {
      backgroundColor: "white",
      padding: 5,
      width: avatarMaxWidth || avatarWidth,
      height: avatarMaxHeight || avatarHeight,
      maxWidth: avatarMaxWidth ? avatarWidth : "100%",
      maxHeight: avatarMaxHeight ? avatarHeight : "100%",
      backgroundImage: img,
    },
  }));
  const classes = AvatarStyles();
  const getInitials = () => {
    if (name) {
      const names = name.split(" ");
      let initials = names[0].substring(0, 1).toUpperCase();
      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    }
    return null;
  };
  return (
    <>
      {name ? (
        <Avatar variant={variant} className={classes.avatarText}>
          {getInitials()}
        </Avatar>
      ) : (
        <Avatar variant={variant} className={classes.avatarImage} src={img} />
      )}
    </>
  );
};
