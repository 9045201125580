import React, { useMemo } from "react";
import { Grid, Box, useMediaQuery, Theme, Button, Typography, Backdrop, CircularProgress, Card } from "@material-ui/core";
import theme from "src/theme";
import { FieldValidator } from "final-form";
import { FormInputData, CommonFormInput } from "../CommonFormInput";
import { useScrollToTopOnMount, ComponentFormProps } from "../../../../commons";

export interface CheckCodeRecoveryFormViewProps extends ComponentFormProps<any> {
  loading: boolean;
  buttonLabel?: string;
  disableNext?: boolean;
  validateCheckCode: FieldValidator<any>;
  reSendCode: () => void;
}

export const CheckCodeRecoveryFormView = ({
  form,
  loading,
  buttonLabel = "Continuar",
  disableNext,
  validateCheckCode,
  reSendCode,
  onSubmit,
}: CheckCodeRecoveryFormViewProps) => {
  useScrollToTopOnMount();
  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down(281));
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isDown768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));

  const formInput: FormInputData[] = useMemo(() => {
    const data: FormInputData[] = [
      {
        variant: "standard",
        name: "checkCodeInfo.checkCode",
        label: "Ingresá tu código",
        xs: 12,
        required: true,
        id: "checkCodeCheckCode",
        autoComplete: "one-time-code",
        validator: validateCheckCode,
      },
    ];
    return data;
  }, [validateCheckCode]);

  return (
    <Grid item lg={4} md={5} sm={isDown768 ? 6 : 12} xs={12}>
      <Box component={Card} borderRadius={8} boxShadow={3} display="content" position="relative">
        <Box padding={isDownSm ? 2 : 3}>
          <Grid container direction="row" spacing={3}>
            {formInput.map((input) => (
              <CommonFormInput<any>
                key={input.name}
                form={form}
                name={input.name}
                label={input.label}
                variant={input.variant}
                type={input.type}
                lg={input.lg}
                md={input.md}
                sm={input.sm}
                xs={input.xs}
                id={input.id}
                autoComplete={input.autoComplete}
                error={input.error}
                helperText={input.helperText}
                inputBaseProps={input.inputBaseProps}
                validator={input.validator}
              />
            ))}
            <Grid item xs={12} />

            <Grid item container xs={12} justify="flex-end">
              <Button style={{ textTransform: "none" }} size={isDownSm ? "small" : "large"} variant="text" onClick={reSendCode}>
                <Typography variant="body1" color="primary">
                  Reenviar código
                </Typography>
              </Button>
              <Box ml={2} mt={isDownXs ? 2 : undefined} display="flex">
                <Button size={isDownSm ? "small" : "large"} disabled={disableNext} variant="contained" onClick={onSubmit}>
                  {buttonLabel}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Backdrop style={{ position: "absolute", zIndex: theme.zIndex.appBar + 1, background: "rgba(255, 255, 255, 0.54)" }} open={loading}>
          <CircularProgress color="primary" />
        </Backdrop>
      </Box>
    </Grid>
  );
};
