import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../../lib/libby/generator";
import { PublicationFavoriteInfo } from "../../models";
import { businessOptions } from "../businessOptions";

const DAO_NAME = "publication_favorite";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "publication_favorite_id",
      labelKey: "name",
    },
    withSelectedChips: {
      idKey: "publication_favorite_id",
      labelKey: "name",
    },
  },
};

const optionsToUse = !businessOptions.PublicationFavorite
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PublicationFavorite,
      hoc: { ...defaultOptions.hoc, ...businessOptions.PublicationFavorite.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<PublicationFavoriteInfo>(DAO_NAME, optionsToUse);

// hooks
export const usePublicationFavoriteFetchById = fetchById;
export const usePublicationFavoriteLibbyCall = libbyCall;
export const usePublicationFavoriteDAO = libbyDAO;
export const usePublicationFavoriteLibbyFetch = libbyFetch;

// components/hoc
export const PublicationFavoriteAutocomplete = entityAutocomplete;
export const PublicationFavoriteCall = renderPropCall;
export const PublicationFavoriteById = renderPropFetchById;
export const PublicationFavoriteSelect = simpleSelect;
export const PublicationFavoriteAccordionSelector = accordionSelector;
export const PublicationFavoriteSelectedChips = selectedChips;

// context
// ByIdContext
export const usePublicationFavoriteByIdContext = useByIdContext;
export const PublicationFavoriteByIdProvider = ByIdProvider;
export const PublicationFavoriteByIdContext = ByIdContext;
// GeneralContext
// export const usePublicationFavoriteContext = useGeneralContext;
// export const PublicationFavoriteProvider = GeneralProvider;
// export const PublicationFavoriteContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
