import { useMemo } from "react";
import { useCategoryContext } from "src/app/business";

interface useBreadcrumbsCategoryProps {
  name: string | undefined;
}

export const useBreadcrumbsCategory = ({ name }: useBreadcrumbsCategoryProps) => {
  const { data: dataAllCategory } = useCategoryContext();

  const links = useMemo(() => {
    const linksProduct = [];

    if (dataAllCategory.length && name) {
      const dataFeatureCategory = name.split(".");
      const reverseDataFeatureCategory = dataFeatureCategory.reverse();

      for (let index = 0; index < dataFeatureCategory.length; index++) {
        const idCategory = dataAllCategory.find((dataCategory) => dataCategory.name === [...reverseDataFeatureCategory].reverse().join("."))?.id;

        linksProduct.unshift({
          label: [...reverseDataFeatureCategory][0],
          url: `/categories/${idCategory}`,
        });
        reverseDataFeatureCategory.splice(0, 1);
      }
    }

    linksProduct.unshift({ label: "Inicio", url: "/" });

    return linksProduct;
  }, [name, dataAllCategory]);

  return links;
};
