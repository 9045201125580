/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded, NavigateBeforeRounded, NavigateNextRounded } from "@material-ui/icons";
import { Box, IconButton, makeStyles, Theme, Grid, useMediaQuery } from "@material-ui/core";

type ScrollCarousel = {
  data: JSX.Element[];
};
const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  mobileContainer: {
    display: "flex",
    marginTop: 25,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  containerItems: {
    overflowX: "auto",
    display: "-webkit-box",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    minHeight: 410,
  },
}));

export const ScrollCarousel = ({ data }: ScrollCarousel) => {
  const classes = useStyles();
  const carouselRef = useRef<HTMLHeadingElement>(null);
  const containerRef = useRef<HTMLHeadingElement>(null);
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const carouselContainer = data.map((item) =>
    isDownSm ? (
      <>
        <Grid className={classes.mobileContainer}>{item}</Grid>
      </>
    ) : (
      <>
        <Box key={item.key} pl="1px" pr={2} pt={2}>
          {item}
        </Box>
      </>
    ),
  );
  const [showArrows, setShowArrows] = useState(true);
  const [onHover, setOnHover] = useState(false);

  useEffect(() => {
    if (carouselRef.current !== null && containerRef.current !== null && carouselRef.current.scrollWidth > containerRef.current?.offsetWidth) {
      setShowArrows(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselRef, containerRef, setShowArrows, window.innerWidth]);

  return (
    <div ref={containerRef} className={classes.container} onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
      {showArrows && (
        <ArrowScrollCarousel
          direction="left"
          onClick={() => sideScroll({ element: carouselRef.current, direction: "left" })}
          parentOnHover={onHover}
          htmlColor="white"
        />
      )}
      <div ref={carouselRef} className={classes.containerItems}>
        {carouselContainer}
      </div>
      {showArrows && (
        <ArrowScrollCarousel
          direction="right"
          onClick={() => sideScroll({ element: carouselRef.current, direction: "right" })}
          parentOnHover={onHover}
          htmlColor="white"
        />
      )}
    </div>
  );
};

type SideScroll = {
  element: any;
  direction: "left" | "right";
  speed?: number;
  distance?: number;
  step?: number;
};

const sideScroll = ({ element, direction, speed = 30, distance = 300, step = 50 }: SideScroll) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    if (direction === "left") {
      element.scrollLeft -= step;
    } else {
      element.scrollLeft += step;
    }
    scrollAmount += step;
    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer);
    }
  }, speed);
};

interface ArrowProps {
  direction: "left" | "right" | "up" | "down";
  disabled?: boolean;
  onClick?: () => void;
  parentOnHover: boolean;
  color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
  htmlColor?: string;
}

const useStylesArrow = makeStyles((theme) => ({
  arrow: {
    display: "none",
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: theme.shadows[3],
    },
  },
  mobileArrow: {
    backgroundColor: "transparent",
    border: "1px solid white",
    width: 32,
    height: 32,
  },
  onHoverClass: {
    display: "block",
  },
}));

export const ArrowScrollCarousel = ({ direction, disabled, onClick, parentOnHover, color, htmlColor }: ArrowProps) => {
  const classes = useStylesArrow();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const fontSize = isDownSm ? "small" : "large";

  let icon;
  switch (direction) {
    case "left":
      icon = <NavigateBeforeRounded htmlColor={htmlColor} color={color} fontSize={fontSize} />;
      break;
    case "up":
      icon = <KeyboardArrowUpRounded htmlColor={htmlColor} color={color} fontSize={fontSize} />;
      break;
    case "right":
      icon = <NavigateNextRounded htmlColor={htmlColor} color={color} fontSize={fontSize} />;
      break;
    case "down":
      icon = <KeyboardArrowDownRounded htmlColor={htmlColor} color={color} fontSize={fontSize} />;
      break;
    default:
      break;
  }

  return (
    <Box
      position={direction === "left" || direction === "right" ? "absolute" : "relative"}
      zIndex={5}
      right={direction === "right" ? "-2%" : ""}
      left={direction === "left" ? "-2%" : ""}
    >
      <IconButton
        disabled={disabled}
        onClick={onClick}
        classes={{ root: isDownSm ? classes.mobileArrow : classes.arrow }}
        className={isDownSm ? classes.mobileArrow : `${classes.arrow} ${parentOnHover && classes.onHoverClass}`}
      >
        {icon}
      </IconButton>
    </Box>
  );
};
