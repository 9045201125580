import { makeStyles } from "@material-ui/core";

export const bannerStyles = makeStyles((theme) => ({
  pointerLinks: {
    pointerEvents: "none",
  },
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  imgContainer: {
    overflow: "hidden",
    display: "block",
    width: "100%",
  },
  principalContainer: {
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
    marginTop: "-6px",
    marginBottom: 48,
    position: "relative",
  },
  img: {
    overflow: "hidden",
    display: "block",
    width: "100%",
    height: "100%",
    margin: 20,
  },
  buttomStepper: {
    position: "absolute",
    width: "100%",
    background: "transparent",
  },
  hiddeText: {
    display: "none",
  },
  iconButtonLg: {
    fontSize: 100,
  },
  iconButtonSm: {
    fontSize: 40,
  },
  actionContainer: {
    "&:hover > $arrowBack": {
      display: "block",
      zIndex: 1000001,
      position: "absolute",
      left: 0,
      alignContent: "center",
      alignItems: "center",
    },
    "&:hover > $arrowNext": {
      display: "block",
      zIndex: 1000001,
      position: "absolute",
      right: 0,
      alignContent: "center",
      alignItems: "center",
    },
  },
  buttonNext: {
    width: 49,
    height: 80,

    borderRadius: "8px 0px 0px 8px",
    background: "white",
    zIndex: 1000001,
    "&:hover": { width: 49, height: 80, borderRadius: "8px 0px 0px 8px", background: "white", zIndex: 1000001 },
  },
  buttonBack: {
    width: 49,
    height: 80,
    borderRadius: "0px 8px 8px 0px",
    background: "white",
    zIndex: 1000001,
    "&:hover": { width: 49, height: 80, borderRadius: "0px 8px 8px 0px", background: "white", zIndex: 1000001 },
  },
  iconSize: { width: 50, height: 40 },
  arrowNext: {
    zIndex: 10000,
    position: "absolute",
    right: 0,
    display: "none",
    "&:hover": { zIndex: 10000, position: "absolute", display: "block", right: 0 },
  },
  arrowBack: {
    zIndex: 10000,
    position: "absolute",
    left: 0,
    display: "none",
    "&:hover": { zIndex: 10000, position: "absolute", display: "block", left: 0 },
  },
  docsStepper: {
    position: "absolute",
    alignItems: "flex-end",
    background: "transparent",
  },
}));
