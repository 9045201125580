import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Dialog, DialogContent, Grid, Typography, Button, DialogActions } from "@material-ui/core";
import { AnyObject } from "src/commons";
import { DappWalletFactory, DappWalletManager } from "src/platform/DappWallets";
import { MetaMaskListeners, WalletConnectListeners, CoinbaseListeners } from "src/platform/DappWallets/Strategies";
import { useBuyerWalletDAO } from "src/app/business/BuyerWallet";
import { BuyerWallet } from "src/app/models";
import { WalletContextValue, WalletContext } from "./WalletContext";
import { useRolesContext } from "../RolesContext";

const strategies = [...DappWalletFactory.Strategies.values()];

const listeners = [...MetaMaskListeners, ...WalletConnectListeners, ...CoinbaseListeners];

const initialState: WalletContextValue = {
  address: "",
  isConnected: false,
  strategy: null,
  error: false,
  msg: "",
};

export const WalletContextProvider = ({ children }: AnyObject) => {
  const [state, setState] = useState<WalletContextValue>(initialState);
  const { userInfo } = useRolesContext();
  const buyerWalletDAO = useBuyerWalletDAO();

  const verifyWallet = useCallback(
    async (address: string) => {
      try {
        if (address && userInfo.id) {
          const [wallets]: Array<BuyerWallet[]> = await buyerWalletDAO.fetchById(address);
          const others = wallets.find(
            (item) => item.wallet.toUpperCase() === address.toUpperCase() && userInfo.id.toUpperCase() !== item.buyer_id.toUpperCase(),
          );
          if (
            !others &&
            !wallets.find((item) => item.wallet.toUpperCase() === address.toUpperCase() && userInfo.id.toUpperCase() === item.buyer_id.toUpperCase())
          ) {
            await buyerWalletDAO.save({ wallet: address });
            setState((old) => ({
              ...old,
              error: false,
              msg: "",
            }));
          } else if (others) {
            setState((old) => ({
              ...old,
              address,
              isConnected: false,
              strategy: null,
              error: true,
              msg: `Esta wallet esta siendo usada por otro usuario!`,
            }));
            return false;
          }
        }
        return true;
      } catch (error) {
        console.log("Error", error);
        setState((old) => ({
          ...old,
          address,
          isConnected: false,
          strategy: null,
          error: true,
          msg: `Esta wallet esta siendo usada por otro usuario!`,
        }));
        return false;
      }
    },
    [buyerWalletDAO, userInfo.id],
  );

  const handleListeners = useCallback(() => {
    listeners.forEach(({ event, wallet, acction }) => {
      try {
        const provider = strategies.find((strategy) => strategy.getName() === wallet);
        if (!provider) throw new Error(`Faild to load listener listener.event, provider ${wallet} not found`);
        provider.getProvider().on(event, (...args: any[]) => {
          const parse = args.reduce((acum, arg, index) => {
            const key = `param${index}`;
            if (!acum[key]) acum[key] = arg;
            return acum;
          }, {});
          acction({ ...parse, strategy: provider, setState, verifyWallet });
        });
      } catch (e) {
        console.log("Error", e);
      }
    });
  }, [verifyWallet]);

  useEffect(() => {
    handleListeners();
  }, [handleListeners]);

  const handleConnection = useCallback(async () => {
    if (userInfo.isGuest) return;
    strategies.forEach(async (strategy) => {
      const isConnected = await strategy.isConnected();
      if (isConnected) {
        await DappWalletManager.connectToWallet(strategy.getName());
        setState((old) => ({ ...old, address: DappWalletManager.getAccount(), isConnected: true, strategy }));
        await verifyWallet(DappWalletManager.getAccount());
      }
    });
  }, [userInfo.isGuest, verifyWallet]);

  useEffect(() => {
    handleConnection();
  }, [handleConnection]);

  const value = useMemo<WalletContextValue>(
    () => ({
      ...state,
      address: state.error ? "" : state.address.toUpperCase(),
    }),
    [state],
  );
  return (
    <WalletContext.Provider value={value}>
      <Dialog open={state.error} fullWidth maxWidth="sm">
        <DialogContent>
          <Grid container justify="center">
            <Grid item>
              <Typography align="center" variant="body1" color="textPrimary">
                {state.msg}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center" variant="body2" color="primary">
                {state.address}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <Button type="button" variant="contained" onClick={() => setState((old) => ({ ...old, error: false, msg: "" }))}>
                Ok
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {children}
    </WalletContext.Provider>
  );
};
