import React, { useMemo } from "react";
import { PUBLICATION_TYPE } from "src/app/const";
import { Grid, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { ScrollCarouselAuto } from "src/commons/ui/view/ScrollCarouselAuto";
import { SkeletonComponentProps, SOURCE } from "src/app/models/Site";
import { IMAGE_BASE_URL } from "../../../../config";

export const useStyles = makeStyles((theme) => ({
  principalContainer: {
    marginBottom: ({ isDownSm }: { isDownSm: boolean }) => (isDownSm ? "5vw" : "2vw"), // tiene que ser 56 y 80 el margin total. se suma al padding.
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  itemGrid: {
    maxWidth: 600,
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  itemGridPlus: {
    maxWidth: 600,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  containerItems: {
    overflowX: "auto",
    display: "flex",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    minHeight: 200,
  },
  bannerRoot: {
    cursor: "pointer",
    width: "100%",
  },
  imgBanner: {
    width: "100%",
    borderRadius: "4px",
  },
  root: {
    marginTop: "10px",
    marginBottom: "30px",
  },
}));

export const SkeletonBannerMultiple = ({ data }: SkeletonComponentProps) => {
  const { data: publicationData, working } = SOURCE.ZONE(data.source_id);
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles({ isDownSm });
  const items = useMemo(
    () =>
      (publicationData?.publications || [])
        .filter((item) => item.type === PUBLICATION_TYPE.BANNER)
        .map((item) => ({
          image: `${IMAGE_BASE_URL}${item.config_values?.imagen?.normal || ""}`,
          title: "",
          url: `/categories/${item.config_values?.redirectTo}`,
        })),
    [publicationData],
  );

  const arrayComponentsToBeRender = items.map((item, index) => ({
    element: (
      <Grid key={item.image} id={index.toString()} className={classes.bannerRoot}>
        <img className={classes.imgBanner} src={item?.image} alt={item.title} />
      </Grid>
    ),
    url: item.url,
    id: index,
  }));

  return working ? (
    <div />
  ) : items.length ? (
    <Grid className={classes.root}>
      <ScrollCarouselAuto data={arrayComponentsToBeRender} />
    </Grid>
  ) : (
    <></>
  );
};
