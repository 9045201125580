import { MenuItem } from "src/commons";
import { routes } from "./routes";

export const favoriteMenu: MenuItem = {
  key: "privateFavorite",
  basePath: "/favorites",
  enabled: true,
  icon: "List",
  text: "Favoritos",
  children: routes,
};
