import React from "react";
import { Grid, Box, useMediaQuery, Theme, Button, Typography, IconButton, Card } from "@material-ui/core";
import { FormRecovery } from "src/screens/Public/Recovery/types";
import { DinamycMuiIcon } from "src/assets/MuiIcons";
import { AppTooltip } from "src/commons/components/AppTooltip";
import { FormInputData, CommonFormInput } from "../CommonFormInput";
import { useScrollToTopOnMount, ComponentFormProps } from "../../../../commons";

export interface ChangePasswordInfoFormViewProps extends ComponentFormProps<FormRecovery> {}

const icon = (
  <AppTooltip
    title={
      <Typography variant="caption">
        Tu clave debe tener entre 6 y 20 caracteres. No incluyas tu nombre, apellido o e-mail, ni caracteres idénticos consecutivos.
      </Typography>
    }
  >
    <IconButton size="small" aria-label="passwordInfo" color="primary">
      {DinamycMuiIcon({ name: "ErrorOutline", props: { fontSize: "small" } })}
    </IconButton>
  </AppTooltip>
);

const formInput: FormInputData[] = [
  {
    variant: "standard",
    type: "password",
    name: "changePasswordInfo.password",
    label: "Contraseña nueva",
    xs: 12,
    required: true,
    id: "changePasswordInfoPassword",
    autoComplete: "new-password",
    inputBaseProps: { endAdornment: icon },
  },
  {
    variant: "standard",
    type: "password",
    name: "changePasswordInfo.confirmPassword",
    label: "Confirmar contraseña",

    xs: 12,
    required: true,
    id: "changePasswordInfoConfirmPassword",
    autoComplete: "new-password",
  },
];

export const ChangePasswordInfoFormView = ({ form, onSubmit }: ChangePasswordInfoFormViewProps) => {
  useScrollToTopOnMount();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isDown768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));

  return (
    <Grid item lg={4} md={5} sm={isDown768 ? 6 : 12} xs={12}>
      <Box component={Card} borderRadius={8} boxShadow={3} display="content" position="relative">
        <Box padding={isDownSm ? 2 : 3}>
          <Grid container direction="row" spacing={3}>
            {formInput.map((input) => (
              <CommonFormInput<FormRecovery>
                key={input.name}
                form={form}
                name={input.name}
                label={input.label}
                variant={input.variant}
                type={input.type}
                lg={input.lg}
                md={input.md}
                sm={input.sm}
                xs={input.xs}
                id={input.id}
                autoComplete={input.autoComplete}
                error={input.error}
                helperText={input.helperText}
                inputBaseProps={input.inputBaseProps}
                validator={input.validator}
              />
            ))}
            <Grid item xs={12} />

            <Grid item container xs={12} justify="flex-end">
              <Button size={isDownSm ? "small" : "large"} variant="contained" onClick={onSubmit}>
                Continuar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};
