import { useMemo } from "react";
import { GridSize } from "@material-ui/core";
import { FormInputData } from "../CommonFormInput";

interface InputsCommonsProps {
  nameForm: string;
  zipCodeLength: number;
  invalidZidCode: boolean;
  nameDelivery: string;
}

interface UploadInputData {
  name: string;
  label?: React.ReactNode;
  hidden?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  id?: string;
  accept?: string;
  lg?: GridSize;
  md?: GridSize;
  sm?: GridSize;
  xs?: GridSize;
}

export const useInputsCommons = ({ nameForm, invalidZidCode, zipCodeLength, nameDelivery }: InputsCommonsProps) =>
  useMemo(() => {
    const personalInputA: FormInputData[] = [
      {
        variant: "standard",
        name: `${nameForm}.name`,
        label: "Nombre",
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: `${nameForm}Name`,
        autoComplete: "name",
      },
      {
        variant: "standard",
        name: `${nameForm}.lastName`,
        label: "Apellido",
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: `${nameForm}LastName`,
        autoComplete: "family-name",
      },
      {
        variant: "standard",
        name: `${nameForm}.document`,
        label: "CUIL",
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: `'${nameForm}CUIL`,
        autoComplete: "on",
      },
    ];

    const personalInputB: FormInputData[] = [
      {
        variant: "standard",
        name: `${nameForm}.email`,
        label: "E-mail",
        lg: 6,
        md: 6,
        sm: 12,
        xs: 12,
        required: true,
        id: `${nameForm}Email`,
        autoComplete: "email",
      },
      {
        variant: "standard",
        name: `${nameForm}.phone.areaCode`,
        label: "Cód. de Área",
        lg: 2,
        md: 2,
        sm: 4,
        xs: 4,
        required: true,
        id: `${nameForm}AreaCode`,
        autoComplete: "tel-area-code",
      },
      {
        variant: "standard",
        name: `${nameForm}.phone.number`,
        label: "Teléfono",
        lg: 4,
        md: 4,
        sm: 8,
        xs: 8,
        required: true,
        id: `${nameForm}PhoneNumber`,
        autoComplete: "tel-local",
      },
      {
        variant: "standard",
        name: `${nameForm}.cbu`,
        label: "CBU",
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: `'${nameForm}CBU`,
        autoComplete: "on",
      },
    ];

    const deliveryInput: FormInputData[] = [
      {
        variant: "standard",
        name: `${nameDelivery}.zipCode`,
        label: "Cód. postal",
        lg: 2,
        md: 4,
        sm: 4,
        xs: 4,
        required: true,
        error: zipCodeLength >= 1 ? invalidZidCode : false,
        helperText: zipCodeLength >= 1 ? (invalidZidCode ? "El codigo postal no coincide" : "") : "",
        id: "applicantInfoZipCode",
        autoComplete: "postal-code",
      },
      {
        variant: "standard",
        name: `${nameDelivery}.street`,
        label: "Calle",
        lg: 6,
        md: 6,
        xs: 8,
        required: true,
        id: `${nameDelivery}Street`,
        autoComplete: "street-address",
      },
      {
        variant: "standard",
        name: `${nameDelivery}.streetNumber`,
        label: "Altura",
        lg: 2,
        md: 2,
        xs: 4,
        required: true,
        id: `${nameDelivery}StreetNumber`,
      },
      {
        variant: "standard",
        name: `${nameDelivery}.floor`,
        label: "Piso",
        lg: 2,
        md: 2,
        xs: 4,
        id: `${nameDelivery}Floor`,
      },
      {
        variant: "standard",
        name: `${nameDelivery}.apartament`,
        label: "Dpto.",
        lg: 2,
        md: 2,
        xs: 4,
        id: `${nameDelivery}Apartment`,
      },
    ];

    const uploadInputs: UploadInputData[] = [
      {
        accept: "image/png,image/jpeg,application/pdf,application/msword",
        name: `${nameForm}.img_dni_front`,
        label: "DNI frente",
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        id: `${nameForm}Img_dni_front`,
      },
      {
        accept: "image/png,image/jpeg,application/pdf,application/msword",
        name: `${nameForm}.img_dni_back`,
        label: "DNI atras",
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        id: `${nameForm}img_dni_back`,
      },
      {
        accept: "image/png,image/jpeg,application/pdf,application/msword",
        name: `${nameForm}.img_paycheck`,
        label: "Recibo de sueldo",
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        id: `${nameForm}img_paycheck`,
      },
      {
        accept: "image/png,image/jpeg,application/pdf,application/msword",
        name: `${nameForm}.img_service_certificate`,
        label: "Servicio",
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        id: `'${nameForm}img_service_certificate`,
      },
    ];

    const uploadInputDocente: UploadInputData = {
      accept: "image/png,image/jpeg,application/pdf,application/msword",
      name: `${nameForm}.img_certificate`,
      label: nameForm === "docentInfo" ? "Certificado de trabajo" : "Certificado de estudio",
      lg: 6,
      md: 6,
      sm: 6,
      xs: 12,
      id: `${nameForm}img_certificate`,
    };

    const docentPersonalInfo =
      nameForm === "docentInfo" || nameForm === "alumnInfo" ? personalInputB.filter((input) => input.label !== "CBU") : personalInputB;
    const docentUploadInfo =
      nameForm === "docentInfo" || nameForm === "alumnInfo"
        ? [
            ...uploadInputs
              .filter((input) => (nameForm === "docentInfo" ? true : input.label !== "Recibo de sueldo"))
              .filter((input) => input.label !== "Servicio"),
            uploadInputDocente,
          ]
        : uploadInputs;

    return {
      personalInputA: personalInputA.filter((input) => !input.hidden),
      personalInputB: docentPersonalInfo.filter((input) => !input.hidden),
      deliveryInput: deliveryInput.filter((input) => !input.hidden),
      uploadInputs: docentUploadInfo.filter((input) => !input.hidden),
    };
  }, [nameForm, invalidZidCode, zipCodeLength, nameDelivery]);
