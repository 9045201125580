import React from "react";
import { EMITTERS } from "src/app/const/Emitters";
import { useCheckoutFormContext } from "../../hooks";
import { FormProps } from "../../types";
import { CardInfoFormDecidir, CardInfoFormMercadoPago, CardInfoFormCredit } from "./components";
import { WithCardValues } from "./types";

const CARD_INFO_ESPECIAL_PAYMENT: { [k: number]: (props: FormProps) => JSX.Element } = {
  [EMITTERS.MERCADOPAGO]: CardInfoFormMercadoPago,
  [EMITTERS.CATAMARCA_CREDITS]: CardInfoFormCredit,
};

const CARD_INFO_PAYMENT_DECIDIR = CardInfoFormDecidir;

export const CardInfoForm = (props: FormProps) => {
  const { values } = useCheckoutFormContext();
  const Comp = CARD_INFO_ESPECIAL_PAYMENT[(values as WithCardValues).paymentMethod.emitter.card_emiter_id] || CARD_INFO_PAYMENT_DECIDIR;
  return <Comp {...props} />;
};
