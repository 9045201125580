import React, { useCallback, useMemo, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Grid } from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router";
import { CategoriesView } from "src/app/views";
import { Product } from "src/app/models/business/Product";
import { sortOptions, SEARCH_TYPES, useScrollToTopOnMount, useSearch, Loading } from "src/commons";
import { useCategoryById } from "src/app/business/Category/useCategoryById";
import { useCategory } from "src/app/business/Category/useCategory";
import { FilterList } from "src/commons/ui/view/GenericFilter";
import { useGeneralContext } from "src/context";
import { useBreadcrumbsCategory } from "src/commons/hooks/useBreadcrumbsCategory";
import { FeatureGroup, Publication } from "../../../app/models";
import { ecommerceConfig } from "../../../config/ecommerce";

export const Categories = () => {
  useScrollToTopOnMount();
  const params = useParams<{ id: string }>();
  const { data: publications, working, recall } = useCategoryById(params.id);
  const { data: categories } = useCategory();

  const categorySelected = useMemo(() => categories?.find((category) => category.id === params.id), [categories, params]);

  const { dispatch } = useGeneralContext();

  const { state } = useLocation<{ goBack: any[]; category?: FilterList }>();

  const publicationsFixed = useMemo<Publication[]>(
    () =>
      publications
        ? publications.map((pub: Publication) => ({
            ...pub,
            attributes: {
              ...pub.attributes,
              features:
                pub.attributes?.features?.map((group: FeatureGroup) => ({
                  ...group,
                  features: group.features || [],
                })) || [],
            },
          }))
        : [],
    [publications],
  );

  const {
    list,
    pagination,
    onChangePagination,
    filterContent,
    onAddFilter,
    onRemoveFilter,
    filters,
    sortOpenModal,
    setSortOpenModal,
    setSortFilter,
    sortFilter,
    freeShipment,
    setFreeShipment,
  } = useSearch({
    searchType: SEARCH_TYPES.category,
    publications: publicationsFixed || [],
    categoriesSource: categories || [],
    defaultFilter: state?.category ? [state.category] : [],
  });

  const history = useHistory();

  useEffect(() => {
    recall(params.id);
  }, [params.id, recall]);

  useEffect(() => {
    if (!params.id) {
      dispatch.setSearch("");
    }
  }, [params.id, dispatch]);

  const links = useBreadcrumbsCategory({ name: categorySelected?.name });

  const onClickProduct = useCallback(
    (item: Product) =>
      history.push({
        pathname: `/product/${item.productId}`,
        state: {
          goBack: links,
        },
      }),
    [links, history],
  );

  return (
    <>
      <Helmet>
        <title>
          {ecommerceConfig.general.title} - {categorySelected?.name || "Categoria"}
        </title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container>
          <Grid style={{ width: "100%" }}>
            {!working || list ? (
              <CategoriesView
                sortOptions={sortOptions}
                sortOpenModal={sortOpenModal}
                setSortOpenModal={setSortOpenModal}
                setSortFilter={setSortFilter}
                sortFilter={sortFilter}
                banner={categorySelected?.config_values}
                products={list}
                filterContent={filterContent}
                selectedFilters={filters}
                working={working}
                onClick={onClickProduct}
                onAddFilter={onAddFilter}
                onRemoveFilter={onRemoveFilter}
                onChangePagination={onChangePagination}
                pagination={pagination}
                defaultSearchText={
                  state?.category?.name?.split(".").reverse()?.[0] ||
                  categorySelected?.name?.split(".").reverse()?.[0] ||
                  ecommerceConfig.general.defaultSearchText
                }
                searchType={SEARCH_TYPES.category}
                freeShipment={freeShipment}
                setFreeShipment={setFreeShipment}
                fromCategories
              />
            ) : (
              <Loading />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
