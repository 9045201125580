import { EcommercePalette } from "../../../app/models/general/ecommercePalette";
import { black, white } from "../../../theme/colors";

export const ecommercePalette: EcommercePalette = {
  themeColor: "#8B04BC",
  materialUi: {
    text: {
      primary: "#000000de",
      secondary: "#757575",
      disabled: "#9E9E9E",
      hint: "#BCBCBC",
      initial: "#FFFFFF",
    },
    action: {
      main: "#E0E0E0",
      background: "#00000008",
      disabledBackground: "#00000012",
      disabledText: "#00000026",
      cta: "#3D2CFC",
    },
    primary: {
      contrastText: "#FFFFFF",
      main: "#8B04BC",
      light: "#F4DCFD",
      dark: "#360949",
      background: "#FAEEFE",
      border: "#CD44FF",
      negroStore: "#4F4F4F",
    },
    secondary: {
      contrastText: "#FFFFFF",
      main: "#FFA443",
      light: "#FDE8DC",
      dark: "#511800",
      background: "#FFF6ED",
      border: "#FFB668",
    },
    success: {
      contrastText: "#FFFFFF",
      main: "#4CAF50",
      light: "#7BC67E",
      dark: "#3B873E",
      textDark: "#1e4620",
      lightBg: "#EdF7ED",
      border: "#4CAF5050",
    },
    info: {
      contrastText: "#FFFFFF",
      main: "#7C00A9",
      light: "#64B6F7",
      dark: "#0B79D0",
      textDark: "#0d3c61",
      lightBg: "#E8f4fe",
      border: "#2196F350",
    },
    warning: {
      contrastText: "#00000087",
      main: "#FF9800",
      light: "#FFB547",
      dark: "#C77700",
      textDark: "#663D00",
      lightBg: "#FFF4E5",
      border: "#FF980050",
    },
    error: {
      contrastText: "#FFFFFF",
      main: "#F44336",
      light: "#F88078",
      dark: "#E31B0C",
      textDark: "#621b16",
      lightBg: "#FEECEB",
      border: "#F4433650",
    },
    other: {
      stroke: "#00000023",
      divider: "#00000012",
      backdrop: "#00000050",
      snackbar: "#323232",
      activeRaiting: "#FFB400",
    },
    /** Estos colores tienen que ser eliminados una vez que se reemplazen con los colores del Theme (arriba) en todos los overrides de los Mui files */
    background: {
      default: "#f8fafc",
      defaultDark: "#28343E",
      dark: "#394363",
      paper: white,
      greyF5: "#F5F5F5",
      blue: "#2D3367",
    },
    border: "#DFE3E8",
    divider: "#DFE3E8",

    common: {
      black,
      white,
      neutral: "#e4e7eb",
      muted: "#9EA0A4",
    },
  },
};
