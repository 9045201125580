import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { LocalShipping } from "@material-ui/icons";
import { ShipmentData } from "src/screens/Public/CalculateShipments/types";
import { productInfoPanelStyles as useStyles } from "../../../styles";

interface FreeShipmentProps {
  shipmentData: ShipmentData;
  calculateShipment: () => void;
}

export const FreeShipmentView = ({ shipmentData, calculateShipment }: FreeShipmentProps) => {
  const classes = useStyles();
  return (
    <Box mt={2} alignItems="center" display="flex">
      <Grid container>
        <Grid item xs={12} container>
          <Grid item xs={1}>
            <Box pr={1}>
              <LocalShipping className={classes.localShipping} />
            </Box>
          </Grid>
          {shipmentData.deliveryDate ? (
            <Grid item xs={11}>
              <Typography variant="body2">
                <Box color="success.dark">{`Este producto llega gratis a tu casa dentro de las próximas ${shipmentData.deliveryDate}`}</Box>
              </Typography>
              <Button color="primary" className={classes.buttonShipment} onClick={calculateShipment}>
                Ver datos de entrega
              </Button>
            </Grid>
          ) : (
            <Box>
              <Typography variant="body2">
                <Box color="success.dark">Este producto llega gratis a tu casa</Box>
              </Typography>
              <Button className={classes.buttonShipment} onClick={calculateShipment}>
                Calcular cuando llega
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
