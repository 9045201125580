import { Link, Typography } from "@material-ui/core";
import React from "react";
import { SITE_URL } from "src/app/const";
import { makeStyles } from "@material-ui/styles";
import Books from "@material-ui/icons/LibraryBooksOutlined";
import { CONTRACT_ADDRESS } from "src/config";

interface Token {
  id: string;
}

const styles = makeStyles(() => ({
  text: {
    marginTop: 34,
  },
  icon: {
    marginRight: 18,
  },
}));

export const SmartContract = ({ id }: Token) => {
  const classes = styles();
  const url = `${SITE_URL.SMART_CONTRACT}${CONTRACT_ADDRESS}?a=${id}`;
  return (
    <Link href={url} target="_blank">
      <Typography variant="subtitle2" className={classes.text}>
        <Books className={classes.icon} /> Smart Contract
      </Typography>
    </Link>
  );
};
