import React, { useState, MouseEvent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import { useCurrencyContext } from "src/context/CurrencyContext/CurrencyContext";
import { USDicon, ETHicon } from "src/assets/images/svgs";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { useMediaQuery, Theme } from "@material-ui/core";
import { currencySelectorStyles as useStyles, StyledButton, StyledMenuItem } from "src/app/views/styles";

enum CurrencyType {
  USD = "USD",
  ETH = "ETH",
}

const StyledMenu = withStyles({
  paper: {
    border: "none",
    borderRadius: 4,
    backgroundColor: "#363636",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const CurrencySelector = () => {
  const classes = useStyles();
  const { selectedCurrency, setSelectedCurrency } = useCurrencyContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDown768 = useMediaQuery<Theme>((theme) => theme.breakpoints.down(768));
  const isETH = selectedCurrency === CurrencyType.ETH;
  const isUSD = selectedCurrency === CurrencyType.USD;
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMobile = () => {
    setSelectedCurrency(isETH ? CurrencyType.USD : CurrencyType.ETH);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledButton className={classes.buttonActive} style={{ backgroundColor: "transparent" }} onClick={isDown768 ? handleClickMobile : handleClick}>
        <>
          {isETH ? <ETHicon width="14" color="#FF007C" /> : <USDicon color="#FF007C" />}
          {selectedCurrency}
          {isDown768 ? "" : anchorEl ? <KeyboardArrowUp className={classes.iconArrow} /> : <KeyboardArrowDown className={classes.iconArrow} />}
        </>
      </StyledButton>
      {isDown768 || (
        <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
          <StyledMenuItem
            onClick={() => {
              setSelectedCurrency(CurrencyType.ETH);
              handleClose();
            }}
          >
            <StyledButton className={isETH ? classes.buttonActive : classes.buttonDefault}>
              <ETHicon width="14" color={isETH ? "#FF007C" : "#ABABAB"} /> ETHERIUM
            </StyledButton>
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              setSelectedCurrency(CurrencyType.USD);
              handleClose();
            }}
          >
            <StyledButton className={isUSD ? classes.buttonActive : classes.buttonDefault}>
              <USDicon color={isUSD ? "#FF007C" : "#ABABAB"} /> USD
            </StyledButton>
          </StyledMenuItem>
        </StyledMenu>
      )}
    </>
  );
};
