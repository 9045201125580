import React, { useCallback } from "react";
import { FormControlLabel, List } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/styles";
import { grey } from "src/theme/colors";
import typography from "src/theme/typography";
import { ModalFormComponentProps } from "../../../../ui/dialogs/ModalForm";
import { AnyObject } from "../../../../types";

export type MultiCheckboxFormValue = AnyObject[];

export type MultiCheckboxFormMetadata = {
  checkedKey?: string;
  labelKey?: string;
  idKey?: string;
};

const multiCheckboxStyles = makeStyles(() => ({
  label: {
    "& .MuiFormControlLabel-label": {
      color: grey.textPrimary,
      fontSize: 16,
      fontFamily: typography.fontFamily,
    },
  },
}));

export const MultiCheckboxForm = ({
  value = [],
  onChange,
  metadata = {},
}: ModalFormComponentProps<MultiCheckboxFormValue, MultiCheckboxFormMetadata>) => {
  const { checkedKey = "checked", labelKey = "label", idKey = "id" } = metadata;
  const classes = multiCheckboxStyles();
  const onChangeValues = useCallback(
    (checked, index) => {
      const newValues = [...value];
      newValues[index] = { ...newValues[index], [checkedKey]: checked };
      onChange(newValues);
    },
    [checkedKey, onChange, value],
  );
  return value.map((item: AnyObject, index: number) => (
    <List key={item[idKey]} dense component="div" role="list" disablePadding>
      <FormControlLabel
        className={classes.label}
        control={<Checkbox checked={item[checkedKey]} onChange={({ target: { checked } }) => onChangeValues(checked, index)} color="primary" />}
        label={item[labelKey]}
      />
    </List>
  ));
};
