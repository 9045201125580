import { MenuItem } from "src/commons";
import { routes } from "./routes";

export const configurationMenu: MenuItem = {
  key: "privateConfiguration",
  basePath: "/configuration",
  enabled: true,
  icon: "List",
  text: "Configuración",
  children: routes,
};
