import React from "react";
import { Grid, Typography, useMediaQuery, Box } from "@material-ui/core";
import { QuantityButton } from "src/commons/ui/view/QuantityButton";
import { Loading } from "src/commons";
import { Stock } from "src/app/models/Stock";
import { ProductInfoPanelBuy } from "./ProductInfoPanelBuy";

interface ProductInfoPanelQuantityProp {
  quantity: number;
  setQuantity: (quantity: number) => void;
  onBuyClick: () => void;
  onAddToCartClick: () => void;
  stock: Stock | undefined;
  workingStock: boolean;
  enableStock: boolean;
  availableQuantity: number;
}

export default function ProductInfoPanelQuantity({
  quantity,
  setQuantity,
  onBuyClick,
  onAddToCartClick,
  stock,
  workingStock,
  enableStock,
  availableQuantity,
}: ProductInfoPanelQuantityProp) {
  const isSm = useMediaQuery<any>((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid item>
      <Box pb={2} mt={2} alignItems="center" display="flex">
        {!workingStock && !enableStock && (
          <Typography variant="h5">
            <Box color="error.main" mb={1}>
              Producto sin stock
            </Box>
          </Typography>
        )}
        {(workingStock || enableStock) && (
          <>
            <Box>
              <Typography variant="body2">
                <Box>Cantidad:</Box>
              </Typography>
            </Box>
            {workingStock ? (
              <Box px={2}>
                <Loading />
              </Box>
            ) : (
              <>
                <Box px={2}>
                  <QuantityButton
                    onAdd={() => {
                      setQuantity(quantity + 1);
                    }}
                    onRemove={() => {
                      setQuantity(quantity - 1);
                    }}
                    quantity={enableStock ? quantity : 0}
                    stock={stock?.quantity}
                    enableStock={enableStock}
                  />
                </Box>

                <Box>
                  <Box color={enableStock && stock?.quantity !== 1 ? "textSecondary" : "error.dark"}>
                    <Typography variant="body2">
                      {enableStock
                        ? stock?.quantity === 1
                          ? "¡Ultimo disponible!"
                          : `(${availableQuantity} ${isSm ? "uni" : "unidades"})`
                        : "*Producto agotado"}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      <Grid item sm={12}>
        <ProductInfoPanelBuy onAddToCartClick={onAddToCartClick} onBuyClick={onBuyClick} disabledButton={!enableStock} />
      </Grid>
    </Grid>
  );
}
