import React, { useMemo } from "react";
import { Theme, useMediaQuery, Container, Box, Grid, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useBreadcrumbs, Loading, usePagination } from "src/commons";
import { AppBreadcrumbsApp } from "src/app/views";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useScrollToTopOnMount } from "src/commons/hooks/useScrollToTopUnMount";
import { usePurchasesLibbyCall } from "src/app/business/PurchasesItem";
import { PurchaseItem } from "src/app/models";
import { Pagination } from "@material-ui/lab";
import { PurchaseItemCard } from "./components";

export const Purchases = () => {
  useScrollToTopOnMount();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isBw768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));

  const { data = [], working } = usePurchasesLibbyCall<PurchaseItem[]>({
    methodName: "getAllPurchases",
  });

  const { list, pagination, onChangePagination } = usePagination({ items: data, orderBy: "created_at", direction: "desc" });

  const paginationNumber = useMemo(() => pagination.allCount / pagination.perPage, [pagination]);
  const resultPaginationNumber = useMemo(() => (paginationNumber % 1 !== 0 ? paginationNumber + 1 : paginationNumber), [paginationNumber]);

  const links = useBreadcrumbs({
    links: isDownSm
      ? [
          { label: "Compras", url: "/dashboard" },
          { label: "Mi cuenta", url: "/dashboard/purchases" },
        ]
      : [
          { label: "Mi cuenta", url: "/dashboard" },
          { label: "Compras", url: "/dashboard/purchases" },
        ],
  });

  return (
    <>
      <Helmet>
        <title>Compras</title>
        <meta name="description" content="User profile" />
      </Helmet>
      <Container maxWidth="lg">
        <Box px={isDownSm ? 0 : "10%"} mt={2}>
          <AppBreadcrumbsApp
            links={links}
            iconSeparator={<Box component="div">/</Box>}
            iconGoBack={<ChevronLeftIcon color="primary" />}
            invert
            msgReturn=" "
            noMargin
          />

          <Grid container item spacing={isDownSm ? 0 : 2} xs={12} sm={isBw768 ? 8 : 12} md={8}>
            {!isDownSm && (
              <Grid item>
                <Typography variant="h5" color="textPrimary">
                  Compras
                </Typography>
              </Grid>
            )}

            {working ? (
              <Loading />
            ) : (
              <Grid item container spacing={2}>
                <Grid item container justify="space-between">
                  <Grid item>
                    <Typography variant="caption" color="textSecondary">
                      {data?.length > 0
                        ? `${data?.length} compra${data?.length > 1 ? "s" : ""} realizada${data?.length > 1 ? "s" : ""}`
                        : "0 compras realizadas"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container>
                  {list.map((item) => (
                    <Box width="100%" key={item.order_id} pb={3}>
                      <PurchaseItemCard item={item} />
                    </Box>
                  ))}
                </Grid>
                {pagination.allCount > pagination.perPage && (
                  <Grid container alignItems="center" justify="center">
                    <Pagination
                      count={Math.trunc(resultPaginationNumber)}
                      page={pagination.currentPage}
                      color="primary"
                      onChange={(event, page) => onChangePagination(page)}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  );
};
