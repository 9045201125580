import React, { useCallback, useState, ReactNode, ChangeEvent } from "react";
import { TextField, Typography, Box, InputLabelProps, CircularProgress, makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useField } from "react-final-form-hooks";
import { getErrorAndHelperTextNoTranslation } from "../../../utils/materialHelpers";

type OptionLabel = (option: any) => JSX.Element;
type TransformValue = (value: any) => any;

// FIXME: type form props and autoComplete
export interface FormAutocompleteFetchProps {
  name: string;
  label: ReactNode;
  form: any;
  multiple?: boolean;
  autoCompleteProps?: any;
  options: any[];
  optionLabelKey: string | OptionLabel;
  transformValue?: TransformValue;
  getOptionSelected?: any;
  valueToOption?: (value: any, options: any) => any;
  optionToValue?: (option: any) => any;
  placeholder?: string;
  positionLabel?: "inner" | "outer";
  variant?: "standard" | "outlined" | "filled";
  inputLabelProps?: Partial<InputLabelProps>;
  loading?: boolean;
  loadingText?: ReactNode;
  noOptionsText?: string;
  freeSolo?: boolean;
  helperText?: ReactNode;
  disabled?: boolean;
  onInputChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  enabledOpenAutocomplete?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
  },
}));

export const FormAutocompleteFetch = ({
  name,
  form,
  label,
  options,
  multiple = false,
  optionLabelKey,
  autoCompleteProps,
  getOptionSelected,
  valueToOption,
  optionToValue,
  placeholder,
  positionLabel = "inner",
  variant,
  inputLabelProps,
  loading = false,
  loadingText = "Cargando...",
  noOptionsText = "Sin opciones",
  freeSolo = false,
  helperText,
  disabled,
  onInputChange,
  enabledOpenAutocomplete = true,
}: FormAutocompleteFetchProps): JSX.Element => {
  const classes = useStyles();
  const field = useField(name, form);
  const [open, setOpen] = useState<boolean>(false);

  const getOptionLabel = useCallback(
    (option: any) => {
      if (typeof optionLabelKey === "string") {
        return option[optionLabelKey] || "";
      }
      return optionLabelKey(option);
    },
    [optionLabelKey],
  );

  const { error, helperText: innerHelperText } = getErrorAndHelperTextNoTranslation(field);

  return (
    <div>
      {positionLabel === "outer" && <Box mb="8px">{label}</Box>}
      <Autocomplete
        className={classes.root}
        fullWidth
        disabled={disabled}
        freeSolo={freeSolo}
        loadingText={loadingText}
        loading={loading}
        filterOptions={(x) => x}
        includeInputInList
        filterSelectedOptions
        open={open && enabledOpenAutocomplete}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        multiple={multiple}
        options={options}
        onChange={(event, newValue: any) => {
          if (Array.isArray(newValue)) {
            field.input.onChange(optionToValue ? newValue.map(optionToValue) : newValue);
          } else {
            field.input.onChange(optionToValue ? optionToValue(newValue) : newValue);
          }
        }}
        onInputChange={onInputChange}
        inputValue={field.input.value ? (valueToOption ? valueToOption(field.input.value, options) : field.input.value) : multiple ? [] : ""}
        renderInput={(params) => (
          <form noValidate>
            <TextField
              {...params}
              InputLabelProps={inputLabelProps}
              label={positionLabel === "inner" && label}
              variant={variant}
              onFocus={field.input.onFocus}
              onBlur={field.input.onBlur}
              placeholder={placeholder}
              helperText={error ? (!helperText ? innerHelperText : helperText) : undefined}
              error={error}
              autoComplete="off"
              InputProps={{
                ...params.InputProps,
                autoComplete: "off",
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="primary" size="20" /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          </form>
        )}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        {...(autoCompleteProps || {})}
        renderOption={(option: any) => <Typography id={option.name}>{option.name}</Typography>}
        noOptionsText={noOptionsText}
      />
    </div>
  );
};
