import { TemplateProperty } from "src/lib/templates";

export const personalInfoTemplate: TemplateProperty = {
  name: "personalInfo",
  type: {
    name: "object",
    kind: [
      {
        name: "name",
        type: { name: "string" },
        label: "Nombre",
        validators: [
          { name: "pattern", signature: ["^[A-Za-zÀ-ÿ\u00f1\u00d1 ]+$"] },
          { name: "max", signature: [30] },
        ],
      },
      {
        name: "lastName",
        type: { name: "string" },
        label: "Apellido",
        validators: [
          { name: "pattern", signature: ["^[A-Za-zÀ-ÿ\u00f1\u00d1 ]+$"] },
          { name: "max", signature: [30] },
        ],
      },
      {
        name: "document",
        type: { name: "string" },
        label: "Documento",
        validators: [
          {
            name: "pattern",
            signature: ["^[0-9]+$"],
          },
          { name: "length", signature: [8] },
        ],
      },
      {
        name: "dateOfBirty",
        type: { name: "date" },
        label: "Fecha de nacimiento",
        validators: [{ name: "less", signature: ["now"] }],
      },
      {
        name: "email",
        type: { name: "string" },
        label: "Correo electrónico",
        validators: [{ name: "email", signature: [{ tlds: { allow: false } }] }],
      },
      {
        name: "phone",
        type: {
          name: "object",
          kind: [
            {
              name: "areaCode",
              type: { name: "string" },
              label: "Cód. Área",
              validators: [
                {
                  name: "pattern",
                  signature: ["^[0-9]+$"],
                },
                {
                  name: "max",
                  signature: [4],
                },
              ],
            },
            {
              name: "number",
              type: { name: "string" },
              label: "Número de teléfono",
              validators: [
                {
                  name: "pattern",
                  signature: ["^[0-9]+$"],
                },
                {
                  name: "max",
                  signature: [20],
                },
              ],
            },
          ],
        },
      },
      {
        name: "termsAndConditions",
        type: { name: "boolean" },
        label: "Términos y condiciones",
        validators: [
          {
            name: "valid",
            signature: [true],
          },
        ],
      },
    ],
  },
};
