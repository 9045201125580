import React, { FunctionComponent } from "react";
import { SnackbarProvider } from "notistack";
import { Box, makeStyles } from "@material-ui/core";
import { ecommerceConfig } from "src/config/ecommerce";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import CancelOutlined from "@material-ui/icons/CancelOutlined";

const useStyles = makeStyles({
  success: { backgroundColor: ecommerceConfig.ecommercePalette.materialUi.primary.dark },
  error: { backgroundColor: ecommerceConfig.ecommercePalette.materialUi.secondary.errorAlert },
  warning: { backgroundColor: "yellow" },
  info: { backgroundColor: ecommerceConfig.ecommercePalette.materialUi.info.main },
});

export const ToastProvider: FunctionComponent<any> = ({ children }) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      preventDuplicate
      iconVariant={{
        success: <Box component={CheckCircleOutline} width={27} height={27} marginRight={1} />,
        error: <Box component={CancelOutlined} width={27} height={27} marginRight={1} />,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
