import { MinPriceFreeShipping } from "src/commons";
import { PromotionItem } from "../app/models";
import { formatPrice } from "./formatPrice";

export const getPriceWithDiscount = (promotionItem: PromotionItem): number => +promotionItem.product.price * (1 - +promotionItem.discount);

export const getPriceWithDiscountOnePay = (promotionItem: PromotionItem): number =>
  getPriceWithDiscount(promotionItem) * (1 - +promotionItem.onePaymentDiscount);

export const getDiscountPublication = (price: string, old_price: number): number => {
  const discountTxt: string = old_price > 0 ? (100 - (parseFloat(price) * 100) / old_price).toFixed(2) : "0";

  return parseFloat(discountTxt);
};

export const validateFreeShipping = (price: string): boolean => parseFloat(price) >= MinPriceFreeShipping;

export const getInterest = (price: number, old_price: number) => old_price - price;

export const formatPriceNotDecimal = (prince: number) => formatPrice(Math.round(prince).toString());
