import { useState } from "react";
import { useSubscriptionDAO } from "src/app/business/Subscription";
import { isEmail } from "src/utils/helpers";

export const useEmailSubscription = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({
    err: false,
    msg: "",
  });
  const subscriptionDao = useSubscriptionDAO();

  const handleInputChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: Event) => {
    e.preventDefault();

    if (isEmail(email)) {
      subscriptionDao.saveSubscription(String(email).toLowerCase()).then((item: boolean) => {
        setError({
          ...error,
          err: !item,
          msg: !item ? "El mail ingresado ya fue registrado." : "Gracias por suscribirte a nuestro newsletter.",
        });
        setEmail("");
      });
    } else {
      setError({
        ...error,
        err: true,
        msg: "El mail no es valido.",
      });
    }
  };

  return { handleInputChange, handleSubmit, email, error };
};
