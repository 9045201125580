import React from "react";
import { useScrollTrigger, Zoom, Grid, Avatar } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ecommerceConfig } from "src/config/ecommerce";

export const ScrollTop = () => {
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: any) => {
    const anchor = (event.target.ownerDocument || document).querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <Grid
        onClick={handleClick}
        role="presentation"
        style={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
      >
        <Avatar
          variant="rounded"
          sizes="small"
          aria-label="scroll back to top"
          style={{ background: ecommerceConfig.ecommercePalette.materialUi.primary.main, width: 44, height: 32 }}
        >
          <KeyboardArrowUpIcon />
        </Avatar>
      </Grid>
    </Zoom>
  );
};
