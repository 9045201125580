import React from "react";
import { Grid, Typography, Divider, Button, Box, useMediaQuery, Theme, Tooltip, IconButton } from "@material-ui/core";
import { formatPriceNotDecimal, validateFreeShipping } from "src/utils";
import { CartTotal, CartItemStock } from "src/context/CartContext/types";
import { PaymentFormat, InnerCard } from "src/screens/Public/Checkout/types";
import { CartItem, PaymentModel } from "src/app/models";
import DeleteIcon from "@material-ui/icons/Delete";
import { getPricePublicationCart } from "src/context/CartContext/utils";

export interface DeliveryCartActionsProps {
  cartTotals: CartTotal;
  cartItems: CartItemStock[];
  valuesAcordion: InnerCard[];
  disabledBuyButton?: boolean;
  paymentFormat: PaymentFormat;
  activeStep: number;
  maxStep: number;
  payment: PaymentModel;
  onBuyClick: () => void;
  onRemoveItem: (value: CartItem) => void;
}

export const DeliveryCartActions = ({
  cartTotals,
  cartItems,
  activeStep,
  maxStep,
  valuesAcordion,
  disabledBuyButton,
  paymentFormat,
  payment,
  onBuyClick,
  onRemoveItem,
}: DeliveryCartActionsProps) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <Box borderRadius={8} boxShadow={3} bgcolor="white">
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <Box marginBottom={1} marginTop={1} marginLeft={2}>
            <Typography variant="h6">
              <Box component="span" color="primary.dark">
                Resumen
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Divider />

        <Grid item container xs={12}>
          <Box margin={2} width="100%">
            <Grid item container direction="column">
              <Grid container direction="column" spacing={2}>
                {valuesAcordion.map((info) => (
                  <Grid key={info.id} item>
                    <Box marginBottom={!info.complete && -1} width="100%">
                      <Typography variant="body2">
                        <Box component="span" fontFamily="Montserrat" fontWeight="400" color={info.complete ? "#000000" : "#9E9E9E"}>
                          {info.title}
                        </Box>
                      </Typography>
                      <Box mt={1}>{info.complete && info.render}</Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Box marginTop={6}>
              <Grid item container xs={12}>
                <Grid item container justify="space-between" xs={12}>
                  <Typography component="div" variant="body2" color="textPrimary">
                    <Box component="span" fontWeight="600">
                      Subtotal
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {cartItems.map((itemCart) => (
                    <Grid item key={itemCart.publication.id} direction="row" container>
                      <Grid item xs={8}>
                        <Tooltip title={itemCart.publication.product} aria-label="product name">
                          <Box width="auto" display="inline-grid">
                            <Grid item zeroMinWidth>
                              <Typography noWrap variant="body2" color="textSecondary">
                                {`${itemCart.quantity} x ${itemCart.publication.product}`}
                              </Typography>
                            </Grid>
                          </Box>
                        </Tooltip>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography align="right" variant="body2" color="textSecondary">
                          {`$${formatPriceNotDecimal(getPricePublicationCart(itemCart.publication, payment))}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} container justify="flex-end">
                        <IconButton style={{ width: 22, height: 22 }} onClick={() => onRemoveItem(itemCart)}>
                          <DeleteIcon color="inherit" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Divider style={{ marginBottom: 8, marginTop: 8 }} />
              {paymentFormat.discount > 0 && (
                <Grid item>
                  <Box>
                    <Grid container justify="space-between">
                      <Typography variant="subtitle2">
                        <Box component="span" color="textPrimary">
                          Descuento por compra en 1 pago
                        </Box>
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {`-$${formatPriceNotDecimal(paymentFormat.discount)}`}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              )}
              {paymentFormat.interest > 0 && (
                <Grid item>
                  <Box>
                    <Grid container justify="space-between">
                      <Typography variant="subtitle2">
                        <Box component="span" color="textPrimary">
                          Intereses de financiación
                        </Box>
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {`$${formatPriceNotDecimal(paymentFormat.interest)}`}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              )}
              <Grid item>
                <Box mb={2}>
                  <Grid container justify="space-between">
                    <Typography variant="subtitle2">
                      <Box component="span" color="textPrimary">
                        Envio
                      </Box>
                    </Typography>
                    <Typography variant="h5" color="textPrimary" />
                    {!(validateFreeShipping(paymentFormat.amount.toString() || "") && !paymentFormat.shipping) ? (
                      paymentFormat.shipmentCost === 0 ? (
                        "a definir"
                      ) : (
                        `$${formatPriceNotDecimal(paymentFormat.shipmentCost)}`
                      )
                    ) : (
                      <Typography variant="subtitle2">
                        <Box color="success.dark">Gratis</Box>
                      </Typography>
                    )}
                  </Grid>
                </Box>
              </Grid>
              <Grid item>
                <Box mt={1} mb={2}>
                  <Grid container justify="space-between">
                    <Typography variant="h5">
                      <Box component="span" color="primary.dark">
                        Total
                      </Box>
                    </Typography>
                    <Typography variant="h5" color="textPrimary">
                      {`$${formatPriceNotDecimal(paymentFormat.totalAmount || cartTotals.totalPrice)}`}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>

              {!isDownSm && (
                <Grid item>
                  <Button fullWidth disabled={disabledBuyButton} size="large" variant="contained" color="primary" onClick={onBuyClick}>
                    {activeStep < maxStep - 1 ? "Continuar" : "Confirmar Compra"}
                  </Button>
                </Grid>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
