import validateCreditCard from "card-validator";

export const customValidatorFunctions: { [k: string]: any } = {
  creditCardNumber: (value: any, helpers: any) => {
    const numberValidation = validateCreditCard.number(value);
    if (!numberValidation.isValid) {
      return helpers.error("creditCardNumber.invalid");
    }
    return value;
  },
  creditCardExpiry: (value: any, helpers: any) => {
    const numberValidation = validateCreditCard.expirationDate(value);
    if (!numberValidation.isValid) {
      return helpers.error("creditCardExpiry.invalid");
    }
    return value;
  },
  creditCardProvincia: (value: any, helpers: any) => {
    const validNumbers = [
      "541719",
      "541646",
      "548790",
      "557497",
      "553668",
      "454832",
      "454850",
      "451275",
      "454846",
      "489321",
      "433833",
      "451772",
      "510510",
    ];

    if (value.toString().length >= 6 && !validNumbers.includes(value.toString().slice(0, 6))) {
      return helpers.error("creditCardProvincia.invalid");
    }
    return value;
  },
  fileSize: (value: any, helpers: any) => {
    if (value.size > 4194304) {
      return helpers.error("any.fileSize", { size: 4 });
    }
    return value;
  },
  fileType: (value: any, helpers: any) => {
    const validFileExtencion = ["image/png", "image/jpeg", "application/pdf", "application/docx"];
    const resuExten = validFileExtencion.map((item) => item.split("/")[1]);
    if (![...validFileExtencion, "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(value.type)) {
      return helpers.error("any.fileType", { extencions: resuExten });
    }
    return value;
  },
  cbu: (value: any, helpers: any) => {
    if (value.length !== 22) return helpers.error("string.length", { limit: 22 });
    const account = value.substr(8, 14);
    if (account.length !== 14) return helpers.error("string.cbu");
    const checkDigit = account[13];
    const accumA =
      account[0] * 3 +
      account[1] * 9 +
      account[2] * 7 +
      account[3] * 1 +
      account[4] * 3 +
      account[5] * 9 +
      account[6] * 7 +
      account[7] * 1 +
      account[8] * 3 +
      account[9] * 9 +
      account[10] * 7 +
      account[11] * 1 +
      account[12] * 3;
    const differenceA = (10 - (accumA % 10)) % 10;
    if (differenceA !== checkDigit) return helpers.error("string.cbu");

    const code = value.substr(0, 8);
    if (code.length !== 8) return helpers.error("string.cbu");
    const bank = code.substr(0, 3);
    const checkDigitA = code[3];
    const branchOffice = code.substr(4, 3);
    const checkDigitB = code[7];
    const accumB = bank[0] * 7 + bank[1] * 1 + bank[2] * 3 + checkDigitA * 9 + branchOffice[0] * 7 + branchOffice[1] * 1 + branchOffice[2] * 3;
    const differenceB = (10 - (accumB % 10)) % 10;
    if (differenceB !== checkDigitB) return helpers.error("string.cbu");
    return value;
  },
  province: (value: any, helpers: any) => {
    if (helpers.state.ancestors[1].useDeliveryAddress && helpers.state.ancestors[1].deliveryInfo.province.id.toString() !== "3")
      return helpers.error("object.province");
    if (value.id.toString() !== "3") return helpers.error("object.province");
    return value;
  },
};
