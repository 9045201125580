import palette from "../palette";

type MuiInputBaseType = { [k: string]: any };

const MuiInputBase: MuiInputBaseType = {
  root: {
    color: palette.text.primary,
    "&.MuiInput-underline:before": {
      borderBottom: `0.3px solid ${palette.text.primary}`,
    },
  },
};

export default MuiInputBase;
