import asService from "src/commons/hoc/asService";

const options = {
  activeProp: "open",
  resolveProp: "onConfirm",
  rejectProp: "onCancel",
  forceResolveOnReject: false,
  rejectValue: false,
};

export default asService(options);
