import ReactGA from "react-ga";

export interface SendEventParams {
  category: string;
  action: string;
  label: string;
  nonInteraction?: boolean;
  value?: number;
}

class GoogleAnalytic {
  initialize(gaID: any) {
    ReactGA.initialize(gaID);
    console.log(gaID, "inicializado");
  }

  sendEvent({ category, action, label, nonInteraction, value }: SendEventParams) {
    return ReactGA.event({
      category,
      action,
      label,
      nonInteraction,
      value,
    });
  }

  ga(a: string, b: string, c: any) {
    ReactGA.ga(a, b, c);
  }
}

const i: GoogleAnalytic = new GoogleAnalytic();
export { i as GoogleAnalytic };
