// import { homeMenu } from './home';
import { MenuItem } from "../../../commons/ui/menu";
import { loginMenu } from "./login";
import { categoriesMenu } from "./categories";
import { productsMenu } from "./products";
import { cartMenu } from "./cart";
import { searchMenu } from "./search";
import { aboutMenu } from "./about/aboutMenu";
import { faqsMenu } from "./faqs/faqsMenu";
import { politicaMenu } from "./politica";
import { comoComprarMenu } from "./comoComprar";
import { promotionsMenu } from "./promotions";
import { customRedirectsMenu } from "./customredirects";
import { regretMenu } from "./regret";
import { registerMenu } from "./register";
import { facebookMenu } from "./facebook";

// TODO: el home lo saco de aqui porque no lo estamos usanddo en el armador de rutas, la ruta del home la estamos declarando directo alla
export const routes: MenuItem[] = [
  // homeMenu,
  loginMenu,
  categoriesMenu,
  cartMenu,
  productsMenu,
  searchMenu,
  aboutMenu,
  faqsMenu,
  politicaMenu,
  comoComprarMenu,
  promotionsMenu,
  customRedirectsMenu,
  regretMenu,
  registerMenu,
  facebookMenu,
];
