import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { Toolbar, Grid, Divider, IconButton, Box, Button, useMediaQuery } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { MainInput, UserCard } from "src/commons";
import { appHeaderStyles as useStyles } from "src/app/views/styles";
import { useRolesContext } from "src/context";
import { LOGIN_ENABLED } from "src/config";
import { ecommercePalette } from "src/config/ecommerce/nftmarket/ecommercePalette";
import { CurrencySelector } from "src/commons/components/CurrencySelector";
import { AppHeaderMenu } from "./AppHeaderMenu";
import { AppHeaderWebProps } from "./types";
import { ecommerceConfig } from "../../../../../config/ecommerce";

export const AppHeaderWeb = ({ contents, onSearhChange, onSearchClick, onSignOut, onLogoClick, searchValue }: AppHeaderWebProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { selectedRole } = useRolesContext();
  const { mainLogo: MainLogo } = ecommerceConfig.ecommerceLogo;
  const isMdDown = useMediaQuery<any>((theme) => theme.breakpoints.down("md"));

  const onEnterPress = useCallback(
    (ev) => {
      if (ev.key === "Enter") {
        ev.preventDefault();
        onSearchClick();
      }
    },
    [onSearchClick],
  );

  return (
    <>
      <Toolbar disableGutters>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid className={classes.logo} item xs={2} lg={3} container alignItems="center" onClick={onLogoClick}>
            <MainLogo height="44" width="216" />
          </Grid>
          <Grid item xs={5} lg={6}>
            <Box width={isMdDown ? "100%" : "85%"} justifyContent="flex-start">
              <MainInput
                type="search"
                handleChange={(e) => onSearhChange(e)}
                placeholder="Buscar"
                name="search"
                icon={
                  <IconButton onClick={onSearchClick}>
                    <Search style={{ color: "#ABABAB" }} />
                  </IconButton>
                }
                iconPossition="end"
                value={searchValue}
                fullWidth
                size="small"
                customClass={classes.searchBar}
                onKeyPress={onEnterPress}
              />
            </Box>
          </Grid>

          <Grid item xs={4} lg={3} container direction="row" alignItems="center">
            <Grid item xs={12} container alignContent="center" justify="space-between" wrap="nowrap">
              <CurrencySelector />
              {LOGIN_ENABLED && (
                <>
                  {selectedRole.rol_id === "" ? (
                    <>
                      <Box
                        mt={3}
                        component={Button}
                        borderRadius={30}
                        bgcolor="transparent"
                        color={ecommercePalette.materialUi.primary.contrastText}
                        padding="12px 48px"
                        marginTop={0}
                        onClick={() => history.push("/register")}
                        className={classes.registerButton}
                      >
                        REGISTRATE
                      </Box>
                      <Box
                        mt={3}
                        component={Button}
                        borderRadius={30}
                        bgcolor={ecommercePalette.materialUi.primary.light}
                        color="black"
                        padding="12px 48px"
                        marginTop={0}
                        onClick={() => history.push("/login")}
                        className={classes.loginButton}
                      >
                        INGRESÁ
                      </Box>
                    </>
                  ) : (
                    <UserCard logout={onSignOut} />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider classes={{ root: classes.divider }} />
      <AppHeaderMenu contents={contents} />
    </>
  );
};
