import React, { useMemo } from "react";
import { Grid, Card, useMediaQuery, useTheme, Box, Divider } from "@material-ui/core";
import { Feature, FeatureGroup, Publication as PublicationModels, ConfigValuesImages } from "src/app/models";
import { VerticalCarousel } from "src/commons/ui/view/Banner";
import { ModalDialog } from "src/commons/components/";
import { Stock } from "src/app/models/Stock";
import { imageCarouselType } from "src/commons";
import { IMAGE_POSITION } from "src/app/const/ImgPosition";
import { ScrollTop } from "src/screens/Public/Product/hooks/BackToTop";
import { searchSkuPublication } from "src/utils";
import { ShipmentData } from "src/screens/Public/CalculateShipments/types";
import { ProductInfoPanel } from "../ProductInfoPanel/ProductInfoPanel";
import { ProductDetailsPromotions } from "./components";
import { ProductEspecification } from "./components/ProductEspecification";
import { ProductDescriptionView } from "./components/ProductDescription";
import { InfoModalData } from "./types";
import { ProductGeneralEspecifications } from "./components/ProductGeneralEspecifications";
import { ProductDetailsInfo } from "../ProductDetailsInfo";
import { VideoComponent } from "../VideoComponent";

interface ProductDetailsLandingViewProps {
  product: PublicationModels;
  stock: Stock | undefined;
  workingStock: boolean;
  onBuyClick: () => void;
  onAddToCartClick: () => void;
  onChangeQuantity: (quantity: number) => void;
  quantity: number;
  freeShipping: boolean;
  imagesCarousel: imageCarouselType[];
  onClickMeansPayment: () => void;
  openMeansPayment: boolean;
  infoModalShow: (idInfoModalShow: string) => InfoModalData | undefined;
  idInfoModalShow: string;
  onClickGuarantees: () => void;
  onClickPolitics: () => void;
  onHandleCloseModal: () => void;
  enableStock: boolean;
  availableQuantity: number;
  discount: number;
  calculateShipment: () => void;
  shipmentData: ShipmentData;
  paymentImages: ConfigValuesImages;
}

export const ProductDetailsLandingView = ({
  product,
  stock,
  workingStock,
  onBuyClick,
  onAddToCartClick,
  onChangeQuantity,
  quantity,
  freeShipping,
  imagesCarousel,
  onClickMeansPayment,
  openMeansPayment,
  infoModalShow,
  idInfoModalShow,
  onClickGuarantees,
  onClickPolitics,
  onHandleCloseModal,
  enableStock,
  availableQuantity,
  discount,
  calculateShipment,
  shipmentData,
  paymentImages,
}: ProductDetailsLandingViewProps) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const dataModal = infoModalShow(idInfoModalShow);

  const carouselImagesArray = imagesCarousel.map((image) => ({
    introline: image.alt,
    isImage: true,
    content: { image: image.img_url, copy: image.alt },
  }));

  const landingFeatures: Feature[] = useMemo(() => {
    const result: Feature[] = [];
    product?.attributes?.features?.forEach((group: FeatureGroup) => {
      if (group.name === "Landing") {
        group.features?.forEach((feature: Feature) => result.push(feature));
      }
    });
    return result;
  }, [product]);
  const sku = searchSkuPublication({
    featuresPublication: product?.attributes.features,
  });

  const especifications = useMemo(() => landingFeatures?.filter((item) => !item.name.split(",")[1]), [landingFeatures]);

  return (
    <Grid container direction="row" xs={12}>
      <Grid item xs={12}>
        <Card>
          <Box p={3} mb={5}>
            <Grid container xs={12} direction={isSm ? "column-reverse" : "row"} spacing={1}>
              <Grid item direction="column" sm={12} md={8}>
                <Box pr={isSm ? 0 : 1}>
                  {!isSm && (
                    <Box pb={4}>
                      <Grid item xs={12}>
                        <VerticalCarousel leadingText="" data={carouselImagesArray} />
                      </Grid>
                    </Box>
                  )}
                  {!isSm && (
                    <Grid item>
                      <Box mb={2}>
                        <Divider />
                      </Box>
                    </Grid>
                  )}
                  <Grid item xl={12}>
                    <Grid container direction="column" spacing={2}>
                      <ProductDescriptionView data={product} />
                      {!!especifications.length && <ProductGeneralEspecifications especifications={especifications} />}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item sm={12} md={4}>
                <Grid item>
                  <ProductInfoPanel
                    data={product}
                    stock={stock}
                    workingStock={workingStock}
                    onBuyClick={onBuyClick}
                    onAddToCartClick={onAddToCartClick}
                    onChangeQuantity={onChangeQuantity}
                    quantity={quantity}
                    freeShipping={freeShipping}
                    imagesCarousel={imagesCarousel}
                    enableStock={enableStock}
                    availableQuantity={availableQuantity}
                    discount={discount}
                    calculateShipment={calculateShipment}
                    shipmentData={shipmentData}
                    onClickMeansPayment={onClickMeansPayment}
                  />
                </Grid>

                <Grid item>
                  <Box mb={2}>
                    <ProductDetailsPromotions paymentImages={paymentImages} onClickGuarantees={onClickGuarantees} onClickPolitics={onClickPolitics} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={1} direction="column">
              <Grid item>
                <Box mt={2}>
                  <Divider />
                </Box>
              </Grid>
              <VideoComponent sku={sku || ""} />
              <Box mb={11} style={{ width: "100%" }}>
                {landingFeatures
                  .filter((item) => item.name.split(",").length > 1)
                  .map((item) => (
                    <ProductDetailsInfo
                      title={item.name.split(",")[0]}
                      description={item.value}
                      image={item.name.split(",")[1]}
                      icon={{ name: item.name.split(",")[1], isValid: item.name.split(",")[3] }}
                      imgPosition={item.name.split(",")[2] as IMAGE_POSITION}
                      sku={sku || ""}
                    />
                  ))}
              </Box>
            </Grid>
            <ProductEspecification data={product} />
          </Box>
        </Card>
      </Grid>
      {dataModal && (
        <ModalDialog title={dataModal.name} open={openMeansPayment} onHandleClose={onHandleCloseModal}>
          <>{dataModal.render}</>
        </ModalDialog>
      )}
      <ScrollTop />
    </Grid>
  );
};
