import { TextFieldProps } from "@material-ui/core/TextField";
import { TranslationFunction } from "../commons/services/translation/service";

export const makeGetErrorAndHelperText =
  (t: TranslationFunction = (str: string) => str) =>
  (field: any): { error: boolean; helperText?: string } => {
    const helperText = field.meta.touched && field.meta.error && t(field.meta.error);
    const error = !!helperText;
    if (!error) {
      return { error };
    }
    return { error, helperText };
  };

export const getErrorAndHelperTextNoTranslation = makeGetErrorAndHelperText();

export const textFieldProps = (label: string): TextFieldProps => ({
  id: label,
  label,
  fullWidth: true,
  variant: "outlined",
  margin: "normal",
});
