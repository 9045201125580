import { Tooltip, withStyles } from "@material-ui/core";
import { get } from "lodash";

interface AppTooltipProps {
  color?: string;
}

const AppTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: ({ color = "primary.dark" }: AppTooltipProps) => get(theme.palette, color),
    boxShadow: theme.shadows[1],
    padding: "4px 16px",
    borderRadius: "4px",
  },
}))(Tooltip);

const i = AppTooltip;

export { i as AppTooltip };
