import { routes } from "./routes";
import { MenuItem } from "../../../../commons/ui/menu";

export const customRedirectsMenu: MenuItem = {
  key: "customredirect",
  basePath: "/bancoprovincia-diadelamadre",
  enabled: true,
  icon: "List",
  text: "Devoluciones",
  children: routes,
  default: true,
};
