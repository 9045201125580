import React from "react";
import { Button, Toolbar, Typography, Grid } from "@material-ui/core";
import { AppHeaderMenuProps } from "./types";

export const AppHeaderMenu = ({ contents }: AppHeaderMenuProps) => (
  <Toolbar variant="dense" disableGutters>
    <Grid container direction="row" justify="space-between">
      <Grid item container xs={8} md={9} lg={10}>
        {contents.map(({ id, isSelected, label, onClick }) => (
          <Button color={isSelected ? "primary" : "default"} key={id} style={{ textTransform: "none" }} onClick={onClick}>
            <Typography variant="body1">{label}</Typography>
          </Button>
        ))}
      </Grid>
    </Grid>
  </Toolbar>
);
