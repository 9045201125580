import React, { useEffect, useState, useCallback } from "react";
import { PersonalInfoForm } from "src/app/views";
import { isEmail } from "src/utils/helpers";
import { useCartContext } from "src/context";
import { useConfigurationsContext } from "src/context/Configuration/ConfigurationContextProvider";
import { useCartDAO, usePaymentDAO } from "src/app/business";
import { Decidir } from "src/app/services";
import { useEmittersContext } from "src/context/EmittersContext/EmittersContextProvider";
import { CONFIGURATION_ID } from "src/app/models/Configuration";
import { useOrderDAO } from "src/app/business/Order";
import moment from "moment";
import { FormProps } from "../../types";
import { useCheckoutFormContext } from "../../hooks";

export const PersonalInformationForm = ({ onBackStep, setOpenModal, setReCaptcha, pendingCredit, setPendingCredit }: FormProps) => {
  const { cartState } = useCartContext();
  const { configurations } = useConfigurationsContext();
  const cartDAO = useCartDAO();
  const { form, values, errors, handleSubmit } = useCheckoutFormContext();
  const [birthdayValid, setBirthdayValid] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [reCaptchaVisible, setReCaptchaVisible] = useState(false);
  const [reCaptchaValue, setReCaptchaValue] = useState("");
  const [open, setOpen] = useState(false);
  const { emitters } = useEmittersContext();
  const orderDAO = useOrderDAO();
  const paymentDAO = usePaymentDAO();

  const handleError = () => {
    setInvalidDate(true);
    setBirthdayValid(true);
  };

  useEffect(() => {
    if (values.personalInfo.dateOfBirty) {
      if (values.personalInfo.dateOfBirty.toString() === "Invalid Date") {
        return handleError();
      }
      setInvalidDate(false);
      const eightYearsAgo = moment().subtract(18, "y");
      const birthday = moment(values.personalInfo.dateOfBirty);
      setBirthdayValid(!eightYearsAgo.isAfter(birthday));
    }
    if (!values.personalInfo.dateOfBirty) {
      setInvalidDate(false);
      setBirthdayValid(false);
    }
  }, [values.personalInfo.dateOfBirty]);

  useEffect(() => {
    if (values.personalInfo.termsAndConditions && isEmail(values.personalInfo.email)) {
      cartDAO.updateCart({ owner: cartState.owner, items: cartState.items, state_id: cartState.state_id, email: values.personalInfo.email });
    }
  }, [cartDAO, cartState.items, cartState.owner, cartState.state_id, values.personalInfo.email, values.personalInfo.termsAndConditions]);

  const onValidateOrder = useCallback(async () => {
    try {
      const res = await orderDAO.getOrderStateByBuyer(values.personalInfo.document);
      if (res) {
        setPendingCredit(true);
        setOpen(true);
      } else {
        setPendingCredit(false);
      }
    } catch (err) {
      // do nothing
    }
  }, [orderDAO, setPendingCredit, values.personalInfo.document]);

  useEffect(() => {
    if (values.personalInfo.document.length > 9) {
      onValidateOrder();
    }
  }, [onValidateOrder, values.personalInfo.document.length]);

  const validationIpAddres = useCallback(async () => {
    const ipAddress = await Decidir.getIp();

    if (!(await paymentDAO.paymentValidationIpAddress(ipAddress))) {
      setReCaptchaVisible(true);
    } else {
      setReCaptcha(true);
    }
  }, [paymentDAO, setReCaptcha]);

  useEffect(() => {
    const value: { enable: boolean; interval: number } = configurations.find(
      (item) => item.general_config_id.toString() === CONFIGURATION_ID.RECAPTCHA.toString(),
    )?.value;

    if (value.enable) {
      if (value.interval !== 0) validationIpAddres();
      else setReCaptchaVisible(true);
    } else setReCaptcha(true);
  }, [configurations, setReCaptcha, validationIpAddres]);

  useEffect(() => {
    if (reCaptchaValue) setReCaptcha(true);
    else if (reCaptchaVisible) setReCaptcha(false);
  }, [reCaptchaValue, reCaptchaVisible, setReCaptcha]);

  const handleReCaptcha = (values: any) => {
    setReCaptchaValue(values);
  };

  const handleModal = () => {
    setOpen(false);
  };

  return (
    <PersonalInfoForm
      openTermsAndConditions={() => setOpenModal && setOpenModal(true)}
      disableNext={Object.keys(errors as any).length !== 0}
      birthdayValid={birthdayValid}
      invalidDate={invalidDate}
      onClickStep={handleSubmit}
      emitters={emitters}
      onBackStep={onBackStep}
      form={form}
      isResponsable={values.isResponsable === "true"}
      handleReCaptcha={handleReCaptcha}
      ReCaptchaVisible={reCaptchaVisible}
      openModal={open}
      handleModal={handleModal}
      pendingCredit={pendingCredit}
    />
  );
};
