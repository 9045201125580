import React from "react";
import { Grid, Box, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { GenericFilterData, FilterList } from "src/commons/ui/view/GenericFilter";

interface FilterListViewProps extends GenericFilterData {
  onAddFilter: (value: FilterList) => void;
}

export const FilterListView = ({ title, items, onAddFilter }: FilterListViewProps) => {
  const maxRenderLevelOne = [...items];

  return (
    <Box component="div" width="100%" pb={1}>
      <Grid container>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" fontSize="small" />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h6" color="textPrimary">
                {title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "inherit" }}>
              {maxRenderLevelOne.map((item) => {
                const maxRenderLevelTwo = [...(item.subItems || [])];
                return (
                  <Grid key={item.name}>
                    {!item.subItems ? (
                      <Typography
                        key={item.name.toString()}
                        style={{ cursor: "pointer" }}
                        onClick={() => onAddFilter(item)}
                        variant="body1"
                        color="primary"
                      >
                        {item.name}
                      </Typography>
                    ) : (
                      maxRenderLevelTwo?.map((_item) => (
                        <Typography
                          key={_item.name.toString()}
                          style={{ cursor: "pointer" }}
                          onClick={() => onAddFilter(_item)}
                          variant="body1"
                          color="primary"
                        >
                          {_item.name}
                        </Typography>
                      ))
                    )}
                  </Grid>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};
