import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "@material-ui/core";
import { CheckoutView, StepRecord } from "src/app/views";
import { CartTotal, CartItemStock } from "src/context/CartContext/types";
import { getDiscountPublication, getInterest } from "src/utils";
import { useCartContext } from "src/context";
import { useSnackbar } from "notistack";
import { getPricePublicationCart } from "src/context/CartContext/utils";
import { getInfoPublicationPayment } from "src/commons/hooks/useInfoPublicationPayment";
import { useEmittersContext } from "src/context/EmittersContext/EmittersContextProvider";
import { useCheckoutFormContext, useIsResponsableInscriptoLogic, useStepTemplateLogic, usePersonalCreditLogic } from "../hooks";
import { FormProps, ValuesForm, PaymentFormat } from "../types";
import { cardInfoInitialValue, deliveryInitialValue, paymentInitialValue, responsableInitialValues } from "../initialValues";
import { useInfoCards } from "../hooks/useInfoCards";
import { useShipmentDAO } from "../../../../app/business";
import { CartItem, Shipment, defaultConfigValues } from "../../../../app/models";
import { Analytics } from "../../../../platform/analytics";

interface ShipmentData {
  quantity: number;
  shipment_category_id: number;
}

export interface CheckoutFormProps {
  totals: CartTotal;
  cartItems: CartItemStock[];
  totalSteps: StepRecord[];
  activeStep: number;
  steps: StepRecord[];
  maxStep: number;
  completedSteps: Set<number>;
  deliveryData: Shipment;
  setOpenModal: (value: boolean) => void;
  component: ({ onBackStep }: FormProps) => JSX.Element;
  onClickStep: (value: number) => void;
  onBackStep: () => void;
  isBlocking: boolean;
  setIsBlocking: (value: boolean) => void;
  setDeliveryData: (value: Shipment) => void;
  setStepCheckout: React.Dispatch<React.SetStateAction<StepRecord[]>>;
}

export const CheckoutForm = ({
  totals,
  totalSteps,
  cartItems,
  activeStep,
  steps,
  completedSteps,
  deliveryData,
  setOpenModal,
  onClickStep,
  onBackStep,
  isBlocking,
  component,
  setIsBlocking,
  setDeliveryData,
  setStepCheckout,
}: CheckoutFormProps) => {
  useIsResponsableInscriptoLogic();
  const { emitters } = useEmittersContext();
  usePersonalCreditLogic({ steps: totalSteps, setStepCheckout, emitters });
  useStepTemplateLogic(activeStep, completedSteps, steps);
  const { values, errors, handleSubmit } = useCheckoutFormContext();

  const shiptemntDAO = useShipmentDAO();
  const { cartState, dispatch } = useCartContext();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [reCaptcha, setReCaptcha] = useState(false);
  const [pendingCredit, setPendingCredit] = useState(false);

  const valuesForm: ValuesForm = {
    cardInfo: cardInfoInitialValue,
    paymentMethod: paymentInitialValue,
    deliveryInfo: deliveryInitialValue,
    responsableInscripto: responsableInitialValues,
    summaryAddress: deliveryInitialValue,
    ...values,
  };

  const {
    paymentMethod: { card, payment },
  } = valuesForm;

  const [cardValidate, setCardValidate] = useState(true);

  useEffect(() => {
    (async () => {
      if (valuesForm.deliveryInfo.province.id !== "") {
        const totals = cartState.items.reduce<number>((accum, item) => {
          const price = getPricePublicationCart(item.publication, valuesForm.paymentMethod.payment);
          accum += +price * item.quantity;
          return accum;
        }, 0);
        const shipmentData: ShipmentData[] = cartState.items.map(({ quantity, publication }) => ({
          quantity,
          shipment_category_id: publication.shipment_category_id,
        }));
        const dataCost: Shipment = await shiptemntDAO.calculate(valuesForm.deliveryInfo.province.id, totals, shipmentData);
        setDeliveryData(dataCost);
      }
    })();
  }, [valuesForm.paymentMethod.payment, shiptemntDAO, setDeliveryData, cartState.items, valuesForm.deliveryInfo.province.id]);

  const valuesAcordion = useInfoCards(valuesForm, completedSteps);

  const paymentFormat = useMemo<PaymentFormat>(() => {
    let communFactor = 0;
    const totalItems = cartState.items.length;
    const preConfig = cartState.items.reduce<{ main_price: boolean; main_amount: "original_amount" | "amount" }>((accum, item) => {
      const { mainAmount, main_price } = getInfoPublicationPayment(item.publication);
      if (defaultConfigValues.showPrice.main_amount !== mainAmount || defaultConfigValues.showPrice.main_price !== main_price) {
        communFactor += 1;
        accum = { main_price, main_amount: mainAmount };
      }
      return accum;
    }, defaultConfigValues.showPrice);
    const config = communFactor === totalItems ? preConfig : defaultConfigValues.showPrice;
    const isCaprescaEdu = payment.reference === "19";
    const amountFinal = payment[isCaprescaEdu ? config.main_amount : "amount"];
    const discountPorcent = payment.amount ? getDiscountPublication(amountFinal.toString(), payment.original_amount) : 0;
    const discount = payment.original_amount - amountFinal || 0;
    const interest = discountPorcent <= 0 ? getInterest(payment.original_amount, amountFinal) : 0;
    const totalPrice = cartState.items.reduce<number>((accum, item) => {
      const price = getPricePublicationCart(item.publication, payment);
      accum += +price * item.quantity;
      return accum;
    }, 0);
    const amount = totalPrice || totals.subTotal;

    const shipment = cartState.items.filter(({ publication }) => publication.shipment_category_id === 2);
    return {
      method: card,
      installments: payment.installments,
      totalAmount: amount + (deliveryData?.cost || 0) - discount,
      amount,
      shipping: !!shipment.length,
      discount: payment.original_amount - amountFinal || 0,
      interest,
      textPayment: payment.installments > 1 ? `cuotas${interest > 0 ? " fijas" : ""}` : "pago",
      textInterest: (interest === 0 && payment.installments > 1 && `sin interés`) || "",
      shipmentCost: deliveryData?.cost || 0,
    };
  }, [payment, card, deliveryData, totals.subTotal, cartState.items]);

  const onUndoAction = useCallback(
    (item: CartItem, one?: boolean) => {
      if (one) {
        dispatch.editQuantity({ id: item.publication.id, newQuantity: item.quantity + 1 });
      } else {
        dispatch.addItem(item);
      }
      dispatch.updateCartTotals();
      closeSnackbar();
    },
    [dispatch, closeSnackbar],
  );

  const onRemoveItem = (item: CartItem) => {
    if (item.quantity === 1) {
      dispatch.removeItem(item.publication.id);
      Analytics.trackRemoveFromCart({ publication: item.publication, quantity: 1 });
      enqueueSnackbar("Eliminaste un producto. ¿Te arrepentiste?", {
        variant: "success",
        action: (
          <Button color="inherit" size="small" onClick={() => onUndoAction(item)}>
            Deshacer
          </Button>
        ),
      });
    } else {
      dispatch.editQuantity({ id: item.publication.id, newQuantity: item.quantity - 1 });
      enqueueSnackbar(`Eliminaste ${item.publication.product.slice(0, 15)} x 1, ¿Te arrepentiste?`, {
        variant: "success",
        action: (
          <Button color="inherit" size="small" onClick={() => onUndoAction(item, true)}>
            Deshacer
          </Button>
        ),
      });
    }
    dispatch.updateCartTotals();
  };

  const zipCode = useMemo(() => valuesForm.deliveryInfo.zipCode || "", [valuesForm.deliveryInfo.zipCode]);

  const invalidZipCode = useMemo(() => {
    let valid = false;
    if (valuesForm.summaryAddress.zipCode) valid = !valuesForm.summaryAddress.city?.zip?.includes(Number(valuesForm.summaryAddress?.zipCode));
    else if (valuesForm.guarantorInfo?.zipCode) valid = !valuesForm.guarantorInfo?.city?.zip?.includes(Number(valuesForm.guarantorInfo?.zipCode));
    else if (valuesForm.applicantInfo?.zipCode) valid = !valuesForm.applicantInfo?.city?.zip?.includes(Number(valuesForm.applicantInfo?.zipCode));
    else if (valuesForm.deliveryInfo.zipCode) valid = !valuesForm.deliveryInfo.city?.zip?.includes(Number(zipCode));
    return valid;
  }, [
    valuesForm.applicantInfo?.city?.zip,
    valuesForm.applicantInfo?.zipCode,
    valuesForm.deliveryInfo.city?.zip,
    valuesForm.deliveryInfo.zipCode,
    valuesForm.guarantorInfo?.city?.zip,
    valuesForm.guarantorInfo?.zipCode,
    valuesForm.summaryAddress.city?.zip,
    valuesForm.summaryAddress.zipCode,
    zipCode,
  ]);
  const [cardExpirationErrorValidation, setCardExpirationErrorValidation] = useState({ error: false, helperText: "" });

  return (
    <CheckoutView
      setOpenModal={setOpenModal}
      cartItems={cartItems}
      valuesAcordion={valuesAcordion}
      paymentFormat={paymentFormat}
      steps={steps}
      activeStep={activeStep}
      totals={totals}
      onClickStep={onClickStep}
      onBackStep={onBackStep}
      completedSteps={completedSteps}
      component={component}
      onBuyClick={handleSubmit}
      isBlocking={isBlocking}
      setIsBlocking={setIsBlocking}
      disabledBuyButton={
        invalidZipCode ||
        Object.keys(errors as any).length !== 0 ||
        !cardValidate ||
        cardExpirationErrorValidation.error ||
        !reCaptcha ||
        pendingCredit
      }
      deliveryData={deliveryData}
      onRemoveItem={onRemoveItem}
      setCardValidate={setCardValidate}
      setCardExpirationErrorValidation={setCardExpirationErrorValidation}
      cardExpirationErrorValidation={cardExpirationErrorValidation}
      payment={payment}
      setReCaptcha={setReCaptcha}
      pendingCredit={pendingCredit}
      setPendingCredit={setPendingCredit}
    />
  );
};
