import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core";
import { ColorBar, Header } from "..";
import { MenuCollection } from "../../ui/menu";

const useStyles = makeStyles(() => ({
  container: {
    background: "#000000",
    width: "100vw",
    height: "100vh",
    margin: -8,
  },
  menuContainer: {
    flexDirection: "row",
    flex: 1,
    paddingLeft: 25,
    paddingRight: 25,
  },
  sideAndContent: {
    display: "flex",
    flexDirection: "row",
  },
}));

interface NoMenuLayoutProps {
  children: React.ReactNode;
  menu: MenuCollection;
}

export const NoMenuLayout: FunctionComponent<NoMenuLayoutProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ColorBar />
      <div className={classes.menuContainer}>
        <Header />
        <main>
          <div />
          {children}
        </main>
      </div>
    </div>
  );
};
