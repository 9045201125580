import React, { FunctionComponent, ReactNode } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { MainButton } from "../MainButton";
import { grey } from "../../../theme/colors";

interface HeaderTablesTypesProps {
  title: string | JSX.Element;
  titleMainButton?: string;
  children?: JSX.Element;
  handleOnClick?(): void;
  renderLeft?(): ReactNode;
  renderRight?(): ReactNode;
  onBack?: () => void;
}

const useStyles = makeStyles({
  typography: {
    color: grey.medium,
    fontWeight: "normal",
    verticalAlign: "center",
  },
});

export const HeaderTable: FunctionComponent<HeaderTablesTypesProps> = ({
  title,
  titleMainButton,
  children,
  handleOnClick,
  renderLeft,
  renderRight,
  onBack,
}) => (
  <>
    <div style={{ width: "100%" }}>
      <Box display="flex" p={2} paddingX={0} alignItems="center">
        <Box p={0} flexGrow={1} flexDirection="row">
          {!renderLeft && (
            // eslint-disable-next-line react-hooks/rules-of-hooks
            <Typography variant="h4" className={useStyles().typography}>
              {onBack && <ChevronLeft onClick={onBack} fontSize="small" style={{ cursor: "pointer" }} />}
              {title}
            </Typography>
          )}
          {!!renderLeft && renderLeft()}
        </Box>
        <Box p={0}>
          {titleMainButton && <MainButton title={titleMainButton} handleOnClick={handleOnClick} />}
          {!!renderRight && renderRight()}
        </Box>
      </Box>
      {children && (
        <Box display="flex" p={1} paddingX={0}>
          {children}
        </Box>
      )}
    </div>
  </>
);
