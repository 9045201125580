import { TemplateProperty } from "src/lib/templates";
import Joi from "joi";

export const changePasswordTemplate: TemplateProperty = {
  name: "changePasswordInfo",
  type: {
    name: "object",
    kind: [
      {
        name: "password",
        type: { name: "string" },
        label: "Clave",
        validators: [
          { name: "pattern", signature: ["^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$"], errorMessage: "Verificá cumplir con los requisitos de contraseña." },
          { name: "min", signature: [6], errorMessage: "Verificá cumplir con los requisitos de contraseña." },
          { name: "max", signature: [20], errorMessage: "Verificá cumplir con los requisitos de contraseña." },
          { name: "empty", errorMessage: "Completa este dato" },
        ],
      },
      {
        name: "confirmPassword",
        type: { name: "string" },
        label: "Confirmar clave",
        validators: [
          { name: "pattern", signature: ["^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$"], errorMessage: "Verificá cumplir con los requisitos de contraseña." },
          { name: "empty", errorMessage: "Completa este dato" },
          { name: "min", signature: [6], errorMessage: "Verificá cumplir con los requisitos de contraseña." },
          { name: "max", signature: [20], errorMessage: "Verificá cumplir con los requisitos de contraseña." },
          { name: "valid", signature: [Joi.ref("password")] },
        ],
        options: { messages: { "any.only": "Ambas contraseñas deben ser iguales." } },
      },
    ],
  },
};
