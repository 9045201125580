import React from "react";
import { Grid, Divider, Typography, useMediaQuery, Theme } from "@material-ui/core";
import { Publication } from "src/app/models";
import { matchStatisticsStyles } from "src/app/views/styles";

interface MatchStatisticsProps {
  dataPublication: Publication;
}

export const MatchStatistics = ({ dataPublication }: MatchStatisticsProps) => {
  const classes = matchStatisticsStyles();
  const { localTeam, oponentTeam } = dataPublication.attributes.tokenMetadata.matchInfo;
  const isDown768 = useMediaQuery<Theme>((theme) => theme.breakpoints.down(768));

  return (
    <Grid className={classes.mainContainer}>
      <Grid className={classes.resultsContainer}>
        <Grid className={classes.resultsItemLeft}>
          <Typography variant="h2">{localTeam.name}</Typography>
          <Typography variant="h1" className={classes.text}>
            {localTeam.goals.length}
          </Typography>
        </Grid>
        <Divider orientation="vertical" />
        <Grid className={classes.resultsItemRight}>
          <Typography variant="h1" className={classes.text}>
            {oponentTeam.goals.length}
          </Typography>
          <Typography variant="h2">{oponentTeam.name}</Typography>
        </Grid>
      </Grid>
      <Divider orientation="horizontal" className={classes.fullWidth} />
      <Grid className={classes.resultsContainer}>
        <Grid className={classes.goalsItem}>
          {localTeam.goals.map((item: any) => (
            <>
              <Typography variant={`${isDown768 ? "body2" : "body1"}`} className={classes.text}>{`${item.player} (${item.minute})`}</Typography>
            </>
          ))}
        </Grid>
        <Divider orientation="vertical" />
        <Grid className={classes.goalsItem} style={{ paddingLeft: 10 }}>
          {oponentTeam.goals.map((item: any) => (
            <>
              <Typography variant={`${isDown768 ? "body2" : "body1"}`} className={classes.text}>{`${item.player} (${item.minute})`}</Typography>
            </>
          ))}
        </Grid>
      </Grid>
      <Divider orientation="horizontal" className={classes.fullWidth} />
      <Grid className={classes.circle} />
      <Grid className={classes.verticalContainer}>
        <Grid className={classes.rowContainer} style={{ marginTop: 35 }}>
          <Typography variant={`${isDown768 ? "body1" : "subtitle2"}`}>{localTeam.possession}</Typography>
          <Typography variant="body1" className={classes.matchInfoText}>
            {" "}
            Posesión{" "}
          </Typography>
          <Typography variant={`${isDown768 ? "body1" : "subtitle2"}`}>{oponentTeam.possession}</Typography>
        </Grid>
        <Grid className={classes.rowContainer}>
          <Typography variant={`${isDown768 ? "body1" : "subtitle2"}`}>{localTeam.shotsOnGoal}</Typography>
          <Typography variant="body1" className={classes.matchInfoText}>
            {" "}
            Remates al arco{" "}
          </Typography>
          <Typography variant={`${isDown768 ? "body1" : "subtitle2"}`}>{oponentTeam.shotsOnGoal}</Typography>
        </Grid>
        <Grid className={classes.rowContainer}>
          <Typography variant={`${isDown768 ? "body1" : "subtitle2"}`}>{localTeam.offside}</Typography>
          <Typography variant="body1" className={classes.matchInfoText}>
            {" "}
            Fuera de juego{" "}
          </Typography>
          <Typography variant={`${isDown768 ? "body1" : "subtitle2"}`}>{oponentTeam.offside}</Typography>
        </Grid>
        <Grid className={classes.rowContainer}>
          <Typography variant={`${isDown768 ? "body1" : "subtitle2"}`}>{localTeam.faults}</Typography>
          <Typography variant="body1" className={classes.matchInfoText}>
            {" "}
            Faltas cometidas{" "}
          </Typography>
          <Typography variant={`${isDown768 ? "body1" : "subtitle2"}`}>{oponentTeam.faults}</Typography>
        </Grid>
        <Grid className={classes.rowContainer}>
          <Typography variant={`${isDown768 ? "body1" : "subtitle2"}`}>{localTeam.yellowCards}</Typography>
          <Typography variant="body1" className={classes.matchInfoText}>
            {" "}
            Tarjetas Amarillas{" "}
          </Typography>
          <Typography variant={`${isDown768 ? "body1" : "subtitle2"}`}>{oponentTeam.yellowCards}</Typography>
        </Grid>
        <Grid className={classes.rowContainer}>
          <Typography variant={`${isDown768 ? "body1" : "subtitle2"}`}>{localTeam.redCards}</Typography>
          <Typography variant="body1" className={classes.matchInfoText}>
            {" "}
            Tarjetas Rojas{" "}
          </Typography>
          <Typography variant={`${isDown768 ? "body1" : "subtitle2"}`}>{oponentTeam.redCards}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
