import { makeStyles } from "@material-ui/core";

export const appBreadcrumbsStyles = makeStyles((theme) => ({
  breadcrumbs: { width: "100%", marginTop: 17 },
  breadcrumbsLi: { margin: 0 },
  breadCrumbLast: {
    color: theme.palette.secondary.contrastText,
    textDecoration: "none",
  },
  breadcrumbRootLink: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
  lastLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  link: {
    color: theme.palette.text.secondary,
    textDecoration: "none",
  },
}));
