import { BuyerAddress, BuyerAddressInfo } from "../../../app/models";
import { EcommerceDAO } from "./EcommerceDAO";

export class BuyerAddressDAO extends EcommerceDAO<BuyerAddress> {
  constructor() {
    super("buyer_address", "buyer_address_id");
  }

  async getAllAddress() {
    const data = await this.fetchData("/buyer/address");
    return data.json();
  }

  async getAddressById(addressId: string) {
    const data = await this.fetchData(`/buyer/address/${addressId}`);
    return data.json();
  }

  async save(data: BuyerAddressInfo) {
    try {
      await this.fetchData(`/buyer/address`, {
        method: data.buyer_address_id ? "PUT" : "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.log(error);
      throw new Error("Fail request save");
    }
  }

  async fetchById(id: string) {
    const data = await this.fetchData(`/buyer/address/${id}`);
    return data.json();
  }

  async remove(id: string) {
    const res = await this.fetchData(`/buyer/address/${id}`, {
      method: "delete",
    });
    if (!res.ok) throw new Error(res.statusText);
  }
}
