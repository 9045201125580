import React from "react";
import ReactHtmlParser from "react-html-parser";
import LocationOnIcon from "@material-ui/icons/LocationOnOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FavoriteButtonView, SalesNFTModal } from "src/app/views/components";
import { Publication, UserRole } from "src/app/models";
import { DummyAvatar } from "src/assets/images";
import { OneStar } from "src/assets/images/svgs";
import { ONESTAR_ADDRESS } from "src/config";
import { PlayerStatistics } from "src/app/views/components/PlayerStatistics";
import { SalesHistory } from "src/app/views/components/SalesHistory";
import { ProductViewTags } from "src/app/views/components/ProductViewTags";
import { PurchaseNFTModal } from "src/app/views/components/PurchaseNFTModal";
import { Grid, Box, Typography, CardContent, CardMedia, Accordion, AccordionSummary, AccordionDetails, makeStyles } from "@material-ui/core";
import { ecommercePalette } from "src/config/ecommerce/nftmarket/ecommercePalette";
import { MatchStatistics } from "../../MatchStatistics";

const useStyles = makeStyles((theme) => ({
  uppercaseText: {
    textTransform: "uppercase",
  },
  text: {
    color: ecommercePalette.materialUi.neutral[2],
  },
  accordionIcon: {
    justifyContent: "flex-start",
    "& > div": { flexGrow: 0 },
  },
  button: {
    marginTop: -24,
    marginBottom: -22,
  },
  textOverImg: {
    marginBottom: 8,
  },
  textLocation: {
    marginTop: 2,
    marginLeft: -8,
  },
  gridLocation: {
    marginTop: -8,
    marginLeft: -4,
  },
  publicationDate: {
    marginTop: -8,
  },
  product: {
    marginTop: -16,
    [theme.breakpoints.down(350)]: {
      width: "96%",
    },
  },
}));

interface ProductNFTViewMobileProps {
  dataPublication: Publication;
  selectedRole: UserRole;
  itBelongsOfOwner: boolean;
  refs: any;
  imgUrl?: string;
  selectedCurrency: "USD" | "ETH";
  tokenId: string;
  salesHistory: any;
  tagsFilter: any;
  priceEth: string | JSX.Element;
  priceUsd: string;
}

export const ProductNFTViewMobile = ({
  dataPublication,
  selectedRole,
  itBelongsOfOwner,
  refs,
  imgUrl,
  selectedCurrency,
  tokenId,
  salesHistory,
  tagsFilter,
  priceEth,
  priceUsd,
}: ProductNFTViewMobileProps) => {
  const classes = useStyles();
  return (
    <CardContent>
      <Box position="relative">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.uppercaseText} color="primary">
              {ReactHtmlParser(dataPublication.attributes.nftInfo?.start || "")}
            </Typography>
          </Grid>
          <Grid item className={classes.product}>
            <Typography variant="body1">{ReactHtmlParser(dataPublication?.product)}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.publicationDate}>
            <Typography variant="body2" className={classes.text}>
              {`${dataPublication?.attributes.nftInfo?.typeOfGame} - ${dataPublication?.attributes.nftInfo?.date}`}
            </Typography>
          </Grid>
          <Grid item container className={classes.gridLocation}>
            <Grid item xs={1} container>
              <Box color="#737373">
                <LocationOnIcon />
              </Box>
            </Grid>
            <Grid xs={11} item className={classes.textLocation}>
              <Box pl="8px" color="#737373">
                <Typography variant="body2" color="inherit">
                  {dataPublication?.attributes.nftInfo?.location}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item container alignItems="center" className={classes.textOverImg}>
              {dataPublication.attributes.tokenOwner === ONESTAR_ADDRESS ? (
                <OneStar width={24} height={24} />
              ) : (
                <Grid style={{ borderRadius: "50%" }} component="img" width="16px" height="16px" src={DummyAvatar} />
              )}
              <Box pl="8px" color="#737373">
                <Typography color="textPrimary" variant="subtitle2">
                  {dataPublication.attributes.tokenOwner === ONESTAR_ADDRESS ? "OneStar" : dataPublication.attributes.ownerNick?.toLowerCase()}
                </Typography>
              </Box>
              <FavoriteButtonView fontSize="small" withCountFavorites right={0} publicationId={dataPublication.id} />
            </Grid>
            {itBelongsOfOwner ? (
              <Box minHeight={200}>
                <Grid ref={refs} container id="embedBox" />
              </Box>
            ) : (
              <CardMedia component="img" image={imgUrl} alt="Product image" />
            )}
          </Grid>

          <Grid item container xs={12} direction="column" spacing={1}>
            <Grid item container justify="flex-start" alignItems="center">
              <Typography variant="h4" color="primary">
                {selectedCurrency === "USD" ? <>{priceUsd}</> : <>{priceEth}</>}
              </Typography>
            </Grid>
          </Grid>

          {selectedRole.rol_id !== "" ? (
            itBelongsOfOwner ? (
              <Grid item className={classes.button}>
                <SalesNFTModal item={dataPublication} />
              </Grid>
            ) : (
              <Grid item className={classes.button}>
                <PurchaseNFTModal item={dataPublication} />
              </Grid>
            )
          ) : (
            <Grid item>
              <Typography variant="caption">Inicia sesión para comprar NFTs</Typography>
            </Grid>
          )}
          <Grid item>
            <Typography variant="body1" color="inherit">
              <ProductViewTags tags={tagsFilter} />
              {ReactHtmlParser(dataPublication?.attributes.description || "")}
            </Typography>
          </Grid>
          <Grid item container direction="column">
            <Grid item>
              <Accordion>
                <AccordionSummary
                  className={classes.accordionIcon}
                  expandIcon={<ExpandMoreIcon color="secondary" fontSize="small" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h5">Estadísticas del partido</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MatchStatistics dataPublication={dataPublication} />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item>
              <Accordion>
                <AccordionSummary
                  className={classes.accordionIcon}
                  expandIcon={<ExpandMoreIcon color="secondary" fontSize="small" />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h5">Estadísticas del jugador</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <PlayerStatistics name={dataPublication.attributes.nftInfo.start} starInfo={dataPublication.attributes.tokenMetadata.starInfo} />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item>
              <Accordion>
                <AccordionSummary
                  className={classes.accordionIcon}
                  expandIcon={<ExpandMoreIcon color="secondary" fontSize="small" />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography variant="h5">Historial de ventas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <SalesHistory salesHistory={salesHistory} tokenId={tokenId} />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CardContent>
  );
};
