import moment from "moment";

export const evaluateDate = (fieldMonth: any, fieldYear: any) => {
  const today = moment();
  const day = moment().day();

  if (fieldYear === "" && fieldMonth === "") {
    return { error: false, helperText: " " };
  }
  if (Number(fieldMonth) > 12) {
    return { error: true, helperText: "Mes inválido" };
  }

  if (fieldYear === "" && fieldMonth) return { error: true, helperText: "Fecha inválida" };
  const date = `20${fieldYear}/${fieldMonth}/${day}`;
  const dateValue = moment(date, "YYYY/MM/DD");
  const helperText = dateValue.isValid() ? (dateValue.isBefore(today) ? "Tarjeta vencida" : "") : "Fecha inválida";
  return { error: !!helperText, helperText };
};
