import { makeStyles } from "@material-ui/core/styles";

export const cartItemStyles = makeStyles(() => ({
  container: {
    // alignItems: 'center',
    // minHeight: 120,
    borderRadius: 8,
    border: "1px solid #C4C4C4",
    // marginBottom: 20,
  },
  number: {
    marginLeft: "1px",
    marginTop: "3px",
    // fontFamily: 'Circular Std',
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#50106B",
  },
  text: {
    // fontFamily: 'Circular Std',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "23px",
    letterSpacing: "0.01em",
    color: "#4F4F4F",
  },
  img: {
    overflow: "hidden",
    display: "block",
    maxWidth: 120,
    margin: 5,
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: "1px solid #C4C4C4",
  },
  buttonGroup: { border: "1px solid #C4C4C4" },
}));
