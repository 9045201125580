import { TemplateProperty } from "src/lib/templates";

export const docentInfoTemplate: TemplateProperty = {
  name: "docentInfo",
  type: {
    name: "object",
    kind: [
      {
        name: "docentType",
        type: {
          name: "object",
          kind: [
            { name: "id", type: { name: "number" } },
            { name: "name", type: { name: "string" } },
          ],
        },
        label: "Tipo de Docente",
      },
      {
        name: "province",
        type: {
          name: "object",
          kind: [
            { name: "id", type: { name: "string" } },
            { name: "name", type: { name: "string" } },
            { name: "matches", type: { name: "array", kind: "string" } },
          ],
        },
        label: "Provincia",
        validators: [{ name: "province", signature: [3] }],
      },
      {
        name: "city",
        type: {
          name: "object",
          kind: [
            { name: "id", type: { name: "string" } },
            { name: "state_id", type: { name: "number" } },
            { name: "zip", type: { name: "array", kind: "number" } },
            { name: "name", type: { name: "string" } },
            { name: "matches", type: { name: "array", kind: "string" } },
          ],
        },
        label: "Ciudad",
      },
      {
        name: "street",
        type: { name: "string" },
        label: "Calle",
        validators: [
          { name: "pattern", signature: ["^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$"] },
          { name: "max", signature: [45] },
        ],
      },
      {
        name: "streetNumber",
        type: { name: "string" },
        label: "Altura",
        validators: [
          {
            name: "pattern",
            signature: ["^[0-9]+$"],
          },
        ],
      },
      {
        name: "floor",
        type: { name: "string" },
        label: "Piso",
        optional: true,
        validators: [
          {
            name: "pattern",
            signature: ["^[A-Za-z]{0,2}[0-9]*$"],
          },
          {
            name: "max",
            signature: [5],
          },
        ],
      },
      {
        name: "apartament",
        type: { name: "string" },
        label: "Apartamento",
        optional: true,
        validators: [
          {
            name: "pattern",
            signature: ["^[A-Za-z0-9]+$"],
          },
          {
            name: "max",
            signature: [5],
          },
        ],
      },
      {
        name: "zipCode",
        type: { name: "string" },
        label: "Código postal",
        validators: [
          { name: "pattern", signature: ["^[0-9]+$"] },
          { name: "max", signature: [6] },
        ],
      },
      {
        name: "img_dni_front",
        type: { name: "any" },
        label: "DNI frente",
        validators: [
          { name: "fileSize", signature: [40000] },
          { name: "fileType", signature: ["image/png", "image/jpeg", "application/pdf", "application/msword"] },
        ],
        optional: false,
      },
      {
        name: "img_dni_back",
        type: {
          name: "any",
        },
        label: "DNI dorso",
        validators: [
          { name: "fileSize", signature: [40000] },
          { name: "fileType", signature: ["image/png", "image/jpeg", "application/pdf", "application/msword"] },
        ],
        optional: false,
      },
      {
        name: "img_certificate",
        type: {
          name: "any",
        },
        label: "Certificado",
        validators: [
          { name: "fileSize", signature: [40000] },
          { name: "fileType", signature: ["image/png", "image/jpeg", "application/pdf", "application/msword"] },
        ],
      },
      {
        name: "img_paycheck",
        type: {
          name: "any",
        },
        label: "Recibo de sueldo",
        validators: [
          { name: "fileSize", signature: [40000] },
          { name: "fileType", signature: ["image/png", "image/jpeg", "application/pdf", "application/msword"] },
        ],
        optional: false,
      },
    ],
  },
};
