import React, { useCallback, useEffect, useState } from "react";
import validateCreditCard from "card-validator";
import { evaluateDate } from "src/utils";
import { useField } from "react-final-form-hooks";
import { API_URL } from "src/config";
import { EMITTERS } from "src/app/const/Emitters";
import { FormProps, ValuesForm } from "../../../types";
import { useCheckoutFormContext } from "../../../hooks";
import { useLogicAddress } from "../../../hooks/useLogicAddress";
import { cardInfoInitialValue, deliveryInitialValue, paymentInitialValue, responsableInitialValues } from "../../../initialValues";
import { CardPaymentInfoForm } from "../../../../../../app/views";

enum typeCards {
  Credito = "Credito",
  Debito = "Debito",
}

export interface BlackList {
  bin_blacklist_id: number;
  bin: Array<string>;
  error_message: string;
}

export const CardInfoFormDecidir = ({ onBackStep, setCardValidate, cardExpirationErrorValidation, setCardExpirationErrorValidation }: FormProps) => {
  const { form, values, errors, handleSubmit } = useCheckoutFormContext();

  const fieldMonth = useField("cardInfo.cardExpiration.month", form);
  const fieldYear = useField("cardInfo.cardExpiration.year", form);

  useEffect(() => {
    const activeYear = fieldYear.meta.active;
    const activeMonth = fieldMonth.meta.active;

    const errorYear = fieldYear.meta?.error;
    const errorMonth = fieldMonth.meta?.error;

    if (setCardExpirationErrorValidation)
      if (activeMonth && errorMonth?.length) {
        setCardExpirationErrorValidation({ error: true, helperText: errorMonth });
      } else if (activeYear && errorYear?.length) {
        setCardExpirationErrorValidation({ error: true, helperText: errorYear });
      }
  }, [fieldMonth.meta.active, fieldMonth.meta.error, fieldYear.meta.active, fieldYear.meta.error, setCardExpirationErrorValidation]);

  const valuesAll: ValuesForm = {
    cardInfo: cardInfoInitialValue,
    paymentMethod: paymentInitialValue,
    responsableInscripto: responsableInitialValues,
    summaryAddress: deliveryInitialValue,
    deliveryInfo: deliveryInitialValue,
    ...values,
  };

  const logicAddress = useLogicAddress(valuesAll, errors);
  const [match, setMach] = useState(true);
  const [messageValidateCardNumber, setMessageValidateCardNumber] = useState("");

  const isCreditCard = valuesAll.paymentMethod.card.type === typeCards.Credito;
  const onChange = useCallback(
    (value: boolean) => {
      if (setCardValidate) {
        setCardValidate(value);
      }
    },
    [setCardValidate],
  );

  useEffect(
    () => () => {
      onChange(true);
      if (setCardExpirationErrorValidation) setCardExpirationErrorValidation({ error: false, helperText: "" });
    },
    [onChange, setCardExpirationErrorValidation],
  );

  const verifiedBlackList = useCallback(
    async (cardNumber: string) => {
      try {
        const response = await fetch(`${API_URL}/card/blacklist/${valuesAll.paymentMethod.payment.reference}`);
        const result: [BlackList] = await response.json();
        const validate = !result[0]?.bin.find((_bin) => _bin === cardNumber);
        setMach(validate);
        onChange(validate);
        setMessageValidateCardNumber(!validate ? result[0].error_message : "");
      } catch (error) {
        console.log(error);
      }
    },
    [valuesAll.paymentMethod.payment.reference, setMach, onChange, setMessageValidateCardNumber],
  );

  useEffect(() => {
    let message = "";
    let valueValidate: boolean = true;
    if (
      valuesAll.cardInfo.cardNumber.substr(0, 6) !== "" &&
      (valuesAll.paymentMethod.emitter.card_emiter_id === EMITTERS.BAPRO ||
        valuesAll.paymentMethod.emitter.card_emiter_id === EMITTERS.AMEX ||
        valuesAll.paymentMethod.emitter.card_emiter_id === EMITTERS.NARANJA)
    ) {
      const cardNumberMatch = valuesAll.cardInfo.cardNumber.substr(0, 6);
      const _match = !!valuesAll.paymentMethod.card.match.find((item) => item === cardNumberMatch);
      valueValidate = _match;
      if (!_match) message = "El numero de tarjeta no coincide con el medio de pago seleccionado previamente";
    } else if (valuesAll.cardInfo.cardNumber.length) {
      const { isValid } = validateCreditCard.number(valuesAll.cardInfo.cardNumber);
      valueValidate = isValid;
      if (!isValid) message = "Número de tarjeta inválido.";
      if (isValid) verifiedBlackList(valuesAll.cardInfo.cardNumber.substr(0, 6));
    }
    setMach(valueValidate);
    onChange(valueValidate);
    setMessageValidateCardNumber(message);
  }, [valuesAll.cardInfo.cardNumber, valuesAll.paymentMethod.card.match, valuesAll.paymentMethod.emitter, onChange, verifiedBlackList]);

  useEffect(() => {
    const { month, year } = valuesAll.cardInfo.cardExpiration;
    if (setCardExpirationErrorValidation && (month.length || year.length)) setCardExpirationErrorValidation(evaluateDate(month, year));
  }, [valuesAll.cardInfo.cardExpiration, setCardExpirationErrorValidation]);

  return (
    <CardPaymentInfoForm
      logicAddress={logicAddress}
      onClickStep={handleSubmit}
      useSummaryAddress={values.useDeliveryAddress}
      form={form}
      disableNext={Object.keys(errors as any).length !== 0}
      cardNumberErrorValidation={!match}
      cardNumberErrorMessageValidation={messageValidateCardNumber}
      isCredidCard={isCreditCard}
      onBackStep={onBackStep}
      cardExpirationErrorValidation={cardExpirationErrorValidation?.error || false}
      cardExpirationErrorMessageValidation={cardExpirationErrorValidation?.helperText ?? ""}
    />
  );
};
