import React from "react";
import { Typography, Grid, IconButton, Box } from "@material-ui/core";
import { DeleteOutlineOutlined } from "@material-ui/icons";
import { formatPrice, searchSkuPublication } from "src/utils";
import { QuantityButton } from "src/commons/ui/view/QuantityButton";
import { CartItemStock } from "src/context/CartContext/types";
import { IMAGE_BASE_URL } from "src/config";
import { Link } from "react-router-dom";
import { getPricePublication } from "src/context/CartContext/utils";

interface CartItemMobileProps {
  product: CartItemStock;
  onChangeQuantity: (newQuantity: number) => void;
  onDeleteItem: () => void;
  enableStock: boolean;
}

export const CartItemMobile = ({ product, onChangeQuantity, onDeleteItem, enableStock }: CartItemMobileProps) => {
  const sku = searchSkuPublication({
    featuresPublication: product.publication.attributes.features,
  });

  return (
    <Grid container>
      <Grid item xs={4}>
        <Box width="80px" height="80px" display="flex" justifyContent="center">
          <Link to={`/product/${product.publication.id}`}>
            <Grid component="img" src={`${IMAGE_BASE_URL}/Products/${sku}/main.jpg`} />
          </Link>
        </Box>
      </Grid>
      <Grid item xs={8} container>
        <Grid item container zeroMinWidth>
          <Typography noWrap align="center" variant="body2" color="textPrimary">
            <Link to={`/product/${product.publication.id}`}>{product.publication.product}</Link>
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center" variant="subtitle1" color="textPrimary">
            {`$${formatPrice(getPricePublication(product.publication) * product.quantity)}`}
          </Typography>
        </Grid>

        <Grid item container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <QuantityButton
              onAdd={() => onChangeQuantity(+1)}
              onRemove={() => onChangeQuantity(-1)}
              quantity={product.quantity}
              stock={product.stock}
              enableStock={enableStock}
            />
          </Grid>

          <Grid item>
            <IconButton aria-label="menus" onClick={onDeleteItem}>
              <DeleteOutlineOutlined />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
