import { makeStyles } from "@material-ui/core";

export const matchStatisticsStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(768)]: {
      width: "66%",
      marginTop: 0,
      marginBottom: "-25%",
    },
    [theme.breakpoints.down(321)]: {
      width: ["54%", "!important"],
    },
  },
  verticalContainer: {
    [theme.breakpoints.down(768)]: {
      width: "-webkit-fill-available",
      marginTop: 35,
      marginBottom: 35,
    },
  },
  rowContainer: {
    display: "flex",
    width: "100%",
    padding: 5,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  resultsContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down(768)]: {
      width: ["100%", "!important"],
    },
  },
  resultsItemLeft: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    "& h1": {
      fontSize: [58, "!important"],
    },
    "& h2": {
      fontSize: [20, "!important"],
    },
    [theme.breakpoints.down(768)]: {
      flexDirection: "column",
      height: "-webkit-fill-available",
    },
  },
  resultsItemRight: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    "& h1": {
      fontSize: [58, "!important"],
    },
    "& h2": {
      fontSize: [20, "!important"],
    },
    [theme.breakpoints.down(768)]: {
      flexDirection: "column-reverse",
      height: "-webkit-fill-available",
    },
  },
  goalsItem: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.down(768)]: {
      flexDirection: "column",
    },
  },
  text: {
    padding: 15,
    color: "#95F035",
  },
  matchInfoText: {
    color: "#ABABAB",
    marginTop: 5,
    marginLeft: 70,
    marginRight: 70,
    width: 170,
    textAlign: "center",
    [theme.breakpoints.down(321)]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  circle: {
    width: 30,
    height: 30,
    border: "1px solid #ABABAB",
    borderRadius: 50,
    marginTop: -50,
    [theme.breakpoints.down(768)]: {
      marginTop: -70,
    },
    [theme.breakpoints.down(321)]: {
      marginTop: [-85, "!important"],
    },
  },
  fullWidth: {
    width: "100%",
  },
}));
