import { EcommerceDAO } from "./EcommerceDAO";
import { Skeleton } from "../../../app/models";

export class SkeletonDAO extends EcommerceDAO<Skeleton> {
  constructor() {
    super("skeleton", "skeletonId");
  }

  async getSkeleton(id: string): Promise<Skeleton> {
    const response = await this.fetchData(`/skeleton/${id}`);
    return response.json();
  }
}
