import { Login } from "src/screens/Public/Login";
import { Recovery } from "src/screens/Public/Recovery";
import { MenuItem } from "../../../../commons/ui/menu";

export const routes: MenuItem[] = [
  {
    key: "publicRecoveryMain",
    basePath: "/recovery",
    target: {
      component: Recovery,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Recovery",
    children: [],
    default: true,
  },
  {
    key: "publicLoginMain",
    basePath: "/",
    target: {
      component: Login,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Login",
    children: [],
    default: true,
  },
];
