import { Button, makeStyles, MenuItem, withStyles } from "@material-ui/core";

export const currencySelectorStyles = makeStyles(() => ({
  buttonActive: {
    color: "#FF007C",
  },
  buttonDefault: {
    color: "#ABABAB",
  },
  iconArrow: {
    width: 24,
    height: 24,
  },
}));

export const StyledButton = withStyles(() => ({
  root: {
    justifyContent: "left",
    fontFamily: "Barlow",
    width: "100%",
    fontWeight: 800,
    fontStyle: "italic",
    fontSize: 17,
  },
}))(Button);

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "none",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    padding: "1px 3px",
  },
}))(MenuItem);
