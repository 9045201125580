import React, { useState, useRef, useEffect, useCallback } from "react";
import { Box, Card, CardActionArea, CardMedia, Grid, Typography, CardContent, makeStyles } from "@material-ui/core";
import { useCurrencyContext } from "src/context/CurrencyContext";
import { Product } from "src/app/models/business/Product";
import { OneStar } from "src/assets/images/svgs/OneStar";
import { ETHicon } from "src/assets/images/svgs";
import { getPricePublication } from "src/context/CartContext/utils";
import { DummyAvatar } from "src/assets/images";
import { ONESTAR_ADDRESS, VDOCIPHER_SCRIPT } from "src/config";
import { EthereumPrice } from "src/app/services";
import { AnyObject } from "src/commons";
import { formatUnits } from "ethers/lib/utils";
import { FavoriteButtonView } from "../FavoriteButtonView";

export interface ProductCardProps {
  data: Product;
  enableStock: boolean;
  loading: boolean;
  onClick: () => void;
}

export const useStyles = makeStyles(() => ({
  rowContainer: {
    width: "100%",
  },
  container: {
    width: 265,
    height: 390,
    maxHeight: 390,
    position: "relative",
  },
  containerHover: {
    position: "relative",
  },
  box: {
    overflow: "hidden",
  },
  priceText: {
    fontSize: 20,
    fontWeight: 400,
  },
  titleText: {
    textTransform: "uppercase",
  },
  productName: {
    width: "100%",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    fontWeight: 400,
    WebkitBoxOrient: "vertical",
  },
  iconOwner: {
    borderRadius: "40px",
    border: "0.5px solid #95F035",
  },
  videoPlayer: {
    "& .vc-container .ytp-fullscreen-button, & .vc-container .speedMenuButton, & .vc-container .ytp-settings-button": {
      display: "none !important",
    },
    height: 230,
  },
}));

export const NftCard = ({ data, onClick }: ProductCardProps) => {
  const classes = useStyles();
  const [onHover, setOnHover] = useState(false);
  const { selectedCurrency } = useCurrencyContext();

  const onMousesOver: React.MouseEventHandler<HTMLDivElement> = () => {
    setOnHover(true);
  };

  const refs = useRef(null);

  const loadPlayer = useCallback(() => {
    (window as AnyObject).playerContainer = refs.current;
    (window as AnyObject).vdo.add({
      autoplay: true,
      otp: data.attributes.videoInfo.otp,
      playbackInfo: data.attributes.videoInfo.playbackInfo,
      theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
      container: document.querySelector("#embedBox"),
    });
    setTimeout(() => setOnHover(false), 10000);
  }, [data]);

  useEffect(() => {
    const playerScript = document.createElement("script");
    playerScript.src = VDOCIPHER_SCRIPT;
    document.body.appendChild(playerScript);
    if (onHover) {
      playerScript.addEventListener("load", () => loadPlayer());
    }
  }, [data, loadPlayer, onHover]);

  const onMousesOut: React.MouseEventHandler<HTMLDivElement> = () => {
    setOnHover(false);
  };

  const priceUsd = EthereumPrice.getPriceBy({ value: formatUnits(getPricePublication(data)) });
  const priceEth = formatUnits(getPricePublication(data));

  const { length } = data.attributes.videoInfo.picture;
  const imageUrl = data.attributes.videoInfo.picture[length - 1].url;

  return (
    <Card className={classes.container} onMouseOver={onMousesOver} onMouseLeave={onMousesOut} onClick={onClick}>
      <CardActionArea onClick={onClick}>
        <Grid className={classes.containerHover}>
          {onHover ? (
            <div ref={refs} id="embedBox" className={classes.videoPlayer} />
          ) : (
            <CardMedia component="img" image={imageUrl} title={data.name} height={230} />
          )}
        </Grid>
        <Box component={CardContent} mt={-1} display="flex" flexDirection="column" justifyItems="space-around" minHeight={260}>
          <Grid container direction="column" justify="space-around" spacing={1} style={{ minHeight: 150 }}>
            <Grid item zeroMinWidth>
              <Typography noWrap variant="subtitle1" color="primary" className={classes.titleText}>
                {data.attributes.nftInfo.start || ""}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" color="inherit" className={classes.productName}>
                {data.attributes.nftInfo.subTitle || ""}
              </Typography>
            </Grid>
            <FavoriteButtonView top={8} right={8} withBg publicationId={data.productId.toString()} />
            <Grid item container alignItems="center">
              {data.attributes.tokenOwner === ONESTAR_ADDRESS ? (
                <OneStar width={16} height={16} />
              ) : (
                <Grid className={classes.iconOwner} component="img" width="16px" height="16px" src={DummyAvatar} />
              )}

              <Box pl="8px" color="#737373">
                <Typography variant="body2">
                  {data.attributes.tokenOwner === ONESTAR_ADDRESS ? "OneStar" : data.attributes.ownerNick?.toLowerCase()}
                </Typography>
              </Box>
            </Grid>
            <Grid item container justify="flex-end" alignContent="flex-end">
              <Typography className={classes.priceText} color="inherit">
                {selectedCurrency === "USD" ? (
                  `$ ${priceUsd}`
                ) : (
                  <>
                    <ETHicon width="18" color="white" /> {priceEth}
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardActionArea>
    </Card>
  );
};
