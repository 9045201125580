import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../../lib/libby/generator";
import { State } from "../../models";
import { businessOptions } from "../businessOptions";

const DAO_NAME = "payment";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "idPayment",
      labelKey: "descripcion",
    },
    withSelectedChips: {
      idKey: "idPayment",
      labelKey: "descripcion",
    },
  },
};

const optionsToUse = !businessOptions.Payment
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Payment,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Payment.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<State>(DAO_NAME, optionsToUse);

// hooks
export const usePaymentFetchById = fetchById;
export const usePaymentLibbyCall = libbyCall;
export const usePaymentDAO = libbyDAO;
export const usePaymentLibbyFetch = libbyFetch;

// components/hoc
export const PaymentAutocomplete = entityAutocomplete;
export const PaymentCall = renderPropCall;
export const PaymentById = renderPropFetchById;
export const PaymentSelect = simpleSelect;
export const PaymentAccordionSelector = accordionSelector;
export const PaymentSelectedChips = selectedChips;

// context
// ByIdContext
export const usePaymentByIdContext = useByIdContext;
export const PaymentByIdProvider = ByIdProvider;
export const PaymentByIdContext = ByIdContext;
// GeneralContext
// export const usePaymentContext = useGeneralContext;
// export const PaymentProvider = GeneralProvider;
// export const PaymentContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
