import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Grid, Box, IconButton, Typography, Theme, useMediaQuery } from "@material-ui/core";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { LOGIN_ENABLED } from "src/config";
import { useFavoriteManage } from "src/commons";

interface FavoriteButtonViewProps {
  publicationId: string;
  hidden?: boolean;
  top?: string | number;
  right?: string | number;
  fontSize?: "small" | "inherit" | "default" | "large";
  withCountFavorites?: boolean;
  withBg?: boolean;
}

export const FavoriteButtonView = ({
  fontSize = "default",
  hidden,
  top,
  right,
  withBg,
  publicationId,
  withCountFavorites,
}: FavoriteButtonViewProps) => {
  const [countFavorites, setCountFavorites] = useState(0);
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const [disabled, setDisabled] = useState(false);

  const { favorites, addToFavorite, removeFromFavorite, numberOfLikes } = useFavoriteManage();

  const getNumberOfLikes = useCallback(async () => {
    if (!publicationId || !withCountFavorites) return;
    const count = await numberOfLikes(publicationId);
    setCountFavorites(count);
  }, [publicationId, withCountFavorites, numberOfLikes]);

  useEffect(() => {
    getNumberOfLikes();
  }, [getNumberOfLikes]);

  const isFavorite = useMemo(() => !!favorites.find((fav) => fav.publication.id === publicationId), [favorites, publicationId]);

  const handleOnClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      try {
        setDisabled(true);
        await (isFavorite ? removeFromFavorite(publicationId) : addToFavorite(publicationId));
        await getNumberOfLikes();
        setDisabled(false);
      } catch (error) {
        console.log("Error", error);
        setDisabled(false);
      }
    },
    [isFavorite, publicationId, removeFromFavorite, addToFavorite, getNumberOfLikes],
  );

  return LOGIN_ENABLED ? (
    <Box width="100%" display="flex" hidden={hidden} zIndex={100} position="absolute" top={top} right={right}>
      <Grid container justify="flex-end" alignItems="center">
        {withCountFavorites && (
          <Box mr={isDownSm ? 0 : 1} color="#737373">
            <Typography variant={isDownSm ? "caption" : "subtitle2"}>{countFavorites}</Typography>
          </Box>
        )}
        {withBg ? (
          <Box component="div" bgcolor="rgba(0, 0, 0, 0.4)" color="neutral.1" borderRadius={4}>
            <IconButton disabled={disabled} size="small" onClick={handleOnClick}>
              {isFavorite ? <FavoriteIcon htmlColor="white" fontSize={fontSize} /> : <FavoriteBorderIcon htmlColor="white" fontSize={fontSize} />}
            </IconButton>
          </Box>
        ) : (
          <IconButton disabled={disabled} size="small" onClick={handleOnClick}>
            {isFavorite ? <FavoriteIcon htmlColor="white" fontSize={fontSize} /> : <FavoriteBorderIcon htmlColor="white" fontSize={fontSize} />}
          </IconButton>
        )}
      </Grid>
    </Box>
  ) : (
    <></>
  );
};
