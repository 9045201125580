import { Template } from "src/lib/templates";
import { personalInfoCaprescaTemplate } from "./personalInfoCaprescaTemplate";

export const caprescaTemplate: Template = {
  name: "Checkout Form",
  template: [
    { name: "isResponsable", type: { name: "string" } },
    { name: "useDeliveryAddress", type: { name: "boolean" } },
    personalInfoCaprescaTemplate,
  ],
};
