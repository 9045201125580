import React from "react";
import { LoginView } from "src/app/views";
import { SingIn } from "src/app/models";
import { useLoginFormContext } from "../hooks";

interface LoginFormProps {
  handlerSocialSingIn: ({ provider }: SingIn) => void;
}

export const LoginForm = ({ handlerSocialSingIn }: LoginFormProps) => {
  const { form, values, errors, handleSubmit } = useLoginFormContext();

  return (
    <LoginView
      form={form}
      disablePassword={values.email === ""}
      disableNext={Object.keys(errors as any).length !== 0}
      handleSingIn={handleSubmit}
      handlerSocialSingIn={handlerSocialSingIn}
    />
  );
};
