import React, { useMemo } from "react";
import { Grid, Box, useMediaQuery, Theme, Button, Card } from "@material-ui/core";
import { FormRecovery } from "src/screens/Public/Recovery/types";
import { FormInputData, CommonFormInput } from "../CommonFormInput";
import { useScrollToTopOnMount, ComponentFormProps } from "../../../../commons";

export interface EmailInfoFormViewProps extends ComponentFormProps<FormRecovery> {}

export const EmailInfoFormView = ({ form, onSubmit }: EmailInfoFormViewProps) => {
  useScrollToTopOnMount();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isDown768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));

  const formInput: FormInputData[] = useMemo(() => {
    const data: FormInputData[] = [
      {
        variant: "standard",
        name: "email",
        label: "Email",
        xs: 12,
        required: true,
        id: "emailRecovery",
        type: "email",
        autoComplete: "email",
      },
    ];
    return data;
  }, []);

  return (
    <Grid item lg={4} md={5} sm={isDown768 ? 6 : 12} xs={12}>
      <Box component={Card} borderRadius={8} boxShadow={3} display="content" position="relative">
        <Box padding={isDownSm ? 2 : 3}>
          <Grid container direction="row" spacing={3}>
            {formInput.map((input) => (
              <CommonFormInput<FormRecovery>
                key={input.name}
                form={form}
                name={input.name}
                label={input.label}
                variant={input.variant}
                type={input.type}
                lg={input.lg}
                md={input.md}
                sm={input.sm}
                xs={input.xs}
                id={input.id}
                autoComplete={input.autoComplete}
                error={input.error}
                helperText={input.helperText}
                inputBaseProps={input.inputBaseProps}
                validator={input.validator}
              />
            ))}
            <Grid item xs={12} />

            <Grid item container xs={12} justify="flex-end">
              <Button size={isDownSm ? "small" : "large"} variant="contained" onClick={onSubmit}>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};
