import React from "react";
import { SnackbarMessage, SnackbarKey } from "notistack";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { ecommerceConfig } from "src/config/ecommerce";
import FaceOutlined from "@material-ui/icons/FaceOutlined";

interface MessageProps {
  key: SnackbarKey;
  message: SnackbarMessage;
  type: string;
}

const useStyles = makeStyles({
  login: {
    backgroundColor: ecommerceConfig.ecommercePalette.materialUi.primary.successAlert,
    width: 288,
    height: 48,
    padding: "6px 16px 6px 20px",
    borderRadius: 4,
  },
  icon: {
    width: 27,
    height: 27,
    marginRight: 1,
  },
  text: {
    fontSize: 12,
  },
});

export const Message = ({ ...props }: MessageProps) => {
  const classes = useStyles();
  const { message } = props;
  return (
    <Box className={classes.login} display="flex" alignItems="center">
      <Box component={FaceOutlined} width={27} height={27} marginRight={1} />
      <Typography className={classes.text}>{message}</Typography>
    </Box>
  );
};
