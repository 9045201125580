import React, { useMemo } from "react";
import { CurrencySelector } from "src/commons/components/CurrencySelector";
import { Toolbar, Grid, IconButton, GridJustification, useMediaQuery, Theme } from "@material-ui/core";
import { Menu, Search, Close } from "@material-ui/icons";
import { MainInput } from "src/commons";
import { AppBarMobileProps } from "./types";
import { appHeaderStyles as useStyles } from "../../../styles/appHeaderStyles";
import { OneStar } from "../../../../../assets/images/svgs/OneStar";

export const AppBarMobile = ({
  onSearchClick,
  inputVisible,
  toggleInput,
  onSearchChange,
  searchValue,
  onOpenMenu,
  onLogoClick,
}: AppBarMobileProps) => {
  const classes = useStyles();
  const isDown768 = useMediaQuery<Theme>((theme) => theme.breakpoints.up(767));

  const containerGrid = useMemo(
    () => ({
      justify: (inputVisible ? "flex-start" : "space-between") as GridJustification,
    }),
    [inputVisible],
  );

  const onEnterPress = () => {
    if (searchValue) {
      onSearchClick();
      toggleInput();
    }
  };

  return (
    <Toolbar>
      {inputVisible ? (
        <Grid container direction="row" {...containerGrid}>
          <Grid item xs={9} container>
            <Grid item xs={1} container>
              <IconButton edge="start" color="primary" aria-label="open drawer" onClick={toggleInput}>
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={11} container alignItems="center">
              <MainInput
                fullWidth
                placeholder=""
                iconPossition="end"
                customClass={classes.searchBarMobile}
                handleChange={(e) => onSearchChange(e)}
                value={searchValue}
                onBlur={() => !searchValue && toggleInput()}
                autoFocus
              />
            </Grid>
          </Grid>
          <Grid item xs={3} container justify="flex-end">
            <Grid item xs={4} sm={isDown768 ? 2 : 4} container justify="flex-end">
              <IconButton onClick={() => onEnterPress()}>
                <Search fontSize="inherit" color="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row" {...containerGrid}>
          <Grid item xs={6} container>
            <Grid item xs={10} sm={isDown768 ? 11 : 10} md={11} container onClick={onLogoClick} alignItems="center" justify="flex-start">
              <OneStar width={60} height={60} noBorder noBackground onClick={onLogoClick} />
            </Grid>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Grid item container className={classes.currencySelector}>
              <CurrencySelector />
            </Grid>
            <Grid item container justify="flex-end" sm={isDown768 ? 1 : 2} xs={2} md={1}>
              <IconButton onClick={toggleInput}>
                <Search fontSize="large" color="inherit" />
              </IconButton>
            </Grid>
            <Grid item xs={2} sm={isDown768 ? 1 : 2} md={1} container>
              <IconButton edge="start" color="primary" aria-label="open drawer" onClick={onOpenMenu}>
                <Menu fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};
