export enum ORDER_SHIPMENT_STATE {
  NOT_SET = 1,
  SCHEDULES = 2,
  PREPARATION = 3,
  PREPARED = 4,
  OUT_TO_DELIVERY = 5,
  DELIVERED = 6,
  RETURNED = 7,
  IN_DISTRIBUTION = 8,
  IN_OFFICE = 9,
  NOT_DELIVERED = 10,
  CANCELLED = 11,
  CLOSED = 12,
  ON_THE_ROAD = 13,
  IN_CUSTODY = 14,
  ENTRY_INTO_THE_OPERATING_CIRCUIT = 15,
  FAILED_WITHDRAW = 16,
  ANNULLED = 17,
  DAMAGED = 18,
  REDIRECTED = 19,
  RESCUED = 20,
  WITHDRAWAL_MADE = 21,
  VISIT = 22,
  SENT_IN_THE_PROCESS_OF_COORDINATION = 23,
  SURPLUS_INFORMATION = 24,
  PENDING_RESCUE = 25,
  SURRENDERED = 26,
  PENDING_RETURN = 27,
  PENDING_ENTRY = 28,
  AT_DESTINATION_BRANCH = 29,
  SURRENDER = 30,
  PENDING = 31,
  PENDING_BRANCH = 32,
  RE_ENTERED_INTO_FULLPACK = 33,
  PENDING_EXPRESS = 34,
  FLEX_SLOPE = 35,
  PENDING_FULLPACK = 36,
  IMPOSSIBLE_TO_PREPARE = 37,
  OUT_OF_STOCK = 38,
  STOCK_ON_THE_WAY = 39,
  UNKNOWN_SKU = 40,
  READY_TO_COLLECT = 41,
  COLLECTION_PENDING = 42,
  PLANNED_COLLECTION = 43,
  COLLECTION_IN_PROGRESS = 44,
  PACKET_PROBLEMS = 45,
  BRAKING_I_REFER_SLOPE = 46,
  BRAKING_INCOMPLETE_SHIPPING = 47,
  BRAKED_DAMAGED_GOODS = 48,
  BRAKING_IMPOSSIBLE_TO_DISPATCH = 49,
  BRAKING_SUSPENDED_SHIPPING = 50,
  IN_TRANSIT = 51,
  IN_BRANCH = 52,
  AGREED_DELIVERY = 53,
  IMPOSSIBLE_TO_CONTACT = 54,
  NOBODY_AT_HOME = 55,
  PROBLEM_IN_DIRECTION = 56,
  REJECTED = 57,
  OTHER_ALERTS = 58,
  SINISTER = 59,
  UNDELIVERABLE = 60,
  IN_RETURN_ANOMALY = 61,
  IN_RETURN_STRICKEN = 62,
  IN_RETURN_WITHOUT_REMIT = 63,
  IN_RETURN_INCOMPLETE_SHIPMENT = 64,
  IN_RETURN_IMPOSSIBLE_TO_DISPATCH = 65,
  IN_RETURN_SUSPENDED_SHIPPING = 66,
  PENDING_COLLECTION = 67,
}

export const ORDER_SHIPMENT_STATE_ANDREANI: { [k: string]: number } = {
  pendiente: ORDER_SHIPMENT_STATE.PENDING,
  entregado: ORDER_SHIPMENT_STATE.DELIVERED,
  "no entregado": ORDER_SHIPMENT_STATE.NOT_DELIVERED,
  "en distribución": ORDER_SHIPMENT_STATE.IN_DISTRIBUTION,
  "en sucursal de destino": ORDER_SHIPMENT_STATE.AT_DESTINATION_BRANCH,
  "en viaje": ORDER_SHIPMENT_STATE.ON_THE_ROAD,
  "en custodia": ORDER_SHIPMENT_STATE.IN_CUSTODY,
  "ingreso al circuito operativo": ORDER_SHIPMENT_STATE.ENTRY_INTO_THE_OPERATING_CIRCUIT,
  "retiro fallido": ORDER_SHIPMENT_STATE.FAILED_WITHDRAW,
  anulado: ORDER_SHIPMENT_STATE.ANNULLED,
  siniestrado: ORDER_SHIPMENT_STATE.DAMAGED,
  redireccionado: ORDER_SHIPMENT_STATE.REDIRECTED,
  rescatado: ORDER_SHIPMENT_STATE.RESCUED,
  devuelto: ORDER_SHIPMENT_STATE.RETURNED,
  "retiro realizado": ORDER_SHIPMENT_STATE.WITHDRAWAL_MADE,
  visita: ORDER_SHIPMENT_STATE.VISIT,
  "envio en proceso de coordinar": ORDER_SHIPMENT_STATE.SENT_IN_THE_PROCESS_OF_COORDINATION,
  "información excedente": ORDER_SHIPMENT_STATE.SURPLUS_INFORMATION,
  "pendiente de ingreso": ORDER_SHIPMENT_STATE.PENDING_ENTRY,
  "pendiente de devolución": ORDER_SHIPMENT_STATE.PENDING_RETURN,
  "rendiente de rescate": ORDER_SHIPMENT_STATE.PENDING_RESCUE,
  rendido: ORDER_SHIPMENT_STATE.SURRENDERED,
  "en rendicion": ORDER_SHIPMENT_STATE.SURRENDER,
};

export const ORDER_SHIPMENT_STATE_ENVIOPACK: { [k: string]: number } = {
  r: ORDER_SHIPMENT_STATE.PENDING,
  pend: ORDER_SHIPMENT_STATE.PENDING,
  pnco: ORDER_SHIPMENT_STATE.PENDING_COLLECTION,
  pnsr: ORDER_SHIPMENT_STATE.PENDING_BRANCH,
  pnrt: ORDER_SHIPMENT_STATE.PENDING_RETURN,
  pnex: ORDER_SHIPMENT_STATE.PENDING_EXPRESS,
  pnfx: ORDER_SHIPMENT_STATE.FLEX_SLOPE,
  pnff: ORDER_SHIPMENT_STATE.PENDING_FULLPACK,
  p: ORDER_SHIPMENT_STATE.PREPARED,
  n: ORDER_SHIPMENT_STATE.IMPOSSIBLE_TO_PREPARE,
  pstf: ORDER_SHIPMENT_STATE.OUT_OF_STOCK,
  stec: ORDER_SHIPMENT_STATE.STOCK_ON_THE_WAY,
  psde: ORDER_SHIPMENT_STATE.UNKNOWN_SKU,
  l: ORDER_SHIPMENT_STATE.READY_TO_COLLECT,
  colp: ORDER_SHIPMENT_STATE.COLLECTION_PENDING,
  colc: ORDER_SHIPMENT_STATE.PLANNED_COLLECTION,
  cole: ORDER_SHIPMENT_STATE.COLLECTION_IN_PROGRESS,
  t: ORDER_SHIPMENT_STATE.IN_DISTRIBUTION,
  prec: ORDER_SHIPMENT_STATE.OUT_TO_DELIVERY,
  pppa: ORDER_SHIPMENT_STATE.PACKET_PROBLEMS,
  frpe: ORDER_SHIPMENT_STATE.BRAKING_I_REFER_SLOPE,
  fein: ORDER_SHIPMENT_STATE.BRAKING_INCOMPLETE_SHIPPING,
  frmd: ORDER_SHIPMENT_STATE.BRAKED_DAMAGED_GOODS,
  frid: ORDER_SHIPMENT_STATE.BRAKING_IMPOSSIBLE_TO_DISPATCH,
  fress: ORDER_SHIPMENT_STATE.BRAKING_SUSPENDED_SHIPPING,
  tran: ORDER_SHIPMENT_STATE.IN_TRANSIT,
  sucs: ORDER_SHIPMENT_STATE.IN_BRANCH,
  pact: ORDER_SHIPMENT_STATE.AGREED_DELIVERY,
  pcon: ORDER_SHIPMENT_STATE.IMPOSSIBLE_TO_CONTACT,
  pdom: ORDER_SHIPMENT_STATE.NOBODY_AT_HOME,
  pdir: ORDER_SHIPMENT_STATE.PROBLEM_IN_DIRECTION,
  rchz: ORDER_SHIPMENT_STATE.REJECTED,
  otal: ORDER_SHIPMENT_STATE.OTHER_ALERTS,
  e: ORDER_SHIPMENT_STATE.DELIVERED,
  c: ORDER_SHIPMENT_STATE.CANCELLED,
  s: ORDER_SHIPMENT_STATE.SINISTER,
  i: ORDER_SHIPMENT_STATE.UNDELIVERABLE,
  dvan: ORDER_SHIPMENT_STATE.IN_RETURN_ANOMALY,
  dvsi: ORDER_SHIPMENT_STATE.IN_RETURN_STRICKEN,
  dvsr: ORDER_SHIPMENT_STATE.IN_RETURN_WITHOUT_REMIT,
  dvei: ORDER_SHIPMENT_STATE.IN_RETURN_INCOMPLETE_SHIPMENT,
  dvid: ORDER_SHIPMENT_STATE.IN_RETURN_IMPOSSIBLE_TO_DISPATCH,
  dves: ORDER_SHIPMENT_STATE.IN_RETURN_SUSPENDED_SHIPPING,
  dvve: ORDER_SHIPMENT_STATE.RETURNED,
  dvff: ORDER_SHIPMENT_STATE.RE_ENTERED_INTO_FULLPACK,
  z: ORDER_SHIPMENT_STATE.REJECTED,
};

export const ALL_SHIPMENT_STATUS_TEXT: { [k: number]: { color: string; text: string } } = {
  [ORDER_SHIPMENT_STATE.NOT_SET]: { color: "text.primary", text: "En proceso" }, // Pendiente
  [ORDER_SHIPMENT_STATE.SCHEDULES]: { color: "text.primary", text: "Horario" }, // ??
  [ORDER_SHIPMENT_STATE.PREPARATION]: { color: "text.primary", text: "En preparación" }, // Preparacion
  [ORDER_SHIPMENT_STATE.PREPARED]: { color: "text.primary", text: "Preparado" },
  [ORDER_SHIPMENT_STATE.OUT_TO_DELIVERY]: { color: "info.main", text: "Listo para el envío" },
  [ORDER_SHIPMENT_STATE.DELIVERED]: { color: "success.main", text: "Entregado" },
  [ORDER_SHIPMENT_STATE.RETURNED]: { color: "info.main", text: "Devuelto" },
  [ORDER_SHIPMENT_STATE.IN_DISTRIBUTION]: { color: "text.primary", text: "En distribución" },
  [ORDER_SHIPMENT_STATE.IN_OFFICE]: { color: "text.primary", text: "En la oficina" },
  [ORDER_SHIPMENT_STATE.NOT_DELIVERED]: { color: "error.main", text: "No entregado" },
  [ORDER_SHIPMENT_STATE.CANCELLED]: { color: "error.main", text: "Cancelada" },
  [ORDER_SHIPMENT_STATE.CLOSED]: { color: "error.main", text: "Cerrada" },
  [ORDER_SHIPMENT_STATE.ON_THE_ROAD]: { color: "info.main", text: "En camino" },
  [ORDER_SHIPMENT_STATE.IN_CUSTODY]: { color: "text.primary", text: "En custodia" },
  [ORDER_SHIPMENT_STATE.ENTRY_INTO_THE_OPERATING_CIRCUIT]: { color: "text.primary", text: "Ingreso al circuito operativo" },
  [ORDER_SHIPMENT_STATE.FAILED_WITHDRAW]: { color: "text.primary", text: "Retiro fallido" },
  [ORDER_SHIPMENT_STATE.ANNULLED]: { color: "text.primary", text: "Anulada" },
  [ORDER_SHIPMENT_STATE.DAMAGED]: { color: "text.primary", text: "Siniestrado" },
  [ORDER_SHIPMENT_STATE.REDIRECTED]: { color: "text.primary", text: "Redireccionado" },
  [ORDER_SHIPMENT_STATE.RESCUED]: { color: "text.primary", text: "Rescatado" },
  [ORDER_SHIPMENT_STATE.WITHDRAWAL_MADE]: { color: "success.main", text: "Retiro realizado" },
  [ORDER_SHIPMENT_STATE.VISIT]: { color: "text.primary", text: "Visita" },
  [ORDER_SHIPMENT_STATE.SENT_IN_THE_PROCESS_OF_COORDINATION]: { color: "text.primary", text: "Envio en proceso de coordinar" },
  [ORDER_SHIPMENT_STATE.SURPLUS_INFORMATION]: { color: "text.primary", text: "Información extra" }, // Información excedente
  [ORDER_SHIPMENT_STATE.PENDING_RESCUE]: { color: "text.primary", text: "Pendiente de rescate" },
  [ORDER_SHIPMENT_STATE.SURRENDERED]: { color: "text.primary", text: "Rendido" }, // Entregado
  [ORDER_SHIPMENT_STATE.PENDING_RETURN]: { color: "text.primary", text: "Pendiente de devolución" },
  [ORDER_SHIPMENT_STATE.PENDING_ENTRY]: { color: "text.primary", text: "Pendiente de ingreso" },
  [ORDER_SHIPMENT_STATE.AT_DESTINATION_BRANCH]: { color: "info.main", text: "En sucursal de destino" },
  [ORDER_SHIPMENT_STATE.SURRENDER]: { color: "info.main", text: "En rendición" }, // Entregado
  [ORDER_SHIPMENT_STATE.PENDING]: { color: "text.primary", text: "Pendiente" },
  [ORDER_SHIPMENT_STATE.PENDING_BRANCH]: { color: "info.main", text: "Sucursal pendiente" },
  [ORDER_SHIPMENT_STATE.RE_ENTERED_INTO_FULLPACK]: { color: "info.main", text: "Reingreso en Full Pack" },
  [ORDER_SHIPMENT_STATE.PENDING_EXPRESS]: { color: "info.main", text: "Express pendiente" },
  [ORDER_SHIPMENT_STATE.FLEX_SLOPE]: { color: "info.main", text: "Flex pendiente" },
  [ORDER_SHIPMENT_STATE.PENDING_FULLPACK]: { color: "info.main", text: "FullPack pendiente" },
  [ORDER_SHIPMENT_STATE.IMPOSSIBLE_TO_PREPARE]: { color: "error.main", text: "Imposible de reparar" },
  [ORDER_SHIPMENT_STATE.OUT_OF_STOCK]: { color: "error.main", text: "Agotada" },
  [ORDER_SHIPMENT_STATE.STOCK_ON_THE_WAY]: { color: "info.main", text: "Acciones en camino" },
  [ORDER_SHIPMENT_STATE.UNKNOWN_SKU]: { color: "error.main", text: "Sku desconocido" },
  [ORDER_SHIPMENT_STATE.READY_TO_COLLECT]: { color: "info.main", text: "Listo para recoger" },
  [ORDER_SHIPMENT_STATE.COLLECTION_PENDING]: { color: "info.main", text: "Colección pendiente" },
  [ORDER_SHIPMENT_STATE.PLANNED_COLLECTION]: { color: "text.primary", text: "Colección planificada" },
  [ORDER_SHIPMENT_STATE.COLLECTION_IN_PROGRESS]: { color: "info.main", text: "Colección en curso" },
  [ORDER_SHIPMENT_STATE.PACKET_PROBLEMS]: { color: "error.main", text: "Problemas con el paquete" },
  [ORDER_SHIPMENT_STATE.BRAKING_I_REFER_SLOPE]: { color: "error.main", text: "Pausado, pendiente" },
  [ORDER_SHIPMENT_STATE.BRAKING_INCOMPLETE_SHIPPING]: { color: "error.main", text: "Pausado, envío inconpleto" },
  [ORDER_SHIPMENT_STATE.BRAKED_DAMAGED_GOODS]: { color: "error.main", text: "Mercancia dañada" },
  [ORDER_SHIPMENT_STATE.BRAKING_IMPOSSIBLE_TO_DISPATCH]: { color: "error.main", text: "Pausado, inposible enviar" },
  [ORDER_SHIPMENT_STATE.BRAKING_SUSPENDED_SHIPPING]: { color: "error.main", text: "Envio suspendido" },
  [ORDER_SHIPMENT_STATE.IN_TRANSIT]: { color: "info.main", text: "En transito" },
  [ORDER_SHIPMENT_STATE.IN_BRANCH]: { color: "success.dark", text: "En sucursal" },
  [ORDER_SHIPMENT_STATE.AGREED_DELIVERY]: { color: "text.primary", text: "Entrega convenida" },
  [ORDER_SHIPMENT_STATE.IMPOSSIBLE_TO_CONTACT]: { color: "text.primary", text: "Imposible de contactar" },
  [ORDER_SHIPMENT_STATE.NOBODY_AT_HOME]: { color: "text.primary", text: "Nadie en casa" },
  [ORDER_SHIPMENT_STATE.PROBLEM_IN_DIRECTION]: { color: "text.primary", text: "Problema en mi dirección" },
  [ORDER_SHIPMENT_STATE.REJECTED]: { color: "error.main", text: "Rechazada" },
  [ORDER_SHIPMENT_STATE.OTHER_ALERTS]: { color: "error.main", text: "Otras alertas" },
  [ORDER_SHIPMENT_STATE.SINISTER]: { color: "error.main", text: "Siniestro" },
  [ORDER_SHIPMENT_STATE.UNDELIVERABLE]: { color: "error.main", text: "No entregable" },
  [ORDER_SHIPMENT_STATE.IN_RETURN_ANOMALY]: { color: "error.main", text: "En retorno por anomalias" },
  [ORDER_SHIPMENT_STATE.IN_RETURN_STRICKEN]: { color: "error.main", text: "En retorno afectado" },
  [ORDER_SHIPMENT_STATE.IN_RETURN_WITHOUT_REMIT]: { color: "error.main", text: "En retorno sin remito" },
  [ORDER_SHIPMENT_STATE.IN_RETURN_INCOMPLETE_SHIPMENT]: { color: "error.main", text: "En retorno, informacion de envio incompleta" },
  [ORDER_SHIPMENT_STATE.IN_RETURN_IMPOSSIBLE_TO_DISPATCH]: { color: "error.main", text: "En retorno, no es posible enviar" },
  [ORDER_SHIPMENT_STATE.IN_RETURN_SUSPENDED_SHIPPING]: { color: "error.main", text: "En retorno, suspendido el envio" },
  [ORDER_SHIPMENT_STATE.PENDING_COLLECTION]: { color: "text.primary", text: "Colección pendiente" },
};
