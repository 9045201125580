import { Configuration, Security } from "src/screens";
import { MenuItem } from "src/commons";
import { AddressesInfoForm } from "src/screens/Private/Profile/component/AddressesInfoForm";
import { EditEmail } from "src/screens/Private/Configuration/Security/components/EditEmail";
import { EditPassword } from "src/screens/Private/Configuration/Security/components/EditPassword";

export const routes: MenuItem[] = [
  {
    key: "privateNotificationMain",
    basePath: "/notification",
    target: {
      component: AddressesInfoForm,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Notificaciones",
    children: [],
    default: true,
  },
  {
    key: "privateSecurityEmailMain",
    basePath: "/security/email",
    target: {
      component: EditEmail,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Seguridad",
    children: [],
    default: true,
  },
  {
    key: "privateSecurityEmailMain",
    basePath: "/security/password",
    target: {
      component: EditPassword,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Seguridad",
    children: [],
    default: true,
  },
  {
    key: "privateSecurityMain",
    basePath: "/security",
    target: {
      component: Security,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Seguridad",
    children: [],
    default: true,
  },
  {
    key: "privateConfigurationMain",
    basePath: "/",
    target: {
      component: Configuration,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Profile",
    children: [],
    default: true,
  },
];
