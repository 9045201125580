import { ethers } from "ethers";
import { DappWalletStrategy } from "./DappWalletStrategy";
import { DappWalletFactory } from "./DappWalletFactory";
import { Wallet, WalletEvent } from "./Strategies/common";

class DappWalletManager {
  private strategy!: DappWalletStrategy;

  private addressWallet!: string;

  async connectToWallet(name: Wallet): Promise<void> {
    this.strategy = DappWalletFactory.get(name);
    this.addressWallet = await this.strategy.connect();
  }

  set AddressWallet(value: string) {
    this.addressWallet = value;
  }

  getName(): Wallet {
    return this.strategy.getName();
  }

  disconnect(): void {
    this.addressWallet = "";
    this.strategy.disconnect();
  }

  getAccount(): string {
    return this.addressWallet;
  }

  isConnected(): Promise<boolean> {
    return this.strategy.isConnected();
  }

  listeners(event: WalletEvent, callback: () => Promise<void>): Promise<void> {
    return this.strategy.listeners(event, callback);
  }

  buyNFT(tokenId: string, price: number): Promise<any> {
    return this.strategy.buyNFT(tokenId, price);
  }

  setPriceNFT(tokenId: string, price: string): Promise<any> {
    return this.strategy.setPriceNFT(tokenId, price);
  }

  getContract(): Promise<ethers.Contract> {
    return this.strategy.getContract();
  }

  async restart() {
    this.strategy.restart();
  }
}

const intance = new DappWalletManager();
export { intance as DappWalletManager };
