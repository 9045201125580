import { MenuItem } from "../../../../commons";
import { Profile } from "../../../../screens";

export const routes: MenuItem[] = [
  {
    key: "privateProfileMain",
    basePath: "/",
    target: {
      component: Profile,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Profile",
    children: [],
    default: true,
  },
];
