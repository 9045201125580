import React, { useMemo } from "react";
import { PromotionalBomb } from "src/app/views/components/PromotionalBomb";
import { Publication } from "src/app/models";
import { PUBLICATION_TYPE } from "src/app/const";
import moment from "moment";
import { ecommerceConfig } from "src/config/ecommerce";
import { SkeletonComponentProps, SOURCE } from "src/app/models/Site";

export interface TTimerPromoProps {
  publications: Publication[];
}

export interface CSSConfig {
  bgImagen: { backgroundImage: string; backgroundColor: string; backgroundRepeat: string; backgroundPosition: string };
  count: { bgColor: string; indicatorColor: string };
  image: string;
}

const initialValue: CSSConfig = {
  bgImagen: {
    backgroundImage: ecommerceConfig.assets.timerBackgroundImage.backgroundImage,
    backgroundColor: ecommerceConfig.assets.timerBackgroundImage.backgroundColor,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
  },
  count: { bgColor: "primary.dark", indicatorColor: "text.primary" },
  image: "",
};

export const SkeletonTimerCount = ({ data }: SkeletonComponentProps) => {
  const { data: publications = [], working } = SOURCE.PUBLICATION(data.source_id);

  const pub = useMemo<Publication>(() => publications.filter((item) => item.type === PUBLICATION_TYPE.TIMER)[0], [publications]);
  const { redirectTo = "", title = "", subTitle = "", timeToEnd = moment(), cssConfig } = pub?.config_values || {};

  const stylesBg = useMemo(
    () => ({
      backgroundImage: `url(${cssConfig?.bgImagen?.backgroundImage || ecommerceConfig.assets.timerBackgroundImage.backgroundImage}),${
        cssConfig?.bgImagen?.backgroundColor || ecommerceConfig.assets.timerBackgroundImage.backgroundColor
      })`,
    }),
    [cssConfig],
  );
  return working ? (
    <div />
  ) : (
    <>
      {!pub ? (
        <></>
      ) : (
        <PromotionalBomb
          title={title}
          subTitle={subTitle}
          timeToEnd={timeToEnd}
          redirecTo={redirectTo}
          cssConfig={{ ...initialValue, ...cssConfig }}
          stylesBg={stylesBg}
        />
      )}
    </>
  );
};
