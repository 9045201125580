import React, { useCallback, useState } from "react";
import { CheckCodeRecoveryFormView } from "src/app/views";
import { useConfirmationDAO } from "src/app/business";
import { VerifyCode } from "src/app/models";
import { useSnackbar } from "notistack";
import { useRecoveryFormContext } from "../../hooks";

export const CheckCodeRecoveryForm = () => {
  const { form, values, errors, handleSubmit } = useRecoveryFormContext();
  const confirmationDAO = useConfirmationDAO();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const sendVerificationCode = useCallback(async () => {
    try {
      await confirmationDAO.sendCode({ email: values.email });
    } catch (error) {
      console.log("Error al enviar el codigo");
    }
  }, [confirmationDAO, values.email]);

  const validateCheckCode = useCallback(
    async (value: string, allValues: any) => {
      const regex = new RegExp("^[0-9]+$");
      if (!regex.test(value)) {
        return "Solo podés ingresar números.";
      }
      if (value.length !== 6) {
        return "El código es de 6 números.";
      }
      if (value === "") {
        return "Completa este dato.";
      }
      let result: VerifyCode = { isValid: false, verification_status_id: 2 };
      try {
        result = await confirmationDAO.verifyCode(allValues.email, allValues.checkCodeInfo.checkCode);
      } catch (error) {
        console.log("Error verificando el codigo");
      }
      if (!result.isValid) {
        return "Código incorrecto.";
      }
    },
    [confirmationDAO],
  );

  const reSendCode = useCallback(async () => {
    setLoading(true);
    await sendVerificationCode();
    enqueueSnackbar("Código reenviado, recordá verificar tu casilla de no deseados.", {
      variant: "info",
    });
    setLoading(false);
  }, [sendVerificationCode, enqueueSnackbar]);

  const send = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <CheckCodeRecoveryFormView
      form={form}
      loading={loading}
      disableNext={Object.keys(errors as any).length !== 0}
      reSendCode={reSendCode}
      validateCheckCode={validateCheckCode}
      onSubmit={send}
    />
  );
};
