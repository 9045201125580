import { EcommerceDAO } from "./EcommerceDAO";
import { PublicationFavoriteInfo, PublicationFavorite } from "../../../app/models";

export class PublicationFavoriteDAO extends EcommerceDAO<PublicationFavoriteInfo> {
  constructor() {
    super("publication_favorite", "publicationFavoriteId");
  }

  async getAll(): Promise<PublicationFavoriteInfo[]> {
    const response = await this.fetchData(`/publication/favorites`);
    return response.json();
  }

  async getCountFavorite(publication_id: string): Promise<{ count: number; publication_id: string }> {
    const response = await this.fetchData(`/publication/favorites/${publication_id}`);
    return response.json();
  }

  async save(favorite: Omit<PublicationFavorite, "buyer_id">): Promise<PublicationFavorite> {
    const response = await this.fetchData(`/publication/favorites`, {
      method: "post",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(favorite),
    });
    return response.json();
  }

  async remove(favorite: PublicationFavorite): Promise<void> {
    const response = await this.fetchData(`/publication/favorites`, {
      method: "delete",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(favorite),
    });
    return response.json();
  }
}
