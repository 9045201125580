import React from "react";
import { Grid, Box, Typography, Divider, Button, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { OneStar } from "src/assets/images/svgs/OneStar";
import { AFALogo, ConmebolLogo, FacebookIcon, FIFALogo, InstagramIcon, TwitterIcon } from "src/assets/images/svgs";
import { ecommercePalette } from "src/config/ecommerce/nftmarket/ecommercePalette";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MenuDrawerProps } from "src/app/views/components/AppHeader/components/types";
import { useHistory } from "react-router";
import { MenuDrawerStyles } from "src/app/views/styles";
import { useRolesContext } from "src/context";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CollectionsOutlinedIcon from "@material-ui/icons/CollectionsOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { WalletConnection } from "src/commons/components/WalletConnection";

const footerIcons = [
  {
    id: 0,
    icon: <ConmebolLogo />,
  },
  {
    id: 1,
    icon: <AFALogo />,
  },
  {
    id: 2,
    icon: <FIFALogo />,
  },
  {
    id: 3,
    icon: <FacebookIcon color={ecommercePalette.materialUi.primary.main} />,
  },
  {
    id: 4,
    icon: <InstagramIcon />,
  },
  {
    id: 5,
    icon: <TwitterIcon />,
  },
];

export const MenuDrawer = ({ onCloseDrawer, contents, onestarLinks, onLogoClick, onSignOut }: MenuDrawerProps) => {
  const classes = MenuDrawerStyles();
  const history = useHistory();
  const { selectedRole, userInfo } = useRolesContext();

  const onButtonClick = (path: string) => {
    history.push(path);
    onCloseDrawer();
  };

  const UserActionsMenu = [
    {
      label: "Editar mi perfil",
      onClick: () => onButtonClick("/profile"),
      icon: <CreateOutlinedIcon />,
    },
    {
      label: "Mi colección",
      onClick: () => onButtonClick("/profile"),
      icon: <CollectionsOutlinedIcon />,
    },
    {
      label: "Cerrar sesión",
      onClick: onSignOut,
      icon: <CloseOutlinedIcon />,
    },
  ];

  return (
    <Grid container style={{ backgroundColor: ecommercePalette.themeColor }}>
      <Grid container className={classes.header}>
        <OneStar width={60} height={60} noBorder noBackground onClick={onLogoClick} />
        <CloseIcon fontSize="large" color="primary" onClick={onCloseDrawer} />
      </Grid>

      <Grid container direction="column">
        <Grid item>
          {contents.map((item) => (
            <Typography
              style={{
                color: item.isSelected ? ecommercePalette.materialUi.primary.light : ecommercePalette.materialUi.primary.contrastText,
              }}
              key={item.id}
              onClick={item.onClick}
              className={classes.title}
            >
              {item.label}
            </Typography>
          ))}
        </Grid>
        <Divider className={classes.divider} />
        <Grid item>
          {onestarLinks.map((item) => (
            <Typography key={item.id} style={{ color: ecommercePalette.materialUi.neutral[2] }} onClick={item.onClick} className={classes.title}>
              {item.label}
            </Typography>
          ))}
        </Grid>
        {selectedRole.rol_id === "" ? (
          <Grid className={classes.loginContainer}>
            <Box
              mt={3}
              component={Button}
              borderRadius={30}
              bgcolor="transparent"
              color={ecommercePalette.materialUi.primary.contrastText}
              padding="12px 48px"
              marginTop={0}
              onClick={() => onButtonClick("/register")}
              className={classes.registerButton}
            >
              REGISTRATE
            </Box>
            <Box
              mt={3}
              component={Button}
              borderRadius={30}
              bgcolor={ecommercePalette.materialUi.primary.light}
              color={ecommercePalette.themeColor}
              padding="12px 48px"
              marginTop={0}
              onClick={() => onButtonClick("/login")}
              className={classes.loginButton}
            >
              INGRESÁ
            </Box>
          </Grid>
        ) : (
          <Grid item className={classes.userActions}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionSummary}
              >
                <Grid container alignContent="center">
                  <AccountCircleOutlinedIcon />
                  <Typography variant="subtitle2" style={{ paddingLeft: 10 }}>
                    {userInfo.name}
                  </Typography>
                </Grid>
              </AccordionSummary>

              <AccordionDetails className={classes.accordionDetails}>
                <WalletConnection />
                {UserActionsMenu.map((item) => (
                  <Grid className={classes.userActionsMenu} onClick={item.onClick}>
                    <Typography className={classes.userActionIcons}> {item.icon} </Typography>
                    <Typography variant="subtitle2" className={classes.userActionIcons}>
                      {" "}
                      {item.label}{" "}
                    </Typography>
                  </Grid>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        <Grid container justify="center" alignItems="center">
          {footerIcons.map((item) => (
            <Grid item className={classes.footerIcons}>
              {item.icon}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
