import React from "react";
import {
  Grid,
  withStyles,
  Theme,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { SmartContract } from "./SmartContract";

type sellHistory = {
  event: string;
  price: string;
  from: string;
  to: string;
  date: string;
  image: string;
  id: number;
};

interface HistoryProps {
  salesHistory: sellHistory[];
  tokenId: string;
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontSize: 20,
      fontStyle: "italic",
      fontWeight: 800,
      color: "#95F035",
      borderColor: "#95F035",
    },
    body: {
      fontSize: 18,
      color: theme.palette.common.white,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
    [theme.breakpoints.down(768)]: {
      width: "max-content",
    },
  },
  iconOwner: {
    borderRadius: "40px",
    border: "0.5px solid #95F035",
    marginRight: 8,
  },
  tableContainer: {
    overflow: "auto",
    [theme.breakpoints.down(426)]: {
      width: "45%",
    },
    [theme.breakpoints.down(321)]: {
      width: "37%",
    },
  },
}));

export const SalesHistory = ({ salesHistory, tokenId }: HistoryProps) => {
  const classes = useStyles();
  return (
    <Grid direction="row" style={{ width: "100%" }}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>EVENTO</StyledTableCell>
              <StyledTableCell align="left">PRECIO</StyledTableCell>
              <StyledTableCell align="left">DE</StyledTableCell>
              <StyledTableCell align="left">HACIA</StyledTableCell>
              <StyledTableCell align="left">FECHA</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salesHistory.map((item) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell component="th" scope="row">
                  {item.event}
                </StyledTableCell>
                <StyledTableCell align="left">{item.price}</StyledTableCell>
                <StyledTableCell align="left">
                  <Grid className={classes.iconOwner} component="img" width="16px" height="16px" src={item.image} />
                  {item.from}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Grid className={classes.iconOwner} component="img" width="16px" height="16px" src={item.image} />
                  {item.to}
                </StyledTableCell>
                <StyledTableCell align="left">{item.date}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SmartContract id={tokenId} />
    </Grid>
  );
};
