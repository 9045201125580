import { useEffect, useMemo } from "react";
import { useTemplateContext, useFormContext } from "src/lib/templates";
import { AnyObject } from "src/commons";
import { EMITTERS } from "src/app/const/Emitters";
import { responsableTemplate } from "../templates/responsableTemplate";
import { summaryAddressTemplate } from "../templates/summaryAddress";
import {
  deliveryInitialValue,
  PaymentInitialValue,
  paymentInitialValue,
  InitialValue,
  initialValue,
  ApplicantInitialValue,
  applicantInitialValue,
} from "../initialValues";

export const useIsResponsableInscriptoLogic = () => {
  // TODO: como es dinamico tiparlo trae problemas al querer setear el estado de la key si esta no esta en el initial value
  const { form, values } = useFormContext<AnyObject>();
  const { setTemplate } = useTemplateContext();

  const valuesForm: InitialValue & { paymentMethod: PaymentInitialValue } & { applicantInfo: ApplicantInitialValue<Partial<File> | undefined> } =
    useMemo(
      () => ({
        ...initialValue,
        paymentMethod: paymentInitialValue,
        applicantInfo: applicantInitialValue,
        ...values,
      }),
      [values],
    );

  useEffect(() => {
    if (valuesForm.isResponsable !== "true") {
      // aqui quito el template adicional del responsable inscripto si existe
      setTemplate((template) => {
        if (!template.template.find((property) => property.name === responsableTemplate.name)) {
          return template;
        }
        form.change(responsableTemplate.name, undefined);
        return {
          ...template,
          template: template.template.filter((property) => property.name !== responsableTemplate.name),
        };
      });
    } else {
      // aqui agrego el template adicional del responsable si no existe
      setTemplate((template) => {
        if (template.template.find((property) => property.name === responsableTemplate.name)) {
          return template;
        }
        form.change(responsableTemplate.name, { business: "" });
        return {
          ...template,
          template: [...template.template, responsableTemplate],
        };
      });
    }
    if (valuesForm.useDeliveryAddress && valuesForm.paymentMethod.emitter.card_emiter_id.toString() !== EMITTERS.CATAMARCA_CREDITS.toString()) {
      // aqui quito el template adicional del summary card si existe
      setTemplate((template) => {
        if (!template.template.find((property) => property.name === summaryAddressTemplate.name)) {
          return template;
        }
        form.change(summaryAddressTemplate.name, undefined);
        return {
          ...template,
          template: template.template.filter((property) => property.name !== summaryAddressTemplate.name),
        };
      });
    } else if (
      !valuesForm.useDeliveryAddress &&
      valuesForm.paymentMethod.emitter.card_emiter_id.toString() !== EMITTERS.CATAMARCA_CREDITS.toString()
    ) {
      // aqui agrego el template adicional del summary card si no existe
      setTemplate((template) => {
        if (template.template.find((property) => property.name === summaryAddressTemplate.name)) {
          return template;
        }
        form.change(summaryAddressTemplate.name, deliveryInitialValue);
        return {
          ...template,
          template: [...template.template, summaryAddressTemplate],
        };
      });
    }
  }, [setTemplate, valuesForm, form]);
};
