import { EcommerceDAO } from "./EcommerceDAO";
import { Publication } from "../../../app/models";

export class PublicationByStockDAO extends EcommerceDAO<Publication> {
  constructor() {
    super("publication_by_stock", "publicationId");
  }

  async getByStock(publication: string): Promise<Publication[]> {
    // TODO: ver como hacer esto mejor
    const response = await this.fetchData(`/publication/stock/${publication}`);
    // return [];
    return response.json();
  }
}
