import { EcommerceDAO } from "./EcommerceDAO";
import { Order } from "../../../app/models";

export class OrderDAO extends EcommerceDAO<Order> {
  constructor() {
    super("order", "orderId");
  }

  async getOrderStateByBuyer(document: string) {
    const response = await this.fetchData(`/order/state/${document}`);
    return response.json();
  }
}
