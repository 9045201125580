import { EcommerceDAO } from "./EcommerceDAO";
import { TokenizedCard } from "../../../app/models";

export class TokenizedCardsDAO extends EcommerceDAO<TokenizedCard> {
  constructor() {
    super("tokenized_cards", "tokenizedCardId");
  }

  async getAllTokenizedCards(): Promise<any> {
    const response = await this.fetchData(`/buyer/tokenizedcards`);
    return response.json();
  }

  async getTokenizedCardById(token_id: string): Promise<TokenizedCard | undefined> {
    const response: TokenizedCard[] = await this.getAllTokenizedCards();
    return response.find((item) => item.token === token_id);
  }

  async remove(token_id: string) {
    const response = await this.fetchData(`/buyer/tokenizedcards/${token_id}`, {
      method: "delete",
    });
    return response.json();
  }
}
