import { makeStyles } from "@material-ui/core";

export const productDetailsInfoStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "90px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  rootCenter: {
    width: "100%",
    marginTop: "90px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    },
  },
  isImgLeft: {
    display: "none",
  },
  ImgJustifyCenter: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  ImgJustifyEnd: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  ImgJustifyStart: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
  },
  infoRootCenter: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  descriptionSm: {
    width: "100%",
    display: "flex",
    marginBottom: "10px",
  },
  descriptionXs: {
    width: "100%",
    display: "flex",
    marginBottom: "10px",
    marginRight: "25px",
  },
  InfoRootRight: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "left",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  InfoRootXs: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "left",
  },
  InfoRootLeft: {
    width: "100%",
    display: "flex",
    marginBottom: "25px",
    alignItems: "center",
    justifyContent: "flex-end",
    textAlign: "left",
  },
  innerRoot90: {
    width: "90%",
    marginLeft: "0px",
  },
  innerRoot100: {
    width: "100%",
    marginLeft: "20px",
  },
  innerRoot100Sm: {
    width: "100%",
  },
  iconRootCenter: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },
  iconRoot: {
    display: "flex",
    width: "100%",
  },
  description: {
    display: "flex",
    width: "85%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  descriptionCenter: {
    display: "flex",
    width: "85%",
    [theme.breakpoints.up("sm")]: {
      width: "45%",
      marginBottom: "20px",
    },
  },
  size: {
    display: "flex",
    width: "100%",
  },
  descriptionRootCenter: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
      textAlign: "center",
    },
  },
  descriptionRootLeft: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  descriptionRootRight: {
    display: "flex",
    width: "90%",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  titleRootCenter: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },
  hidden: {
    display: "none",
  },
  imgCenter: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  imgRight: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
  },
  imgLeft: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  titleRootLeft: {
    display: "flex",
    width: "90%",
  },
  titleRootRigth: {
    display: "flex",
    width: "100%",
  },
  titleRoot: {
    display: "flex",
    width: "100%",
  },
  icon: {
    display: "flex",
    width: "40px",
    marginBottom: "10px",
  },
}));
