import { makeStyles } from "@material-ui/core/styles";
import { ecommerceConfig } from "src/config/ecommerce";
import { ecommercePalette } from "src/config/ecommerce/nftmarket/ecommercePalette";
import { ecommerceTypography } from "src/config/ecommerce/nftmarket/ecommerceTypography";
import pallet from "../../../theme/palette";

export const appHeaderStyles = makeStyles(() => ({
  container: {
    backgroundColor: pallet.background.defaultDark,
    justifyContent: "center",
    borderBottom: "1px solid gray",
  },
  logo: {
    cursor: "pointer",
  },
  searchBar: {
    ...ecommerceTypography.body1,
    border: "0.5px solid #ABABAB",
    borderRadius: "4px",
    height: 32,
    textAlign: "left",
  },
  searchBarMobile: {
    ...ecommerceTypography.body2,
    border: "0.5px solid #ABABAB",
    borderRadius: "4px",
    height: 32,
    width: 275,
    textAlign: "left",
    marginLeft: 15,
  },
  grow: {
    flexGrow: 1,
  },
  linkLogin: {
    textDecoration: "none",
  },
  divider: {
    backgroundColor: ecommercePalette.materialUi.neutral[4],
  },
  loginButton: {
    ...ecommerceTypography.h5,
    color: ecommerceConfig.ecommercePalette.materialUi.primary.dark,
    background: ecommerceConfig.ecommercePalette.materialUi.primary.light,
    width: "147px",
    height: "38px",
    borderRadius: "30px",
    "&:hover": {
      color: "white",
    },
  },
  registerButton: {
    ...ecommerceTypography.h5,
    width: "147px",
    height: "38px",
    marginRight: "8px",
  },
  currencySelector: {
    width: "auto",
    marginRight: 19,
  },
}));
