import React, { useMemo } from "react";
import { Grid, Box, useMediaQuery, Theme, Typography, Button, Card } from "@material-ui/core";
import { Link } from "react-router-dom";
import { SingIn } from "src/app/models";
import { APP_ECOMMERCE } from "src/config";
import { FormInputData, CommonFormInput } from "../CommonFormInput";
import { FormLogin } from "../../../../screens/Public/Login/types";
import { SocialButton } from "./SocialButton";

export interface LoginViewProps {
  form: FormLogin;
  disableNext: boolean;
  disablePassword: boolean;
  handleSingIn: () => void;
  handlerSocialSingIn: ({ provider }: SingIn) => void;
}

export const LoginView = ({ disableNext, disablePassword, form, handlerSocialSingIn, handleSingIn }: LoginViewProps) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isDown768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSingIn();
    }
  };

  const allFormInput: FormInputData[] = useMemo(
    () => [
      {
        variant: "standard",
        name: `email`,
        label: "Email",
        xs: 12,
        required: true,
        id: "loginEmail",
        type: "email",
        autoComplete: "email",
      },
      {
        variant: "standard",
        name: `password`,
        label: "Clave",
        disabled: disablePassword,
        xs: 12,
        required: true,
        id: "loginPassword",
        type: "password",
        autoComplete: "password",
      },
    ],
    [disablePassword],
  );

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} container spacing={1} justify="center">
        <Grid item xs={12}>
          <Typography variant="h4" align="center" color="textPrimary">
            ¡Hola! Ingresá tu e‑mail o usuario
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <Typography variant="body1" align="center" color="textSecondary">
            {`Si ya tienes un usuario generado, completa con tu correo o tu nombre de usuario. En caso de no tener, podés `}
            <Link to="/register" style={{ textDecoration: "none" }}>
              <Box display="inline" color="primary.main">
                crear un nuevo usuario
              </Box>
            </Link>
            .
          </Typography>
        </Grid>
      </Grid>

      <Grid item lg={4} md={4} sm={isDown768 ? 6 : 12} xs={12}>
        <Card>
          <Box onKeyPress={handleKeyPress} padding={isDownSm ? 2 : 3}>
            <Grid container direction="row" spacing={3}>
              {allFormInput.map((input) => (
                <CommonFormInput<FormLogin>
                  key={input.id}
                  lg={input.lg}
                  md={input.md}
                  xs={input.xs}
                  name={input.name}
                  form={form}
                  variant={input.variant}
                  label={input.label}
                  disabled={input.disabled}
                  error={input.error}
                  helperText={input.helperText}
                  id={input.id}
                  autoComplete={input.autoComplete}
                  type={input.type}
                />
              ))}
              <Grid item xs={12} container justify="center" alignItems="center">
                <Link
                  to="/login/recovery"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Typography variant="body2">
                    <Box color="primary.main">¿Necesitas ayuda?</Box>
                  </Typography>
                </Link>

                <Box ml={2} display="flex">
                  <Button disabled={disableNext} size="large" variant="contained" onClick={handleSingIn}>
                    Ingresar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>

      {APP_ECOMMERCE !== "nftmarket" && (
        <Grid item lg={4} md={5} sm={isDown768 ? 6 : 12} xs={12}>
          <SocialButton handlerSocialSingIn={handlerSocialSingIn} />
        </Grid>
      )}
    </Grid>
  );
};
