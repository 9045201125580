export interface Skeleton {
  skeleton_id: string;
  name: string;
  site_id: string;
  url: string;
  skeleton_components: Array<Skeleton_Component>;
}

export interface Skeleton_Component {
  skeleton_component_id: number;
  skeleton_id: number;
  name: string;
  url: string;
}

export const initialContentState = {
  skeleton_id: "",
  name: "",
  site_id: "",
  url: "",
  skeleton_components: new Array<Skeleton_Component>(),
};
