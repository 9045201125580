import React, { useCallback, useState } from "react";
import { Publication } from "src/app/models";
import { FormContextProvider } from "src/lib/templates";
import { Grid } from "@material-ui/core";
import { PROVINCES } from "src/app/const";
import { template } from "./template";
import { initialValue, InitialValue } from "./initialValues";
import { ShipmentAddressForm } from "./components/ShipmentAddressForm";
import { ShipmentData } from "./types";

interface CalculateShipmentProps {
  publication?: Publication;
  shipmentData: ShipmentData;
  quantity: number;
  setShipmentData: (value: ShipmentData) => void;
}

export const CalculateShipment = ({ publication, quantity, shipmentData, setShipmentData }: CalculateShipmentProps) => {
  const [loading, setLoading] = useState(false);

  const onSetDataFetch = useCallback(
    (values: InitialValue) => {
      const date = values.shipmentAddress.province.id === PROVINCES.CABA.toString() ? "24hs" : "72hs";
      setShipmentData({ deliveryDate: date, ...values });
      setLoading(false);
    },
    [setShipmentData],
  );

  const onSubmitForm = useCallback(
    (values: InitialValue) => {
      setLoading(true);
      setTimeout(onSetDataFetch, 3000, values);
    },
    [onSetDataFetch],
  );

  const onChangeAddress = useCallback(() => {
    setShipmentData({ deliveryDate: "", ...initialValue });
  }, [setShipmentData]);

  const { deliveryDate, ...rest } = shipmentData;

  return (
    <FormContextProvider template={template} initialValues={rest.shipmentAddress.zipCode === "" ? initialValue : rest} onSubmit={onSubmitForm}>
      <Grid container item xs={12}>
        <ShipmentAddressForm
          publication={publication}
          quantity={quantity}
          onChangeAddress={onChangeAddress}
          loadingShipment={loading}
          shipmentData={shipmentData}
        />
      </Grid>
    </FormContextProvider>
  );
};
