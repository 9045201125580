import { Grid, GridSize, Box, InputBaseProps } from "@material-ui/core";
import React from "react";
import { FieldValidator } from "final-form";
import { FormInput } from "src/lib/templates/components";

interface CommonFormInputProps<T> {
  form: T;
  name: string;
  variant?: "standard" | "filled" | "outlined";
  size?: "small" | "medium";
  disabled?: boolean;
  label: string;
  lg?: GridSize;
  md?: GridSize;
  sm?: GridSize;
  xs?: GridSize;
  error?: boolean;
  helperText?: React.ReactNode;
  // key?: string | number | null;
  id?: string;
  type?: string;
  autoComplete?: string;
  className?: string;
  inputBaseProps?: Partial<InputBaseProps>;
  validator?: FieldValidator<any>;
  inputProps?: any;
}

export const CommonFormInput = <T,>({
  name,
  variant,
  form,
  label,
  size = "small",
  disabled,
  lg,
  md,
  sm,
  xs,
  error,
  helperText,
  id,
  type,
  autoComplete,
  className,
  inputBaseProps = {},
  inputProps,
  validator,
}: CommonFormInputProps<T>) => (
  <Grid key={`${label}-${id}`} item lg={lg} md={md} sm={sm} xs={xs} className={className}>
    <FormInput
      variant={variant}
      inputLabelProps={{
        shrink: true,
      }}
      disabled={disabled}
      label={
        <Box color="text.disabled" minWidth="104px">
          {label}
        </Box>
      }
      name={name}
      form={form}
      size={size}
      error={error}
      helperText={helperText}
      id={id || name}
      type={type}
      autoComplete={autoComplete}
      inputProps={inputProps}
      inputBaseProps={inputBaseProps}
      validator={validator}
    />
  </Grid>
);
