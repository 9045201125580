import React, { useEffect, useState } from "react";
import { Grid, Container, Typography, Box, useMediaQuery, Theme, Divider } from "@material-ui/core";
import { ApprovedPayment as Icon } from "src/assets/images/svgs";
import ReportOutlined from "@material-ui/icons/ReportOutlined";
import { useCartContext } from "src/context";
import { pendingPayment as useStyles } from "../../styles";
import { Analytics } from "../../../../platform/analytics";
import { persistentCart } from "../../../../context/CartContext/utils";

const persistCart = persistentCart();
export const PendingPaymentView = () => {
  const { cartState, dispatch } = useCartContext();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [isTracked, setIsTracked] = useState(false);

  useEffect(() => {
    if (!isTracked) {
      setIsTracked(true);
      // TODO: terminar esto cuando el backend este listo para el tema del analitics
      Analytics.trackPurchaseApproved({ cart: cartState });
      persistCart.remove();
      dispatch.updateState({ owner: "", items: [], state_id: 0 });
    }
  }, [cartState, dispatch, isTracked]);

  return (
    <Container maxWidth="lg">
      <Box borderBottom="6px solid #8B04BC" mt={6}>
        <Container maxWidth="md">
          <Grid container justify="center" alignContent="center">
            <Box color="warning.dark">
              <ReportOutlined fontSize="large" className={classes.icon} />
            </Box>

            <Grid item xs={12}>
              <Typography align="center" variant={isDownSm ? "h5" : "h3"}>
                <Box color="warning.dark">Pago Pendiente por Aprobación</Box>
              </Typography>
              <Typography align="center" variant="body1" color="textSecondary">
                Hemos recibido correctamente tus datos sobre la compra realizada. Te avisaremos cuando el pago sea aprobado. Muchas gracias por
                confiar en nosotros!
              </Typography>
            </Grid>
            <Grid item container justify="center" xs={12}>
              <Box>
                <Icon width={isDownSm ? 303 : 606} height={isDownSm ? 213 : 426} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={3}>
                <Divider variant="inset" />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Container>
  );
};
