import React, { useCallback, useMemo, useState } from "react";
import { Grid, Box, useMediaQuery, Theme, Typography, Drawer, List, ListItem, ListItemText, Button, Divider, makeStyles } from "@material-ui/core";
import { Category, CategoryPromo, ConfigValues, Feature, FeatureGroup, Publication } from "src/app/models";
import { normalizePublications } from "src/utils";
import { Product } from "src/app/models/business/Product";
import { flatten, uniqBy } from "lodash";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";
import { ecommerceConfig } from "src/config/ecommerce";
import { useFavoriteManage } from "src/commons";
import { ProductCardView } from "../../ProductCard";
import { FilterTabs } from "./FilterTabs";

interface ListProductsProps {
  publications: Publication[];
  title: string;
  onClickProduct: (value: Product) => void;
}

const styles = makeStyles((theme) => ({
  cardContainer: {
    minHeight: "400px",
  },
  cardItems: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(265px, 1fr))",
  },
  drawer: {
    width: "18em",
    flexShrink: 0,
  },
  drawerPaper: {
    width: "18em",
  },
  filterButton: {
    textTransform: "none",
    padding: 0,
  },
  filtrarGrid: {
    textAlign: "start",
    marginTop: 8,
  },
  divider: {
    width: "100%",
    marginBottom: 16,
  },
  filterCollapsedContainer: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  resultResume: {
    padding: 16,
    "@media (max-width:48em)": {
      background: theme.palette.primary.dark,
      "& .MuiTypography-colorTextSecondary": {
        color: "white !important",
      },
    },
  },
}));

const all: CategoryPromo = { name: "Todos", id: "0", value: 0, config_values: {} as ConfigValues, publications: [] };

export const ListProducts = ({ publications, title, onClickProduct }: ListProductsProps) => {
  const classes = styles();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const products = useMemo(() => normalizePublications(publications), [publications]);
  const [category, setCategory] = useState<CategoryPromo>(all);
  const [openModal, setOpenModal] = useState(false);

  const { favorites, addToFavorite, removeFromFavorite } = useFavoriteManage();

  const fixedCategories = useMemo(() => {
    const pool: Category[] = [all];
    products.forEach((product: Product) => {
      product.attributes?.features?.forEach((group: FeatureGroup) => {
        if (group.name === "Categories") {
          group.features?.forEach((feature: Feature) => {
            const names = feature.name.split(".");
            pool.push({
              name: names.length > 1 ? names[1] : names[0],
              id: feature?.category_id || "",
              config_values: {} as ConfigValues,
              publications: [],
            });
          });
        }
      });
    });
    return uniqBy(pool, (category: Category) => category.id).map((p, index) => ({ ...p, value: index }));
  }, [products]);

  const onChangeCategory = useCallback((value: CategoryPromo) => {
    setCategory(value);
  }, []);

  const filterProducts = useMemo(() => {
    if (category.value === 0) return products;
    const productsResult: Product[] = [];
    products.forEach((product: Product) => {
      const features = flatten(
        product.attributes?.features?.map((featureGroup: FeatureGroup) => featureGroup.features?.map((feature: Feature) => feature)),
      ) as Feature[];
      let match = true;
      const { name, id } = category;
      match = !!features?.find((feature) => {
        const names = feature.name.split(".");
        const featureName = names.length > 1 ? names[1] : names[0];
        return featureName === name && feature.category_id === id;
      });
      if (match) productsResult.push(product);
    });
    return uniqBy(flatten(productsResult), (product) => product.productId);
  }, [category, products]);

  return (
    <Grid item xs={12} container>
      <Grid item xs={12} id="to-products-anchor">
        <Typography variant={isDownSm ? "h5" : "h4"} color="textPrimary">
          {title}
        </Typography>
      </Grid>

      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Grid>
          <Grid className={classes.resultResume} container direction="column">
            <Typography variant="h6" style={{ color: "white" }}>
              {category.value === 0 ? ecommerceConfig.general.defaultSearchText : category.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {`${filterProducts.length} resultado${filterProducts.length > 1 ? "s" : ""}`}
            </Typography>
          </Grid>
          <List>
            {fixedCategories.map((_category) => (
              <ListItem button key={_category.id} onClick={() => onChangeCategory(_category)}>
                <ListItemText primary={_category.name} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Drawer>
      {isDownSm ? (
        <>
          <Grid item container xs={12} alignItems="center" className={classes.filterCollapsedContainer}>
            <Grid item container xs={12} className={classes.filtrarGrid}>
              <Grid item xs={4}>
                <Button onClick={() => setOpenModal(true)} color="primary" startIcon={<TuneRoundedIcon />} className={classes.filterButton}>
                  <Typography variant="subtitle1" color="primary">
                    Filtrar
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </>
      ) : (
        <FilterTabs categories={fixedCategories} category={category} onChange={onChangeCategory} />
      )}
      <Grid item container xs={12} className={classes.cardContainer} direction="column">
        <Grid item container direction="row" justify={isDownSm ? "center" : "space-between"} className={classes.cardItems}>
          {filterProducts.map((item) => (
            <Box key={`${item.productId}-list-products`} my={1} justifyContent="center">
              <Grid container justify="center">
                <ProductCardView
                  data={item}
                  onClick={() => onClickProduct(item)}
                  isFavorite={!!favorites.find((fav) => fav.publication.id === item.productId)}
                  onAddFavorite={() => addToFavorite(item.productId.toString())}
                  onRemoveFavorite={() => removeFromFavorite(item.productId.toString())}
                />
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
