import React from "react";

export const TwitterIcon = () => (
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.814 9.4369C23.8219 9.64093 23.8245 9.84488
 23.8245 10.0489C23.8245 16.2348 19.4731 23.3761 11.5157 23.3761C9.07136 23.3761 6.79846 22.597 
 4.88342 21.2708C5.22194 21.3079 5.56574 21.3358 5.9148 21.3358C7.94151 21.3358 9.80819 20.5844
  11.2889 19.3231C9.39582 19.2953 7.79732 17.932 7.24602 16.0772C7.51068 16.1328 7.78323 16.1607
   8.06196 16.1607C8.45499 16.1607 8.83659 16.1051 9.20236 15.9938C7.22138 15.5672 5.72928 13.6753
    5.72928 11.4031C5.72928 11.3753 5.72928 11.366 5.72928 11.3474C6.31311 11.6906 6.98134 11.9038
     7.69091 11.9316C6.52852 11.0877 5.76443 9.65019 5.76443 8.0272C5.76443 7.17397 5.97721 6.36708 
     6.35178 5.67151C8.48488 8.50942 11.674 10.3736 15.2693 10.5683C15.1955 10.2252 15.1576 9.86358
      15.1576 9.50188C15.1576 6.91438 17.0946 4.81836 19.4845 4.81836C20.7287 4.81836 21.8524 5.38417
       22.641 6.29304C23.6285 6.08901 24.5535 5.69952 25.3905 5.16161C25.0661 6.25597 24.3811 7.17393
        23.486 7.74893C24.3618 7.63764 25.1971 7.38743 25.9717 7.01646C25.3905 7.95315 24.659 8.77843
         23.814 9.4369Z"
      fill="#95F035"
    />
  </svg>
);
