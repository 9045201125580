import React from "react";
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";

interface ProductViewTagsProps {
  tags: string[];
}

export const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 12px",
    width: "auto",
    height: "auto",
    left: 64,
    top: 554,
    background: "#363636",
    borderRadius: 4,
    marginRight: 16,
    marginBottom: 16,
  },
  grid: {
    marginTop: 24,
    marginBottom: "1%",
  },
}));

export const ProductViewTags = ({ tags }: ProductViewTagsProps) => {
  const classes = useStyles();
  return (
    <Grid item container direction="row" className={classes.grid}>
      {tags.map((item) => (
        <Grid item>
          <Box className={classes.box}>
            <Typography>{item}</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
