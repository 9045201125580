import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../../lib/libby/generator";
import { PurchasedItems } from "../../models";
import { businessOptions } from "../businessOptions";

const DAO_NAME = "PurchasedItems";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "idPurchasedItem",
      labelKey: "descripcion",
    },
    withSelectedChips: {
      idKey: "idPurchasedItem",
      labelKey: "descripcion",
    },
  },
};

const optionsToUse = !businessOptions.PurchasedItems
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PurchasedItems,
      hoc: { ...defaultOptions.hoc, ...businessOptions.PurchasedItems.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<PurchasedItems>(DAO_NAME, optionsToUse);

// hooks
export const usePurchasedItemsFetchById = fetchById;
export const usePurchasedItemsLibbyCall = libbyCall;
export const usePurchasedItemsDAO = libbyDAO;
export const usePurchasedItemsLibbyFetch = libbyFetch;

// components/hoc
export const PurchasedItemsAutocomplete = entityAutocomplete;
export const PurchasedItemsCall = renderPropCall;
export const PurchasedItemsById = renderPropFetchById;
export const PurchasedItemsSelect = simpleSelect;
export const PurchasedItemsAccordionSelector = accordionSelector;
export const PurchasedItemsSelectedChips = selectedChips;

// context
// ByIdContext
export const usePurchasedItemsByIdContext = useByIdContext;
export const PurchasedItemsByIdProvider = ByIdProvider;
export const PurchasedItemsByIdContext = ByIdContext;
// GeneralContext
