import { getPricePublication } from "src/context/CartContext/utils";
import { BaseAnalytics } from "../../lib/analytics";
import { FacebookPixel } from "./FacebookPixel";
import { GoogleAnalytic } from "./GoogleAnalytic";
import { Publication } from "../../app/models";
import { CartState } from "../../context/CartContext/types";
import { searchSkuPublication } from "../../utils";

enum TrackEvent {
  ADD_TO_CART = "AddToCart",
  REMOVE_FROM_CART = "RemoveFromCart",
  PURCHASE_APPROVED = "PurchaseApproved",
  PURCHASE_PENDING = "PurchasePending",
  PURCHASE_FAILED = "PurchaseFailed",
  START_CHECKOUT = "StartCheckout",
  VISIT_PUBLICATION = "VisitPublication",
}

export interface AddToCartParams {
  publication: Publication;
  quantity: number;
}

export interface RemoveFromCartParams {
  publication: Publication;
  quantity: number;
}

export interface PurchaseEventParams {
  publication?: Publication;
  cart: CartState;
  transactionId?: string;
}

export interface StartCheckoutEventParams {
  cart: CartState;
}

export interface VisitPublicationEventParams {
  publication: Publication;
}

class Analytics extends BaseAnalytics {
  TRACK_EVENT = TrackEvent;

  initialize(gaID: any) {
    FacebookPixel.initialize();
    GoogleAnalytic.initialize(gaID);
  }

  // emit helpers
  trackAddToCart(params: AddToCartParams) {
    this.track(this.TRACK_EVENT.ADD_TO_CART, params);
  }

  trackRemoveFromCart(params: RemoveFromCartParams) {
    this.track(this.TRACK_EVENT.REMOVE_FROM_CART, params);
  }

  trackPurchaseApproved(params: PurchaseEventParams) {
    this.track(this.TRACK_EVENT.PURCHASE_APPROVED, params);
  }

  trackPurchasePending(params: PurchaseEventParams) {
    this.track(this.TRACK_EVENT.PURCHASE_PENDING, params);
  }

  trackPurchaseFailed(params: PurchaseEventParams) {
    this.track(this.TRACK_EVENT.PURCHASE_FAILED, params);
  }

  trackStartCheckout(params: StartCheckoutEventParams) {
    this.track(this.TRACK_EVENT.START_CHECKOUT, params);
  }

  trackVisitPublication(params: VisitPublicationEventParams) {
    this.track(this.TRACK_EVENT.VISIT_PUBLICATION, params);
  }

  // registrations
  register() {
    if (process.env.REACT_APP_ANALYTICS === "true") {
      this.TRACK_EVENT = TrackEvent;
      this.addEvent(this.TRACK_EVENT.ADD_TO_CART, this.addToCart);
      this.addEvent(this.TRACK_EVENT.REMOVE_FROM_CART, this.removeFromCart);
      this.addEvent(this.TRACK_EVENT.PURCHASE_APPROVED, this.purchaseApproved);
      this.addEvent(this.TRACK_EVENT.PURCHASE_PENDING, this.purchasePending);
      this.addEvent(this.TRACK_EVENT.PURCHASE_FAILED, this.purchaseFailed);
      this.addEvent(this.TRACK_EVENT.START_CHECKOUT, this.startCheckout);
      this.addEvent(this.TRACK_EVENT.VISIT_PUBLICATION, this.visitPublication);
    }
  }

  private addToCart(params: AddToCartParams) {
    FacebookPixel.addToCartTrack({
      content_name: params?.publication?.product,
      content_ids: [params?.publication?.id],
      value: Number(params?.publication?.price || 0) * (params.quantity || 1),
      currency: "ARS",
    });

    const googleParams = {
      items: [
        {
          id: searchSkuPublication({ featuresPublication: params.publication.attributes.features || [] }),
          name: params.publication.product,
          quantity: params.quantity,
          price: Number(getPricePublication(params.publication) || 0) * (params.quantity || 1),
        },
      ],
    };
    // @ts-ignore
    window.gtag("event", "add_to_cart", googleParams);
  }

  private removeFromCart(params: RemoveFromCartParams) {
    const googleParams = {
      items: [
        {
          id: searchSkuPublication({ featuresPublication: params.publication.attributes.features || [] }),
          name: params.publication.product,
          quantity: params.quantity,
          price: Number(getPricePublication(params.publication) || 0) * (params.quantity || 1),
        },
      ],
    };
    // @ts-ignore
    window.gtag("event", "remove_from_cart", googleParams);
  }

  private purchaseApproved(params: PurchaseEventParams) {
    // TODO: terminar de codear el evento cuando tenga la info del backend
    // Facebook pixel
    FacebookPixel.purchaseTrack({
      content_name: "Compra aprobada",
      currency: "ARS",
      value: params.cart?.items?.reduce((acum, item) => acum + (item.quantity || 1) * Number(getPricePublication(item.publication) || 0), 0),
      content_ids: params.cart?.items?.map((item) => item.publication.id) || [],
      num_items: params.cart?.items?.reduce((acum, item) => acum + (item.quantity || 1), 0) || 0,
      contents: params.cart?.items || [],
    });

    // Google
    const googleParams = {
      transaction_id: params.transactionId,
      value: params.cart?.items?.reduce((acum, item) => acum + (item.quantity || 1) * Number(getPricePublication(item?.publication) || 0), 0),
      currency: "ARS",
      items: params.cart.items.map((item) => ({
        id: searchSkuPublication({ featuresPublication: item.publication.attributes.features || [] }),
        name: item.publication.product,
        quantity: item.quantity,
        price: Number(getPricePublication(item.publication) || 0) * (item.quantity || 1),
      })),
    };
    // @ts-ignore
    window.gtag("event", "purchase", googleParams);
  }

  // TODO: esto no se esta llamando desde ningun lado
  private purchasePending(params: PurchaseEventParams) {
    // // TODO: terminar de codear el evento cuando tenga la info del backend
    FacebookPixel.purchaseTrack({
      content_name: "Compra aprobada con pago pendiente",
      currency: "ARS",
      value: params.cart?.totals?.totalPrice || 0,
      content_ids: params.cart?.items?.map((item) => item.publication.id) || [],
      num_items: params.cart?.items?.reduce((acum, item) => acum + (item.quantity || 1), 0) || 0,
      contents: params.cart?.items || [],
    });
    // Google
    // params.cart.items.forEach((item) => {
    //   GoogleAnalytic.sendEvent({
    //     category: item.publication.product,
    //     action: 'sold',
    //     label: item.publication.product,
    //     value: Number(item.quantity || 1) * Number(item?.publication?.price || 0),
    //     nonInteraction: false,
    //   });
    // });
  }

  private purchaseFailed() {
    // TODO: terminar de codear el evento cuando tenga la info del backend
  }

  private startCheckout(params: StartCheckoutEventParams) {
    // Facebook pixel
    FacebookPixel.checkOutTrack({
      num_items: params.cart?.items?.reduce((acum, item) => acum + (item.quantity || 1), 0) || 0,
      currency: "ARS",
      value: params.cart?.items?.reduce((acum, item) => acum + (item.quantity || 1) * Number(getPricePublication(item.publication) || 0), 0),
    });

    const googleParams = {
      items: params.cart.items.map((item) => ({
        id: searchSkuPublication({ featuresPublication: item.publication.attributes.features || [] }),
        name: item.publication.product,
        quantity: item.quantity,
        price: Number(getPricePublication(item.publication) || 0) * (item.quantity || 1),
      })),
    };
    // @ts-ignore
    window.gtag("event", "begin_checkout", googleParams);
  }

  private visitPublication(params: VisitPublicationEventParams) {
    // Facebook Pixel
    FacebookPixel.viewContent({
      content_name: params?.publication?.product || "Producto sin nombre",
    });

    const googleParams = {
      items: [
        {
          id: searchSkuPublication({ featuresPublication: params.publication.attributes.features || [] }),
          name: params.publication.product,
          quantity: 1,
          price: getPricePublication(params.publication),
        },
      ],
    };

    // @ts-ignore
    window.gtag("event", "view_item", googleParams);
  }
}

const instance = new Analytics();
export { instance as Analytics };
