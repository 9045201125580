import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { DeliveryInitialValue } from "src/screens/Public/Checkout/initialValues";

interface DeliveryInformationShowProps {
  data: DeliveryInitialValue;
}

export const DeliveryInformationShow = ({ data }: DeliveryInformationShowProps) => (
  <Grid container>
    {!!data.province.name && (
      <Box width="auto" display="inline-grid">
        <Grid item container xs={12} zeroMinWidth>
          <Typography variant="caption" color="textPrimary">
            {`${data.street} ${data.streetNumber} ${data.floor} ${data.apartament}`}
          </Typography>
        </Grid>
        <Grid item container xs={12} zeroMinWidth>
          <Typography variant="caption" color="textPrimary">
            {`${data.province?.name}`}
          </Typography>
        </Grid>
        <Grid item container xs={12} zeroMinWidth>
          <Typography variant="caption" color="textPrimary">
            {`${data.city?.name}, CP ${data.zipCode}`}
          </Typography>
        </Grid>
        <Grid item container xs={12} zeroMinWidth>
          <Typography variant="caption" color="textPrimary">
            {`${data.additionalInfo}`}
          </Typography>
        </Grid>
      </Box>
    )}
  </Grid>
);
