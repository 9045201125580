import React from "react";
import { Typography, Grid, IconButton, useMediaQuery, Theme, Box } from "@material-ui/core";
import DeleteOutlineOutlined from "@material-ui/icons/DeleteOutlineOutlined";
import { formatPrice, searchSkuPublication } from "src/utils";
import { QuantityButton } from "src/commons/ui/view/QuantityButton";
import { CartItemStock } from "src/context/CartContext/types";
import { IMAGE_BASE_URL } from "src/config";
import { Link } from "react-router-dom";
import { useInfoPublicationPayment } from "src/commons/hooks/useInfoPublicationPayment";

interface CartItemProps {
  product: CartItemStock;
  onChangeQuantity: (newQuantity: number) => void;
  onDeleteItem: () => void;
  enableStock: boolean;
}

export const CartItem = ({ product, onChangeQuantity, onDeleteItem, enableStock }: CartItemProps) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const sku = searchSkuPublication({
    featuresPublication: product.publication.attributes.features,
  });

  const { mainAmount, mainPrice } = useInfoPublicationPayment(product.publication);

  const price = mainPrice[mainAmount];

  return (
    <Grid container justify="space-around" spacing={2}>
      <Grid container justify="center" alignContent="center" item xs={3} md={2} lg={1}>
        <Box width="50px" height="50px">
          <Grid component="img" src={`${IMAGE_BASE_URL}/Products/${sku}/main.jpg`} />
        </Box>
      </Grid>
      <Grid item container justify="center" alignContent="center" xs={7} md={8} lg={10}>
        <Grid item sm={12} md={12} lg={6}>
          <Grid item zeroMinWidth>
            <Link to={`/product/${product.publication.id}`}>
              <Typography {...(!isDownSm ? { noWrap: true } : {})} align={isDownSm ? "center" : "left"} variant="subtitle1" color="textPrimary">
                {product.publication.product}
              </Typography>
            </Link>
          </Grid>
        </Grid>

        <Grid item sm={12} md={4} lg={2} hidden={isDownSm}>
          <Typography align="center" variant="body2" color="textPrimary">
            Precio
          </Typography>
          <Typography align="center" variant="subtitle1" color="textPrimary">
            {`$${formatPrice(price)}`}
          </Typography>
        </Grid>
        <Grid item container justify="center" sm={12} md={4} lg={2}>
          <Typography hidden={isDownSm} variant="body2" color="textPrimary">
            Cantidad
          </Typography>
          <QuantityButton
            onAdd={() => onChangeQuantity(+1)}
            onRemove={() => onChangeQuantity(-1)}
            quantity={product.quantity}
            stock={product.stock}
            enableStock={enableStock}
            productId={product.publication.id}
          />
        </Grid>
        <Grid item sm={12} md={4} lg={2}>
          <Typography hidden={isDownSm} align="center" variant="body2" color="textPrimary">
            Subtotal
          </Typography>
          <Typography align="center" variant="subtitle1" color="textPrimary">
            {`$${formatPrice(Number(price) * product.quantity)}`}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={2} md={2} lg={1} justify="center" alignItems="center" style={{ borderLeft: "1px solid #C4C4C4" }}>
        <IconButton aria-label="menus" onClick={onDeleteItem} id={`${product.publication.id}-delete`}>
          <DeleteOutlineOutlined />
        </IconButton>
      </Grid>
    </Grid>
  );
};
