import { makeStyles } from "@material-ui/core/styles";

export const cartResumeStyles = makeStyles(() => ({
  container: {
    border: "1px solid #C4C4C4",
    // position: 'absolute',
    marginBottom: "25px",
    marginLeft: "20px",
    borderRadius: 8,
    background: "#FFFFFF",
    padding: 20,
  },
  title: {
    // fontFamily: 'Circular Std',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "40px",
    letterSpacing: "0.02em",
    color: "#360949",
  },
  number: {
    color: "#00A650",
  },
  text: {
    // fontFamily: 'Circular Std',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "23px",
    letterSpacing: "0.01em",
    color: "#4F4F4F",
  },
  cuotes: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 24,
    lineHeight: "26px",
    letterSpacing: "0.01em",
    color: "#4F4F4F",
  },
  price: {
    fontSize: 35,
    // lineHeight: 54,
    fontFamily: "Archivo, Rubik, sans-serif;",
    color: "#4F4F4F",
    fontWeight: 700,
    margin: "8px 0px",
  },
  send: {
    // fontFamily: 'Circular Std',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "23px",
    letterSpacing: "0.01em",
    color: "#00A650",
  },
}));

export const customStyleButton = {
  backgroundColor: "#029CDC",
  color: "white",
  fontWeight: 700,
  width: "100%",
};
