import { IMAGE_BASE_URL } from "../config";

interface GetProductImageProps {
  sku: string;
  typeImage: string;
  width?: number;
}

export const getProductImage = ({ sku, typeImage, width }: GetProductImageProps): string =>
  `${IMAGE_BASE_URL}/${width ? `w_${width}` : "w_auto"},f_auto/Products/${sku}/${typeImage}`;
