import React, { useEffect, useCallback } from "react";
import { SkeletonComponentProps, SOURCE } from "src/app/models/Site";
import { Grid, Container, Typography, Box, useMediaQuery, Theme, makeStyles, Slide } from "@material-ui/core";
import { ConfigValuesSection } from "src/app/models";
import { Loading } from "src/commons";
import ReactParse from "react-html-parser";
import { IMAGE_BASE_URL } from "src/config";
import { useHistory } from "react-router";
import { CustomButtonProps } from "src/commons/components/CustomBotton";

const initialValues: ConfigValuesSection = {
  imagen: { small: "", normal: "" },
  redirectTo: "",
  title: "",
  subTitle: "",
};
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: 500,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  center: {
    [theme.breakpoints.down(500)]: {
      textAlign: "center",
    },
  },
  mobile: {
    [theme.breakpoints.down(500)]: {
      marginTop: "65%",
    },
  },
}));

export const SkeletonSection = ({ data }: SkeletonComponentProps) => {
  const history = useHistory();
  const { data: publication = [], working } = SOURCE.PUBLICATION(data.source_id);
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down(769));
  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down(500));
  const config: ConfigValuesSection = { ...initialValues, ...(publication[0]?.config_values || {}) };

  useEffect(() => {
    const elem = document.getElementById("section_img");
    if (!elem) return;
    elem.style.background = `url(${IMAGE_BASE_URL}${config.imagen?.normal})`;
    elem.style.backgroundPosition = "center";
    elem.style.backgroundRepeat = "no-repeat";
    elem.style.backgroundSize = "100%";
    elem.style.backgroundPositionY = "40px";
    if (isDownSm) {
      elem.style.backgroundSize = "contain";
      elem.style.backgroundPositionY = "20px";
    }
    if (isDownXs) {
      elem.style.backgroundPositionX = "100%";
      elem.style.backgroundSize = "170%";
    }
    return () => {
      elem.style.background = "";
    };
  }, [config.imagen?.normal, isDownSm, isDownXs]);

  const onHanadlerClick = useCallback(() => {
    history.push(`${config.redirectTo || "/"}`);
  }, [history, config.redirectTo]);

  const classes = useStyles();
  return working ? (
    <Grid className={classes.container}>
      <Loading />
    </Grid>
  ) : (
    <Container maxWidth="lg" className={classes.mobile}>
      <Box py={isDownSm ? 3 : 8}>
        <Grid container>
          <Grid item direction="column" container xs={12} sm={12} md={6} spacing={isDownSm ? 1 : 4} className={classes.center}>
            <Grid item className={classes.center}>
              <Slide in timeout={500} direction="right">
                <Typography variant={isDownSm ? "h2" : "h1"} color="inherit">
                  {ReactParse(config.title)}
                </Typography>
              </Slide>
            </Grid>

            <Grid item className={classes.center}>
              <Slide in timeout={500} direction="right">
                <Typography variant={isDownSm ? "body2" : "subtitle2"} color="textSecondary">
                  {ReactParse(config.subTitle)}
                </Typography>
              </Slide>
            </Grid>
            <Grid item>
              <CustomButtonProps mt={3} name="Explorá" onClick={onHanadlerClick} />
            </Grid>
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </Box>
    </Container>
  );
};
