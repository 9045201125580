import React from "react";
import { Box, Card, CardActionArea, Zoom, CardContent, Grid, Typography, useMediaQuery, Theme } from "@material-ui/core";
import { EthereumPrice } from "src/app/services";
import { getPricePublication } from "src/context/CartContext/utils";
import { useCurrencyContext } from "src/context/CurrencyContext";
import { SPACING } from "src/commons";
import { DummyAvatar } from "src/assets/images";
import { ETHicon } from "src/assets/images/svgs";
import { ONESTAR_ADDRESS } from "src/config";
import { formatUnits } from "ethers/lib/utils";
import { Product } from "../../../models/business/Product";
import { useStyles } from "./NftCard";
import { FavoriteButtonView } from "../FavoriteButtonView";

export interface NftCardRowProps {
  data: Product;
  enableStock: boolean;
  onClick: () => void;
}

export const NftCardRow = ({ data, enableStock, onClick }: NftCardRowProps) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles({ isDownSm, lineCount: 2, lineHeight: 1.2, bgColor: "white" });

  const quantity = data?.attributes.videoInfo.picture.length || 1;
  const imgUrl = data?.attributes.videoInfo.picture[quantity - 1].url;

  const { selectedCurrency } = useCurrencyContext();
  const priceUsd = EthereumPrice.getPriceBy({ value: formatUnits(getPricePublication(data)) });
  const priceEth = formatUnits(getPricePublication(data));

  return (
    <Zoom in>
      <Card className={classes.rowContainer}>
        <CardActionArea onClick={onClick} id={data.productId.toString()}>
          <Box padding="20px 0 0 10px">
            <Grid container direction="row" alignItems={!isDownSm ? "flex-start" : "center"}>
              <Grid item xs={3} container alignItems="center">
                {isDownSm ? (
                  <Box width="80px" height="80px">
                    <Grid component="img" src={imgUrl} />
                  </Box>
                ) : (
                  <Grid style={{ backgroundImage: `url(${imgUrl})`, width: 320, height: 115, backgroundSize: "cover" }} />
                )}
              </Grid>
              <Grid item xs={9} container>
                <CardContent style={{ width: "100%" }}>
                  <Grid container direction="row" style={{ width: "100%" }}>
                    <FavoriteButtonView publicationId={data.productId.toString()} top={8} right={8} withBg />
                    {enableStock ? (
                      <Box mt={-1} width="100%" display="flex" flexDirection="column">
                        <Grid container direction="column" spacing={SPACING.SHORT}>
                          <Grid item xs zeroMinWidth>
                            <Typography noWrap variant="subtitle1" color="primary">
                              {data.attributes.nftInfo.start || ""}
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body1" color="inherit" className={classes.productName}>
                              {data.attributes.nftInfo.subTitle || ""}
                            </Typography>
                          </Grid>
                          <Grid item container alignItems="center">
                            <Grid style={{ borderRadius: "50%" }} component="img" width="16px" height="16px" src={DummyAvatar} />
                            <Box pl="8px" color="#737373">
                              <Typography variant="body2">
                                {data.attributes.tokenOwner === ONESTAR_ADDRESS ? "OneStar" : data.attributes.ownerNick?.toLowerCase()}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid item container justify="flex-end">
                          <Typography variant="h4" color="inherit">
                            {selectedCurrency === "USD" ? (
                              `$ ${priceUsd}`
                            ) : (
                              <>
                                <ETHicon width="18" color="white" /> {priceEth}
                              </>
                            )}
                          </Typography>
                        </Grid>
                      </Box>
                    ) : (
                      <Typography variant="h5">
                        <Box color="error.main" id={`${data.productId.toString()}-no-stock`}>
                          Sin stock
                        </Box>
                      </Typography>
                    )}
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </Box>
        </CardActionArea>
      </Card>
    </Zoom>
  );
};
