import React, { useMemo } from "react";
import { Theme, useMediaQuery, Box, Grid, Typography, Card, Hidden } from "@material-ui/core";
import { IMAGE_BASE_URL } from "src/config";
import { Link, useHistory } from "react-router-dom";
import { ecommerceConfig } from "src/config/ecommerce";
import { OptionsModal, OptionsModalOption } from "src/commons";
import { PurchaseItem, ORDER_STATE } from "src/app/models";
import moment from "moment";
import { formatPrice } from "src/utils";
import { getTextComponent } from "src/screens/Private/Profile/common";
import { ALL_SHIPMENT_STATUS_TEXT } from "../../utils";
import { getTrackingLink } from "../../common/ShipmentState";

interface PurchaseItemCardProps {
  item: PurchaseItem;
}

export const PurchaseItemCard = ({ item }: PurchaseItemCardProps) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const history = useHistory();

  const optionsMenu = useMemo<OptionsModalOption<PurchaseItem>[]>(
    () => [
      {
        label: getTextComponent("Ver compra", "text.primary"),
        onClick: (data) => history.push(`/dashboard/purchases/details/${data.order_id}`),
        hide: false,
        disable: false,
        id: 1,
      },
      {
        label: getTextComponent("Seguir envío", "text.primary"),
        onClick: (data) => getTrackingLink(data.sterOrder),
        hide: (data) =>
          ![ORDER_STATE.OUT_FOR_DELIVERY, ORDER_STATE.EXTERNAL_DELIVERY_READY].includes(data?.sterOrder?.state?.order_state_id as ORDER_STATE),
        disable: false,
        id: 2,
      },
      {
        label: getTextComponent("Comprar de nuevo", "primary.main", "subtitle2"),
        onClick: (data) => history.push(`/product/${data.publication_id}`),
        hide: false,
        disable: false,
        id: 3,
      },
    ],
    [history],
  );

  const shipment_info = item.sterOrder
    ? ALL_SHIPMENT_STATUS_TEXT[Number(item.sterOrder.shipment.order_shipment_id)] || ALL_SHIPMENT_STATUS_TEXT[1]
    : ALL_SHIPMENT_STATUS_TEXT[1];

  return (
    <Box justifyItems="center" width="100%" component={Card} maxHeight={113} maxWidth={678} borderRadius={8} boxShadow={3} display="flex">
      <Grid component="img" width="100px" src={`${IMAGE_BASE_URL}/Products/${item.sku}/main.jpg`} />
      <Box py={2} px={1} width="100%">
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={isDownSm ? 10 : 5}>
            <Box color={shipment_info.color}>
              <Typography variant="subtitle2">{shipment_info.text}</Typography>
            </Box>

            <Grid item xs={12} container>
              <Box width="auto" display="inline-grid">
                <Grid item zeroMinWidth>
                  <Link style={{ color: ecommerceConfig.ecommercePalette.materialUi.text.secondary }} to={`/product/${item.publication_id}`}>
                    <Typography noWrap variant="subtitle1" color="textPrimary">
                      {item.product}
                    </Typography>
                  </Link>
                </Grid>
              </Box>
            </Grid>

            <Typography variant="body2" color="textSecondary">
              {`x${item.quantity} unidad${item.quantity > 1 ? "es" : ""}`}
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid item container xs={3} direction="column">
              <Grid item>
                <Typography variant="subtitle2" color="textPrimary">
                  Fecha de compra
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {moment(item.created_at).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
            </Grid>
          </Hidden>

          <Hidden smDown>
            <Grid item xs={2} container direction="column">
              <Grid item>
                <Typography variant="subtitle2" color="textPrimary">
                  Precio
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {formatPrice(item.payment.amount)}
                </Typography>
              </Grid>
            </Grid>
          </Hidden>

          <Grid item xs={1} container alignItems="center" justify="flex-end">
            <OptionsModal options={optionsMenu} data={item} color="default" />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
