import React, { useState, useEffect, useCallback } from "react";
import { usePublicationByStockDAO } from "src/app/business";
import { Product } from "src/app/models/business/Product";
import { Stock } from "src/app/models";
import { NftCard, NftCardRow } from "../NftCard";

interface ProductCardViewProps {
  data: Product;
  viewGrid?: boolean;
  withStock?: boolean;
  onClick: () => void;
}

export const ProductCardView = ({ data, viewGrid = true, withStock = false, onClick }: ProductCardViewProps) => {
  const [loading, setLoading] = useState(false);
  const [enableStock, setEnableStock] = useState(true);
  const stockDAO = usePublicationByStockDAO();

  const getStock = useCallback(async () => {
    setLoading(true);
    const stock: Stock | undefined = await stockDAO.getByStock(data.productId.toString());
    setEnableStock(!!(stock && stock.quantity > 0));
    setLoading(false);
  }, [data.productId, stockDAO]);

  useEffect(() => {
    getStock();
  }, [getStock]);

  return viewGrid ? (
    <NftCard data={data} enableStock={withStock || enableStock} loading={loading} onClick={onClick} />
  ) : (
    <NftCardRow data={data} enableStock={withStock || enableStock} onClick={onClick} />
  );
};
