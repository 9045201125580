import { MenuItem } from "../../../../commons/ui/menu";
import { Politicas } from "../../../../screens/Public/Politicas";

export const routes: MenuItem[] = [
  {
    key: "publicPoliticaMain",
    basePath: "/",
    target: {
      component: Politicas,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Politica de Devolucion",
    children: [],
    default: true,
  },
];
