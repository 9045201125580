import { City, State, Emitter, CardType, PaymentModel } from "src/app/models";

export interface PersonalInfoInitialValue {
  name: string;
  lastName: string;
  document: string;
  dateOfBirty: string;
  phone: { areaCode: string; number: string };
  termsAndConditions: boolean;
  email: string;
}

export interface InitialValue {
  isResponsable: string;
  useDeliveryAddress: boolean;
  personalInfo: PersonalInfoInitialValue;
}

export const personalInfoInitialValue: PersonalInfoInitialValue = {
  name: "",
  lastName: "",
  document: "",
  dateOfBirty: "",
  phone: { areaCode: "", number: "" },
  termsAndConditions: false,
  email: "",
};

export const initialValue: InitialValue = {
  isResponsable: "false",
  useDeliveryAddress: true,
  personalInfo: personalInfoInitialValue,
};

export interface DeliveryInitialValue {
  province: State;
  city: City;
  street: string;
  streetNumber: string;
  floor: string;
  apartament: string;
  zipCode: string;
  additionalInfo: string;
}

export const deliveryInitialValue: DeliveryInitialValue = {
  province: { id: "", name: "", matches: [] },
  city: { id: "", zip: [0], name: "", matches: [], site_id: 0 },
  street: "",
  streetNumber: "",
  floor: "",
  apartament: "",
  zipCode: "",
  additionalInfo: "",
};

export interface PaymentInitialValue {
  emitter: Emitter;
  card: CardType;
  payment: PaymentModel;
}

export const paymentInitialValue: PaymentInitialValue = {
  emitter: {
    card_emiter_id: 0,
    name: "",
    image_url: "",
  },
  card: {
    card_id: "",
    name: "",
    emiter: "",
    type: "",
    card_image_url: "",
    emiter_image_url: "",
    match: [],
  },
  payment: {
    reference: "",
    type: "",
    installments: 0,
    original_amount: 0,
    amount: 0,
    metadata: {},
  },
};

export interface CardInfoInitialValue {
  cardHolder: string;
  cardNumber: string;
  cardExpiration: { month: string; year: string };
  cardSecurityCode: string;
}

export const cardInfoInitialValue: CardInfoInitialValue = {
  cardHolder: "",
  cardNumber: "",
  cardExpiration: { month: "", year: "" },
  cardSecurityCode: "",
};

export interface ResponsableInitialValues {
  business: string;
  cuit: string;
}

export const responsableInitialValues: ResponsableInitialValues = {
  business: "",
  cuit: "",
};

export interface ApplicantInitialValue<T> extends Omit<DeliveryInitialValue, "additionalInfo"> {
  cbu: string;
  img_dni_front: T;
  img_dni_back: T;
  img_paycheck: T;
  img_service_certificate: T;
}

export const applicantInitialValue: ApplicantInitialValue<Partial<File> | undefined> = {
  province: { id: "", name: "", matches: [] },
  city: { id: "", zip: [0], name: "", matches: [], site_id: 0 },
  street: "",
  streetNumber: "",
  floor: "",
  apartament: "",
  zipCode: "",
  cbu: "",
  img_dni_front: undefined,
  img_dni_back: undefined,
  img_paycheck: undefined,
  img_service_certificate: undefined,
};

export interface GuarantorInitialValue<T> extends ApplicantInitialValue<T> {
  name: string;
  lastName: string;
  document: string;
  dateOfBirty: string;
  email: string;
  phone: { areaCode: string; number: string };
}

export const guarantorInitialValue: GuarantorInitialValue<Partial<File> | undefined> = {
  province: { id: "", name: "", matches: [] },
  city: { id: "", zip: [0], name: "", matches: [], site_id: 0 },
  street: "",
  streetNumber: "",
  floor: "",
  apartament: "",
  zipCode: "",
  name: "",
  lastName: "",
  document: "",
  dateOfBirty: "",
  phone: { areaCode: "", number: "" },
  email: "",
  cbu: "",
  img_dni_front: undefined,
  img_dni_back: undefined,
  img_paycheck: undefined,
  img_service_certificate: undefined,
};

export interface DocentInitialValue<T> extends Omit<Omit<Omit<ApplicantInitialValue<T>, "img_paycheck">, "img_service_certificate">, "cbu"> {
  docentType: { id: number; name: string };
  img_dni_front: T;
  img_dni_back: T;
  img_certificate: T;
  img_paycheck?: T;
}

export const docentInitialValue: DocentInitialValue<Partial<File> | undefined> = {
  docentType: { id: 0, name: "" },
  province: { id: "", name: "", matches: [] },
  city: { id: "", zip: [0], name: "", matches: [], site_id: 0 },
  street: "",
  streetNumber: "",
  floor: "",
  apartament: "",
  zipCode: "",
  img_dni_front: undefined,
  img_dni_back: undefined,
  img_certificate: undefined,
  img_paycheck: undefined,
};

export interface AlumnInitialValue<T> extends Omit<Omit<Omit<ApplicantInitialValue<T>, "img_paycheck">, "img_service_certificate">, "cbu"> {
  img_dni_front: T;
  img_dni_back: T;
  img_certificate: T;
}

export const alumnInitialValue: AlumnInitialValue<Partial<File> | undefined> = {
  province: { id: "", name: "", matches: [] },
  city: { id: "", zip: [0], name: "", matches: [], site_id: 0 },
  street: "",
  streetNumber: "",
  floor: "",
  apartament: "",
  zipCode: "",
  img_dni_front: undefined,
  img_dni_back: undefined,
  img_certificate: undefined,
};

export interface PersonalCredit {
  applicantInfo?: GuarantorInitialValue<string>;
  guarantorInfo?: GuarantorInitialValue<string>;
  docentInfo?: DocentInitialValue<string>;
  alumnInfo?: AlumnInitialValue<string>;
}
