import { EventEmitter } from "./EventEmitter";

export abstract class BaseAnalytics {
  events: Map<string, EventEmitter> = new Map<string, EventEmitter>();

  constructor() {
    this.register();
  }

  async emit(event: string, ...params: any[]) {
    try {
      await this.events.get(event)?.emit(...params);
    } catch (e) {
      console.log("Error ejecutando track", e);
    }
  }

  track(event: string, ...params: any[]) {
    return this.emit(event, ...params);
  }

  addEvent(event: string, method: (...params: any[]) => void) {
    if (!this.events.has(event)) {
      this.events.set(event, new EventEmitter());
    }
    this.events.get(event)?.subscribe(method);
  }

  protected abstract register(): void;
}
