import React, { useCallback } from "react";
import { Theme, useMediaQuery, Container, Box, Grid, Typography, Button, Card } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useBreadcrumbs } from "src/commons";
import { AppBreadcrumbsApp } from "src/app/views";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useScrollToTopOnMount } from "src/commons/hooks/useScrollToTopUnMount";
import { useRolesContext } from "src/context";
import { confirmationTimeManager } from "src/utils";
import moment from "moment";
import { useConfirmationDAO } from "src/app/business";
import { useHistory } from "react-router";
import { TextInput } from "../../Profile/common";

export const Security = () => {
  useScrollToTopOnMount();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isBw768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));
  const { userInfo } = useRolesContext();
  const history = useHistory();
  const confirmationDAO = useConfirmationDAO();

  const links = useBreadcrumbs({
    links: isDownSm
      ? [
          { label: "Seguridad", url: "/dashboard" },
          { label: "Seguridad", url: "/dashboard" },
          { label: "Mi cuenta", url: "/dashboard/configuration/security" },
        ]
      : [
          { label: "Mi cuenta", url: "/dashboard" },
          { label: "Configuración", url: "/dashboard/configuration/security" },
          { label: "Seguridad", url: "/dashboard/configuration/security" },
        ],
  });

  const handleUpdatePersonalInfo = useCallback(
    async (redirecTo: string) => {
      const nowTime = moment();
      // INFO: verifica cada 24 horas, tras la ultima verificacion
      const time = await confirmationTimeManager.retrieve();
      if (!moment(time).isValid() || moment(time).diff(nowTime) < 0) {
        await confirmationDAO.sendCode({ email: userInfo.email });
        history.push({
          pathname: "/dashboard/confirm",
          state: {
            goBack: "/dashboard",
            goNext: redirecTo,
          },
        });
      } else {
        history.push(redirecTo);
      }
    },
    [history, userInfo.email, confirmationDAO],
  );

  return (
    <>
      <Helmet>
        <title>Compras</title>
        <meta name="description" content="User profile" />
      </Helmet>
      <Container maxWidth="lg">
        <Box px={isDownSm ? 0 : "10%"} mt={isDownSm ? 1 : 3}>
          <AppBreadcrumbsApp
            links={links}
            iconSeparator={<Box component="div">/</Box>}
            iconGoBack={<ChevronLeftIcon color="primary" />}
            invert
            msgReturn=" "
            noMargin
          />

          <Grid container item spacing={isDownSm ? 0 : 2} xs={12} sm={isBw768 ? 8 : 12} md={8}>
            {!isDownSm && (
              <Grid item>
                <Typography variant="h5" color="textPrimary">
                  Seguridad
                </Typography>
              </Grid>
            )}

            <Grid item container>
              <Box mb={2} width={isDownSm ? "100%" : "60%"}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography variant={isDownSm ? "subtitle1" : "h6"} color="textPrimary">
                      Accesos
                    </Typography>
                  </Grid>
                  <Box display="flex" m={0} boxSizing="border-box" alignItems="flex-end">
                    <Button
                      style={{ textTransform: "none", textDecoration: "none" }}
                      color="primary"
                      onClick={() => handleUpdatePersonalInfo("/dashboard/configuration/security/email")}
                    >
                      <Typography variant="caption" color="primary">
                        Modificar correo electrónico
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
                <Box justifyItems="center" component={Card} borderRadius={8} boxShadow={3} display="content">
                  <Box padding={isDownSm ? 2 : 3}>
                    <Grid container direction="row" spacing={3}>
                      <Grid item xs={12}>
                        <TextInput label="Correo electrónico" value={userInfo.email || ""} />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>

              <Box mb={2} width={isDownSm ? "100%" : "60%"}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography variant={isDownSm ? "subtitle1" : "h6"} color="textPrimary">
                      {`  `}
                    </Typography>
                  </Grid>
                  <Box display="flex" m={0} boxSizing="border-box" alignItems="flex-end">
                    <Button
                      style={{ textTransform: "none", textDecoration: "none" }}
                      color="primary"
                      onClick={() => handleUpdatePersonalInfo("/dashboard/configuration/security/password")}
                    >
                      <Typography variant="caption" color="primary">
                        Modificar contraseña
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
                <Box justifyItems="center" component={Card} borderRadius={8} boxShadow={3} display="content">
                  <Box padding={isDownSm ? 2 : 3}>
                    <Grid container direction="row" spacing={3}>
                      <Grid item xs={12}>
                        <TextInput label="Contraseña" value="****************" />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
