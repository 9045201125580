import { orderBy } from "lodash";
import { Publication, PaymentModel } from "src/app/models";
import { Product } from "src/app/models/business/Product";
import { getInfoPublicationPayment } from "src/commons/hooks/useInfoPublicationPayment";
import { CartItemStock } from "./types";

export const persistentCart = () => {
  const key = "owner";

  return {
    persist: (data: string) => localStorage.setItem(key, data),
    get: () => localStorage.getItem(key),
    remove: () => localStorage.removeItem(key),
  };
};

const get = (price: Array<{ promotion_id: number; price: string }> | null, oldPrice: number) =>
  Number(price ? orderBy(price, "price")[0].price : oldPrice);

export const getPrice = (cart: CartItemStock) => get(cart.publication.price, cart.publication.old_price) * cart.quantity;

export const getPricePublication = (publication: Product | Publication, payment?: PaymentModel) =>
  Number(
    publication.price?.find((promo) => promo.promotion_id.toString() === payment?.reference)?.price ||
      get(publication.price, Number(publication.old_price)) ||
      publication.old_price,
  );

export const getPricePublicationCart = (publication: Product | Publication, payment?: PaymentModel) => {
  const { mainAmount, mainPrice } = getInfoPublicationPayment(publication);
  let price;
  if (payment?.amount) {
    price = publication.price?.find((promo) => promo.promotion_id.toString() === payment?.reference)?.price || publication.old_price;
  } else {
    price = mainPrice[mainAmount];
  }
  return Number(price);
};
