import { makeStyles } from "@material-ui/core";
import palette from "src/theme/palette";

export const productInfoPanelStyles = makeStyles(() => ({
  normalFontWeight: {
    fontWeight: "normal",
  },
  sale: {
    textDecoration: "line-through",
  },
  noSale: {
    textDecoration: "none",
  },
  img: {
    maxWidth: "280px",
    height: "280px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  off: {
    background: palette.primary.light,
  },
  localShipping: { color: palette.success.dark },
  localShippingPay: { color: palette.text.primary },
  priceGrid: { marginTop: 8 },
  buttonShipment: { textTransform: "none", marginLeft: "-6px" },
}));
