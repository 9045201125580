import { makeStyles } from "@material-ui/core";
import { ecommerceConfig } from "src/config/ecommerce";

// const store = process.env.REACT_APP_ECOMMERCE;

export const bannerInputViewStyles = makeStyles((theme) => ({
  container: {
    height: "120px",
    width: "100%",
    margin: "8px 0",
    borderRadius: "4px",
    padding: "24px 48px",
    // eslint-disable-next-line max-len
    background: ecommerceConfig.assets.subcribeBackground,
    color: "#fff",
    // boxShadow: store === 'enova' ? '0px 4px 4px rgba(139, 4, 188, 0.25);' : '0px 4px 4px rgba(1, 150, 209, 0.2)',
    [theme.breakpoints.down(1050)]: {
      height: "150px",
    },
    [theme.breakpoints.down(500)]: {
      height: "250px",
      padding: "16px 24px",
    },
    [theme.breakpoints.down(361)]: {
      height: "290px",
    },
  },
  title: {
    maxWidth: "550px",
  },
  containerForm: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(769)]: {
      height: "71px",
    },
    [theme.breakpoints.down(500)]: {
      height: "100%",
      flexDirection: "column",
      width: "100%",
      marginTop: "24px",
    },
  },
  inputStyle: {
    width: "320px",
    margin: "auto 16px auto 0",
    color: "#fff",
    "& .MuiInputBase-root": {
      color: "#fff!important",
    },
    "& .MuiInputBase-root.MuiInput-underline:before": {
      borderColor: "rgba(255, 255, 255, 0.3)!important",
    },
    "& .MuiInput-underline:after": {
      borderColor: "rgba(255, 255, 255, 0.42)!important",
    },
    "& .MuiFormLabel-root": {
      color: "#fff!important",
    },
    "& .MuiFormHelperText-root": {
      color: theme.palette.primary.contrastText,
    },
    [theme.breakpoints.down(500)]: {
      margin: "0",
      width: "295px",
    },
    [theme.breakpoints.down(361)]: {
      width: "100%",
    },
  },
  buttonStyles: {
    width: "130px",
    height: "42px",
    margin: "auto 0",
    [theme.breakpoints.down(500)]: {
      marginTop: "16px",
      marginLeft: "58%",
    },
    [theme.breakpoints.down(361)]: {
      marginLeft: "52%",
    },
    [theme.breakpoints.down(321)]: {
      marginLeft: "47%",
    },
  },
}));
