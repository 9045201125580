import React from "react";
import { Box, Typography } from "@material-ui/core";
import { payMethodTableColumnStyles, payMethodTableRowStyles } from "src/app/views/styles";
import { PaymentModelWithCustom } from "src/app/models/Payment";
import { ConfigValuesPublication } from "src/app/models";
import { PaymentModel } from "../app/models/PaymentModel";
import { formatPriceNotDecimal, getDiscountPublication, getInterest } from "./productHelpers";

interface PaymentConfig extends PaymentModel {
  config: ConfigValuesPublication["showPrice"];
  isCatamarca: boolean;
}

const columnsPayments = [
  {
    id: "installments",
    label: "CUOTAS",
    hideSortIcon: true,
    render: (value: number, row: PaymentConfig) => (
      <Typography variant="body2">{`${value} cuota${value > 1 ? "s" : ""} de $${formatPriceNotDecimal(row.amount / value)}`}</Typography>
    ),
  },
  {
    id: "original_amount",
    label: "INTERÉS",
    hideSortIcon: true,
    render: (value: number, row: PaymentConfig) => {
      const discount = getDiscountPublication(row.amount.toString(), value);
      const interests = getInterest(value, row.amount);
      return (
        <Typography variant="body2">
          <Box component="text" color={interests === 0 || discount > 0 ? "success.dark" : "textPrimary"}>
            {row.isCatamarca ? "" : interests !== 0 && discount <= 0 ? `$${formatPriceNotDecimal(interests)}` : "Sin interés"}
          </Box>
        </Typography>
      );
    },
  },
  {
    id: "original_amount",
    label: "DESCUENTO",
    hideSortIcon: true,
    render: (value: number, row: PaymentConfig) => {
      const discount = getDiscountPublication((row.reference === "19" ? row[row.config.main_amount] : row.amount).toString(), value);
      return (
        <Typography variant="body2">
          <Box component="text" color={discount <= 0 ? "success.dark" : "textPrimary"}>
            {discount > 0 && `${discount}%`}
          </Box>
        </Typography>
      );
    },
  },
  {
    id: "amount",
    label: "TOTAL",
    hideSortIcon: true,
    render: (value: number, row: PaymentConfig) => (
      <Typography variant="body2" align="right">{`$${formatPriceNotDecimal(
        row.isCatamarca && row.reference === "19" ? row[row.config.main_amount] : value,
      )}`}</Typography>
    ),
  },
];

export const addStylesWithPayments = (payments: PaymentModel[]): PaymentModelWithCustom[] =>
  payments.reduce((paymentsAll: PaymentModelWithCustom[], payment: PaymentModel) => {
    const copyPaymentsAll = [...paymentsAll];
    copyPaymentsAll.push({
      ...payment,
      customStyles: payMethodTableRowStyles,
    });
    return copyPaymentsAll;
  }, []);

export const columnsPaymentsWithStyles = columnsPayments.map((column) => ({
  ...column,
  customStyles: payMethodTableColumnStyles,
}));
