import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { PersonalInfoInitialValue, ResponsableInitialValues } from "src/screens/Public/Checkout/initialValues";

interface PersonalInformationProps {
  personalInfo: PersonalInfoInitialValue;
  isResponsable: string;
  responsable: ResponsableInitialValues;
}

export const PersonalInformation = ({ personalInfo, isResponsable, responsable }: PersonalInformationProps) => (
  <>
    {!!personalInfo.name && (
      <Grid container>
        <Grid item container justify="flex-start" xs={8}>
          <Typography variant="caption" color="textPrimary">
            {`Nombre: ${personalInfo.name} ${personalInfo.lastName}`}
          </Typography>
        </Grid>
        <Grid item container justify="flex-start" xs={4}>
          <Typography variant="caption" color="textPrimary">
            {`DNI: ${personalInfo.document}`}
          </Typography>
        </Grid>
        <Grid item container justify="flex-start" xs={8}>
          <Typography variant="caption" color="textPrimary">
            {`Email: ${personalInfo.email}`}
          </Typography>
        </Grid>
        <Grid item container justify="flex-start" xs={4}>
          <Typography variant="caption" color="textPrimary">
            {`Celular: ${personalInfo.phone.areaCode}${personalInfo.phone.number}`}
          </Typography>
        </Grid>
        {isResponsable === "true" && (
          <>
            <Grid item container justify="flex-start" xs={8}>
              <Typography variant="caption" color="textPrimary">
                {`Empresa: ${responsable.business}`}
              </Typography>
            </Grid>
            <Grid item container justify="flex-start" xs={4}>
              <Typography variant="caption" color="textPrimary">
                {`CUIT: ${responsable.cuit}`}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    )}
  </>
);
