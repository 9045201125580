export const ecommerceTypography = {
  useNextVariants: true,
  fontFamily: "Readex Pro, Barlow",
  h1: {
    fontFamily: "Barlow",
    fontSize: "84px",
    fontStyle: "italic",
    lineHeight: "101px",
    fontWeight: 800,
  },
  h2: {
    fontFamily: "Barlow",
    fontSize: "36px",
    fontStyle: "italic",
    lineHeight: "43px",
    fontWeight: 800,
  },
  h3: {
    fontFamily: "Readex Pro",
    fontStyle: "normal",
    fontSize: "30px",
    lineHeight: "37px",
    fontWeight: 500,
  },
  h4: {
    fontFamily: "Readex Pro",
    fontStyle: "normal",
    fontSize: "24px",
    lineHeight: "30px",
    fontWeight: 500,
  },
  h5: {
    fontFamily: "Barlow",
    fontSize: "18px",
    fontStyle: "italic",
    lineHeight: "22px",
    fontWeight: 800,
  },
  h6: {
    fontFamily: "Readex Pro",
    fontSize: "20px",
    letterSpacing: "0.15px",
    lineHeight: "32px",
  },
  subtitle1: {
    fontFamily: "Barlow",
    fontStyle: "italic",
    fontWeight: 800,
    fontSize: "18px",
    letterSpacing: "0.15px",
    lineHeight: "22px",
  },
  subtitle2: {
    fontFamily: "Readex Pro",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "25px",
    fontWeight: 500,
  },
  body1: {
    fontFamily: "Readex Pro",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 500,
  },
  body2: {
    fontFamily: "Readex Pro",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "15px",
  },
  button: {
    fontFamily: "Readex Pro",
    fontSize: "16px",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    textTransform: "none",
  },
  buttonLarge: {
    fontFamily: "Readex Pro",
    fontSize: "15px",
    letterSpacing: "0.46px",
    lineHeight: "26px",
    textTransform: "uppercase",
  },
  buttonMedium: {
    fontFamily: "Readex Pro",
    fontSize: "14px",
    letterSpacing: "0.46px",
    lineHeight: "24px",
    textTransform: "uppercase",
  },
  buttonSmall: {
    fontFamily: "Readex Pro",
    fontSize: "13px",
    letterSpacing: "0.46px",
    lineHeight: "22px",
    textTransform: "uppercase",
  },
  caption: {
    fontFamily: "Readex Pro",
    fontSize: "12px",
    letterSpacing: "0.4px",
    lineHeight: "20px",
  },
  overline: {
    fontFamily: "Readex Pro",
    fontSize: "12px",
    letterSpacing: "1px",
    lineHeight: "32px",
    textTransform: "uppercase",
  },
  inputLabel: {
    fontFamily: "Readex Pro ",
    fontSize: "12px",
    letterSpacing: "0.15px",
    lineHeight: "12px",
  },
  helperText: {
    fontFamily: "Readex Pro",
    fontSize: "11px",
    letterSpacing: "0.4px",
    lineHeight: "16px",
  },
  inputText: {
    fontFamily: "Readex Pro",
    fontSize: "16px",
    letterSpacing: "0.15px",
    lineHeight: "22px",
  },
  tooltip: {
    fontFamily: "Readex Pro",
    fontSize: "10px",
    lineHeight: "14px",
  },
  buttonText: {
    fontFamily: "Barlow",
    fontStyle: "italic",
    fontWeight: 800,
    fontSize: "20px",
    lineHeight: "24px",
  },
  navbarIcon: {
    height: "32px",
    width: "32px",
    fontSize: "32px",
  },
};
