import { updateCartTotals } from "src/screens/Public/Cart/utils";
import { ADD_ITEM, REMOVE_ITEM, EDIT_QUANTITY, EDIT_ITEM, UPDATE_CART_TOTALS, UPDATE_STATE } from "./const";
import { CartState, CartActions } from "./types";

export const initialTotals = {
  subTotal: 0,
  shippingPrice: 0,
  totalPrice: 0,
  totalOnePay: 0,
  installments: 1,
  quantity: 0,
};

export const cartInitialState: CartState = {
  items: [],
  owner: "",
  totals: initialTotals,
  state_id: 0,
};

export const cartReducer = (state = cartInitialState, action: CartActions) => {
  switch (action.type) {
    case ADD_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload?.item],
      };
    case REMOVE_ITEM:
      return {
        ...state,
        items: [...state.items.filter((_item) => _item.publication.id !== action.payload?.id)],
      };
    case EDIT_QUANTITY:
      return {
        ...state,
        items: state.items.map((_item) => {
          if (_item.publication.id === action.payload?.id) {
            _item.quantity = action.payload?.newQuantity;
          }
          return _item;
        }),
      };
    case EDIT_ITEM:
      return {
        ...state,
      };
    case UPDATE_CART_TOTALS:
      return {
        ...state,
        totals: updateCartTotals(state.items),
      };
    case UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
