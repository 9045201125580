import React, { useEffect, useState, useMemo, useCallback } from "react";
import { PaymentMethodForm } from "src/app/views";
import { PaymentSimulation, CardData, PaymentModalName, CardName } from "src/app/models";
import { useSimulateDAO } from "src/app/business/Simulate";
import { useCartContext } from "src/context";
import { useEmittersContext } from "src/context/EmittersContext/EmittersContextProvider";
import { getDiscountPublication, getInterest, formatPriceNotDecimal } from "src/utils";
import { sortBy } from "lodash";
import { useField } from "react-final-form-hooks";
import { EMITTERS } from "src/app/const/Emitters";
import { useCheckoutFormContext } from "../hooks";
import { FormProps } from "../types";
import { InitialValue, PaymentInitialValue, paymentInitialValue } from "../initialValues";

interface Values extends InitialValue {
  paymentMethod: PaymentInitialValue;
}

export const PaymentMethodInfoForm = ({ onBackStep }: FormProps) => {
  const [promotions, setPromotions] = useState<CardData[]>([]);
  const { emitters } = useEmittersContext();
  const [loading, setLoading] = useState<boolean>(false);
  // TODO: nesesario para frenar el infinite fecth de los emisores, cuando el simulate no devuelve payments, debido a la incompatibilidad de las promosA
  const { form, values, errors, handleSubmit } = useCheckoutFormContext();
  const { cartState } = useCartContext();
  const simulateDAO = useSimulateDAO();

  const cardField = useField("paymentMethod.card", form);
  const paymentField = useField("paymentMethod.payment", form);

  const [itemsProducts, setItemsProducts] = useState<number>(0);

  useEffect(() => {
    if (itemsProducts !== cartState.items.length) {
      setItemsProducts(cartState.items.length);
    }
  }, [cartState.items, itemsProducts]);

  const { emitter, card, payment } = ({ paymentMethod: paymentInitialValue, ...values } as Values).paymentMethod;
  const isSelectedEmitter = emitter.name !== "";
  const isSelectedCard = card.name !== "";

  const onChangeEmitter = useCallback(() => {
    cardField.input.onChange(paymentInitialValue.card);
    paymentField.input.onChange(paymentInitialValue.payment);
  }, [cardField.input, paymentField.input]);

  useEffect(() => {
    (async () => {
      if (isSelectedEmitter) {
        setLoading(true);
        const { totals, ...rest } = cartState;
        const data: PaymentSimulation = await simulateDAO.generateSimulate(rest, emitter.card_emiter_id, values.deliveryInfo.province.id);
        setPromotions(Object.values(data.options));
        setLoading(false);
      }
    })();
  }, [cartState, emitter, isSelectedEmitter, simulateDAO, values.deliveryInfo.province.id]);

  const cards = useMemo<CardName[]>(
    () =>
      promotions.map((item) => {
        const sort = sortBy(item.payments, "installments").reverse();
        const cuotas = sort[0].installments > 1 ? "cuotas" : "cuota";
        const to = sort[0].installments > 1 ? "Hasta en" : "En";
        const discount = getDiscountPublication(sort[0].amount.toString(), sort[0].original_amount);
        const interest = discount <= 0 ? getInterest(sort[0].original_amount, sort[0].amount) : 0;
        const textInterest = sort[0].installments > 1 ? (interest === 0 ? `sin interés` : interest !== 0 ? "fijas" : "") : "";
        const nameOption =
          emitter.card_emiter_id === EMITTERS.CATAMARCA_CREDITS
            ? item.card.name
            : `${item.card.name}. ${to} ${sort[0].installments} ${cuotas} ${textInterest}: Total $${formatPriceNotDecimal(sort[0].amount)}`;
        return {
          ...item.card,
          nameOption,
        };
      }),
    [promotions, emitter.card_emiter_id],
  );

  const payments = useMemo<PaymentModalName[]>(() => {
    if (isSelectedCard && Number(card.card_id) > 0) {
      const select = promotions.find((item) => item.card.card_id === card.card_id);
      const _payments = sortBy(select?.payments, "installments").reverse();
      return _payments.map((item) => {
        const cuotas = item.installments > 1 ? "cuotas" : "cuota";
        const discount = getDiscountPublication(item.amount.toString(), item.original_amount);
        const interest = discount <= 0 ? getInterest(item.original_amount, item.amount) : 0;
        const textInterest = item.installments > 1 ? (interest === 0 ? `sin interés` : interest !== 0 ? "fijas" : "") : "";
        return {
          ...item,
          id: `${item.installments} ${item.amount} ${item.original_amount}`,
          installments: `${item.installments}`,
          name: `En ${item.installments} ${cuotas} ${textInterest} de $${formatPriceNotDecimal(
            item.amount / item.installments,
          )}; Total $${formatPriceNotDecimal(item.amount)}`,
        };
      });
    }
    return [] as PaymentModalName[];
  }, [promotions, card.card_id, isSelectedCard]);

  const select = promotions.find((item) => item.card.card_id === card.card_id);

  useEffect(() => {
    if (
      emitter.card_emiter_id === EMITTERS.CATAMARCA_CREDITS &&
      isSelectedCard &&
      select &&
      (payment.amount === 0 || select?.payments[0].reference !== payment.reference)
    ) {
      const select = promotions.find((item) => item.card.card_id === card.card_id);
      paymentField.input.onChange(select?.payments[0]);
    }
  }, [paymentField.input, emitter.card_emiter_id, select, isSelectedCard, payment, card.card_id, promotions]);

  useEffect(() => {
    if (isSelectedCard) {
      const cardInfo = cards.find((v) => v.card_id === card.card_id);
      if (cardInfo?.type.toLowerCase() === "debito") {
        paymentField.input.onChange(select?.payments[0]);
      }
    }
  }, [paymentField.input, select, isSelectedCard, card.card_id, cards]);

  return (
    <PaymentMethodForm
      loading={loading}
      disableNext={Object.keys(errors as any).length !== 0}
      emitters={emitters}
      cards={cards}
      payments={payments}
      promotions={promotions}
      isSelectedEmitter={isSelectedEmitter}
      isSelectedCard={isSelectedCard}
      onClickStep={handleSubmit}
      onBackStep={onBackStep}
      onChangeEmitter={onChangeEmitter}
      form={form}
      disableCuotes={emitter.card_emiter_id !== EMITTERS.CATAMARCA_CREDITS}
    />
  );
};
