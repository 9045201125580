import { WalletLink, WalletLinkProvider } from "walletlink";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { CONTRACT_ADDRESS } from "src/config";
import { DappWalletStrategy } from "../../DappWalletStrategy";
import { Wallet, WalletEvent } from "../common";
import { abi2 } from "../const/Abi";

export class Coinbase implements DappWalletStrategy {
  name: Wallet;

  private provider!: WalletLinkProvider;

  private ethersProvider!: ethers.providers.Web3Provider;

  constructor() {
    this.name = "coinbase";
    this.init();
  }

  get Provider() {
    return this.provider;
  }

  async init() {
    //  Create Coinbase Provider
    const walletLink = new WalletLink({
      appName: "OneStar",
      darkMode: false,
    });
    this.provider = walletLink.makeWeb3Provider("https://eth-ropsten.alchemyapi.io/v2/_3evpOycrKE3HcBDtP6Sp_ekA44nSzU3", 3);
    this.ethersProvider = new ethers.providers.Web3Provider(this.provider as any, "any");
  }

  getName(): Wallet {
    return this.name;
  }

  getProvider() {
    return this.provider;
  }

  public async connect() {
    console.log("Create new session");
    console.log("chainID", this.provider.chainId);
    const accounts = await this.provider.enable();
    // emitimos el evento al connectarnos
    this.provider.emit("connect", accounts[0]);
    return accounts[0];
  }

  async isConnected() {
    const isConnected = !!this.provider?.selectedAddress;
    return isConnected;
  }

  public async getAccount() {
    const signer = await this.getSigner();
    return signer.getAddress();
  }

  async disconnect() {
    this.provider.close();
  }

  async getSigner() {
    return this.ethersProvider.getSigner();
  }

  async getContract() {
    const signer = await this.getSigner();
    const contract = new ethers.Contract(CONTRACT_ADDRESS, abi2, signer);
    return contract;
  }

  async buyNFT(tokenId: string, price: number) {
    const contract = await this.getContract();
    const response = await contract.buyNFT(tokenId, { value: price }); // el gasprice es para asegurar el mineo
    console.log("Minando");
    return response;
  }

  async setPriceNFT(tokenId: string, price: string) {
    const format = parseUnits(price);
    const contract = await this.getContract();
    const response = await contract.setPriceNFT(tokenId, format); // el gasprice es para asegurar el mineo
    console.log("Minando");
    return response;
  }

  async listeners(event: WalletEvent, callback: () => Promise<void>) {
    const ehter = window.ethereum as any;
    ehter?.on(event, () => callback());
  }

  async restart() {
    if (this.provider && this.provider instanceof WalletConnectProvider) {
      //  this.provider = null;
      await this.init();
    }
  }
}
