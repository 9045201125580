import React from "react";
import { useField } from "react-final-form-hooks";
import CreditCardInput from "react-credit-card-input";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import pallet from "../../../theme/palette";

export const useStyles = makeStyles({
  label: {
    marginBottom: 10,
    fontSize: 16,
    lineHeight: "17px",
    fontWeight: 400,
    color: pallet.text.primaryAlternative,
  },
});

/* eslint-disable */
export interface FormGenericCreditCardProps<T = any> {
  cardNumberName: string;
  expiryName: string;
  cvcName: string;
  form: any;
  label: string;
}

export const FormGenericCreditCard = ({ form, cardNumberName, cvcName, expiryName, label }: FormGenericCreditCardProps) => {
  const classes = useStyles();
  const fieldNumber = useField(cardNumberName, form);
  const fieldExpiry = useField(expiryName, form);
  const fieldCVC = useField(cvcName, form);
  return (
    <div>
      <Typography className={classes.label}>{label}</Typography>
      <div>
        <CreditCardInput cardNumberInputProps={fieldNumber.input} cardExpiryInputProps={fieldExpiry.input} cardCVCInputProps={fieldCVC.input} />
      </div>
    </div>
  );
};
