import { makeStyles } from "@material-ui/core";

export const productFiltersStyles = makeStyles((theme) => ({
  container: {
    padding: 30,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 8,
    background: "black",
    [theme.breakpoints.up("lg")]: {
      maxHeight: 1200,
      overflowY: "auto",
      overflowX: "hidden",
      paddingTop: 0,
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
    },
  },
}));
