import React from "react";
import { Typography, Link } from "@material-ui/core";
import { ecommerceConfig } from "../../../config/ecommerce";

export const GuaranteesText = () => (
  <Typography variant="body1" color="textSecondary">
    En enova garantizamos la calidad y el correcto funcionamiento de cada uno de nuestros productos. Con tu compra obtenés un año de garantía y
    asistencia técnica a través de nuestra red de centros de servicios autorizados a nivel nacional. Si necesitás realizar una consulta sobre tu
    garantía podés escribirnos a <Link href={`mailto:${ecommerceConfig.general.contactUrl}`}>{ecommerceConfig.general.contactUrl}</Link>
  </Typography>
);
