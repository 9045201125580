import React from "react";

export const ConmebolLogo = () => (
  <svg width="50" height="50" viewBox="0 0 37 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.26593 32.4141C5.07941 32.4118 4.89406 32.3849 4.71485 32.3341C4.52765 32.2845 4.35359 32.1958 
        4.20459 32.0741C4.04626 31.9358 3.92085 31.7651 3.8372 31.5741C3.73233 31.3273 3.68357 31.0611 
        3.69433 30.794V27.7736C3.69575 27.5613 3.73727 27.3511 3.81679 27.1536C3.88708 26.9663 3.99579 
        26.7951 4.13621 26.6507C4.27663 26.5062 4.44578 26.3914 4.63321 26.3135C5.03278 26.1566 5.47867 
        26.1566 5.87824 26.3135C6.06337 26.3931 6.23004 26.5088 6.36809 26.6535C6.51084 26.8041 6.62182 
        26.9808 6.69466 27.1736C6.77686 27.3843 6.81838 27.6081 6.81712 27.8336V28.1937C6.81712 28.2149 
        6.80852 28.2353 6.79321 28.2503C6.7779 28.2653 6.75713 28.2737 6.73548 28.2737H5.81701C5.79536 
        28.2737 5.77459 28.2653 5.75928 28.2503C5.74397 28.2353 5.73537 28.2149 5.73537 28.1937V27.8937C5.7399 
        27.7354 5.68954 27.5802 5.5925 27.4536C5.55153 27.3959 5.49643 27.3491 5.43224 27.3177C5.36805 27.2862 
        5.29683 27.271 5.22511 27.2736C5.14979 27.2654 5.07362 27.2778 5.00513 27.3096C4.93664 27.3414 4.87853 
        27.3913 4.83731 27.4536C4.76137 27.6165 4.72637 27.7948 4.73526 27.9737V30.754C4.72662 30.9071 4.76197 
        31.0596 4.83731 31.194C4.88103 31.2527 4.94023 31.2986 5.00855 31.3268C5.07687 31.355 5.15174 31.3644 
        5.22511 31.354H5.4088L5.57209 31.254C5.62693 31.2044 5.66891 31.1427 5.69455 31.074C5.72801 30.9776
         5.74188 30.8756 5.73537 30.774V30.4739C5.73537 30.4634 5.73748 30.453 5.74158 30.4433C5.74569
          30.4336 5.7517 30.4248 5.75928 30.4174C5.76686 30.4099 5.77586 30.404 5.78577 30.4C5.79567 
          30.396 5.80629 30.3939 5.81701 30.3939H6.71507C6.72579 30.3939 6.73641 30.396 6.74631 30.4C6.75622 
          30.404 6.76522 30.4099 6.7728 30.4174C6.78038 30.4248 6.78639 30.4336 6.7905 30.4433C6.7946 30.453 
          6.79671 30.4634 6.79671 30.4739V30.854C6.79413 31.2631 6.63372 31.6561 6.34768 31.9541C6.20724 32.0961 
          6.04115 32.2114 5.85783 32.2941C5.67172 32.3756 5.46977 32.4166 5.26593 32.4141Z"
      fill="white"
    />
    <path
      d="M9.06238 32.4141C8.86736 32.4141 8.67388 32.3802 8.49089 32.3141C8.29445 32.2423 8.1142 
        32.1336 7.96022 31.9941C7.80237 31.8475 7.67727 31.6704 7.59284 31.474C7.49189 31.2401 7.44318 30.9878 
        7.44996 30.7339V27.8536C7.44665 27.6065 7.49529 27.3614 7.59284 27.1336C7.67727 26.9372 7.80237 26.7601 
        7.96022 26.6135C8.1142 26.474 8.29445 26.3653 8.49089 26.2935C8.86554 26.1535 9.27964 26.1535 9.65429 
        26.2935C9.85073 26.3653 10.031 26.474 10.185 26.6135C10.3428 26.7601 10.4679 26.9372 10.5523
         27.1336C10.6499 27.3614 10.6985 27.6065 10.6952 27.8536V30.7539C10.702 31.0078 10.6533 31.2601 
         10.5523 31.494C10.4679 31.6904 10.3428 31.8675 10.185 32.0141C10.031 32.1536 9.85073 32.2623 
         9.65429 32.3341C9.46348 32.3954 9.26298 32.4225 9.06238 32.4141ZM9.06238 27.2536C8.98357 27.2497 
         8.90486 27.263 8.83198 27.2927C8.75911 27.3224 8.6939 27.3676 8.64111 27.4251C8.58832 27.4825
          8.54927 27.5508 8.52682 27.625C8.50436 27.6991 8.49906 27.7772 8.5113 27.8536V30.7539C8.50451 
          30.8345 8.51558 30.9155 8.54377 30.9915C8.57195 31.0675 8.61658 31.1365 8.67459 31.194C8.7855 
          31.287 8.92664 31.3381 9.07259 31.3381C9.21854 31.3381 9.35968 31.287 9.47059 31.194C9.5286
           31.1365 9.57323 31.0675 9.60141 30.9915C9.6296 30.9155 9.64067 30.8345 9.63388 30.7539V27.8536C9.63936
            27.7732 9.62767 27.6925 9.59956 27.6167C9.57144 27.541 9.52752 27.4718 9.47059 27.4136C9.35849 27.3163 9.21192 27.266 9.06238 27.2736V27.2536Z"
      fill="white"
    />
    <path
      d="M14.614 32.3741H13.7771C13.7586 32.3747 13.7404 32.3691 13.7256 32.3582C13.7107 32.3473 
        13.7001 32.3317 13.6955 32.3141L12.5117 29.2938C12.5046 29.2863 12.4956 29.2808 12.4857 29.2777C12.4758 
        29.2745 12.4652 29.274 12.455 29.276C12.4448 29.278 12.4352 29.2825 12.4273 29.2891C12.4194 29.2957
         12.4133 29.3042 12.4096 29.3138V32.2741C12.4096 32.2953 12.401 32.3157 12.3857 32.3307C12.3704 
         32.3457 12.3496 32.3541 12.328 32.3541H11.4095C11.3988 32.3541 11.3882 32.3521 11.3783 32.348C11.3684
          32.344 11.3594 32.3381 11.3518 32.3307C11.3442 32.3233 11.3382 32.3144 11.3341 32.3047C11.33 32.295 
          11.3279 32.2846 11.3279 32.2741V26.3335C11.3279 26.323 11.33 26.3126 11.3341 26.3028C11.3382 26.2931
           11.3442 26.2843 11.3518 26.2769C11.3594 26.2695 11.3684 26.2636 11.3783 26.2596C11.3882 26.2555 
           11.3988 26.2535 11.4095 26.2535H12.2668C12.2853 26.2529 12.3035 26.2585 12.3183 26.2694C12.3332 
           26.2803 12.3438 26.2959 12.3484 26.3135L13.5118 29.3338C13.5189 29.3412 13.5279 29.3468 13.5378 
           29.3499C13.5477 29.353 13.5583 29.3536 13.5685 29.3516C13.5787 29.3496 13.5882 29.3451 13.5962 
           29.3385C13.6041 29.3319 13.6102 29.3234 13.6138 29.3138V26.3335C13.6138 26.323 13.616 26.3126 
           13.6201 26.3028C13.6242 26.2931 13.6302 26.2843 13.6378 26.2769C13.6453 26.2695 13.6543 26.2636 
           13.6642 26.2596C13.6741 26.2555 13.6848 26.2535 13.6955 26.2535H14.5935C14.6043 26.2535 14.6149
            26.2555 14.6248 26.2596C14.6347 26.2636 14.6437 26.2695 14.6513 26.2769C14.6589 26.2843 
            14.6649 26.2931 14.669 26.3028C14.6731 26.3126 14.6752 26.323 14.6752 26.3335V32.2741C14.6782
             32.2846 14.679 32.2956 14.6776 32.3065C14.6761 32.3173 14.6724 32.3277 14.6666 32.3371C14.6609
              32.3465 14.6533 32.3546 14.6442 32.361C14.6351 32.3673 14.6248 32.3718 14.614 32.3741Z"
      fill="white"
    />
    <path
      d="M19.7368 32.3743H18.8387C18.828 32.3743 18.8174 32.3723 18.8075 32.3683C18.7976 32.3642 
        18.7886 32.3583 18.781 32.3509C18.7734 32.3435 18.7674 32.3347 18.7633 32.325C18.7592 32.3153 18.7571 
        32.3048 18.7571 32.2943V29.334C18.7534 29.3245 18.7474 29.316 18.7394 29.3093C18.7315 29.3027 18.722 
        29.2982 18.7117 29.2962C18.7015 29.2942 18.691 29.2948 18.681 29.2979C18.6711 29.301 18.6622 29.3066 
        18.655 29.314L17.9611 31.4342L17.8999 31.4742H17.41L17.3488 31.4342L16.6752 29.314C16.6681 29.3066 
        16.6592 29.301 16.6492 29.2979C16.6393 29.2948 16.6287 29.2942 16.6185 29.2962C16.6083 29.2982 
        16.5988 29.3027 16.5908 29.3093C16.5829 29.316 16.5768 29.3245 16.5732 29.334V32.2743C16.5732 
        32.2848 16.5711 32.2952 16.567 32.305C16.5629 32.3147 16.5568 32.3235 16.5493 32.3309C16.5417 
        32.3383 16.5327 32.3442 16.5228 32.3483C16.5129 32.3523 16.5023 32.3543 16.4915 
        32.3543H15.5935C15.5718 32.3543 15.5511 32.3459 15.5358 32.3309C15.5204 32.3159 15.5118 32.2956
         15.5118 32.2743V26.3337C15.5118 26.3125 15.5204 26.2921 15.5358 26.2771C15.5511 26.2621 15.5718 
         26.2537 15.5935 26.2537H16.4507C16.4693 26.2531 16.4874 26.2587 16.5023 26.2696C16.5171 26.2806 
         16.5277 26.2961 16.5324 26.3137L17.6141 29.174C17.6197 29.1823 17.6273 29.189 17.6362 29.1937C17.6451 
         29.1984 17.655 29.2008 17.6651 29.2008C17.6752 29.2008 17.6852 29.1984 17.6941 29.1937C17.703 
         29.189 17.7106 29.1823 17.7162 29.174L18.7775 26.2937C18.7821 26.2761 18.7927 26.2606 18.8076 
         26.2496C18.8224 26.2387 18.8406 26.2331 18.8591 26.2337H19.7368C19.7584 26.2337 19.7792 26.2421 
         19.7945 26.2571C19.8098 26.2721 19.8184 26.2925 19.8184 26.3137V32.2543C19.8263 32.2673 19.8302 
         32.2822 19.8298 32.2972C19.8294 32.3123 19.8247 32.327 19.8161 32.3395C19.8076 32.352 19.7956 32.362 19.7816 32.3681C19.7675 32.3743 19.752 32.3765 19.7368 32.3743Z"
      fill="white"
    />
    <path
      d="M23.4312 32.3741H20.7167C20.695 32.3741 20.6742 32.3657 20.6589 32.3506C20.6436 32.3356 
        20.635 32.3153 20.635 32.2941V26.3334C20.635 26.3122 20.6436 26.2919 20.6589 26.2769C20.6742 26.2618
         20.695 26.2534 20.7167 26.2534H23.4516C23.4624 26.2534 23.473 26.2555 23.4829 26.2595C23.4928 
         26.2635 23.5018 26.2694 23.5094 26.2769C23.517 26.2843 23.523 26.2931 23.5271 26.3028C23.5312 
         26.3125 23.5333 26.3229 23.5333 26.3334V27.1935C23.5333 27.2147 23.5247 27.2351 23.5094 27.2501C23.4941 
         27.2651 23.4733 27.2735 23.4516 27.2735H21.7576C21.7495 27.2735 21.7416 27.2751 21.7341 27.2781C21.7267 
         27.2811 21.72 27.2855 21.7143 27.2911C21.7086 27.2967 21.7041 27.3033 21.701 27.3106C21.6979 27.3179
          21.6964 27.3257 21.6964 27.3335V28.7137C21.6964 28.7216 21.6979 28.7294 21.701 28.7366C21.7041 
          28.7439 21.7086 28.7505 21.7143 28.7561C21.72 28.7617 21.7267 28.7661 21.7341 28.7691C21.7416 
          28.7721 21.7495 28.7737 21.7576 28.7737H23.2067C23.2174 28.7737 23.2281 28.7758 23.238 
          28.7798C23.2479 28.7838 23.2569 28.7897 23.2644 28.7971C23.272 28.8046 23.278 28.8134
           23.2821 28.8231C23.2862 28.8328 23.2884 28.8432 23.2884 28.8537V29.7138C23.2884 29.7243
            23.2862 29.7347 23.2821 29.7444C23.278 29.7541 23.272 29.7629 23.2644 29.7704C23.2569 
            29.7778 23.2479 29.7837 23.238 29.7877C23.2281 29.7917 23.2174 29.7938 23.2067 
            29.7938H21.7576C21.7495 29.7938 21.7416 29.7954 21.7341 29.7984C21.7267 29.8014
             21.72 29.8058 21.7143 29.8114C21.7086 29.8169 21.7041 29.8236 21.701 29.8308C21.6979
              29.8381 21.6964 29.8459 21.6964 29.8538V31.234C21.6964 31.2499 21.7028 31.2651 21.7143 
              31.2764C21.7258 31.2876 21.7413 31.294 21.7576 31.294H23.4516C23.4624 31.294 23.473 
              31.296 23.4829 31.3001C23.4928 31.3041 23.5018 31.31 23.5094 31.3174C23.517 
              31.3248 23.523 31.3336 23.5271 31.3434C23.5312 31.3531 23.5333 31.3635 23.5333 31.374V32.2741C23.5377 
              32.288 23.5381 32.3028 23.5345 32.3169C23.5309 32.331 23.5235 32.3439 23.513 32.3542C23.5025 32.3645 
              23.4893 32.3718 23.4749 32.3753C23.4605 32.3788 23.4454 32.3784 23.4312 32.3741Z"
      fill="white"
    />
    <path
      d="M25.4518 32.3742H24.2068C24.196 32.3742 24.1854 32.3721 24.1755 32.3681C24.1656 32.3641
         24.1566 32.3582 24.149 32.3507C24.1415 32.3433 24.1354 32.3345 24.1313 32.3248C24.1272 32.3151 
         24.1251 32.3047 24.1251 32.2942V26.3335C24.1251 26.323 24.1272 26.3126 24.1313 26.3029C24.1354
          26.2932 24.1415 26.2844 24.149 26.2769C24.1566 26.2695 24.1656 26.2636 24.1755 26.2596C24.1854
           26.2556 24.196 26.2535 24.2068 26.2535H25.5334C25.7991 26.247 26.0632 26.2947 26.309 
           26.3935C26.5122 26.4805 26.6934 26.6102 26.8397 26.7736C27.0806 27.0638 27.2233
            27.4203 27.2479 27.7937C27.2887 28.3537 27.1663 28.9138 26.6968 29.2538H26.6764C26.8525
             29.3593 26.9942 29.512 27.0846 29.6939C27.2512 30.0716 27.3009 30.4889 27.2275 
             30.894C27.1947 31.2534 27.0525 31.5949 26.8193 31.8741C26.5336 32.1942 26.0641 
             32.3742 25.4518 32.3742ZM25.1865 31.2341C25.1865 31.25 25.1929 31.2652 25.2044 31.2765C25.2159
              31.2877 25.2315 31.2941 25.2477 31.2941H25.513C25.6536 31.2991 25.7934 31.2717 
              25.9212 31.2141C26.3499 30.994 26.3703 30.2339 26.0437 29.9139L25.9825 29.8539C25.7784
               29.7139 25.4926 29.7539 25.2477 29.7539C25.2315 29.7539 25.2159 29.7602 25.2044 
               29.7715C25.1929 29.7827 25.1865 29.798 25.1865 29.8139V31.2341ZM25.1865 28.7338C25.1865 
               28.7497 25.1929 28.765 25.2044 28.7762C25.2159 28.7875 25.2315 28.7938 25.2477 
               28.7938H25.5334C25.8192 28.7938 26.0029 28.7338 26.1049 28.5938C26.2086 28.4238 26.2616 
               28.2288 26.258 28.0308C26.2544 27.8329 26.1943 27.6399 26.0845 27.4736C26.0078 
               27.3984 25.9147 27.3414 25.8121 27.3067C25.7096 27.272 25.6003 27.2607 25.4926
                27.2736H25.2273C25.211 27.2736 25.1955 27.2799 25.184 27.2912C25.1725 27.3025 25.1661 27.3177 25.1661 27.3336L25.1865 28.7338Z"
      fill="white"
    />
    <path
      d="M29.4727 32.4141C29.2776 32.4141 29.0842 32.3802 28.9012 32.3141C28.7047 32.2423 28.5245
         32.1336 28.3705 31.9941C28.2126 31.8475 28.0875 31.6704 28.0031 31.474C27.9022 31.2401 27.8535 
         30.9878 27.8602 30.7339V27.8536C27.8569 27.6065 27.9056 27.3614 28.0031 27.1336C28.0875 26.9372 
         28.2126 26.7601 28.3705 26.6135C28.5245 26.474 28.7047 26.3653 28.9012 26.2935C29.2758 26.1535 
         29.6899 26.1535 30.0646 26.2935C30.261 26.3653 30.4413 26.474 30.5952 26.6135C30.7531 26.7601 30.8782 
         26.9372 30.9626 27.1336C31.0602 27.3614 31.1088 27.6065 31.1055 27.8536V30.7539C31.1123 
         31.0078 31.0636 31.2601 30.9626 31.494C30.8782 31.6904 30.7531 31.8675 30.5952 32.0141C30.4413 32.1536
         30.261 32.2623 30.0646 32.3341C29.8738 32.3954 29.6733 32.4225 29.4727 32.4141ZM29.4727 27.2536C29.3939 
         27.2497 29.3151 27.263 29.2423 27.2927C29.1694 27.3224 29.1042 27.3676 29.0514 27.4251C28.9986 27.4825
          28.9595 27.5508 28.9371 27.625C28.9146 27.6991 28.9093 27.7772 28.9216 27.8536V30.7539C28.9148 30.8345
           28.9259 30.9155 28.954 30.9915C28.9822 31.0675 29.0269 31.1365 29.0849 31.194C29.1958 31.287 29.3369
            31.3381 29.4829 31.3381C29.6288 31.3381 29.77 31.287 29.8809 31.194C29.9389 31.1365 29.9835 31.0675
             30.0117 30.9915C30.0399 30.9155 30.0509 30.8345 30.0442 30.7539V27.8536C30.0496 27.7732 
             30.0379 27.6925 30.0098 27.6167C29.9817 27.541 29.9378 27.4718 29.8809 27.4136C29.7688 27.3163 29.6222 27.266 29.4727 27.2736V27.2536Z"
      fill="white"
    />
    <path
      d="M34.718 32.3741H31.983C31.9723 32.3741 31.9617 32.372 31.9518 32.368C31.9419 32.364 
        31.9329 32.3581 31.9253 32.3506C31.9177 32.3432 31.9117 32.3344 31.9076 32.3247C31.9035 
        32.315 31.9014 32.3046 31.9014 32.2941V26.3334C31.9014 26.3229 31.9035 26.3125 31.9076 26.3028C31.9117 
        26.2931 31.9177 26.2843 31.9253 26.2769C31.9329 26.2694 31.9419 26.2635 31.9518 26.2595C31.9617 26.2555
         31.9723 26.2534 31.983 26.2534H32.8811C32.8918 26.2534 32.9024 26.2555 32.9123 26.2595C32.9222 26.2635
          32.9312 26.2694 32.9388 26.2769C32.9464 26.2843 32.9524 26.2931 32.9565 26.3028C32.9606 26.3125 32.9627
           26.3229 32.9627 26.3334V31.234C32.9627 31.2499 32.9692 31.2651 32.9806 31.2764C32.9921 31.2876 33.0077
            31.294 33.0239 31.294H34.718C34.7397 31.294 34.7604 31.3024 34.7757 31.3174C34.791 31.3324 34.7996 
            31.3528 34.7996 31.374V32.2741C34.8028 32.2861 34.8031 32.2987 34.8004 32.3109C34.7978 32.323 
            34.7923 32.3344 34.7844 32.3441C34.7764 32.3538 34.7663 32.3616 34.7548 32.3668C34.7433 32.372 34.7307 32.3745 34.718 32.3741Z"
      fill="white"
    />
    <path
      d="M37.4735 28.7739H35.4937C35.4824 28.7739 35.4733 28.7829 35.4733 28.7939V29.832C35.4733 
        29.8431 35.4824 29.852 35.4937 29.852H37.4735C37.4848 29.852 37.4939 29.8431 37.4939 29.832V28.7939C37.4939 28.7829 37.4848 28.7739 37.4735 28.7739Z"
      fill="white"
    />
    <path
      d="M2.00022 28.7739H0.0204101C0.00913778 28.7739 0 28.7829 0 28.7939V29.832C0 29.8431 
        0.00913778 29.852 0.0204101 29.852H2.00022C2.01149 29.852 2.02063 29.8431 2.02063 29.832V28.7939C2.02063 28.7829 2.01149 28.7739 2.00022 28.7739Z"
      fill="white"
    />
    <path
      d="M18.7571 4.05128C17.5132 4.04974 16.2733 4.19071 15.0628 4.47133C13.8908 4.74212 12.759 
        5.15889 11.6951 5.71146C11.6177 5.75012 11.5512 5.80682 11.5013 5.87657C11.4515 5.94632 
        11.4199 6.027 11.4094 6.1115C11.3685 6.45154 11.3277 6.77158 11.3073 7.09161C11.2869 
        7.41165 11.2665 7.75168 11.2665 8.07172C11.265 10.5708 11.9337 13.0264 13.2055 
        15.1925C14.4687 17.3474 16.2929 19.1351 18.4918 20.3731C18.5693 20.4169 18.6573 20.44 
        18.7469 20.44C18.8365 20.44 18.9244 20.4169 19.002 20.3731C21.2084 19.1379 23.0398 
        17.3499 24.3087 15.1925C25.5805 13.0264 26.2492 10.5708 26.2477 8.07172C26.2477 7.75168 
        26.2273 7.43165 26.2069 7.09161C26.1865 6.75157 26.1456 6.43154 26.1048 6.1115C26.0943 
        6.027 26.0627 5.94632 26.0128 5.87657C25.963 5.80682 25.8965 5.75012 25.8191 5.71146C24.755 
        5.15909 23.6233 4.74233 22.4514 4.47133C21.2408 4.19089 20.001 4.04992 18.7571 4.05128Z"
      fill="white"
    />
    <path
      d="M9.77661 13.8525L8.26625 17.2128H8.22542C7.41392 15.6163 6.99455 13.8559 7.0008 
        12.0723L9.61333 13.3924C9.6944 13.4351 9.75617 13.5061 9.7864 13.5913C9.81664 13.6765 9.81315 
        13.7697 9.77661 13.8525ZM27.452 6.75168C27.4724 7.13172 27.4928 7.49176 27.4928 7.8518C27.4898 
        9.22957 27.3046 10.6011 26.9417 11.9322L30.187 9.53198C29.9142 8.3547 29.4526 7.22725 
        28.8195 6.19162L27.4112 6.25163C27.4316 6.43164 27.452 6.59166 27.4724 6.77168L27.452 
        6.75168ZM14.0832 3.39132L14.5322 1.35109C12.8802 1.97793 11.394 2.96256 10.1848 4.23141L12.3075 
        3.97138C12.8873 3.74423 13.4803 3.55055 14.0832 3.39132ZM25.166 3.97138L27.2887 4.23141C26.0913
         2.96751 24.6195 1.9832 22.9821 1.35109L23.4107 3.39132C24.0137 3.55051 24.6066 3.74419 25.1864 
         3.97138H25.166ZM21.4922 2.97127L20.6145 0.731026C19.3775 0.535621 18.1165 0.535621 16.8794 
         0.731026L16.0018 2.99127C17.8213 2.71773 19.6727 2.71773 21.4922 2.99127V2.97127ZM10.0011 
         7.8518C10.0011 7.49176 10.0215 7.13172 10.0419 6.75168C10.0624 6.37164 10.0828 6.41164 10.1032 
         6.25163L8.67445 6.19162C8.03934 7.23343 7.57772 8.36778 7.30696 9.55198L10.5522 11.9522C10.1876 
         10.6147 10.0024 9.23627 10.0011 7.8518ZM30.4931 12.0523L27.8806 13.3924C27.7995 13.4351 27.7378 
         13.5061 27.7075 13.5913C27.6773 13.6765 27.6808 13.7697 27.7173 13.8525L29.2277 17.2128H29.2685C30.08 
         15.6163 30.4994 13.8559 30.4931 12.0723V12.0523ZM12.1442 15.7727C11.9197 15.3926 11.736 15.0126 
         11.5523 14.6325L9.61333 19.313C10.7012 20.6373 12.0742 21.7096 13.6342 22.4534L16.3488 20.4532C14.6417 
         19.1846 13.2119 17.5929 12.1442 15.7727ZM18.3694 21.8533L16.3488 23.3535C17.9308 23.6748 19.5632 
         23.6748 21.1452 23.3535L19.1246 21.8533C19.0186 21.7754 18.8897 21.7333 18.7572 21.7333C18.6247 
         21.7333 18.4958 21.7754 18.3898 21.8533H18.3694ZM25.3293 15.7727C24.2612 17.5926 22.8315 19.1842 
         21.1248 20.4532L23.819 22.4734C25.3789 21.7296 26.7519 20.6573 27.8398 19.333L25.9008 14.6525C25.7579 15.0326 25.5742 15.4126 25.3497 15.7727H25.3293Z"
      fill="white"
    />
    <path
      d="M22.8802 10.6919C22.8902 10.7029 22.8958 10.7172 22.8958 10.7319C22.8958 10.7467 22.8902 
        10.7609 22.8802 10.7719L22.7373 10.952H22.6556C22.363 10.6204 22.001 10.3544 21.5943 
        10.1719C21.1642 9.98356 20.6979 9.88806 20.2268 9.89185H20.186C19.528 9.89942 18.8878 10.1016 
        18.3491 10.4719C17.8532 10.7919 17.4687 11.2524 17.2469 11.7921L17.1857 11.8321L17.1448
         11.7921L17.0632 11.452C17.0602 11.4238 17.0674 11.3955 17.0836 11.372C17.1717 11.2101 
         17.2741 11.0562 17.3898 10.912C17.5952 10.6361 17.8431 10.3932 18.1245 10.1919C18.7237
          9.78226 19.435 9.5592 20.1656 9.55181H20.2064C20.7274 9.55173 21.2424 9.66085 21.7168 
          9.87184C22.1583 10.0634 22.5544 10.3426 22.8802 10.6919ZM23.023 10.0319C22.2473 9.35641 21.2441 8.98595 
          20.2064 8.99175H20.1452C19.4227 8.99945 18.7138 9.18517 18.0837 9.53181C17.4995 9.8529 17.0079 10.314 
          16.655 10.872C16.6503 10.8849 16.6503 10.899 16.655 10.912V10.992L16.8387 11.092H16.9203C17.2434 
          10.5835 17.6929 10.1637 18.2266 9.87184C18.8064 9.55146 19.4592 9.37948 20.1248 9.37179H20.186C20.6967 
          9.36727 21.2033 9.46219 21.6763 9.65104C22.1493 9.83989 22.5793 10.1189 22.9414 10.4719H23.0026C23.0151 
          10.4676 23.0259 10.4594 23.0333 10.4486C23.0406 10.4378 23.0442 10.4249 23.0434 10.4119V10.1119C23.0491
           10.0993 23.052 10.0856 23.052 10.0719C23.052 10.0581 23.0491 10.0445 23.0434 10.0319H23.023ZM23.023
            9.31178C22.2018 8.75331 21.2264 8.45329 20.2268 8.45169H20.1656C19.3112 8.46125 18.4741 8.68874 
            17.7367 9.11176C17.0574 9.50281 16.4945 10.0614 16.1039 10.7319C16.0985 10.7411 16.0957 10.7514
             16.0957 10.7619C16.0957 10.7725 16.0985 10.7828 16.1039 10.7919L16.1651 10.8319H16.3897L16.4713 
             10.7919C16.832 10.2228 17.3296 9.74891 17.9204 9.41179C18.6015 9.01834 19.3751 8.8047 20.1656 
             8.79173H20.2268C21.2212 8.78733 22.1894 9.10362 22.9822 9.69182H23.0434C23.056 9.68751 23.0667
              9.67932 23.0741 9.6685C23.0814 9.65768 23.085 9.6448 23.0843 9.63182V9.39179C23.0821 9.37436 
              23.0753 9.35779 23.0646 9.34375C23.0538 9.3297 23.0395 9.31868 23.023 9.31178ZM20.2268
               7.91163H20.1452C19.1924 7.92025 18.2577 8.16817 17.4306 8.63171C16.698 9.04457 16.0809
                9.62865 15.6345 10.3319C15.6229 10.3461 15.6166 10.3637 15.6166 10.3819C15.6166 10.4001 
                15.6229 10.4177 15.6345 10.4319L15.7569 10.5919H15.859C16.2812 9.90092 16.8789 9.32893 
                17.5939 8.93174C18.3707 8.49445 19.2493 8.26026 20.1452 8.25167H20.2064C21.1776 8.2395 
                22.1326 8.49601 22.9618 8.99175H23.0434L23.2067 8.85173C23.2155 8.84459 23.2221 8.83518
                 23.2257 8.82458C23.2293 8.81398 23.2298 8.8026 23.2271 8.79173V8.75172C22.3364 8.19796 
                 21.3021 7.90638 20.2472 7.91163H20.2268ZM23.37 8.19166C22.4221 7.65088 21.3441 7.36778 
                 20.2472 7.37157H20.1656C19.1454 7.37936 18.1429 7.63356 17.2469 8.11165C16.4455 8.5411 
                 15.7598 9.15132 15.2467 9.89185C15.2377 9.90705 15.233 9.9243 15.233 9.94185C15.233
                  9.95941 15.2377 9.97665 15.2467 9.99186L15.3896 10.1519H15.4916C15.9757 9.42627 
                  16.6346 8.82862 17.4102 8.41169C18.2499 7.96303 19.1889 7.72267 20.1452 7.71161H20.2268C21.2825
                   7.70656 22.3196 7.98313 23.2271 8.5117H23.2884L23.3292 8.47169L23.3904 8.25167C23.3931 8.24079
                    23.3925 8.22942 23.3889 8.21882C23.3853 8.20821 23.3788 8.19881 23.37 8.19166ZM20.1248 
                    7.17155H20.186C20.1914 7.16243 20.1942 7.15208 20.1942 7.14155C20.1942 7.13101 20.1914 7.12067
                     20.186 7.11154L20.1044 6.93153C20.0959 6.91232 20.0815 6.8962 20.0632 6.88543C20.0449 6.87467
                      20.0236 6.8698 20.0023 6.87152C19.0079 6.90617 18.033 7.15184 17.1448 7.5916C16.2803 8.0201
                       15.5265 8.63569 14.9405 9.39179C14.9283 9.40513 14.9199 9.42146 14.9163 9.43906C14.9127 
                       9.45665 14.914 9.47487 14.9201 9.4918L15.0222 9.69182L15.063 9.73183H15.1242C15.6954 8.95163
                        16.4454 8.31359 17.3138 7.86894C18.1823 7.42429 19.145 7.18543 20.1248 7.17155ZM21.5535
                         7.29156C22.1514 7.41008 22.7287 7.61217 23.268 7.89163H23.3292C23.338 7.88448 23.3445 
                         7.87508 23.3481 7.86448C23.3517 7.85387 23.3522 7.84249 23.3496 7.83162L23.2884 
                         7.57159C23.2833 7.5578 23.2752 7.54528 23.2646 7.53491C23.254 7.52454 23.2412 
                         7.51658 23.2271 7.51159C22.7903 7.30373 22.3318 7.14278 21.8596 7.03154H21.778L21.5331 
                         7.19155C21.5243 7.1987 21.5178 7.2081 21.5142 7.21871C21.5105 7.22931 21.51 7.24069 
                         21.5127 7.25156L21.5535 7.29156ZM16.9816 7.39158C17.4493 7.1583 17.9422 6.97715 
                         18.4511 6.85152C18.9652 6.72198 19.4922 6.6482 20.0227 6.63149L20.0839 6.59149C20.0893 
                         6.58237 20.0921 6.57202 20.0921 6.56149C20.0921 6.55095 20.0893 6.5406 20.0839 
                         6.53148L19.9615 6.33146C19.9501 6.31594 19.9345 6.30393 19.9164 6.29684C19.8983 
                         6.28975 19.8786 6.28789 19.8594 6.29146C19.3566 6.3162 18.8575 6.38991 18.3695 
                         6.51148C17.8318 6.64694 17.3114 6.84153 16.8183 7.09154C15.8801 7.56665 15.0637
                          8.24362 14.4303 9.07176C14.4249 9.08088 14.4221 9.09123 14.4221 9.10176C14.4221 
                          9.11229 14.4249 9.12264 14.4303 9.13176L14.4711 9.17177L14.6752 9.25178H14.7568C15.3485
                           8.4777 16.1084 7.84228 16.9816 7.39158ZM16.206 7.19155C16.9947 6.71325 17.8664 6.38103
                            18.7777 6.21145L18.8185 6.17144C18.8211 6.16057 18.8206 6.14919 18.817 
                            6.13859C18.8134 6.12799 18.8069 6.11858 18.7981 6.11144L18.594 5.93142H18.5123C17.6315
                             6.11692 16.7894 6.44838 16.0223 6.91152C15.3008 7.34278 14.659 7.89057 
                             14.1241 8.5317C14.1151 8.54285 14.1086 8.55575 14.1051 8.56955C14.1016
                              8.58334 14.1011 8.59772 14.1037 8.61171L14.1445 8.89174L14.1853 8.93174H14.2466C14.7812
                               8.22973 
                15.4459 7.63261 16.206 7.17155V7.19155ZM14.2874 7.95164H14.3486C15.3241 6.95852 16.5488
                 6.23423 17.9 5.85141L17.9409 5.8114C17.9435 5.80053 17.943 5.78915 17.9394 5.77855C17.9358
                  5.76795 17.9292 5.75854 17.9204 5.7514L17.7776 5.59138C17.764 5.57938 17.7473 5.57119 
                  17.7293 5.56767C17.7114 5.56415 17.6928 5.56543 17.6755 5.57138C16.3777 5.95519 15.1941 6.64151 14.2262 
                  7.57159C14.2172 7.58274 14.2107 7.59564 14.2071 7.60944C14.2036 7.62324 14.2032 7.63761 14.2058 7.6516L14.2466
                   7.91163L14.2874 7.95164ZM14.4711 6.63149V6.6715L14.6548 6.81151H14.7364C15.3759 6.31623 16.0836 5.91217 
                   16.8387 5.61138C16.8512 5.60707 16.862 5.59888 16.8693 5.58806C16.8767 5.57724 16.8803 5.56436 16.8795
                    5.55138C16.8803 5.53839 16.8767 5.52551 16.8693 5.51469C16.862 5.50387 16.8512 5.49568 16.8387 
                    5.49137L16.5325 5.39136H16.4509C15.7478 5.70705 15.0892 6.11043 14.4915 6.59149L14.4711 
                    6.63149ZM14.3282 6.39147H14.3894C14.6951 6.15851 15.0155 5.94472 15.3487 5.7514C15.3575
                     5.74425 15.3641 5.73485 15.3677 5.72424C15.3713 5.71364 15.3718 5.70226 15.3691 
                     5.69139L15.3283 5.65139L15.063 5.57138H14.9813L14.2874 6.05143C14.2716 6.06254 
                     14.2593 6.07787 14.2521 6.09559C14.2448 6.11331 14.2429 6.13269 14.2466 6.15144L14.2874 
                     6.37146L14.3282 6.39147ZM20.4921 12.3921C20.4101 12.33 20.3101 12.295 20.2064 12.2921C20.1457
                      12.2894 20.0851 12.2988 20.0283 12.3197C19.9714 12.3406 19.9195 12.3726 19.8756
                       12.4137C19.8317 12.4548 19.7968 12.5043 19.773 12.559C19.7492 12.6137 19.7369 
                       12.6726 19.737 12.7322C19.7468 12.8456 19.7974 12.9519 19.8798 13.0322L20.5942 
                       13.6723H20.6758L20.8799 13.5522V13.5122C20.8826 13.5014 20.8821 13.49 20.8785 
                       13.4794C20.8749 13.4688 20.8683 13.4594 20.8595 13.4522C20.5738 13.2122 20.1044 
                       12.7922 20.1044 12.7922L20.0635 12.7122C20.0684 12.6839 20.0834 12.6583 20.1059
                        12.6399C20.1283 12.6216 20.1568 12.6117 20.186 12.6121H20.2676L20.9208 13.1722H20.982L21.0228
                         13.1322L21.1045 12.9122C21.1071 12.9013 21.1066 12.8899 21.103 12.8793C21.0994
                          12.8687 21.0928 12.8593 21.084 12.8522L20.4921 12.3921ZM20.9412 12.0121C20.7379 
                          11.8363 20.4775 11.737 20.2064 11.732H20.1656C19.884 11.7338 19.6138 11.8412 
                          19.4104 12.0321C19.3133 12.1221 19.2361 12.2307 19.1835 12.3511C19.1308 12.4715
                           19.1038 12.6012 19.1042 
                  12.7322C19.1099 12.9448 19.1891 13.1493 19.3288 13.3122C19.7012 13.7553 20.1589 14.1223 
                  20.6758 14.3923H20.7371C20.7458 14.3852 20.7524 14.3758 20.756 14.3652C20.7596 14.3546 
                  20.7601 14.3432 20.7575 14.3323L20.6758 14.0323V13.9923C20.266 13.7624 19.9 13.4647
                   19.5941 13.1122C19.5044 13.0048 19.4541 12.8709 19.4512 12.7322C19.4507 12.6458 
                   19.4685 12.5602 19.5037 12.481C19.5388 12.4018 19.5905 12.3306 19.6553 12.2721C19.7937
                    12.145 19.9759 12.0736 20.1656 12.0721H20.2064C20.3957 12.0755 20.5772 12.1466 
                    20.7167 12.2721L21.2882 12.7722H21.3698L21.5535 12.6321V12.5921C21.5522 12.5767 
                    21.5449 12.5624 21.5331 12.5521L20.9412 12.0121ZM21.329 11.592C21.2308 11.5046 
                    21.1209 11.4306 21.0024 11.372C20.755 11.2522 20.4824 11.1906 20.2064 11.192H20.186C19.7462
                     11.1927 19.323 11.3572 19.0022 11.652C18.8504 11.7935 18.7302 11.9643 18.6494 
                     12.1537C18.5686 12.3431 18.5289 12.5469 18.5328 12.7522C18.5401 13.0727 18.6548 
                     13.382 18.8593 13.6323C19.1819 14.0359 19.5677 14.3869 20.0023 14.6724H20.0635L20.3085
                      14.5724L20.3493 14.5324C20.3519 14.5215 20.3514 14.5101 20.3478 14.4995C20.3442 
                      14.4889 20.3376 14.4795 20.3289 14.4723C19.8763 14.1948 19.4759 13.8429 19.1451
                       13.4322C18.9892 13.2382 18.903 12.9991 18.9001 12.7522C18.8987 12.59 18.9325
                        12.4294 18.9993 12.2811C19.0662 12.1328 19.1646 12.0002 19.2879 11.8921C19.5438 
                        11.661 19.8789 11.5325 20.2268 11.532H20.2472C20.4663 11.5305 20.6828 11.5784 
                        20.8799 11.672L21.1249 11.8521L21.7372 12.4121H21.7984C21.8081 12.4093 21.8168 
                        12.4041 21.8239 12.3971C21.8311 12.3901 21.8363 12.3816 21.8392 12.3721V12.0721C21.8418
                         12.0581 21.8414 12.0437 21.8378 12.0299C21.8343 12.0161 21.8278 12.0032 21.8188 
                         11.9921L21.329 11.592ZM20.2064 10.6519H20.1656C19.5742 10.6547 19.0056 10.8761 
                         18.5736 11.272C18.3658 11.4608 18.2005 11.6899 18.0881 11.9447C17.9756 12.1995 
                         17.9185 12.4745 17.9204 12.7522C17.9292 13.1806 18.0796 13.5947 18.3491 13.9323C18.6999 14.3802 19.12 14.7716 19.5941 
                  15.0924H19.6553L19.6961 15.0524L19.7574 14.8324C19.76 14.8215 19.7595 14.8101
                   19.7559 14.7995C19.7523 14.7889 19.7457 14.7795 19.737 14.7724C19.3123 
                   14.4821 18.9339 14.1316 18.6144 13.7323C18.3936 13.451 18.2718 13.107 18.2674 
                   12.7522C18.2653 12.5191 18.3133 12.2883 18.4082 12.0746C18.5032 11.8609 18.643
                    11.6692 18.8185 11.512C19.1855 11.1799 19.666 10.9944 20.1656 10.992H20.2064C20.4768
                     10.9908 20.7446 11.0434 20.9937 11.1465C21.2428 11.2497 21.4679 11.4012 21.6555 
                     11.592H21.7576L21.8801 11.412C21.8844 11.3981 21.8848 11.3833 21.8812 11.3692C21.8776 
                     11.3551 21.8701 11.3423 21.8596 11.332C21.4208 10.9017 20.8271 10.6575 20.2064 10.6519ZM20.2064
                      10.1119H20.1656C19.4127 10.117 18.6901 10.4031 18.145 10.912C17.8825 11.1521 17.6742 11.4435
                       17.5336 11.7673C17.393 12.0912 17.3231 12.4403 17.3285 12.7922C17.3329 13.3357
                        17.5194 13.8628 17.8592 14.2923C18.3537 14.9011 18.9636 15.4105 19.6553 
                        15.7925H19.737L19.9002 15.6325C19.909 15.6253 19.9156 15.6159 19.9192 
                        15.6053C19.9228 15.5947 19.9233 15.5833 19.9207 15.5725V15.5325C19.246
                         15.1508 18.6561 14.6408 18.1858 14.0323C17.8885 13.6608 17.7237 13.2042 
                         17.7163 12.7322C17.7136 12.4282 17.7757 12.1271 17.8987 11.8481C18.0218 
                         11.569 18.203 11.3183 18.4307 11.112C18.9109 10.6669 19.5454 10.4167 20.2064 
                         10.4119C20.8709 10.4102 21.5118 10.6529 22.0025 11.092H22.0842L22.2883 10.972V10.932C22.2909 
                         10.9211 22.2904 10.9097 22.2868 10.8991C22.2832 10.8885 22.2766 10.8791 22.2679 10.872C21.7081 
                         10.3626 20.9702 10.0834 20.2064 10.0919V10.1119ZM19.4308 16.3325H19.5125L19.6145 16.1525C19.6222
                          16.1392 19.6243 16.1234 19.6205 16.1085C19.6167 16.0937 19.6073 16.0808 19.5941 16.0725C18.8306
                           15.6411 18.1641 15.0628 17.6347 14.3723H17.5735L17.5326 14.4123L17.4714 14.6724C17.4688 
                           14.6864 17.4693 14.7007 17.4728 14.7145C17.4763 14.7283 17.4828 14.7412 17.4918 14.7524C18.0342 
                           15.3907 18.6914 15.9263 19.4308 16.3325ZM19.7982 17.1326H19.8186V16.8326C19.8194 16.8196
                            19.8158 16.8067 19.8084 16.7959C19.8011 16.7851 19.7903 16.7769 19.7778 16.7726C19.0503 
                            16.4234 18.388 15.9569 17.8184 15.3924H17.7572C17.7446 15.3968 17.7339 15.4049 17.7265 
                            15.4158C17.7192 15.4266 17.7156 15.4395 17.7163 15.4524V15.7525C17.7163 15.768 17.72 
                            15.7833 17.7271 15.7972C17.7342 15.8111 17.7445 15.8232 17.7572 15.8325C18.3481 16.3739
                             19.0233 16.8195 19.7574 17.1526L19.7982 17.1326ZM18.3082 16.5726L18.2674 16.6126L18.2266
                              16.8326C18.223 16.8513 18.2249 16.8707 18.2321 16.8885C18.2393 16.9062 18.2516 16.9215 
                              18.2674 16.9326C18.6614 17.2002 19.0779 17.4344 19.5125 17.6327H19.5737V17.3527C19.5744
                               17.3397 19.5709 17.3268 19.5635 17.316C19.5561 17.3052 19.5454 17.297 19.5329
                                17.2926C19.1255 17.092 18.7363 16.8578 18.3695 16.5926L18.3082 16.5726ZM20.1248
                                 18.4728H20.1656C20.171 18.4637 20.1738 18.4533 20.1738 18.4428C20.1738 18.4322
                                  20.171 18.4219 20.1656 18.4128L19.9819 18.1127H19.9411C19.368 17.8901 18.8207 
                                  17.6085 18.3082 17.2726H18.247C18.2382 17.2798 18.2317 17.2892 18.2281 
                                  17.2998C18.2245 17.3104 18.224 17.3218 18.2266 17.3327V17.5927C18.2266 
                                  17.6082 18.2303 17.6235 18.2374 17.6374C18.2445 17.6513 18.2547 17.6634 
                                  18.2674 17.6727C18.8385 18.0246 19.4477 18.3131 20.0839 18.5328L20.1248 
                                  18.4728ZM18.7369 18.1127H18.6756C18.6669 18.1199 18.6603 18.1293 18.6567
                                   18.1399C18.6531 18.1505 18.6526 18.1619 18.6552 18.1727L18.7573 
                         18.4528L18.8185 18.5128C19.234 18.7157 19.6637 18.8895 20.1044 19.0328H20.186L20.4717 
                         18.8928C20.4802 18.8873 20.4871 18.8799 20.4918 18.8712C20.4966 18.8625 20.4991 18.8527 
                         20.4991 18.8428C20.4991 18.8329 20.4966 18.8232 20.4918 18.8144C20.4871 18.8057 20.4802
                          18.7983 20.4717 18.7928C19.8657 18.6306 19.2776 18.4095 18.7164 18.1327L18.7369 18.1127Z"
      fill="#121212"
    />
    <path
      d="M21.9617 13.9722V14.7523H21.778V14.1722L21.6351 14.2322C21.5953 14.188 21.5735 14.1311 21.5739 14.0722L21.8188 13.9722H21.9617Z"
      fill="white"
    />
    <path
      d="M22.676 14.3322C22.676 14.6522 22.4923 14.7722 22.1861 14.7722C22.1706 14.7412 22.1636 
        14.7067 22.1657 14.6722C22.1633 14.6523 22.1633 14.6322 22.1657 14.6122C22.3494 14.6122 22.4719 
        14.5922 22.4923 14.4322C22.4546 14.4699 22.4032 14.4915 22.3494 14.4922C22.3138 14.4953 22.2779
         14.4906 22.2443 14.4786C22.2107 14.4667 22.1802 14.4476 22.1549 14.4228C22.1296 14.398 22.1101
          14.3681 22.0979 14.3352C22.0857 14.3023 22.0809 14.2671 22.0841 14.2322C22.0841 14.1579 22.1142
           14.0867 22.1678 14.0342C22.2213 13.9817 22.294 13.9521 22.3698 13.9521C22.5739 13.9521 22.676
            14.1122 22.676 14.3322ZM22.3698 14.3322L22.5127 14.2922C22.5127 14.1722 22.4514 14.0922 22.3698 
            14.0922C22.3529 14.0883 22.3353 14.0888 22.3187 14.0934C22.302 14.0981 22.2868 14.1069 22.2746 
            14.1189C22.2623 14.1309 22.2534 14.1457 22.2486 14.1621C22.2439 14.1784 22.2434 14.1956 22.2473
             14.2122C22.2383 14.2298 22.2348 14.2496 22.2373 14.2692C22.2397 14.2887 22.248 14.3071 22.2611
              14.3221C22.2742 14.337 22.2915 14.3479 22.3108 14.3532C22.3302 14.3586 22.3507 14.3582 22.3698
               14.3522V14.3322Z"
      fill="white"
    />
    <path
      d="M23.125 13.9722V14.7523H22.9413V14.1722L22.7984 14.2322C22.7587 14.188 22.7369 14.1311 22.7372 14.0722L22.9821 13.9722H23.125Z"
      fill="white"
    />
    <path
      d="M23.8596 14.4924C23.8633 14.5319 23.8576 14.5716 23.8432 14.6085C23.8288 14.6455 23.8059 
        14.6788 23.7764 14.7058C23.7469 14.7328 23.7115 14.7528 23.6728 14.7644C23.6342 14.776 23.5934 
        14.7787 23.5535 14.7725C23.5106 14.7759 23.4675 14.7699 23.4273 14.7547C23.3871 14.7395 23.351 
        14.7157 23.3215 14.6849C23.2921 14.6541 23.2701 14.6172 23.2573 14.577C23.2444 14.5367 23.241 
        14.4942 23.2473 14.4524C23.2465 14.3243 23.2939 14.2004 23.3805 14.1044C23.4672 14.0084 23.5869 
        13.9472 23.7168 13.9324C23.7477 13.98 23.7621 14.0361 23.7576 14.0924C23.6896 14.0929 23.6231 
        14.1118 23.5654 14.1471C23.5078 14.1824 23.4612 14.2327 23.431 14.2924L23.5739 14.2524C23.6098 
        14.2421 23.6477 14.2407 23.6843 14.2484C23.7208 14.2561 23.7548 14.2726 23.7832 14.2965C23.8116 
        14.3203 23.8334 14.3507 23.8468 14.385C23.8601 14.4192 23.8646 14.4561 23.8596 14.4924ZM23.6964 
        14.4924C23.6967 14.4774 23.6935 14.4626 23.6872 14.4489C23.6808 14.4353 23.6714 14.4232 23.6597 
        14.4136C23.6479 14.404 23.6341 14.3971 23.6192 14.3934C23.6044 14.3898 23.5889 14.3894 23.5739 
        14.3924L23.431 14.4324C23.431 14.5324 23.4923 14.5924 23.5535 14.5924C23.5681 14.5988 23.5841
         14.6017 23.6001 14.601C23.6161 14.6002 23.6317 14.5957 23.6456 14.5879C23.6595 14.5801 23.6714 
         14.5692 23.6802 14.5561C23.689 14.543 23.6945 14.528 23.6964 14.5124V14.4924Z"
      fill="white"
    />
  </svg>
);
