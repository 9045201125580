import React, { ChangeEvent, useMemo } from "react";
import { useField } from "react-final-form-hooks";
import { Radio, RadioGroup, FormControlLabel, Box, Grid, FormHelperText, useMediaQuery, Theme } from "@material-ui/core";
import { Loading } from "src/commons";
import { getErrorAndHelperTextNoTranslation } from "src/utils/materialHelpers";
import { EMITTERS } from "src/app/const/Emitters";

export interface FormRadioGroupCustomProps {
  name: string;
  form: any;
  emisor?: boolean;
  row?: boolean;
  options: Array<any>;
  marginLeft?: string | number;
  marginRight?: string | number;
  marginBottom?: string | number;
  loading?: boolean;
  valueKey?: string;
  labelKey?: string;
  customHelperText?: string;
  renderComponent?: (props: any) => JSX.Element;
  optionToValue?: (value: any, options: any) => any;
  valueToOption?: (value: any, options: any) => any;
}

export const FormRadioGroupCustom = ({
  name,
  form,
  row,
  options,
  marginLeft,
  marginRight,
  marginBottom,
  emisor,
  loading,
  valueKey = "id",
  labelKey = "description",
  customHelperText,
  renderComponent,
  optionToValue,
  valueToOption,
}: FormRadioGroupCustomProps): JSX.Element => {
  const field = useField(name, form);
  const Comp = renderComponent;

  const isDown520 = useMediaQuery<Theme>((theme) => theme.breakpoints.down(520));
  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));

  const { error, helperText } = getErrorAndHelperTextNoTranslation(field);
  const optionsSorted = useMemo(() => options.sort((a, b) => a.card_emiter_id - b.card_emiter_id), [options]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <RadioGroup
          row={row}
          onFocus={field.input.onFocus}
          onBlur={field.input.onBlur}
          value={field.input.value ? (valueToOption ? valueToOption(field.input.value, options) : field.input.value) : ""}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            field.input.onChange(optionToValue ? optionToValue(newValue, options) : newValue);
          }}
        >
          <Box display={isDown520 ? "none" : "flex"} flexDirection="column" flexWrap="wrap">
            <Box display={isDown520 ? "none" : "flex"} flexWrap="wrap">
              {options?.map((item) => (
                <>
                  {!Comp ? (
                    <FormControlLabel
                      key={item.id}
                      value={item[valueKey].toString()}
                      control={item?.control || <Radio id={`type-${item[valueKey]}`} color="primary" />}
                      label={item[labelKey]}
                    />
                  ) : (
                    <>
                      {(item.card_emiter_id === EMITTERS.VISA ||
                        item.card_emiter_id === EMITTERS.MASTER ||
                        item.card_emiter_id === EMITTERS.AMEX) && (
                        <Box key={item.id} marginBottom={marginBottom} marginRight={marginRight} marginLeft={marginLeft} mt={row && emisor ? 2 : 2}>
                          <Comp value={item[valueKey]} image={item[labelKey]} />
                        </Box>
                      )}
                    </>
                  )}
                </>
              ))}
            </Box>
            <Box display={isDown520 ? "none" : "flex"} flexWrap="wrap">
              {options?.map((item) => (
                <>
                  {!Comp ? (
                    ""
                  ) : (
                    <>
                      {(item.card_emiter_id === EMITTERS.MERCADOPAGO ||
                        item.card_emiter_id === EMITTERS.BAPRO ||
                        item.card_emiter_id === EMITTERS.CATAMARCA_CREDITS ||
                        item.card_emiter_id === EMITTERS.NARANJA) && (
                        <Box key={item.id} marginBottom={marginBottom} marginRight={marginRight} marginLeft={marginLeft} mt={row && emisor ? 2 : 2}>
                          <Comp value={item[valueKey]} image={item[labelKey]} />
                        </Box>
                      )}
                    </>
                  )}
                </>
              ))}
            </Box>
          </Box>
          <Box display={isDown520 ? "flex" : "none"} width="100%" flexWrap="wrap">
            {optionsSorted?.map((item) => (
              <>
                {!Comp ? (
                  <FormControlLabel
                    key={item.id}
                    value={item[valueKey].toString()}
                    control={item?.control || <Radio id={item[valueKey]} color="primary" />}
                    label={item[labelKey]}
                  />
                ) : (
                  <>
                    {(item.card_emiter_id === EMITTERS.VISA || item.card_emiter_id === EMITTERS.MASTER || item.card_emiter_id === EMITTERS.AMEX) && (
                      <Grid xs={12} sm={6}>
                        <Box
                          display="flex"
                          justifyContent={isDownXs ? "flex-start" : "center"}
                          key={item.id}
                          marginBottom={marginBottom}
                          marginRight={marginRight}
                          marginLeft={marginLeft}
                          mt={row && emisor ? 2 : 2}
                        >
                          <Comp value={item[valueKey]} image={item[labelKey]} />
                        </Box>
                      </Grid>
                    )}
                  </>
                )}
              </>
            ))}
          </Box>
          <Box display={isDown520 ? "flex" : "none"} width="100%" flexWrap="wrap">
            {optionsSorted?.map((item) => (
              <>
                {!Comp ? (
                  ""
                ) : (
                  <>
                    {(item.card_emiter_id === EMITTERS.MERCADOPAGO ||
                      item.card_emiter_id === EMITTERS.BAPRO ||
                      item.card_emiter_id === EMITTERS.CATAMARCA_CREDITS ||
                      item.card_emiter_id === EMITTERS.NARANJA) && (
                      <Grid xs={12} sm={6}>
                        <Box
                          display="flex"
                          justifyContent={isDownXs ? "flex-start" : "center"}
                          key={item.id}
                          marginBottom={marginBottom}
                          marginRight={marginRight}
                          marginLeft={marginLeft}
                          mt={row && emisor ? 2 : 2}
                        >
                          <Comp value={item[valueKey]} image={item[labelKey]} />
                        </Box>
                      </Grid>
                    )}
                  </>
                )}
              </>
            ))}
          </Box>
          {error && <FormHelperText error={error}>{!customHelperText ? helperText : customHelperText}</FormHelperText>}
        </RadioGroup>
      )}
    </>
  );
};
