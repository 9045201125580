import React from "react";
import { Grid, Box, useMediaQuery, Theme, Typography, Button } from "@material-ui/core";
import { Form } from "src/screens/Public/Checkout/types";
import { FormRadioGroupCustom } from "src/lib/templates/components/FormRadioGroupCustom";
import { Emitter, CardData, PaymentModalName, CardType, CardName } from "src/app/models";
import { SimpleSelect } from "src/commons/components";
import { FormGenericControl } from "src/lib/templates";
import { useScrollToTopOnMount } from "src/commons";
import { PaymentCard } from "../PaymentCard";

export interface PaymentMethodFormProps {
  form: Form;
  loading: boolean;
  promotions: CardData[];
  emitters: Emitter[];
  cards: CardName[];
  payments: PaymentModalName[];
  isSelectedEmitter: boolean;
  isSelectedCard: boolean;
  disableNext: boolean;
  onClickStep: () => void;
  onBackStep: () => void;
  onChangeEmitter: () => void;
  disableCuotes: boolean;
}

export const PaymentMethodForm = ({
  form,
  loading,
  disableNext,
  emitters,
  isSelectedEmitter,
  isSelectedCard,
  cards,
  payments,
  onClickStep,
  onChangeEmitter,
  disableCuotes,
}: PaymentMethodFormProps) => {
  useScrollToTopOnMount();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <Box padding={isDownSm ? 2 : 3}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            Seleccioná el metodo de pago
          </Typography>
          <Box mt={2} mb={2}>
            <FormRadioGroupCustom
              row={!isDownSm}
              form={form}
              emisor
              valueKey="card_emiter_id"
              labelKey="image_url"
              marginRight="10px"
              marginBottom={isDownSm ? "2%" : 0}
              name="paymentMethod.emitter"
              renderComponent={PaymentCard}
              options={emitters}
              customHelperText="Debés seleccionar una opción"
              optionToValue={(value: string, options: Emitter[]) => {
                onChangeEmitter();
                return options.find((item) => item.card_emiter_id.toString() === value);
              }}
              valueToOption={(value: Emitter, options: Emitter[]) =>
                options.find((item) => item.card_emiter_id === value.card_emiter_id)?.card_emiter_id || ""
              }
            />
          </Box>
        </Grid>

        {isSelectedEmitter && (
          <Grid item>
            <Typography variant="h5" color="textPrimary">
              {!disableCuotes ? "Seleccioná el tipo" : "Seleccioná la tarjeta"}
            </Typography>
            <Box mt={1} mb={2}>
              <FormRadioGroupCustom
                loading={loading}
                form={form}
                valueKey="card_id"
                labelKey="nameOption"
                marginBottom={isDownSm ? "2%" : 0}
                name="paymentMethod.card"
                options={cards}
                customHelperText="Debés seleccionar una opción"
                optionToValue={(value: string, options: CardName[]) =>
                  options.map(({ nameOption, ...rest }) => rest).find((item) => item.card_id.toString() === value.toString())
                }
                valueToOption={(value: CardType, options: CardName[]) =>
                  options.find((item) => item.card_id.toString() === value.card_id.toString())?.card_id.toString() || ""
                }
              />
            </Box>
          </Grid>
        )}
        {disableCuotes && isSelectedCard && !loading && (
          <>
            <Grid item xs={12} sm={12} md={10} lg={6}>
              <Typography variant="h5" color="textPrimary">
                Seleccioná las cuotas
              </Typography>
              <Grid item md={12} xs={12}>
                <Box mt={2} mb={2}>
                  <FormGenericControl
                    form={form}
                    name="paymentMethod.payment"
                    component={SimpleSelect}
                    customHelperText="Debés seleccionar una opción"
                    componentProps={{
                      placeholder: "Seleccioná las cuotas",
                      content: payments,
                      valueKey: "id",
                      labelKey: "name",
                    }}
                    optionToValue={(value: string) => {
                      const newValue = payments.find((item) => item.id === value);
                      const { name, id, ...rest } = newValue || {};
                      return rest;
                    }}
                    valueToOption={(value: PaymentModalName) => {
                      const oldValue = `${value.installments} ${value.amount} ${value.original_amount}`;
                      return payments.find((item) => item.id === oldValue)?.id || "";
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        )}

        {isDownSm && (
          <Grid item xs={12}>
            <Button fullWidth disabled={disableNext} size="large" variant="contained" color="primary" onClick={onClickStep}>
              Continuar
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
