import React, { ChangeEvent } from "react";
import { Grid, Typography, Box, useMediaQuery, Theme, Button } from "@material-ui/core";
import { optionsDocentType, DocentType } from "src/screens/Public/Checkout/constant";
import { CommonFormInput } from "../CommonFormInput";
import { useScrollToTopOnMount } from "../../../../commons";
import { useInputsCommons } from "./useInputsCommons";
import { State, City } from "../../../models";
import { ComponentProps, Form } from "../../../../screens/Public/Checkout/types";
import { FormDatePicker, FormCheckbox, FormSelect, FormUploadFile, FormAutocompleteFetch } from "../../../../lib/templates";

export interface PersonalCreditInfoFormProps extends Omit<ComponentProps, "isResponsable"> {
  title: string;
  nameForm?: string;
  disableNext: boolean;
  invalidZidCode: boolean;
  provinces?: State[];
  cities?: City[];
  selectedProvince?: boolean;
  birthdayValid?: boolean;
  invalidDate?: boolean;
  loading?: boolean;
  loadingCities?: boolean;
  searchCityValue?: string;
  zipCodeLength: number;
  useSameDeliveryAddress: boolean;
  nameDelivery: string;
  showCheckbox?: boolean;
  onInputChangeCity: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const PersonalCreditInfoForm = ({
  title,
  nameForm = "applicantInfo",
  showCheckbox = false,
  nameDelivery,
  form,
  disableNext,
  invalidZidCode,
  provinces = [],
  cities = [],
  birthdayValid,
  invalidDate,
  selectedProvince,
  searchCityValue = "",
  loading,
  loadingCities,
  zipCodeLength = 0,
  onClickStep,
  useSameDeliveryAddress,
  onInputChangeCity,
}: PersonalCreditInfoFormProps) => {
  useScrollToTopOnMount();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const { personalInputA, personalInputB, deliveryInput, uploadInputs } = useInputsCommons({
    zipCodeLength,
    invalidZidCode,
    nameForm,
    nameDelivery,
  });

  return (
    <Box padding={isDownSm ? 2 : 3}>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <Box mb={1} paddingBottom={1} borderColor="other.stroke" borderBottom={isDownSm && "1px solid"}>
            <Typography variant="h5" color="textPrimary">
              {title}
            </Typography>
          </Box>
        </Grid>

        {nameForm === "guarantorInfo" && (
          <>
            {personalInputA.map((input) => (
              <CommonFormInput<Form>
                key={input.name}
                form={form}
                name={input.name}
                label={input.label}
                variant={input.variant}
                lg={input.lg}
                md={input.md}
                sm={input.sm}
                xs={input.xs}
                id={input.id}
                autoComplete={input.autoComplete}
                error={input.error}
                helperText={input.helperText}
              />
            ))}
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FormDatePicker
                form={form}
                fullWidth
                disableFuture
                openTo="year"
                error={birthdayValid}
                helperText={invalidDate ? "La fecha debe ser valida" : "Debes ser mayor de edad!"}
                name={`${nameForm}.dateOfBirty`}
                views={["year", "month", "date"]}
                format="DD/MM/YYYY"
                label={<Box color="text.disabled">Fecha de nacimiento</Box>}
                inputLabelProps={{ shrink: true }}
              />
            </Grid>
          </>
        )}

        {showCheckbox && (
          <Grid item xs={6}>
            <Box mb={useSameDeliveryAddress ? 0 : 2}>
              <FormCheckbox
                label={
                  <Typography variant="body2" color="inherit">
                    La dirección es la misma que la de envío
                  </Typography>
                }
                name="useDeliveryAddress"
                form={form}
              />
            </Box>
          </Grid>
        )}

        {nameForm === "docentInfo" && (
          <Grid item xs={6}>
            <FormSelect<DocentType>
              loading={loading}
              fullWidth
              form={form}
              label={<Box color="text.disabled">Tipo de Docente</Box>}
              name={`${nameForm}.docentType`}
              content={optionsDocentType}
              valueKey="id"
              labelKey="name"
              inputLabelProps={{ shrink: true }}
              helperText="El valor ingresado no es valido"
              optionToValue={(value, options) => options?.find((item) => item.id.toString() === value.toString())}
              valueToOption={(value, options) => options.find((option: DocentType) => option.id === value.id)?.id.toString()}
            />
          </Grid>
        )}

        {nameForm === "guarantorInfo" ? (
          personalInputB.map((input) => (
            <CommonFormInput<Form>
              key={input.name}
              form={form}
              name={input.name}
              label={input.label}
              variant={input.variant}
              lg={input.lg}
              md={input.md}
              sm={input.sm}
              xs={input.xs}
              id={input.id}
              autoComplete={input.autoComplete}
              error={input.error}
              helperText={input.helperText}
            />
          ))
        ) : nameForm === "applicantInfo" ? (
          <CommonFormInput<Form>
            key={personalInputB[personalInputB.length - 1].name}
            form={form}
            name={personalInputB[personalInputB.length - 1].name}
            label={personalInputB[personalInputB.length - 1].label}
            variant={personalInputB[personalInputB.length - 1].variant}
            lg={personalInputB[personalInputB.length - 1].lg}
            md={personalInputB[personalInputB.length - 1].md}
            sm={personalInputB[personalInputB.length - 1].sm}
            xs={personalInputB[personalInputB.length - 1].xs}
            id={personalInputB[personalInputB.length - 1].id}
            autoComplete={personalInputB[personalInputB.length - 1].autoComplete}
            error={personalInputB[personalInputB.length - 1].error}
            helperText={personalInputB[personalInputB.length - 1].helperText}
          />
        ) : null}

        {nameForm !== "docentInfo" && <Grid item xs={6} />}

        <Grid item lg={5} md={4} sm={6} xs={12}>
          <FormSelect<State>
            loading={loading}
            fullWidth
            form={form}
            label={<Box color="text.disabled">Provincia</Box>}
            name={`${nameDelivery}.province`}
            content={provinces}
            valueKey="id"
            labelKey="name"
            helperText="El campo Provincia es requerido"
            inputLabelProps={{ shrink: true }}
            showErrorAtInvalid
            disabled={useSameDeliveryAddress}
            optionToValue={(value, options) => options?.find((province) => province.id === value)}
            valueToOption={(value, options) => options.find((option: State) => option.id === value.id)?.id || searchCityValue}
          />
        </Grid>

        <Grid item lg={5} md={4} sm={6} xs={12}>
          <FormAutocompleteFetch
            disabled={!selectedProvince || useSameDeliveryAddress}
            name={`${nameDelivery}.city`}
            form={form}
            label={<Box color="text.disabled">Ciudad</Box>}
            options={cities}
            optionLabelKey="name"
            optionToValue={(option: City) => option}
            valueToOption={(value: City, options: City[]) =>
              searchCityValue?.toLocaleLowerCase() === value?.name?.toLocaleLowerCase() && searchCityValue?.length > 0 && options.length > 0
                ? options.find((option: City) => option.id === value.id)?.name
                : searchCityValue
            }
            helperText="El campo Ciudad es requerido"
            getOptionSelected={(option: City, value: City) => option.id === value?.id}
            onInputChange={onInputChangeCity}
            inputLabelProps={{ shrink: true }}
            loading={loadingCities}
            enabledOpenAutocomplete={searchCityValue.length > 1}
          />
        </Grid>

        {deliveryInput.map((input) => (
          <CommonFormInput<Form>
            key={input.name}
            form={form}
            name={input.name}
            label={input.label}
            variant={input.variant}
            lg={input.lg}
            md={input.md}
            sm={input.sm}
            xs={input.xs}
            id={input.id}
            autoComplete={input.autoComplete}
            error={input.error}
            helperText={input.helperText}
            disabled={useSameDeliveryAddress}
          />
        ))}

        {uploadInputs.map((input) => (
          <Grid item key={input.name} lg={input.lg} md={input.md} sm={input.sm} xs={input.xs} id={input.id}>
            <FormUploadFile form={form} name={input.name} label={input.label} helperText={input.helperText} error={input.error} />
          </Grid>
        ))}

        {isDownSm && (
          <Grid item xs={12}>
            <Button fullWidth disabled={disableNext} size="large" variant="contained" color="primary" onClick={onClickStep}>
              Continuar
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
