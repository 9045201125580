import React, { useState, useCallback } from "react";
import { Dialog, DialogContent, Box, Grid, Typography, Link as LinkMaterial, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CheckIcon } from "src/assets/images/svgs/CheckIcon";
import { CustomButtonProps } from "src/commons/components/CustomBotton";
import customServiceFormDialog from "src/commons/services/customServiceFormDialog";
import { Publication } from "src/app/models";
import { useWalletContext } from "src/context/WalletContext";
import { useSnackbar } from "notistack";
import { SalesNFTModalForm } from "./SalesNFTModalForm";

const SalesNFTModalFormDialog = customServiceFormDialog(SalesNFTModalForm);
interface SalesNFTModalProps {
  label?: string;
  item: Publication;
  component?: (props: any) => JSX.Element;
}

export const SalesNFTModal = ({ label = "Vender", item, component }: SalesNFTModalProps) => {
  const [openTxModal, setOpenTxModal] = useState(false);
  const [finished, setFinished] = useState(false);
  const [result, setResult] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();
  const { isConnected, strategy } = useWalletContext();

  const onSetPrice = useCallback(
    async (price: string) => {
      if (!isConnected) return;
      try {
        const res = await strategy?.setPriceNFT(item?.attributes.tokenId || "", price);
        setOpenTxModal(true);
        setResult(res);
        await res.wait();
        setFinished(true);
        enqueueSnackbar("NFT publicado", { variant: "success" });
      } catch (error) {
        console.log("error", JSON.stringify(error));
        enqueueSnackbar("Transacción cancelada", { variant: "info" });
        setOpenTxModal(false);
      }
    },
    [item, isConnected, strategy, enqueueSnackbar],
  );

  const onHandleSale = useCallback(async () => {
    const confirm: any = await SalesNFTModalFormDialog.show({
      item,
    });

    if (confirm) {
      onSetPrice(confirm.price);
    }
  }, [item, onSetPrice]);

  const Comp = component;

  return (
    <>
      {Comp ? <Comp onClick={onHandleSale}>{label}</Comp> : <CustomButtonProps name={label} onClick={onHandleSale} />}
      <Dialog open={openTxModal} fullWidth maxWidth="xs">
        <Box
          component={DialogContent}
          boxShadow=" inset 0px 0px 8px 2px rgba(112, 167, 54, 0.4)"
          display="block"
          alignItems="center"
          justifyContent="center"
          position="relative"
          padding="32px 32px"
        >
          <Grid container justify="center" spacing={4}>
            <Grid item container justify="center">
              {finished ? (
                <Grid item xs={12} container justify="center">
                  <Box height="64px" alignItems="center">
                    <CheckIcon />
                  </Box>
                </Grid>
              ) : (
                <Box component={(props: any) => <CircularProgress size={56} {...props} />} color="primary.main" />
              )}
            </Grid>
            <Grid item xs={12} container justify="center" spacing={1}>
              <Grid item>
                <Typography variant="subtitle2">{finished ? "Transacción terminada" : "Procesando solicitud"}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container justify="center" spacing={1}>
              <Grid item xs={12} container justify="center">
                <Box component="div" color="neutral.2">
                  <Typography variant="body2">TRANSACTION HASH</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} container justify="center">
                <Grid item zeroMinWidth>
                  <LinkMaterial
                    href={`https://ropsten.etherscan.io/tx/${result?.hash}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#94f034" }}
                  >
                    <Typography noWrap variant="body2" color="primary">
                      {result?.hash}
                    </Typography>
                  </LinkMaterial>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {finished && (
            <Box position="absolute" onClick={() => setOpenTxModal(false)} top="16px" right="16px">
              <CloseIcon />
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
};
