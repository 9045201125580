import React from "react";
import { Theme } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Typography from "@material-ui/core/Typography";
import { useMediaQuery, StepLabel, Box, Grid } from "@material-ui/core";
import { StepRecord } from "src/app/views";

export interface StepperViewProps {
  completedSteps: Set<number>;
  onClickStep: (value: number) => void;
  activeStep: number;
  steps: StepRecord[];
}

export const StepperView = ({ steps, activeStep, completedSteps, onClickStep }: StepperViewProps) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const isStepComplete = (step: number) => completedSteps.has(step);
  const handleStep = (step: number) => () => {
    onClickStep(step);
  };

  return (
    <Grid>
      <Stepper activeStep={activeStep} nonLinear orientation="horizontal">
        {steps.map((step, index) => (
          <Step key={step.label} completed={isStepComplete(index)}>
            <StepButton onClick={handleStep(index)} disabled={!isStepComplete(index)} completed={isStepComplete(index)}>
              <StepLabel>
                {!isDownSm && (
                  <Typography variant={isDownSm ? "body1" : "body1"} color="textPrimary">
                    <Box component="span" fontWeight={activeStep === index ? "bold" : ""}>
                      {step.label}
                    </Box>
                  </Typography>
                )}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
};
