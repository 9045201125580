import { ModalServices } from "../../ui/dialogs/ModalServices";
import asService from "../../hoc/asService";

const options = {
  activeProp: "open",
  resolveProp: "onConfirm",
  rejectProp: "onCancel",
  forceResolveOnReject: true,
  rejectValue: false,
};
export default asService(options)(ModalServices);
