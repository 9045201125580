import { useCallback } from "react";
import { SessionChangeEmitter, userGuest, validateUser } from "src/platform/libby/session";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { LoginStatusResponse, SingIn, OAuthProvider } from "src/app/models";
import { useAccountDAO } from "src/app/business";
import { GoogleApi } from "src/app/services";
import { Message } from "src/commons/components/ToastMessage/Message";

interface SendLoginProps {
  redirectOnSuccess?: string;
  msgOnSuccess?: string;
}

export const useLogin = (props?: SendLoginProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const accounDAO = useAccountDAO();

  const { redirectOnSuccess = "", msgOnSuccess = "" } = props || {};

  const singIn = useCallback(
    async (values: SingIn) => {
      let Session: LoginStatusResponse = { isGuest: false, user: userGuest };
      try {
        const to_loging: SingIn = {
          provider: "user",
          token_id: values.token_id,
          email: values.email,
          password: values.password,
          username: values.email,
        };
        switch (values.provider) {
          case OAuthProvider.GOOGLE:
            to_loging.provider = OAuthProvider.GOOGLE;
            to_loging.token_id = await GoogleApi.singIn();
            break;
          case OAuthProvider.FACEBOOK:
            to_loging.provider = OAuthProvider.FACEBOOK;
            to_loging.token_id = values.token_id;
            break;
          default:
            break;
        }
        if ((values.provider === OAuthProvider.GOOGLE || values.provider === OAuthProvider.FACEBOOK) && !to_loging.token_id) return;
        Session = await accounDAO.login(to_loging);
        if (Session.isGuest || !validateUser(Session.user)) {
          enqueueSnackbar("Usuario o clave incorrectos", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(msgOnSuccess || `Bienvenido ${Session?.user?.name || ""}`, {
            content: (key, message) => Message({ key, message, type: "login" }),
          });
          history.push(redirectOnSuccess || "/");
        }
      } catch (e) {
        console.log(e);
        if (e?.error !== "popup_closed_by_user") {
          enqueueSnackbar("Usuario o clave incorrectos", {
            variant: "error",
          });
        }
      } finally {
        const { user } = Session;
        const isLogged: boolean = !!user && user.isGuest === false;
        SessionChangeEmitter.emitChange({ isLogged, user });
      }
    },
    [history, accounDAO, redirectOnSuccess, msgOnSuccess, enqueueSnackbar],
  );

  return { singIn };
};
