/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded, NavigateBeforeRounded, NavigateNextRounded } from "@material-ui/icons";
import { Box, IconButton, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useInterval } from "../BannerCarousel/hooks";

type DataType = {
  element: JSX.Element;
  url: string;
  id: number;
};

type ScrollCarousel = {
  data: DataType[];
};

type SideScroll = {
  element: any;
  directionInner?: "left" | "right";
  distance?: number;
  speed?: number;
  step?: number;
  reset?: boolean;
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  containerItems: {
    overflowX: "auto",
    display: "-webkit-box",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollBehavior: "smooth",
    "&>:nth-child(1)": {
      paddingRight: "8px",
      paddingLeft: "0",
    },
    "&>:nth-child(2)": {
      paddingRight: "4px",
      paddingLeft: "4px",
    },
    "&>:nth-child(3)": {
      paddingLeft: "8px",
      paddingRight: "0",
    },
  },
  itemBox: {
    width: "33.3%",
    paddingTop: "0px",
  },
  itemBoxSm: {
    width: "100%",
    padding: "0px!important",
  },
}));

export const ScrollCarouselAuto = ({ data }: ScrollCarousel) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const history = useHistory();
  const [direction, setDirection] = useState("right");
  const carouselRef = useRef<HTMLHeadingElement>(null);
  const containerRef = useRef<HTMLHeadingElement>(null);
  const carouselContainer = data.map((item) => (
    <Box
      className={isDownSm ? classes.itemBoxSm : classes.itemBox}
      onClick={() => history.push(item.url || "/search")}
      key={item.url}
      pl="1px"
      pr={2}
      pt={2}
    >
      {item.element}
    </Box>
  ));
  const [showArrows, setShowArrows] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = data.length;

  useInterval(() => {
    const totalExtra = maxSteps - 3;
    if (isDownSm) {
      if (maxSteps - 1 === activeStep) {
        sideScroll({ element: carouselRef.current });
        if (direction === "left") {
          setDirection("right");
          setActiveStep(1);
        } else {
          setDirection("left");
          setActiveStep(1);
        }
      } else {
        sideScroll({ element: carouselRef.current });
        setActiveStep(activeStep + 1);
      }
    } else {
      if (totalExtra === activeStep) {
        sideScroll({ element: carouselRef.current });
        if (direction === "left") {
          setDirection("right");
          setActiveStep(1);
        } else {
          setDirection("left");
          setActiveStep(1);
        }
      } else {
        sideScroll({ element: carouselRef.current });
        setActiveStep(activeStep + 1);
      }
    }
  }, 5000);

  const sideScroll = ({ element, speed = 50, distance = 5, step = 150, reset = false }: SideScroll) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      const totalWidth = element.scrollWidth - element.clientWidth;
      const elementSonWidth = isDownSm ? element.clientWidth / maxSteps : element.clientWidth / 3;
      if (isDownSm) {
        if (reset) {
          element.scrollLeft -= element.clientWidth;
        }
        if (direction === "left") {
          element.scrollLeft -= element.clientWidth;
        } else {
          element.scrollLeft += element.clientWidth;
        }
        scrollAmount += element.clientWidth;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
      } else {
        if (reset) {
          element.scrollLeft -= totalWidth;
        }
        if (direction === "left") {
          element.scrollLeft -= parseInt(elementSonWidth.toFixed());
        } else {
          element.scrollLeft += parseInt(elementSonWidth.toFixed());
        }
        scrollAmount += parseInt(elementSonWidth.toFixed());
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
      }
    }, speed);
  };

  useEffect(() => {
    if (carouselRef.current !== null && containerRef.current !== null && carouselRef.current.scrollWidth > containerRef.current?.offsetWidth) {
      setShowArrows(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselRef, containerRef, setShowArrows, window.innerWidth]);

  return (
    <>
      {isDownSm ? (
        <div ref={containerRef} className={classes.container} onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
          {showArrows && !isDownSm && (
            <ArrowScrollCarousel direction="left" onClick={() => sideScroll({ element: carouselRef.current })} parentOnHover={onHover} />
          )}
          <div ref={carouselRef} className={classes.containerItems}>
            {carouselContainer}
          </div>
          {showArrows && !isDownSm && (
            <ArrowScrollCarousel direction="right" onClick={() => sideScroll({ element: carouselRef.current })} parentOnHover={onHover} />
          )}
        </div>
      ) : (
        <div ref={containerRef} className={classes.container} onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
          {showArrows && !isDownSm && (
            <ArrowScrollCarousel
              direction="left"
              onClick={() => sideScroll({ element: carouselRef.current, directionInner: "left" })}
              parentOnHover={onHover}
            />
          )}
          <div ref={carouselRef} className={classes.containerItems}>
            {carouselContainer}
          </div>
          {showArrows && !isDownSm && (
            <ArrowScrollCarousel
              direction="right"
              onClick={() => sideScroll({ element: carouselRef.current, directionInner: "right" })}
              parentOnHover={onHover}
            />
          )}
        </div>
      )}
    </>
  );
};

interface ArrowProps {
  direction: "left" | "right" | "up" | "down";
  disabled?: boolean;
  onClick?: () => void;
  parentOnHover: boolean;
  color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
}

const useStylesArrow = makeStyles((theme) => ({
  arrow: {
    display: "none",
    boxShadow: theme.shadows[1],
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
      boxShadow: theme.shadows[3],
    },
  },
  onHoverClass: {
    display: "block",
  },
}));

export const ArrowScrollCarousel = ({ direction, disabled, onClick, parentOnHover, color = "secondary" }: ArrowProps) => {
  const classes = useStylesArrow();

  let icon;
  switch (direction) {
    case "left":
      icon = <NavigateBeforeRounded color={color} fontSize="large" />;
      break;
    case "up":
      icon = <KeyboardArrowUpRounded color={color} fontSize="large" />;
      break;
    case "right":
      icon = <NavigateNextRounded color={color} fontSize="large" />;
      break;
    case "down":
      icon = <KeyboardArrowDownRounded color={color} fontSize="large" />;
      break;
    default:
      break;
  }

  return (
    <Box position={direction === "left" || direction === "right" ? "absolute" : "relative"} zIndex={5} right={direction === "right" ? "-1%" : ""}>
      <IconButton
        disabled={disabled}
        onClick={onClick}
        classes={{ root: classes.arrow }}
        className={`${classes.arrow} ${parentOnHover && classes.onHoverClass}`}
      >
        {icon}
      </IconButton>
    </Box>
  );
};
