import { LibbyFetchByIdReturn, useLibbyFetchById } from "./useLibbyFetchById";

export const makeEntityFetchById =
  <T>(daoName: string) =>
  (id: string | number, aspect?: string): LibbyFetchByIdReturn<T> =>
    useLibbyFetchById<T>({
      daoName,
      id,
      aspect,
    });
