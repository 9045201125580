import { Box } from "@material-ui/core";
import React from "react";
import { MainButton } from "src/commons";
import { MainButtonTypesProps } from "src/commons/components/MainButton";

interface ProductInfoPanelBuyProps {
  onBuyClick: () => void;
  onAddToCartClick: () => void;
  disabledButton: boolean;
}

export const ProductInfoPanelBuy = ({ onBuyClick, onAddToCartClick, disabledButton }: ProductInfoPanelBuyProps) => {
  const buttonsBuy: MainButtonTypesProps[] = [
    {
      title: "Comprar",
      handleOnClick: onBuyClick,
      textTransform: "uppercase",
    },
    {
      title: "Agregar al carrito",
      handleOnClick: onAddToCartClick,
      textTransform: "uppercase",
      color: "inherit",
      variant: "outlined",
    },
  ];

  return (
    <>
      {buttonsBuy.map((buttonBuy: MainButtonTypesProps) => (
        <Box py={1} key={buttonBuy.title?.toString()}>
          <MainButton
            title={buttonBuy.title}
            handleOnClick={buttonBuy.handleOnClick}
            textTransform="uppercase"
            fontWeight={700}
            widthComplete
            variant={buttonBuy.variant}
            disabled={disabledButton}
            color={buttonBuy?.color}
          />
        </Box>
      ))}
    </>
  );
};
