import React from "react";
import { usePublicationByZone } from "./usePublicationByZone";
import { usePublicationNameByZone } from "./usePublicationNameByZone";
import { LibbyCallReturn } from "../../../lib/libby/hooks";
import { Publication } from "../../models";

export interface PublicationByZoneProps {
  children: (response: LibbyCallReturn<Publication[]>, title: LibbyCallReturn<string>) => JSX.Element | JSX.Element[];
  zone: string;
}

export const PublicationByZone = ({ children, zone }: PublicationByZoneProps) => {
  const response = usePublicationByZone(zone);
  const title = usePublicationNameByZone(zone);

  return <>{children(response, title)}</>;
};
