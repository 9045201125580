import React, { ReactNode } from "react";
import { useField } from "react-final-form-hooks";
import { Grid, TextField, InputLabelProps, Box, InputAdornment, FormHelperText } from "@material-ui/core";
import { FormApi } from "final-form";
import { Loading } from "src/commons";

// FIXME: type form props
export interface FormCardExpiryDateProps {
  nameMonth: string;
  nameYear: string;
  form: FormApi<any, Partial<any>>;
  placeholderMonth?: string;
  placeholderYear?: string;
  label?: ReactNode;
  disabled?: boolean;
  variant?: "standard" | "filled" | "outlined";
  inputLabelProps?: Partial<InputLabelProps>;
  size?: "small" | "medium";
  loading?: boolean;
  cardExpirationErrorValidation: boolean;
  cardExpirationErrorMessageValidation: string;
}
// FIXED: this component
export const FormCardExpiryDate = ({
  nameMonth,
  nameYear,
  form,
  loading = false,
  disabled,
  label,
  placeholderMonth = "mm",
  placeholderYear = "yy",
  size = "small",
  cardExpirationErrorValidation,
  cardExpirationErrorMessageValidation,
}: FormCardExpiryDateProps) => {
  const fieldMonth = useField(nameMonth, form);
  const fieldYear = useField(nameYear, form);

  const isNumeric = /^\d*$/;

  return (
    <Grid>
      {loading ? (
        <Loading size={30} />
      ) : (
        <Grid container direction="row">
          <Grid item xs={6}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Box width="5px"> </Box>
                  </InputAdornment>
                ),
              }}
              onFocus={fieldMonth.input.onFocus}
              label={label}
              disabled={disabled}
              size={size}
              placeholder={placeholderMonth}
              value={fieldMonth.input.value}
              onChange={(e) => {
                if (!isNumeric.test(e.target.value)) return;
                if (e.target.value.length < (fieldMonth.input.value as string).length) {
                  fieldMonth.input.onChange(e.target.value);
                }
                if ((fieldMonth.input.value as string).length < 2) {
                  fieldMonth.input.onChange(e.target.value);
                }
              }}
              id="card-expiry-date-input-month"
              InputLabelProps={{ shrink: true }}
              autoComplete="cc-exp-month"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">/</InputAdornment>,
              }}
              label=" "
              size={size}
              placeholder={placeholderYear}
              disabled={disabled}
              onFocus={fieldYear.input.onFocus}
              value={fieldYear.input.value}
              onChange={(e) => {
                if (!isNumeric.test(e.target.value)) return;
                if (e.target.value.length === 4) {
                  const lastTwoYearDigits = e.target.value.toString().substr(2, 2);
                  fieldYear.input.onChange(lastTwoYearDigits);
                } else {
                  if (e.target.value.length < (fieldYear.input.value as string).length) {
                    fieldYear.input.onChange(e.target.value);
                  }
                  if ((fieldYear.input.value as string).length < 2) {
                    fieldYear.input.onChange(e.target.value);
                  }
                }
              }}
              id="card-expiry-date-input-year"
              InputLabelProps={{ shrink: true }}
              autoComplete="cc-exp-year"
            />
          </Grid>
          <FormHelperText error={cardExpirationErrorValidation}>{cardExpirationErrorMessageValidation}</FormHelperText>
        </Grid>
      )}
    </Grid>
  );
};
