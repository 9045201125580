import { Category, Publication } from "src/app/models";
import { EcommerceDAO } from "./EcommerceDAO";
import { SHOW_PRODUCTS_WITH_OUT_STOCK } from "../../../config";

export class CategoryDAO extends EcommerceDAO<Category> {
  constructor() {
    super("category", "idCategory");
  }

  async getAll(): Promise<Category[]> {
    const response = await this.fetchData(`/category/@ALL@`);
    return response.json();
  }

  async getActiveCategories(): Promise<Category[]> {
    const data = await this.getAll();
    const categories: Category[] = [];
    for (let i = 0; i < data.length; i++) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const publications = await this.getCategoryPublications(data[i].id);
        if (publications.length) categories.push(data[i]);
      } catch (e) {
        console.log(``);
      }
    }
    return categories;
  }

  async getCategoryPublications(id: string): Promise<Publication[]> {
    const response = await this.fetchData(`/category/${id}`);
    let publications: Publication[] = await response.json();
    if (!SHOW_PRODUCTS_WITH_OUT_STOCK) {
      publications = publications.filter((publi) => publi.type === "2" || publi.has_stock);
    }
    return publications;
  }
}
