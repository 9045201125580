export enum SITE_URL {
  NOSOTROS = "/nosotros",
  COMO_COMPRAR = "/como-comprar",
  POLITICAS_DEVOLUCIONES = "/devoluciones",
  PREGUNTAS_FRECUENTES = "/preguntas-frecuentes",
  SITE_FACEBOOK = "#",
  SITE_INSTAGRAM = "#",
  SITE_TWITTER = "#",
  SMART_CONTRACT = "https://ropsten.etherscan.io/token/",
  SITE_FIFA = "https://www.fifa.com/es",
  SITE_AFA = "https://www.afa.com.ar/es/",
  SITE_CONMEBOL = "https://conmebollibertadores.com/",
}
