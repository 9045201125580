import React from "react";
import { Box } from "@material-ui/core";
import { Input } from "src/app/components";

export const TextInput = ({ label, value }: { label: string; value: string }) => (
  <Input
    InputProps={{ readOnly: true }}
    variant="standard"
    size="small"
    defaultValue={value}
    positionLabel="inner"
    InputLabelProps={{
      shrink: true,
    }}
    label={
      <Box color="text.disabled" minWidth="104px">
        {label}
      </Box>
    }
    color="primary"
  />
);
