import React from "react";
import { useField } from "react-final-form-hooks";
import { makeStyles } from "@material-ui/core";
import { primary } from "src/theme/colors";

// FIXME: type form props
export interface FormRadioProps {
  name: string;
  form: any;
  value: string;
}

const useStyles = makeStyles(() => ({
  checkBox: {
    color: primary.lightBlue,
    marginTop: "6px",
    marginRight: 5,
  },
}));

export const FormRadio = ({ name, form, value }: FormRadioProps): JSX.Element => {
  const classes = useStyles();
  const radio = useField(name, form);
  return (
    <>
      <input type="radio" {...radio.input} value={value} checked={radio.input.value === value} className={classes.checkBox} />
    </>
  );
};
