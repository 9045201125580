import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../../lib/libby/generator";
import { Publication } from "../../models";
import { businessOptions } from "../businessOptions";

const DAO_NAME = "publication_by_zone";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "idPublicationByZone",
      labelKey: "descripcion",
    },
    withSelectedChips: {
      idKey: "idPublicationByZone",
      labelKey: "descripcion",
    },
  },
};

const optionsToUse = !businessOptions.PublicationByZone
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PublicationByZone,
      hoc: { ...defaultOptions.hoc, ...businessOptions.PublicationByZone.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Publication>(DAO_NAME, optionsToUse);

// hooks
export const usePublicationByZoneFetchById = fetchById;
export const usePublicationByZoneLibbyCall = libbyCall;
export const usePublicationByZoneDAO = libbyDAO;
export const usePublicationByZoneLibbyFetch = libbyFetch;

// components/hoc
export const PublicationByZoneAutocomplete = entityAutocomplete;
export const PublicationByZoneCall = renderPropCall;
export const PublicationByZoneById = renderPropFetchById;
export const PublicationByZoneSelect = simpleSelect;
export const PublicationByZoneAccordionSelector = accordionSelector;
export const PublicationByZoneSelectedChips = selectedChips;

// context
// ByIdContext
export const usePublicationByZoneByIdContext = useByIdContext;
export const PublicationByZoneByIdProvider = ByIdProvider;
export const PublicationByZoneByIdContext = ByIdContext;
// GeneralContext
// export const usePublicationByZoneContext = useGeneralContext;
// export const PublicationByZoneProvider = GeneralProvider;
// export const PublicationByZoneContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
