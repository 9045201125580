import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { TimerBomb } from "src/app/models";
import { CSSConfig } from "src/app/skeletons/components/SkeletonTimerCount";
import { PromotionalBombView } from "./PromotionalBombView";

interface PromotionalBombProps {
  title: string;
  subTitle: string;
  redirecTo?: string;
  timeToEnd: Date;
  cssConfig: CSSConfig;
  stylesBg: any;
}

const initialTimerLeft: TimerBomb = { days: "00", hours: "00", minutes: "00", seconds: "00" };
export const PromotionalBomb = ({ title, subTitle, timeToEnd, redirecTo, cssConfig, stylesBg }: PromotionalBombProps) => {
  const [showTimer, setShowTimer] = useState(true);
  const dateToday = moment();

  const calculateTimeLeft = useCallback(() => {
    if (moment(timeToEnd).diff(dateToday) < 0) {
      if (showTimer) setShowTimer(false);
      return initialTimerLeft;
    }
    const valueDays = moment(timeToEnd).diff(dateToday, "days");
    const days = valueDays < 10 ? `0${valueDays}` : valueDays.toString();
    const valueHours = moment(timeToEnd).diff(dateToday, "hours") % 24;
    const hours = valueHours < 10 ? `0${valueHours}` : valueHours.toString();
    const valueMinutes = moment(timeToEnd).diff(dateToday, "minutes") % 60;
    const minutes = valueMinutes < 10 ? `0${valueMinutes}` : valueMinutes.toString();
    const valueSeconds = Math.floor((moment(timeToEnd).diff(dateToday) / 1000) % 60);
    const seconds = valueSeconds < 10 ? `0${valueSeconds}` : valueSeconds.toString();
    return { days, hours, minutes, seconds };
  }, [timeToEnd, dateToday, showTimer]);

  const [timeLeft, setTimeLeft] = useState<TimerBomb>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  }, [calculateTimeLeft]);

  return <>{showTimer ? <PromotionalBombView timer={timeLeft} {...{ title, subTitle, redirecTo, cssConfig, stylesBg }} /> : null}</>;
};
