import React from "react";
import * as MuiIcon from "@material-ui/icons";

export type NameMuiIcon = keyof typeof MuiIcon;
interface MuiIconProps {
  name: NameMuiIcon;
  props?: any;
}

export const DinamycMuiIcon = ({ name, props }: MuiIconProps) => {
  const Comp = MuiIcon[name];
  return Comp ? <Comp {...props} /> : <></>;
};
