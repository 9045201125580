import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

interface HighLightPromoProps {
  highlights: Array<{ name: string; color: string }>;
  isAbsolute?: boolean;
  semiBorderRadius?: boolean;
  invert?: boolean;
  top?: number;
  left?: boolean;
  productId: string | number;
}

const useStyles = makeStyles(() => ({
  highlight: {
    marginBottom: "0px",
    display: "flex",
    justifyContent: "center",
  },
}));

export const HighLightPromo = ({
  semiBorderRadius = true,
  invert = false,
  highlights,
  isAbsolute = true,
  top = 0,
  left = true,
  productId,
}: HighLightPromoProps) => {
  const classes = useStyles();

  return (
    <Box position={isAbsolute ? "absolute" : "relative"} top={top} left={left && 0} right={!left && 0}>
      <Grid container direction="column">
        {highlights?.map((highlight, index) => (
          <Box
            mt={index !== 0 ? 1 : 0}
            key={highlight.name}
            borderRadius={semiBorderRadius ? (invert ? "5px 0px 5px 0px" : "0px 5px 0px 5px") : "5px"}
            color={highlight.color?.split("/")[1] || "white"}
            flexDirection="column"
            bgcolor={highlight.color?.split("/")[0] || "success.main"}
            p="3px 12px 4px 12px"
            width={isAbsolute ? "auto" : "fit-content"}
          >
            <Typography className={isAbsolute ? "" : classes.highlight} gutterBottom variant="caption" id={`${productId.toString()}-highlight`}>
              {highlight.name}
            </Typography>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};
