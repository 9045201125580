import React, { useMemo } from "react";
import { CategoryContext, CategoryContextValue } from "./CategoryContext";
import { useCategoryLibbyCall } from "./CategoryBusinessGenerated";
import { Category } from "../../models";

export interface CategoryProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const CategoryProvider = ({ children }: CategoryProviderProps) => {
  const { working, data } = useCategoryLibbyCall({ methodName: "getActiveCategories" });
  const value = useMemo<CategoryContextValue>(
    () => ({
      data: (data as Category[]) || [],
      working,
    }),
    [data, working],
  );
  return <CategoryContext.Provider value={value}>{children}</CategoryContext.Provider>;
};
