import { Box, Card } from "@material-ui/core";
import React from "react";
import { verticalBannerCardStyles as useStyles } from "src/app/views/styles";
import { UserAvatar } from "src/commons/components";

interface BannerVerticalCardProps {
  placement: number | undefined;
  maxItemHeight: boolean;
  setActiveIndex: () => void;
  activeCard: boolean;
  item: any;
}

export const BannerVerticalCard = ({ placement, maxItemHeight, setActiveIndex, activeCard, item }: BannerVerticalCardProps) => {
  const classes = useStyles({ placement, maxItemHeight, activeCard });

  return (
    <Box className={classes.carouselItem} key={item.id} top="147px" onClick={setActiveIndex} position="absolute">
      {item.isImage ? (
        <Card className={classes.card}>
          <UserAvatar avatarWidth={70} avatarHeight={60} img={item.content.image} variant="square" />
        </Card>
      ) : (
        item.introline
      )}
    </Box>
  );
};
