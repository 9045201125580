import { ModalForm, ModalFormProps } from "../../../../ui/dialogs";
import asService, { IShow } from "../../../../hoc/asService";
import { AnyObject } from "../../../../types/types";

const Service = asService({
  activeProp: "open",
  resolveProp: "onConfirm",
  rejectProp: "onCancel",
  forceResolveOnReject: true,
  rejectValue: null,
  maxWidth: "sm",
})(ModalForm);

export type FormDialogCreateProps<T, S> = Pick<
  ModalFormProps<T, S>,
  "customTheme" | "component" | "title" | "confirmButtonText" | "cancelButtonText" | "metadata" | "preConfirm" | "maxWidth"
>;

export type OpenOptions = Partial<Omit<ModalFormProps, "initialValue" | "metadata" | "open" | "onConfirm" | "onCancel">>;

export class FormDialog<T, S = AnyObject> {
  private service: IShow;

  constructor(props: FormDialogCreateProps<T, S>) {
    this.service = Service.create(props);
  }

  open(initialValue: T, metadata?: S, options: OpenOptions = {}) {
    const props: AnyObject = { initialValue, ...options };
    if (metadata) {
      const createProps = this.service.getCreateProps();
      props.metadata = createProps.metadata ? { ...createProps.metadata, ...metadata } : metadata;
    }
    return this.service.show(props);
  }

  static create<T, S = AnyObject>(props: FormDialogCreateProps<T, S>): FormDialog<T, S> {
    return new FormDialog(props);
  }
}
