import React, { useMemo, useCallback } from "react";
import { Theme, useMediaQuery, Container, Box, Grid, Typography, Card, Divider, Button } from "@material-ui/core";
import { useBreadcrumbs, Loading, OptionsModalOption, OptionsModal } from "src/commons";
import { AppBreadcrumbsApp } from "src/app/views";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { PurchaseItem } from "src/app/models";
import { Link, useHistory, useParams } from "react-router-dom";
import { useScrollToTopOnMount } from "src/commons/hooks/useScrollToTopUnMount";
import { IMAGE_BASE_URL } from "src/config";
import { getTextComponent } from "src/screens/Private/Profile/common";
import { ecommerceConfig } from "src/config/ecommerce";
import { usePurchasesLibbyCall } from "src/app/business/PurchasesItem";
import moment from "moment";
import { getDevileryState } from "../../common/ShipmentState";

export const PurchaseItemDetails = () => {
  useScrollToTopOnMount();
  const { id } = useParams<{ id: string }>();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isBw768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));
  const history = useHistory();

  const { data: [item] = [], working } = usePurchasesLibbyCall<PurchaseItem[]>({
    methodName: "getPurchasesById",
    params: [id || ""],
  });

  const links = useBreadcrumbs({
    links: isDownSm
      ? [
          { label: "Compra", url: "/dashboard" },
          { label: "Compras realizada", url: "/dashboard/purchases" },
          { label: "Mi cuenta", url: "/dashboard/purchases/details" },
        ]
      : [
          { label: "Mi cuenta", url: "/dashboard" },
          { label: "Compras", url: "/dashboard/purchases" },
          { label: "Compra realizada", url: "/dashboard/purchases/details" },
        ],
  });

  const optionsMenu = useMemo<OptionsModalOption<PurchaseItem>[]>(
    () => [
      {
        label: getTextComponent("Comprar de nuevo", "primary.main", "subtitle2"),
        onClick: (data) => history.push(`/product/${data.publication_id}`),
        hide: false,
        disable: false,
        id: 3,
      },
    ],
    [history],
  );

  const dataOrderShipment = item && getDevileryState(item);

  const handleRepentance = useCallback(() => {
    history.push({
      pathname: "/cancelar-compra",
      state: { payment_id: item.payment.payment_id },
    });
  }, [item, history]);

  return (
    <Container maxWidth="lg">
      <Box px={isDownSm ? 0 : "10%"} mt={3}>
        <AppBreadcrumbsApp
          links={links}
          iconSeparator={<Box component="div">/</Box>}
          iconGoBack={<ChevronLeftIcon color="primary" />}
          invert
          msgReturn=" "
          noMargin
        />

        <Grid container item spacing={isDownSm ? 0 : 2} xs={12} sm={isBw768 ? 8 : 12} md={8}>
          {working ? (
            <Loading />
          ) : (
            item && (
              <Box mb={2}>
                <Box
                  justifyItems="center"
                  width="100%"
                  component={Card}
                  maxHeight={isDownSm ? 183 : 113}
                  maxWidth={678}
                  borderRadius={8}
                  boxShadow={3}
                  display="flex"
                  mb={2}
                  alignItems="center"
                >
                  <Grid
                    component="img"
                    width="100px"
                    height={isDownSm ? "100px" : undefined}
                    src={`${IMAGE_BASE_URL}/Products/${item?.sku}/main.jpg`}
                  />
                  <Box py={2} px={1} width="100%" display="flex">
                    <Grid
                      container
                      justify={isDownSm ? undefined : "space-between"}
                      {...(!isDownSm ? {} : { direction: "column" })}
                      spacing={isDownSm ? 0 : 1}
                      alignItems={isDownSm ? undefined : "center"}
                    >
                      <Grid item xs={isDownSm ? undefined : 11}>
                        <Grid item xs={12} container alignItems="center">
                          {isDownSm ? (
                            <Typography
                              component="div"
                              align="left"
                              style={{
                                width: "100%",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical",
                                background: "#fff",
                              }}
                              variant={isDownSm ? "subtitle2" : "subtitle1"}
                              color="textPrimary"
                            >
                              {item.product}
                            </Typography>
                          ) : (
                            <Box width="auto" display="inline-grid">
                              <Grid item zeroMinWidth>
                                <Link
                                  style={{ color: ecommerceConfig.ecommercePalette.materialUi.text.secondary }}
                                  to={`/product/${item.publication_id}`}
                                >
                                  <Typography noWrap variant="subtitle1" color="textPrimary">
                                    {item.product}
                                  </Typography>
                                </Link>
                              </Grid>
                            </Box>
                          )}
                        </Grid>

                        <Typography variant="body2" color="textSecondary">
                          {`x${item.quantity} unidad${item.quantity > 1 ? "es" : ""}`}
                        </Typography>
                      </Grid>

                      {isDownSm ? (
                        <Grid item container>
                          <Box width="100%" pb={1} pt={1}>
                            <Divider />
                          </Box>
                        </Grid>
                      ) : (
                        <Grid item xs={1} container alignItems="center" justify="flex-end">
                          <OptionsModal options={optionsMenu} data={item} color="default" />
                        </Grid>
                      )}

                      {isDownSm && (
                        <Grid item container>
                          <Grid item>
                            <Button size="small" variant="contained" color="primary">
                              Comprar de nuevo
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Box>

                <Box justifyItems="center" component={Card} borderRadius={8} boxShadow={3} display="content" mb={2}>
                  <Box padding={isDownSm ? 2 : 3}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Box color={dataOrderShipment.color}>
                          <Typography variant="subtitle2">{dataOrderShipment.state}</Typography>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Typography variant={isDownSm ? "subtitle1" : "h6"} color="textPrimary">
                          {dataOrderShipment.title}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant={isDownSm ? "body2" : "body1"} color="textSecondary">
                          {dataOrderShipment.subtitle}
                        </Typography>
                      </Grid>
                      <Grid item>{dataOrderShipment.caption}</Grid>

                      <Grid item>
                        <Box pb={1} pt={1}>
                          <Divider />
                        </Box>
                      </Grid>

                      <Grid item>
                        <Button size={isDownSm ? "small" : "medium"} onClick={handleRepentance} variant="contained" color="primary">
                          Arrepentimiento
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box justifyItems="center" component={Card} borderRadius={8} boxShadow={3} display="content">
                  <Box padding={isDownSm ? 2 : 3}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Typography variant={isDownSm ? "h6" : "h5"} color="textPrimary">
                          Resumen de compra
                        </Typography>
                        <Typography variant={isDownSm ? "body2" : "body1"} color="textSecondary">
                          {`Fecha: ${moment(item.created_at).format("DD/MM/YYYY")} | N° de compra: ${item.sterOrder?.soNumber || "-"}`}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Box pb={1} pt={1}>
                          <Divider />
                        </Box>
                      </Grid>

                      <Grid item>
                        <Typography variant={isDownSm ? "h6" : "h5"} color="textPrimary">
                          Pago
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant={isDownSm ? "body2" : "body1"} color="textSecondary">
                          {`Tarjeta n° xxxx-xxxx-xxxx-${item.card_info.last_four_digits}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant={isDownSm ? "body2" : "body1"} color="textSecondary">
                          {`En ${item.payment.metadata.installments} cuota${item.payment.metadata.installments > 1 ? "s" : ""}; Total $${
                            item.payment.amount
                          }`}
                        </Typography>
                      </Grid>
                      {item.payment.metadata.installments === 1 && (
                        <Grid item>
                          <Typography variant={isDownSm ? "body2" : "body1"} color="textSecondary">
                            {`Descuento por compra en 1 pago: ${item.payment.amount - Number(item.total)}`}
                          </Typography>
                        </Grid>
                      )}

                      <Grid item>
                        <Box pb={1} pt={1}>
                          <Divider />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Typography variant={isDownSm ? "h6" : "h5"} color="textPrimary">
                          Envio
                        </Typography>
                      </Grid>

                      <Grid item>
                        {item.shipment_cost === "0" ? (
                          <Typography variant="h6" color="textSecondary">
                            <Box component="span" color="success.main">
                              Gratis
                            </Box>
                          </Typography>
                        ) : (
                          <Typography variant="h6" color="textSecondary">
                            {`Costo $${item.shipment_cost}`}
                          </Typography>
                        )}
                      </Grid>

                      <Grid item>
                        <Typography variant={isDownSm ? "body2" : "body1"} color="textSecondary">
                          {`${item.shipment_address.state.name} / ${item.shipment_address.city.name},
                             ${item.shipment_address.zip}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant={isDownSm ? "body2" : "body1"} color="textSecondary">
                          {`Piso: ${item.shipment_address.floor} / Dpto: ${item.shipment_address.department || "-"}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant={isDownSm ? "body2" : "body1"} color="textSecondary">
                          {`${item.shipment_address.street} ${item.shipment_address.number}, ${item.shipment_address.comments}`}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Box pb={1} pt={1}>
                          <Divider />
                        </Box>
                      </Grid>

                      <Grid item>
                        <Typography variant={isDownSm ? "h6" : "h5"} color="textPrimary">
                          <Box pr={3} component="span" display="inline" color="primary.dark">
                            Total
                          </Box>
                          {`   $${item.payment.amount}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            )
          )}
        </Grid>
      </Box>
    </Container>
  );
};
