import React, { useEffect, useState, ChangeEvent, useMemo, useCallback } from "react";
import { DeliveryForm } from "src/app/views";
import { useLocationDAO, useLocationLibbyCall } from "src/app/business";
import { State, City } from "src/app/models";
import { orderBy } from "lodash";
import { useDebounce } from "src/commons";
import { InitialValue, deliveryInitialValue, DeliveryInitialValue } from "../initialValues";
import { useCheckoutFormContext } from "../hooks";
import { FormProps } from "../types";

interface Values extends InitialValue {
  deliveryInfo: DeliveryInitialValue;
}

export const DeliveryInfoForm = ({ onBackStep }: FormProps) => {
  const [cities, setCities] = useState<City[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { form, values, errors, handleSubmit } = useCheckoutFormContext();

  const valuesAll = useMemo(
    () =>
      ({
        deliveryInfo: deliveryInitialValue,
        ...values,
      } as Values),
    [values],
  );

  const [search, setSearch] = useState(valuesAll.deliveryInfo.city?.name || "");
  const searchDebunce = useDebounce(search, 600);

  const locationDAO = useLocationDAO();
  const { data, working } = useLocationLibbyCall<State[]>({ methodName: "getStates" });

  const selectedProvince = useMemo(() => valuesAll.deliveryInfo.province?.id !== "", [valuesAll.deliveryInfo.province?.id]);

  const zitCode = useMemo(() => valuesAll.deliveryInfo.zipCode || "", [valuesAll.deliveryInfo.zipCode]);

  const onInputChangeCity = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      setSearch(value);
    },
    [setSearch],
  );

  useEffect(() => {
    if (
      (valuesAll?.deliveryInfo?.city?.name?.length > 0 && valuesAll?.deliveryInfo?.city?.name !== search) ||
      valuesAll?.deliveryInfo?.city === null
    ) {
      const copyValuesForm = { ...valuesAll };
      copyValuesForm.deliveryInfo.city = deliveryInitialValue.city;
      form.reset(copyValuesForm);
    }
  }, [search, valuesAll.deliveryInfo.city, form, valuesAll]);

  useEffect(() => {
    if (selectedProvince && searchDebunce && searchDebunce !== valuesAll.deliveryInfo.city?.name) {
      setLoading(true);
      (async () => {
        const city = await locationDAO.getCityByState(valuesAll.deliveryInfo.province?.id, searchDebunce);
        setCities(city);
        setLoading(false);
      })();
    }
  }, [selectedProvince, locationDAO, valuesAll.deliveryInfo.province, searchDebunce, valuesAll.deliveryInfo.city]);

  const invalidZipCode = useMemo(
    () => !!zitCode && !valuesAll.deliveryInfo.city?.zip?.includes(Number(zitCode)),
    [valuesAll.deliveryInfo.city, zitCode],
  );

  return (
    <DeliveryForm
      disableNext={Object.keys(errors as any).length !== 0}
      invalidZidCode={invalidZipCode}
      onClickStep={handleSubmit}
      onInputChangeCity={onInputChangeCity}
      form={form}
      onBackStep={onBackStep}
      loading={working}
      loadingCities={loading}
      provinces={orderBy(data, "name")}
      cities={cities}
      selectedProvince={selectedProvince}
      isResponsable={values.isResponsable === "true"}
      searchCityValue={search}
      zipCodeLength={valuesAll.deliveryInfo.zipCode.length || 0}
    />
  );
};
