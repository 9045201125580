import { routes } from "./routes";
import { MenuItem } from "../../../../commons";

export const profilesMenu: MenuItem = {
  key: "privateProfile",
  basePath: "/profile",
  enabled: true,
  icon: "List",
  text: "Profile",
  children: routes,
};
