import { EcommerceLogo } from "../../../app/models/general/ecommerceLogo";
import { MainLogo } from "./components/MainLogo";
import { ShortLogo } from "./components/ShortLogo";
import { NotFoundPage } from "./components/NotFoundPage";

export const ecommerceLogo: EcommerceLogo = {
  mainLogo: MainLogo,
  shortLogo: ShortLogo,
  notFoundPage: NotFoundPage,
};
