import { Profile } from "../../../lib/profiles/types";
import { MainLayout } from "../../../commons/components/layouts";
import { privateMenu } from "./privateMenu";

// TODO: fix private profile
export const privateProfile: Profile = {
  name: "private",
  component: MainLayout,
  menu: privateMenu,
};
