import React, { FC } from "react";
import cx from "classnames";
import { Typography, TableBody, Paper, TableRow, Table, TableContainer, Grid } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/styles";
import { AnyObject, InfoTableProps } from "src/commons/types";
import _ from "lodash";
import { EnhancedTableHead } from "./components/EnhancedTableHead";
import { grey } from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: "15px",
  },
  tableContainer: {
    maxHeight: "450px",
  },
  search: {
    display: "flex",
    justifyContent: "center",
  },
  caption: {
    height: "15px",
  },
  typography: {
    fontSize: 13,
    color: grey.dark,
    textOverflow: "true",
  },
  arrayContainer: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  seccionNames: {
    "&:hover": {
      fontWeight: 600,
    },
  },
}));

const InfoTableRaw: FC<InfoTableProps> = ({
  columns,
  rows,
  onBottomScroll,
  onRowClick,
  rowIdKey = "id",
  onSortChange,
  orderBy,
  direction: order = "asc",
  working,
  customStyle,
  customClassnameTypography,
  hover = true,
  elevation = true,
}: InfoTableProps) => {
  const classes = useStyles();
  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
    if (bottom && onBottomScroll) {
      onBottomScroll();
    }
  };

  const handleRequestSort = (event: AnyObject, property: any) => {
    if (onSortChange) {
      onSortChange(property, order === "asc" ? "desc" : "asc");
    }
  };

  return (
    <>
      <Paper className={classes.root} elevation={elevation ? 1 : 0}>
        <TableContainer className={`${classes.tableContainer} ${customStyle}`} onScroll={handleScroll}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              headCells={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={!rows ? 0 : rows.length}
            />
            <TableBody>
              {!rows
                ? []
                : rows.map((row: any) => (
                    <TableRow
                      hover={hover}
                      role="checkbox"
                      tabIndex={-1}
                      key={row[rowIdKey]}
                      onClick={onRowClick ? () => onRowClick(row) : undefined}
                      style={{ ...row.customStyles }}
                    >
                      <>
                        {columns
                          .filter((column) => {
                            const value = row[column.id];
                            if (column.hide === undefined) {
                              return true;
                            }
                            return typeof column.hide === "boolean" ? !column.hide : !column.hide(value, row);
                          })
                          .map((column: any) => {
                            const value = _.get(row, column.id);
                            return (
                              <TableCell key={column.id} align={column.align} style={{ ...column.customStyles }}>
                                <Typography
                                  component="span"
                                  className={cx(customClassnameTypography, classes.typography)}
                                  id={`${rows.indexOf(row)}-${column.id}`}
                                >
                                  {column.render ? column.render(value, row) : value}
                                </Typography>
                              </TableCell>
                            );
                          })}
                      </>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {working && (
            <Grid container justify="center" alignItems="center">
              <img src="https://ster-ecommerce-img.s3.amazonaws.com/assets/images/loading.gif" width={50} alt="loading" />
            </Grid>
          )}
        </TableContainer>
      </Paper>
    </>
  );
};

export const InfoTable = React.memo(InfoTableRaw);
