import React from "react";
import palette from "src/theme/palette";
import { Link } from "react-router-dom";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { PaymentMsg } from "../Payment/Payment";
import { approvedPayment as useStyles } from "../../styles";

export const FailurePaymentMethodView = () => {
  const color = palette.error.main;
  const classes = useStyles({ color });
  return (
    <PaymentMsg
      title="¡Ups! Estos productos no se pueden comprar juntos"
      color={color}
      description={
        <>
          Para finalizar tu pedido tenés que adquirirlos por separado: volvé a intentarlo eliminando uno de los productos de tu carrito.
          <br />
          <Link to="/cart" className={classes.lastLink}>
            Volver a tu pedido..
          </Link>
        </>
      }
      icon={<ErrorOutlineIcon fontSize="large" className={classes.icon} />}
      noContainer
    />
  );
};
