import { EcommerceDAO } from "./EcommerceDAO";
import { PurchasedItems } from "../../../app/models";

// export interface PurchasedItems {
//     order_item_id: string;
//     order_id: string;
//     publication_id: string;
//     quantity: number;
//     unit_price: string;
//     tax: string;
//     subtotal: string;
//     total: string;
//     product: string;
//     order_state: {
//       order_state_id: string;
//       name: string;
//     };
//     publication: Publication;
//   }

export class PurchasedItemsDAO extends EcommerceDAO<PurchasedItems> {
  constructor() {
    super("PurchasedItems", "PurchasedItemsId");
  }

  async getItems(address: string) {
    const response = await this.fetchData(`/order/purchased_items/${address}`);
    return response.json();
  }
}
