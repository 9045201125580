import React from "react";

interface ETHiconProps {
  color: string;
  width: string;
}

export const ETHicon = ({ color, width }: ETHiconProps) => (
  <svg width={width} viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99851 0.583313L6.90927 0.886423V9.6812L6.99851 9.77023L11.0809 7.35711L6.99851 0.583313Z" />
    <path d="M6.99912 0.583313L2.91666 7.35711L6.99912 9.77023V5.50149V0.583313Z" />
    <path d="M6.9985 10.5432L6.94821 10.6045V13.7373L6.9985 13.8841L11.0834 8.13129L6.9985 10.5432Z" />
    <path d="M6.99912 13.8841V10.5432L2.91666 8.13129L6.99912 13.8841Z" />
    <path d="M6.99847 9.77014L11.0808 7.35703L6.99847 5.5014V9.77014Z" />
    <path d="M2.91666 7.35703L6.99912 9.77014V5.5014L2.91666 7.35703Z" />
  </svg>
);
