import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../../lib/libby/generator";
import { Cart } from "../../models";
import { businessOptions } from "../businessOptions";

const DAO_NAME = "cart";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "idCart",
      labelKey: "descripcion",
    },
    withSelectedChips: {
      idKey: "idCart",
      labelKey: "descripcion",
    },
  },
};

const optionsToUse = !businessOptions.Cart
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Cart,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Cart.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Cart>(DAO_NAME, optionsToUse);

// hooks
export const useCartFetchById = fetchById;
export const useCartLibbyCall = libbyCall;
export const useCartDAO = libbyDAO;
export const useCartLibbyFetch = libbyFetch;

// components/hoc
export const CartAutocomplete = entityAutocomplete;
export const CartCall = renderPropCall;
export const CartById = renderPropFetchById;
export const CartSelect = simpleSelect;
export const CartAccordionSelector = accordionSelector;
export const CartSelectedChips = selectedChips;

// context
// ByIdContext
export const useCartByIdContext = useByIdContext;
export const CartByIdProvider = ByIdProvider;
export const CartByIdContext = ByIdContext;
// GeneralContext
// export const useCartContext = useGeneralContext;
// export const CartProvider = GeneralProvider;
// export const CartContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
