import React, { ReactNode } from "react";
import { Grid, Container, Typography, Box, useMediaQuery, Theme, Divider, Card } from "@material-ui/core";

interface PaymentMsgProps {
  title: ReactNode;
  noBorder?: boolean;
  color: string;
  img?: React.ReactNode;
  icon?: React.ReactNode;
  description?: React.ReactNode;
  noContainer?: boolean;
}

export const PaymentMsg = ({ title, color, img, noBorder = false, icon, description, noContainer = false }: PaymentMsgProps) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const Conponent = (
    <Card style={{ display: "contents" }}>
      <Box borderTop={!noBorder && `6px solid ${color}`} borderRadius py={3}>
        <Container maxWidth="md">
          <Grid container justify="center" alignContent="center">
            {icon && <Box color={color}>{icon}</Box>}
            <Grid item xs={12}>
              <Box>
                <Typography align="center" variant={isDownSm ? "h5" : "h3"}>
                  <Box color={color}>{title}</Box>
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography align="center" variant="body1" color="textPrimary">
                  {description}
                </Typography>
              </Box>
            </Grid>
            {img && (
              <>
                <Grid item container justify="center" xs={12}>
                  <Box py={5}>{img}</Box>
                </Grid>

                <Grid item xs={12}>
                  <Box mt={3}>
                    <Divider variant="inset" />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </Box>
    </Card>
  );

  return noContainer ? (
    Conponent
  ) : (
    <Container maxWidth="lg">
      <Box mt={4}>{Conponent}</Box>
    </Container>
  );
};
