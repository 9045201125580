import { EcommerceDAO } from "./EcommerceDAO";

export class OrderNftDAO extends EcommerceDAO<any> {
  constructor() {
    super("order_nft", "order_nft_Id");
  }

  async getOrderStateByBuyer(document: string) {
    const response = await this.fetchData(`/order/${document}`);
    return response.json();
  }

  async insertBuyerId(publication_id: string) {
    const response = await this.fetchData(`/nft/order`, {
      method: "post",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({ publication_id }),
    });
    return response.json();
  }
}
