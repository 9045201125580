import React, { useCallback } from "react";
import { Grid, Box, Button, Typography, makeStyles, useMediaQuery, Theme } from "@material-ui/core";
import { SingIn, OAuthProvider } from "src/app/models";
import { FacebookLogin, ResponseHandler } from "src/commons/components/FacebookButton";
import { FACEBOOK_REDIRECT_URI, FACEBOOK_OAUTH_APP_ID } from "src/config";
import { GoogleIcon, FacebookIcon } from "../../../../assets/images/svgs";

const useStyles = makeStyles(() => ({
  facebook: {
    padding: 0,
    borderRadius: "8px",
    height: "50px",
    textTransform: "none",
    backgroundColor: "#1877F2",
    "&:hover": {
      backgroundColor: "rgb(27, 38, 117)",
    },
  },
  google: {
    height: "50px",
    textTransform: "none",
    backgroundColor: "#FFFFFF",
  },
  container: { width: "100%", borderRadius: "8px" },
  image: { borderRadius: "0px 8px 8px 0px" },
}));

interface SocialButtonProps {
  handlerSocialSingIn: ({ provider }: SingIn) => void;
}

export const SocialButton = ({ handlerSocialSingIn }: SocialButtonProps) => {
  const classes = useStyles();
  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));

  const onFacebookLogin = useCallback(
    (res: ResponseHandler) => {
      if (res.authResponse) {
        handlerSocialSingIn({ provider: OAuthProvider.FACEBOOK, token_id: res.authResponse?.accessToken });
      }
    },
    [handlerSocialSingIn],
  );

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={10}>
        <FacebookLogin
          appId={FACEBOOK_OAUTH_APP_ID}
          xfbml
          cookie
          version="8.0"
          redirectUri={FACEBOOK_REDIRECT_URI}
          responseHandler={onFacebookLogin}
          render={({ clickHandler, userInfo }) => (
            <Button className={classes.facebook} fullWidth variant="contained" onClick={clickHandler}>
              <Grid className={classes.container} container justify="center" alignItems="center">
                <Grid item xs={2} container alignItems="center" justify="center">
                  <FacebookIcon />
                </Grid>
                <Grid item xs={userInfo?.id ? 8 : 10} container justify="flex-start">
                  <Typography align="left" variant={isDownXs ? "body1" : "h6"}>
                    <Box color="primary.contrastText">
                      {userInfo?.id ? `Continuar como ${userInfo.name.split(" ")[0]}` : `Continuar con Facebook`}
                    </Box>
                  </Typography>
                </Grid>

                {userInfo?.id ? (
                  <Grid item xs={2} container alignItems="center" justify="flex-end">
                    <Grid
                      className={classes.image}
                      component="img"
                      width={userInfo.picture.data.width}
                      height={userInfo.picture.data.height}
                      src={userInfo.picture.data.url}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Button>
          )}
        />
      </Grid>

      <Grid item xs={12} md={10}>
        <Box
          className={classes.google}
          component={(props: any) => (
            <Button fullWidth variant="contained" {...props}>
              {props.children}
            </Button>
          )}
          padding={isDownXs ? "8px" : "15px"}
          borderRadius="8px"
          onClick={() => handlerSocialSingIn({ provider: OAuthProvider.GOOGLE })}
          maxHeight="48px"
        >
          <Grid container justify="space-around">
            <Grid item xs={2} container justify="center" alignItems="center">
              <GoogleIcon />
            </Grid>
            <Grid item xs={10} container justify="flex-start" alignItems="center">
              <Typography color="textSecondary" align="left" variant={isDownXs ? "body1" : "h6"}>
                Continuar con google
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
