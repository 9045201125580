import React, { useMemo } from "react";
import { DeliveryInformationShow, PaymentMethodShow, PersonalInformation } from "src/app/views";
import { ValuesForm, InnerCard } from "../types";

export const useInfoCards = (data: ValuesForm, completedSteps: Set<number>) =>
  useMemo<InnerCard[]>(() => {
    const renders = [
      {
        title: "Información de envío",
        id: 1,
        render: <DeliveryInformationShow data={data.deliveryInfo} />,
      },
      {
        title: "Medio de pago",
        id: 2,
        render: <PaymentMethodShow paymentInfo={data.paymentMethod} cardInto={data.cardInfo} />,
      },
      {
        title: "Datos del comprador",
        id: 0,
        render: <PersonalInformation personalInfo={data.personalInfo} isResponsable={data.isResponsable} responsable={data.responsableInscripto} />,
      },
    ];

    return renders.map((render) => ({ ...render, complete: completedSteps.has(render.id) }));
  }, [data, completedSteps]);
