/* eslint-disable no-useless-escape */
import { Template } from "src/lib/templates";

export const template: Template = {
  name: "Regret Form",
  template: [
    {
      name: "firstName",
      type: {
        name: "string",
      },
      label: "Nombre",
    },
    {
      name: "lastName",
      type: {
        name: "string",
      },
      label: "Apellido",
    },
    {
      name: "DNI",
      type: { name: "string" },
      label: "DNI",
      validators: [
        { name: "pattern", signature: ["([0-9]{2}.[0-9]{3}.[0-9]{3})|([0-9]{8})$"], errorMessage: "DNI invalido" },
        { name: "max", signature: [10], errorMessage: "DNI invalido" },
      ],
    },
    {
      name: "phoneNumber",
      type: { name: "string" },
      label: "Teléfono",
    },
    {
      name: "email",
      type: { name: "string" },
      label: "Email",
      validators: [
        {
          name: "pattern",
          signature: ["^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$"],
          errorMessage: "Formato incorrecto de email",
        },
      ],
    },
    {
      name: "emailConfirm",
      type: { name: "string" },
      label: "Confirmación email",
      validators: [
        {
          name: "pattern",
          signature: ["^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$"],
          errorMessage: "Formato incorrecto de email",
        },
      ],
    },
    {
      name: "buyNumber",
      type: { name: "string" },
      label: "Nro. de compra",
      validators: [{ name: "max", signature: [39], errorMessage: "Nro. de Compra invalido" }],
    },
    {
      name: "product",
      type: { name: "string" },
      label: "Producto",
    },
    {
      name: "motive",
      type: { name: "string" },
      label: "Motivo de la devolución",
      validators: [{ name: "min", signature: [10], errorMessage: "El motivo no puede tener menos de 10 caracteres" }],
    },
  ],
};
