import { useCallback, useLayoutEffect } from "react";

const mercadopagoButtonClick = (cb?: () => void) => {
  const payButton: any = document.getElementsByClassName("mercadopago-button")[0];
  if (payButton) {
    payButton?.click();
    if (cb) cb();
  } else {
    setTimeout(() => mercadopagoButtonClick(), 300);
  }
};

export const useMercadopagoPayment = () =>
  useCallback((cb?: () => void) => {
    mercadopagoButtonClick(cb);
  }, []);

export const useMountMercadopagoPaymentCallback = () =>
  useCallback((id: string) => {
    const elem = document.getElementById("mercadopagocontainer");
    if (elem) {
      elem?.parentNode?.removeChild(elem);
    }
    const btn = document.getElementsByClassName("mercadopago-button")[0];
    if (btn) {
      btn?.parentNode?.removeChild(btn);
    }
    const div = document.createElement("div");
    div.id = "mercadopagocontainer";
    const script = document.createElement("script");
    script.src = "https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js";
    script.setAttribute("data-preference-id", id);
    div.appendChild(script);
    document.head.appendChild(div);
  }, []);

export const useMountMercadopagoPayment = (id?: string) => {
  const mountMercadopagoPayment = useMountMercadopagoPaymentCallback();
  useLayoutEffect(() => {
    if (id) {
      mountMercadopagoPayment(id);
    }
  }, [id, mountMercadopagoPayment]);
};
