import React from "react";
import { SkeletonComponentProps, SOURCE } from "src/app/models/Site";
import { ListProducts } from "src/app/views/components/PromotionsView/components";
import { Product } from "src/app/models/business/Product";
import { useHistory } from "react-router";

export const SkeletonListProducts = ({ data, props }: SkeletonComponentProps) => {
  const history = useHistory();
  const { data: dataProduct = { publications: [], name: "" }, working } = SOURCE.ZONE(data.source_id);
  const { publications, name } = dataProduct;

  const { onClickProduct } = props || {};

  const onClick = (item: Product) => {
    if (onClickProduct) {
      onClickProduct(item);
    } else {
      history.push(`/product/${item.productId}`);
    }
  };

  return working ? <div /> : <ListProducts title={name} publications={publications} onClickProduct={onClick} />;
};
