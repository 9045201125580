import { makeStyles } from "@material-ui/core";

export const payMethodTableStyles = makeStyles(() => ({
  tableContainer: {
    maxHeight: "100%!important",
  },
}));

export const payMethodTableColumnStyles = {
  padding: "5px 16px",
};

export const payMethodTableRowStyles = { padding: "0px", height: "auto" };
