import React, { MouseEvent } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/styles";
import { primary } from "../../../theme/colors";

interface IconButtonRemoveTypesProps {
  disabled?: boolean;
  handleOnClick?: (event: MouseEvent) => void;
}

const iconButtonStyles = makeStyles(() => ({
  button: {
    display: "flex",
    textTransform: "none",
    padding: 0,
    paddingTop: -5,
    borderRadius: 0,
    paddingLeft: "7px",
    "&:hover": {
      color: primary.lightBlue,
    },
  },
}));

export const IconButtonRemove = ({ disabled = false, handleOnClick }: IconButtonRemoveTypesProps) => {
  const classes = iconButtonStyles();

  return (
    <>
      <IconButton className={classes.button} onClick={handleOnClick} disabled={disabled}>
        <ClearIcon />
      </IconButton>
    </>
  );
};
