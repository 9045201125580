import React from "react";
import palette from "src/theme/palette";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { PaymentMsg } from "../Payment/Payment";
import { Links } from "../AppBreadcrumbs";

interface ErrorMessageViewProps {
  title: string;
  description: string;
  link: Links;
  image?: React.ReactNode;
  noContainer?: boolean;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    height: "100px",
    width: "100px",
    [theme.breakpoints.down("sm")]: {
      height: "60px",
      width: "60px",
    },
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  lastLink: {
    color: "#3D2CFC!important",
  },
}));

export const ErrorMessageView = ({ title, description, link, image, noContainer }: ErrorMessageViewProps) => {
  const classes = useStyles();
  return (
    <PaymentMsg
      title={title}
      color={palette.error.main}
      description={
        <>
          {description}
          <Link to={link.url} className={classes.lastLink}>
            {link.label}
          </Link>
        </>
      }
      img={image}
      icon={<InfoOutlinedIcon fontSize="large" className={classes.icon} />}
      noContainer={noContainer}
    />
  );
};
// <Img width={isDownSm ? 303 : 506} height={isDownSm ? 213 : 326} />
