import React from "react";
import { makeStyles } from "@material-ui/styles";

export interface IconStatusTableTypes {
  text: string;
  color: string;
}

const IconStatusTableStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    marginRight: 8,
  },
});

export const IconStatusTable = ({ text, color }: IconStatusTableTypes) => {
  const classes = IconStatusTableStyles();

  return (
    <div className={classes.container}>
      <div style={{ backgroundColor: color }} className={classes.circle} />
      {text}
    </div>
  );
};
