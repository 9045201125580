import React from "react";
import { useField } from "react-final-form-hooks";
import { Radio, RadioGroup, FormControlLabel, Box } from "@material-ui/core";

interface RadioOptions {
  label: React.ReactNode;
  id: string;
  control?: React.ReactElement<any, any>;
}

export interface FormRadioGroupProps {
  name: string;
  form: any;
  row?: boolean;
  options: Array<RadioOptions>;
  marginLeft?: string | number;
  marginRight?: string | number;
}

export const FormRadioGroup = ({ name, form, row, options, marginLeft, marginRight }: FormRadioGroupProps): JSX.Element => {
  const radio = useField(name, form);

  return (
    <RadioGroup row={row} {...radio.input}>
      {options?.map((item) => (
        <Box key={item.id} marginRight={marginRight} marginLeft={marginLeft}>
          <FormControlLabel value={item.id} control={item?.control || <Radio id={item.id} color="primary" />} label={item.label} />
        </Box>
      ))}
    </RadioGroup>
  );
};
