import { routes } from "./routes";
import { MenuItem } from "../../../../commons/ui/menu";

export const aboutMenu: MenuItem = {
  key: "publicAbout",
  basePath: "/nosotros",
  enabled: true,
  icon: "List",
  text: "Nosotros",
  children: routes,
  default: true,
};
