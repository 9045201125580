import React, { useCallback, useState } from "react";
import { Grid, Container, Box } from "@material-ui/core";
import { FormContextProvider } from "src/lib/templates";
import { StepFormRecord } from "src/commons";
import { registerInitialValue, accountInfoInitialValues, checkCodeInitialValue } from "./initialValues";
import { registerTemplate, accountInfoTemplate, checkCodeTemplate } from "./Templates";
import { AccountInfoForm } from "./components/AccountInfoForm";
import { RegisterForm } from "./components";
import { FormRegisterValues } from "./types";
import { useSendRegister } from "./hooks";
import { CheckCodeRegisterForm } from "./components/CheckCodeRegisterForm";

export const steps: Array<StepFormRecord> = [
  {
    id: 1,
    title: "Completá tus datos",
    subTitle: "Si ya tienes un usuario generado, completa con tu correo o tu nombre de usuario.",
    hidden: false,
    template: accountInfoTemplate,
    initialValue: accountInfoInitialValues,
    render: AccountInfoForm,
  },
  {
    id: 2,
    title: "Confirmación",
    subTitle:
      "Te enviamos un correo a <b>$1</b> con un código de 6 dígitos para confirmar tu identidad. Si no lo encontrás, verificá en tu casilla de no deseados.",
    hidden: false,
    template: checkCodeTemplate,
    initialValue: checkCodeInitialValue,
    render: CheckCodeRegisterForm,
  },
];

const maxStep = steps.length;

export const Register = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Set<number>());

  const { sendRegister } = useSendRegister();

  const onSubmit = useCallback(
    async (values: FormRegisterValues) => {
      try {
        if (activeStep === maxStep - 1) {
          await sendRegister(values);
        } else {
          const newCompleted = new Set(completed);
          newCompleted.add(activeStep);
          setCompleted(newCompleted);
          setActiveStep((oldValue) => oldValue + 1);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    [completed, activeStep, sendRegister],
  );

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Box mt={4}>
          <FormContextProvider initialValues={registerInitialValue} onSubmit={onSubmit} template={registerTemplate}>
            <RegisterForm activeStep={activeStep} steps={steps} completedSteps={completed} />
          </FormContextProvider>
        </Box>
      </Grid>
    </Container>
  );
};
