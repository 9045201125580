import { Dashboard } from "src/screens";
import { MenuItem } from "src/commons";
import { ConfirmAccount } from "src/screens/Private/Dashboard/components";
import { profilesMenu } from "./profile";
import { purchasesMenu } from "./purchases";
import { configurationMenu } from "./configuration";
import { favoriteMenu } from "./favorites";

export const routes: MenuItem[] = [
  favoriteMenu,
  purchasesMenu,
  configurationMenu,
  profilesMenu,
  {
    key: "privateDashboardValidateMain",
    basePath: "/confirm",
    target: {
      component: ConfirmAccount,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Profile",
    children: [],
    default: true,
  },
  {
    key: "privateDashboardMain",
    basePath: "/",
    target: {
      component: Dashboard,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Profile",
    children: [],
    default: true,
  },
];
