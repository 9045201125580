import { Account, Confirmation, VerifyCode } from "../../../app/models";
import { EcommerceDAO } from "./EcommerceDAO";

export class ConfirmationDAO extends EcommerceDAO<Account> {
  constructor() {
    super("confirmation", "confirmation_id");
  }

  async sendCode({ email }: Confirmation) {
    const res = await this.fetchData(`/confirmation`, {
      method: "post",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({ email }),
    });
    return res.json();
  }

  async verifyEmail(email: string) {
    const res = await this.fetchData(`/confirmation/account/${email}`, { method: "post" });
    return res.json();
  }

  async verifyCode(email: string, code: string): Promise<VerifyCode> {
    const res = await this.fetchData(`/confirmation/${email}?code=${code}`);
    return res.json();
  }

  async verifyToken(token: string): Promise<VerifyCode> {
    const res = await this.fetchData(`/confirmation/facebook?access_token=${token}&type=facebook`);
    return res.json();
  }
}
