import React from "react";
import { useParams } from "react-router";
import { Container, useMediaQuery, Theme, Box } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useBreadcrumbs } from "src/commons";
import { AppBreadcrumbsApp } from "src/app/views";
import { UserInformationEditValue, MainForm } from "./components";
import { Loading } from "../../../../../commons/components/Loading";
import { InitialValueDefinition, TransformFunction } from "./types";

const initialValue: InitialValueDefinition = {
  name: "",
  lastName: "",
  document: "",
  dateOfBirty: "",
  phone: { areaCode: "", number: "" },
};

export const transformer: TransformFunction<InitialValueDefinition> = (value) => ({
  name: value.name,
  lastName: value.last_name,
  document: value.document_number,
  dateOfBirty: value.date_of_birth,
  phone: { areaCode: value.phone?.split(" ")[0], number: value.phone?.split(" ")[1] },
});

export const UserInformationForm = () => {
  const { id } = useParams();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const links = useBreadcrumbs({
    links: [
      { label: "Modificar datos", url: "/dashboard/profile" },
      { label: "Perfil", url: "/dashboard/profile" },
    ],
  });
  return (
    <Container maxWidth="lg">
      <Box px={isDownSm ? 0 : "10%"}>
        {isDownSm && (
          <AppBreadcrumbsApp
            links={links}
            iconSeparator={<Box px={1}>/</Box>}
            iconGoBack={<ChevronLeftIcon color="primary" />}
            invert
            msgReturn=" "
            noMargin
          />
        )}
        {id === "new" ? (
          <MainForm initialValue={initialValue} />
        ) : (
          <UserInformationEditValue<InitialValueDefinition> idBuyer={id} transformer={transformer}>
            {({ working, data, raw }) => (working || !data ? <Loading /> : <MainForm initialValue={data} raw={raw} />)}
          </UserInformationEditValue>
        )}
      </Box>
    </Container>
  );
};
