import { useLibbyCall } from "./useLibbyCall";

export interface LibbyFetchByIdOptions {
  daoName: string;
  id: string | number;
  aspect?: string;
}

export interface LibbyFetchByIdReturn<T> {
  data?: T;
  working: boolean;
}

export const useLibbyFetchById = <T>({ daoName, id, aspect }: LibbyFetchByIdOptions): LibbyFetchByIdReturn<T> => {
  const { data: [item] = [], working } = useLibbyCall<T[]>({
    daoName,
    methodName: "fetchById",
    params: [id],
    aspect,
  });

  return { data: item, working };
};
