import React from "react";
import { InputLabelProps, InputBaseProps } from "@material-ui/core";
import { useField } from "react-final-form-hooks";
import { FieldValidator } from "final-form";
import { Input, InputProps } from "../../../app/components/inputs/Input";
import { getErrorAndHelperTextNoTranslation } from "../../../utils/materialHelpers";

// FIXME: type form props
export interface FormInputProps {
  name: string;
  label?: React.ReactNode;
  positionLabel?: "inner" | "outer";
  form: any;
  size?: "small" | "medium";
  inputProps?: InputProps;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  variant?: "standard" | "filled" | "outlined";
  inputLabelProps?: Partial<InputLabelProps>;
  inputBaseProps?: Partial<InputBaseProps>;
  validator?: FieldValidator<any>;
}

export const FormInput = ({
  type,
  name,
  form,
  label,
  positionLabel = "inner",
  disabled,
  size,
  placeholder,
  variant,
  inputProps = {},
  inputLabelProps = {},
  error,
  helperText,
  id,
  autoComplete,
  inputBaseProps = {},
  validator,
}: FormInputProps & InputProps): JSX.Element => {
  const field = useField(name, form, validator);

  const { helperText: helperTextHelper, error: errorHelper } = getErrorAndHelperTextNoTranslation(field);

  const helperTextEnd = error || errorHelper ? (!helperText ? helperTextHelper : helperText) : "";

  return (
    <Input
      placeholder={placeholder}
      InputLabelProps={inputLabelProps}
      label={label}
      disabled={disabled}
      variant={variant}
      {...field.input}
      error={error || errorHelper}
      helperText={helperTextEnd}
      {...inputProps}
      type={type}
      size={size}
      positionLabel={positionLabel}
      id={id}
      autoComplete={autoComplete}
      InputProps={inputBaseProps}
    />
  );
};
