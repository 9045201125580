import { routes } from "./routes";
import { MenuItem } from "../../../../commons/ui/menu";

export const politicaMenu: MenuItem = {
  key: "publicPolitica",
  basePath: "/devoluciones",
  enabled: true,
  icon: "List",
  text: "Devoluciones",
  children: routes,
  default: true,
};
