import React, { useMemo, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Grid, Typography, Box, Link, useMediaQuery, Theme, Button } from "@material-ui/core";
import { FormRadioGroup, FormCheckbox, FormDatePicker } from "src/lib/templates/components";
import { ComponentProps, Form } from "src/screens/Public/Checkout/types";
import { optionsInvoice } from "src/screens/Public/Checkout/constant";
import { EMITTERS } from "src/app/const/Emitters";
import { Emitter } from "src/app/models";
import { ModalDialog } from "src/commons";
import { GOOGLE_RECAPTCHA_KEY } from "src/config";
import { FormInputData, CommonFormInput } from "../CommonFormInput";
import { useScrollToTopOnMount, Loading } from "../../../../commons";
import { ecommerceConfig } from "../../../../config/ecommerce";

export interface PersonalInfoFormProps extends ComponentProps {
  isResponsable: boolean;
  birthdayValid: boolean;
  invalidDate: boolean;
  disableNext: boolean;
  emitters: Emitter[];
  openTermsAndConditions: () => void;
  handleReCaptcha: (values: any) => void;
  ReCaptchaVisible: boolean;
  openModal?: boolean;
  handleModal?: () => void;
  pendingCredit?: boolean;
  validIpAddress?: boolean;
}

const formInput: FormInputData[] = [
  {
    variant: "standard",
    name: "personalInfo.name",
    label: "Nombre",
    lg: 6,
    md: 6,
    sm: 6,
    xs: 12,
    required: true,
    id: "personalInfoName",
    autoComplete: "name",
  },
  {
    variant: "standard",
    name: "personalInfo.lastName",
    label: "Apellido",
    lg: 6,
    md: 6,
    sm: 6,
    xs: 12,
    required: true,
    id: "personalInfoLastName",
    autoComplete: "family-name",
  },
  {
    variant: "standard",
    name: "personalInfo.document",
    label: "DNI",
    lg: 6,
    md: 6,
    sm: 6,
    xs: 12,
    required: true,
    id: "personalInfoDocument",
    autoComplete: "on",
  },
  {
    variant: "standard",
    name: "personalInfo.phone.areaCode",
    label: "Cód. de Área",
    lg: 2,
    md: 2,
    sm: 2,
    xs: 4,
    required: true,
    id: "personalInfoAreaCode",
    autoComplete: "tel-area-code",
  },
  {
    variant: "standard",
    name: "personalInfo.phone.number",
    label: "Teléfono",
    lg: 4,
    md: 4,
    sm: 4,
    xs: 8,
    required: true,
    id: "personalInfoPhoneNumber",
    autoComplete: "tel-local",
  },
  {
    variant: "standard",
    name: "personalInfo.email",
    label: "E-mail",
    lg: 6,
    md: 6,
    sm: 6,
    xs: 12,
    required: true,
    id: "personalInfoEmail",
    autoComplete: "email",
  },
];

export const PersonalInfoForm = ({
  form,
  disableNext,
  birthdayValid,
  invalidDate,
  isResponsable,
  onClickStep,
  emitters,
  openTermsAndConditions,
  handleReCaptcha,
  ReCaptchaVisible,
  openModal,
  handleModal,
  pendingCredit,
  validIpAddress,
}: PersonalInfoFormProps) => {
  useScrollToTopOnMount();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const reponsableInputs = useMemo(() => {
    const reponsable: FormInputData[] = [
      {
        variant: "standard",
        name: "responsableInscripto.business",
        label: "Empresa",
        hidden: !isResponsable,
        lg: 6,
        md: 6,
        xs: 12,
        autoComplete: "organization",
      },
      {
        variant: "standard",
        name: "responsableInscripto.cuit",
        label: "CUIT",
        hidden: !isResponsable,
        lg: 6,
        md: 6,
        xs: 12,
        autoComplete: "document",
      },
    ];

    return reponsable.filter((input) => !input.hidden);
  }, [isResponsable]);

  useEffect(() => {
    formInput[2].label = emitters.find((item) => item.card_emiter_id === EMITTERS.CATAMARCA_CREDITS) ? "CUIL" : "DNI";
  }, [emitters, pendingCredit]);

  return (
    <Box padding={isDownSm ? 2 : 3}>
      {emitters.length !== 0 ? (
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <Box mb={1} paddingBottom={1} borderColor="other.stroke" borderBottom={isDownSm && "1px solid"}>
              <Typography variant="h5" color="textPrimary">
                Datos del comprador
              </Typography>
            </Box>
          </Grid>
          {!emitters.find((item) => item.card_emiter_id === EMITTERS.CATAMARCA_CREDITS) ? (
            <Grid item>
              <FormRadioGroup row marginRight={isDownSm ? "0px" : "100px"} name="isResponsable" form={form} options={optionsInvoice} />
            </Grid>
          ) : null}

          {formInput.map((input) => (
            <CommonFormInput<Form>
              key={input.name}
              form={form}
              name={input.name}
              label={input.label}
              variant={input.variant}
              lg={input.lg}
              md={input.md}
              sm={input.sm}
              xs={input.xs}
              id={input.id}
              autoComplete={input.autoComplete}
              error={input.label === "CUIL" && pendingCredit}
              helperText={input.label === "CUIL" && pendingCredit ? "El CUIL ingresado cuenta con un crédito pendiente" : ""}
            />
          ))}

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <FormDatePicker
              form={form}
              fullWidth
              disableFuture
              openTo="year"
              error={birthdayValid}
              helperText={invalidDate ? "La fecha debe ser valida" : "Debes ser mayor de edad!"}
              name="personalInfo.dateOfBirty"
              views={["year", "month", "date"]}
              format="DD/MM/YYYY"
              label={<Box color="text.disabled">Fecha de nacimiento</Box>}
              inputLabelProps={{ shrink: true }}
            />
          </Grid>

          {reponsableInputs.map((input) => (
            <CommonFormInput<Form>
              key={input.name}
              form={form}
              name={input.name}
              label={input.label}
              variant={input.variant}
              lg={input.lg}
              md={input.md}
              sm={input.sm}
              xs={input.xs}
              autoComplete={input.autoComplete}
            />
          ))}

          {isResponsable && (
            <Grid item container direction="row" spacing={3}>
              <Grid item xs={12} sm={12} lg={8}>
                <Typography variant="caption" color="textSecondary">
                  Somos agente de percepción de IIBB en CABA y Prov. de Bs. As. Si estás exento de este impuesto, envianos los comprobantes a {}
                  <Link href={`mailto:${ecommerceConfig.general.contactUrl}`}>{ecommerceConfig.general.contactUrl}</Link>
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} />
          <Grid item xs={12}>
            <FormCheckbox
              label={
                <Typography variant="body2" color="inherit">
                  <span>Acepto los </span>
                  <Box color="primary.main" display="inline" onClick={openTermsAndConditions}>
                    términos y condiciones
                  </Box>
                  <span>. Autorizo el uso de mis datos y recibir mails.</span>
                </Typography>
              }
              name="personalInfo.termsAndConditions"
              form={form}
            />
          </Grid>

          {ReCaptchaVisible && !validIpAddress ? (
            <Grid item xs={12}>
              <ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_KEY} onChange={handleReCaptcha} />
            </Grid>
          ) : null}

          {isDownSm && (
            <Grid item xs={12}>
              <Button fullWidth disabled={disableNext} size="large" variant="contained" color="primary" onClick={onClickStep}>
                Continuar
              </Button>
            </Grid>
          )}

          <ModalDialog title="Crédito Pendiente" open={openModal} onHandleClose={handleModal}>
            <p>Usted ya posee una solicitud de préstamo creada, por favor, aguarde a que finalice su revisión para poder dar inicio a una nueva.</p>
          </ModalDialog>
        </Grid>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
