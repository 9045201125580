import React from "react";
import { Box, Divider, Grid, MenuItem, TextField, Theme, Typography, useMediaQuery } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import { InfoTable } from "src/commons/components";
import { CardData } from "src/app/models/Simulate";
import { CardType, Emitter, State, ConfigValuesPublication, defaultConfigValues } from "src/app/models";
import { columnsPaymentsWithStyles, formatPriceNotDecimal } from "src/utils";
import { EMITTERS } from "src/app/const/Emitters";
import { orderBy } from "lodash";
import { PaymentCard } from "../PaymentCard";
import { payMethodTableStyles as useStyles } from "../../styles";

interface PaymentMethodProps {
  publicConfig: ConfigValuesPublication;
  cards: CardType[];
  selectedCard: CardType;
  provinces: State[];
  selectedProvince: State;
  emitters: Emitter[];
  selectedEmitter: Emitter;
  cardOptionsSelected: CardData[];
  handleChangeCard: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  handleChangeEmitter: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  HandleChangeStateId: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PaymentMethod = ({
  publicConfig = defaultConfigValues,
  selectedCard,
  handleChangeCard,
  cardOptionsSelected,
  cards,
  emitters,
  selectedEmitter,
  handleChangeEmitter,
  selectedProvince,
  HandleChangeStateId,
  provinces,
}: PaymentMethodProps) => {
  const classes = useStyles();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));

  const emiterRowA = emitters.filter(
    (item) => item.card_emiter_id === EMITTERS.VISA || item.card_emiter_id === EMITTERS.MASTER || item.card_emiter_id === EMITTERS.AMEX,
  );

  const emiterRowB = emitters.filter(
    (item) =>
      item.card_emiter_id === EMITTERS.MERCADOPAGO ||
      item.card_emiter_id === EMITTERS.BAPRO ||
      item.card_emiter_id === EMITTERS.CATAMARCA_CREDITS ||
      item.card_emiter_id === EMITTERS.NARANJA,
  );

  const renderEmiter = (emitter: Emitter) => (
    <Box my={1} mx={isDownSm ? 0 : 1}>
      <PaymentCard
        width={isDownSm ? (!isXs ? "130px" : "100px") : "130px"}
        widthImg={isDownSm ? (!isXs ? "80px" : "60px") : "80px"}
        border={!isDownSm}
        image={emitter.image_url}
        value={emitter.card_emiter_id.toString()}
      />
    </Box>
  );

  const configs: ConfigValuesPublication = {
    ...defaultConfigValues,
    ...publicConfig,
    showPrice: { ...defaultConfigValues.showPrice, ...(publicConfig?.showPrice || {}) },
  };

  const isCatamarca = selectedEmitter.card_emiter_id === EMITTERS.CATAMARCA_CREDITS;

  return (
    <>
      <Box pb={2}>
        <Box pb={2}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1">Envio a:</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField fullWidth id="standard-select-province" select label="" value={selectedProvince.id} onChange={HandleChangeStateId}>
                {provinces.map((province) => (
                  <MenuItem key={province.id} value={province.id}>
                    {province.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>

        <Box pb={2}>
          <Typography variant="body1">Seleccioná el metodo de pago</Typography>
        </Box>
        <RadioGroup row value={selectedEmitter.card_emiter_id.toString()} onChange={handleChangeEmitter}>
          <Box display="flex" flexDirection="column" flexWrap="wrap">
            {emitters && (
              <>
                <Box display="flex" flexDirection={isXs ? "column" : ""} flexWrap="wrap">
                  {emiterRowA.map((emitter: Emitter) => renderEmiter(emitter))}
                </Box>
                <Box display="flex" flexDirection={isXs ? "column" : ""} flexWrap="wrap">
                  {emiterRowB.map((emitter: Emitter) => renderEmiter(emitter))}
                </Box>
              </>
            )}
          </Box>
        </RadioGroup>
      </Box>
      <Box>
        <Box pb={2}>
          <Typography variant="body1">{isCatamarca ? "Seleccioná el tipo" : "Seleccioná la tarjeta"}</Typography>
        </Box>

        <RadioGroup row value={selectedCard.card_id.toString()} onChange={handleChangeCard}>
          <Grid container justify="flex-start" direction="row">
            {cards.map((cardData) => (
              <Box my={1}>
                <PaymentCard
                  width={isDownSm ? "120px" : "130px"}
                  widthImg={isDownSm ? "70px" : "80px"}
                  border={false}
                  image={cardData.card_image_url}
                  value={cardData.card_id.toString()}
                  nameId={cardData.type}
                />
              </Box>
            ))}
          </Grid>
        </RadioGroup>
      </Box>
      <Box>
        {cardOptionsSelected?.map((option) => (
          <Box py={3}>
            <Box py={1}>
              <Typography variant="subtitle1">{option.card.name}</Typography>
            </Box>

            {!isDownSm ? (
              <InfoTable
                rowIdKey="installments"
                columns={columnsPaymentsWithStyles}
                rows={orderBy(option.payments, "installments", "desc").map((item) => ({
                  ...item,
                  config: { ...configs.showPrice },
                  isCatamarca,
                }))}
                key={option.card.card_id}
                orderBy="installments"
                customStyle={classes.tableContainer}
                hover={false}
                elevation={false}
              />
            ) : (
              <Box>
                {orderBy(option.payments, "installments", "desc").map((row) => (
                  <>
                    <Box justifyContent="space-between" flexDirection="row" display="flex" my={1}>
                      <Box flex width="70%">
                        <Typography variant="body1">
                          {`${row.installments} cuota${row.installments > 1 ? "s" : ""} de ${formatPriceNotDecimal(
                            (row.reference === "19" ? row[configs.showPrice.main_amount] : row.amount) / row.installments,
                          )}`}
                        </Typography>
                      </Box>

                      <Box flex width="30%">
                        <Typography variant="body1" align="right">
                          {formatPriceNotDecimal(row.reference === "19" ? row[configs.showPrice.main_amount] : row.amount)}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider />
                  </>
                ))}
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};
