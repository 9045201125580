// Palette
import palette from "../palette";

type MuiTabType = { [k: string]: any };

const MuiTab: MuiTabType = {
  root: {
    height: "50px",
    fontWeight: 400,
    textTransform: "none",
    fontSize: "14px",
    "@media (min-width: 960px)": {
      minWidth: "100px",
    },
    "&$selected": {
      fontWeight: 500,
    },
  },
  textColorPrimary: {
    color: palette.text.secondary,
  },
};

export default MuiTab;
