import React, { useCallback } from "react";
import { AccountInfoFormView } from "src/app/views";
import { useConfirmationDAO } from "src/app/business";
import { useSnackbar } from "notistack";
import { FieldValidator } from "final-form";
import { VERIFICATION_STATUS } from "src/app/models";
import { useRegisterFormContext } from "../../hooks";

export const AccountInfoForm = () => {
  const { form, values, errors, validating, handleSubmit } = useRegisterFormContext();
  const confirmationDAO = useConfirmationDAO();
  const { enqueueSnackbar } = useSnackbar();

  const validateEmail: FieldValidator<string> = useCallback(
    async (value: string, app, meta) => {
      if (!value || meta?.valid) return;
      try {
        const { isValid } = await confirmationDAO.verifyEmail(value);
        if (!isValid) return "Este email ya ah sido usado.";
      } catch (error) {
        console.log(error);
      }
    },
    [confirmationDAO],
  );

  const onSubmit = useCallback(async () => {
    if (Object.keys(errors as any).length !== 0) return;
    try {
      const result = await confirmationDAO.sendCode({ email: values.accountInfo.email });
      if (result.codeResult === VERIFICATION_STATUS.FAILED) {
        enqueueSnackbar("El email es incorrecto", { variant: "error" });
      } else if (result.codeResult === VERIFICATION_STATUS.SENT) {
        handleSubmit();
      }
    } catch (error) {
      enqueueSnackbar("Error al enviar el codigo", { variant: "error" });
    }
  }, [values.accountInfo.email, errors, confirmationDAO, handleSubmit, enqueueSnackbar]);

  return (
    <AccountInfoFormView
      form={form}
      disabledNext={Object.keys(errors as any).length !== 0 || validating}
      openTermsAndConditions={() => console.log("AKI")}
      onSubmit={onSubmit}
      validateEmail={validateEmail}
    />
  );
};
