import React from "react";
import { Grid, Typography, useMediaQuery, Box } from "@material-ui/core";
import { ecommerceConfig } from "src/config/ecommerce";
import { Feature } from "src/app/models";

interface ProductGeneralEspecificationsProps {
  especifications: Feature[];
  noTitle?: boolean;
}
export const ProductGeneralEspecifications = ({ especifications, noTitle = false }: ProductGeneralEspecificationsProps) => {
  const isSmDown = useMediaQuery<any>((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item xs>
        {!noTitle && !isSmDown && (
          <Typography variant="h6" color="primary">
            Especificaciones generales del producto
          </Typography>
        )}
      </Grid>
      <Grid item container justify="space-between" spacing={2}>
        {especifications.map((item, index) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            lg="auto"
            style={{
              borderLeft: !((index === 2 && isSmDown) || index === 0) ? `1px solid ${ecommerceConfig.ecommercePalette.materialUi.text.hint}` : "",
            }}
          >
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="body1">
                  <Box color={ecommerceConfig.ecommercePalette.materialUi.primary.dark}>{item.name}</Box>
                </Typography>
              </Grid>
              <Grid item>
                <Typography display="block" variant={isSmDown ? "h5" : "h4"} color="textPrimary">
                  {item.value}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
