import { EcommerceGeneral } from "../../../app/models/general/ecommerceGeneral";

export const general: EcommerceGeneral = {
  nameMp: "enova",
  address: "Uspallata 2776, Parque Patricios",
  contactUrl: "hola@tiendaenova.com.ar",
  facebookUrl: "https://www.facebook.com/enova.ar",
  instagramUrl: "http://instagram.com/novatechstore",
  title: "enova - Tecnología pensada para vos",
  description: "Tecnología diseñada para acompañarte en cada momento de tu vida.",
  defaultSearchText: "Todos los productos",
};
