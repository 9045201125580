import { JetUser } from "src/platform/libby/session";

export interface LoginStatusResponse {
  isGuest: boolean;
  user: JetUser;
}

export interface SingIn {
  provider?: string;
  token_id?: string;
  email?: string;
  password?: string;
  username?: string;
}

export enum OAuthProvider {
  GOOGLE = "google",
  FACEBOOK = "facebook",
}

interface Picture {
  data: {
    height: number;
    is_silhouette: boolean;
    url: string;
    width: number;
  };
}

export interface ProfileUserFB {
  id: string;
  name: string;
  email: string;
  picture: Picture;
  last_name: string;
  first_name: string;
}
