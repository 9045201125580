import { WatchMedia } from "src/screens";
import { MenuItem } from "../../../../commons/ui/menu";

export const routes: MenuItem[] = [
  {
    key: "privateWatchhMain",
    basePath: "/:id",
    target: {
      component: WatchMedia,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Watch Media",
    children: [],
    default: true,
  },
];
