import React from "react";

export const FIFALogo = () => (
  <svg width="47" height="14" viewBox="0 0 47 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.74707 0.00012207V13.9988H5.51253V8.9443H8.82372L10.111 5.88456H5.51253V3.04411H11.1095L12.3302 0.00012207H0.74707Z" fill="white" />
    <path d="M13.7874 0.000305176L13.7789 13.999H18.4892V0.000305176H13.7874Z" fill="white" />
    <path
      d="M36.9734 9.42643L38.7879 3.59781L40.6675 
            9.42643H36.9734ZM41.3101 0.000305176H36.4193L30.9965 14.0003H35.5489L36.1349 12.1178H41.4417L42.0552 
            14.0003H46.7471L41.3101 0.000305176Z"
      fill="white"
    />
    <path d="M21.7416 0.000305176V13.999H26.5042V8.94449H29.8119L31.1083 5.88474H26.5042V3.04429H32.1033L33.3332 0.000305176H21.7416Z" fill="white" />
    <defs>
      <clipPath id="clip0_2251_20734">
        <rect width="46" height="14" fill="white" transform="translate(0.746948)" />
      </clipPath>
    </defs>
  </svg>
);
