import React, { useState, useMemo } from "react";
import { AnyObject } from "src/commons";
import { CurrencyContext, CurrencyContextValue } from "./CurrencyContext";

export const CurrencyContextProvider = ({ children }: AnyObject) => {
  const [selectedCurrency, setSelectedCurrency] = useState<"USD" | "ETH">("ETH");

  const value = useMemo<CurrencyContextValue>(
    () => ({
      selectedCurrency,
      setSelectedCurrency,
    }),
    [selectedCurrency],
  );

  return <CurrencyContext.Provider value={value}>{children}</CurrencyContext.Provider>;
};
