import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = "relogin";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "relogin_id",
      labelKey: "name",
    },
    withSelectedChips: {
      idKey: "relogin_id",
      labelKey: "name",
    },
  },
};

const optionsToUse = !businessOptions.Relogin
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Relogin,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Relogin.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useReloginFetchById = fetchById;
export const useReloginLibbyCall = libbyCall;
export const useReloginDAO = libbyDAO;
export const useReloginLibbyFetch = libbyFetch;

// components/hoc
export const ReloginAutocomplete = entityAutocomplete;
export const ReloginCall = renderPropCall;
export const ReloginById = renderPropFetchById;
export const ReloginSelect = simpleSelect;
export const ReloginAccordionSelector = accordionSelector;
export const ReloginSelectedChips = selectedChips;

// context
// ByIdContext
export const useReloginByIdContext = useByIdContext;
export const ReloginByIdProvider = ByIdProvider;
export const ReloginByIdContext = ByIdContext;
// GeneralContext
// export const useReloginContext = useGeneralContext;
// export const ReloginProvider = GeneralProvider;
// export const ReloginContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
