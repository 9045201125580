import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../../lib/libby/generator";
import { Account } from "../../models";
import { businessOptions } from "../businessOptions";

const DAO_NAME = "account";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "account_id",
      labelKey: "username",
    },
    withSelectedChips: {
      idKey: "account_id",
      labelKey: "username",
    },
  },
};

const optionsToUse = !businessOptions.Account
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Account,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Account.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Account>(DAO_NAME, optionsToUse);

// hooks
export const useAccountFetchById = fetchById;
export const useAccountLibbyCall = libbyCall;
export const useAccountDAO = libbyDAO;
export const useAccountLibbyFetch = libbyFetch;

// components/hoc
export const AccountAutocomplete = entityAutocomplete;
export const AccountCall = renderPropCall;
export const AccountById = renderPropFetchById;
export const AccountSelect = simpleSelect;
export const AccountAccordionSelector = accordionSelector;
export const AccountSelectedChips = selectedChips;

// context
// ByIdContext
export const useAccountByIdContext = useByIdContext;
export const AccountByIdProvider = ByIdProvider;
export const AccountByIdContext = ByIdContext;
// GeneralContext
// export const useAccountContext = useGeneralContext;
// export const AccountProvider = GeneralProvider;
// export const AccountContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
