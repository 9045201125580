import { EcommerceDAO } from "./EcommerceDAO";
import { PromotionItem } from "../../../app/models";

export class PromotionItemDAO extends EcommerceDAO<PromotionItem> {
  constructor() {
    super("promotion_item", "promotionItemId");
  }

  getByPromotionId(promotionId: number | string) {
    return this.query().equals("promotion", promotionId).equals("available", true).orderBy("product.priority").run();
  }
}
