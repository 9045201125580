/* eslint-disable @typescript-eslint/dot-notation */
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "src/lib/templates";
import { AnyObject } from "src/commons";
import { EMITTERS } from "src/app/const/Emitters";
import { StepRecord } from "src/app/views";
import { DocenteInfoForm } from "src/screens/Public/Checkout/components/DocenteInfoForm";
import { AlumnInfoForm } from "src/screens/Public/Checkout/components/AlumnInfoForm";
import {
  paymentInitialValue,
  PaymentInitialValue,
  docentInitialValue,
  DocentInitialValue,
  alumnInitialValue,
} from "src/screens/Public/Checkout/initialValues";
import { Emitter } from "src/app/models";
import { CARD_PERSONAL_CREDIT, optionsDocentType } from "../constant";
import { docentInfoTemplate, personalInfoCaprescaTemplate, alumnInfoTemplate } from "../templates";

interface PersonalCreditLogic {
  steps: StepRecord[];
  setStepCheckout: React.Dispatch<React.SetStateAction<StepRecord[]>>;
  emitters: Emitter[];
}

export const usePersonalCreditLogic = ({ steps, setStepCheckout, emitters }: PersonalCreditLogic) => {
  // TODO: como es dinamico tiparlo trae problemas al querer setear el estado de la key si esta no esta en el initial value
  const { values } = useFormContext<AnyObject>();
  const [modify, setModify] = useState<boolean>(false);

  const valuesForm: { paymentMethod: PaymentInitialValue; docentInfo: DocentInitialValue<Partial<File> | undefined> } = {
    paymentMethod: paymentInitialValue,
    docentInfo: docentInitialValue,
    ...values,
  };

  const { emitter, card } = valuesForm.paymentMethod;

  const evaluateStepApplicant = steps.find((item) => item.label === "Datos del solicitante");

  const validStepper = useCallback(
    (newStepper) => {
      if (JSON.stringify(newStepper) !== JSON.stringify(steps)) {
        setStepCheckout(newStepper);
      }
    },
    [setStepCheckout, steps],
  );

  useEffect(() => {
    if (emitters.length !== 0) {
      if (
        evaluateStepApplicant &&
        emitters.find((item) => item.card_emiter_id === EMITTERS.CATAMARCA_CREDITS) &&
        evaluateStepApplicant?.hidden &&
        !modify
      ) {
        const newSteper = steps.map((item) => {
          if (item.label === "Datos del comprador") return { ...item, template: personalInfoCaprescaTemplate };
          if (item.label === "Datos del solicitante") return { ...item, hidden: false };
          return item;
        });
        validStepper(newSteper);
      } else if (evaluateStepApplicant && !evaluateStepApplicant.hidden && emitter.card_emiter_id !== EMITTERS.CATAMARCA_CREDITS && !modify) {
        const newSteper = steps.map((item) => {
          if (item.label === "Datos del solicitante") return { ...item, hidden: true };
          return item;
        });
        validStepper(newSteper);
      }
      setModify(true);
    } else {
      setModify(false);
    }
  }, [emitter.card_emiter_id, emitters, evaluateStepApplicant, modify, steps, validStepper]);

  useEffect(() => {
    if (
      evaluateStepApplicant &&
      emitter.card_emiter_id === EMITTERS.CATAMARCA_CREDITS &&
      card.card_id.toString() === CARD_PERSONAL_CREDIT.DOCENTES.toString() &&
      (valuesForm.docentInfo.docentType.id === optionsDocentType[0].id ||
        valuesForm.docentInfo.docentType.id.toString() === "" ||
        valuesForm.docentInfo.docentType.id === 0)
    ) {
      const newSteper = steps.map((item) => {
        if (item.label === "Datos del solicitante")
          return { ...item, template: docentInfoTemplate, initialValue: docentInitialValue, render: DocenteInfoForm };
        if (item.label === "Datos del garante") return { ...item, hidden: true };
        return item;
      });
      validStepper(newSteper);
    } else if (
      evaluateStepApplicant &&
      emitter.card_emiter_id === EMITTERS.CATAMARCA_CREDITS &&
      card.card_id.toString() === CARD_PERSONAL_CREDIT.ALUMNOS.toString()
    ) {
      const newSteper = steps.map((item) => {
        if (item.label === "Datos del solicitante")
          return { ...item, template: alumnInfoTemplate, initialValue: alumnInitialValue, render: AlumnInfoForm };
        if (item.label === "Datos del garante") return { ...item, hidden: false };
        return item;
      });
      validStepper(newSteper);
    }

    if (
      valuesForm.docentInfo.docentType.id !== 0 &&
      valuesForm.docentInfo.docentType.id !== 1 &&
      valuesForm.docentInfo.docentType.id.toString() !== ""
    ) {
      const newSteper = steps.map((item) => {
        if (item.label === "Datos del garante") return { ...item, hidden: false };
        return item;
      });
      validStepper(newSteper);
    }
  }, [card.card_id, emitter.card_emiter_id, evaluateStepApplicant, steps, validStepper, valuesForm.docentInfo.docentType.id]);
};
