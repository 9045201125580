import React, { useState, useCallback } from "react";
import { Dialog, DialogContent, Box, Grid, Typography, Link as LinkMaterial, CircularProgress, Button, ClickAwayListener } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import { CustomButtonProps } from "src/commons/components/CustomBotton";
import { Publication } from "src/app/models";
import { useWalletContext } from "src/context/WalletContext";
import { ErrorIcon } from "src/assets/images/svgs/ErrorIcon";
import { CheckIcon } from "src/assets/images/svgs/CheckIcon";
import { Link } from "react-router-dom";
import { ecommerceConfig } from "src/config/ecommerce";

interface PurchaseNFTModalProps {
  item: Publication;
  disabled?: boolean;
}

const PurchaseNFTModalStyle = makeStyles(() => ({
  explore: {
    cursor: "pointer",
    padding: "9px 36px",
    alignItems: "center",
    borderRadius: "32px",
    border: "1px solid #737373",
  },
  close: {
    cursor: "pointer",
    position: "absolute",
    top: "16px",
    right: "16px",
  },
}));

export const PurchaseNFTModal = ({ item, disabled }: PurchaseNFTModalProps) => {
  const [modalContent, setModalContent] = useState<JSX.Element | undefined>();
  const { isConnected, strategy } = useWalletContext();

  const classes = PurchaseNFTModalStyle();

  const onHandlePurchase = useCallback(async () => {
    try {
      if (!isConnected) {
        setModalContent(
          <ClickAwayListener onClickAway={() => setModalContent(undefined)}>
            <Box
              component={DialogContent}
              boxShadow=" inset 0px 0px 8px 2px rgba(112, 167, 54, 0.4)"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              position="relative"
              padding="24px 32px"
              borderRadius="4px"
            >
              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} container justify="center" spacing={1}>
                  <Grid item>
                    <Typography variant="subtitle2">Necesitas una billetera para continuar!</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <Box
                    color="neutral.6"
                    onClick={() => setModalContent(undefined)}
                    padding="12px 24px"
                    component={(props: any) => (
                      <Button type="button" variant="contained" {...props}>
                        Entendido
                      </Button>
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </ClickAwayListener>,
        );
        return;
      }
      const res = await strategy?.buyNFT(item.attributes.tokenId, item.old_price);
      setModalContent(
        <Box
          component={DialogContent}
          boxShadow=" inset 0px 0px 8px 2px rgba(112, 167, 54, 0.4)"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          position="relative"
          padding="24px 32px"
          borderRadius="4px"
        >
          <Grid container justify="center" spacing={4}>
            <Grid item xs={12} container justify="center">
              <Box component={(props: any) => <CircularProgress size={56} {...props} />} color="primary.main" />
            </Grid>
            <Grid item xs={12} container justify="center" spacing={1}>
              <Grid item>
                <Typography variant="subtitle2">Procesando compra</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container justify="center" spacing={1}>
              <Grid item xs={12} container justify="center">
                <Box component="div" color="neutral.2">
                  <Typography variant="body2">TRANSACTION HASH</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} container justify="center">
                <Grid item zeroMinWidth>
                  <LinkMaterial
                    href={`https://ropsten.etherscan.io/tx/${res?.hash}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#94f034" }}
                  >
                    <Typography noWrap variant="body2" color="primary">
                      {res?.hash}
                    </Typography>
                  </LinkMaterial>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>,
      );
      await res.wait();
      setModalContent(
        <ClickAwayListener onClickAway={() => setModalContent(undefined)}>
          <Box
            component={DialogContent}
            boxShadow=" inset 0px 0px 8px 2px rgba(112, 167, 54, 0.4)"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            position="relative"
            padding="24px 32px"
            borderRadius="4px"
          >
            <Grid container justify="center" spacing={4}>
              <Grid item xs={12} container justify="center">
                <Box height="100px" alignItems="center">
                  <CheckIcon />
                </Box>
              </Grid>
              <Grid item xs={8} container justify="center" spacing={1}>
                <Grid item>
                  <Typography variant="subtitle2">¡Compra exitosa!</Typography>
                </Grid>
                <Grid item>
                  <Box color="neutral.2" textAlign="center" maxWidth="168px">
                    <Typography variant="body2">
                      Podés ver el NFT en{" "}
                      <Link
                        to="/profile"
                        style={{
                          textDecoration: "none",
                          color: ecommerceConfig.ecommercePalette.materialUi.primary.main,
                        }}
                      >
                        tu perfil
                      </Link>{" "}
                      o seguir explorando
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} justify="center" container>
                <Box
                  onClick={() => setModalContent(undefined)}
                  color="neutral.3"
                  className={classes.explore}
                  component={(props: any) => (
                    <Typography variant="h5" {...props}>
                      EXPLORAR
                    </Typography>
                  )}
                />
              </Grid>
            </Grid>
            <Box className={classes.close} onClick={() => setModalContent(undefined)}>
              <CloseIcon />
            </Box>
          </Box>
        </ClickAwayListener>,
      );
    } catch (e) {
      console.log("Error", e);
      setModalContent(
        <ClickAwayListener onClickAway={() => setModalContent(undefined)}>
          <Box padding="2px">
            <Box
              component={DialogContent}
              boxShadow="0px 0px 10px 3px rgba(255, 0, 124, 0.4), inset 0px 0px 5px rgba(255, 0, 124, 0.4)"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              position="relative"
              padding="24px 24px"
              borderRadius="4px"
              maxWidth="360px"
            >
              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} container justify="center">
                  <Box maxHeight="100px" justifyContent="center" alignItems="center">
                    <ErrorIcon />
                  </Box>
                </Grid>
                <Grid item xs={12} container justify="center">
                  <Typography variant="subtitle2">
                    {e.message.includes("insufficient funds")
                      ? "No cuentas con los ETH suficientes para realizar esta transacción"
                      : "Sucedió un error"}
                  </Typography>
                </Grid>
                <Grid item xs={12} justify="center" container>
                  <Box
                    onClick={() => setModalContent(undefined)}
                    color="neutral.3"
                    className={classes.explore}
                    component={(props: any) => (
                      <Typography variant="h5" {...props}>
                        INTÉNTALO OTRA VEZ
                      </Typography>
                    )}
                  />
                </Grid>
              </Grid>
              <Box onClick={() => setModalContent(undefined)} className={classes.close}>
                <CloseIcon />
              </Box>
            </Box>
          </Box>
        </ClickAwayListener>,
      );
    }
  }, [item, isConnected, strategy, classes]);

  return (
    <>
      <CustomButtonProps mt={3} name="COMPRAR" disabled={disabled} onClick={onHandlePurchase} />
      <Dialog open={!!modalContent} maxWidth="xs" style={{ padding: "24px, 32px, 24px, 32px", width: "auto" }}>
        {modalContent}
      </Dialog>
    </>
  );
};
