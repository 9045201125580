import { useState, ChangeEvent, useEffect } from "react";
import { useDebounce } from "src/commons";
import { useLocationDAO, useLocationLibbyCall } from "src/app/business";
import { City, State } from "src/app/models";
import { ValidationErrors } from "final-form";
import { LogicAddress, ValuesForm } from "../types";

export const useLogicAddress = (values: ValuesForm, errors: ValidationErrors): LogicAddress => {
  const [cities, setCities] = useState<City[]>([]);
  const [search, setSearch] = useState("");
  const searchDebunce = useDebounce(search, 600);
  const [validZipCode, setValidZipCode] = useState<boolean>(false);

  const locationDAO = useLocationDAO();
  const { data, working } = useLocationLibbyCall<State[]>({ methodName: "getStates" });

  const selectedProvince = values.summaryAddress.province?.id !== "";

  const zitCode = values.summaryAddress.zipCode || "";

  const citySelected = values.summaryAddress.city || {};

  const onInputChangeCity = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    setSearch(value);
  };

  useEffect(() => {
    if (selectedProvince && searchDebunce) {
      (async () => {
        const city = await locationDAO.getCityByState(values.summaryAddress.province?.id, searchDebunce);
        setCities(city);
      })();
    }
    if (zitCode) setValidZipCode(!citySelected.zip?.includes(Number(zitCode)));
  }, [selectedProvince, locationDAO, values.summaryAddress.province, searchDebunce, citySelected.zip, zitCode]);
  return {
    validZipCode,
    onInputChangeCity,
    provinces: data || [],
    working,
    selectedProvince,
    isResponsable: values.isResponsable === "true",
    cities,
    disableNext: !Object.keys(errors as any).length,
    search,
    zipCodeLength: values.deliveryInfo.zipCode.length || 0,
  };
};
