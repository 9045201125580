import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../../lib/libby/generator";
import { PromotionItem } from "../../models";
import { businessOptions } from "../businessOptions";

const DAO_NAME = "promotion_item";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "idPromotionItem",
      labelKey: "descripcion",
    },
    withSelectedChips: {
      idKey: "idPromotionItem",
      labelKey: "descripcion",
    },
  },
};

const optionsToUse = !businessOptions.PromotionItem
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PromotionItem,
      hoc: { ...defaultOptions.hoc, ...businessOptions.PromotionItem.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PromotionItem>(DAO_NAME, optionsToUse);

// hooks
export const usePromotionItemFetchById = fetchById;
export const usePromotionItemLibbyCall = libbyCall;
export const usePromotionItemDAO = libbyDAO;
export const usePromotionItemLibbyFetch = libbyFetch;

// components/hoc
export const PromotionItemAutocomplete = entityAutocomplete;
export const PromotionItemCall = renderPropCall;
export const PromotionItemById = renderPropFetchById;
export const PromotionItemSelect = simpleSelect;
export const PromotionItemAccordionSelector = accordionSelector;
export const PromotionItemSelectedChips = selectedChips;

// context
// ByIdContext
export const usePromotionItemByIdContext = useByIdContext;
export const PromotionItemByIdProvider = ByIdProvider;
export const PromotionItemByIdContext = ByIdContext;
// GeneralContext
export const usePromotionItemContext = useGeneralContext;
export const PromotionItemProvider = GeneralProvider;
export const PromotionItemContext = GeneralContext;
