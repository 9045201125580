import React, { FunctionComponent, useCallback, useState } from "react";
import { makeStyles, useMediaQuery, Theme, Typography, Grid } from "@material-ui/core";
import { AppFooter } from "src/app/views";
import { MobileSearchIcon } from "src/assets/images/svgs";
import { useLocation } from "react-router";
import { MenuCollection } from "../../ui/menu";
import { AppHeader } from "../../../app/views/components/AppHeader";
import { SideMenu } from "../SideMenu";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    minHeight: 800,
    paddingTop: theme.spacing(0.125),
    position: "relative",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  searchText: {
    fontFamily: "Readex Pro",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
    textAlign: "center",
    color: "#ABABAB",
    marginTop: -50,
  },
  paperProps: { position: "absolute", height: "100%", marginTop: "-6px", marginBottom: "-30px" },
  modalProps: { position: "absolute" },
}));

interface MainLayoutProps {
  children: React.ReactNode;
  menu: MenuCollection;
}

export const MainLayout: FunctionComponent<MainLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const [mobileInputVisible, setMobileInputVisible] = useState(false);

  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const toggleInput = useCallback(() => setMobileInputVisible(!mobileInputVisible), [setMobileInputVisible, mobileInputVisible]);

  const location = useLocation();

  return isDownSm ? (
    <div id="section_img">
      <AppHeader mobileInputVisible={mobileInputVisible} toggleInput={toggleInput} />
      <main className={classes.mainContainer}>
        {mobileInputVisible ? (
          <Grid className={classes.searchContainer}>
            <MobileSearchIcon />
            <Typography className={classes.searchText}>
              Buscá a tu estrella favorita,
              <br />
              equipo o torneo{" "}
            </Typography>
          </Grid>
        ) : (
          children
        )}
      </main>
      <AppFooter
        title="¡Recibí los mejores descuentos!"
        email=""
        onChangeEmail={() => console.log("change email")}
        onSubcriber={() => console.log("on subcriber")}
        optionLists={[]}
      />
    </div>
  ) : (
    <div id="section_img">
      <AppHeader toggleInput={toggleInput} />
      <main className={classes.mainContainer}>
        <>
          {!isDownSm && location.pathname.includes("/dashboard") && (
            <SideMenu
              modalsProps={{
                container: document.getElementById("drawer-container"),
                className: classes.modalProps,
              }}
              paperProps={{ className: classes.paperProps }}
            />
          )}
          {children}
        </>
      </main>
      <AppFooter
        title="¡Recibí los mejores descuentos!"
        email=""
        onChangeEmail={() => console.log("change email")}
        onSubcriber={() => console.log("on subcriber")}
        optionLists={[]}
      />
    </div>
  );
};
