import { routes } from "./routes";
import { MenuItem } from "../../../../commons/ui/menu";

export const watchMediaMenu: MenuItem = {
  key: "privateWatchMedia",
  basePath: "/watch",
  enabled: true,
  icon: "List",
  text: "Watch",
  children: routes,
};
