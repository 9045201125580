import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = "order_nft";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "idOrderNftnft",
      labelKey: "descripcion",
    },
    withSelectedChips: {
      idKey: "idOrderNftnft",
      labelKey: "descripcion",
    },
  },
};

const optionsToUse = !businessOptions.OrderNft
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.OrderNft,
      hoc: { ...defaultOptions.hoc, ...businessOptions.OrderNft.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useOrderNftFetchById = fetchById;
export const useOrderNftLibbyCall = libbyCall;
export const useOrderNftDAO = libbyDAO;
export const useOrderNftLibbyFetch = libbyFetch;

// components/hoc
export const OrderNftAutocomplete = entityAutocomplete;
export const OrderNftCall = renderPropCall;
export const OrderNftById = renderPropFetchById;
export const OrderNftSelect = simpleSelect;
export const OrderNftAccordionSelector = accordionSelector;
export const OrderNftSelectedChips = selectedChips;

// context
// ByIdContext
export const useOrderNftByIdContext = useByIdContext;
export const OrderNftByIdProvider = ByIdProvider;
export const OrderNftByIdContext = ByIdContext;
// GeneralContext
// export const usePaymentContext = useGeneralContext;
// export const PaymentProvider = GeneralProvider;
// export const PaymentContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
