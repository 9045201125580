import { DappWalletManager } from "src/platform/DappWallets/DappWalletManager";
import { Listeners } from "../common";

export const CoinbaseListeners: Listeners[] = [
  {
    wallet: "coinbase",
    event: "connect",
    acction: async ({ param0, strategy, setState }) => {
      const address = param0;
      DappWalletManager.AddressWallet = address;
      setState((old) => ({ ...old, address, isConnected: true, strategy }));
    },
  },
];
