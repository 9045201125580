import React, { useMemo } from "react";
import { Grid, useMediaQuery, Theme, Typography } from "@material-ui/core";
import { StepFormRecord } from "src/commons";
import ReactHtmlParser from "react-html-parser";
import { SingIn } from "src/app/models";
import { SocialButton } from "../LoginView/SocialButton";

export interface RegisterViewProps {
  steps: StepFormRecord[];
  activeStep: number;
  email: string;
  handlerSocialSingIn: ({ provider }: SingIn) => void;
}

export const RegisterView = ({ steps, activeStep, email, handlerSocialSingIn }: RegisterViewProps) => {
  const isDown768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));

  const Comp = useMemo(() => steps[activeStep].render, [steps, activeStep]);
  const hiddenSocialButtons = useMemo(() => !steps[activeStep].title /* !== 'Confirmación' */, [steps, activeStep]);

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} container spacing={1} justify="center">
        <Grid item xs={12}>
          <Typography variant="h4" align="center" color="textPrimary">
            {steps[activeStep].title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <Typography variant="body1" color="textSecondary">
            {ReactHtmlParser(steps[activeStep].subTitle.replace("$1", email))}
          </Typography>
        </Grid>
      </Grid>

      <Comp />

      {hiddenSocialButtons && (
        <Grid item lg={4} md={4} sm={isDown768 ? 6 : 12} xs={12}>
          <SocialButton handlerSocialSingIn={handlerSocialSingIn} />
        </Grid>
      )}
    </Grid>
  );
};
