import React, { FunctionComponent, Fragment, useMemo } from "react";
import { Divider } from "@material-ui/core";
import { MenuDivider, MenuItem } from "../MenuItem";
import { MenuGroup } from "./MenuGroup";

export type MenuListProps = {
  menus: (MenuItem | MenuDivider)[];
  onOpen: (item: MenuItem) => void;
};

const isMenuDivider = (menu: MenuItem | MenuDivider): menu is MenuDivider => (menu as MenuDivider).divider !== undefined;

export const MenuList: FunctionComponent<MenuListProps> = ({ menus, onOpen }) => {
  // We split the menus in groups.
  const memoizedMenus = useMemo(() => {
    const _menus: Array<Array<MenuItem>> = [];
    let activeMenu: Array<MenuItem> | undefined;
    menus.forEach((m) => {
      if (isMenuDivider(m)) {
        if (activeMenu) activeMenu = undefined;
      } else {
        if (!activeMenu) {
          activeMenu = new Array<MenuItem>();
          _menus.push(activeMenu);
        }
        activeMenu.push(m);
      }
    });
    return _menus;
  }, [menus]);
  return (
    <>
      {memoizedMenus.map((menu) => (
        <Fragment key={`Group${menu}`}>
          <Divider />
          <MenuGroup menus={menu} open onOpen={onOpen} />
        </Fragment>
      ))}
    </>
  );
};
