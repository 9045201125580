import { DappWalletStrategy } from "./DappWalletStrategy";
import { MetaMask, WalletConnect, Coinbase } from "./Strategies";
import { Wallet } from "./Strategies/common";

class DappWalletFactory {
  private walletStrategies = new Map<Wallet, DappWalletStrategy>();

  constructor() {
    this.register(new MetaMask());
    this.register(new WalletConnect());
    this.register(new Coinbase());
  }

  get Strategies() {
    return this.walletStrategies;
  }

  register(strategy?: DappWalletStrategy): void {
    if (strategy) this.walletStrategies.set(strategy.name, strategy);
  }

  get(name: Wallet): DappWalletStrategy {
    const strategy = this.walletStrategies.get(name);
    if (!strategy) throw new Error(`${name} not a valid wallet strategy`);
    return strategy;
  }
}

const intance = new DappWalletFactory();
export { intance as DappWalletFactory };
