import { createContext, useContext } from "react";
import { JetUser, JetGroups } from "src/platform/libby/session";

export type UserRole = {
  rol_id: string;
  rol_name: string;
};

export interface UserInfo extends JetUser {}

const UserIfnoInitialValue: UserInfo = {
  id: "",
  email: "",
  name: "",
  lastname: "",
  isGuest: true,
  nick: "",
  username: "",
  groups: new Array<JetGroups>(),
};

export type RolesContextValue = {
  selectedRole: UserRole;
  setSelectedRole: (selectedRole: UserRole) => void;
  deselectRole: () => void;
  setUserRoles: (userRoles: UserRole[]) => void;
  userRoles: UserRole[];
  userInfo: UserInfo;
  setUserInfo: (userInfo: UserInfo) => void;
};

export const RolesContext = createContext<RolesContextValue>({
  selectedRole: {
    rol_id: "",
    rol_name: "",
  },
  setSelectedRole: () => {},
  deselectRole: () => {},
  userRoles: [],
  setUserRoles: () => {},
  userInfo: UserIfnoInitialValue,
  setUserInfo: () => {},
});

export const useRolesContext = () => useContext(RolesContext);
