import { BuyerWallet } from "../../../app/models";
import { EcommerceDAO } from "./EcommerceDAO";

export class BuyerWalletDAO extends EcommerceDAO<BuyerWallet> {
  constructor() {
    super("buyer_wallet", "buyer_wallet_id");
  }

  async getBuyerWallets() {
    const data = await this.fetchData(`/buyer/wallet`);
    return data.json();
  }

  async save(data: BuyerWallet) {
    await this.fetchData(`/buyer/wallet`, {
      method: data.buyer_id ? "PUT" : "POST",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(data),
    });
  }

  async fetchById(id: string) {
    const data = await this.fetchData(`/buyer/wallet/${id}`);
    return [await data.json()];
  }

  async search(params: { [k: string]: string }) {
    const paramsQuery = Object.entries(params);
    let query = "";
    paramsQuery.forEach((param, index) => {
      query += `${param[0]}=${param[1]}`;
      if (paramsQuery.length !== index + 1) query += "&";
    });
    const data = await this.fetchData(paramsQuery.length > 0 ? `/buyer/wallet/search?${query}` : `/buyer/wallet/search`);
    return [await data.json()];
  }

  async getBuyerByWallet(wallet_id: string) {
    const data = await this.fetchData(`/buyer/wallet/nick?wallet=${wallet_id}`);
    return data.json();
  }
}
