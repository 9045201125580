import React from "react";
import { UserAvatar } from "src/commons/components";
import { grey } from "src/theme/colors";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid, IconButton } from "@material-ui/core";

interface ListAvatarTypes {
  image: string;
  title: string;
  secondaryTitle?: string;
  subtitle: string;
  onClick: () => void;
}

const useStyles = makeStyles({
  listContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 12,
    paddingTop: 12,
    "&:hover": {
      backgroundColor: "white",
    },
  },
  textContainer: {
    paddingLeft: 10,
    flexDirection: "column",
    alignItems: "center",
  },
  titleStyle: {
    color: grey.heading,
    fontSize: 11,
  },
  subtitleStyle: {
    fontSize: 12,
    fontWeight: 700,
    textAlign: "left",
    color: grey.heading,
  },
  secondaryTitleStyle: {
    paddingLeft: 7,
    fontSize: 11,
    color: grey.disabled,
  },
  titlesRow: {
    display: "flex",
    flexDirection: "row",
  },
});

export const ListAvatar = ({ image, title, subtitle, secondaryTitle, onClick }: ListAvatarTypes) => {
  const classes = useStyles();
  return (
    <Grid>
      <IconButton className={classes.listContainer} onClick={onClick}>
        <Grid>
          <UserAvatar avatarWidth={30} avatarHeight={30} img={image} />
        </Grid>
        <Grid className={classes.textContainer}>
          <Grid className={classes.titlesRow}>
            <Typography className={classes.titleStyle}>{title}</Typography>
            {secondaryTitle && <Typography className={classes.secondaryTitleStyle}>{secondaryTitle}</Typography>}
          </Grid>
          <Typography className={classes.subtitleStyle}>{subtitle}</Typography>
        </Grid>
      </IconButton>
    </Grid>
  );
};
