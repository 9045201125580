import React from "react";
import { useMediaQuery, Theme } from "@material-ui/core";
import palette from "src/theme/palette";
import { Link } from "react-router-dom";
import { EmpyCart } from "src/assets/images/svgs";
import { PaymentMsg } from "../Payment/Payment";
import { approvedPayment as useStyles } from "../../styles";

export const CartEmpy = () => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const color = palette.success.dark;
  const classes = useStyles({ color });
  return (
    <PaymentMsg
      title="Tu carrito está vacío"
      color="text.primary"
      description={
        <>
          {`Probá agregar tu primer producto. `}
          <Link to="/search" className={classes.lastLink}>
            Explorar categorías
          </Link>
        </>
      }
      img={<EmpyCart width={isDownSm ? 303 : 506} height={isDownSm ? 213 : 326} />}
    />
  );
};
