import React from "react";
import { StepFormRecord } from "src/commons";
import { RegisterView } from "src/app/views";
import { useRegisterFormContext, useStepTemplateLogic } from "../hooks";
import { useLogin } from "../../Login/hooks";

interface RegisterFormProps {
  activeStep: number;
  steps: StepFormRecord[];
  completedSteps: Set<number>;
}

export const RegisterForm = ({ activeStep, steps, completedSteps }: RegisterFormProps) => {
  useStepTemplateLogic(activeStep, completedSteps, steps);
  const { values } = useRegisterFormContext();
  const { singIn } = useLogin({ redirectOnSuccess: "/register/success", msgOnSuccess: "Registro completado!" });

  return <RegisterView steps={steps} activeStep={activeStep} email={values.accountInfo.email} handlerSocialSingIn={singIn} />;
};
