import React, { useMemo, useCallback, useState } from "react";
import { Theme, useMediaQuery, Container, Box, Grid, Typography, Button } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useBreadcrumbs, Loading, usePagination, useFavoriteManage } from "src/commons";
import { AppBreadcrumbsApp, ProductCardView } from "src/app/views";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useScrollToTopOnMount } from "src/commons/hooks/useScrollToTopUnMount";
import { PublicationFavoriteProduct } from "src/app/models";
import { Pagination } from "@material-ui/lab";
import { normalizePublications } from "src/utils";
import { useSnackbar } from "notistack";
import { Product } from "src/app/models/business/Product";
import { useHistory } from "react-router";

export const Favorites = () => {
  useScrollToTopOnMount();
  const [blackList, setBlackList] = useState<string[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isBw768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));

  const { favorites, loading, addToFavorite, removeFromFavorite } = useFavoriteManage();

  const filterFavorites = useMemo(() => favorites.filter((fav) => !blackList.includes(fav.publication.id)), [favorites, blackList]);

  const { list, pagination, onChangePagination } = usePagination({ items: filterFavorites, orderBy: "created_at", direction: "desc" });

  const paginationNumber = useMemo(() => pagination.allCount / pagination.perPage, [pagination]);
  const resultPaginationNumber = useMemo(() => (paginationNumber % 1 !== 0 ? paginationNumber + 1 : paginationNumber), [paginationNumber]);

  const links = useBreadcrumbs({
    links: isDownSm
      ? [
          { label: "Compra", url: "/dashboard" },
          { label: "Favoritos", url: "/dashboard/purchases" },
          { label: "Mi cuenta", url: "/dashboard/favorites" },
        ]
      : [
          { label: "Mi cuenta", url: "/dashboard" },
          { label: "Compras", url: "/dashboard/purchases" },
          { label: "Favoritos", url: "/dashboard/favorites" },
        ],
  });

  const items = useMemo<PublicationFavoriteProduct[]>(
    () => list.map((item) => ({ ...item, publication: normalizePublications([item.publication])[0] })),
    [list],
  );

  const onUndoAction = useCallback(
    async (item: PublicationFavoriteProduct) => {
      try {
        await addToFavorite(item.publication.productId.toString());
        setBlackList((old) => old.filter((_item) => _item !== item.publication.productId.toString()));
      } catch (error) {
        console.log("error al deshacer");
      } finally {
        closeSnackbar();
      }
    },
    [addToFavorite, closeSnackbar],
  );

  const onRemoveFavorite = useCallback(
    async (item: PublicationFavoriteProduct) => {
      try {
        await removeFromFavorite(item.publication.productId.toString());
        setBlackList((old) => [...old, item.publication.productId.toString()]);
        enqueueSnackbar("Eliminaste tu favorito seleccionado", {
          variant: "success",
          action: (
            <Button color="inherit" size="small" onClick={() => onUndoAction(item)}>
              Deshacer
            </Button>
          ),
        });
      } catch (error) {
        console.log("error al eliminar ", error);
        enqueueSnackbar("No se pudo eliminar su favorito", { variant: "error" });
      }
    },
    [removeFromFavorite, enqueueSnackbar, onUndoAction],
  );

  const onClickProduct = useCallback(
    (item: Product) => {
      history.push(`/product/${item.productId}`);
    },
    [history],
  );

  return (
    <>
      <Helmet>
        <title>Favoritos</title>
        <meta name="description" content="Productos favoritos" />
      </Helmet>
      <Container maxWidth="lg">
        <Box px={isDownSm ? 0 : "10%"} mt={2}>
          <AppBreadcrumbsApp
            links={links}
            iconSeparator={<Box component="div">/</Box>}
            iconGoBack={<ChevronLeftIcon color="primary" />}
            invert
            msgReturn=" "
            noMargin
          />

          <Grid container item spacing={isDownSm ? 0 : 2} xs={12} sm={isBw768 ? 8 : 12} md={8}>
            {!isDownSm && (
              <Grid item>
                <Typography variant="h5" color="textPrimary">
                  Favoritos
                </Typography>
              </Grid>
            )}

            {loading ? (
              <Loading />
            ) : (
              <Grid item container spacing={2}>
                <Grid item container justify="space-between">
                  <Grid item>
                    <Typography variant="caption" color="textSecondary">
                      {list?.length > 0
                        ? `${list?.length} favorito${list?.length > 1 ? "s" : ""} seleccionado${list?.length > 1 ? "s" : ""}`
                        : "0 favoritos seleccionados"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container>
                  {items.map((item) => (
                    <Box width="100%" key={item.publication.productId} pb={3}>
                      <ProductCardView
                        data={item.publication}
                        viewGrid={false}
                        withStock
                        onClick={() => onClickProduct(item.publication)}
                        onRemoveFavorite={() => onRemoveFavorite(item)}
                        onAddFavorite={() => {}}
                        isFavorite={false}
                      />
                    </Box>
                  ))}
                </Grid>
                {pagination.allCount > pagination.perPage && (
                  <Grid container alignItems="center" justify="center">
                    <Pagination
                      count={Math.trunc(resultPaginationNumber)}
                      page={pagination.currentPage}
                      color="primary"
                      onChange={(event, page) => onChangePagination(page)}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  );
};
