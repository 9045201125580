import { PaymentCancelation } from "src/app/models";

export const initialValue: PaymentCancelation = {
  firstName: "",
  lastName: "",
  DNI: "",
  phoneNumber: "",
  email: "",
  emailConfirm: "",
  buyNumber: "",
  product: "",
  motive: "",
};
