import React from "react";

export const MobileSearchIcon = () => (
  <svg width="293" height="293" viewBox="0 0 293 293" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_2280_21925)">
      <path
        d="M146.466 173C131.857 173 120 161.155 120 146.5C120 131.872 131.857 120 146.466 
            120C161.101 120 172.931 131.872 172.931 146.5C172.931 161.155 161.101 173 146.466 173Z"
        stroke="#95F035"
        strokeWidth="6.47308"
      />
    </g>
    <g filter="url(#filter1_f_2280_21925)">
      <path
        d="M146.466 173C131.857 173 120 161.155 120 146.5C120 131.872 131.857 120 146.466 
            120C161.101 120 172.931 131.872 172.931 146.5C172.931 161.155 161.101 173 146.466 173Z"
        stroke="#95F035"
        strokeWidth="8.4648"
      />
    </g>

    <g>
      <path
        d="M146.466 173C131.857 173 120 161.155 120 146.5C120 131.872 131.857 120 146.466 
            120C161.101 120 172.931 131.872 172.931 146.5C172.931 161.155 161.101 173 146.466 173Z"
        stroke="#95F035"
        strokeWidth="3.98343"
      />
    </g>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.466 173C131.857 173 120 161.155 
            120 146.5C120 131.872 131.857 120 146.466 120C161.101 120 172.931 131.872 172.931 146.5C172.931 161.155 161.101 173 146.466 173Z"
        stroke="white"
        strokeWidth="1.99172"
      />
    </g>
    <g filter="url(#filter5_b_2280_21925)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.456 172.926C131.852 172.926 120 
            161.097 120 146.463C120 131.855 131.852 120 146.456 120C161.086 120 172.911 131.855 
            172.911 146.463C172.911 161.097 161.086 172.926 146.456 172.926Z"
        fill="#95F035"
        fillOpacity="0.01"
      />
    </g>
    <g filter="url(#filter6_f_2280_21925)">
      <path d="M113 160.8V159H116.94V155H119.04V159H123.02V160.8H119.04V164.66H116.94V160.8H113Z" fill="white" />
    </g>
    <g filter="url(#filter7_f_2280_21925)">
      <path d="M169 125.8V124H172.94V120H175.04V124H179.02V125.8H175.04V129.66H172.94V125.8H169Z" fill="white" />
    </g>
    <g filter="url(#filter8_f_2280_21925)">
      <path d="M128 101.798V100.93H129.901V99H130.914V100.93H132.834V101.798H130.914V103.66H129.901V101.798H128Z" fill="#ABABAB" />
    </g>
    <path
      d="M148.379 134.424L148.167 134.574V134.833V137.167V137.427L148.38 137.576L155.03 
        142.226L155.238 142.372L155.478 142.29L157.728 141.523L157.974 141.439L158.047 141.19L158.697 
        138.956L158.76 138.739L158.638 138.547C156.892 135.816 154.212 133.741 151.064 132.772L150.829 
        132.7L150.629 132.841L148.379 134.424ZM143.371 132.841L143.169 132.699L142.934 132.773C139.787 
        133.758 137.109 135.832 135.363 138.546L135.24 138.738L135.303 138.956L135.953 141.19L136.026 
        141.439L136.272 141.523L138.522 142.29L138.762 142.372L138.97 142.226L145.62 137.576L145.833 
        137.427V137.167V134.833V134.574L145.621 134.424L143.371 132.841ZM138.844 155.015L139.105 
        154.992L139.234 154.764L140.534 152.481L140.645 152.286L140.574 152.074L138.14 144.841L138.062
         144.608L137.831 144.528L135.531 143.728L135.283 143.642L135.072 143.796L133.405 145.013L133.221
          145.148L133.202 145.375C133.2 145.39 133.199 145.406 133.198 145.421C133.183 145.601 133.167 
          145.793 133.167 146C133.167 149.441 134.43 152.59 136.522 155.01L136.689 155.204L136.944 
          155.181L138.844 155.015ZM151.323 159.125L151.527 159.058L151.619 158.864L152.785 
          156.397L152.897 156.16L152.766 155.934L151.7 154.084L151.555 
          153.833H151.267H142.75H142.462L142.318 154.082L141.251 155.915L141.12 
          156.14L141.23 156.377L142.38 158.86L142.471 159.057L142.677 159.125C144.039 
          159.573 145.492 159.833 147 159.833C148.508 159.833 149.96 159.573 151.323 
          159.125ZM150.783 151.5H151.142L151.257 151.16L153.524 144.46L153.644 144.105L153.337 
          143.89L147.287 139.657L147 139.456L146.713 139.657L140.679 143.891L140.374 144.105L140.493 
          144.459L142.743 151.159L142.857 151.5H143.217H150.783ZM157.056 155.181L157.311 155.204L157.478 
          155.01C159.57 152.59 160.833 149.441 160.833 146C160.833 145.777 160.815 145.575 160.799 
          145.396L160.798 145.388L160.777 145.163L160.595 145.03L158.928 143.813L158.719 143.66L158.473
           143.743L156.156 144.526L155.922 144.606L155.843 144.841L153.409 152.074L153.337 
           152.288L153.45 152.483L154.767 154.766L154.897 154.992L155.156 155.015L157.056 
           155.181ZM130.833 146C130.833 137.076 138.076 129.833 147 129.833C155.924 129.833
            163.167 137.076 163.167 146C163.167 154.924 155.924 162.167 147 162.167C138.076
             162.167 130.833 154.924 130.833 146Z"
      fill="#95F035"
      stroke="white"
    />
    <defs>
      <filter
        id="filter0_f_2280_21925"
        x="0.671234"
        y="0.671234"
        width="291.589"
        height="291.658"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="58.0461" result="effect1_foregroundBlur_2280_21925" />
      </filter>
      <filter
        id="filter1_f_2280_21925"
        x="77.0702"
        y="77.0702"
        width="138.791"
        height="138.86"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="19.3487" result="effect1_foregroundBlur_2280_21925" />
      </filter>
      <filter
        id="filter2_ddi_2280_21925"
        x="66.4568"
        y="105.154"
        width="160.018"
        height="160.086"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="25.1533" />
        <feGaussianBlur stdDeviation="9.67435" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0382353 0 0 0 0 0.261765 0 0 0 0 0.0955882 0 0 0 0.7 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_2280_21925" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="38.6974" />
        <feGaussianBlur stdDeviation="25.1533" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0764706 0 0 0 0 0.523529 0 0 0 0 0.191176 0 0 0 1 0" />
        <feBlend mode="color-dodge" in2="effect1_dropShadow_2280_21925" result="effect2_dropShadow_2280_21925" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2280_21925" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-2.51533" dy="5.03066" />
        <feGaussianBlur stdDeviation="1.25767" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_2280_21925" />
      </filter>
      <filter
        id="filter3_f_2280_21925"
        x="117.012"
        y="117.012"
        width="58.9063"
        height="58.9751"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.497929" result="effect1_foregroundBlur_2280_21925" />
      </filter>
      <filter
        id="filter4_f_2280_21925"
        x="118.34"
        y="118.34"
        width="56.2507"
        height="56.3195"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.331953" result="effect1_foregroundBlur_2280_21925" />
      </filter>
      <filter
        id="filter5_b_2280_21925"
        x="96.6631"
        y="96.6631"
        width="99.5852"
        height="99.5995"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="11.6685" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2280_21925" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2280_21925" result="shape" />
      </filter>
      <filter id="filter6_f_2280_21925" x="109" y="151" width="18.02" height="17.66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2280_21925" />
      </filter>
      <filter id="filter7_f_2280_21925" x="165" y="116" width="18.02" height="17.66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2280_21925" />
      </filter>
      <filter id="filter8_f_2280_21925" x="124" y="95" width="12.8337" height="12.66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2280_21925" />
      </filter>
    </defs>
  </svg>
);
