import React from "react";
import { SkeletonComponentProps, SOURCE } from "src/app/models/Site";
import { ProductCarousel } from "src/app/views/components";

export const SkeletonCarousel = ({ data, props }: SkeletonComponentProps) => {
  const { data: publiZoneName = { publications: [], name: "" }, working } = SOURCE.ZONE(data.source_id);

  const { publications, name } = publiZoneName;

  const { onClickProduct, toLink } = props || {};

  return working ? (
    <div />
  ) : (
    <ProductCarousel publications={publications} title={name || ""} zone={data.source_id} toLink={toLink} onClickProduct={onClickProduct} />
  );
};
