import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Button, useMediaQuery, Drawer, Hidden, Theme, Divider } from "@material-ui/core";
import TuneRounded from "@material-ui/icons/TuneRounded";
import { IMAGE_BASE_URL } from "src/config";
import { SortOptions, SEARCH_TYPES, Loading } from "src/commons";
import { Pagination } from "@material-ui/lab";
import { Product } from "src/app/models/business/Product";
import { BannerMobile } from "src/assets/images";
import { PagePagination, ConfigValues } from "src/app/models";
import { FilterList, GenericFilterData } from "src/commons/ui/view/GenericFilter";
import { ecommerceConfig } from "src/config/ecommerce";
import { useWalletContext } from "src/context/WalletContext";
import { GenericChipFilter } from "src/commons/ui/view/GenericChipFilter";
import { fixedNameCategory } from "src/utils";
import { profileImage } from "src/utils/profileImage";
import { ProductCardView } from "../ProductCard";
import { ZONE } from "../../../const";
import { PublicationByZone } from "../../../business/PublicationByZone";
import { categoriesViewStyles as useStyles } from "../../styles";
import { ViewGridContent, ProductNotFound } from "./components";
import { ProductCarousel } from "../ProductCarouselView";
import { CategoryFilters } from "./components/CategoryFilters";

interface CategoriesViewProps {
  banner?: ConfigValues | null;
  products: Product[];
  filterContent: GenericFilterData[];
  selectedFilters: FilterList[];
  onClick: (item: Product) => void;
  onAddFilter: (item: FilterList) => void;
  onRemoveFilter: (item: FilterList) => void;
  onChangePagination: (page: number) => void;
  pagination: PagePagination;
  defaultSearchText?: string;
  sortOpenModal: boolean;
  working?: boolean;
  setSortOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSortFilter: (value: SortOptions) => void;
  sortFilter: SortOptions;
  sortOptions: SortOptions[];
  searchType: SEARCH_TYPES;
  freeShipment: boolean;
  setFreeShipment: React.Dispatch<React.SetStateAction<boolean>>;
  fromCategories?: boolean;
}

interface Img {
  small: string;
  normal: string;
}

export const CategoriesView = ({
  banner,
  products,
  filterContent,
  selectedFilters,
  onClick,
  onAddFilter,
  onRemoveFilter,
  onChangePagination,
  pagination,
  defaultSearchText = ecommerceConfig.general.defaultSearchText,
  sortOpenModal,
  working,
  setSortOpenModal,
  setSortFilter,
  sortFilter,
  sortOptions,
  searchType,
  freeShipment,
  fromCategories,
  setFreeShipment,
}: CategoriesViewProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [viewGrid, setViewGrid] = useState(true);
  const classes = useStyles();
  const { address } = useWalletContext();
  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isDownTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.down(768));
  const isMdUp = useMediaQuery("(min-width:50em)");
  const imageValues = banner?.imagen || ({} as Img);

  const imageValidData = Object.keys(imageValues);
  const filteredProducts = products.filter((item) => item.attributes.tokenOwner.toUpperCase() === address.toUpperCase() || item.old_price !== "0");
  const imageNameProfile = filteredProducts[0]?.attributes?.nftInfo?.start;
  const content = (
    <CategoryFilters
      defaultSearchText={defaultSearchText}
      filterContent={filterContent}
      pagination={pagination}
      selectedFilters={selectedFilters}
      onAddFilter={onAddFilter}
      onRemoveFilter={onRemoveFilter}
      searchType={searchType}
      freeShipment={freeShipment}
      setFreeShipment={setFreeShipment}
      setOpenModal={setOpenModal}
    />
  );

  useEffect(() => {
    if (isDownSm) setViewGrid(false);
  }, [isDownSm]);

  const paginationNumber = pagination.allCount / pagination.perPage;
  const resultPaginationNumber = paginationNumber % 1 !== 0 ? paginationNumber + 1 : paginationNumber;
  return (
    <Grid container direction="row" className={classes.container}>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        {content}
      </Drawer>

      {!isMdUp && (
        <>
          {fromCategories && (!!imageValidData.length || isDownTablet) && (
            <>
              <Grid
                className={classes.banner}
                component="img"
                src={isDownTablet ? BannerMobile : `${IMAGE_BASE_URL}${imageValues[isDownXs ? "small" : "normal"]}`}
                alt="imagenes de productos"
              />
              <Grid container justify="center" alignItems="center" xs={12}>
                <Grid component="img" src={profileImage(imageNameProfile)} width={100} height={100} className={classes.profileImage} />
              </Grid>
            </>
          )}
          {isDownTablet && (
            <Grid item justify="center" xs={12} className={classes.textUnderBanner}>
              <Typography variant="h4" color="inherit">
                {defaultSearchText}
              </Typography>
              <Typography variant="body2" color="inherit">
                {!!selectedFilters.length && ` ${selectedFilters.length} resultado ${selectedFilters.length > 1 ? "s" : ""}`}
              </Typography>
            </Grid>
          )}
          <Grid item container xs={12} alignItems="center" className={classes.filterCollapsedContainer}>
            {pagination.allCount !== 0 ? (
              <Grid item container xs={12} className={classes.filtrarGrid}>
                <Grid item xs={4}>
                  <Button onClick={() => setOpenModal(true)} color="primary" startIcon={<TuneRounded />} className={classes.filterButton}>
                    <Typography variant="subtitle1" color="primary">
                      Filtrar
                      {!!selectedFilters.length && ` (${selectedFilters.length})`}
                    </Typography>
                  </Button>
                </Grid>

                {!isDownTablet && (
                  <ViewGridContent
                    sortOptions={sortOptions}
                    sortOpenModal={sortOpenModal}
                    setSortOpenModal={setSortOpenModal}
                    setSortFilter={setSortFilter}
                    sortFilter={sortFilter}
                    xs={8}
                    viewGrid={viewGrid}
                    setViewGrid={setViewGrid}
                  />
                )}
              </Grid>
            ) : null}
          </Grid>
          {isDownTablet ? "" : <Divider className={classes.divider} />}
          <GenericChipFilter
            labelKey="name"
            idKey="value"
            items={fixedNameCategory(selectedFilters)}
            onItemDelete={onRemoveFilter}
            customStyles={{ container: { padding: 0 }, chip: { backgroundColor: "#EEEEEE" } }}
          />
        </>
      )}

      <Hidden mdDown={!isMdUp}>
        <Grid item md={3} lg={3}>
          <Box paddingRight={2} mt={6}>
            {content}
          </Box>
        </Grid>
      </Hidden>

      <Grid item container xs={12} md={9} lg={9} className={classes.cardContainer} direction="column">
        {isMdUp && !!imageValidData.length && (
          <Grid
            className={classes.banner}
            component="img"
            src={`${IMAGE_BASE_URL}${imageValues[isDownXs ? "small" : "normal"]}`}
            alt="imagenes de productos"
          />
        )}

        {working ? (
          <Loading />
        ) : pagination.allCount !== 0 ? (
          <Hidden mdDown={!isMdUp}>
            <ViewGridContent
              sortOptions={sortOptions}
              sortOpenModal={sortOpenModal}
              setSortOpenModal={setSortOpenModal}
              setSortFilter={setSortFilter}
              sortFilter={sortFilter}
              viewGrid={viewGrid}
              setViewGrid={setViewGrid}
            />
          </Hidden>
        ) : (
          <ProductNotFound />
        )}

        <Grid
          item
          container
          direction={viewGrid ? "row" : "column"}
          justify={viewGrid ? "center" : isDownXs ? "center" : "space-between"}
          className={viewGrid ? classes.cardItems : ""}
        >
          {filteredProducts.map((item) => (
            <Box key={item.productId} my={1} justifyContent="center">
              <Grid container justify="center">
                {isDownTablet ? (
                  <ProductCardView data={item} onClick={() => onClick(item)} />
                ) : (
                  <ProductCardView data={item} viewGrid={viewGrid} onClick={() => onClick(item)} />
                )}
              </Grid>
            </Box>
          ))}
        </Grid>

        {pagination.allCount > pagination.perPage && (
          <Grid container alignItems="center" justify="center" className={classes.paginationGrid}>
            <Pagination
              count={Math.trunc(resultPaginationNumber)}
              page={pagination.currentPage}
              color="primary"
              variant="outlined"
              onChange={(event, page) => onChangePagination(page)}
            />
          </Grid>
        )}
      </Grid>

      {pagination.allCount !== 0 ? null : (
        <Grid item xs={12} container direction="column">
          <Box mt={3} />
          <PublicationByZone key={ZONE.TE_PUEDE_INTERESAR} zone={ZONE.TE_PUEDE_INTERESAR}>
            {({ working, data }) =>
              (working || !data) && data !== undefined ? (
                <div />
              ) : data ? (
                <ProductCarousel publications={[...data, ...data]} title="Te puede interesar" zone={ZONE.TE_PUEDE_INTERESAR} />
              ) : (
                <></>
              )
            }
          </PublicationByZone>
        </Grid>
      )}
    </Grid>
  );
};
