import React, { useState } from "react";
import { useHistory } from "react-router";
import { Avatar, Divider, Grid, Menu, IconButton, MenuItem, Typography, makeStyles, Box, CardActionArea } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AnyObject } from "src/commons/types";
import { useRolesContext } from "src/context/RolesContext/RolesContext";
import { shortenWalletAddress } from "src/utils";
import { ContentCopy } from "src/assets/images/svgs";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import { DappWalletManager } from "src/platform/DappWallets";
import { useWalletContext } from "src/context/WalletContext";
import { WalletConnection } from "../WalletConnection";

type UserCardProps = {
  logout: () => void;
};

export const useStyles = makeStyles(() => ({
  avatar: {
    width: 30,
    height: 30,
    border: "1px solid #94f034",
    boxShadow: "0px 0px 10px 2px rgba(149, 240, 53, 0.4)",
  },
  avatarName: {
    fontSize: 13,
  },
  info: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: 220,
    height: 70,
  },
  mailAndAddress: {
    display: "flex",
    flexDirection: "column",
  },
  menuItem: {
    marginTop: 5,
    fontSize: 15,
    fontWeight: 300,
    "&:hover": {
      color: "#94f034",
    },
  },
  walletIcon: {
    fontSize: 30,
    color: "#94f034",
  },
}));

export const UserCard = ({ logout }: UserCardProps) => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { address, isConnected } = useWalletContext();
  const { userInfo } = useRolesContext();
  const handleMenu = (event: AnyObject) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const nick = userInfo.name?.split(" ")[0].substring(0, 1).toUpperCase() + userInfo.lastname?.split(" ")[0].substring(0, 1).toUpperCase();

  return (
    <Grid container justify="center" alignContent="center">
      <Grid container justify="flex-end" alignItems="center">
        <Grid item>
          <Box display="flex" component={CardActionArea} onClick={() => history.push("/profile")}>
            <Avatar aria-label="recipe" className={classes.avatar}>
              <Typography className={classes.avatarName}>{nick}</Typography>
            </Avatar>
          </Box>
        </Grid>

        <Grid item>
          <IconButton aria-label="change language" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu}>
            {!anchorEl ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Grid>
      </Grid>

      <Menu
        id="menu-appbar-language"
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: -80 }}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        <Box display="flex" width={220} height={70} alignItems="center">
          <Grid item>
            <Box ml={2} mr={1}>
              <Avatar aria-label="recipe" className={classes.avatar}>
                <Typography className={classes.avatarName}>{nick}</Typography>
              </Avatar>
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textPrimary">
              {userInfo.name.split(" ")[0]}
            </Typography>
            {isConnected ? (
              <Grid container direction="row" alignItems="center">
                <Typography variant="body1" color="primary">
                  {shortenWalletAddress(address)}
                </Typography>
                <IconButton size="small" /* onClick={() => connectToProvider('metamask')} */>
                  <ContentCopy />
                </IconButton>
                <IconButton size="small" onClick={() => DappWalletManager.disconnect()}>
                  <ExitToAppRoundedIcon />
                </IconButton>
              </Grid>
            ) : (
              <WalletConnection />
            )}
          </Grid>
        </Box>

        <Divider variant="middle" />
        <MenuItem onClick={() => history.push("/profile")} className={classes.menuItem}>
          Mi Perfil
        </MenuItem>
        <MenuItem onClick={logout} className={classes.menuItem}>
          Salir de la cuenta
        </MenuItem>
      </Menu>
    </Grid>
  );
};
