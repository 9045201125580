import React from "react";
import { Typography, Link, Box, Grid } from "@material-ui/core";
import { ecommerceConfig } from "../../../config/ecommerce";

export const TermsAndConditionsText = () => (
  <Grid container>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold">
          Novatech S. A.
        </Box>
        , (en adelante Novatech) a través de nuestro //
        <Link href="mailto:www.novatechstore.com.ar">www.novatechstore.com.ar</Link>
        {` o `}
        <Link href="mailto:www.tiendaenova.com.ar">www.tiendaenova.com.ar</Link>
        {` `}
        (en adelante el “Sitio” y/0 “Sitios), comunica a nuestros Clientes (en adelante “Usuario” y/o “Usuarios”) los términos y condiciones que van a
        regir a los efectos de la utilización por éstos del Sitio y que regirán los derechos y obligaciones entre el Usuario y Novatech. Asimismo, se
        informa en el presente al Usuario que, al operar el Sitio, aceptan la utilización de sus Datos Personales consignados en el Sitio (en adelante
        los “Datos Personales”) con adecuación a lo establecido en el punto 4 infra.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        El Propietario y Operador del Sitio es Novatech. La dirección postal del Sitio web es Uspallata 2776, Ciudad Autónoma de Buenos Aires. (Código
        Postal 1437) República Argentina. En función de los expresado ut supra, se fija en el presente los términos y condiciones a los efectos de
        reglar el uso por parte del Usuario del sitio www.novatechstore.com.ar o www.tiendaenova.com.ar y sus respectivos derechos y obligaciones.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          1.
        </Box>
        El sólo uso del Sitio implica la aceptación irrevocable e incondicional por parte del Usuario de todos los términos y condiciones fijados en
        el presente, asumiendo la obligación de respetar las mismas. En caso de no aceptar las presentes Bases y Condiciones el Usuario deberá
        abstenerse de utilizar el Sitio. Con la aceptación de las presente Bases y Condiciones, el Usuario tendrá una relación contractual con
        www.tiendaenova.com.ar en caso de utilización del Sitio o en caso de ser usuario registrado. Cualquiera de las Partes podrá rescindir la
        relación contractual, el Usuario Registrado, dando de baja su inscripción, el Usuario registrado no utilizando el Sitio y Novatech en
        cualquier momento notificándolo a sus domicilios registrados o a sus domicilios electrónicos.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          2.
        </Box>
        Novatech se reserva el derecho de realizar cambios en el Sitio, en las condiciones de uso y en las notificaciones que correspondieren, en
        cualquier momento, y con la simple modificación del presente, comunicándolo a través de publicación en el Sitio. Dicha modificación será
        aplicable en forma inmediata a su publicación. Asimismo, Novatech se reserva el derecho en cualquier momento de interrumpir el servicio
        ofrecido, sin previo aviso a los Usuarios, en forma transitoria o definitiva, y sin necesitar en ningún momento consentimiento del Usuario.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          3.
        </Box>
        En función de lo expresado en el punto 2 del presente, cada vez que el Usuario desee utilizar el Sitio, deberá aceptar a priori todos y cada
        uno de los términos y condiciones especificados en el presente.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold">
          4.
        </Box>
        {` Protección de los Datos Personales: Novatech ha implementado todos los procesos requeridos a
        los efectos de Protección de los Datos Personales de los Usuarios, con el objeto de evitar
        el mal uso, alteración, acceso no autorizado y/o robo de los Datos Personales suministrados
        por el Usuario a Novatech. Sin perjuicio de lo expresado en el presente, el Usuario acepta y
        reconoce que las medidas de seguridad en Internet no son inviolables. Así, todo dato que
        suministren los Usuarios, serán incluidos en una Base de Datos de Novatech, con el objeto de
        identificar perfiles a los efectos de segmentar dicha Base con fines comerciales,
        promocionales y mejorar las ofertas a los Usuarios, quienes prestan conformidad mediante la
        utilización del Sitio de tales fines. Dicha Base será inscripta en el Registro Nacional de
        Bases de Datos Personales por Novatech. Los Usuarios que suministren sus datos personales
        tendrán la posibilidad de ejercer su derecho de acceso a los mismos, en forma gratuita, con
        un intervalo no inferior a seis (6) meses, salvo que se encuentre comprendido en el artículo
        14, inc. 3 de la Ley Número 25.326 en el sentido que acredite un interés legítimo. También
        podrán rectificar los mismos, o solicitar el retiro de su nombre de la base de datos,
        enviando un mail `}
        <Link href="mailto:www.novatechstore.com.ar">www.novatechstore.com.ar</Link>
        {`. Asimismo, los
        Usuarios al completar sus datos personales prestarán su consentimiento de la posibilidad de
        cesión por parte de Novatech a terceras empresas con el fin que el Usuario reciba oferta de
        productos y/o servicios y/o ofertas promocionales. En caso que el Usuario contrate a través
        de Novatech un servicio y/o producto brindado por un tercero (en adelante en esta cláusula
        como “Tercero”), autoriza expresamente a Novatech a suministrarle a dicho Tercero sus datos
        personales (Nombre y Apellido, DNI, Teléfono, domicilio y correo electrónico) sólo a los
        efectos de la prestación del servicio y la facturación de dichos bienes y/o servicios y a la
        entrega de los mismos. Esta información no podrá ser utilizada por el Tercero con fines
        publicitarios y/o promocionales, salvo expresa autorización del Usuario. Toda información
        suministrada por el Usuario será tratada por Novatech conforme términos previstos en la Ley
        Nacional de Protección de Datos Personales Nª 25.326.`}
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          5.
        </Box>
        Mediante el uso del sitio el Usuario declara bajo juramento ser mayor de 18 años de edad o más, y tener capacidad legal para contratar.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          6.
        </Box>
        Toda la información y datos suministrados por el Usuario deberán ser verdaderos, precisos, válidos, exactos y vigentes, siendo exclusiva
        responsabilidad del Usuario verificar que sus datos sean correctamente ingresados en el Sitio y de actualizarlos en caso de corresponder.
        Novatech no se responsabiliza por veracidad de los datos suministrados, respondiendo los Usuarios por la autenticidad y exactitud de los
        mismos.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          7.
        </Box>
        Al utilizar el Sitio, el Usuario es responsable del cuidado de la confidencialidad de su cuenta. En función de lo especificado en el presente,
        el Usuario es responsable de todo lo que se registra en su cuenta.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          8.
        </Box>
        Indemnidad: Asimismo el Sitio sólo se puede utilizar con fines lícitos. El Usuario se obliga a mantener indemne a Novatech por cualquier tipo
        de reclamo, extrajudicial, administrativo o judicial, de terceros, por el uso del Sitio por parte del Usuario, o en caso de verificarse multas
        y/o deudas de cualquier tipo en función del desempeño o actividad realizada por el Usuario en el Sitio.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          9.
        </Box>
        Compra de Productos: la compra de cualquier producto o servicio publicado a través del Sitio se encuentra sujeta a disponibilidad de stock, de
        la aprobación de identidad del Usuario, de la imputación correcta de la tarjeta de crédito o valores transferidos, como a la aprobación por
        parte del Usuario de estos términos y condiciones. Las fotos o ilustraciones publicadas en el Sitio son al solo efecto ilustrativo y, la
        información de los productos, podrá variar en caso de incorrecciones o equívocos involuntarios.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          10.
        </Box>
        Proceso de compra: Sin perjuicio de encontrarse detallado el proceso de compra en el sitio, el mismo se detalla a continuación:
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          10.1.
        </Box>
        Se deja expresa constancia que Novatech S. A. no se hace responsable por los reintegros que deban efectuar los Bancos emisores de tarjetas de
        crédito respecto de las promociones vigentes, siendo dichas entidades emisoras las únicas responsables de efectivizar en tiempo y forma el
        reintegro correspondiente.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          10.2.
        </Box>
        Una vez elegido el producto el Usuario deberá presionar el botón comprar y verá en su carrito de compras los artículos que tiene cargados,
        pudiendo modificar la cantidad o eliminar los que desee.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          10.3.
        </Box>
        Aquí el Usuario encontrará los distintos medios de pagos y promociones que están disponibles en la venta online.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          10.4.
        </Box>
        Si el Usuario no se encuentra registrado podrá comprar dejando su dirección de e-mail.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          10.5.
        </Box>
        Luego deberá continuar con el proceso de compra.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          10.6.
        </Box>
        Las ventas online se realizan a los titulares de tarjetas de crédito con la que va a pagar, por lo que deberá indicar el nombre de la calle y
        el número de puerta donde llega el resumen que será validado.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          10.7.
        </Box>
        Podrá ingresar su dirección de entrega y ver el costo de envíos para el código postal de entrega y conocer las fechas posibles de entrega.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          10.8.
        </Box>
        Luego deberá confirmar o modificar los datos ingresados y las cuotas elegidas, para pasar a un servicio externo autorizado por su tarjeta para
        el pago y validación de los datos, montos y plan de pago.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          10.9.
        </Box>
        Novatech S. A. podrá contactar al Usuario vía telefónica para realizar una validación de datos, debiendo tener dicha línea base en Argentina.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          10.10.
        </Box>
        El resultado de la operación le será informado, y las operaciones exitosas generarán el comprobante vía correo electrónico a su casilla de
        email de la registración, como comprobante de la misma, con los datos para cualquier consulta o duda posterior a su compra.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          11.
        </Box>
        Todos los precios publicados en el Sitio incluyen IVA. Se deja expresa constancia que los precios publicados en el Sitio podrán sufrir
        modificaciones y el precio estipulado en la factura será el del momento del cierre de la compra por parte del Usuario.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box display="inline" fontWeight="bold" pr={1}>
        12. Facturación:
      </Box>
      La factura a emitirse será la de consumidor final (Comprobante Tipo “B”), siendo esta la única modalidad de facturación para ventas online. Las
      mismas son enviadas al mail informado.
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          13. Pago:
        </Box>
        Condiciones: El Sitio solo opera con tarjetas de crédito que podrán variar y sus condiciones se informan en la página de medios de pago
        correspondiente, como en el carro de compras. Una vez registrado el Usuario podrá conocer los planes disponibles de cada entidad y prestadora
        autorizados por las mismas como por acuerdos con Novatech. Novateh no valida ni opera con los establecimientos de tarjetas de crédito en forma
        directa, sino a través de una pasarela de pago autorizadas por las mismas que ofrece el servicio de validación de tarjetas, identidad de
        titulares, código de Seguridad, para el monto de compra efectuada. Novatech no recolecta ni almacena datos de su tarjeta de crédito, ni define
        el resultado de la operación, siendo esto responsabilidad de cada entidad emisora.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          14. Entregas:
        </Box>
        Los métodos y costos de envío que brinda Novatech dependerán de la cantidad de productos adquiridos por el Usuario y del lugar de entrega de
        los mismos. Una vez registrado podrá conocer el costo de entrega de los productos seleccionados para una localidad y provincia de lugar de
        destino. Despachamos el pedido entre dos y tres días hábiles luego de realizado el pago. Si la entrega es en CABA, la recibirás a través de
        Moto Express. Si el envío es a GBA o al interior del país, te mandaremos a tu correo electrónico un link para que puedas hacer el seguimiento.
        El tiempo de entrega depende del tipo de envío. Por lo general, demora entre 3 y 7 días hábiles una vez acreditado el pago.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          15. Cambios y devoluciones:
        </Box>
        Podés realizar cambios o devoluciones, sin costo alguno, dentro de los diez (10) días corridos a partir de la fecha en la que recibiste el
        producto. Pasado ese tiempo, tu caso lo atenderá el servicio de asistencia técnica. Es necesario que el producto y su empaque estén en
        perfectas condiciones. Además, debés incluir los accesorios, manuales de uso, remito de entrega y factura de compra. Una vez firmado el remito
        de entrega no se aceptan reclamos por daños estéticos y/o artículos faltantes. Para comunicarte con nosotros: (011) 7700-3333
        {ecommerceConfig.general.contactUrl}
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          16.
        </Box>
        Se deja expresa constancia que el Usuario posee el derecho a revocar la aceptación de la oferta que realice el Establecimiento, durante un
        plazo de 10 (diez) días corridos, desde la entrega del bien o celebración del contrato, lo último que ocurra, en los términos del Artículo 34º
        de la Ley Nº 24.240 y la Resolución 906/98 de la Secretaría de Industria, Comercio y Minería.
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold" pr={1}>
          17. Derechos de Propiedad Intelectual:
        </Box>
        {` enova y Novatech son marcas registradas. Se encuentran todos los derechos reservados
        conforme a la ley 11.723.`}
      </Box>
    </Typography>
    <Typography component="p" variant="body1" color="textSecondary">
      <Box mb={1}>
        <Box display="inline" fontWeight="bold">
          13. Legislación aplicable:
        </Box>
        {` el presente es regido por las leyes de la República Argentina.`}
      </Box>
    </Typography>
  </Grid>
);
