import { EcommerceDAO } from "./EcommerceDAO";
import { CardType, Emitter } from "../../../app/models";

export class CardDAO extends EcommerceDAO<CardType> {
  constructor() {
    super("card", "cardId");
  }

  async getAllCards(): Promise<any> {
    const response = await this.fetchData(`/card/@ALL@`);
    return response.json();
  }

  async getCArdById(idCard: number): Promise<any> {
    const response = await this.fetchData(`/card/${idCard}`);
    return response.json();
  }

  async getAllEmitters(): Promise<Emitter[]> {
    const response = await this.fetchData(`/card/emitter/@ALL@`);
    return response.json();
  }

  async getEmitterById(idEmitter: number): Promise<any> {
    const response = await this.fetchData(`/card/emitter/${idEmitter}`);
    return response.json();
  }
}
