import { Campaign } from "src/app/models/Site";
import { EcommerceDAO } from "./EcommerceDAO";

export class CampaignDAO extends EcommerceDAO<Campaign> {
  constructor() {
    super("campaign", "campaignId");
  }

  async getById(campaign: string): Promise<Campaign> {
    // TODO: ver como hacer esto mejor
    const response = await this.fetchData(`/campaign/${campaign}`);
    const campaigns: Campaign = await response.json();
    return campaigns;
  }

  async getAll(): Promise<Campaign[]> {
    // TODO: ver como hacer esto mejor
    const response = await this.fetchData(`/campaign/@ALL@`);
    const campaigns: Campaign[] = await response.json();
    return campaigns;
  }
}
