import { MenuItem } from "../../../commons";
import { categoriesMenu } from "./categories";
import { cartMenu } from "./cart";
import { productsMenu } from "./products";
import { searchMenu } from "./search";
import { aboutMenu } from "./about";
import { faqsMenu } from "./faqs";
import { politicaMenu } from "./politica";
import { comoComprarMenu } from "./comoComprar";
import { promotionsMenu } from "./promotions";
import { regretMenu } from "./regret";
import { registerMenu } from "./register";
import { profilesMenu } from "./profiles";
import { watchMediaMenu } from "./WatchMedia";
import { dashboardMenu } from "./dashboard";

export const routes: MenuItem[] = [
  categoriesMenu,
  cartMenu,
  productsMenu,
  searchMenu,
  aboutMenu,
  faqsMenu,
  politicaMenu,
  comoComprarMenu,
  promotionsMenu,
  regretMenu,
  registerMenu,
  profilesMenu,
  watchMediaMenu,
  dashboardMenu,
];
