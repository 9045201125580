import React from "react";
import { Drawer, Divider } from "@material-ui/core";
import { MenuDivider, MenuItem } from "./MenuItem";
import { MenuList } from "./components";
import { Buttons } from "../button";
import { useUIContext } from "../useUIContext";

export type MenuProps = {
  onOpen: (item: MenuItem) => void;
  menus: Array<MenuItem | MenuDivider>;
};

export const Menu = ({ onOpen, menus }: MenuProps) => {
  const { style, menu, toggleMenu } = useUIContext();
  return (
    <Drawer className={style.drawer} variant="persistent" anchor="left" open={menu.isOpen} classes={{ paper: style.drawerPaper }}>
      <div className={style.drawerHeader}>
        <Buttons.IconButton icon="chevron_left_icon" onClick={toggleMenu} />
      </div>
      <MenuList menus={menus} onOpen={onOpen} />
      <Divider />
    </Drawer>
  );
};
