import React, { useCallback, useEffect } from "react";
import { Theme, useMediaQuery, Container, Box, Grid, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useScrollToTopOnMount } from "src/commons/hooks/useScrollToTopUnMount";
import { FormContextProvider, Template } from "src/lib/templates";
import { confirmationTimeManager } from "src/utils";
import moment from "moment";
import { useHistory } from "react-router";
import { ConfirmAccountForm } from "./components/ConfirmAccountForm";

const confirmationAccountTemplate: Template = {
  name: "Confirm Account Form",
  template: [
    {
      name: "checkCode",
      type: { name: "string" },
      label: "Ingresá tu código",
      validators: [
        { name: "pattern", signature: ["^[0-9]+$"], errorMessage: "Solo podés ingresar números." },
        { name: "length", signature: [6], errorMessage: "El código es de 6 números." },
        { name: "empty", errorMessage: "Completa este dato." },
      ],
    },
  ],
};

export const ConfirmAccount = () => {
  useScrollToTopOnMount();
  const history = useHistory<any>();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isBw768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));

  useEffect(() => {
    (async () => {
      const { goBack } = history.location.state || { goBack: "/dashboard/profile" };
      const nowTime = moment();
      const time = await confirmationTimeManager.retrieve();
      if (!(!moment(time).isValid() || moment(time).diff(nowTime) < 0)) {
        history.push(goBack);
      }
    })();
  }, [history]);

  const onSubmit = useCallback(async () => {
    const { goNext } = history.location.state;
    confirmationTimeManager.persist(moment().add(1, "day").toISOString());
    history.push(goNext);
  }, [history]);

  return (
    <>
      <Helmet>
        <title>Confirmación</title>
        <meta name="description" content="Confirmación de cuenta" />
      </Helmet>
      <Container maxWidth="lg">
        <Box px={isDownSm ? 0 : "10%"} mt={2}>
          <FormContextProvider initialValues={{ checkCode: "" }} onSubmit={onSubmit} template={confirmationAccountTemplate}>
            <Grid container item spacing={isDownSm ? 0 : 2} xs={12} sm={isBw768 ? 8 : 12} md={8}>
              <Grid item>
                <Typography variant="h5" color="textPrimary">
                  Confirmación
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Te enviamos un código por correo para confirmar tu identidad. Ingresá el código para poder continuar
                </Typography>
              </Grid>

              <Grid item container spacing={2}>
                <ConfirmAccountForm />
              </Grid>
            </Grid>
          </FormContextProvider>
        </Box>
      </Container>
    </>
  );
};
