import React from "react";
import { Helmet } from "react-helmet";
import { Grid } from "@material-ui/core";
import { useSkeletonLibbyCall } from "src/app/business";
import { Skeleton, SkeletonComponent, SkeletonComponents, SKELETON_TYPE } from "src/app/models/Site";
import { SKELETON_IDS } from "src/app/skeletons";
import { orderBy } from "lodash";
import { ecommerceConfig } from "../../../../config/ecommerce";

export const HomeView = () => {
  const { data, working } = useSkeletonLibbyCall<Skeleton>({ methodName: "getSkeleton", params: [SKELETON_IDS.HOME] });

  return (
    <>
      <Helmet>
        <title>{ecommerceConfig.general.title}</title>
        <meta name="description" content={ecommerceConfig.general.description} />
      </Helmet>
      <Grid container direction="column">
        {working ? (
          <div />
        ) : data && !Array.isArray(data) ? (
          orderBy(data.skeleton_components, "relevance", "asc").map((skeleton: SkeletonComponent) => {
            const Component = SkeletonComponents[SKELETON_TYPE[skeleton.config_values.component_key]];
            return Component ? <Component data={skeleton.config_values} key={skeleton.skeleton_id} /> : <></>;
          })
        ) : null}
      </Grid>
    </>
  );
};
