// ReadexProNew
import readexProFont from "src/assets/fonts/readexProNew/ReadexPro-Medium.ttf";
import readexProBoldFont from "src/assets/fonts/readexProNew/ReadexPro-bold.ttf";

// Circular
import circularWoff from "../fonts/Circular-Fonts/Fonts/circular-book.woff";
import circularMediumWoff from "../fonts/Circular-Fonts/Fonts/circular-medium.woff";
import circularBoldWoff from "../fonts/Circular-Fonts/Fonts/circular-bold.woff";
import circularBlackWoff from "../fonts/Circular-Fonts/Fonts/circular-black.woff";

// Montserrat
import montserratTtf from "../fonts/Montserrat/Montserrat-Regular.ttf";
import montserratMediumTtf from "../fonts/Montserrat/Montserrat-Medium.ttf";
import montserratBoldTtf from "../fonts/Montserrat/Montserrat-Bold.ttf";
import montserratBlackTtf from "../fonts/Montserrat/Montserrat-Black.ttf";

// Futurx kc
import futurxkc from "../fonts/futura_xblkcn_bt/FUTURXKC.ttf";

type MuiCssBaselineType = { [k: string]: any };

const circular = {
  fontFamily: "Circular",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
    local('Circular'),
    local('Circular-Regular'),
    url(${circularWoff}) format('woff')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const circularMedium = {
  fontFamily: "CircularMedium",
  fontStyle: "normal",
  fontWeight: 500,
  src: `
    local('CircularMedium'),
    local('Circular-Medium'),
    url(${circularMediumWoff}) format('woff')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const circularBold = {
  fontFamily: "CircularBold",
  fontStyle: "normal",
  fontWeight: 600,
  src: `
    local('CircularBold'),
    local('Circular-Bold'),
    url(${circularBoldWoff}) format('woff')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const circularBlack = {
  fontFamily: "CircularBlack",
  fontStyle: "normal",
  fontWeight: 700,
  src: `
    local('CircularBlack'),
    local('Circular-Black'),
    url(${circularBlackWoff}) format('woff')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const montserrat = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
    local('Montserrat'),
    local('Montserrat-Regular'),
    url(${montserratTtf}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const montserratMedium = {
  fontFamily: "MontserratMedium",
  fontStyle: "normal",
  fontWeight: 500,
  src: `
    local('MontserratMedium'),
    local('Montserrat-Medium'),
    url(${montserratMediumTtf}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const montserratBold = {
  fontFamily: "MontserratBold",
  fontStyle: "normal",
  fontWeight: 600,
  src: `
    local('MontserratBold'),
    local('Montserrat-Bold'),
    url(${montserratBoldTtf}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const montserratBlack = {
  fontFamily: "MontserratBlack",
  fontStyle: "normal",
  fontWeight: 700,
  src: `
    local('MontserratBlack'),
    local('Montserrat-Black'),
    url(${montserratBlackTtf}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const readexPro = {
  fontFamily: "Readex Pro",
  fontStyle: "normal",
  fontWeight: 400,
  src: `url(${readexProFont}) format('truetype')`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const readexProBold = {
  fontFamily: "Readex Pro Bold",
  fontStyle: "normal",
  fontWeight: 600,
  src: `url(${readexProBoldFont}) format('truetype')`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const futuraXBLkcBT = {
  fontFamily: "Futura XBlkCnIt BT",
  fontStyle: "italic",
  fontWeight: 800,
  src: `
    local('Futura XBlkCnIt BT'),
    local('Futura-XBlkCnI-BT'),
    url(${futurxkc}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const futuraXBLkcBTBtn = {
  fontFamily: "Futura XBlkCnIt BT",
  fontStyle: "italic",
  fontWeight: 800,
  src: `
    local('Futura XBlkCnIt BT'),
    local('FuturaExtraBlackCondensedBT'),
    url(${futurxkc}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const futuraXBLkcBTE = {
  fontFamily: "Futura XBlkCnIt BT",
  fontStyle: "italic",
  fontWeight: 800,
  src: `
    local('Futura XBlkCnIt BT'),
    local('FUTURA_E'),
    url(${futurxkc}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const MuiCssBaseline: MuiCssBaselineType = {
  "@global": {
    "@font-face": [
      montserrat,
      montserratMedium,
      montserratBold,
      montserratBlack,
      circular,
      circularMedium,
      circularBold,
      circularBlack,
      readexPro,
      readexProBold,
      futuraXBLkcBT,
      futuraXBLkcBTE,
      futuraXBLkcBTBtn,
    ],
    "*::-webkit-scrollbar": {
      width: "0.6em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
};

export default MuiCssBaseline;
