import { makeStyles } from "@material-ui/core";
import { ecommerceTypography } from "src/config/ecommerce/nftmarket/ecommerceTypography";
import { ecommercePalette } from "src/config/ecommerce/nftmarket/ecommercePalette";

export const MenuDrawerStyles = makeStyles(() => ({
  header: {
    padding: 8,
    marginBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 3px 10px 0px rgb(149 240 53 / 40%)",
  },
  title: {
    fontSize: 20,
    textTransform: "uppercase",
    fontWeight: 700,
    fontStyle: "italic",
    fontFamily: "Barlow",
    letterSpacing: 1.5,
    marginLeft: 20,
    marginTop: 22,
    marginBottom: 22,
  },
  loginContainer: {
    backgroundColor: ecommercePalette.materialUi.background.paper,
    display: "flex",
    height: 90,
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20,
    justifyContent: "center",
  },
  userActions: {
    backgroundColor: ecommercePalette.materialUi.background.paper,
  },
  loginButton: {
    ...ecommerceTypography.h5,
    color: ecommercePalette.materialUi.primary.dark,
    background: ecommercePalette.materialUi.primary.light,
    width: 170,
    fontSize: 22,
    height: 50,
    borderRadius: "30px",
  },
  registerButton: {
    ...ecommerceTypography.h5,
    width: 170,
    fontSize: 22,
    height: 50,
  },
  divider: {
    marginLeft: 20,
    width: "90%",
    height: 1,
    marginTop: 10,
    marginBottom: 10,
    color: ecommercePalette.materialUi.text.secondary,
  },
  footerIcons: {
    padding: 10,
  },
  expandMoreIcon: {
    color: ecommercePalette.materialUi.secondary.main,
    width: 40,
    height: 40,
  },
  userActionIcons: {
    paddingRight: 10,
    color: ecommercePalette.materialUi.text.secondary,
  },
  accordionDetails: {
    marginLeft: 25,
    marginRight: 25,
    display: "flex",
    flexDirection: "column",
  },
  accordionSummary: {
    height: 70,
    marginLeft: 25,
    marginRight: 25,
  },
  userActionsMenu: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 5,
    paddingBottom: 5,
  },
}));
