import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../../lib/libby/generator";
import { Template } from "../../models";
import { businessOptions } from "../businessOptions";

const DAO_NAME = "template";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "idTemplate",
      labelKey: "descripcion",
    },
    withSelectedChips: {
      idKey: "idTemplate",
      labelKey: "descripcion",
    },
  },
};

const optionsToUse = !businessOptions.Template
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Template,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Template.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Template>(DAO_NAME, optionsToUse);

// hooks
export const useTemplateFetchById = fetchById;
export const useTemplateLibbyCall = libbyCall;
export const useTemplateDAO = libbyDAO;
export const useTemplateLibbyFetch = libbyFetch;

// components/hoc
export const TemplateAutocomplete = entityAutocomplete;
export const TemplateCall = renderPropCall;
export const TemplateById = renderPropFetchById;
export const TemplateSelect = simpleSelect;
export const TemplateAccordionSelector = accordionSelector;
export const TemplateSelectedChips = selectedChips;

// context
// ByIdContext
export const useTemplateByIdContext = useByIdContext;
export const TemplateByIdProvider = ByIdProvider;
export const TemplateByIdContext = ByIdContext;
// GeneralContext
export const useTemplateContext = useGeneralContext;
export const TemplateProvider = GeneralProvider;
export const TemplateContext = GeneralContext;
