import React, { useEffect, useCallback, useMemo } from "react";
import { ProfileUserFB } from "src/app/models";
import { StatusResponse } from "src/app/services";

interface OnClickProps {
  clickHandler: () => void;
  disabled: boolean;
  userInfo: ProfileUserFB | null;
}

export interface ResponseHandler extends StatusResponse {
  userInfo?: ProfileUserFB;
}

type FacebookEventType = "auth.authResponseChange" | "auth.logout" | "auth.login" | "auth.statusChange" | "xfbml.render";

interface Listeners {
  event: FacebookEventType;
  callback: (response: StatusResponse) => void;
}

export interface FacebookLoginProps {
  disabled?: boolean;
  appId: string;
  xfbml?: boolean;
  cookie?: boolean;
  scope?: string;
  fields?: string;
  version?: string;
  language?: string;
  continueAs?: boolean;
  buttonProps?: any;
  titleButton?: string;
  listeners?: Array<Listeners>;
  redirectUri?: string;
  render?: (props: OnClickProps) => JSX.Element;
  responseHandler: (props: ResponseHandler) => void;
}

export interface FbState {
  isSdkLoaded?: boolean;
  isProcessing?: boolean;
  signedIn?: boolean;
  autoLoad?: boolean;
}

const defaultConfig: Partial<FacebookLoginProps> = {
  scope: "public_profile,email",
  xfbml: true,
  cookie: false,
  fields: "name",
  version: "3.1",
  language: "en_US",
  continueAs: false,
  listeners: [],
  redirectUri: "/",
};

export const FacebookLogin = (props: FacebookLoginProps) => {
  const propsMerge = useMemo(() => ({ ...defaultConfig, ...props }), [props]);

  const getIsMobile = () => {
    let isMobile = false;
    const wNavigator: any = window.navigator;
    try {
      isMobile = !!(
        (wNavigator && wNavigator.standalone) ||
        navigator.userAgent.match("CriOS") ||
        navigator.userAgent.match(/mobile/i) ||
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      );
    } catch (ex) {
      // continue regardless of error
    }
    return isMobile;
  };

  const getParamsFromObject = (params: any) =>
    `?${Object.keys(params)
      .map((param) => `${param}=${encodeURIComponent(params[param])}`)
      .join("&")}`;

  useEffect(() => {
    if (document.getElementById("facebook-jssdk")) {
      return;
    }
    ((d, s, id) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      // @ts-ignore
      js.src = "//connect.facebook.net/es_LA/sdk.js";
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
    const { appId, xfbml, cookie, version, listeners = [] } = propsMerge;
    window.fbAsyncInit = () => {
      FB.init({
        appId,
        xfbml,
        cookie,
        version: `v${version}`,
      });
      if (listeners.length > 0) {
        listeners.forEach(({ event, callback }) => {
          FB.Event.subscribe(event, callback);
        });
      }
    };
  }, [propsMerge]);

  const responseApi = useCallback(
    (response: StatusResponse) => {
      const { fields, responseHandler } = propsMerge;
      FB.api(`/me`, { fields }, (me: ProfileUserFB) => responseHandler({ ...response, userInfo: me }));
    },
    [propsMerge],
  );

  const checkLoginState = useCallback(
    (response: StatusResponse) => {
      const { responseHandler } = propsMerge;
      if (response.authResponse) {
        responseApi(response);
      } else if (responseHandler) {
        responseHandler({ ...response });
      }
    },
    [propsMerge, responseApi],
  );

  const clickHandler = useCallback(() => {
    const { scope, appId, version, redirectUri, responseHandler } = propsMerge;
    if (getIsMobile()) {
      const params = {
        client_id: appId,
        redirect_uri: redirectUri,
        scope,
        response_type: "token",
      };
      const urlParam = getParamsFromObject(params);

      window.location.href = `https://www.facebook.com/v${version}/dialog/oauth${urlParam}`;
    } else {
      if (!window.FB && responseHandler) responseHandler({ status: "unknown", authResponse: undefined });

      FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          checkLoginState(response);
        } else {
          FB.login(checkLoginState, { scope });
        }
      });
    }
  }, [propsMerge, checkLoginState]);

  const { render, buttonProps, disabled = false, titleButton = "Continuar con facebook" } = propsMerge;
  const Comp = render;
  return Comp ? (
    <Comp clickHandler={clickHandler} disabled={disabled} userInfo={null} />
  ) : (
    <button type="button" {...buttonProps} onClick={clickHandler}>
      {titleButton}
    </button>
  );
};
