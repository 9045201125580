import React from "react";

interface CheckIconProps {
  width?: string;
  height?: string;
}

export const CheckIcon = ({ width = "160", height = "160" }: CheckIconProps) => (
  <svg width={width} height={height} viewBox="0 0 112 218" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_588_920)">
      <path
        d="M56.4657 98C41.8566 98 30 86.1545 30 71.5C30 56.872 41.8566 45 56.4657 45C71.1012 45 82.9312 56.872 82.9312
71.5C82.9312 86.1545 71.1012 98 56.4657 98Z"
        stroke="#95F035"
        strokeWidth="6.47308"
      />
    </g>
    <g filter="url(#filter1_f_588_920)">
      <path
        d="M56.4657 98C41.8566 98 30 86.1545 30 71.5C30 56.872 41.8566 45 56.4657 45C71.1012 45 82.9312 56.872 
82.9312 71.5C82.9312 86.1545 71.1012 98 56.4657 98Z"
        stroke="#95F035"
        strokeWidth="8.4648"
      />
    </g>
    {/* <g filter="url(#filter2_ddi_588_920)">
      <path
        d="M56.4657 98C41.8566 98 30 86.1545 30 71.5C30 56.872 41.8566 45 56.4657 45C71.1012 45 82.9312 56.872 82.9312 
71.5C82.9312 86.1545 71.1012 98 56.4657 98Z"
        stroke="#95F035"
        strokeOpacity="0.2"
        strokeWidth="6.47308"
      />
    </g> */}
    <g filter="url(#filter3_f_588_920)">
      <path
        d="M56.4657 98C41.8566 98 30 86.1545 30 71.5C30 56.872 41.8566 45 56.4657 45C71.1012 45 82.9312 56.872 82.9312 
71.5C82.9312 86.1545 71.1012 98 56.4657 98Z"
        stroke="#95F035"
        strokeWidth="3.98343"
      />
    </g>
    <g filter="url(#filter4_f_588_920)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.4657 98C41.8566 98 30 86.1545 30 71.5C30 56.872 41.8566 45 56.4657 
45C71.1012 45 82.9312 56.872 82.9312 71.5C82.9312 86.1545 71.1012 98 56.4657 98Z"
        stroke="white"
        strokeWidth="1.99172"
      />
    </g>
    <g filter="url(#filter5_b_588_920)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.4557 97.9257C41.8522 97.9257 30 86.0968 30 71.4629C30 56.8554 
41.8522 45 56.4557 45C71.0857 45 82.9114 56.8554 82.9114 71.4629C82.9114 86.0968 71.0857 97.9257 56.4557 97.9257Z"
        fill="#95F035"
        fillOpacity="0.01"
      />
    </g>
    <g filter="url(#filter6_f_588_920)">
      <path d="M23 85.8V84H26.94V80H29.04V84H33.02V85.8H29.04V89.66H26.94V85.8H23Z" fill="white" />
    </g>
    <g filter="url(#filter7_f_588_920)">
      <path d="M79 50.8V49H82.94V45H85.04V49H89.02V50.8H85.04V54.66H82.94V50.8H79Z" fill="white" />
    </g>
    <g filter="url(#filter8_f_588_920)">
      <path d="M38 26.7979V25.9296H39.9007V24H40.9137V25.9296H42.8337V26.7979H40.9137V28.66H39.9007V26.7979H38Z" fill="#ABABAB" />
    </g>
    <path
      d="M51.7857 78.5188L52.1392 78.8724L52.4928 78.5188L71.8045 59.2071L73.6947 61.0973L52.1392 82.6528L42.4571 
72.9707L44.3473 71.0805L51.7857 78.5188Z"
      fill="#95F035"
      stroke="white"
    />
    <defs>
      <filter
        id="filter0_f_588_920"
        x="-89.3288"
        y="-74.3288"
        width="291.589"
        height="291.658"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="58.0461" result="effect1_foregroundBlur_588_920" />
      </filter>
      <filter
        id="filter1_f_588_920"
        x="-12.9298"
        y="2.07018"
        width="138.791"
        height="138.86"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="19.3487" result="effect1_foregroundBlur_588_920" />
      </filter>
      <filter
        id="filter2_ddi_588_920"
        x="-23.5432"
        y="30.1542"
        width="160.018"
        height="160.086"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="25.1533" />
        <feGaussianBlur stdDeviation="9.67435" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0382353 0 0 0 0 0.261765 0 0 0 0 0.0955882 0 0 0 0.7 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_588_920" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="38.6974" />
        <feGaussianBlur stdDeviation="25.1533" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0764706 0 0 0 0 0.523529 0 0 0 0 0.191176 0 0 0 1 0" />
        <feBlend mode="color-dodge" in2="effect1_dropShadow_588_920" result="effect2_dropShadow_588_920" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_588_920" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-2.51533" dy="5.03066" />
        <feGaussianBlur stdDeviation="1.25767" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_588_920" />
      </filter>
      <filter
        id="filter3_f_588_920"
        x="27.0124"
        y="42.0124"
        width="58.9064"
        height="58.9751"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.497929" result="effect1_foregroundBlur_588_920" />
      </filter>
      <filter
        id="filter4_f_588_920"
        x="28.3402"
        y="43.3402"
        width="56.2508"
        height="56.3195"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.331953" result="effect1_foregroundBlur_588_920" />
      </filter>
      <filter
        id="filter5_b_588_920"
        x="6.66306"
        y="21.6631"
        width="99.5852"
        height="99.5995"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="11.6685" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_588_920" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_588_920" result="shape" />
      </filter>
      <filter id="filter6_f_588_920" x="19" y="76" width="18.02" height="17.66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_588_920" />
      </filter>
      <filter id="filter7_f_588_920" x="75" y="41" width="18.02" height="17.66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_588_920" />
      </filter>
      <filter id="filter8_f_588_920" x="34" y="20" width="12.8336" height="12.66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_588_920" />
      </filter>
    </defs>
  </svg>
);
