import { tokenManager } from "src/platform/libby/TokenManager";
import { Account, Buyer, RegisterInfo, UpdatePassword, SingIn } from "../../../app/models";
import { EcommerceDAO } from "./EcommerceDAO";

export class AccountDAO extends EcommerceDAO<Account> {
  constructor() {
    super("account", "account_id");
  }

  async login({ email, provider, ...rest }: SingIn): Promise<any> {
    const data = await this.fetchData("/login", {
      method: "post",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({ nick: email || provider, ...rest, provider }),
    });
    tokenManager.persist(data.headers.get("X-JustRPC-Token") || "");
    return data.json();
  }

  async register(newUser: RegisterInfo): Promise<Buyer> {
    const data = await this.fetchData("/account", {
      method: "post",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(newUser),
    });
    return data.json();
  }

  async updateAccess(info: UpdatePassword): Promise<Buyer> {
    const data = await this.fetchData("/account", {
      method: "put",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(info),
    });
    return data.json();
  }

  async validatePass(info: SingIn) {
    const data = await this.fetchData("/confirmation/pass", {
      method: "post",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(info),
    });
    return data.json();
  }
}
