import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import parse from "react-html-parser";
import { useSkeletonDAO } from "src/app/business/Skeleton";
import { Skeleton, initialContentState } from "../../../app/models";

export const LegalesText = () => {
  const [content, setContent] = useState<Skeleton>(initialContentState);
  const skeletonDAO = useSkeletonDAO();

  const getContent = useCallback(async () => {
    const res = await skeletonDAO.getSkeleton("1");
    setContent(res);
  }, [skeletonDAO]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  return <Grid container>{content.skeleton_components.length ? parse(content.skeleton_components[0].url) : null}</Grid>;
};
