import React, { useMemo, ChangeEvent } from "react";
import { Grid, Box, useMediaQuery, Theme, Typography, Button } from "@material-ui/core";
import { useScrollToTopOnMount } from "src/commons";
import { ComponentProps, Form } from "src/screens/Public/Checkout/types";
import { FormAutocompleteFetch } from "src/lib/templates";
import { State, City } from "src/app/models";
import { FormSelect } from "src/lib/templates/components/FormSelect";
import { CommonFormInput, FormInputData } from "../CommonFormInput";

export interface DeliveryFormProps extends ComponentProps {
  provinces?: State[];
  invalidZidCode: boolean;
  cities?: City[];
  selectedProvince?: boolean;
  loading?: boolean;
  loadingCities?: boolean;
  onInputChangeCity: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  disableNext: boolean;
  nameForm?: string;
  searchCityValue?: string;
  disableFooter?: boolean;
  noTitle?: boolean;
  zipCodeLength: number;
}

export const DeliveryForm = ({
  invalidZidCode,
  form,
  onClickStep,
  loadingCities,
  loading,
  provinces = [],
  cities = [],
  selectedProvince,
  onInputChangeCity,
  disableNext,
  nameForm = "deliveryInfo",
  searchCityValue = "",
  disableFooter,
  noTitle = false,
  zipCodeLength = 0,
}: DeliveryFormProps) => {
  useScrollToTopOnMount();
  const allFormInput = useMemo(() => {
    const formInput: FormInputData[] = [
      {
        variant: "standard",
        name: `${nameForm}.zipCode`,
        label: "Cód. postal",
        lg: 2,
        md: 6,
        xs: 4,
        required: true,
        error: invalidZidCode,
        helperText: invalidZidCode ? "El codigo postal no coincide" : "",
        id: "deliveryInfoZipCode",
        autoComplete: "postal-code",
      },
      {
        variant: "standard",
        name: `${nameForm}.street`,
        label: "Calle",
        lg: 6,
        md: 5,
        xs: 8,
        required: true,
        id: "deliveryInfoStreet",
        autoComplete: "street-address",
      },
      {
        variant: "standard",
        name: `${nameForm}.streetNumber`,
        label: "Altura",
        lg: 2,
        md: 2,
        xs: 4,
        required: true,
        id: "deliveryInfoStreetNumber",
      },
      {
        variant: "standard",
        name: `${nameForm}.floor`,
        label: "Piso",
        lg: 2,
        md: 2,
        xs: 4,
        id: "deliveryInfoFloor",
      },
      {
        variant: "standard",
        name: `${nameForm}.apartament`,
        label: "Dpto.",
        lg: 2,
        md: 2,
        xs: 4,
        id: "deliveryInfoApartment",
      },
      {
        variant: "standard",
        name: `${nameForm}.additionalInfo`,
        label: "Información adicional",
        xs: 6,
        id: "deliveryInfoAditionalInfo",
      },
    ];

    return formInput.filter((input) => !input.hidden);
  }, [invalidZidCode, nameForm]);
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <Box padding={isDownSm ? 2 : 3}>
      <Grid container direction="row" spacing={3}>
        {!noTitle && (
          <Grid item xs={12}>
            <Box mb={1} paddingBottom={1} borderColor="other.stroke" borderBottom={isDownSm ? "1px solid" : ""}>
              <Typography variant="h5" color="textPrimary">
                Información de envío
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item lg={5} md={6} sm={6} xs={12}>
          <FormSelect<State>
            loading={loading}
            fullWidth
            form={form}
            label={<Box color="text.disabled">Provincia</Box>}
            name={`${nameForm}.province`}
            content={provinces}
            valueKey="id"
            labelKey="name"
            helperText="El campo Provincia es requerido"
            inputLabelProps={{ shrink: true }}
            optionToValue={(value, options) => options?.find((province) => province.id === value)}
            valueToOption={(value, options) => options.find((option: State) => option.id === value.id)?.id || searchCityValue}
          />
        </Grid>

        <Grid item lg={5} md={6} sm={6} xs={12}>
          <FormAutocompleteFetch
            disabled={!selectedProvince}
            name={`${nameForm}.city`}
            form={form}
            label={<Box color="text.disabled">Ciudad</Box>}
            options={cities}
            optionLabelKey="name"
            optionToValue={(option: City) => option}
            valueToOption={(value: City, options: City[]) =>
              searchCityValue?.toLocaleLowerCase() === value?.name?.toLocaleLowerCase() && searchCityValue?.length > 0 && options.length > 0
                ? options.find((option: City) => option.id === value.id)?.name
                : searchCityValue
            }
            helperText="El campo Ciudad es requerido"
            getOptionSelected={(option: City, value: City) => option.id === value?.id}
            onInputChange={onInputChangeCity}
            inputLabelProps={{ shrink: true }}
            loading={loadingCities}
            enabledOpenAutocomplete={searchCityValue.length > 1}
          />
        </Grid>

        {allFormInput.map((input) => (
          <CommonFormInput<Form>
            lg={input.lg}
            md={input.md}
            xs={input.xs}
            name={input.name}
            form={form}
            variant={input.variant}
            label={input.label}
            error={input.label === "Cód. postal" ? (zipCodeLength >= 1 ? input.error : false) : input.error}
            helperText={input.label === "Cód. postal" ? (zipCodeLength >= 1 ? input.helperText : "") : input.helperText}
            id={input.id}
            autoComplete={input.autoComplete}
          />
        ))}

        {isDownSm && !disableFooter && (
          <Grid item xs={12}>
            <Button fullWidth disabled={disableNext} size="large" variant="contained" color="primary" onClick={onClickStep}>
              Continuar
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
