import React from "react";
import { Grid, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { DummyAvatar, PlayerStatisticsImage } from "src/assets/images";
import { playerStatisticsStyles as useStyles } from "src/app/views/styles";

interface PlayerStatisticsProps {
  name: string;
  starInfo: any;
}

export const PlayerStatistics = ({ starInfo, name }: PlayerStatisticsProps) => {
  const classes = useStyles();
  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down(768));

  return (
    <Grid style={{ background: `url(${isDownXs || PlayerStatisticsImage})`, width: "100%", backgroundSize: "cover" }}>
      <Grid container justify="center" spacing={1} style={{ marginTop: "72px" }} className={classes.containers}>
        <Grid item className={classes.containers}>
          <Grid component="img" src={DummyAvatar} className={classes.profileImage} />
        </Grid>
        <Grid item>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="h2" color="primary" className={classes.centerTypography}>
                {name}
              </Typography>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item>
                <Typography variant="h1" className={classes.centerNumber}>
                  {starInfo.playerNumber}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="center" alignContent="center" className={classes.playerContainer}>
        <Grid direction="column" justify="center" alignContent="center" className={classes.gridTextContainer}>
          <Grid className={classes.textContainer}>
            <Typography variant="body2" color="textSecondary" style={{ marginRight: 15 }}>
              Edad
            </Typography>
            <Typography variant="body1">{`${starInfo.age} (${starInfo.date})`}</Typography>
          </Grid>
          <Grid className={classes.textContainer}>
            <Typography variant="body2" color="textSecondary" style={{ marginRight: 15 }}>
              País
            </Typography>
            <Typography variant="body1">Brasil</Typography>
          </Grid>
          <Grid className={classes.textContainer}>
            <Typography variant="body2" color="textSecondary" style={{ marginRight: 15 }}>
              Altura
            </Typography>
            <Typography variant="body1">{starInfo.height}</Typography>
          </Grid>
          <Grid className={classes.textContainer}>
            <Typography variant="body2" color="textSecondary" style={{ marginRight: 15 }}>
              Peso
            </Typography>
            <Typography variant="body1">{starInfo.weight}</Typography>
          </Grid>
        </Grid>

        <Grid direction="column" justify="center" alignContent="center" className={classes.gridTextContainer} style={{ marginBottom: -8 }}>
          <Grid className={classes.textContainer}>
            <Typography variant="body2" color="textSecondary" style={{ marginRight: 15 }}>
              Pierna hábil
            </Typography>
            <Typography variant="body1">{starInfo.skillfulLeg}</Typography>
          </Grid>
          <Grid className={classes.textContainer}>
            <Typography variant="body2" color="textSecondary" style={{ marginRight: 15 }}>
              Posición
            </Typography>
            <Typography variant="body1">{starInfo.position}</Typography>
          </Grid>
          <Grid className={classes.textContainer}>
            <Typography variant="body2" color="textSecondary" style={{ marginRight: 15 }}>
              Equipo actual
            </Typography>
            <Typography variant="body1">{starInfo.actualTeam}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
