import React, { useEffect, useState, useMemo, ChangeEvent, useCallback } from "react";
import { PersonalCreditInfoForm } from "src/app/views";
import { useLocationLibbyCall, useLocationDAO } from "src/app/business";
import { State, City } from "src/app/models";
import moment from "moment";
import { useDebounce } from "src/commons";
import { orderBy } from "lodash";
import { PROVINCES } from "src/app/const";
import { PersonalInfoInitialValue, PaymentInitialValue, guarantorInitialValue, GuarantorInitialValue } from "../../initialValues";
import { ValuesForm, FormProps } from "../../types";
import { useCheckoutFormContext } from "../../hooks";

interface Values extends ValuesForm {
  personalInfo: PersonalInfoInitialValue;
  guarantorInfo: GuarantorInitialValue<Partial<File>>;
  paymentMethod: PaymentInitialValue;
}

export const GuarantorInfoForm = ({ onBackStep }: FormProps) => {
  const { form, values, errors, handleSubmit } = useCheckoutFormContext();
  const [birthdayValid, setBirthdayValid] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [cities, setCities] = useState<City[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const locationDAO = useLocationDAO();
  const { data, working } = useLocationLibbyCall<State[]>({ methodName: "getStates" });

  const provinces = useMemo(() => data?.filter((item) => item.id.toString() === PROVINCES.CATAMARCA.toString()), [data]);

  const valuesAll = useMemo(
    () =>
      ({
        guarantorInfo: guarantorInitialValue,
        ...values,
      } as Values),
    [values],
  );

  const [search, setSearch] = useState(valuesAll.guarantorInfo.city?.name || "");
  const searchDebunce = useDebounce(search, 600);

  const selectedProvince = useMemo(() => valuesAll.guarantorInfo.province?.id !== "", [valuesAll.guarantorInfo.province?.id]);

  const zitCode = useMemo(() => valuesAll.guarantorInfo.zipCode || "", [valuesAll.guarantorInfo.zipCode]);

  const onInputChangeCity = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      setSearch(value);
    },
    [setSearch],
  );

  useEffect(() => {
    if (
      (valuesAll?.guarantorInfo?.city?.name?.length > 0 && valuesAll?.guarantorInfo?.city?.name !== search) ||
      valuesAll?.guarantorInfo?.city === null
    ) {
      const copyValuesForm = { ...valuesAll };
      copyValuesForm.guarantorInfo.city = guarantorInitialValue.city;
      form.reset(copyValuesForm);
    }
  }, [search, form, valuesAll]);

  useEffect(() => {
    if (selectedProvince && searchDebunce && searchDebunce !== valuesAll.guarantorInfo.city?.name) {
      setLoading(true);
      (async () => {
        const city = await locationDAO.getCityByState(valuesAll.guarantorInfo.province?.id, searchDebunce);
        setCities(city);
        setLoading(false);
      })();
    }
  }, [selectedProvince, locationDAO, valuesAll.guarantorInfo.province, searchDebunce, valuesAll.guarantorInfo.city]);

  const invalidZipCode = useMemo(
    () => !!zitCode && !valuesAll.guarantorInfo.city?.zip?.includes(Number(zitCode)),
    [valuesAll.guarantorInfo.city, zitCode],
  );

  const handleError = () => {
    setInvalidDate(true);
    setBirthdayValid(true);
  };

  useEffect(() => {
    if (valuesAll.guarantorInfo.dateOfBirty) {
      if (valuesAll.guarantorInfo.dateOfBirty.toString() === "Invalid Date") {
        return handleError();
      }
      setInvalidDate(false);
      const eightYearsAgo = moment().subtract(18, "y");
      const birthday = moment(valuesAll.guarantorInfo.dateOfBirty);
      setBirthdayValid(!eightYearsAgo.isAfter(birthday));
    }
    if (!valuesAll.guarantorInfo.dateOfBirty) {
      setInvalidDate(false);
      setBirthdayValid(false);
    }
  }, [valuesAll.guarantorInfo.dateOfBirty]);

  return (
    <PersonalCreditInfoForm
      title="Datos del garante"
      nameForm="guarantorInfo"
      nameDelivery="guarantorInfo"
      loading={working}
      invalidZidCode={invalidZipCode}
      loadingCities={loading}
      disableNext={Object.keys(errors as any).length !== 0}
      birthdayValid={birthdayValid}
      invalidDate={invalidDate}
      onClickStep={handleSubmit}
      onBackStep={onBackStep}
      form={form}
      provinces={orderBy(provinces, "name")}
      selectedProvince={selectedProvince}
      cities={cities}
      onInputChangeCity={onInputChangeCity}
      zipCodeLength={zitCode.length || 0}
      searchCityValue={search}
      useSameDeliveryAddress={false}
    />
  );
};
