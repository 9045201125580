import React from "react";

export const InstagramIcon = () => (
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7473 3.81805C11.9822 3.81805 11.6352 3.83015 10.5491 3.87957C9.46522 3.92921 8.72536 
4.10081 8.07799 4.3526C7.40834 4.61266 6.8403 4.96054 6.27437 5.52669C5.70803 6.09263 5.36016 6.66069 
5.09926 7.33014C4.84684 7.97775 4.67503 8.71784 4.62624 9.80136C4.57767 10.8874 4.56494 11.2347
 4.56494 13.9999C4.56494 16.7651 4.57724 17.1111 4.62645 18.1971C4.6763 19.2811 4.8479 20.0209 
 5.09947 20.6683C5.35974 21.338 5.7076 21.9061 6.27374 22.472C6.83945 23.0384 7.40749 23.3871 
 8.07671 23.6471C8.72451 23.8989 9.46458 24.0705 10.5483 24.1202C11.6343 24.1696 11.9811 24.1817 
 14.746 24.1817C17.5114 24.1817 17.8573 24.1696 18.9434 24.1202C20.0273 24.0705 20.768 23.8989
  21.4158 23.6471C22.0852 23.3871 22.6524 23.0384 23.2181 22.472C23.7845 21.9061 24.1323 21.338 
  24.3932 20.6685C24.6435 20.0209 24.8153 19.2808 24.8662 18.1973C24.915 17.1113 24.9277 16.7651 
  24.9277 13.9999C24.9277 11.2347 24.915 10.8876 24.8662 9.80157C24.8153 8.71763 24.6435 7.97775 
  24.3932 7.33036C24.1323 6.66069 23.7845 6.09263 23.2181 5.52669C22.6518 4.96033 22.0854 4.61245 
  21.4151 4.3526C20.7661 4.10081 20.0258 3.92921 18.9419 3.87957C17.8558 3.83015 17.5101 3.81805 
  14.7441 3.81805H14.7473ZM13.8339 5.6529C14.105 5.65248 14.4075 5.6529 14.7473 5.6529C17.4658 5.6529
   17.788 5.66266 18.8615 5.71145C19.8542 5.75684 20.3929 5.92272 20.7518 6.06208C21.227 6.24663 21.5657
    6.46724 21.9219 6.8236C22.2782 7.17996 22.4988 7.51936 22.6838 7.99451C22.8231 8.35299 22.9892 
    8.89178 23.0344 9.88451C23.0832 10.9578 23.0938 11.2803 23.0938 13.9975C23.0938 16.7148 23.0832 
    17.0372 23.0344 18.1106C22.989 19.1033 22.8231 19.6421 22.6838 20.0006C22.4992 20.4757 22.2782 
    20.8141 21.9219 21.1702C21.5655 21.5266 21.2272 21.7472 20.7518 21.9317C20.3934 22.0717 19.8542
     22.2372 18.8615 22.2826C17.7882 22.3314 17.4658 22.342 14.7473 22.342C12.0286 22.342 11.7064 
     22.3314 10.6331 22.2826C9.64042 22.2367 9.10165 22.0709 8.74254 21.9315C8.2674 21.747 7.92802 
     21.5264 7.57167 21.17C7.21532 20.8136 6.99472 20.4751 6.80975 19.9997C6.67039 19.6412 6.50431 
     19.1024 6.45913 18.1097C6.41034 17.0364 6.40058 16.714 6.40058 13.995C6.40058 11.276 6.41034 
     10.9553 6.45913 9.88196C6.50452 8.88924 6.67039 8.35045 6.80975 7.99154C6.99429 7.51639 7.21532
      7.17699 7.57167 6.82063C7.92802 6.46427 8.2674 6.24366 8.74254 6.05869C9.10144 5.91869 9.64042
       5.75324 10.6331 5.70763C11.5724 5.66521 11.9364 5.65248 13.8339 5.65036V5.6529ZM20.1821 
       7.34351C19.5076 7.34351 18.9603 7.89014 18.9603 8.5649C18.9603 9.23945 19.5076 9.78672 
       20.1821 9.78672C20.8566 9.78672 21.4039 9.23945 21.4039 8.5649C21.4039 7.89036 20.8566 
       7.34308 20.1821 7.34308V7.34351ZM14.7473 8.77108C11.8598 8.77108 9.51867 11.1123 9.51867
        13.9999C9.51867 16.8875 11.8598 19.2276 14.7473 19.2276C17.6348 19.2276 19.9751 16.8875 
        19.9751 13.9999C19.9751 11.1123 17.6346 8.77108 14.7471 8.77108H14.7473ZM14.7473 10.6059C16.6215
         10.6059 18.1411 12.1254 18.1411 13.9999C18.1411 15.8742 16.6215 17.3938 14.7473 17.3938C12.8728
          17.3938 11.3535 15.8742 11.3535 13.9999C11.3535 12.1254 12.8728 10.6059 14.7473 10.6059Z"
      fill="#95F035"
    />
  </svg>
);
