import { ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import { parseUnits } from "ethers/lib/utils";
import { CONTRACT_ADDRESS } from "src/config";
import { AnyObject } from "src/commons";
import { MetaMaskProvider, WalletEvent, Wallet } from "../common";
import { DappWalletStrategy } from "../../DappWalletStrategy";
import { abi2 } from "../const/Abi";

export class MetaMask implements DappWalletStrategy {
  name: Wallet;

  protected provider!: MetaMaskProvider;

  protected ethersProvider!: ethers.providers.Web3Provider;

  private error!: string;

  private isInitialized: boolean = false;

  constructor() {
    this.name = "metamask";
    this.init();
  }

  async init() {
    try {
      const _provider: AnyObject = (await detectEthereumProvider({ mustBeMetaMask: true })) as any;
      this.provider = (_provider.providers ? _provider.providers.find((provider: any) => provider.isMetaMask) : _provider) as MetaMaskProvider;
    } catch (e) {
      console.log("error metamask", e);
      this.error = e.message;
    } finally {
      this.isInitialized = true;
      if (this.provider) {
        this.ethersProvider = new ethers.providers.Web3Provider(this.provider as MetaMaskProvider, "any");
      }
    }
  }

  get Provider(): MetaMaskProvider {
    if (this.error) throw new Error(this.error);
    if (!this.provider) throw new Error("Error on MetaMaskManager");
    return this.provider;
  }

  get EtherProvider() {
    if (this.error) throw new Error(this.error);
    if (!this.provider) throw new Error("Error on MetaMaskManager");
    return this.ethersProvider;
  }

  get IsInitialized() {
    return this.isInitialized;
  }

  get Error(): string | undefined {
    // TODO: handle this
    // If the provider returned by detectEthereumProvider is not the same as
    // window.ethereum, something is overwriting it, perhaps another wallet.
    // if (provider !== window.ethereum) {
    //   console.error('Do you have multiple wallets installed?');
    // }
    if (!this.isInitialized) {
      this.error = "MetaMaskManager is not initialized.";
    } else if (!this.provider) {
      this.error = "Please, install MetaMask to buy NFTs";
    } else {
      this.error = "";
    }
    return this.error;
  }

  getName(): Wallet {
    return this.name;
  }

  getProvider() {
    return this.provider;
  }

  public async connect() {
    try {
      // TODO : se queda esperando si metamas esta bloqueado, no abre la ventana, manejar el caso
      const accounts = await this.ethersProvider.send("eth_requestAccounts", []);
      window.localStorage.setItem(this.getName(), JSON.stringify({ provider: this.getName(), address: accounts[0] }));
      return accounts[0];
    } catch (error) {
      console.log("Error", error);
      this.error = error.message;
    }
  }

  private async getSigner() {
    return this.ethersProvider.getSigner();
  }

  public async getAccount() {
    const signer = await this.getSigner();
    return signer.getAddress();
  }

  async getContract() {
    const signer = await this.getSigner();
    const contract = new ethers.Contract(CONTRACT_ADDRESS, abi2, signer);
    return contract;
  }

  async getBalance(address: string) {
    const contract = await this.getContract();
    const balance = await contract.balanceOf(address);
    return balance;
  }

  public async isConnected() {
    const accounts = (await this.ethersProvider?.listAccounts()) || [];
    return accounts.length > 0 && !!this.provider?.isMetaMask;
  }

  async buyNFT(tokenId: string, price: number) {
    const contract = await this.getContract();
    const response = await contract.buyNFT(tokenId, { value: price }); // el gasprice es para asegurar el mineo
    console.log("Minando");
    return response;
  }

  async setPriceNFT(tokenId: string, price: string) {
    const format = parseUnits(price);
    const contract = await this.getContract();
    const response = await contract.setPriceNFT(tokenId, format); // el gasprice es para asegurar el mineo
    console.log("Minando");
    return response;
  }

  async listeners(event: WalletEvent, callback: () => Promise<void>) {
    const ehter = window.ethereum as any;
    ehter?.on(event, () => callback());
  }

  async disconnect() {
    this.isInitialized = false;
    window.localStorage.removeItem(this.getName());
  }

  async restart() {
    // ? INFO: en metamask no es necesario reinicial el proveedor
  }
}
