/* eslint-disable class-methods-use-this */
import { Database, Libby, RESTSessionStrategy, RESTStoreStrategy } from "@phinxlab/libby-rest-web";
import { API_URL } from "../../config";
import { SessionChangeEmitter, checkSession } from "./session";
import { TokenManager } from "./TokenManager";
import {
  PromotionItemDAO,
  PublicationByStockDAO,
  PublicationByZoneDAO,
  PublicationDAO,
  CategoryDAO,
  CartDAO,
  TargetPromotionDAO,
  CardDAO,
  SimulateDAO,
  LocationDAO,
  PaymentDAO,
  ShipmentDAO,
  CampaignDAO,
  SubscriptionDAO,
  FileUploadDAO,
  SkeletonDAO,
  AccountDAO,
  ConfirmationDAO,
  PurchasedItemsDAO,
  PublicationFavoriteDAO,
  ConfigurationDAO,
  BuyerDAO,
  BuyerAddressDAO,
  TokenizedCardsDAO,
  PurchasesDAO,
  OrderDAO,
  OrderNftDAO,
  BuyerWalletDAO,
  ReloginDAO,
} from "./dao";

class DatabaseStoreRaw {
  chinoTokenEnvironmet: string;

  url: string;

  constructor() {
    this.chinoTokenEnvironmet = "EcommerceApp";
    this.url = API_URL;
  }

  addSession(): void {
    const Session = RESTSessionStrategy.init(this.url);
    Session.setTokenManager(TokenManager.create());
    Database.addSessionStrategy(Session);
    Session.addSessionListener({
      // TODO: remove any type
      newSessionState: async () => {
        const { isLogged, user } = await checkSession();
        SessionChangeEmitter.emitChange({ isLogged, user });
      },
    });
    Database.addSessionStrategy(Session);
  }

  register(): void {
    Database.register(new RESTStoreStrategy("promotion_item", "/api/business/promotionitem", "promotionItemId"), PromotionItemDAO);
    Database.register(new RESTStoreStrategy("publication_by_zone", "/publication/zone", "publicationId"), PublicationByZoneDAO);
    Database.register(new RESTStoreStrategy("cart", "/cart", "cartId"), CartDAO);
    Database.register(new RESTStoreStrategy("publication", "/publication", "publicationId"), PublicationDAO);
    Database.register(new RESTStoreStrategy("publication_by_stock", "/publication/stock", "publicationId"), PublicationByStockDAO);
    Database.register(new RESTStoreStrategy("category", "/category", "id"), CategoryDAO);
    Database.register(new RESTStoreStrategy("card", "/card", "cardId"), CardDAO);
    Database.register(new RESTStoreStrategy("targetpromotion", "/target", "name"), TargetPromotionDAO);
    Database.register(new RESTStoreStrategy("simulate", "/order/simulate", "emitter"), SimulateDAO);
    Database.register(new RESTStoreStrategy("PurchasedItems", "/order/purchased_items", "PurchasedItemsId"), PurchasedItemsDAO);
    Database.register(new RESTStoreStrategy("location", "/location", "locationId"), LocationDAO);
    Database.register(new RESTStoreStrategy("payment", "/payment", "paymentId"), PaymentDAO);
    Database.register(new RESTStoreStrategy("shipment", "/shipment", "shipmentid"), ShipmentDAO);
    Database.register(new RESTStoreStrategy("campaign", "/campaign", "campaignId"), CampaignDAO);
    Database.register(new RESTStoreStrategy("subscription", "/subscription", "subscriptionId"), SubscriptionDAO);
    Database.register(new RESTStoreStrategy("fileUpload", "/file/upload", "fileUploadId"), FileUploadDAO);
    Database.register(new RESTStoreStrategy("skeleton", "/skeleton", "skeletonId"), SkeletonDAO);
    Database.register(new RESTStoreStrategy("account", "/account", "account_id"), AccountDAO);
    Database.register(new RESTStoreStrategy("confirmation", "/confirmation", "confirmation_id"), ConfirmationDAO);
    Database.register(new RESTStoreStrategy("configuration", "/configuration", "configuration_id"), ConfigurationDAO);
    Database.register(new RESTStoreStrategy("buyer", "/buyer", "buyer_id"), BuyerDAO);
    Database.register(new RESTStoreStrategy("buyer_address", "/buyer/address", "buyer_address_id"), BuyerAddressDAO);
    Database.register(new RESTStoreStrategy("tokenized_cards", "/buyer/tokenizedcards", "tokenized_card_id"), TokenizedCardsDAO);
    Database.register(new RESTStoreStrategy("purchases", "/order/purchased", "purchased_id"), PurchasesDAO);
    Database.register(new RESTStoreStrategy("order", "/order/state", "orderid"), OrderDAO);
    Database.register(new RESTStoreStrategy("relogin", "/relogin", "reloging_id"), ReloginDAO);
    Database.register(new RESTStoreStrategy("publication_favorite", "/publication/favorites", "publicationFavoriteId"), PublicationFavoriteDAO);
    Database.register(new RESTStoreStrategy("order_nft", "/nft/order", "order_nft_id"), OrderNftDAO);
    Database.register(new RESTStoreStrategy("buyer_wallet", "/buyer/wallet", "buyer_wallet_id"), BuyerWalletDAO);
  }

  init(): void {
    Libby.App = this.chinoTokenEnvironmet;
    this.addSession();
    this.register();
  }
}

export const DatabaseStore = new DatabaseStoreRaw();
