import { TableRow } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";

export const StyledTableRowProductDescription = withStyles((theme) => ({
  root: {
    display: "grid",
    height: "auto",
    gridTemplateColumns: "repeat(2, 50%)",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      borderBottom: "none",
    },
    "& td": {
      borderBottom: "none",
      padding: "10px",
      wordWrap: "break-word",
    },
  },
}))(TableRow);

export const productDescriptionStyles = makeStyles(() => ({
  normalFontWeight: {
    fontWeight: "normal",
  },
}));
