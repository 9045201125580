import React, { useCallback, useEffect, useState } from "react";
import { Payment, CartItem } from "src/app/models";
import { usePaymentDAO, useCartDAO } from "src/app/business";
import { Button, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FormProps, ValuesForm } from "../../../types";
import { Loading } from "../../../../../../commons";
import { useCheckoutFormContext } from "../../../hooks";
import { createOrder, createPaymentCredit } from "../../../../../../utils/paymentOrderHelper";
import { useCartContext } from "../../../../../../context";
import { WithCardValues } from "../types";
import { DocentInitialValue, AlumnInitialValue } from "../../../initialValues";
import { useProcesingFile, ImagesUrl } from "../hooks/useProcesingFile";

interface Values extends ValuesForm {
  docentInfo?: DocentInitialValue<Partial<File> | undefined>;
  alumnInfo?: AlumnInitialValue<Partial<File> | undefined>;
}

export const CardInfoFormCredit = ({ onBackStep, setIsBlocking, deliveryData }: FormProps) => {
  const { values } = useCheckoutFormContext();
  const { cartState } = useCartContext();
  const cartDAO = useCartDAO();
  const paymentDAO = usePaymentDAO();
  const [requesting, setRequesting] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [error, setError] = useState("");

  const { getImagesUrl } = useProcesingFile();

  useEffect(() => {
    if (values && cartState && !requesting) {
      setRequesting(true);
      (async () => {
        try {
          const { items, owner, lastUpdate = new Date(), state_id } = cartState;
          const itemsFix: CartItem[] = items.map(({ quantity, publication }) => ({
            quantity,
            publication,
          }));

          const { personalInfo, applicantInfo, guarantorInfo, docentInfo, alumnInfo } = values as Values;

          const applicantInfoImg = applicantInfo
            ? await getImagesUrl([
                { item: applicantInfo.img_dni_back, name: "img_dni_back" },
                { item: applicantInfo.img_paycheck, name: "img_paycheck" },
                { item: applicantInfo.img_dni_front, name: "img_dni_front" },
                { item: applicantInfo.img_service_certificate, name: "img_service_certificate" },
              ] as Array<{ item: Partial<File>; name: string }>)
            : ({} as ImagesUrl);

          const guarantorInfoImg = guarantorInfo
            ? await getImagesUrl([
                { item: guarantorInfo.img_dni_back, name: "img_dni_back" },
                { item: guarantorInfo.img_paycheck, name: "img_paycheck" },
                { item: guarantorInfo.img_dni_front, name: "img_dni_front" },
                { item: guarantorInfo.img_service_certificate, name: "img_service_certificate" },
              ] as Array<{ item: Partial<File>; name: string }>)
            : ({} as ImagesUrl);
          const docentInfoImg = docentInfo
            ? await getImagesUrl([
                { item: docentInfo.img_dni_back, name: "img_dni_back" },
                {
                  item: docentInfo.img_certificate,
                  name: "img_certificate",
                },
                { item: docentInfo.img_dni_front, name: "img_dni_front" },
                { item: docentInfo.img_paycheck, name: "img_paycheck" },
              ] as Array<{
                item: Partial<File>;
                name: string;
              }>)
            : ({} as ImagesUrl);
          const AlumnInfoImg = alumnInfo
            ? await getImagesUrl([
                { item: alumnInfo.img_dni_back, name: "img_dni_back" },
                {
                  item: alumnInfo.img_certificate,
                  name: "img_certificate",
                },
                { item: alumnInfo.img_dni_front, name: "img_dni_front" },
              ] as Array<{
                item: Partial<File>;
                name: string;
              }>)
            : ({} as ImagesUrl);

          const order = await createOrder(
            { items: itemsFix, owner, lastUpdate, state_id, email: values.personalInfo.email, send: false },
            values as ValuesForm,
            deliveryData,
          );

          const paymentBody = createPaymentCredit(order, (values as WithCardValues).paymentMethod.payment, {
            ...(applicantInfo ? { applicantInfo: { ...applicantInfo, ...applicantInfoImg, ...personalInfo } } : {}),
            ...(guarantorInfo ? { guarantorInfo: { ...guarantorInfo, ...guarantorInfoImg } } : {}),
            ...(docentInfo ? { docentInfo: { ...docentInfo, ...docentInfoImg, ...personalInfo } } : {}),
            ...(alumnInfo ? { alumnInfo: { ...alumnInfo, ...AlumnInfoImg, ...personalInfo } } : {}),
          });

          const resultPayment: Payment = await paymentDAO.paymentCredit(paymentBody);
          if (resultPayment.id) {
            if (setIsBlocking) setIsBlocking(false);
            cartDAO.updateCart({ owner: cartState.owner, items: [], email: "", send: false });
            setTimeout(() => window.location.replace(`/cart/checkout/confirm/${resultPayment.id}?type=credit`), 0);
          } else {
            setShowButton(true);
            setError("");
          }
        } catch (error) {
          setError("Ocurrió un problema. Por favor pongase en contacto con nosotros");
          setShowButton(true);
        }
      })();
    }
  }, [cartState, paymentDAO, requesting, values, deliveryData, cartDAO, setIsBlocking, getImagesUrl]);

  const onPay = useCallback(() => {
    if (setIsBlocking) setIsBlocking(false);
    setRequesting(false);
  }, [setRequesting, setIsBlocking]);

  return (
    <Grid container direction="column" spacing={1}>
      {!!error && (
        <Grid item>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      {showButton ? (
        <Grid item>
          <Grid container spacing={1} justify="center">
            <Grid item>
              <Button variant="outlined" onClick={onBackStep}>
                Volver
              </Button>
            </Grid>
            {!error && (
              <Grid item>
                <Button variant="outlined" color="primary" onClick={onPay}>
                  Intentar Pagar
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </Grid>
  );
};
