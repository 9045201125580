import React from "react";
import { Grid, Container, useMediaQuery, Theme } from "@material-ui/core";
import { Loading } from "src/commons";
import { Helmet } from "react-helmet";
import { validateFreeShipping } from "src/utils";
import { getPricePublication } from "src/context/CartContext/utils";
import { ShipmentData } from "src/screens/Public/CalculateShipments/types";
import { ConfigValuesImages } from "src/app/models";
import { AppBreadcrumbsApp } from "../AppBreadcrumbs";
import { ProductDetailsLandingView, ProductDetailsView, InfoModalData } from "../ProductDetailsView";

interface ProductViewProps {
  dataPublication: any;
  newLinks: any;
  working: boolean;
  stock: any;
  workingStock: boolean;
  quantity: number;
  imagesCarousel: Array<any>;
  enableStock: boolean;
  availableQuantity: number;
  paymentImages: ConfigValuesImages;
  landingFeatures: Array<any>;
  idInfoModalShow: string;
  openMeansPayment: boolean;
  discount: number;
  shipmentData: ShipmentData;
  infoModalShow: (id: string) => InfoModalData | undefined;
  onBuyClick: () => void;
  onAddToCartClick: () => void;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  onClickOpenModal: (value: string) => void;
  setOpenMeansPayment: (value: boolean) => void;
}

export const ProductView = ({
  dataPublication,
  newLinks,
  working,
  stock,
  quantity,
  availableQuantity,
  imagesCarousel,
  paymentImages,
  enableStock,
  workingStock,
  landingFeatures,
  idInfoModalShow,
  openMeansPayment,
  discount,
  shipmentData,
  infoModalShow,
  onAddToCartClick,
  onBuyClick,
  onClickOpenModal,
  setOpenMeansPayment,
  setQuantity,
}: ProductViewProps) => {
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));
  return (
    <>
      {dataPublication && (
        <Helmet>
          <title>{dataPublication.product}</title>
          <meta name="description" content={dataPublication.attributes.description} />
        </Helmet>
      )}
      <Container maxWidth="lg" disableGutters={isXs}>
        <Grid container>
          <Grid>
            <AppBreadcrumbsApp links={newLinks} msgReturn="Ver más" />
          </Grid>
          <Grid>
            {working ? (
              <Loading />
            ) : dataPublication ? (
              landingFeatures?.length ? (
                <ProductDetailsLandingView
                  product={dataPublication}
                  stock={stock}
                  workingStock={workingStock}
                  onBuyClick={onBuyClick}
                  onAddToCartClick={onAddToCartClick}
                  onChangeQuantity={setQuantity}
                  quantity={quantity}
                  freeShipping={validateFreeShipping(getPricePublication(dataPublication).toString()) && dataPublication.shipment_category_id !== 2}
                  imagesCarousel={imagesCarousel}
                  onClickMeansPayment={() => onClickOpenModal("payment")}
                  openMeansPayment={openMeansPayment}
                  infoModalShow={infoModalShow}
                  idInfoModalShow={idInfoModalShow}
                  onClickGuarantees={() => onClickOpenModal("guarantees")}
                  onClickPolitics={() => onClickOpenModal("politics")}
                  onHandleCloseModal={() => setOpenMeansPayment(false)}
                  enableStock={enableStock}
                  availableQuantity={availableQuantity}
                  discount={discount}
                  calculateShipment={() => onClickOpenModal("calculateShipment")}
                  shipmentData={shipmentData}
                  paymentImages={paymentImages}
                />
              ) : (
                <ProductDetailsView
                  product={dataPublication}
                  stock={stock}
                  workingStock={workingStock}
                  onBuyClick={onBuyClick}
                  onAddToCartClick={onAddToCartClick}
                  onChangeQuantity={setQuantity}
                  quantity={quantity}
                  freeShipping={validateFreeShipping(getPricePublication(dataPublication).toString()) && dataPublication.shipment_category_id !== 2}
                  imagesCarousel={imagesCarousel}
                  onClickMeansPayment={() => onClickOpenModal("payment")}
                  openMeansPayment={openMeansPayment}
                  infoModalShow={infoModalShow}
                  idInfoModalShow={idInfoModalShow}
                  onClickGuarantees={() => onClickOpenModal("guarantees")}
                  onClickPolitics={() => onClickOpenModal("politics")}
                  onHandleCloseModal={() => setOpenMeansPayment(false)}
                  enableStock={enableStock}
                  availableQuantity={availableQuantity}
                  discount={discount}
                  calculateShipment={() => onClickOpenModal("calculateShipment")}
                  shipmentData={shipmentData}
                  paymentImages={paymentImages}
                />
              )
            ) : (
              <div>No existe este producto.</div>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
