import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Stock } from "../models";
import { usePublicationByStockDAO } from "../business";
import { CartItemStock } from "../../context/CartContext/types";
import { useCartContext } from "../../context";
import { Analytics } from "../../platform/analytics";

export interface ItemToErase {
  item: CartItemStock;
  index: number;
}

export const useCheckCartStock = () => {
  const { cartState, dispatch } = useCartContext();
  const { enqueueSnackbar } = useSnackbar();
  const [stockChecked, setStockChecked] = useState(false);
  const publicationByStockDAO = usePublicationByStockDAO();

  // Checking stock
  useEffect(() => {
    if (!stockChecked && publicationByStockDAO && cartState.items.length && dispatch) {
      setStockChecked(true);
      (async () => {
        const itemsToErase: Array<ItemToErase | null> = await Promise.all(
          cartState.items.map(async (item, index) => {
            const { quantity }: Stock = (await publicationByStockDAO.getByStock(item.publication.id)) as Stock;
            if (quantity < item.quantity) {
              return { item, index };
            }
            return null;
          }),
        );
        itemsToErase.forEach((item) => {
          if (item) {
            dispatch.removeItem(item.item.publication.id);
            Analytics.trackRemoveFromCart({ publication: item.item.publication, quantity: item.item.quantity });
            enqueueSnackbar(`Producto sin stock: ${item.item.publication.product}`, {
              variant: "error",
            });
          }
        });
        dispatch.updateCartTotals();
      })();
    }
  }, [cartState.items, dispatch, enqueueSnackbar, publicationByStockDAO, stockChecked]);

  return { stockChecked };
};
