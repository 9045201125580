import { Categories } from "src/screens/Public/Categories";
import { MenuItem } from "../../../../commons/ui/menu";

export const routes: MenuItem[] = [
  {
    key: "publicCategoriesMain",
    basePath: "/",
    target: {
      component: Categories,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Categories",
    children: [],
    default: true,
  },
];
