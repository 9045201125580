import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { FormContextProvider } from "src/lib/templates";
import { useCartContext, useGeneralContext } from "src/context";
import { Container, Grid, Backdrop, CircularProgress, Button, Typography, Box } from "@material-ui/core";
import theme from "src/theme";
import { StepRecord } from "src/app/views";
import { useCartDAO } from "src/app/business";
import { ModalDialog, sortOptions, Loading } from "src/commons";
import { useHistory } from "react-router";
import { Decidir } from "src/app/services";
import { useEmittersContext } from "src/context/EmittersContext/EmittersContextProvider";
import { Shipment, PAYMENT_STATUS } from "src/app/models";
import { EMITTERS } from "src/app/const/Emitters";
import { CheckoutForm } from "./components";
import {
  initialValue,
  deliveryInitialValue,
  paymentInitialValue,
  personalInfoInitialValue,
  cardInfoInitialValue,
  applicantInitialValue,
  guarantorInitialValue,
} from "./initialValues";
import { DeliveryInfoForm } from "./components/DeliveryInfoForm";
import { PaymentMethodInfoForm } from "./components/PaymentMethodInfoForm";
import { PersonalInformationForm } from "./components/PersonalInformationForm";
import { CardInfoForm } from "./components/CardInfoForm/CardInfoForm";
import { template, caprescaTemplate, deliveryInfoTemplate, paymentMethodTemplate, personalInfoTemplate } from "./templates";
import { cardInfoTemplate } from "./templates/cardInfoTemplate";
import { ValuesForm } from "./types";
import { TermsAndConditionsText } from "../TermsAndConditions";
import { useProcessingPayment } from "./hooks/useProcessingPayment";
import { Analytics } from "../../../platform/analytics";
import { useCheckCartStock } from "../../../app/hooks";
import { useMountFingerPrintScriptCallback } from "./components/CardInfoForm/hooks/useFingerprint";
import { CodeErrors, getErrorMsgDecidirCS } from "./utils";
import { ecommerceConfig } from "../../../config/ecommerce";
import { guarantorInfoTemplate } from "./templates/guarantorTemplate";
import { GuarantorInfoForm } from "./components/GuarantorInfoForm";
import { applicantInfoTemplate } from "./templates/applicantTemplate";
import { ApplicantInfoForm } from "./components/ApplicantInfoForm";
import { EmittersContextProvider } from "../../../context/EmittersContext/EmittersContextProvider";
import { FailurePaymentMethodView } from "../../../app/views/components/FailurePaymentMethodView";

export const steps: Array<StepRecord> = [
  {
    label: "Datos del comprador",
    hidden: false,
    template: personalInfoTemplate,
    initialValue: personalInfoInitialValue,
    render: PersonalInformationForm,
  },
  {
    label: "Información de envío",
    hidden: false,
    template: deliveryInfoTemplate,
    initialValue: deliveryInitialValue,
    render: DeliveryInfoForm,
  },
  { label: "Medio de pago", hidden: false, template: paymentMethodTemplate, initialValue: paymentInitialValue, render: PaymentMethodInfoForm },
  {
    label: "Datos del solicitante",
    hidden: true,
    template: applicantInfoTemplate,
    initialValue: applicantInitialValue,
    render: ApplicantInfoForm,
  },
  {
    label: "Datos del garante",
    hidden: true,
    template: guarantorInfoTemplate,
    initialValue: guarantorInitialValue,
    render: GuarantorInfoForm,
  },
  { label: "Finalizá tu compra", hidden: false, template: cardInfoTemplate, initialValue: cardInfoInitialValue, render: CardInfoForm },
];

interface MessageErrors {
  open: boolean;
  message: CodeErrors;
}

const CheckoutScreen = () => {
  const { cartState } = useCartContext();
  const { emitters, working, initialLoad } = useEmittersContext();
  const [isTracked, setIsTracked] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [deliveryData, setDeliveryData] = useState<Shipment>({ cost: 0 });
  const [openModal, setOpenModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState<MessageErrors>({ open: false, message: { title: "", description: "", details: "" } });
  const [completed, setCompleted] = useState(new Set<number>());
  const [tagsMounted, setTagsMounted] = useState<boolean>(false);
  const history = useHistory();
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const [isBlocking, setIsBlocking] = useState<boolean>(true);
  const generateFingerprint = useMountFingerPrintScriptCallback();
  const cartDAO = useCartDAO();
  const [stepsCheckout, setStepCheckout] = useState<Array<StepRecord>>(steps);

  const filterSteps = useMemo(() => stepsCheckout.filter((item) => !item.hidden), [stepsCheckout]);

  const maxStep = useMemo(() => filterSteps.length, [filterSteps]);

  const onHandleCloseModal = () => {
    setOpenModal(false);
  };

  const { getPayment, checkStatusPayment } = useProcessingPayment();

  const { stockChecked } = useCheckCartStock();

  const { generalState, dispatch: generalDispatch } = useGeneralContext();

  useEffect(() => {
    if (generalState.paginate.path === history.location.pathname) return;
    generalDispatch.setPaginate({ ...generalState.paginate, path: history.location.pathname });
    generalDispatch.sortOption(sortOptions[0]);
  }, [generalDispatch, generalState.paginate, history.location.pathname]);

  // checking cart items
  useEffect(() => {
    if (stockChecked && !cartState.items.length) {
      setIsBlocking(false);
      setTimeout(() => history.replace("/cart"), 0);
    }
  }, [cartState.items.length, history, stockChecked]);

  const onClickForm = useCallback(
    async (values: ValuesForm) => {
      if (activeStep === maxStep - 1) {
        setOpenBackdrop(true);
        try {
          const payment = await getPayment(values, deviceId, deliveryData);
          if (payment.id) {
            checkStatusPayment(payment.id, (result) => {
              if (result.payment_state_id === PAYMENT_STATUS.APPROVED) {
                setIsBlocking(false);
                cartDAO.updateCart({ owner: cartState.owner, items: [], email: "", send: false });
                setTimeout(() => window.location.replace(`/cart/checkout/confirm/${payment.id}`), 0);
              } else {
                setIsBlocking(false);
                setOpenErrorModal({
                  open: true,
                  message: getErrorMsgDecidirCS(result),
                });
              }
              setOpenBackdrop(false);
            });
          } else {
            setIsBlocking(false);
            setOpenErrorModal({
              open: true,
              message: getErrorMsgDecidirCS({
                payment_state_id: "",
                payment_state_name: "Error",
                metadata: {
                  status: 500,
                  result: "Error",
                  status_details: JSON.stringify(payment),
                },
              }),
            });
            setOpenBackdrop(false);
          }
        } catch (error) {
          console.log(error);
          setIsBlocking(false);
          history.push("/cart/checkout/failure");
        }
      } else {
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);
        setCompleted(newCompleted);
        setActiveStep((oldValue) => oldValue + 1);
      }
    },
    [completed, activeStep, history, cartDAO, cartState.owner, deviceId, deliveryData, maxStep, checkStatusPayment, getPayment],
  );

  const onBackStep = useCallback(() => setActiveStep((oldStep) => oldStep - 1), []);

  useEffect(() => {
    // calling start checkout
    if (cartState?.owner && cartState?.items?.length && !isTracked) {
      setIsTracked(true);
      Analytics.trackStartCheckout({ cart: cartState });
    }
  }, [cartState, isTracked]);

  useEffect(() => {
    if (!deviceId) {
      const id = Decidir.getDeviceFingerPrintId().toString();
      setDeviceId(id);
    }
  }, [setDeviceId, deviceId]);

  useEffect(() => {
    if (!tagsMounted && deviceId) {
      setTagsMounted(true);
      generateFingerprint(deviceId);
    }
  }, [deviceId, tagsMounted, generateFingerprint]);

  const closeErrorModal = useCallback(() => setOpenErrorModal({ open: false, message: { title: "", description: "", details: "" } }), []);

  return (
    <>
      {!working ? (
        <>
          <Helmet>
            <title>{ecommerceConfig.general.title}</title>
            <meta name="description" content={ecommerceConfig.general.description} />
          </Helmet>
          <Container maxWidth="lg">
            <Grid container justify={initialLoad ? "flex-start" : "center"}>
              <Grid>
                {initialLoad ? (
                  emitters.length ? (
                    <FormContextProvider
                      initialValues={initialValue}
                      onSubmit={onClickForm}
                      template={emitters.find((item) => item.card_emiter_id === EMITTERS.CATAMARCA_CREDITS) ? caprescaTemplate : template}
                    >
                      <CheckoutForm
                        totalSteps={stepsCheckout}
                        setOpenModal={setOpenModal}
                        cartItems={cartState.items}
                        maxStep={maxStep}
                        steps={filterSteps}
                        completedSteps={completed}
                        component={filterSteps[activeStep].render}
                        activeStep={activeStep}
                        isBlocking={isBlocking}
                        setIsBlocking={setIsBlocking}
                        totals={cartState.totals}
                        onBackStep={onBackStep}
                        onClickStep={(step) => setActiveStep(step)}
                        setDeliveryData={setDeliveryData}
                        deliveryData={deliveryData}
                        setStepCheckout={setStepCheckout}
                      />
                      <ModalDialog title="Términos y Condiciones" open={openModal} onHandleClose={onHandleCloseModal}>
                        <TermsAndConditionsText />
                      </ModalDialog>
                      <ModalDialog maxWidth="xs" title={`${openErrorModal.message.title}`} open={openErrorModal.open} onHandleClose={closeErrorModal}>
                        <Grid container direction="column">
                          <Grid item>
                            <Box bgcolor="primary.background" p={2} borderRadius={4} mb={4}>
                              <Typography variant="body1">{openErrorModal.message.description}</Typography>
                              <Typography variant="body1">{openErrorModal.message.details}</Typography>
                            </Box>
                          </Grid>

                          <Grid item container justify="flex-end">
                            <Button onClick={closeErrorModal} color="primary" variant="contained" autoFocus>
                              Volver
                            </Button>
                          </Grid>
                        </Grid>
                      </ModalDialog>
                    </FormContextProvider>
                  ) : (
                    <Loading />
                  )
                ) : (
                  <FailurePaymentMethodView />
                )}
              </Grid>
            </Grid>
            <Backdrop style={{ zIndex: theme.zIndex.appBar + 1, background: "rgba(255, 255, 255, 0.54)" }} open={openBackdrop}>
              <CircularProgress color="primary" />
            </Backdrop>
          </Container>
        </>
      ) : null}
    </>
  );
};

export const Checkout = () => (
  <EmittersContextProvider>
    <CheckoutScreen />
  </EmittersContextProvider>
);
