import React, { useCallback } from "react";
import { useFormContext, FormInput, FormContextProvider } from "src/lib/templates";
import { Box, Card, Grid, Button, useMediaQuery, Theme, Container, Typography } from "@material-ui/core";
import { useAccountDAO } from "src/app/business";
import { useHistory } from "react-router";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Joi from "joi";
import { useSnackbar } from "notistack";
import { AppBreadcrumbsApp } from "src/app/views";
import { useBreadcrumbs } from "src/commons";
import { useRolesContext } from "src/context";

interface EditPasswordValue {
  confirmPassword: string;
  password: string;
}
const initialValues: EditPasswordValue = { confirmPassword: "", password: "" };

export const EditPassword = () => {
  const history = useHistory();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isBw768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));
  const accountDAO = useAccountDAO();
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useRolesContext();

  const links = useBreadcrumbs({
    links: isDownSm
      ? [
          { label: "Seguridad", url: "/dashboard" },
          { label: "Cambiar clave", url: "/dashboard/configuration/security" },
          { label: "Mi cuenta", url: "/dashboard/configuration/security" },
        ]
      : [
          { label: "Mi cuenta", url: "/dashboard" },
          { label: "Securidad", url: "/dashboard/configuration/security" },
          { label: "Cambiar clave", url: "/dashboard/configuration/security/password" },
        ],
  });

  const Form = () => {
    const { form, errors, handleSubmit } = useFormContext<EditPasswordValue>();
    return (
      <Box component={Card} width="100%" borderRadius={8} boxShadow={3} display="content" position="relative">
        <Box padding={isDownSm ? 2 : 3} width="100%">
          <Grid container direction="row" spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormInput
                variant="standard"
                inputLabelProps={{
                  shrink: true,
                }}
                label={
                  <Box color="text.disabled" minWidth="104px">
                    Ingresá tu nueva clave
                  </Box>
                }
                type="password"
                name="password"
                form={form}
                size="small"
                id="password-1"
                autoComplete="password"
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormInput
                variant="standard"
                inputLabelProps={{
                  shrink: true,
                }}
                label={
                  <Box color="text.disabled" minWidth="104px">
                    Confirma tu nueva clave
                  </Box>
                }
                type="password"
                name="confirmPassword"
                form={form}
                size="small"
                id="confirmPassword-1"
                autoComplete="password"
              />
            </Grid>

            <Grid item xs={12} />

            <Grid item container xs={12} justify="flex-end">
              <Box mr={2}>
                <Button style={{ textTransform: "none" }} size="large" variant="text" color="primary" onClick={() => history.goBack()}>
                  Cancelar
                </Button>
              </Box>

              <Grid item>
                <Button
                  type="submit"
                  onClick={() => handleSubmit()}
                  disabled={Object.keys(errors as any).length > 0}
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const onSubmit = useCallback(
    async (values: EditPasswordValue) => {
      try {
        await accountDAO.updateAccess({ email: userInfo.email, newPassword: values.password });
        enqueueSnackbar("Modificaste tu clave", { variant: "success" });
        history.push("/dashboard/configuration/security");
      } catch (error) {
        console.log("No se pudo modificar la clave.", error);
        enqueueSnackbar("No se pudo modificar la clave.", { variant: "error" });
      }
    },
    [userInfo, accountDAO, history, enqueueSnackbar],
  );

  return (
    <Container maxWidth="lg">
      <Box px={isDownSm ? 0 : "10%"} mt={isDownSm ? 1 : 3}>
        {isDownSm && (
          <AppBreadcrumbsApp
            links={links}
            iconSeparator={<Box component="div">/</Box>}
            iconGoBack={<ChevronLeftIcon color="primary" />}
            invert
            msgReturn=" "
            noMargin
          />
        )}
        <Grid container item spacing={isDownSm ? 0 : 2} xs={12} sm={isBw768 ? 8 : 12} md={8}>
          {!isDownSm && (
            <Grid item xs={12}>
              <Typography variant="h5" color="textPrimary">
                Cambiar clave
              </Typography>
            </Grid>
          )}

          {!isDownSm && (
            <Grid item xs={8}>
              <Typography variant="caption" color="textSecondary">
                Tu clave debe tener entre 6 y 20 caracteres. No incluyas tu nombre, apellido o e-mail, ni caracteres idénticos consecutivos.
              </Typography>
            </Grid>
          )}

          <Grid item container lg={7} md={7} sm={isBw768 ? 7 : 12} xs={12}>
            <FormContextProvider
              initialValues={initialValues}
              onSubmit={onSubmit}
              template={{
                name: "Update email",
                template: [
                  {
                    name: "password",
                    type: { name: "string" },
                    label: "Clave",
                    validators: [
                      {
                        name: "pattern",
                        signature: ["^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$"],
                        errorMessage: "Verificá cumplir con los requisitos de contraseña.",
                      },
                      { name: "min", signature: [6], errorMessage: "Verificá cumplir con los requisitos de contraseña." },
                      { name: "max", signature: [20], errorMessage: "Verificá cumplir con los requisitos de contraseña." },
                      { name: "empty", errorMessage: "Completa este dato" },
                    ],
                  },
                  {
                    name: "confirmPassword",
                    type: { name: "string" },
                    label: "Confirmar clave",
                    validators: [
                      {
                        name: "pattern",
                        signature: ["^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$"],
                        errorMessage: "Verificá cumplir con los requisitos de contraseña.",
                      },
                      { name: "empty", errorMessage: "Completa este dato" },
                      { name: "min", signature: [6], errorMessage: "Verificá cumplir con los requisitos de contraseña." },
                      { name: "max", signature: [20], errorMessage: "Verificá cumplir con los requisitos de contraseña." },
                      { name: "valid", signature: [Joi.ref("password")] },
                    ],
                    options: { messages: { "any.only": "Ambas contraseñas deben ser iguales." } },
                  },
                ],
              }}
            >
              <Form />
            </FormContextProvider>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
