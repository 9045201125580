import React from "react";
import { Grid, Box, Typography, ListItemText, makeStyles, useMediaQuery, Theme, Button } from "@material-ui/core";
import { TimerBomb } from "src/app/models";
import { Link } from "react-router-dom";
import { Variant } from "@material-ui/core/styles/createTypography";
import ReactHtmlParser from "react-html-parser";
import { CSSConfig } from "src/app/skeletons/components/SkeletonTimerCount";
import { ecommerceConfig } from "src/config/ecommerce";

const usepromotionalStyles = makeStyles((theme) => ({
  bgPromo: {
    backgroundRepeat: ({ cssConfig }: { cssConfig: CSSConfig }) => cssConfig.bgImagen.backgroundRepeat,
    backgroundPosition: ({ cssConfig }: { cssConfig: CSSConfig }) => cssConfig.bgImagen.backgroundPosition,
    backgroundImage: ({ cssConfig }: { cssConfig: CSSConfig }) =>
      `url(${cssConfig.bgImagen.backgroundImage || ecommerceConfig.assets.timerBackgroundImage.backgroundImage}),${
        cssConfig.bgImagen.backgroundColor || ecommerceConfig.assets.timerBackgroundImage.backgroundColor
      }`,
    padding: "16px 48px 16px 48px",
    [theme.breakpoints.down("md")]: {
      padding: "26px",
    },
    [theme.breakpoints.down(281)]: {
      padding: "16px",
    },
    borderRadius: "4px",
    marginBottom: "26px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "16px",
    },
  },
  countTimer: {
    marginBottom: "16px",
  },
}));

interface PromotionalBombViewProps {
  timer: TimerBomb;
  title: string;
  subTitle: string;
  redirecTo?: string;
  cssConfig: CSSConfig;
  stylesBg: any;
}

interface TypeTextTimer {
  boxWidth: string;
  leterCountVariant: Variant;
  numberCountVariant: Variant;
}

const down320: TypeTextTimer = {
  boxWidth: "45px",
  leterCountVariant: "caption",
  numberCountVariant: "h5",
};

export const PromotionalBombView = ({ timer, title, subTitle, redirecTo, cssConfig, stylesBg }: PromotionalBombViewProps) => {
  const classes = usepromotionalStyles({ cssConfig });
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isDown690 = useMediaQuery<Theme>((theme) => theme.breakpoints.down(691));
  const is768 = useMediaQuery<Theme>((theme) => theme.breakpoints.up(767));
  const is320 = useMediaQuery<Theme>((theme) => theme.breakpoints.down(321));
  const isBetween768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));

  const LinkButton = ({ children, ...props }: any) => (
    <Button {...props} color="primary" variant="contained">
      {children}
    </Button>
  );

  const boxWidth = is320 || isBetween768 ? down320.boxWidth : "58px";
  const leterCountVariant = is320 || isBetween768 ? down320.leterCountVariant : "subtitle1";
  const numberCountVariant = is320 || isBetween768 ? down320.numberCountVariant : "h4";

  return (
    <Grid container className={classes.bgPromo} justify="center" alignContent="center" style={stylesBg}>
      {!redirecTo && (
        <Grid
          item
          xs={12}
          sm={is768 ? (redirecTo ? 3 : 2) : 12}
          md={redirecTo ? 3 : 2}
          container
          justify={isDownSm ? (is768 ? "flex-end" : "flex-start") : "flex-end"}
          alignItems="center"
        >
          {cssConfig.image && !isDown690 && <Grid width="100px" height="100px" component="img" src={cssConfig.image} />}
        </Grid>
      )}
      <Grid item xs={12} sm={is768 ? (redirecTo ? 5 : 6) : 12} md={redirecTo ? 5 : 6}>
        <Box pb={isDownSm ? 3 : 0} justifyItems="center">
          <ListItemText
            primary={
              <Typography variant="h4" align={redirecTo ? "left" : isDown690 ? "left" : "center"} color="textPrimary">
                <Box color="primary.contrastText">{ReactHtmlParser(title)}</Box>
              </Typography>
            }
            secondary={
              <Typography variant="subtitle1" align={redirecTo ? "left" : isDown690 ? "left" : "center"} color="textSecondary">
                <Box pt={1} color="primary.contrastText">
                  {ReactHtmlParser(subTitle)}
                </Box>
              </Typography>
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={is768 ? 4 : 12} md={4} container justify="center" className={classes.countTimer}>
        <Grid item xs={3} md={3} container justify="center" direction="column">
          <Box
            borderRadius={2}
            p={1}
            alignSelf="center"
            display="flex"
            justifyContent="center"
            width={boxWidth}
            height={boxWidth}
            bgcolor={cssConfig.count.bgColor}
          >
            <Typography variant={numberCountVariant} color="textPrimary">
              <Box color="primary.contrastText">{timer.days}</Box>
            </Typography>
          </Box>
          <Typography display="block" align="center" variant={leterCountVariant} color="textPrimary">
            <Box component="text" color={cssConfig.count.indicatorColor}>
              Días
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={3} md={3} container justify="center" direction="column">
          <Box
            borderRadius={2}
            p={1}
            alignSelf="center"
            display="flex"
            justifyContent="center"
            width={boxWidth}
            height={boxWidth}
            bgcolor={cssConfig.count.bgColor}
          >
            <Typography variant={numberCountVariant} color="textPrimary">
              <Box color="primary.contrastText">{timer.hours}</Box>
            </Typography>
          </Box>
          <Typography display="block" align="center" variant={leterCountVariant} color="textPrimary">
            <Box component="text" color={cssConfig.count.indicatorColor}>
              Horas
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={3} md={3} container justify="center" direction="column">
          <Box
            borderRadius={2}
            alignSelf="center"
            p={1}
            display="flex"
            justifyContent="center"
            width={boxWidth}
            height={boxWidth}
            bgcolor={cssConfig.count.bgColor}
          >
            <Typography variant={numberCountVariant} color="textPrimary">
              <Box color="primary.contrastText">{timer.minutes}</Box>
            </Typography>
          </Box>
          <Typography display="block" align="center" variant={leterCountVariant} color="textPrimary">
            <Box component="text" color={cssConfig.count.indicatorColor}>
              Minutos
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={3} md={3} container justify="center" direction="column">
          <Box
            borderRadius={2}
            alignSelf="center"
            p={1}
            display="flex"
            justifyContent="center"
            width={boxWidth}
            height={boxWidth}
            bgcolor={cssConfig.count.bgColor}
          >
            <Typography variant={numberCountVariant} color="textPrimary">
              <Box color="primary.contrastText">{timer.seconds}</Box>
            </Typography>
          </Box>
          <Typography display="block" align="center" variant={leterCountVariant} color="textPrimary">
            <Box component="text" color={cssConfig.count.indicatorColor}>
              Segundos
            </Box>
          </Typography>
        </Grid>
      </Grid>

      {redirecTo && (
        <Grid
          item
          xs={12}
          sm={is768 ? 3 : 12}
          md={3}
          container
          justify={isDownSm ? (is768 ? "flex-end" : "flex-start") : "flex-end"}
          alignItems="center"
        >
          <Grid>
            <Link component={LinkButton} to={redirecTo || "/"}>
              Ver ofertas
            </Link>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
