import { EcommercePalette } from "../../../app/models/general/ecommercePalette";
import { black, white } from "../../../theme/colors";

export const ecommercePalette: EcommercePalette = {
  themeColor: "#000000",
  materialUi: {
    text: {
      primary: "#FFFFFF",
      secondary: "#737373",
      disabled: "#9E9E9E",
      hint: "#BCBCBC",
      initial: "#FFFFFF",
    },
    action: {
      main: "#E0E0E0",
      background: "#FFFFFF",
      disabledBackground: "#737373",
      disabledText: "#121212",
      cta: "#3D2CFC",
    },
    primary: {
      contrastText: "#FFFFFF",
      main: "#95F035",
      light: "#B5FA6D",
      dark: "#2E3E1D",
      background: "#FFFFFF",
      border: "#CD44FF",
      negroStore: "#4F4F4F",
      successAlert: "#70A736",
      3: "#2E3E1D", // ? :(
    },
    secondary: {
      contrastText: "#FFFFFF",
      main: "#FF007C",
      light: "#FDE8DC",
      dark: "#270013",
      background: "#FFF6ED",
      border: "#FFB668",
      errorAlert: "#C70061",
    },
    success: {
      contrastText: "#FFFFFF",
      main: "#4CAF50",
      light: "#7BC67E",
      dark: "#3B873E",
      textDark: "#1e4620",
      lightBg: "#EdF7ED",
      border: "#4CAF5050",
    },
    info: {
      contrastText: "#FFFFFF",
      main: "#2196F3",
      light: "#64B6F7",
      dark: "#0B79D0",
      textDark: "#0d3c61",
      lightBg: "#E8f4fe",
      border: "#2196F350",
    },
    warning: {
      contrastText: "#00000087",
      main: "#FF9800",
      light: "#FFB547",
      dark: "#C77700",
      textDark: "#663D00",
      lightBg: "#FFF4E5",
      border: "#FF980050",
    },
    error: {
      contrastText: "#FFFFFF",
      main: "#F44336",
      light: "#F88078",
      dark: "#E31B0C",
      textDark: "#621b16",
      lightBg: "#FEECEB",
      border: "#F4433650",
    },
    neutral: {
      1: "white",
      2: "#ABABAB",
      3: "#737373",
      4: "#363636",
      5: "#121212",
      6: "#000000",
    },
    other: {
      stroke: "#00000023",
      divider: "#00000012",
      backdrop: "#00000050",
      snackbar: "#323232",
      activeRaiting: "#FFB400",
    },
    /** Estos colores tienen que ser eliminados una vez que se reemplazen con los colores del Theme (arriba) en todos los overrides de los Mui files */
    background: {
      default: "#000000",
      defaultDark: "#000000",
      dark: "#394363",
      paper: "#121212",
      greyF5: "#F5F5F5",
      blue: "#2D3367",
    },
    border: "#DFE3E8",
    divider: "#363636",

    common: {
      black,
      white,
      neutral: "#e4e7eb",
      muted: "#9EA0A4",
    },
  },
};
