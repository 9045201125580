import React, { ReactNode, ChangeEvent, createRef } from "react";
import { useField } from "react-final-form-hooks";
import _ from "lodash";
import { Grid, TextField, MenuItem, InputProps, InputLabelProps, Box } from "@material-ui/core";
import { getErrorAndHelperTextNoTranslation } from "src/utils/materialHelpers";
import { FormApi } from "final-form";
import { Loading } from "src/commons";

// FIXME: type form props
export interface FormSelectProps<T> {
  name: string;
  form: FormApi<any, Partial<any>>;
  content: T[];
  valueKey?: string;
  labelKey?: string;
  fullWidth?: boolean;
  inputProps?: InputProps;
  placeholder?: ReactNode;
  label?: ReactNode;
  disabled?: boolean;
  variant?: "standard" | "filled" | "outlined";
  inputLabelProps?: Partial<InputLabelProps>;
  size?: "small" | "medium";
  positionLabel?: "inner" | "outer";
  loading?: boolean;
  helperText?: ReactNode;
  multiple?: boolean;
  defaultValue?: T;
  showErrorAtInvalid?: boolean;
  optionToValue?: (value: string, options?: T[]) => T | undefined;
  valueToOption?: (value: T, options: T[]) => string | undefined;
}

export function FormSelect<T>({
  name,
  form,
  content,
  label,
  placeholder = "Seleccioná",
  fullWidth = false,
  valueKey = "description",
  labelKey = "id",
  inputLabelProps,
  variant,
  size,
  positionLabel = "inner",
  loading = false,
  helperText,
  multiple,
  showErrorAtInvalid = false,
  disabled,
  optionToValue,
  valueToOption,
}: FormSelectProps<T>) {
  const field = useField(name, form);

  const { error, helperText: innerHelperText } = getErrorAndHelperTextNoTranslation(field);

  const options = content[0] ? [{ ...content[0], [valueKey]: "", [labelKey]: placeholder }, ...content] : content;

  const helperTextEnd = typeof innerHelperText !== "object" ? innerHelperText : helperText;

  const helperTextGlobal = showErrorAtInvalid ? (typeof field.meta.error !== "object" ? field.meta.error : helperText) : helperTextEnd;

  const errorGlobal = showErrorAtInvalid ? error || field.meta.invalid : error;

  const ref = createRef<HTMLLIElement>();

  return (
    <Grid>
      {loading ? (
        <Loading size={30} />
      ) : (
        <>
          {positionLabel === "outer" && <Box mb="10px">{label}</Box>}
          <TextField
            disabled={disabled}
            fullWidth={fullWidth}
            select
            label={positionLabel === "inner" && label}
            variant={variant}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              const newValue = event.target.value;
              if (Array.isArray(newValue)) {
                field.input.onChange(optionToValue ? newValue.map((item) => optionToValue(item, options)) : newValue);
              } else {
                field.input.onChange(optionToValue ? optionToValue(newValue, options) : newValue);
              }
            }}
            value={field.input.value ? (valueToOption ? valueToOption(field.input.value, options) : field.input.value) : multiple ? [] : ""}
            helperText={errorGlobal ? helperTextGlobal : undefined}
            error={errorGlobal}
            onFocus={field.input.onFocus}
            onBlur={field.input.onBlur}
            size={size}
            InputLabelProps={inputLabelProps}
          >
            {options.map((option) => {
              const theValue = _.get(option, valueKey);
              return (
                <MenuItem ref={ref} key={theValue} id={theValue} value={theValue}>
                  {_.get(option, labelKey)}
                </MenuItem>
              );
            })}
          </TextField>
        </>
      )}
    </Grid>
  );
}
