import React, { useEffect } from "react";
import { AccordionSelector, AccordionSelectorProps, AnyObject, Loading } from "src/commons";
import { makeEntityLibbyCall } from "../hooks";

export interface WithAccordionSelectorPropsProps<T>
  extends Pick<AccordionSelectorProps<T>, "onChange" | "onItemClick" | "value" | "title" | "expanded"> {
  filter?: AnyObject;
}
export interface WithAccordionSelectorPropsDefaultProps<T> {
  labelKey: keyof T;
  idKey: keyof T;
}
const dummyFilter = {};
export function withAccordionSelector<T>(daoName: string, defaultProps: WithAccordionSelectorPropsDefaultProps<T>) {
  const useHook = makeEntityLibbyCall(daoName);
  return (props: WithAccordionSelectorPropsProps<T>) => {
    const { filter = dummyFilter, ...restProps } = props;
    // TODO: improve this
    const { working, data, recall } = useHook({
      methodName: "fetch",
      params: [],
      noAutoCall: true,
    });
    useEffect(() => {
      if (recall) {
        const params = { filter, limit: 200000 };
        recall(params);
      }
    }, [filter, recall]);
    return working || !data ? <Loading /> : <AccordionSelector<T> items={data as T[]} {...defaultProps} {...restProps} />;
  };
}
