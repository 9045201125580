import React from "react";

interface SearchNotFoundIconProps {
  width?: string;
  height?: string;
}

export const SearchNotFoundIcon = ({ width = "293", height = "293" }: SearchNotFoundIconProps) => (
  <svg width={width} height={height} viewBox="0 0 280 230" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_2139_15174)">
      <path
        d="M146.466 173C131.857 173 120 161.155 120 146.5C120 131.872 131.857 120 146.466 
            120C161.101 120 172.931 131.872 172.931 146.5C172.931 161.155 161.101 173 146.466 173Z"
        stroke="#FF007C"
        strokeWidth="6.47308"
      />
    </g>
    <g filter="url(#filter1_f_2139_15174)">
      <path
        d="M146.466 173C131.857 173 120 161.155 120 146.5C120 131.872 131.857 120 146.466 
            120C161.101 120 172.931 131.872 172.931 146.5C172.931 161.155 161.101 173 146.466 173Z"
        stroke="#FF007C"
        strokeWidth="8.4648"
      />
    </g>
    <g>
      <path
        d="M146.466 173C131.857 173 120 161.155 120 146.5C120 131.872 131.857 120 146.466 
            120C161.101 120 172.931 131.872 172.931 146.5C172.931 161.155 161.101 173 146.466 173Z"
        stroke="#FF007C"
        strokeOpacity="0.2"
        strokeWidth="6.47308"
      />
    </g>
    <g filter="url(#filter3_f_2139_15174)">
      <path
        d="M146.466 173C131.857 173 120 161.155 120 146.5C120 131.872 131.857 120 146.466 
            120C161.101 120 172.931 131.872 172.931 146.5C172.931 161.155 161.101 173 146.466 173Z"
        stroke="#FF007C"
        strokeWidth="3.98343"
      />
    </g>
    <g filter="url(#filter4_f_2139_15174)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.466 173C131.857 173 120 161.155 
            120 146.5C120 131.872 131.857 120 146.466 120C161.101 120 172.931 131.872 172.931 146.5C172.931 161.155 161.101 173 146.466 173Z"
        stroke="white"
        strokeWidth="1.99172"
      />
    </g>
    <g filter="url(#filter5_b_2139_15174)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.456 172.926C131.852 172.926 120 
            161.097 120 146.463C120 131.855 131.852 120 146.456 120C161.086 120 172.911 131.855 
            172.911 146.463C172.911 161.097 161.086 172.926 146.456 172.926Z"
        fill="#95F035"
        fillOpacity="0.01"
      />
    </g>
    <g filter="url(#filter6_f_2139_15174)">
      <path d="M179.02 102.86V104.66H175.08V108.66H172.98V104.66H169V102.86H172.98V99H175.08V102.86H179.02Z" fill="white" />
    </g>
    <g filter="url(#filter7_f_2139_15174)">
      <path d="M123.02 137.86V139.66H119.08V143.66H116.98V139.66H113V137.86H116.98V134H119.08V137.86H123.02Z" fill="white" />
    </g>
    <g filter="url(#filter8_f_2139_15174)">
      <path d="M164.02 161.862V162.73H162.119V164.66H161.106V162.73H159.186V161.862H161.106V160H162.119V161.862H164.02Z" fill="#ABABAB" />
    </g>
    <path
      d="M163.004 161.664L154.29 152.861V151.593V151.393L154.152 151.248L153.654 150.726L153.324 
        150.38L152.964 150.694C150.95 152.446 148.338 153.5 145.496 153.5C145.353 153.5 145.214 153.495 
        145.075 153.487V150.744C145.212 150.758 145.355 150.769 145.496 150.769C150.367 150.769 154.29 
        146.79 154.29 141.875C154.29 136.96 150.367 132.981 145.496 132.981C141.108 132.981 137.534 
        136.219 136.858 140.442H134.123C134.811 134.687 139.643 130.25 145.496 130.25C151.831 130.25 
        156.975 135.449 156.975 141.875C156.975 144.755 155.933 147.402 154.201 149.442L153.899 
        149.797L154.233 150.123L154.749 150.627L154.895 150.769H155.098H156.345L165.048 159.595L163.004 
        161.664Z"
      fill="#FF007C"
      stroke="white"
    />
    <path
      d="M139.913 144.337L135.36 148.944L130.808 144.337L129.5 145.661L134.052 150.268L129.5 
        154.876L130.808 156.2L135.36 151.593L139.913 156.2L141.221 154.876L136.669 150.268L141.221 
        145.661L139.913 144.337Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_f_2139_15174"
        x="0.671478"
        y="0.671234"
        width="291.589"
        height="291.658"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="58.0461" result="effect1_foregroundBlur_2139_15174" />
      </filter>
      <filter
        id="filter1_f_2139_15174"
        x="77.0702"
        y="77.0702"
        width="138.791"
        height="138.86"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="19.3487" result="effect1_foregroundBlur_2139_15174" />
      </filter>
      <filter
        id="filter2_ddi_2139_15174"
        x="66.4571"
        y="105.154"
        width="160.018"
        height="160.086"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="25.1533" />
        <feGaussianBlur stdDeviation="9.67435" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0382353 0 0 0 0 0.261765 0 0 0 0 0.0955882 0 0 0 0.7 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_2139_15174" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="38.6974" />
        <feGaussianBlur stdDeviation="25.1533" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0764706 0 0 0 0 0.523529 0 0 0 0 0.191176 0 0 0 1 0" />
        <feBlend mode="color-dodge" in2="effect1_dropShadow_2139_15174" result="effect2_dropShadow_2139_15174" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2139_15174" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-2.51533" dy="5.03066" />
        <feGaussianBlur stdDeviation="1.25767" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_2139_15174" />
      </filter>
      <filter
        id="filter3_f_2139_15174"
        x="117.012"
        y="117.012"
        width="58.9063"
        height="58.9751"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.497929" result="effect1_foregroundBlur_2139_15174" />
      </filter>
      <filter
        id="filter4_f_2139_15174"
        x="118.34"
        y="118.34"
        width="56.2512"
        height="56.3195"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.331953" result="effect1_foregroundBlur_2139_15174" />
      </filter>
      <filter
        id="filter5_b_2139_15174"
        x="96.6631"
        y="96.6631"
        width="99.5855"
        height="99.5995"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="11.6685" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2139_15174" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2139_15174" result="shape" />
      </filter>
      <filter id="filter6_f_2139_15174" x="165" y="95" width="18.02" height="17.66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2139_15174" />
      </filter>
      <filter id="filter7_f_2139_15174" x="109" y="130" width="18.02" height="17.66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2139_15174" />
      </filter>
      <filter
        id="filter8_f_2139_15174"
        x="155.187"
        y="156"
        width="12.8335"
        height="12.66"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2139_15174" />
      </filter>
    </defs>
  </svg>
);
