import React from "react";
import { SITE_URL } from "src/app/const";
import { Box, Grid, Typography, Container, useMediaQuery, Theme, Paper, Link, Divider } from "@material-ui/core";
import { OptionListProps } from "src/commons/ui/view/OptionList/OptionList";
import { Padding } from "src/commons/components/Padding";
import { SPACING } from "src/commons";
import { appFooterStyles as useStyles } from "src/app/views/styles";
import caceLogo from "../../../../assets/images/CACE originales 3.png";
import dataFiscal from "../../../../assets/images/DATAWEB 2.svg";
import { ecommerceConfig } from "../../../../config/ecommerce";
import { AppFooterSubscription } from "./components";
import Facebook from "../../../../assets/images/Facebook.png";
import Instagram from "../../../../assets/images/Instagram.png";
import Twitter from "../../../../assets/images/Twitter.png";
import ConmebolLogo from "../../../../assets/images/ConmebolLogo.png";
import FifaLogo from "../../../../assets/images/FifaLogo.png";
import AfaLogoNegativo from "../../../../assets/images/AfaLogoNegativo.png";

export interface AppFooterProps {
  title: string;
  email: string;
  onChangeEmail: () => void;
  onSubcriber: () => void;
  optionLists: Array<OptionListProps>;
}

export const AppFooter = ({ optionLists }: AppFooterProps) => {
  const classes = useStyles();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down(769));
  const isUp767 = useMediaQuery<Theme>((theme) => theme.breakpoints.up(767));
  const is540 = useMediaQuery<Theme>((theme) => theme.breakpoints.up(539));
  const Wrapper = isDownSm ? React.Fragment : Padding;
  const { mainLogo: MainLogo } = ecommerceConfig.ecommerceLogo;

  return (
    <Container className={classes.root} maxWidth={false} disableGutters>
      <Box mt="-5px">
        <Paper elevation={3} square>
          <AppFooterSubscription />
          <Divider component="div" />
          <Container maxWidth="lg">
            <Box minHeight={270}>
              <Wrapper>
                <Grid container spacing={SPACING.COMMON} className={classes.container} direction="row" justify="space-around" alignItems="flex-start">
                  <Grid item xs={12} sm={12} md={12} xl={3} lg={3}>
                    {isDownSm || <MainLogo height="44" width="250" />}
                  </Grid>
                  <Grid item sm={12} md={12} xl={3} lg={3}>
                    <Typography variant="body1">Más información</Typography>
                    <Link href={SITE_URL.NOSOTROS}>
                      <Typography variant="body1" className={classes.linksFooter}>
                        Nosotros
                      </Typography>
                    </Link>
                    <Link href={SITE_URL.COMO_COMPRAR}>
                      <Typography variant="body1" className={classes.linksFooter}>
                        Cómo comprar
                      </Typography>
                    </Link>
                    <Link href={SITE_URL.PREGUNTAS_FRECUENTES}>
                      <Typography variant="body1" className={classes.linksFooter}>
                        Preguntas frecuentes
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid container xs={6} sm={6} md={6} xl={3} lg={3} className={classes.associationsLogo}>
                    <Link href={SITE_URL.SITE_CONMEBOL} target="blank">
                      <img width={isDownSm ? 40 : 85.12} height={isDownSm ? 40 : 85.12} src={ConmebolLogo} alt="conmebol logo" />
                    </Link>
                    <Link href={SITE_URL.SITE_AFA} target="blank">
                      <img width={isDownSm ? 28.11 : 48} height={isDownSm ? 35 : 60} src={AfaLogoNegativo} alt="afa logo" />
                    </Link>
                    <Link href={SITE_URL.SITE_FIFA} target="blank">
                      <img width={isDownSm ? 46 : 79.33} height={isDownSm ? 14 : 26.02} src={FifaLogo} alt="fifa logo" />
                    </Link>
                  </Grid>
                  <Grid container xs={6} sm={6} md={6} xl={3} lg={3} className={classes.socialLogo}>
                    <Link href={SITE_URL.SITE_FACEBOOK} target="blank">
                      <img height={isDownSm ? 30 : 40} src={Facebook} alt="facebook logo" />
                    </Link>
                    <Link href={SITE_URL.SITE_INSTAGRAM} target="blank">
                      <img height={isDownSm ? 30 : 40} src={Instagram} alt="instagram logo" />
                    </Link>
                    <Link href={SITE_URL.SITE_TWITTER} target="blank">
                      <img height={isDownSm ? 30 : 40} src={Twitter} alt="twitter logo" />
                    </Link>
                  </Grid>
                  {optionLists.map((option) => (
                    <Grid key={option.title} item xs={12} sm={isUp767 ? 4 : is540 ? 6 : 12} md={4} lg={3}>
                      <Typography variant="h6">{option.title}</Typography>
                      {option.items.map((item) => (
                        <Link
                          key={item.description}
                          href={item.url}
                          target={item.blank && item.blank}
                          underline={item.url ? "hover" : "none"}
                          color="primary"
                        >
                          <Typography variant="body1">{item.description}</Typography>
                        </Link>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Wrapper>
            </Box>
          </Container>
          <Grid className={classes.bottom} container>
            <Container maxWidth="lg">
              <Grid container direction="row" justify="space-between" alignItems="center" className={classes.fullHeight}>
                <Grid>
                  {isDownSm && <MainLogo height="44" width="250" />}
                  <Typography variant="body2" color="textPrimary" className={classes.copyright}>
                    Copyright © OneStar Marketplace 2022{" "}
                    <Typography variant="body2" color="textSecondary" className={classes.built}>
                      Built by{" "}
                      <a style={{ textDecoration: "none", color: "inherit" }} href="https://www.phinxlab.com/">
                        Phinxlab
                      </a>
                    </Typography>
                  </Typography>
                </Grid>
                {isDownSm || (
                  <Grid spacing={1} direction="row" item container className={classes.autoWidth} alignItems="center">
                    <Grid>
                      <img height={65} src={caceLogo} alt="cace logo" />
                    </Grid>
                    <Grid>
                      <a href="http://qr.afip.gob.ar/?qr=b0Lr-aQ_n6jfULRC_e-HnA,,">
                        <img src={dataFiscal} alt="fiscal data qr" />
                      </a>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Container>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};
