import React, { FunctionComponent, MouseEventHandler } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ThemeProvider } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { Box, makeStyles } from "@material-ui/core";

type ConfirmDialogProps = {
  title: React.ReactNode;
  content: React.ReactNode;
  cancelText: React.ReactNode;
  confirmText: React.ReactNode;
  open: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  onCancel: MouseEventHandler;
  onConfirm: MouseEventHandler;
  customTheme: { [k: string]: any } | null;
  buttonCancel?: (props: any) => JSX.Element;
  buttonConfirm?: (props: any) => JSX.Element;
};

const dummyFunc = () => {};

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": { maxWidth: 388 },
  },
}));

const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = ({
  title = "Confirmation Dialog",
  content = "Are you sure of perform this action?",
  cancelText,
  confirmText = "Confirm",
  open = false,
  maxWidth,
  onCancel = dummyFunc,
  onConfirm = dummyFunc,
  customTheme = null,
  buttonCancel,
  buttonConfirm,
}) => {
  const classes = useStyles();
  const ButtonConfirm = buttonConfirm;
  const ButtonCancel = buttonCancel;
  const dialog = (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      fullWidth
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <Box component={DialogActions} p="16px 24px">
        {cancelText &&
          (ButtonCancel ? (
            <ButtonCancel onClick={onCancel}>{cancelText}</ButtonCancel>
          ) : (
            <Button onClick={onCancel} variant="text" color="primary" style={{ textTransform: "capitalize" }}>
              {cancelText}
            </Button>
          ))}
        {confirmText &&
          (ButtonConfirm ? (
            <ButtonConfirm onClick={onConfirm}>{confirmText}</ButtonConfirm>
          ) : (
            <Button onClick={onConfirm} color="primary" variant="contained" autoFocus style={{ textTransform: "capitalize" }}>
              {confirmText}
            </Button>
          ))}
      </Box>
    </Dialog>
  );
  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};

export default ConfirmDialog;
