import React, { useMemo } from "react";
import { Grid, Theme, useMediaQuery } from "@material-ui/core";
import { IMAGE_BASE_URL } from "src/config";
import { makeStyles } from "@material-ui/styles";
import { SkeletonComponentProps, SOURCE } from "src/app/models/Site";
import { ConfigValues } from "src/app/models";

const styles = makeStyles(() => ({
  banner: {
    borderRadius: "8px",
    marginBottom: "24px",
  },
}));

export const SkeletonBanner = ({ data }: SkeletonComponentProps) => {
  const classes = styles();
  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));
  const { data: publication, working } = SOURCE.PUBLICATION(data.source_id);

  const imageConfig = useMemo<ConfigValues>(() => publication?.[0].config_values as ConfigValues, [publication]);

  return working ? (
    <div />
  ) : (
    <Grid item container>
      {imageConfig && (
        <Grid
          className={classes.banner}
          component="img"
          src={`${IMAGE_BASE_URL}${imageConfig?.imagen?.[isDownXs ? "small" : "normal"]}`}
          alt="Banner promos"
        />
      )}
    </Grid>
  );
};
