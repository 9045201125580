export interface Configuration {
  general_config_id: number;
  name: string;
  value: JSON;
}

export enum CONFIGURATION_ID {
  RECAPTCHA = 1,
  FRAUD = 5,
}
