import React from "react";

interface USDiconProps {
  color: string;
}

export const USDicon = ({ color }: USDiconProps) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7332 8.67289C10.7332 9.61771 10.4456 10.4034 9.87053 11.0298C9.29542 11.646 8.53545 12.0465 7.59062 12.2314C7.529 12.2519
7.49819 12.2827 7.49819 12.3238L7.40577 13.2943C7.3955 13.3457 7.36982 13.3868 7.32874 13.4176C7.29793 13.4586 7.25685 13.4792 7.2055
13.4792H5.38774C5.33639 13.4792 5.29531 13.4586 5.2645 13.4176C5.23369 13.3868 5.22342 13.3457 5.23369 13.2943L5.37234 12.293C5.38261
12.2519 5.3672 12.2211 5.32612 12.2006C4.58669 12.026 4.01158 11.7025 3.60079 11.2301C3.20026 10.7474 3 10.1517 3 9.44313C3 9.34043 3.01027
9.18638 3.03081 8.98098L3.07702 8.68829C3.08729 8.63694 3.10783 8.59586 3.13864 8.56505C3.17972 8.52398 3.22594 8.50344 3.27729
8.50344H5.2491C5.30045 8.50344 5.34153 8.52398 5.37234 8.56505C5.40315 8.59586 5.41341 8.63694 5.40315 8.68829L5.38774 8.87315C5.34666
9.21206 5.46476 9.50988 5.74205 9.76663C6.02961 10.0131 6.4404 10.1363 6.97443 10.1363C7.43658 10.1363 7.77548 10.0234 7.99115
9.79744C8.21708 9.5715 8.33005 9.31989 8.33005 9.0426C8.33005 8.85775 8.2633 8.7037 8.12979 8.58046C7.99628 8.45722 7.83196 8.35452
7.63684 8.27236C7.45198 8.19021 7.09767 8.05156 6.57391 7.85644C6.01934 7.65104 5.54179 7.43537 5.14126 7.20944C4.74074 6.9835 4.3967
6.68054 4.10914 6.30055C3.82159 5.9103 3.67781 5.43275 3.67781 4.86791C3.67781 4.77548 3.68808 4.63684 3.70862 4.45198C3.81132 3.67147
4.12455 3.0142 4.64831 2.48017C5.18234 1.94614 5.84475 1.59183 6.63553 1.41724C6.68688 1.3967 6.71255 1.36589 6.71255 1.32481L6.83579
0.184858C6.84606 0.133509 6.8666 0.0924288 6.89741 0.061619C6.93849 0.0205396 6.9847 0 7.03605 0H8.83841C8.88976 0 8.93084 0.0205396
8.96165 0.061619C9.00273 0.0924288 9.01813 0.133509 9.00786 0.184858L8.83841 1.34022C8.81787 1.38129 8.83328 1.4121 8.88462 1.43264C9.59324
1.6175 10.1581 1.96668 10.5792 2.48017C11.0002 2.98339 11.2107 3.59445 11.2107 4.31334C11.2107 4.3955 11.2005 4.53927 11.1799 4.74467L11.1491
4.96034C11.1389 5.01169 11.1132 5.0579 11.0721 5.09898C11.0413 5.12979 11.0002 5.14519 10.9489 5.14519H8.96165C8.9103 5.14519 8.86922
5.12979 8.83841 5.09898C8.8076 5.0579 8.79733 5.01169 8.8076 4.96034L8.82301 4.8525C8.86408 4.49306 8.75625 4.17469 8.49951 3.89741C8.24276
3.62012 7.86277 3.48148 7.35955 3.48148C6.94876 3.48148 6.62526 3.57904 6.38905 3.77417C6.15284 3.9693 6.03474 4.23118 6.03474 4.55981C6.03474
4.82683 6.17338 5.04763 6.45067 5.22222C6.72796 5.39681 7.24145 5.62274 7.99115 5.90003C8.06304 5.93084 8.366 6.04894 8.90003 6.25434C9.44433
6.44947 9.88594 6.75756 10.2248 7.17863C10.5637 7.59969 10.7332 8.09778 10.7332 8.67289Z"
    />
  </svg>
);
