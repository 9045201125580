import React, { useEffect, useRef, useCallback } from "react";
import { Container, Grid, Theme, useMediaQuery, Card, CardContent, Typography, Box } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { usePublication } from "src/app/business";
import { Loading, AnyObject } from "src/commons";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ReactHtmlParser from "react-html-parser";
import { VDOCIPHER_SCRIPT } from "src/config";
import { useScrollToTopOnMount } from "src/commons/hooks/useScrollToTopUnMount";

export const WatchMedia = () => {
  useScrollToTopOnMount();
  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));
  const { id } = useParams<{ id: string }>();
  const { data = [], working } = usePublication(id);

  const refs = useRef(null);

  const loadPlayer = useCallback(() => {
    (window as AnyObject).playerContainer = refs.current;
    (window as AnyObject).vdo.add({
      otp: data[0]?.attributes.videoInfo.otp,
      playbackInfo: data[0]?.attributes.videoInfo.playbackInfo,
      theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
      container: (window as AnyObject).playerContainer,
    });
  }, [data]);

  useEffect(() => {
    if (working || data.length === 0) return;
    if ((window as AnyObject).vdo) return loadPlayer();
    const playerScript = document.createElement("script");
    playerScript.src = VDOCIPHER_SCRIPT;
    document.body.appendChild(playerScript);
    playerScript.addEventListener("load", () => loadPlayer());
  }, [data, working, loadPlayer]);

  return (
    <>
      <Helmet>
        <title>{data.length ? `Video - ${data[0].attributes.tokenMetadata.name}` : "Video player"}</title>
        <meta name="description" content={data.length ? data[0].attributes.tokenMetadata.description : "Visualizador de medios"} />
      </Helmet>
      <Container maxWidth="lg" disableGutters={isDownXs}>
        <Grid container justify="space-between">
          {working ? (
            <Loading />
          ) : data ? (
            <Grid container>
              <Grid item container justify="center" xs={8}>
                <Box component={Card} width={750}>
                  <CardContent>
                    <Grid ref={refs} container id="embedBox" />
                  </CardContent>
                </Box>
              </Grid>
              <Grid item container xs={4}>
                <Grid item container spacing={1}>
                  <Grid item>
                    <Typography variant="h2" color="primary">
                      {ReactHtmlParser(data[0]?.attributes.nftInfo?.start || "")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h3">{ReactHtmlParser(data[0]?.attributes.nftInfo?.subTitle || "")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h4" color="inherit">
                      {`${data[0]?.attributes.nftInfo?.typeOfGame} - ${data[0]?.attributes.nftInfo?.date}`}
                    </Typography>
                  </Grid>

                  <Grid item xs={1} container>
                    <Box color="#737373">
                      <LocationOnIcon />
                    </Box>
                  </Grid>
                  <Grid xs={11} item>
                    <Box pl="8px" color="#737373">
                      <Typography variant="subtitle2" color="inherit">
                        {data[0]?.attributes.nftInfo?.location}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h4" color="inherit">
                      {data[0]?.attributes.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container justify="center" alignItems="center">
              <Typography variant="h1" color="error">
                No se pudo encontrar el video
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};
