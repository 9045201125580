import React, { useMemo } from "react";
import { Grid, Typography, Box, makeStyles, useMediaQuery, Theme } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import { Product } from "src/app/models/business/Product";
import { normalizePublications } from "src/utils";
import { ScrollCarousel } from "src/commons/ui/view/ScrollCarousel";
import { useWalletContext } from "src/context/WalletContext";
import { Publication } from "../../../models";
import { ProductCardView } from "../ProductCard";

export interface ProductCarouselProps {
  publications: Publication[];
  title: string;
  zone: string;
  toLink?: (event: any) => void;
  onClickProduct?: (item: Product) => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  principalContainer: {
    marginBottom: ({ isDownSm }: { isDownSm: boolean }) => (isDownSm ? "5vw" : "3vw"),
  },
  linkVerOfertas: {
    ...theme.typography.body1,
    color: theme.palette.text.hint,
    textDecoration: "none",
  },
}));

export const ProductCarousel = ({ publications, title, zone, toLink, onClickProduct }: ProductCarouselProps) => {
  const history = useHistory();
  const { address } = useWalletContext();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm")); // 2
  const isDownMd = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md")); // 2
  const classes = useStyles({ isDownSm });
  const items = useMemo<Product[]>(() => normalizePublications(publications), [publications]);
  const filteredProducts = items.filter((item) => item.attributes.tokenOwner.toUpperCase() === address.toUpperCase() || item.old_price !== "0");

  const onClick = (item: Product) => {
    if (onClickProduct) {
      onClickProduct(item);
    } else {
      history.push(`/product/${item.productId}`);
    }
  };
  const arrayComponentsToBeRender = filteredProducts.map((item) => (
    <Grid key={item.productId}>
      <ProductCardView data={item} onClick={() => onClick(item)} />
    </Grid>
  ));

  return !items.length ? (
    <h1>No se han encontrado publicaciones en {title}</h1>
  ) : (
    <Grid container direction="row" className={classes.principalContainer}>
      <Grid item container direction="row" alignItems="flex-end">
        <Box pr={1}>
          <Typography variant={isDownMd ? "h5" : "h2"} color="primary">
            {title}
          </Typography>
        </Box>

        <Box mb="3px">
          {toLink ? (
            <Typography component="h6" variant="body2" onClick={toLink} style={{ cursor: "pointer" }}>
              <Box component="p" color="action.cta" m={0}>
                Ver más
              </Box>
            </Typography>
          ) : (
            <Link className={classes.linkVerOfertas} to={`/search/${zone}`}>
              Ver más
            </Link>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ScrollCarousel data={arrayComponentsToBeRender} />
      </Grid>
    </Grid>
  );
};
