import React from "react";
import ReactHtmlParser from "react-html-parser";
import LocationOnIcon from "@material-ui/icons/LocationOnOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FavoriteButtonView, SalesNFTModal, MatchStatistics } from "src/app/views/components";
import { Publication, UserRole } from "src/app/models";
import { DummyAvatar } from "src/assets/images";
import { OneStar } from "src/assets/images/svgs";
import { ONESTAR_ADDRESS } from "src/config";
import { PlayerStatistics } from "src/app/views/components/PlayerStatistics";
import { SalesHistory } from "src/app/views/components/SalesHistory";
import { ProductViewTags } from "src/app/views/components/ProductViewTags";
import { PurchaseNFTModal } from "src/app/views/components/PurchaseNFTModal";
import {
  Grid,
  Box,
  Typography,
  CardContent,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { ecommercePalette } from "src/config/ecommerce/nftmarket/ecommercePalette";

const useStyles = makeStyles(() => ({
  uppercaseText: {
    textTransform: "uppercase",
  },
  text: {
    color: ecommercePalette.materialUi.neutral[2],
  },
  accordionIcon: {
    justifyContent: "flex-start",
    "& > div": { flexGrow: 0 },
  },
  accordionHistory: {
    width: "100%",
  },
}));

interface ProductNFTViewWebProps {
  dataPublication: Publication;
  selectedRole: UserRole;
  itBelongsOfOwner: boolean;
  refs: any;
  imgUrl?: string;
  selectedCurrency: "USD" | "ETH";
  tokenId: string;
  salesHistory: any;
  tagsFilter: any;
  priceEth: string | JSX.Element;
  priceUsd: string;
}

export const ProductNFTViewWeb = ({
  dataPublication,
  selectedRole,
  itBelongsOfOwner,
  refs,
  imgUrl,
  selectedCurrency,
  tokenId,
  salesHistory,
  tagsFilter,
  priceEth,
  priceUsd,
}: ProductNFTViewWebProps) => {
  const classes = useStyles();
  const { starInfo } = dataPublication.attributes.tokenMetadata;
  const isDownMd = useMediaQuery<Theme>((theme) => theme.breakpoints.down(1025));
  return (
    <CardContent>
      <Box p={6} position="relative">
        <Grid container spacing={2}>
          <Grid item container /* SUPERIOR */ spacing={4}>
            <Grid item container xs={12} md={8} spacing={2}>
              <Grid item xs={12} /* IMAGEN */>
                {itBelongsOfOwner ? (
                  <Grid ref={refs} container id="embedBox" />
                ) : (
                  <Grid style={{ backgroundImage: `url(${imgUrl})`, width: `${isDownMd ? "auto" : 692}`, height: 466, backgroundSize: "cover" }} />
                )}
              </Grid>
              <Grid item xs={12} /* DESCRIPCION IMAGEN */>
                <ProductViewTags tags={tagsFilter} />
                <Typography variant="body1" className={classes.text}>
                  {ReactHtmlParser(dataPublication?.attributes.description || "")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={4} /* DETALLES COMPRA */>
              <Grid item container spacing={1}>
                <Grid item>
                  <Typography variant="h2" className={classes.uppercaseText} color="primary">
                    {ReactHtmlParser(dataPublication.attributes.nftInfo?.start || "")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3">{ReactHtmlParser(dataPublication?.attributes.nftInfo?.subTitle || "")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" className={classes.text}>
                    {`${dataPublication?.attributes.nftInfo?.typeOfGame} - ${dataPublication?.attributes.nftInfo?.date}`}
                  </Typography>
                </Grid>

                <FavoriteButtonView withCountFavorites top={0} right={0} fontSize="large" publicationId={dataPublication.id} />

                <Grid item xs={1} container>
                  <Box color="#737373">
                    <LocationOnIcon />
                  </Box>
                </Grid>
                <Grid xs={11} item>
                  <Box pl="8px" color={ecommercePalette.materialUi.neutral[3]}>
                    <Typography variant="subtitle2" color="inherit">
                      {dataPublication?.attributes.nftInfo?.location}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item container alignItems="center">
                  {dataPublication.attributes.tokenOwner === ONESTAR_ADDRESS ? (
                    <OneStar width={24} height={24} />
                  ) : (
                    <Grid style={{ borderRadius: "50%" }} component="img" width="24px" height="24px" src={DummyAvatar} />
                  )}
                  <Box pl="8px" color={ecommercePalette.materialUi.neutral[3]}>
                    <Typography color="textPrimary" variant="subtitle2">
                      {dataPublication.attributes.tokenOwner === ONESTAR_ADDRESS ? "OneStar" : dataPublication.attributes.ownerNick?.toLowerCase()}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item container justify="flex-start" alignItems="center">
                  <Typography variant="h4" color="primary">
                    {selectedCurrency === "USD" ? <>{priceUsd}</> : <>{priceEth}</>}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item>
                {selectedRole.rol_id !== "" ? (
                  itBelongsOfOwner ? (
                    <SalesNFTModal item={dataPublication} />
                  ) : (
                    <PurchaseNFTModal item={dataPublication} />
                  )
                ) : (
                  <Typography variant="caption">Inicia sesión para comprar NFTs</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container direction="column" /* INFERIOR */>
            <Grid item container>
              <Accordion style={{ width: "100%" }}>
                <AccordionSummary
                  className={classes.accordionIcon}
                  expandIcon={<ExpandMoreIcon color="secondary" fontSize="large" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h2">Estadísticas del partido</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MatchStatistics dataPublication={dataPublication} />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item container>
              <Accordion style={{ width: "100%" }}>
                <AccordionSummary
                  className={classes.accordionIcon}
                  expandIcon={<ExpandMoreIcon color="secondary" fontSize="large" />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h2">Estadísticas del jugador</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <PlayerStatistics starInfo={starInfo} name={dataPublication.attributes.nftInfo.start} />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item container>
              <Accordion className={classes.accordionHistory}>
                <AccordionSummary
                  className={classes.accordionIcon}
                  expandIcon={<ExpandMoreIcon color="secondary" fontSize="large" />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography variant="h2">Historial de ventas</Typography>
                </AccordionSummary>

                <AccordionDetails className={classes.accordionHistory}>
                  <SalesHistory salesHistory={salesHistory} tokenId={tokenId} />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CardContent>
  );
};
