import { routes } from "./routes";
import { MenuItem } from "../../../../commons/ui/menu";

export const regretMenu: MenuItem = {
  key: "privateRegret",
  basePath: "/cancelar-compra",
  enabled: true,
  icon: "List",
  text: "Cancelar Compra",
  children: routes,
};
