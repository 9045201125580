import React, { useMemo, ChangeEvent } from "react";
import { Grid, Box, Typography, Button, Divider, useMediaQuery, Theme } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import { Loading, useScrollToTopOnMount } from "src/commons";
import { FormAutocompleteFetch } from "src/lib/templates";
import { CommonFormInput, FormInputData } from "src/app/views";
import { ecommerceConfig } from "src/config/ecommerce";
import { State, City } from "src/app/models";
import { FormSelect } from "src/lib/templates/components/FormSelect";
import { Form } from "../../types";
import { ShipmentAddressInitialValue } from "../../initialValues";

export interface ShipmentAddressViewProps {
  form: Form;
  provinces?: State[];
  validZidCode: boolean;
  cities?: City[];
  selectedProvince?: boolean;
  loading?: boolean;
  loadingCities?: boolean;
  onInputChangeCity: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  nameForm?: string;
  searchCityValue?: string;
  title?: string;
  zipCodeLength: number;
  onClickCalculate: () => void;
  disableCalculate: boolean;
  deliveryData: { cost: number };
  deliveryTime: string;
  loadingShipment: boolean;
  onChangeAddress: () => void;
  valuesAddress: ShipmentAddressInitialValue;
}

export const ShipmentAddressView = ({
  validZidCode,
  form,
  loadingCities,
  loading,
  provinces = [],
  cities = [],
  selectedProvince,
  onInputChangeCity,
  nameForm = "shipmentAddress",
  searchCityValue = "",
  title,
  zipCodeLength = 0,
  onClickCalculate,
  disableCalculate,
  deliveryData,
  deliveryTime,
  loadingShipment,
  onChangeAddress,
  valuesAddress,
}: ShipmentAddressViewProps) => {
  useScrollToTopOnMount();
  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isUp767 = useMediaQuery<Theme>((theme) => theme.breakpoints.up(767));
  const is540 = useMediaQuery<Theme>((theme) => theme.breakpoints.up(539));
  const allFormInput = useMemo(() => {
    const formInput: FormInputData[] = [
      {
        variant: "standard",
        name: `${nameForm}.zipCode`,
        label: "Cód. postal",
        md: 4,
        sm: isUp767 ? 4 : is540 ? 6 : 12,
        xs: 12,
        required: true,
        id: "shipmentAddressZipCode",
        autoComplete: "postal-code",
        error: zipCodeLength >= 4 ? validZidCode : false,
        helperText: zipCodeLength >= 4 ? (validZidCode ? "El codigo postal no coincide" : "") : "",
      },
      {
        variant: "standard",
        name: `${nameForm}.street`,
        label: "Calle",
        md: 4,
        sm: isUp767 ? 4 : is540 ? 6 : 12,
        xs: 12,
        required: true,
        id: "shipmentAddressStreet",
        autoComplete: "street-address",
      },
      {
        variant: "standard",
        name: `${nameForm}.streetNumber`,
        label: "Altura",
        md: 4,
        sm: isUp767 ? 4 : is540 ? 6 : 12,
        xs: 12,
        required: true,
        id: "shipmentAddressStreetNumber",
      },
    ];

    return formInput.filter((input) => !input.hidden);
  }, [nameForm, validZidCode, zipCodeLength, isUp767, is540]);

  return (
    <Grid container spacing={3}>
      {title && (
        <Grid item xs={12} md={8}>
          <Box mb={1} paddingBottom={1}>
            <Typography variant="body1" color="textPrimary">
              {title}
            </Typography>
          </Box>
        </Grid>
      )}
      {loadingShipment ? (
        <Loading size={30} />
      ) : (
        <>
          {!deliveryTime ? (
            <Grid item xs={12}>
              <Grid container spacing={3} direction="row">
                <Grid item md={6} sm={isUp767 ? 6 : is540 ? 6 : 12} xs={12}>
                  <FormSelect<State>
                    loading={loading}
                    fullWidth
                    form={form}
                    label={<Box color="text.disabled">Provincia</Box>}
                    name={`${nameForm}.province`}
                    content={provinces}
                    valueKey="id"
                    labelKey="name"
                    helperText="El campo Provincia es requerido"
                    inputLabelProps={{ shrink: true }}
                    optionToValue={(value, options) => options?.find((province) => province.id === value)}
                    valueToOption={(value, options) => options.find((option: State) => option.id === value.id)?.id || searchCityValue}
                  />
                </Grid>

                <Grid item md={6} sm={isUp767 ? 6 : is540 ? 6 : 12} xs={12}>
                  <FormAutocompleteFetch
                    disabled={!selectedProvince}
                    name={`${nameForm}.city`}
                    form={form}
                    label={<Box color="text.disabled">Ciudad</Box>}
                    options={cities}
                    optionLabelKey="name"
                    optionToValue={(option: City) => option}
                    valueToOption={(value: City, options: City[]) =>
                      searchCityValue?.toLocaleLowerCase() === value?.name?.toLocaleLowerCase() && searchCityValue?.length > 0 && options.length > 0
                        ? options.find((option: City) => option.id === value.id)?.name
                        : searchCityValue
                    }
                    helperText="El campo Ciudad es requerido"
                    getOptionSelected={(option: City, value: City) => option.id === value?.id}
                    onInputChange={onInputChangeCity}
                    inputLabelProps={{ shrink: true }}
                    loading={loadingCities}
                    enabledOpenAutocomplete={searchCityValue.length > 1}
                  />
                </Grid>

                {allFormInput.map((input) => (
                  <CommonFormInput<Form>
                    key={input.label}
                    lg={input.lg}
                    md={input.md}
                    sm={input.sm}
                    xs={input.xs}
                    name={input.name}
                    form={form}
                    variant={input.variant}
                    label={input.label}
                    error={input.error}
                    helperText={input.helperText}
                    id={input.id}
                    autoComplete={input.autoComplete}
                  />
                ))}

                <Grid container item xs={12} justify="flex-end">
                  <Button disabled={disableCalculate} size="large" variant="contained" color="primary" onClick={onClickCalculate}>
                    Calcular
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item container xs={12}>
              <Box p={1} borderRadius={8} width="100%" bgcolor={ecommerceConfig.ecommercePalette.materialUi.primary.background}>
                <Grid container>
                  <Grid item xs={2} sm={1} container justify="center" alignContent="center">
                    <RoomIcon fontSize="large" color="primary" />
                  </Grid>
                  <Grid item xs={10} sm={8}>
                    <Typography>{`${valuesAddress?.street} ${valuesAddress?.streetNumber}`}</Typography>
                    <Typography>{`CP: ${valuesAddress?.zipCode} - ${valuesAddress?.city?.name}, ${valuesAddress?.province?.name}`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} container justify="flex-end">
                    <Button onClick={onChangeAddress} style={{ textTransform: "none" }}>
                      Modificar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}

          {deliveryTime && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}

          {deliveryTime && (
            <Grid item container>
              <Box
                width="100%"
                display="flex"
                border={`2px solid ${
                  deliveryData.cost > 0
                    ? ecommerceConfig.ecommercePalette.materialUi.text.primary
                    : ecommerceConfig.ecommercePalette.materialUi.success.main
                }`}
                p={2}
                borderRadius={5}
              >
                <Grid item container>
                  <Grid item xs={12} sm={10}>
                    <Typography variant="body1" color="textPrimary">
                      <Box
                        color={
                          deliveryData.cost > 0
                            ? ecommerceConfig.ecommercePalette.materialUi.text.primary
                            : ecommerceConfig.ecommercePalette.materialUi.success.main
                        }
                      >
                        {`Tu compra llegará dentro de las próximas `}
                        <Typography display="inline" variant="h6">
                          {deliveryTime}
                        </Typography>
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    {deliveryData.cost > 0 ? (
                      <Typography variant="h6" align={isSm ? "left" : "right"} color="textPrimary">
                        {`$${deliveryData.cost}`}
                      </Typography>
                    ) : (
                      <Typography variant="h6" align={isSm ? "left" : "right"}>
                        <Box color="success.main">Gratis</Box>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
