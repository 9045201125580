import { getEthPriceNow, getEthPriceHistorical, EthPriceValue, Currency } from "get-eth-price";

interface EhtPriceParse {
  date: string;
  value: EthPriceValue;
}

const initialValue: EhtPriceParse = {
  date: "",
  value: { ETH: { AUD: 0, BTC: 0, CAD: 0, CHF: 0, EUR: 0, GBP: 0, USD: 0 } },
};

export const SIMBOL_CURRENCY: { [k: string]: string } = {
  AUD: "$",
  BTC: "",
  CAD: "$",
  CHF: "Fr",
  EUR: "€",
  GBP: "£",
  USD: "$",
};

class EthereumPrice {
  private actualPrice: EhtPriceParse;

  constructor() {
    console.log("Initialize Eth price");
    this.actualPrice = initialValue;
    setTimeout(this.updatePriceInterval, 0, this);
    setInterval(this.updatePriceInterval, 1800000, this);
  }

  async updatePriceInterval(intance: EthereumPrice) {
    const ethPriceNow = await getEthPriceNow();
    const parse = Object.entries(ethPriceNow).reduce<EhtPriceParse>((acum, [date, value]) => {
      acum = { date, value };
      return acum;
    }, initialValue);
    intance.setPrice(parse);
  }

  setPrice(price: EhtPriceParse) {
    this.actualPrice = price;
  }

  getActualPrice() {
    return this.actualPrice;
  }

  async getHistoricalPrice(day?: number) {
    const price = await getEthPriceHistorical(day);
    return price;
  }

  getPriceBy({ value, coin = "ETH", currency = "USD" }: { value?: string; coin?: "ETH"; currency?: Currency }): string {
    const priceNow = this.getActualPrice();
    const price = priceNow.value[coin][currency];
    const result = value ? Number(value) * price : price;
    return (Math.round(result * 100) / 100).toFixed(2);
  }
}

const i: EthereumPrice = new EthereumPrice();
export { i as EthereumPrice };
