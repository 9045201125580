import { CartItem } from "src/app/models";
import { getPricePublication } from "src/context/CartContext/utils";
// import { getInfoPublicationPayment } from 'src/commons/hooks/useInfoPublicationPayment';

export const updateCartTotals = (cartItems: CartItem[]) => {
  const quantity = cartItems.reduce<number>((accum, item) => {
    accum += item.quantity;
    return accum;
  }, 0);
  const subTotal = cartItems.reduce<number>((accum, item) => {
    const price = getPricePublication(item.publication);
    accum += +price * item.quantity;
    return accum;
  }, 0);

  const totalOnePay = cartItems.reduce<number>((accum, item) => {
    accum += getPricePublication(item.publication) * item.quantity;
    return accum;
  }, 0);

  return {
    quantity,
    subTotal,
    shippingPrice: 0,
    totalPrice: subTotal,
    totalOnePay,
    installments: 1,
  };
};
