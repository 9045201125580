import React, { useState, useCallback } from "react";
import { Tabs, Tab, Box, Grid, Typography } from "@material-ui/core";
import { Publication } from "src/app/models";
import { PurchasedItemCard } from "src/app/views/components";
import { getPricePublication } from "src/context/CartContext/utils";
import { useHistory } from "react-router";
import { useFavoriteManage, Loading } from "src/commons";
import { formatUnits } from "ethers/lib/utils";
import { CustomButtonProps } from "src/commons/components/CustomBotton";

interface PurchasedItemsProps {
  data: Publication[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => (
  <Grid container role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
    {value === index && (
      <Box p={3} width="100%">
        {children}
      </Box>
    )}
  </Grid>
);

const EmpyComponent = ({ onHanadlerClick }: { onHanadlerClick: () => void }) => (
  <Grid justify="center" alignItems="center">
    <Box height={400} justifyContent="center" alignItems="center" display="flex" flexDirection="column">
      <Typography variant="h3">No tiene items todavia</Typography>
      <CustomButtonProps mt={3} name="Explorá" onClick={onHanadlerClick} />
    </Box>
  </Grid>
);

export const PurchasedItemsTabs = ({ data }: PurchasedItemsProps) => {
  const [value, setValue] = useState<number>(0);
  const history = useHistory();
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const { favorites, loading, addToFavorite, removeFromFavorite } = useFavoriteManage();

  const onSale = data.length > 0 ? data.filter((item) => formatUnits(getPricePublication(item)) !== "0.0") : [];
  const hidden = data.length > 0 ? data.filter((item) => formatUnits(getPricePublication(item)) === "0.0") : [];

  const onHanadlerClick = useCallback(() => {
    history.push("/search");
  }, [history]);

  return (
    <>
      <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { background: "#94f034", height: 2 } }}>
        <Tab label="Colección" />
        <Tab label="En venta" />
        <Tab label="Oculto" />
        <Tab label="Actividad" />
        <Tab label="Favoritos" />
      </Tabs>
      <Box>
        <TabPanel value={value} index={0}>
          {data.length > 0 ? (
            <Grid item container xs={12} direction="column">
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(265px, 1fr))" }}
              >
                {data.map((item) => (
                  <Box key={`${item.id}-list-products`} my={1} justifyContent="center">
                    <Grid container justify="center">
                      <PurchasedItemCard
                        item={item}
                        isFavorite={!!favorites.find((fav) => fav.publication.id === item.id)}
                        onAddFavorite={() => addToFavorite(item.id)}
                        onRemoveFavorite={() => removeFromFavorite(item.id)}
                      />
                    </Grid>
                  </Box>
                ))}
              </Grid>
            </Grid>
          ) : (
            <EmpyComponent onHanadlerClick={onHanadlerClick} />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {onSale.length > 0 ? (
            <Grid item container xs={12} direction="column">
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(265px, 1fr))" }}
              >
                {onSale.map((item) => (
                  <Box key={`${item.id}-list-products`} my={1} justifyContent="center">
                    <Grid container justify="center">
                      <PurchasedItemCard
                        item={item}
                        isFavorite={!!favorites.find((fav) => fav.publication.id === item.id)}
                        onAddFavorite={() => addToFavorite(item.id)}
                        onRemoveFavorite={() => removeFromFavorite(item.id)}
                      />
                    </Grid>
                  </Box>
                ))}
              </Grid>
            </Grid>
          ) : (
            <EmpyComponent onHanadlerClick={onHanadlerClick} />
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {hidden.length > 0 ? (
            <Grid item container xs={12} direction="column">
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(265px, 1fr))" }}
              >
                {hidden.map((item) => (
                  <Box key={`${item.id}-list-products`} my={1} justifyContent="center">
                    <Grid container justify="center">
                      <PurchasedItemCard
                        item={item}
                        isFavorite={!!favorites.find((fav) => fav.publication.id === item.id)}
                        onAddFavorite={() => addToFavorite(item.id)}
                        onRemoveFavorite={() => removeFromFavorite(item.id)}
                      />
                    </Grid>
                  </Box>
                ))}
              </Grid>
            </Grid>
          ) : (
            <EmpyComponent onHanadlerClick={onHanadlerClick} />
          )}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <EmpyComponent onHanadlerClick={onHanadlerClick} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          {loading ? (
            <Loading />
          ) : favorites.length > 0 ? (
            <Grid item container xs={12} direction="column">
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(265px, 1fr))" }}
              >
                {favorites.map((item) => (
                  <Box key={`${item.publication.id}-list-products`} my={1} justifyContent="center">
                    <Grid container justify="center">
                      <PurchasedItemCard
                        item={item.publication}
                        isFavorite={!!favorites.find((fav) => fav.publication.id === item.publication.id)}
                        onAddFavorite={() => addToFavorite(item.publication.id)}
                        onRemoveFavorite={() => removeFromFavorite(item.publication.id)}
                      />
                    </Grid>
                  </Box>
                ))}
              </Grid>
            </Grid>
          ) : (
            <EmpyComponent onHanadlerClick={onHanadlerClick} />
          )}
        </TabPanel>
      </Box>
    </>
  );
};
