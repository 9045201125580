import { makeStyles } from "@material-ui/core";

export const NotFoundPageStyles = makeStyles((theme) => ({
  gridContainer: {
    backgroundColor: "#121212",
    padding: "2.5rem 0 0 2.5rem",
    width: "100%",
    maxWidth: "56rem",
    height: "25.25rem",
    borderRadius: "0.25rem",
    margin: "2% auto",
    marginTop: "4rem",
    boxShadow: "0 0.25rem 0.3125rem 0 rgba(0, 0, 0, .25)",
  },
  boxContainer: {
    position: "relative",
    overflow: "hidden",
  },
  titleText: {
    alignItems: "center",
    color: "#FF007C",
    fontSize: "1.875rem",
    marginBottom: "1rem",
  },
  boxError: {
    position: "absolute",
  },
  boxImage: {
    position: "absolute",
    width: "100%",
  },
  errorNumber: {
    paddingRight: "6.5rem",
    alignItems: "center",
    color: "#ABABAB",
    fontSize: "5.25rem",
    lineHeight: "1em",
    fontStyle: "italic",
  },
  errorText: {
    paddingRight: "6.5rem",
    alignItems: "center",
    color: "#ABABAB",
    fontSize: "1.125rem",
    fontStyle: "italic",
  },
  infoText: {
    alignItems: "center",
    color: "#ABABAB",
    fontSize: "0.75rem",
    width: "14rem",
    lineHeight: "1em",
    marginBottom: "10.25rem",
  },
  linkStyle: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
  },
}));
