import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../../lib/libby/generator";
import { Skeleton } from "../../models";
import { businessOptions } from "../businessOptions";

const DAO_NAME = "skeleton";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "skeleton_id",
      labelKey: "name",
    },
    withSelectedChips: {
      idKey: "skeleton_id",
      labelKey: "name",
    },
  },
};

const optionsToUse = !businessOptions.Skeleton
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Skeleton,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Skeleton.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Skeleton>(DAO_NAME, optionsToUse);

// hooks
export const useSkeletonFetchById = fetchById;
export const useSkeletonLibbyCall = libbyCall;
export const useSkeletonDAO = libbyDAO;
export const useSkeletonLibbyFetch = libbyFetch;

// components/hoc
export const SkeletonAutocomplete = entityAutocomplete;
export const SkeletonCall = renderPropCall;
export const SkeletonById = renderPropFetchById;
export const SkeletonSelect = simpleSelect;
export const SkeletonAccordionSelector = accordionSelector;
export const SkeletonSelectedChips = selectedChips;

// context
// ByIdContext
export const useSkeletonByIdContext = useByIdContext;
export const SkeletonByIdProvider = ByIdProvider;
export const SkeletonByIdContext = ByIdContext;
