import React, { useMemo } from "react";
import { Breadcrumbs, Typography, useMediaQuery, Box, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { appBreadcrumbsStyles as useStyles } from "../../styles";

export interface Links {
  label: string;
  url: string;
  search?: string;
  onClick?: () => void;
}

export interface AppBreadcrumbsAppProps {
  links: Array<Links>;
  msgReturn?: string;
  mobile?: boolean;
  iconSeparator?: React.ReactNode;
  invert?: boolean;
  iconGoBack?: React.ReactNode;
  noMargin?: boolean;
}

export const AppBreadcrumbsApp = ({
  links,
  msgReturn = "Volver a",
  mobile = true,
  iconSeparator,
  invert = false,
  iconGoBack,
  noMargin,
}: AppBreadcrumbsAppProps) => {
  const classes = useStyles();
  const isDownSm = useMediaQuery<any>((theme) => theme.breakpoints.down("sm"));
  const isDown366 = useMediaQuery<any>((theme) => theme.breakpoints.down(376));

  const linksBack = useMemo(() => {
    const copyLinks = [...links];
    copyLinks.pop();
    return copyLinks;
  }, [links]);

  const linkToBack = useMemo(() => linksBack.reverse()[0], [linksBack]);

  return (
    <Box pb={isDownSm ? 0 : 2}>
      {isDownSm ? (
        linksBack.length && mobile ? (
          <Link
            color="inherit"
            className={invert ? classes.lastLink : classes.link}
            to={{
              pathname: linkToBack.url,
              search: linkToBack.search,
              state: {
                goBack: [...linksBack].splice(-1),
              },
            }}
            onClick={linkToBack.onClick}
          >
            <Box display="flex" flexDirection="row" alignItems="center" style={isDown366 && !noMargin ? { marginLeft: 16 } : {}}>
              <Box>
                <IconButton edge="start" color="primary">
                  {iconGoBack || <ArrowBackIcon />}
                </IconButton>
              </Box>

              <Typography noWrap variant={invert ? "h6" : "subtitle1"} color={invert ? "textPrimary" : "textSecondary"}>
                {`${linksBack.length > 1 ? msgReturn : ""} ${linkToBack.label}`}
              </Typography>
            </Box>
          </Link>
        ) : null
      ) : (
        <Breadcrumbs
          separator={iconSeparator || <NavigateNextIcon fontSize="small" htmlColor="white" />}
          className={classes.breadcrumbs}
          aria-label="breadcrumb"
          classes={{ separator: classes.breadcrumbsLi }}
        >
          {links.map((link, index) => {
            const goBackLinks = [...links];

            return index + 1 === links.length ? (
              <Typography key={link.url} color="textPrimary" className={invert ? classes.lastLink : classes.breadCrumbLast} variant="body1">
                {link.label}
              </Typography>
            ) : (
              <Link
                key={link.url}
                color="inherit"
                className={invert ? classes.link : classes.breadcrumbRootLink}
                to={{
                  pathname: link.url,
                  search: link.search,
                  state: {
                    goBack: goBackLinks.splice(0, index),
                  },
                }}
                onClick={link.onClick}
              >
                <Typography variant="body1">{link.label}</Typography>
              </Link>
            );
          })}
        </Breadcrumbs>
      )}
    </Box>
  );
};
