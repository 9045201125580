// TODO: Rehacer esto
/* eslint-disable no-shadow */
import React, { useCallback, useMemo, useState } from "react";
import { useStyles } from "./style";

type MenuState = {
  isOpen: boolean;
  isVisible: boolean;
};

export type UIContextStyle = {
  root: string;
  appBar: string;
  appBarShift: string;
  menuButton: string;
  hide: string;
  drawer: string;
  drawerPaper: string;
  drawerHeader: string;
  content: string;
  contentShift: string;
};
/**
 *
 */
const menu: MenuState = {
  isOpen: true,
  isVisible: true,
};
const toggleMenu = () => {
  menu.isOpen = !menu.isOpen;
};
const showMenu = () => {
  menu.isVisible = true;
};
const hideMenu = () => {
  menu.isVisible = false;
};

export type UIContextType = {
  [k: string]: any;
  style: UIContextStyle;
  menu: MenuState;
  toggleMenu(): void;
  showMenu(): void;
  hideMenu(): void;
};
/**
 *
 */
export const UIContext = React.createContext<UIContextType>({
  style: {
    root: "",
    appBar: "",
    appBarShift: "",
    menuButton: "",
    hide: "",
    drawer: "",
    drawerPaper: "",
    drawerHeader: "",
    content: "",
    contentShift: "",
  },
  menu,
  toggleMenu,
  showMenu,
  hideMenu,
});
/**
 *
 */
export type UIContextProviderProps = {
  children: JSX.Element[] | JSX.Element;
};
/**
 *
 * @param props
 * @constructor
 */
export const UIContextProvider = (props: UIContextProviderProps) => {
  const { children } = props;
  const style = useStyles();
  const [menu, setMenu] = useState({
    isOpen: true,
    isVisible: true,
  });

  const toggleMenu = useCallback(() => {
    setMenu((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  }, []);
  const showMenu = useCallback(() => {
    setMenu((prev) => ({ ...prev, isVisible: true }));
  }, []);
  const hideMenu = useCallback(() => {
    setMenu((prev) => ({ ...prev, isVisible: false }));
  }, []);

  const value = useMemo(
    () => ({
      style,
      menu,
      toggleMenu,
      showMenu,
      hideMenu,
    }),
    [style, menu, toggleMenu, showMenu, hideMenu],
  );
  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};
