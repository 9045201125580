import React, { useState, useMemo, useCallback, useEffect } from "react";
import { SOURCE } from "src/app/models/Site";
import { LinksBreadcrumbs, useBreadcrumbsPromotions, useBreadcrumbs } from "src/commons";
import { useParams, useHistory } from "react-router";
import { usePublicationByStock, usePublication } from "src/app/business";
import { useCartContext } from "src/context";
import { ConfigValuesImages, Publication, FeatureGroup, ConfigValuesPublication, Feature } from "src/app/models";
import { PUBLICATION_TYPE } from "src/app/const";
import { ShipmentData } from "src/screens/Public/CalculateShipments/types";
import { useBreadcrumbsCategory } from "src/commons/hooks/useBreadcrumbsCategory";
import { Analytics } from "src/platform/analytics";
import { searchSkuPublication, getProductImage, searchFeature, getDiscountPublication } from "src/utils";
import { useInfoPublicationPayment } from "src/commons/hooks/useInfoPublicationPayment";
import { usePaymentInfoShow } from "src/screens/Public/Product/hooks/paymentInfoShow";
import { InfoModalData, ProductView } from "src/app/views";
import { PaymentMethod } from "src/app/views/components/PaymentMethod";
import { orderBy } from "lodash";
import { PoliticsText } from "src/screens/Public/Politicas/components";
import { GuaranteesText } from "src/screens/Public/guarantees";
import { CalculateShipment } from "src/screens/Public/CalculateShipments";
import { initialValue } from "src/screens/Public/CalculateShipments/initialValues";

export const SkeletonProductDetails = () => {
  const { publicationId } = useParams<{ publicationId: string }>();
  const [isTrack, setIsTrack] = useState(false);

  const { data: publication, working } = SOURCE.PUBLICATION(publicationId);

  const dataPublication = useMemo(() => {
    if (publication?.[0]) {
      const publicationData: Publication = { ...publication?.[0] };
      const {
        attributes: { features },
      } = publicationData;
      const newFeatures = features?.filter((feature) => feature.name !== "Categories");
      publicationData.attributes.features = newFeatures;
      return publicationData;
    }
    return undefined;
  }, [publication]);

  const { data: stock, working: workingStock } = usePublicationByStock(publicationId);
  // TODO : esta es la publicacion que tiene configurado la imagenes de los metodos de pago
  const { data: images = [] } = usePublication("1e574739-8813-4f0d-a7bf-ae7ee7b30fa6");

  const paymentImages = useMemo<ConfigValuesImages>(
    () =>
      images
        .filter((publication) => publication.type === PUBLICATION_TYPE.IMAGES)
        .map<ConfigValuesImages>((publication) => publication.config_values as ConfigValuesImages)[0] || { images: [] },
    [images],
  );
  const history = useHistory<any>();

  const [openMeansPayment, setOpenMeansPayment] = useState(false);

  const [shipmentData, setShipmentData] = useState<ShipmentData>({ deliveryDate: "", ...initialValue });

  const links = useMemo(() => {
    if (publication?.[0]) {
      const linksProduct: LinksBreadcrumbs[] = [{ label: publication?.[0].product }];
      return linksProduct;
    }
    return [];
  }, [publication]);

  const nameCategory = useMemo(() => {
    if (publication?.[0]?.attributes?.features) {
      const featureCategory: FeatureGroup | undefined = searchFeature({
        name: "Categories",
        featuresAll: publication?.[0]?.attributes.features,
      });

      return featureCategory?.features?.[0]?.name;
    }
    return "";
  }, [publication]);

  const newLinksCategory = useBreadcrumbsCategory({ name: nameCategory });

  const newLinksPromo = useBreadcrumbsPromotions();

  const newLinks = useBreadcrumbs({
    links,
    oldLinks: history.location.state?.prevPath ? newLinksPromo : newLinksCategory,
  });

  const { cartState, dispatch } = useCartContext();

  const productIdInCart = cartState.items.find((item) => item.publication.id === publicationId);

  const [quantity, setQuantity] = useState(productIdInCart?.quantity || 1);

  // here should go the logic of buying
  const onClickUpdateCart = useCallback(() => {
    if (productIdInCart) {
      dispatch.editQuantity({ id: publicationId, newQuantity: quantity + 1 });
    } else {
      dispatch.addItem({ publication: publication?.[0], quantity, stock: stock?.quantity || 0 });
    }
    dispatch.updateCartTotals();
    // Tracking event
    if (publication) {
      Analytics.trackAddToCart({ publication: publication[0], quantity });
    }
  }, [publication, dispatch, productIdInCart, publicationId, quantity, stock]);

  const onBuyClick = () => {
    onClickUpdateCart();
    history.push({
      pathname: `/cart/checkout`,
      state: {
        goBack: newLinks,
      },
    });
  };

  const onAddToCartClick = () => {
    onClickUpdateCart();
    history.push({
      pathname: `/cart`,
      state: {
        goBack: newLinks,
      },
    });
  };

  const imagesCarousel = useMemo(() => {
    const images = publication?.[0].attributes?.images;
    const sku = searchSkuPublication({
      featuresPublication: publication?.[0].attributes.features,
    });
    if (images && sku) {
      return images.map((image) => ({
        img_url: getProductImage({
          sku,
          typeImage: image,
        }),
        alt: image,
      }));
    }
    return publication?.[0].attributes.image_url
      ? [
          {
            img_url: publication?.[0].attributes.image_url,
            alt: publicationId,
          },
        ]
      : [];
  }, [publication, publicationId]);

  const [idInfoModalShow, setIdInfoModalShow] = useState("payment");

  const onClickOpenModal = useCallback(
    (id: string) => {
      setIdInfoModalShow(id);
      setOpenMeansPayment(!openMeansPayment);
    },
    [openMeansPayment],
  );

  const { mainPrice, mainAmount } = useInfoPublicationPayment(publication?.[0]);

  const {
    cards,
    provinces,
    cardOptionsSelected,
    validEmitters,
    selectedCard,
    selectedEmitter,
    selectedProvince,
    setSelectedCard,
    setSelectedProvince,
    setSelectedEmitter,
  } = usePaymentInfoShow(quantity, publication?.[0] as Publication);

  const onChanteProvince = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedProvince((old) => ({ ...old, id: event.target.value }));
    },
    [setSelectedProvince],
  );

  const onChanteCard = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setSelectedCard((old) => ({ ...old, card_id: value }));
    },
    [setSelectedCard],
  );
  const onChanteEmiter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setSelectedEmitter((old) => ({ ...old, card_emiter_id: Number(value) }));
    },
    [setSelectedEmitter],
  );

  const infoModalShow = useCallback(
    (id: string): InfoModalData | undefined => {
      const modalsData: InfoModalData[] = [
        {
          id: "payment",
          name: "Otras Promociones",
          render: (
            <PaymentMethod
              publicConfig={publication?.[0]?.config_values as ConfigValuesPublication}
              cards={cards}
              selectedCard={selectedCard}
              provinces={orderBy(provinces, "name")}
              selectedProvince={selectedProvince}
              emitters={validEmitters}
              selectedEmitter={selectedEmitter}
              cardOptionsSelected={cardOptionsSelected}
              handleChangeCard={onChanteCard}
              handleChangeEmitter={onChanteEmiter}
              HandleChangeStateId={onChanteProvince}
            />
          ),
        },
        {
          id: "politics",
          name: "Política de devolución",
          render: <PoliticsText />,
        },
        {
          id: "guarantees",
          name: "Garantía",
          render: <GuaranteesText />,
        },
        {
          id: "calculateShipment",
          name: "Datos para envío",
          render: (
            <CalculateShipment publication={publication?.[0]} quantity={quantity} shipmentData={shipmentData} setShipmentData={setShipmentData} />
          ),
        },
      ];

      return modalsData.find((modalData) => modalData.id === id);
    },
    [
      selectedCard,
      selectedEmitter,
      onChanteCard,
      onChanteEmiter,
      onChanteProvince,
      validEmitters,
      provinces,
      cardOptionsSelected,
      cards,
      selectedProvince,
      publication,
      shipmentData,
      quantity,
    ],
  );

  const enableStock = useMemo(() => !!(stock && stock.quantity > 0), [stock]);

  const availableQuantity = useMemo(() => (stock && stock?.quantity > 0 ? stock?.quantity - quantity : 0), [quantity, stock]);

  const discount = useMemo(
    () => (publication?.[0] ? getDiscountPublication(mainPrice[mainAmount].toString(), publication?.[0].old_price) : 0),
    [publication, mainPrice, mainAmount],
  );

  useEffect(() => {
    if (publication?.[0] && !isTrack) {
      setIsTrack(true);
      Analytics.trackVisitPublication({ publication: publication[0] });
    }
  }, [isTrack, publication]);

  const landingFeatures: Feature[] = [];

  publication?.[0]?.attributes?.features?.forEach((group: FeatureGroup) => {
    if (group.name === "Landing") {
      group.features?.forEach((feature: Feature) => landingFeatures?.push(feature));
    }
  });

  return working ? (
    <div />
  ) : (
    <ProductView
      dataPublication={dataPublication}
      newLinks={newLinks}
      working={working}
      stock={stock}
      quantity={quantity}
      availableQuantity={availableQuantity}
      imagesCarousel={imagesCarousel}
      paymentImages={paymentImages}
      enableStock={enableStock}
      workingStock={workingStock}
      landingFeatures={landingFeatures}
      idInfoModalShow={idInfoModalShow}
      openMeansPayment={openMeansPayment}
      discount={discount}
      shipmentData={shipmentData}
      infoModalShow={infoModalShow}
      onAddToCartClick={onAddToCartClick}
      onBuyClick={onBuyClick}
      onClickOpenModal={onClickOpenModal}
      setOpenMeansPayment={setOpenMeansPayment}
      setQuantity={setQuantity}
    />
  );
};
