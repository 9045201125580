import React, { useMemo } from "react";
import { Grid, Theme, useMediaQuery } from "@material-ui/core";
import { IMAGE_BASE_URL } from "src/config";
import { PUBLICATION_TYPE } from "src/app/const";
import { ConfigValues } from "src/app/models";
import { makeStyles } from "@material-ui/styles";

interface BannerPromoProps {
  publications: any[];
}

const styles = makeStyles(() => ({
  banner: {
    borderRadius: "8px",
    marginBottom: "24px",
  },
}));

export const BannerPromo = ({ publications }: BannerPromoProps) => {
  const classes = styles();
  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));

  const banner = useMemo<ConfigValues>(
    () => publications?.find((publication) => publication.type === PUBLICATION_TYPE.BANNER)?.config_values as ConfigValues,
    [publications],
  );
  return (
    <Grid item container>
      {banner && (
        <Grid
          className={classes.banner}
          component="img"
          src={`${IMAGE_BASE_URL}${banner?.imagen[isDownXs ? "small" : "normal"]}`}
          alt="Banner promos"
        />
      )}
    </Grid>
  );
};
