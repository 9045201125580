/* eslint-disable */
import { sort } from "ramda";
import moment from "moment";
import { AnyObject } from "src/commons";
import TranslateService from "src/commons/services/translation";

type FilterByKeyFunction = (item: any) => boolean;

export const filterByKey =
  (query = ""): FilterByKeyFunction =>
  (value) => {
    const blackList = ["id"];
    if (typeof value === "string") {
      return value.toLowerCase().includes(query.toLowerCase());
    }
    if (typeof value === "number") {
      return value.toString().toLowerCase().includes(query.toLowerCase());
    }
    if (Array.isArray(value)) {
      return value.filter(filterByKey(query)).length > 0;
    }
    if (typeof value === "object" && value !== null) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, val] of Object.entries(value)) {
        if (!blackList.includes(key) && filterByKey(query)(val)) {
          return true;
        }
      }
      return false;
    }
    return false;
  };

export const orderPending = (data: AnyObject) =>
  data.sort(
    (a: any, b: any) =>
      b?.estadoSolicitudPases?.descripcionSolicitudPase.indexOf("P") - a?.estadoSolicitudPases?.descripcionSolicitudPase.indexOf("P"),
  );

export const sortByKey = (key: string) =>
  sort((a: any, b: any) => {
    if (typeof a[key] === "undefined" || typeof b[key] === "undefined") {
      return 0;
    }

    let varA = a[key];
    let varB = b[key];

    if (Number.isInteger(+varA) && Number.isInteger(+varB)) {
      varA = +varA;
      varB = +varB;
    }

    if (typeof varA === "string") {
      varA = a[key].toUpperCase();
      varB = b[key].toUpperCase();
    }
    // eslint-disable-next-line no-nested-ternary
    return varA > varB ? 1 : varA < varB ? -1 : 0;
  });

export const isEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getFromLocalStorage = (key: string) => {
  const items = localStorage.getItem(key);
  return items ? JSON.parse(items) : [];
};
export const setToLocalStorage = (key: string, items: any) => {
  localStorage.setItem(key, JSON.stringify(items));
  return true;
};

export const getQueryVariable = (key: string) => {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === key) {
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
};

export const convertNullsToString = (data = {}) =>
  Object.entries(data).reduce(
    (accum, [key, val]) => ({
      ...accum,
      [key]: val === null ? "" : val,
    }),
    {},
  );

export const capitalize = (word: string): string =>
  word
    .trim()
    .split(" ")
    .reduce((prev, current) => `${prev ? `${prev} ` : ""}${current[0].toUpperCase() + current.slice(1).toLowerCase()}`, "");

export const formatMoney = (numberToFormat: string) => `$${parseFloat(numberToFormat).toFixed(2)}`;

export const formatDate = (dateToFormat: Date | string, withTime = true) => moment(dateToFormat).format(`DD-MM-YYYY ${withTime ? " HH:mm" : ""}`);

export const formatDecimal = (number: string) => parseInt(number, 10);

export const getProperty = (path: string, obj: AnyObject) => path.split(".").reduce((prev, curr) => (prev ? prev[curr] : null), obj);

export const translate = (string: string) => TranslateService.t(string);

export const isSearchIncludedInValue = (search: string, values: AnyObject, properties: Array<string>) =>
  search
    .trim()
    .split(" ")
    .every((element: string) => properties.some((property: string) => getProperty(property, values)?.toLowerCase().includes(element?.toLowerCase())));

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const optionsSort = (Array: any[]) =>
  Array.sort((a, b) => {
    if (a.descripcion !== "Otros") {
      if (a.descripcion > b.descripcion) {
        return 1;
      }
      return -1;
    }
    return 0;
  });

export type ValueFile = {
  files: File[];
  value: string;
};

export const getFileProperties = async (file: ValueFile) => {
  const base64: string = await toBase64(file.files[0]);
  const pieces = base64.split("base64,");
  return {
    file_name: file.value,
    base64: pieces[pieces.length - 1],
    mime_type: file.files[0].type,
  };
};
