import { makeStyles } from "@material-ui/core";

interface verticalBannerCardStylesProps {
  placement: number | undefined;
  maxItemHeight: boolean;
  activeCard: boolean;
}

export const verticalBannerCardStyles = makeStyles((theme) => ({
  carouselItem: {
    transition: "transform 0.4s ease, opacity 0.4s ease",
    transform: ({ placement }: verticalBannerCardStylesProps) => `translateY(${placement}px)`,
    opacity: ({ maxItemHeight }: verticalBannerCardStylesProps) => (maxItemHeight ? 1 : 0),
  },
  card: {
    border: ({ activeCard }: verticalBannerCardStylesProps) => (activeCard ? `1px solid ${theme.palette.primary.main}` : "none"),
  },
}));
