import React, { useCallback, useState } from "react";
import { AppBar, Box, useMediaQuery, Container, Theme, Paper, Drawer, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import { useGeneralContext, useRolesContext } from "src/context";
import { ecommerceConfig } from "src/config/ecommerce";
import { logout } from "src/platform/libby/session";
import { AppBarMobile } from "./components/AppHeaderMobile";
import { AppHeaderWeb } from "./components/AppHeaderWeb";
import { MenuDrawer } from "../MenuDrawer";

const useStyle = makeStyles((theme) => ({
  appBar: {
    boxShadow: "0px 0px 63px 4px rgb(149 240 53 / 40%)",
  },
  drawer: {
    width: "100%",
  },
  drawerPaper: {
    width: "100%",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    backgroundColor: "black",
  },
  zindex: {
    zIndex: theme.zIndex.appBar,
  },
}));

interface AppHeaderProps {
  mobileInputVisible?: boolean;
  toggleInput: () => void;
}

export const AppHeader = ({ mobileInputVisible, toggleInput }: AppHeaderProps) => {
  const classes = useStyle();
  const { dispatch } = useGeneralContext();
  const [searchValue, setSearchValue] = useState("");
  const [openMainMenu, setOpenMainMenu] = useState(false);
  const { deselectRole } = useRolesContext();
  const isDownSM = useMediaQuery<any>((theme) => theme.breakpoints.down("sm"));
  const history = useHistory();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const Wrapper = isDownSm ? React.Fragment : Container;

  const onOpenMenu = useCallback(() => {
    if (isDownSm) {
      setOpenMainMenu(true);
    } else {
      dispatch.setSearch("");
      history.push("/search");
    }
  }, [isDownSm, history, dispatch]);

  const onSearchChange = useCallback(
    (e) => {
      setSearchValue(e.target.value);
    },
    [setSearchValue],
  );

  const onSearchClick = useCallback(() => {
    dispatch.setSearch(searchValue);
    history.push(!searchValue ? "/search" : `/search/${searchValue.trim()}`);
    setSearchValue("");
  }, [history, searchValue, dispatch, setSearchValue]);

  const onSignOut = useCallback(async () => {
    await logout();
    deselectRole();
    history.push("/");
  }, [history, deselectRole]);

  const onLogoClick = useCallback(() => {
    history.push("/");
  }, [history]);

  const onCloseDrawer = useCallback(() => {
    setOpenMainMenu(false);
  }, []);

  const onMobileItemClick = useCallback(
    (path: string) => {
      history.push(path);
      onCloseDrawer();
    },
    [history, onCloseDrawer],
  );

  const contents = [
    {
      id: 0,
      isSelected: true,
      label: ecommerceConfig.general.defaultSearchText,
      onClick: () => onMobileItemClick("/search"),
    },
    {
      id: 1,
      isSelected: false,
      label: "Stars",
      onClick: () => onMobileItemClick("/search"),
    },
    {
      id: 2,
      isSelected: false,
      label: "Equipos",
      onClick: () => onMobileItemClick("/search"),
    },
    {
      id: 3,
      isSelected: false,
      label: "Torneos",
      onClick: () => onMobileItemClick("/search"),
    },
  ];

  const onestarLinks = [
    {
      id: 0,
      label: "Acerca de Onestar",
      onClick: () => console.log("Acerca"),
    },
    {
      id: 1,
      label: "Primeros Pasos",
      onClick: () => console.log("Primeros"),
    },
    {
      id: 2,
      label: "Preguntas Frecuentes",
      onClick: () => console.log("Preguntas"),
    },
  ];

  return (
    <AppBar position="sticky" elevation={0} className={classes.appBar}>
      <Paper square elevation={2}>
        <Container maxWidth={false} id="back-to-top-anchor">
          <Drawer
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            open={openMainMenu}
          >
            <MenuDrawer
              onSignOut={onSignOut}
              onCloseDrawer={onCloseDrawer}
              onLogoClick={() => onMobileItemClick("/")}
              contents={contents}
              onestarLinks={onestarLinks}
            />
          </Drawer>

          <Wrapper>
            <Box justifyContent="center">
              {isDownSM ? (
                <AppBarMobile
                  onSearchClick={onSearchClick}
                  inputVisible={mobileInputVisible || false}
                  toggleInput={toggleInput}
                  onSearchChange={onSearchChange}
                  searchValue={searchValue}
                  onOpenMenu={onOpenMenu}
                  onLogoClick={onLogoClick}
                />
              ) : (
                <AppHeaderWeb
                  contents={contents}
                  onSearhChange={onSearchChange}
                  onSearchClick={onSearchClick}
                  onSignOut={onSignOut}
                  onLogoClick={onLogoClick}
                  searchValue={searchValue}
                />
              )}
            </Box>
          </Wrapper>
        </Container>
      </Paper>
    </AppBar>
  );
};
