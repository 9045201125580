import { fade, makeStyles } from "@material-ui/core";

export const productCardStyles = makeStyles((theme) => ({
  container: {
    width: 265,
    height: 390,
    maxHeight: 390,
    position: "relative",
  },
  containerHover: {
    position: "relative",
  },
  backdrop: {
    zIndex: 2,
    color: "#fff",
    position: "absolute",
    background: fade(theme.palette.primary.dark, 0.7),
  },
  box: {
    overflow: "hidden",
  },
  installmentsContainer: {
    position: "absolute",
    zIndex: 10,
    top: 0,
  },
  installmentsInnerContainer: {
    background: "#00D1F7",
    padding: "4px 12px",
    display: "flex",
    flexDirection: "column",
    borderBottomLeftRadius: 5,
  },
  installmentsTitle: {
    color: "white",
    fontWeight: 600,
  },
  installmentsSubtitle: {
    color: "white",
    fontWeight: 600,
    fontSize: 11,
    marginTop: "-5px",
  },
  priceWithDiscountContainer: {
    height: 30,
    margin: "0px -4px 5px",
  },
  priceText: {
    textDecoration: "line-through",
    color: "#8492A6",
    fontSize: 12,
    fontWeight: 400,
  },
  priceWithDiscountText: {
    color: "#3C4858",
    fontSize: 20,
    fontWeight: 400,
  },
  titleText: {
    minHeight: "50px",
  },

  oldPrice: {
    textDecoration: "line-through",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
  },
  iconLocalShipping: { width: 15, height: 15 },
  productName: {
    width: "100%",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    background: "#fff",
  },
}));
