import { makeBusinessEntity, PartialBusinessEntityOptions } from "../../../lib/libby/generator";
import { Publication } from "../../models";
import { businessOptions } from "../businessOptions";

const DAO_NAME = "publication";

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: "idPublication",
      labelKey: "descripcion",
    },
    withSelectedChips: {
      idKey: "idPublication",
      labelKey: "descripcion",
    },
  },
};

const optionsToUse = !businessOptions.Publication
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Publication,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Publication.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Publication>(DAO_NAME, optionsToUse);

// hooks
export const usePublicationFetchById = fetchById;
export const usePublicationLibbyCall = libbyCall;
export const usePublicationDAO = libbyDAO;
export const usePublicationLibbyFetch = libbyFetch;

// components/hoc
export const PublicationAutocomplete = entityAutocomplete;
export const PublicationCall = renderPropCall;
export const PublicationById = renderPropFetchById;
export const PublicationSelect = simpleSelect;
export const PublicationAccordionSelector = accordionSelector;
export const PublicationSelectedChips = selectedChips;

// context
// ByIdContext
export const usePublicationByIdContext = useByIdContext;
export const PublicationByIdProvider = ByIdProvider;
export const PublicationByIdContext = ByIdContext;
