import React, { useEffect, useState } from "react";
import { Grid, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { VIDEO_BASE_URL } from "src/config";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import { videoComponentStyle } from "../../styles/videoComponentStyles";

interface SpecificationsType {
  title: string;
  data: string;
}
interface SpecificationsSmType {
  title1: string;
  data1: string;
  title2: string;
  data2: string;
}
interface VideoComponentType {
  url?: string;
  sku: string;
  specifications?: SpecificationsType[];
  specificationsSm?: SpecificationsSmType[];
}

export const VideoComponent = ({ url, sku, specifications, specificationsSm }: VideoComponentType) => {
  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isDown350 = useMediaQuery<Theme>((theme) => theme.breakpoints.down(350));
  const classes = videoComponentStyle();
  const [mute, setMute] = useState(true);
  const [hover, setHover] = useState(false);
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    (async () => {
      await fetch(`${VIDEO_BASE_URL}/q_auto:eco/Products/${sku}/video.mp4`)
        .then((response) => {
          if (response.status === 200) {
            setLoadData(true);
          } else {
            setLoadData(false);
          }
        })
        .catch((error) => {
          setLoadData(false);
          console.error(error);
        });
    })();
  }, [sku]);

  return (
    <>
      {specifications && (
        <>
          <Grid className={isSm ? classes.hidden : classes.specificationsRoot}>
            {specifications.map(({ title, data }, index) => {
              if (specifications.length - 1 === index) {
                return (
                  <Grid className={classes.specification}>
                    <Grid>
                      <Typography variant="body1" color="textSecondary">
                        {title}
                      </Typography>
                      <Typography variant="h4" color="textPrimary">
                        {data}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }
              return (
                <>
                  <Grid className={classes.specification}>
                    <Grid>
                      <Typography variant="body1" color="textSecondary">
                        {title}
                      </Typography>
                      <Typography variant="h4" color="textPrimary">
                        {data}
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className={classes.dividerV} />
                </>
              );
            })}
          </Grid>
          <Grid className={isSm ? classes.specificationsRootSm : classes.hidden}>
            {specificationsSm &&
              specificationsSm.map(({ title1, data1, title2, data2 }, index) => {
                if (specificationsSm.length - 1 === index) {
                  return (
                    <>
                      <Grid className={classes.specificationSmRoot}>
                        <Grid className={isDown350 ? classes.specificationXs : classes.specificationSm}>
                          <Grid>
                            <Typography variant="body1" color="textSecondary">
                              {title1}
                            </Typography>
                            <Typography variant="h4" color="textPrimary">
                              {data1}
                            </Typography>
                          </Grid>
                        </Grid>
                        <div className={classes.dividerV} />
                        <Grid className={isDown350 ? classes.specificationXs : classes.specificationSm}>
                          <Grid>
                            <Typography variant="body1" color="textSecondary">
                              {title2}
                            </Typography>
                            <Typography variant="h4" color="textPrimary">
                              {data2}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                }
                return (
                  <>
                    <Grid className={classes.specificationSmRoot}>
                      <Grid className={isDown350 ? classes.specificationXs : classes.specificationSm}>
                        <Grid>
                          <Typography variant="body1" color="textSecondary">
                            {title1}
                          </Typography>
                          <Typography variant="h4" color="textPrimary">
                            {data1}
                          </Typography>
                        </Grid>
                      </Grid>
                      <div className={classes.dividerV} />
                      <Grid className={isDown350 ? classes.specificationXs : classes.specificationSm}>
                        <Grid>
                          <Typography variant="body1" color="textSecondary">
                            {title2}
                          </Typography>
                          <Typography variant="h4" color="textPrimary">
                            {data2}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className={classes.dividerH} />
                  </>
                );
              })}
          </Grid>
        </>
      )}
      {loadData && (
        <Grid className={classes.videoRoot}>
          {/* eslint-disable-next-line */}
          <div title="Seleccione para activar sonido" onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
            <Grid
              onClick={() => setMute(!mute)}
              className={classes.muteIconRoot}
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}
            >
              {isSm && !mute ? (
                <VolumeUpIcon className={classes.muteIcon} onClick={() => setMute(!mute)} />
              ) : hover && !mute ? (
                <VolumeUpIcon className={classes.muteIcon} onClick={() => setMute(!mute)} />
              ) : mute ? (
                <VolumeOffIcon className={classes.muteIcon} onClick={() => setMute(!mute)} />
              ) : (
                ""
              )}
            </Grid>
            {/* eslint-disable-next-line */}
            <video
              width={isSm ? "100%" : "702px"}
              height={isSm ? "" : "394.88px"}
              autoPlay
              loop
              className={classes.video}
              onClick={() => setMute(!mute)}
              muted={mute}
            >
              <source src={url || `${VIDEO_BASE_URL}/q_auto:eco/Products/${sku}/video.mp4`} />
            </video>
          </div>
        </Grid>
      )}
    </>
  );
};
