import React from "react";
import { Checkbox, FormControlLabel, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { useField } from "react-final-form-hooks";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "../../../../theme/colors";
import { AnyObject } from "../../../../commons/types";

const useStyles = makeStyles(() => ({
  listItem: {
    padding: 0,
  },
  listItemIcon: {
    marginRight: "-20px",
  },
  text: {
    color: grey.medium,
  },
}));

export interface FormMultiCheckboxProps {
  label?: string;
  name: string;
  form: any;
  options: AnyObject[];
  idKey?: string;
  labelKey?: string;
  onChange?: any;
}

// TODO: reimplementar el checkbox
export const FormMultiCheckbox = ({
  label,
  name,
  form,
  options,
  idKey = "id",
  labelKey = "descripcion",
  onChange = () => null,
}: FormMultiCheckboxProps) => {
  const classes = useStyles();
  const field = useField(name, form);

  return (
    <>
      <Typography>{label}</Typography>
      {options.map((option) => {
        const ids = field.input.value || [];
        const isChecked = !!ids.find((val: number) => val === option[idKey]);
        return (
          <ListItem key={option[idKey]} role="listitem" className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={({ target: { checked: _checked } }: any) => {
                      const newIds = _checked ? [...ids, option[idKey]] : ids.filter((id: number) => id !== option[idKey]);
                      field.input.onChange(newIds);
                      onChange(newIds);
                    }}
                    color="primary"
                  />
                }
                label=""
              />
            </ListItemIcon>
            <ListItemText id={`list-all-item-${option[idKey]}-label`} disableTypography>
              <Typography variant="body1" className={classes.text}>
                {option[labelKey]}
              </Typography>
            </ListItemText>
          </ListItem>
        );
      })}
    </>
  );
};
