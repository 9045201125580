import React from "react";
import { useParams } from "react-router";
import { Container, useMediaQuery, Theme, Box } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useBreadcrumbs } from "src/commons";
import { AppBreadcrumbsApp } from "src/app/views";
import { AddressesInfoEditValue, MainForm } from "./components";
import { Loading } from "../../../../../commons/components/Loading";
import { InitialValueDefinition, TransformFunction } from "./types";

const initialValue: InitialValueDefinition = {
  province: { id: "", name: "", matches: [] },
  city: { id: "", zip: [0], name: "", matches: [], site_id: 0 },
  street: "",
  streetNumber: "",
  floor: "",
  apartament: "",
  zipCode: "",
  additionalInfo: "",
  is_selected: false,
};

export const transformer: TransformFunction<InitialValueDefinition> = (value) => ({
  province: { ...value.state, id: value.state.id.toString() },
  city: { ...value.city, id: value.city.id.toString() },
  street: value.street,
  streetNumber: value.number.toString(),
  floor: value.floor.toString(),
  apartament: value.department.toString(),
  zipCode: value.zip,
  additionalInfo: value.comments,
  is_selected: value.is_selected,
});

export const AddressesInfoForm = () => {
  const { id } = useParams();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const links = useBreadcrumbs({
    links: [
      { label: "Modificar datos", url: "/dashboard/profile" },
      { label: "Perfil", url: "/dashboard/profile" },
    ],
  });
  return (
    <Container maxWidth="lg">
      <Box px={isDownSm ? 0 : "10%"}>
        {isDownSm && (
          <AppBreadcrumbsApp
            links={links}
            iconSeparator={<Box px={1}>/</Box>}
            iconGoBack={<ChevronLeftIcon color="primary" />}
            invert
            msgReturn=" "
            noMargin
          />
        )}
        {id === "new" ? (
          <MainForm initialValue={initialValue} />
        ) : (
          <AddressesInfoEditValue<InitialValueDefinition> idBuyerAddress={id} transformer={transformer}>
            {({ working, data, raw }) => (working || !data ? <Loading /> : <MainForm initialValue={data} raw={raw} />)}
          </AddressesInfoEditValue>
        )}
      </Box>
    </Container>
  );
};
