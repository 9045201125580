import React, { useMemo, useReducer, createContext, useContext, useEffect, useCallback } from "react";
import { AnyObject } from "src/commons";
import { Cart } from "src/app/models";
import { useCartDAO } from "src/app/business/Cart";
import { usePublicationByStockDAO } from "src/app/business";
import { cartInitialState, cartReducer, initialTotals } from "./reducer";
import { Dispatch, CartState, CartItemStock } from "./types";
import { addItem, removeItem, editQuantity, updateCartTotals, updateState } from "./actions";
import { persistentCart } from "./utils";

export interface CartContextValue {
  cartState: CartState;
  dispatch: Dispatch | AnyObject;
}

const CartContext = createContext<CartContextValue>({ cartState: cartInitialState, dispatch: {} });

interface CartContextProviderProps {
  children: JSX.Element;
}

const persistCart = persistentCart();
export const CartContextProvider = ({ children }: CartContextProviderProps) => {
  const cartDAO = useCartDAO();
  const publicationStockDAO = usePublicationByStockDAO();

  const [cartState, dispatcher] = useReducer(cartReducer, cartInitialState);

  const dispatch = useMemo<Dispatch>(
    () => ({
      addItem: (item) => dispatcher(addItem(item)),
      removeItem: (id) => dispatcher(removeItem(id)),
      editQuantity: ({ id, newQuantity }) => dispatcher(editQuantity({ id, newQuantity })),
      updateCartTotals: () => dispatcher(updateCartTotals()),
      updateState: (state: CartState) => dispatcher(updateState(state)),
    }),
    [dispatcher],
  );

  const initials = useCallback(async () => {
    const ownerPersist = persistCart.get();

    if (!ownerPersist) {
      const cart: Cart = await cartDAO.getByOwner("");
      persistCart.persist(cart.owner);
      dispatch.updateState({ ...cart, totals: initialTotals });
      dispatch.updateCartTotals();
    } else if (cartState.owner !== ownerPersist) {
      // en caso de error, borro el carrito y creo uno nuevo
      try {
        const cart: Cart[] = await cartDAO.getByOwner(ownerPersist);
        const items: CartItemStock[] = [];
        await cart[0].items.reduce<Promise<void>>(async (acum, item) => {
          const res = await publicationStockDAO.getByStock(item.publication.id);
          items.push({ ...item, stock: res.quantity });
        }, Promise.resolve());
        dispatch.updateState({ ...cart[0], items, totals: initialTotals });
        dispatch.updateCartTotals();
      } catch (e) {
        console.log("Error obteniendo el carrito", e);
        persistCart.remove();
        const cart: Cart = await cartDAO.getByOwner("");
        persistCart.persist(cart.owner);
        dispatch.updateState({ ...cart, totals: initialTotals });
        dispatch.updateCartTotals();
      }
    }
  }, [cartDAO, dispatch, cartState.owner, publicationStockDAO]);

  useEffect(() => {
    initials();
  }, [initials]);

  useEffect(() => {
    (async () => {
      const { lastUpdate, totals, ...rest } = cartState;
      if (rest.owner !== "") await cartDAO.updateCart(rest);
    })();
  }, [cartState, cartDAO]);

  const contextValue = useMemo(() => ({ cartState, dispatch }), [cartState, dispatch]);

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};

export const useCartContext = () => useContext(CartContext);
