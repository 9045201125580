import React from "react";
import { useHistory } from "react-router";
import { Grid, Typography, makeStyles, Box, Button } from "@material-ui/core";
import { SearchNotFoundIcon } from "src/assets/images/svgs";
import { ecommerceTypography } from "src/config/ecommerce/nftmarket/ecommerceTypography";
import { ecommercePalette } from "src/config/ecommerce/nftmarket/ecommercePalette";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: -30,
  },
  message: {
    ...ecommerceTypography.h3,
    textAlign: "center",
    fontWeight: 700,
    marginTop: -30,
  },
  info: {
    marginTop: 12,
    textAlign: "center",
    ...ecommerceTypography.subtitle2,
    color: ecommercePalette.materialUi.text.secondary,
  },
  exploreButton: {
    ...ecommerceTypography.h5,
    color: ecommercePalette.themeColor,
    marginTop: 30,
    marginBottom: 30,
  },
}));

export const ProductNotFound = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <SearchNotFoundIcon width="250" height="220" />
      <Typography className={classes.message}>
        No hay publicaciones que coincidan
        <br />
        con tu búsqueda.
      </Typography>
      <Typography className={classes.info}>
        Revisa la ortografía de tu búsqueda
        <br />o te invitamos a seguir explorando nuestro sitio
      </Typography>
      <Box
        mt={3}
        component={Button}
        borderRadius={30}
        bgcolor="white"
        color={ecommercePalette.materialUi.primary.contrastText}
        padding="12px 48px"
        marginTop={0}
        onClick={() => history.push("/search")}
        className={classes.exploreButton}
      >
        EXPLORAR
      </Box>
    </Grid>
  );
};
