/* eslint max-len: ["error", { "ignorePattern": "^\\s*var\\s.+=\\s*require\\s*\\(", "code": 1000 }] */
import * as React from "react";

interface MainLogoProps {
  width?: string;
  height?: string;
}

export const MainLogo = ({ width = "162", height = "45" }: MainLogoProps) => (
  <svg width={width} height={height} viewBox="0 0 162 36" fill="none" xmlns="http://www.w3.org/2000/svg" id="store-icon">
    <g clipPath="url(#clip0)">
      <path
        d="M67.1074 28.9391V25.2002H56.6202C52.571 25.2002 52.0023 22.8719 52.0023 19.5462H64.4732C66.902 19.5462 67.794 17.8834 67.794 15.3901C67.794 8.32558 63.5413 6.65887 57.4665 6.65887C50.1784 6.65887 47.1011 9.15214 47.1011 17.8815C47.1011 24.946 48.3164 28.9411 55.1994 28.9411L67.1074 28.9391ZM52.0023 15.8073C52.0023 12.0664 53.4212 10.4036 57.4703 10.4036C60.3042 10.4036 62.8965 11.6085 62.8965 14.519C62.8965 15.1437 62.7748 15.8073 61.8429 15.8073H52.0023Z"
        fill="black"
        fillOpacity="0.87"
      />
      <path
        d="M71.6805 28.9388H76.4353V11.8876C76.4353 11.0572 76.8411 10.6413 77.6526 10.64H81.9909C85.2355 10.64 86.4927 12.3863 86.4927 15.7139V28.9388H91.238V14.4236C91.238 8.60272 87.7499 6.89722 82.8848 6.89722H75.3322C72.899 6.89722 71.6824 8.14482 71.6824 10.64V28.9388H71.6805Z"
        fill="black"
        fillOpacity="0.87"
      />
      <path
        d="M106.521 6.66266C98.8376 6.66266 95.1099 9.15398 95.1099 17.8775C95.1099 26.6806 98.83 29.1738 106.521 29.1738C114.213 29.1738 117.933 26.6825 117.933 17.8775C117.925 9.15398 114.205 6.66266 106.521 6.66266ZM100.011 17.8775C100.011 12.4777 101.668 10.4016 106.521 10.4016C111.375 10.4016 113.032 12.4777 113.032 17.8775C113.032 23.2773 111.375 25.4446 106.521 25.4446C101.668 25.4446 100.011 23.2831 100.011 17.8775Z"
        fill="black"
        fillOpacity="0.87"
      />
      <path
        d="M124.315 6.89722H119.41L127.588 27.2197C128.396 29.1736 129.695 29.1736 130.911 29.1736C132.126 29.1736 133.423 29.1736 134.193 27.2197L142.391 6.89722H137.486L131.487 23.2305C131.365 23.5623 131.164 24.1036 130.916 24.1036C130.669 24.1036 130.471 23.5623 130.346 23.2305L124.315 6.89722Z"
        fill="black"
        fillOpacity="0.87"
      />
      <path
        d="M155.97 6.95557H145.751V10.6984H154.269C156.293 10.6984 157.263 11.7791 157.263 13.8591V15.2736H147.563C143.921 15.2736 142.223 17.9764 142.223 22.1363C142.223 26.7096 143.921 28.9972 147.563 28.9972H158.36C160.787 28.9972 162.001 27.7496 162.001 25.2544V13.4419C161.995 9.70107 160.013 6.95557 155.97 6.95557ZM157.263 24.0048C157.263 24.8366 156.859 25.2531 156.052 25.2544H149.376C147.759 25.2544 147.111 24.2144 147.111 22.1344C147.111 20.0544 147.759 19.0144 149.376 19.0144H157.263V24.0048Z"
        fill="black"
        fillOpacity="0.87"
      />
      <path
        d="M32.1128 0H2.62836C1.17676 0 0 1.20049 0 2.68137V33.3173C0 34.7982 1.17676 35.9987 2.62836 35.9987H32.1128C33.5644 35.9987 34.7411 34.7982 34.7411 33.3173V2.68137C34.7411 1.20049 33.5644 0 32.1128 0Z"
        fill="#8B04BC"
      />
      <path
        d="M9.11038 24.4902C8.6401 24.4899 8.18026 24.6318 7.78905 24.898C7.39784 25.1643 7.09283 25.543 6.9126 25.9861C6.73236 26.4292 6.68501 26.917 6.77653 27.3876C6.86805 27.8582 7.09432 28.2905 7.42673 28.6299C7.75914 28.9693 8.18275 29.2005 8.64397 29.2942C9.1052 29.3879 9.58331 29.34 10.0178 29.1565C10.4524 28.973 10.8238 28.6621 11.0851 28.2632C11.3464 27.8644 11.4859 27.3954 11.4859 26.9156C11.4849 26.273 11.2343 25.657 10.7891 25.2024C10.3439 24.7479 9.74027 24.4918 9.11038 24.4902V24.4902Z"
        fill="white"
      />
      <path
        d="M25.6381 6.67267C25.1679 6.67267 24.7083 6.81491 24.3173 7.08141C23.9264 7.34792 23.6217 7.7267 23.4417 8.16988C23.2618 8.61305 23.2147 9.10071 23.3064 9.57118C23.3982 10.0417 23.6246 10.4738 23.9571 10.813C24.2896 11.1522 24.7132 11.3832 25.1743 11.4768C25.6355 11.5704 26.1135 11.5223 26.5479 11.3388C26.9823 11.1552 27.3536 10.8443 27.6149 10.4455C27.8761 10.0466 28.0155 9.57771 28.0155 9.09802C28.015 8.45494 27.7644 7.83834 27.3187 7.38361C26.8729 6.92887 26.2685 6.67318 25.6381 6.67267V6.67267Z"
        fill="white"
      />
      <path
        d="M8.93926 20.2779H23.6335C24.2132 20.2607 24.7634 20.0136 25.1674 19.5892C25.5715 19.1648 25.7975 18.5965 25.7975 18.0049C25.7975 17.4133 25.5715 16.845 25.1674 16.4206C24.7634 15.9962 24.2132 15.7491 23.6335 15.7319H11.2216V11.3526H17.3191C17.9103 11.3526 18.4773 11.1131 18.8953 10.6866C19.3133 10.2601 19.5482 9.68174 19.5482 9.07864C19.5482 8.47553 19.3133 7.89713 18.8953 7.47067C18.4773 7.04421 17.9103 6.80463 17.3191 6.80463H8.90122C8.31037 6.80514 7.74389 7.04495 7.32627 7.47135C6.90866 7.89776 6.67407 8.47587 6.67407 9.07864V17.9632C6.67407 18.5764 6.9126 19.1646 7.3373 19.5986C7.762 20.0326 8.33815 20.2769 8.93926 20.2779V20.2779Z"
        fill="white"
      />
      <path
        d="M15.2554 26.9311C15.2554 27.5342 15.4902 28.1126 15.9082 28.539C16.3263 28.9655 16.8932 29.2051 17.4844 29.2051H25.9023C26.4935 29.2051 27.0605 28.9655 27.4785 28.539C27.8965 28.1126 28.1314 27.5342 28.1314 26.9311C28.1314 26.3279 27.8965 25.7495 27.4785 25.3231C27.0605 24.8966 26.4935 24.657 25.9023 24.657H17.4844C16.8932 24.657 16.3263 24.8966 15.9082 25.3231C15.4902 25.7495 15.2554 26.3279 15.2554 26.9311V26.9311Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="162" height="35.9987" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
