import React from "react";
import { Grid } from "@material-ui/core";
import { Product } from "src/app/models/business/Product";
import { SkeletonComponent, SkeletonComponents, SKELETON_TYPE } from "src/app/models/Site";
import { orderBy } from "lodash";
import { promotionsViewStyles as useStyles } from "../../styles";

interface PromotionsViewProps {
  onClick: (item: Product) => void;
  skeletonComponents: SkeletonComponent[];
}

export const PromotionsView = ({ skeletonComponents, onClick }: PromotionsViewProps) => {
  const classes = useStyles();

  const handleClick = (event: any) => {
    const anchor = (event.target.ownerDocument || document).querySelector("#to-products-anchor");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Grid container direction="column" className={classes.container}>
      {orderBy(skeletonComponents, "relevance", "asc").map((skeleton: SkeletonComponent) => {
        const Component = SkeletonComponents[SKELETON_TYPE[skeleton.config_values.component_key]];
        // TODO : Ver como mejorar esto, para los custom props
        return <Component data={skeleton.config_values} props={{ toLink: handleClick, onClickProduct: onClick }} />;
      })}
    </Grid>
  );
};
