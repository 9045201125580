import React, { useCallback, useState } from "react";
import { useConfirmationDAO } from "src/app/business";
import { VerifyCode } from "src/app/models";
import { useSnackbar } from "notistack";
import { FieldValidator } from "final-form";
import { useRolesContext } from "src/context";
import { ConfirmAccountFormView } from "src/app/views/components/ConfirmAccountFormView";
import { useConfirmAccountFormContext } from "../hooks/useConfirmAccountFormContext";

export const ConfirmAccountForm = () => {
  const { form, errors, handleSubmit } = useConfirmAccountFormContext();
  const confirmationDAO = useConfirmationDAO();
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useRolesContext();
  const [loading, setLoading] = useState(false);

  const sendVerificationCode = useCallback(async () => {
    try {
      await confirmationDAO.sendCode({ email: userInfo.email });
    } catch (error) {
      console.log("Error al enviar el codigo");
    }
  }, [confirmationDAO, userInfo.email]);

  const validateCheckCode: FieldValidator<string> = useCallback(
    async (value: string, allValues: any, meta) => {
      let result: VerifyCode = { isValid: false, verification_status_id: 2 };
      if (meta?.pristine) return;
      try {
        result = await confirmationDAO.verifyCode(userInfo.email, value);
      } catch (error) {
        console.log("Error verificando el codigo");
      }
      if (!result.isValid) {
        return "Código incorrecto.";
      }
    },
    [confirmationDAO, userInfo.email],
  );

  const reSendCode = useCallback(async () => {
    setLoading(true);
    await sendVerificationCode();
    enqueueSnackbar("Código reenviado, recordá verificar tu casilla de no deseados.", {
      variant: "info",
    });
    setLoading(false);
  }, [sendVerificationCode, enqueueSnackbar]);

  return (
    <ConfirmAccountFormView
      form={form}
      loading={loading}
      disableNext={Object.keys(errors as any).length !== 0}
      reSendCode={reSendCode}
      validateCheckCode={validateCheckCode}
      onSubmit={handleSubmit}
    />
  );
};
