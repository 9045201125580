import React, { FunctionComponent } from "react";
import { ListItemText, MenuItem, Menu } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { primary } from "../../../theme/colors";

interface ListMenuTypesProps {
  title: string;
  handleOnClick: () => void;
}

interface MenuListTypesProps {
  ListMenu: Record<string, any>;
  anchorEl: any;
  setAnchorEl: any;
}

export const MenuList: FunctionComponent<MenuListTypesProps> = ({ anchorEl, setAnchorEl, ListMenu }) => {
  const handleClose = (handleOnClick: () => void) => {
    setAnchorEl(null);
    handleOnClick();
  };
  // TODO: ARREGLAR COLOES
  const menuListStyles = makeStyles(() => ({
    root: {
      "& .MuiPaper-root": {
        boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
        background: "black",
        border: `1px solid ${primary.paleBlueStrong}`,
        borderBottom: 0,
        width: "15%",
        marginTop: 6,
        padding: 5,
      },
      "& .MuiList-padding": {
        padding: 0,
      },
      "& .MuiMenuItem-root": {
        borderBottom: `1px solid ${primary.paleBlueStrong}`,
        color: primary.lightBlue,
        padding: "1px 10px",
        textAlign: "right",
        paddingBottom: 3,
        wordWrap: "break-word",
      },
    },
    listItemText: {
      fontSize: 16,
      wordWrap: "break-word",
      whiteSpace: "normal",
      color: primary.lightBlue,
    },
  }));

  const classes = menuListStyles();

  return (
    <Menu
      keepMounted
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      className={classes.root}
    >
      {ListMenu.map((value: ListMenuTypesProps) => (
        <MenuItem key={value.title} onClick={() => handleClose(value.handleOnClick)}>
          <ListItemText classes={{ primary: classes.listItemText }} primary={value.title} />
        </MenuItem>
      ))}
    </Menu>
  );
};
